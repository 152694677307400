import classNames from 'classnames';
import React, { FC } from 'react';

import Sessions from './sessions/Sessions';
import styles from './styles.module.scss';
import DiskUsage from '../../../molecules/disk-usage/DiskUsage.container';
import WorkbenchMetrics from '../../../molecules/workbench-metrics/WorkbenchMetrics';

type Props = {};

const ResourceManagement: FC<Props> = () => {
  return (
    <div className={styles.resourceManagement}>
      <div className={styles.leftCol}>
        <div className={classNames(styles.category, styles.categorySessions)}>
          <Sessions />
        </div>
      </div>

      <div className={styles.rightCol}>
        <div className={classNames(styles.category, styles.categoryMetrics)}>
          <WorkbenchMetrics />
        </div>
        <div className={classNames(styles.category, styles.categoryDiskUsage)}>
          <DiskUsage />
        </div>
      </div>
    </div>
  );
};

export default ResourceManagement;
