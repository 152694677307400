import React from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';

import styles from './styles.module.scss';
import { AugurReportEntry } from '../../../../pages/augur/type';
import { useMultiReportWrapperHook } from '../../../../pages/augur/utils/multiReportWrapperHook';
import { AugurReportElementMeta, MultiReportElementProps } from '../types/meta';

export type Props<
  TReportData = Record<string, unknown>,
  TConfigType extends Record<string, unknown> = Record<string, unknown>
> = {
  elementProps: MultiReportElementProps<TReportData, TConfigType>;
  elementMeta: AugurReportElementMeta<TReportData, TConfigType>;
};

function MultiReportWrapper<
  TReportData,
  TConfigType extends Record<string, unknown>
>({ elementProps, elementMeta }: Props<TReportData, TConfigType>) {
  const { input, ...rest } = elementProps;
  const { activePage, setActivePage, prevPage, nextPage } =
    useMultiReportWrapperHook(input?.map((report) => report.jobCode));

  function renderComponent() {
    const found = input.filter((report) => report.jobCode === activePage);
    if (!found.length) {
      return <div>Invalid report data.</div>;
    }
    return (
      <elementMeta.SingleReportComponent
        {...rest}
        input={found[0] as AugurReportEntry<TReportData>}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.btn}>
        <GrPrevious className={styles.prev} onClick={prevPage} />
      </div>
      <div className={styles.item}>
        <div className={styles.content}>{renderComponent()}</div>
        <div className={styles.background}>
          {input.map((_entry, i) => {
            return (
              <div
                title={_entry.jobCode}
                key={i}
                className={styles.circle}
                onClick={() => setActivePage(_entry.jobCode)}
              >
                <div
                  className={styles.mover}
                  style={{
                    transform: `translateX(${
                      (input?.findIndex(
                        (report) => report.jobCode === activePage
                      ) -
                        i) *
                      15
                    }px)`,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btn}>
        <GrNext className={styles.next} onClick={nextPage} />
      </div>
    </div>
  );
}

export default MultiReportWrapper;
