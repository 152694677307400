import { DeepPartial } from "react-hook-form";
import { TargetTaxonomyAugurSettings, TargetTaxonomyValidationError, Taxonomy } from "./type";
import { cleanErrorObject } from "common/dist/validation/helpers";
import _ from 'lodash';

export const validateTargetTaxonomy = (
  value: TargetTaxonomyAugurSettings
): DeepPartial<TargetTaxonomyValidationError> | undefined => {
  const error: DeepPartial<TargetTaxonomyValidationError> = {};
  const seenIds = new Set<string>();

  const validateRecursive = (taxonomy: Taxonomy) => {
    const taxonomyError = validateSingleTaxonomy(taxonomy, seenIds, error);
    if (!_.isEmpty(taxonomyError)) {
      error.nodes = {
        ...error.nodes,
        [taxonomy.id]: taxonomyError,
      }
    }

    taxonomy.sub.forEach((subTaxonomy: Taxonomy) => {
      validateRecursive(subTaxonomy);
    });
  };

  value?.forEach((taxonomy: Taxonomy) => {
    validateRecursive(taxonomy);
  });

  return cleanErrorObject(error);
}

export const validateSingleTaxonomy = (
  taxonomy: Partial<Taxonomy>,
  seenIds: Set<string>,
  error: DeepPartial<TargetTaxonomyValidationError>,
): {
  titleError?: string;
  subtitleError?: string;
} => {
  const titleError = (!taxonomy.name || taxonomy.name.replace(/\s/g, '') === '') ? 'Title required' : undefined
  let subtitleError = (!taxonomy.sub || (taxonomy.id || '').replace(/\s/g, '') === '') ? 'ID required' : undefined


  if (!subtitleError && taxonomy.id) { 
    if (seenIds.has(taxonomy.id)) {
      subtitleError = 'Duplicate ID';
      error.global = 'Taxonomy contains duplicate IDs';
    } else {
      seenIds.add(taxonomy.id);
    }
  }

  return {
    titleError: titleError,
    subtitleError: subtitleError,
  };
};
