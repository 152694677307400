import React, { FC } from 'react';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FiFile } from 'react-icons/fi';
import { RouteComponentProps } from 'react-router';
import { FormattedDateTime } from '../../../../atoms/formatted-date-time/FormattedDateTime';
import { styledFileSize } from '../../../util';
import FileActions from './FileActions';

const FileElement: FC<
  {
    dataSourceCode: string;
    bucket: string;
    element: S3Object;
    showRenameModal: (renameFile: string, renamePath: string) => void;
    showDeleteModal: (deletePath: string) => void;
  } & RouteComponentProps
> = (props) => {
  const { element, dataSourceCode, bucket, showRenameModal, showDeleteModal } =
    props;

  return (
    <tr className={classNames(styles.row, styles.rowFile)}>
      <td className={styles.colIcon}>
        <FiFile size={'16px'} />
      </td>
      <td className={styles.colName}>{element.name}</td>
      <td className={styles.colCreatedAt}>
        <FormattedDateTime date={new Date(element.lastModified * 1000)} />
      </td>
      <td className={styles.colSize}>{styledFileSize(element.size)}</td>
      <td className={styles.colActions}>
        <FileActions
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      </td>
    </tr>
  );
};

export default withRouter(FileElement);
