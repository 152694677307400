import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Workspace from './Workspace.container';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';

/**
 * This component is the main part on the right side.
 * It will contain the parent Tab view as well as the Notebook and Code Editor
 */
export default class RightPart extends Component {
  render() {
    // If this div child immediately after the <Tabs> wouldn't be there, the Tab bar disappears after open/closing the
    // Arrange App Modal
    const { setSidebarVisible, sidebarVisible } = this.props;
    return (
      <div className={'container-notebook'}>
        {!sidebarVisible && (
          <BrowserCollapseFlap isExpanded={false} onClick={setSidebarVisible} />
        )}

        <Workspace />
      </div>
    );
  }
}
RightPart.propTypes = {
  setSidebarVisible: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool,
};
