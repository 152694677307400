import { FormatNumberOptions } from 'react-intl';

/**
 * TODO keep? If we want to just return the biggest unit we could use the other function and take the first element
 *  Also this is still subtly wrong, because it also takes the floor of negative diffs, i.e. in 1 minute, but 2 minutes ago if the parameters are switched
 *
 * Return the time difference in a format usable by Intl.RelativeTimeFormat
 * @param date
 * @param reference
 */
export function timeDiff(
  date: Date,
  reference?: Date
): {
  value: number;
  unit:
    | 'year'
    | 'quarter'
    | 'month'
    | 'week'
    | 'day'
    | 'hour'
    | 'minute'
    | 'second';
} {
  const referenceOrNow = reference ? reference.getTime() : Date.now();

  const diff = (referenceOrNow - date.getTime()) / 1000;
  if (Math.abs(diff) < 60) {
    const secs = Math.floor(diff);
    return { value: -1 * secs, unit: 'second' };
  } else if (Math.abs(diff) < 60 * 60) {
    const mins = Math.floor(diff / 60);
    return { value: -1 * mins, unit: 'minute' };
  } else if (Math.abs(diff) < 60 * 60 * 24) {
    const hour = Math.floor(diff / 60 / 60);
    return { value: -1 * hour, unit: 'hour' };
  } else if (Math.abs(diff) < 60 * 60 * 24 * 7) {
    const days = Math.floor(diff / 60 / 60 / 24);
    return { value: -1 * days, unit: 'day' };
  } else {
    const weeks = Math.floor(diff / 60 / 60 / 24 / 7);
    return { value: -1 * weeks, unit: 'week' };
  }
}

/**
 * Return the complete time difference split by units, Order descending (weeks,days,...) and leaving out units with value 0.
 *
 * @param date
 * @param reference
 * @returns Example [[1,{"style":"unit","unit":"minute"}],[41,{"style":"unit","unit":"second"}]]
 */
export function timeDiffAll(
  date: Date,
  reference?: Date
): [number, FormatNumberOptions][] {
  const referenceOrNow = reference ? reference.getTime() : Date.now();

  let diff = (referenceOrNow - date.getTime()) / 1000;
  const sign = Math.sign(diff);
  diff = Math.abs(diff);
  let formatList: [number, FormatNumberOptions][] = [];
  if (diff > 60 * 60 * 24 * 7) {
    const weeks = Math.floor(diff / 60 / 60 / 24 / 7);
    diff = diff - weeks * (60 * 60 * 24 * 7);
    formatList = [...formatList, [weeks, { style: 'unit', unit: 'week' }]];
  }
  if (diff > 60 * 60 * 24) {
    const days = Math.floor(diff / 60 / 60 / 24);
    diff = diff - days * (60 * 60 * 24);
    formatList = [...formatList, [days, { style: 'unit', unit: 'day' }]];
  }
  if (diff > 60 * 60) {
    const hour = Math.floor(diff / 60 / 60);
    diff = diff - hour * (60 * 60);
    formatList = [...formatList, [hour, { style: 'unit', unit: 'hour' }]];
  }
  if (diff > 60) {
    const mins = Math.floor(diff / 60);
    diff = diff - mins * 60;
    formatList = [...formatList, [mins, { style: 'unit', unit: 'minute' }]];
  }
  const secs = Math.floor(diff);
  formatList = [...formatList, [secs, { style: 'unit', unit: 'second' }]];
  // TODO what to do with the sign? -1 could mean "ago", and +1 could mean "in"
  // return formatList.map(([value, options]) => [sign * value, options]);
  return formatList;
}

/**
 * Takes an object and recursively converts all fields with the name "timestamp" to a Date.
 * @param obj
 */
// @ts-ignore
export function convertTimestamps(obj) {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (
        (key === 'timestamp' || key === 'createdAt') &&
        typeof obj[key] === 'string'
      ) {
        obj[key] = new Date(obj[key]);
      } else {
        convertTimestamps(obj[key]);
      }
    }
  }
}
