import classNames from 'classnames';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import Button from '../../../atoms/button/Button';
import { dataManagementRoutes } from '../../routes';
import styles from '../../styles.module.scss';

export interface Props {
  error?: string;
  destroyUploadForm(...args: unknown[]): unknown;
}

class UploadConfirmation extends Component<Props & RouteComponentProps> {
  componentDidMount() {
    const { destroyUploadForm } = this.props;
    destroyUploadForm();
  }

  render() {
    const {
      error,
      match: { params },
    } = this.props;

    const dataSourceCode = (params as Record<string, unknown>)?.dataSourceCode;

    return (
      <div className={classNames(styles.uploadConfirmationParent)}>
        <div className={styles.animationContainer} />
        <h3>Data transfer completed</h3>
        <Button
          color={'primary'}
          className={styles.backButton}
          label={'Back to Data Management'}
          linkTo={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}`}
          id={'confirmationRunButton'}
        />
        <br />
        {error && (
          <div className={styles.error}>
            <p>Error: {JSON.stringify(error)}</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(UploadConfirmation);
