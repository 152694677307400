import { Summary } from 'common/dist/types/habitat';

export const initial: Summary = [];

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const test: DeepPartial<Summary> = [
  {
    augurs: [
      {
        code: '0QV0G6D',
        description: 'augur classification 9',
        augurSettingsCode: 'DF343E',
        name: 'Augur Classification 9',
        health: 1,
      },
      {
        code: '0QV0G6F',
        description: 'augur classification 10',
        augurSettingsCode: 'DF343F',
        name: 'Augur Classification 9',
        health: 3,
      },
    ],
    code: '0QTY20X',
    description: 'habitat 1',
    name: 'habitat 1',
  },
];
