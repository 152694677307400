import { defineMessages } from 'react-intl';

export default defineMessages({
  msgModalShutdownConfirm: {
    id: 'modal.shutdown.confirm',
    defaultMessage: 'Shutdown',
  },
  msgModalShutdownWorkbenchHeadline: {
    id: 'modal.shutdown.workbench.headline',
    defaultMessage: 'Shutdown Workbench',
  },
  msgModalShutdownWorkbenchDescription: {
    id: 'modal.shutdown.workbench.description',
    defaultMessage:
      'You are about to shutdown this workbench. Please confirm that you are sure.',
  },
});
