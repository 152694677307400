import React, { FC } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import {
  DropdownSelectCFEAugurSettings,
  DropdownSelectCFEConfig,
  OptionsType,
} from './type';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { AugurSettingsProps } from '../../types/meta';

export type Option = {
  label: string;
  value: string;
};

export type Props = AugurSettingsProps<
  DropdownSelectCFEAugurSettings,
  DropdownSelectCFEConfig
>;

export const DropdownSelectCFE: FC<Props> = ({
  config: {
    options = [],
    placeholder = '',
    isClearable = false,
    isSearchable = false,
    isMulti = false,
  },
  error,
  disabled,
  onChange,
  onBlur,
  name,
  inputRef,
  value,
  isDirty,
  invalid,
  isTouched,
  portalTarget,
}) => {
  return (
    <DropdownSelectInput<OptionsType, typeof isMulti>
      options={options}
      disabled={disabled}
      onChange={(value) => {
        if (isMulti) {
          onChange?.(
            (value as MultiValue<Option>).map((option) => option.value)
          );
        } else {
          onChange?.([(value as SingleValue<Option>)?.value]);
        }
      }}
      value={options.filter((option) => value?.includes(option.value))}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      isClearable={isClearable}
      searchable={isSearchable}
      isMulti={isMulti}
      menuPortalTarget={portalTarget}
    />
  );
};
