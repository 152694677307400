import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC } from 'react';

import { form } from './addGroup.form';
import { ButtonProps } from '../../../atoms/button/Button';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import Wizard from '../../../pages/wizard/Wizard';
import { usersRoutes } from '../routes';

const StepName = (props) => {
  const {
    input: { onChange, onBlur, value },
  } = props;

  return (
    // @ts-ignore will be refactored at some point
    <TextInputLine
      hasLabel
      id={'groupName'}
      labelDefault={'Group Name'}
      placeholderDefault={'Enter Group Name'}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
};

type Props = {
  values: ToBeRefined;
  adminAddGroup: (...values: ToBeRefined[]) => void;
  filledCompletely: boolean;
};

const AdminAddGroup: FC<Props> = ({
  filledCompletely,
  adminAddGroup,
  values,
}) => {
  const buttons: ButtonProps[] = [
    {
      linkTo: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
      color: 'white',
      label: 'Cancel',
    },
    {
      color: 'secondary',
      label: 'Finish',
      disabled: !filledCompletely,
      linkTo: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
      onClick: () => {
        if (filledCompletely) {
          adminAddGroup(values);
        }
      },
    },
  ];

  return (
    <Wizard
      additionalClassname={'AddGroupWizard'}
      headline={'Add Group'}
      buttons={buttons}
    >
      <GenericFormStep
        formName={form.form}
        fieldName={form.fieldName}
        title={{
          defaultMessage: 'Group Name',
        }}
        description={{
          defaultMessage: 'Enter the name for your Group',
        }}
        num={1}
        component={StepName}
      />
    </Wizard>
  );
};

export default AdminAddGroup;
