import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';

import infoStyles from './../../../info/styles.module.scss';
import { inputDeleteRemote } from './DeleteBranchModal.container';
import Button from '../../../../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default class DeleteBranchModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      buttonClicked: false,
      forceButtonClicked: false,
      deleteRemote: false,
    };
  }

  handleClick(force) {
    const {
      infoFilePath,
      activeBranch,
      gitForceDeleteBranch,
      gitDeleteBranch,
      gitDeleteRemoteBranch,
      remote,
    } = this.props;
    const { deleteRemote } = this.state;

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---
    let group, repoName;
    if (remote) {
      const partsRemote = remote.pathname.substr(1).split('/');
      group = partsRemote[0];
      repoName = partsRemote.slice(1).join('/').replace('.git', '');
    }
    if (force) {
      if (!this.state.forceButtonClicked) {
        this.setState({ forceButtonClicked: true });
        gitForceDeleteBranch(repositoryPath, activeBranch);
        if (deleteRemote) {
          gitDeleteRemoteBranch(repositoryPath, activeBranch, group, repoName);
        }
      }
    } else {
      if (!this.state.buttonClicked) {
        this.setState({ buttonClicked: true });
        gitDeleteBranch(repositoryPath, activeBranch);
        if (deleteRemote) {
          gitDeleteRemoteBranch(repositoryPath, activeBranch, group, repoName);
        }
      }
    }
  }

  renderWarning() {
    const { isForceDeleteVisible } = this.props;
    if (isForceDeleteVisible) {
      return (
        <p className={'warning-text'}>
          The current branch has unmerged changes. By deleting the branch you
          will loose these changes.
        </p>
      );
    }
  }

  renderButton() {
    const { isForceDeleteVisible } = this.props;

    const buttonText = isForceDeleteVisible ? 'Delete Anyway' : 'Delete';
    const buttonDisabled = isForceDeleteVisible
      ? this.state.forceButtonClicked
      : this.state.buttonClicked;

    return (
      <Button
        color={'red'}
        label={buttonText}
        disabled={buttonDisabled}
        onClick={() => this.handleClick(isForceDeleteVisible)}
      />
    );
  }

  render() {
    const { isDeleteBranchModalOpen, hideDeleteBranchModal, activeBranch } =
      this.props;

    return (
      <ReactModal
        isOpen={isDeleteBranchModalOpen}
        contentLabel='Delete Branch Modal'
        onRequestClose={hideDeleteBranchModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={infoStyles.modalCommitParent}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={hideDeleteBranchModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={infoStyles.modalCommitElements}>
            Are your sure you want to delete the branch {activeBranch}?
            {this.renderWarning()}
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <div className={'delete-remote'}>
              <input
                type={'checkbox'}
                name={inputDeleteRemote}
                onChange={() =>
                  (this.state.deleteRemote = !this.state.deleteRemote)
                }
              />
              <span>Delete remote branch too.</span>
            </div>
            {this.renderButton()}
          </div>
        </div>
      </ReactModal>
    );
  }
}

DeleteBranchModal.propTypes = {
  isDeleteBranchModalOpen: PropTypes.bool,
  hideDeleteBranchModal: PropTypes.func.isRequired,
  gitForceDeleteBranch: PropTypes.func.isRequired,
  gitDeleteBranch: PropTypes.func.isRequired,
  gitDeleteRemoteBranch: PropTypes.func.isRequired,
  isForceDeleteVisible: PropTypes.bool,
};

DeleteBranchModal.defaultProps = {
  isOpen: false,
  isForceDeleteVisible: false,
};
