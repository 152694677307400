import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import PreQueueContainer from './pre-queue/PreQueue.container';
import { orchestrationRoutes } from '../routes';

export class Queue extends Component {
  componentDidMount() {
    const { fetchPreQueue } = this.props;
    fetchPreQueue();
  }

  render() {
    return (
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content'}>
          <Switch>
            <Route
              exact
              path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`}
            >
              <PreQueueContainer />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

Queue.propTypes = {
  links: PropTypes.shape({
    pre: PropTypes.string.isRequired,
    execution: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.object,
  fetchPreQueue: PropTypes.func.isRequired,
};

export default withRouter(Queue);
