import {
  REPO_TYPE,
  RepoType as RepoTypeType,
} from 'common/dist/types/repository';
import React from 'react';

import Label from '../../atoms/label/Label';

export interface Props {
  repoType?: RepoTypeType;
}

/**
 * Displays the label for the repository type
 * @param props
 * @constructor
 */
export default function RepositoryTypeLabel(props: Props) {
  const { repoType } = props;
  return (
    <div className={'repo-type'}>
      {repoType === REPO_TYPE.PLAIN && (
        <Label
          text={{ defaultMessage: 'Plain' }}
          backgroundColor={'grey'}
          textColor={'white'}
          borderColor={'grey'}
        />
      )}
      {repoType === REPO_TYPE.CODE_CAPSULE && (
        <Label
          text={{ defaultMessage: 'Code Capsule' }}
          backgroundColor={'blue'}
          textColor={'white'}
          borderColor={'blue'}
        />
      )}
      {repoType === REPO_TYPE.APP && (
        <Label
          text={{ defaultMessage: 'App' }}
          backgroundColor={'light-blue'}
          textColor={'white'}
          borderColor={'light-blue'}
        />
      )}
      {repoType === REPO_TYPE.MODULE && (
        <Label
          text={{ defaultMessage: 'Module' }}
          backgroundColor={'light-blue'}
          textColor={'white'}
          borderColor={'light-blue'}
        />
      )}
    </div>
  );
}
