import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { getSelectedDirPathQuery, openFileFromBin } from '../../../workbench/utils';
import {
  Copy,
  CopyName,
  DeletePermanently,
  Download,
  Restore,
  RestoreDisabled,
} from '../context-menu/ContextMenu.defaults';
import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';
import { EmptyRecycleBin } from '../icon-button-bar/IconButtonBar.defaults';

export const buttonBarButtons = [EmptyRecycleBin];

export const onClickListeners = {
  [ContentElementType.FILE_CODE]: openFileFromBin,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: openFileFromBin,
  [ContentElementType.FILE_NOTEBOOK]: openFileFromBin,
  [ContentElementType.FILE_REPOSITORY_INFO]: openFileFromBin,
  [ContentElementType.FILE_TEXT]: openFileFromBin,
  [ContentElementType.FILE_AUGUR_SETTINGS]: openFileFromBin,
  [ContentElementType.FILE_CONFIG]: openFileFromBin,
  [ContentElementType.FILE_GITIGNORE]: openFileFromBin,
  [ContentElementType.DIRECTORY_PLAIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    )
  },
  [ContentElementType.DIRECTORY_REPOSITORY]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    );
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery(
        selectedPath.slice(0, -1)
      )}`
    );
  },
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
  [ContentElementType.RECYCLE_BIN]: undefined,
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${history.location.search}`
    );
  },
};

export const contextMenuEntries = (path?: string[]) => {
  //Only allow restoring items in the top level directory of the Recycle Bin
  const isTopLevelDirectory = path.length === 1;
  const restoreAction = isTopLevelDirectory ? Restore : RestoreDisabled;

  return {
    [ContentElementType.FILE_CODE]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_DEFAULT]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_MARKDOWN]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_NOTEBOOK]: [restoreAction, CopyName, Copy, Download, DeletePermanently],
    [ContentElementType.FILE_REPOSITORY_INFO]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_TEXT]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_AUGUR_SETTINGS]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_CONFIG]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.FILE_GITIGNORE]: [restoreAction, CopyName, Download, DeletePermanently],
    [ContentElementType.DIRECTORY_PLAIN]: [restoreAction, DeletePermanently],
    [ContentElementType.DIRECTORY_REPOSITORY]: [restoreAction, DeletePermanently],
    [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
    [ContentElementType.SPECIAL_LAUNCHER]: [],
    [ContentElementType.RECYCLE_BIN]: [],
    [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: [],
  };
};

export const dnd = {
  [ContentElementType.FILE_CODE]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_DEFAULT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_MARKDOWN]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_NOTEBOOK]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_REPOSITORY_INFO]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_TEXT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_AUGUR_SETTINGS]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_CONFIG]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_GITIGNORE]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_PLAIN]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_REPOSITORY]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_LAUNCHER]: {
    isDraggable: false,
  },
  [ContentElementType.RECYCLE_BIN]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: {
    isDraggable: false,
  },
};
