import React, { FC, useState } from 'react';
import { IconType } from 'react-icons';
import { MessageDescriptor, useIntl } from 'react-intl';

import styles from './styles.module.scss';
import TextInputSearch from '../../atoms/input-elements/text-input-search/TextInputSearch';
import {
  ElementVersions,
  findElementMeta,
} from '../augur-layout-elements/common/utils';
import { ReportElementTypes } from '../augur-layout-elements/report-elements/types/type';
import { SettingsElementTypes } from '../augur-layout-elements/settings-elements/types/type';
import {
  GridLayoutElementTransferData,
  PayloadCategory,
} from '../grid-layout-editor/type';

export type GalleryEntry = {
  name: MessageDescriptor;
  description: MessageDescriptor;
  type: string;
  version: string;
  previewImage: string;
  Icon?: IconType;
};

export type Props = {
  elements: GalleryEntry[];
  category: PayloadCategory;
};

const ElementGallery: FC<Props> = ({ elements, category }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const intl = useIntl();

  let filteredElements: GalleryEntry[];
  if (!searchQuery) {
    filteredElements = elements;
  } else {
    // Filter for elements that include the search query in the name
    const lowerQuery = searchQuery.toLowerCase();
    filteredElements = elements.filter((el) => {
      const intlName = intl.formatMessage(el.name).toLowerCase();
      return intlName.includes(lowerQuery);
    });
  }

  const renderEmpty = () => {
    return (
      <div className={styles.empty}> There are no elements to be shown</div>
    );
  };

  const renderElements = () => {
    return (
      <div className={styles.elementsGridContainer}>
        {filteredElements?.map((element) => {
          const meta = findElementMeta(
            element.type as ReportElementTypes | SettingsElementTypes,
            element.version as ElementVersions
          );
          const data: GridLayoutElementTransferData = {
            minH: meta.tileSizeRestrictions.minH,
            minW: meta.tileSizeRestrictions.minW,
            maxH: meta.tileSizeRestrictions.maxH,
            maxW: meta.tileSizeRestrictions.maxW,
            payload: {
              type: element.type,
              version: element.version,
              category: category,
            },
          };

          return (
            <div
              key={`${element.type}_${element.version}`}
              className={styles.elementContainer}
              draggable
              unselectable='on'
              // DO NOT REMOVE: this is required for DnD in Firefox
              //  @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
              //  @see https://github.com/react-grid-layout/react-grid-layout#grid-layout-props
              onDragStart={(e) =>
                e.dataTransfer.setData('text/plain', JSON.stringify(data))
              }
            >
              <div
                className={styles.imageContainer}
                title={intl.formatMessage(element.description)}
              >
                <img
                  src={`data:image/png;base64, ${element.previewImage}`}
                  alt={element.type}
                  className={styles.img}
                />
              </div>
              <div className={styles.nameParent}>
                {intl.formatMessage(element.name)}
              </div>
              {element.Icon && (
                <div className={styles.icon} title={'Control Flow Element'}>
                  <element.Icon />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.elementGallery}>
      <div className={styles.searchBarParent}>
        <TextInputSearch
          submitSearchQuery={setSearchQuery}
          submitOnChange={true}
        />
      </div>
      {filteredElements.length > 0 ? renderElements() : renderEmpty()}
    </div>
  );
};

export default ElementGallery;
