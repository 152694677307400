export const cassandraTableSelectImg = `
iVBORw0KGgoAAAANSUhEUgAAAlQAAABuCAIAAABuhBqcAAAOsklEQVR4Xu3dy1IcV4KHcT1Lv0C/
wDxBT8Ss3euJ6GX3ZmbRvZ1ZOMIL0wu3LduyZVtGINlCalmEQAgoRBWIiwAB4n6/FypuEtcqqP6L
hKNTJ4tU0QWplPL7RYai8uShbo6oT6fItK6srm3NLa+Nz66ysbGxsbF99JuSp/BdWUxv7uwd5AEA
iAElT+G74g4DAPCxI34AgNghfgCA2CF+AIDYIX4AgNghfgCA2CF+AIDYIX4AgNghfgCA2CF+AIDY
IX4AgNghfgCA2CF+AIDY+ZDi19HTv7n1yh0NUUNLmzsUrs7egeB34L0/QwD4IIQdv/GpWX1AN7Y+
1fb4SfvA0Nje/r476QwXFT/dyWpmzR0tVHTOxaal6EMEI34AcCFCjd+LkYm2rr5sNuvtHh0dzS8u
p1+WGoCLit/c4vKL0Ql3tFDROReblqIPEYz4AcCFCDV+9+ubDw8P3dGSET/iBwAXIuz4uUM+Y5Mz
3vei+vPZ8xd2LP3xC5gsM/OLTcmOZm2pzpa2rvmlFQ1qqfmwqfXBo0SirUuD49Oz9o94zpqjR9nd
23v67Hki1fkokdKNXOEj5nI5PQ3zlManity556yH0I/ozr3vhJ+0d69vbNk/5cVPr9q7//bu5wcH
J8tojxO/19s7yY4e8z1z6YtsAPi4hRq/+ubU1qvX7qhlcHi8f2j08OjI252YnlNLzFEnfsGTh8cm
u3oHTA41bTWz7t0uZclVdM6jRFtnz8DO7q63Oz278PzFqD1BudIPerf10B3P+r3iFuV/iFzu0J6/
f3DQ0t69u7tnRhQ/vWTT1OX0S6X96PQdyBfGb29/XwXd2DzJp7KtXfveACC2Qo2fytfQ0j46Mb23
V+Qkl2w2q4WaM6hP/4PT3xHa8QuerIrUNSXfNqGQvzp+Ref8Vt/8cu2koB7lxNxWip6/GLEO5rUu
1KLTHrEVfQiH1mrD41NmV/FT0d8ezudHxqemZhfMrh2/vsERPSWzK8q/Bu0RAIinUOOXPz3JRUsi
LZKGRiezuZw5tLic1mLOmvuGllaZ9Q3vth2/4Mlqhr0KdJRSnaJzah+3OEFtaesyi8vegWGzuDSa
U26hjaIPIZMz86nOnmRnT+vTZ2qnXrU5pPiZlaVne2f3ydNus2vHT3/PsBeF+eM3P9HWZY8AQDyF
HT/b/NLKo0RKH9/e7vjUrJZr+sS3Ny2tVlZPli92/IInz84v2Qsmx1nVsRWd8yjhnk6iB93dO/ki
UblKpDqdp6QnaQfeVvQh2rv7xiZnTLS2Xm+nOnvNUcVvbX3T7HoeNrWa23b87tc3O0/G28wEAIit
9xm//PFXhW1dfd5t9WxyZq7weAEnfgGTZ+YXRy4hfv5zKd/E7/S3aMmOntfbO4XHg/gfYmPzlRMn
vT9qqtk9V/zqm1PmNgDA9p7jt7d/UN+c9G4vLKX7h8YKjxew4xc8Of1yrad/yB095a+OX9E5wfHT
I6ZfZgqPB/E/xPTcgjOi1+jEr/SvPbWqdr72BAB43nP8VlYzrR0na52Dg2xDy9vzR/zs+AVPzuUO
TVP9tJx653kfRecEx29pZTWguH7+h9A99FmnzChcqc5eJ37FTniZN7vOCS8Ly2mzCwAwQo1fS1uX
fUqISlb7uMVeLQ2OjPcODJurF7K5nH2pnHupQ+Dk4bHJZ/0vzFGtgdY2Tr4wVDhrHz9xrpBzFJ0T
HL/88QucnV8yuzu7u/auw/8QB9nsw8ZW8/97Gxgem1tYcuLnXOrQFHipgxZ/66eXOkhmfYNL/QAg
H3L8dvf2uvsGG1ufNic79LmsVGTWTs7kNPTJ/vhJe+L4svSW9u6A+OUDJ+ePf/PXnOpMtHVpa051
TM+9vSRgcTmtHzzrInePf84746cVZ8/AkPeU9KBatzkXGzj8D7GaWdP7o599c8ngwtL2zq4TP+8i
d/3U8UXuffsHB+Zo3vcMt3d29BwUyDdvQqpTq8bg6ywBICZCjR8AAFFA/AAAsUP8AACxQ/wAALFD
/AAAsUP8AACxQ/wAALFD/AAAsUP8AACxQ/wAALFD/AAAsUP8AACxE2r8crnc2ORMXVNrVU1t5Z0H
bGxsbGwx35QDRUFpUCDcZlym8OKXyx129b1oTnWurJ7jX3wFAHzcFAWlQYFQJtxjlya8+I1Nzejl
uaMAAOTzCoQy4Y5emvDiV9eUTGf4l1QBAEUoEMqEO3ppwotfVU2tOwQAwKkwMxFe/CrvPHCHAAA4
FWYmiB8AIBLCzATxAwBEQpiZIH4AgEgIMxPEDwAQCWFmgvgBACIhzEwQPwBAJISZCeIHAIiEMDNB
/AAAkRBmJogfACASwswE8QMAREKYmSB+AIBICDMTxA8AEAlhZoL4AQAiIcxMED8AQCSEmQniBwCI
hDAzQfwAAJEQZiaIX5AHDx6srq66o5bvv//eHbp8/f39nZ2d7uh7MjU11dTU5I4CwPmFmYkoxu/J
kyeffvrp3t6ePTg0NPRvf8jW1NT8dKqiouLq1atmN5lMurMtH3f8stns8+fP3dFzIn5ATAxOpP/v
m0Rd27h7wOd2w+Dfb7Zvvi74DC9F6ZkoX0Tjd+3atebmZnuwnPjZHj16NDIy4o6e4eOO3+7u7s8/
/+yOnhPxA2LiP/772u/+q0Kb2uYes/xPRZ03TTfcY+9SeibKF9H4dXd3f/XVV/bij/gZxA9A+Ez8
AvpnyqftT///T/fwu5SeifJFNH49PT3qn734Kxq/ZDL5ww8/6BP8xx9/HB0ddY4W5cRPj1JZWfnT
Tz/pfm7durW8vGzNPYlfV1eX7v/69ev37t1TMOwJTvwODg7q6uo0qDvUn7pz+6gjk8lUV1frnjVZ
f/b19dlHlTc9og7pT91nLpczh/zxC5gsi4uLemk3btyoPJZKpTS4tbWl9+2zzz67eezhw4dmvnqm
yT8cu3379ubm5tv7yudfvXr1yy+/6JCe88DAAPEDYmJwIv37T/5h2va/FfXOBLt8f/jzDb72PFH6
q/Lid3h4aC/+/PFraWlRybzbqo4+wfVBbE8oyo5fNpu1Q6iwqUb6cDcjip8CbBo2OTmpYBwdHZkJ
Tvz048PDw95tFej+/fsBq0z97MbGhtldX183t/UO6HH1Dni7ejeUNHPUiV/w5Pn5eVXq9evXZmR6
etq7UXTlp6Mq5fb2trercKqp5qjeMWVvYWHB221tbW1sbCR+QEyc1T917o9/+6XM8uXPk4nyRTd+
uqEll1n8OfHb39//4osvzCe+KCRa95jdswR/7Tk7O9ve3m52Fb/a2lrreP7p06f2SSJ2/JRGJwNK
hVZa9oih5VRNTY07ekwvTQsvZ1BZ1bh3247fOyfrqLN0M4rGT/OdU430n0Mvzbvd1tY2OFjwdYeW
hsQPiA9//9S5//zLz+WXL3+eTJQv0vGzF39O/MbHx82yz9CixHzon8Ufv76+vjt37vz666/6HFer
fvvtN3NI8dPjWnPfREsLI7Nrx6+hoWFubs7sevx18aiL+lnn+0mPXpreAWdQr12LMO+2Hb/gyVqk
fvfdd85Rwx8/LaD9KdV62vumVLSI1DO3j2pZTPyAWHH69/s/fnkh5cufJxPli3T88tbiz4mfPnM7
OjrMrufu3bvB56fkffG7d++eHsKsIDOZjEJojip+JjnGN998Y27b8VM+1c7bhb799lsVxcyxTU9P
qzSNjY1abtpfpeql6SGc+1HXzZe6dvyCJ29vb1dVVZ3cr48/fnr5FRUVzr3dvHnTXBCil2PPzx/X
l/gBcaP+2ee/XEj58ufJRPmiHj+z+CslfjU1NS9fvnQGHXb80um0Ptzto2qGGmZ2zxs/+3d4JdJz
aG1t1YrK/CJNL623t7dwVgEnfgGTFb/q6mp39FTR+OkvEPaIwx+/sbEx4gfEkDqn2pnyffLX22WW
L3+eTJQv6vHLny7+/F971te75xpdv379XF97qiLORe6JRMKJX+lfe+r5zMzMmN1z0erQfD85Ojqq
p1F4vIAdv+DJwV976q8UTvz07inD9oiDrz0BGKZ//8YlfUWVnonyfQDxy+VyX375pdY39oesd8KL
/W2hVl3OuZdF2fGbmJhobGw0h3Rv3i//zEjRE17saxLsR9S9+X8NWbpr1655L0dNCj5zx47fOyff
uHFja2vLHT2mVbX/HVPednZ2nEEjlUpxwgsAW/kLPqP0TJTvA4hf/vg6tqtXrzofst6lDl4w1EIt
Ys57qYPK8fXXX5vT+nWHWuc58bMvdfAugAu41KGqqspug6rjpMJQqldWVsyu7tlehOkdaGhoML+J
1LrQvmTQf6lDwOT5+Xk9Z7tn5vvV/PEpQktLS2ZXtHitrKw08/Vih4eHzde5unO1lksdAFyG0jNR
vg8jflr8ff755/4P2WQyqQWTPsEVoYALGGzOCS+zs7Na69y8efPWrVsq3+bmphM/+yL3u3fvBl/k
ns1mdf/eHYrWkeYiAYe6qAfSk1fzNFO3nQsSnj17pvtRhxTU6urqgPjlAyfnj6/Vu318IqseyDvF
xhzKZDIa1CH7Inevf3piujfdSCQS9sUPeua3j8+p4SJ3ABer9EyUL4rxAwDEUJiZIH4AgEgIMxPE
DwAQCWFmgvgBACIhzEwQPwBAJISZCeIHAIiEMDNB/AAAkRBmJogfACASwswE8QMAREKYmSB+AIBI
CDMTxA8AEAlhZoL4AQAiIcxMED8AQCSEmQniBwCIhDAzEV78qmoK/lVYAABsYWYivPjVNSXTmTV3
FACAfF6BUCbc0UsTXvzGpmaaUwX/BCsAAB4FQplwRy9NePHL5Q67+l7o5a2sZtxjAIC4UhSUBgVC
mXCPXZrw4pd/07/c2ORMXVNrVU1t5Z0HbGxsbGwx35QDRUFpUCDcZlymUOMHAEAUED8AQOwQPwBA
7BA/AEDsED8AQOwQPwBA7BA/AEDsED8AQOwQPwBA7BA/AEDsED8AQOxcWUxv7uwduMMAAHyMlDyF
78rq2tbc8tr47CobGxsbG9tHvyl5Ct+VtY0tNjY2Nja2WG3/AkkMFDG53YCmAAAAAElFTkSuQmCC
`;
