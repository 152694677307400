import React, { FC } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import {
  S3ParquetColumnSelectAugurSettings,
  S3ParquetColumnSelectConfig,
  S3ParquetColumnSelectValidationError,
} from './type';
import { useS3TableSamples } from '../../../../../../core/api/data';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { OptionType } from '../../common/type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  S3ParquetColumnSelectAugurSettings,
  S3ParquetColumnSelectConfig,
  S3ParquetColumnSelectValidationError
>;

export type S3ParquetColumnSelectErrorType = {
  global: string;
};

const S3ParquetColumnSelect: FC<Props> = (props) => {
  const { config, onChange, onBlur, error, value, disabled, portalTarget } =
    props;
  const { s3Bucket, file, isMulti, isClearable, isSearchable } = config;
  const tableName = file?.replace('.parquet', '');

  const tableSamples = useS3TableSamples(
    s3Bucket?.dataSourceCode,
    s3Bucket?.bucketName,
    tableName
  );

  const columns = tableSamples.data?.colSpecs || [];
  const columnOptions: OptionType[] = columns.map((column) => ({
    label: column.colName,
    value: column.colName,
  }));
  // Find all options that match the values in the value array
  const selectedOptions = columnOptions.filter(
    (option) => Array.isArray(value) && value.includes(option.value)
  );
  // Handle single and multi-select cases in onChange handler
  const handleChange = (
    selected: SingleValue<OptionType> | MultiValue<OptionType>
  ) => {
    if (Array.isArray(selected)) {
      onChange?.(selected.map((option) => option.value));
    } else {
      // @ts-ignore
      onChange?.(selected ? [selected.value] : []);
    }
  };

  return (
    <DropdownSelectInput<OptionType, any>
      key={'s3ParquetColumnSelect_' + file}
      id={'s3ParquetColumnSelect'}
      label={'S3 Parquet Table Columns'}
      placeholder={isMulti ? 'No Columns selected' : 'No Column Selected'}
      options={columnOptions}
      onBlur={onBlur}
      onChange={handleChange}
      value={selectedOptions}
      error={error?.global}
      isTouched={true}
      isClearable={isClearable}
      searchable={isSearchable}
      disabled={disabled}
      menuPortalTarget={portalTarget}
      isMulti={isMulti}
      appearance={'multiLines'}
    />
  );
};

export default S3ParquetColumnSelect;
