import { defineMessages } from 'react-intl';

const moduleOptimizationMessages = defineMessages({
  msgNewAugurPageChannelsTitle: {
    id: 'new_augur.pages.opt_channels.title',
    defaultMessage: 'Channels',
  },
  msgNewAugurPagePropensitiesTitle: {
    id: 'new_augur.pages.opt_propensities.title',
    defaultMessage: 'Propensities',
  },
  msgNewAugurPageCommunicationsTitle: {
    id: 'new_augur.pages.opt_communications.title',
    defaultMessage: 'Communications',
  },
  msgNewAugurPageCommunicationGroupsTitle: {
    id: 'new_augur.pages.opt_communication_groups.title',
    defaultMessage: 'Communication Groups',
  },

  msgNewAugurStepChannelsTitle: {
    id: 'new_augur.steps.opt_channels.title',
    defaultMessage: 'Channels',
  },
  msgNewAugurStepChannelsDescription: {
    id: 'new_augur.steps.opt_channels.description',
    defaultMessage: 'Define the Channels for your Campaign Optimization',
  },
  msgNewAugurStepPropensitiesTitle: {
    id: 'new_augur.steps.opt_propensities.title',
    defaultMessage: 'Propensities',
  },
  msgNewAugurStepPropensitiesDescription: {
    id: 'new_augur.steps.opt_propensities.description',
    defaultMessage: 'Define the Propensities for your Campaign Optimization',
  },
  msgNewAugurStepCommunicationsTitle: {
    id: 'new_augur.steps.opt_communications.title',
    defaultMessage: 'Communications',
  },
  msgNewAugurStepCommunicationsDescription: {
    id: 'new_augur.steps.opt_communications.description',
    defaultMessage: 'Define the Communications for your Campaign Optimization',
  },
  msgNewAugurStepCommunicationGroupsTitle: {
    id: 'new_augur.steps.opt_communication_groups.title',
    defaultMessage: 'Communication Groups (optional)',
  },
  msgNewAugurStepCommunicationGroupsDescription: {
    id: 'new_augur.steps.opt_communication_groups.description',
    defaultMessage:
      'Define the Communication Groups for your Campaign Optimization',
  },

  msgNewAugurStepChannelsErrNoName: {
    id: 'new_augur.steps.opt_channels.error.no_name',
    defaultMessage: 'Please enter a Name',
  },
  msgNewAugurStepChannelsErrNameInvalidChar: {
    id: 'new_augur.steps.opt_channels.error.name_invalid_char',
    defaultMessage:
      'Only lower- or uppercase characters and numbers are allowed',
  },
  msgNewAugurStepChannelsErrNameLength: {
    id: 'new_augur.steps.opt_channels.error.name_length',
    defaultMessage: 'The Name must not have more than 64 characters',
  },
  msgNewAugurStepChannelsErrNameDuplicate: {
    id: 'new_augur.steps.opt_channels.error.name_duplicate',
    defaultMessage: 'This name already exists',
  },
  msgNewAugurStepChannelsErrDescriptionInvalidChar: {
    id: 'new_augur.steps.opt_channels.error.description_invalid_char',
    defaultMessage:
      'Only lower- or uppercase characters, numbers spaces, as well as - _ /  are allowed',
  },
  msgNewAugurStepChannelsErrDescriptionLength: {
    id: 'new_augur.steps.opt_channels.error.description_length',
    defaultMessage: 'The Description must not have more than 256 characters',
  },
  msgNewAugurStepChannelsErrNoChannels: {
    id: 'new_augur.steps.opt_channels.error.no channels',
    defaultMessage: 'Please add at least one Channel',
  },

  msgNewAugurStepPropensitiesErrNoValueId: {
    id: 'new_augur.steps.opt_propensities.error.no_value_id',
    defaultMessage: 'Please enter a Value ID',
  },
  msgNewAugurStepPropensitiesErrValueIdInvalidChar: {
    id: 'new_augur.steps.opt_propensities.error.value_id_invalid_char',
    defaultMessage:
      'Only lower- or uppercase characters, numbers and - _ are allowed',
  },
  msgNewAugurStepPropensitiesErrValueIdLength: {
    id: 'new_augur.steps.opt_propensities.error.value_id_length',
    defaultMessage: 'The Value ID must not have more than 64 characters',
  },
  msgNewAugurStepPropensitiesErrValueIdExists: {
    id: 'new_augur.steps.opt_propensities.error.value_id_exists',
    defaultMessage: 'This Value ID already exists',
  },
  msgNewAugurStepPropensitiesErrNoPropensities: {
    id: 'new_augur.steps.opt_propensities.error.no_propensities',
    defaultMessage: 'Please add at least one Propensity',
  },

  msgNewAugurStepCommunicationsErrInvalidNumber: {
    id: 'new_augur.steps.opt_communications.error.invalid_number',
    defaultMessage: 'Please enter a valid number',
  },
  msgNewAugurStepCommunicationsErrNoCommunications: {
    id: 'new_augur.steps.opt_communications.error.no_communications',
    defaultMessage: 'Please add at least one Communication',
  },
  msgNewAugurStepCommunicationsErrNoChannel: {
    id: 'new_augur.steps.opt_communications.error.no_channel',
    defaultMessage: 'Please select a Channel',
  },
  msgNewAugurStepCommunicationsErrNoPropensity: {
    id: 'new_augur.steps.opt_communications.error.no_propensity',
    defaultMessage: 'Please select a Propensity',
  },

  msgNewAugurStepCommunicationGroupsErrNoCommunication: {
    id: 'new_augur.steps.opt_communication_groups.error.no_communication',
    defaultMessage: 'Please select a Communication',
  },

  msgAugurDetailsChannelsTabName: {
    id: 'augur_details.opt_channels.tab_name',
    defaultMessage: 'Channels',
  },
  msgAugurDetailsPropensitiesTabName: {
    id: 'augur_details.opt_propensities.tab_name',
    defaultMessage: 'Propensities',
  },
  msgAugurDetailsCommunicationsTabName: {
    id: 'augur_details.opt_communications.tab_name',
    defaultMessage: 'Communications',
  },
  msgAugurDetailsCommunicationGroupsTabName: {
    id: 'augur_details.opt_communication_groups.tab_name',
    defaultMessage: 'Communication Groups',
  },

  // --- New Augur Wizard
  msgNewAugurPageConstraintsTitle: {
    id: 'new_augur.pages.opt_constraints.title',
    defaultMessage: 'Channels',
  },
  msgNewAugurPageContactPolicyTitle: {
    id: 'new_augur.pages.opt_contact_policy.title',
    defaultMessage: 'Contact Policy',
  },
  msgNewAugurPageTargetFunctionTitle: {
    id: 'new_augur.pages.opt_target_function.title',
    defaultMessage: 'Target Function',
  },
  msgNewAugurPageCustomerTableTitle: {
    id: 'new_augur.pages.opt_customer_table.title',
    defaultMessage: 'Customer Table',
  },

  msgNewAugurStepCustomerTableTitle: {
    id: 'new_augur.steps.opt_customer_table.title',
    defaultMessage: 'Customer Table',
  },
  msgNewAugurStepCustomerTableDescription: {
    id: 'new_augur.steps.opt_customer_table.description',
    defaultMessage: 'Select the Customer Table for your Campaign Optimization',
  },
  msgNewAugurCustomerTableLabel: {
    id: 'newAugur.opt_customer_table.select_customer_table',
    defaultMessage: 'Select Customer Table',
  },
  msgNewAugurCustomerTablePlaceholder: {
    id: 'newAugur.opt_customer_table.no_customer_table_selected',
    defaultMessage: 'No Table selected',
  },
  msgNewAugurCustomerTableCustomerIdColumnLabel: {
    id: 'newAugur.opt_customer_table.customer_id_label',
    defaultMessage: 'Select Customer ID Column',
  },
  msgNewAugurCustomerTableCustomerIdColumnPlaceholder: {
    id: 'newAugur.opt_customer_table.customer_id_placeholder',
    defaultMessage: 'Customer ID Column',
  },
  msgNewAugurCustomerTableCustomerId2ColumnLabel: {
    id: 'newAugur.opt_customer_table.customer_id2_label',
    defaultMessage: 'Select Customer ID 2 Column (optional)',
  },
  msgNewAugurCustomerTableCustomerId2ColumnPlaceholder: {
    id: 'newAugur.opt_customer_table.customer_id2_placeholder',
    defaultMessage: 'Customer ID 2 Column',
  },
  msgNewAugurCustomerTableValueIdColumnLabel: {
    id: 'newAugur.opt_customer_table.value_id_label',
    defaultMessage: 'Select Value ID Column',
  },
  msgNewAugurValueTableValueIdColumnPlaceholder: {
    id: 'newAugur.opt_customer_table.value_id_placeholder',
    defaultMessage: 'Value ID Column',
  },
  msgNewAugurCustomerTableValueColumnLabel: {
    id: 'newAugur.opt_customer_table.value_column_label',
    defaultMessage: 'Select Value Column',
  },
  msgNewAugurValueTableValueColumnPlaceholder: {
    id: 'newAugur.opt_customer_table.value_column_placeholder',
    defaultMessage: 'Value Column',
  },

  msgNewAugurStepConstraintsTitle: {
    id: 'new_augur.steps.opt_constraints.title',
    defaultMessage: 'Constraints (optional)',
  },
  msgNewAugurStepConstraintsDescription: {
    id: 'new_augur.steps.opt_constraints.description',
    defaultMessage: 'Define the Constraints for your Campaign Optimization',
  },
  msgNewAugurStepContactPoliciesTitle: {
    id: 'new_augur.steps.opt_contact_policies.title',
    defaultMessage: 'Contact Policies',
  },
  msgNewAugurStepContactPoliciesDescription: {
    id: 'new_augur.steps.opt_contact_policies.description',
    defaultMessage:
      'Define the Contact Policies for your Campaign Optimization',
  },

  msgNewAugurStepTargetFunctionTitle: {
    id: 'new_augur.steps.opt_target_function.title',
    defaultMessage: 'Target Function',
  },
  msgNewAugurStepTargetFunctionDescription: {
    id: 'new_augur.steps.opt_target_function.description',
    defaultMessage: 'Define the Target Function for your Campaign Optimization',
  },

  msgNewAugurStepConstraintsErrNoLevel: {
    id: 'new_augur.steps.opt_constraints.error.no_level',
    defaultMessage: 'Please select a Constraint Level',
  },
  msgNewAugurStepConstraintsErrNoChannel: {
    id: 'new_augur.steps.opt_constraints.error.no_channel',
    defaultMessage: 'Please select a Channel',
  },
  msgNewAugurStepConstraintsErrNoCommunication: {
    id: 'new_augur.steps.opt_constraints.error.no_communication',
    defaultMessage: 'Please select a Communication',
  },
  msgNewAugurStepConstraintsErrNoCommunicationGroup: {
    id: 'new_augur.steps.opt_constraints.error.no_communication_group',
    defaultMessage: 'Please select a Communication Group',
  },
  msgNewAugurStepConstraintsErrNoConstraintType: {
    id: 'new_augur.steps.opt_constraints.error.no_constraint_type',
    defaultMessage: 'Please select a Constraint Type',
  },
  msgNewAugurStepConstraintsErrNoOperator: {
    id: 'new_augur.steps.opt_constraints.error.no_operator',
    defaultMessage: 'Please select an Operator',
  },
  msgNewAugurStepConstraintsErrNoValue: {
    id: 'new_augur.steps.opt_constraints.error.no_value',
    defaultMessage: 'Please enter a Value',
  },
  msgNewAugurStepConstraintsErrValueInvalid: {
    id: 'new_augur.steps.opt_constraints.error.value_invalid',
    defaultMessage: 'Only numbers are allowed (examples: 10 or 5.2)',
  },

  msgNewAugurStepContactPoliciesErrNoType: {
    id: 'new_augur.steps.opt_contact_policies.error.no_type',
    defaultMessage: 'Please select a Contact Policy Type',
  },
  msgNewAugurStepContactPoliciesErrNoOperator: {
    id: 'new_augur.steps.opt_contact_policies.error.no_operator',
    defaultMessage: 'Please select an Operator',
  },
  msgNewAugurStepContactPoliciesErrNoValue: {
    id: 'new_augur.steps.opt_contact_policies.error.no_value',
    defaultMessage: 'Please enter a Value',
  },
  msgNewAugurStepContactPoliciesErrValueInvalid: {
    id: 'new_augur.steps.opt_contact_policies.error.value_invalid',
    defaultMessage: 'Only numbers are allowed (examples: 10 or 5.2)',
  },
  msgNewAugurStepContactPoliciesErrNoContactPolicies: {
    id: 'new_augur.steps.opt_contact_policies.error.no_contact_policy',
    defaultMessage: 'Please add at least one Contact Policy',
  },

  msgNewAugurStepCustomerTableErrNoTable: {
    id: 'new_augur.steps.opt_customer_table.error.no_table',
    defaultMessage: 'Please select a Table',
  },
  msgNewAugurStepCustomerTableErrNoCustomerIdColumn: {
    id: 'new_augur.steps.opt_customer_table.error.no_customer_id_column',
    defaultMessage: 'Please select a Customer ID Column',
  },
  msgNewAugurStepCustomerTableErrNoValueIdColumn: {
    id: 'new_augur.steps.opt_customer_table.error.no_value_id_column',
    defaultMessage: 'Please select a Value ID Column',
  },
  msgNewAugurStepCustomerTableErrNoValueColumn: {
    id: 'new_augur.steps.opt_customer_table.error.no_value_column',
    defaultMessage: 'Please select a Value Column',
  },

  msgNewAugurStepTargetFunctionErrNoSumOver: {
    id: 'new_augur.steps.opt_target_function.error.no_sum_over',
    defaultMessage: 'Please select about what to take the Sum Over',
  },
  msgNewAugurStepTargetFunctionErrNoSumOf: {
    id: 'new_augur.steps.opt_target_function.error.no_sum_of',
    defaultMessage: 'Please select about what to take the Sum Of',
  },
  msgNewAugurStepTargetFunctionErrNoFactor: {
    id: 'new_augur.steps.opt_target_function.error.no_factor',
    defaultMessage: 'Please enter a Factor',
  },
  msgNewAugurStepTargetFunctionErrInvalidFactor: {
    id: 'new_augur.steps.opt_target_function.error.invalid_factor',
    defaultMessage: 'Please enter a Factor',
  },
  msgNewAugurStepTargetFunctionErrNoSummandGroups: {
    id: 'new_augur.steps.opt_target_function.error.no_summand_groups',
    defaultMessage: 'Please add at least one Summand Group',
  },
  msgNewAugurStepTargetFunctionErrNoMinMax: {
    id: 'new_augur.steps.opt_target_function.error.no_min_max',
    defaultMessage:
      'Please select whether to Maximize or to Minimize the Target Function',
  },

  // --- Augur Details Tabs
  msgAugurDetailsCustomerTableTabName: {
    id: 'augur_details.opt_customer_table.tab_name',
    defaultMessage: 'Customer Table',
  },
  msgAugurDetailsConstraintsTabName: {
    id: 'augur_details.opt_constraints.tab_name',
    defaultMessage: 'Constraints',
  },
  msgAugurDetailsContactPoliciesTabName: {
    id: 'augur_details.opt_contact_policies.tab_name',
    defaultMessage: 'Contact Policies',
  },
  msgAugurDetailsTargetFunctionTabName: {
    id: 'augur_details.opt_target_function.tab_name',
    defaultMessage: 'Target Function',
  },
  msgAugurDetailsConstraintExhaustionTabName: {
    id: 'augur_details.opt_constraint_exhaustion.tab_name',
    defaultMessage: 'Constraint Exhaustion',
  },
});

export default moduleOptimizationMessages;
