const defaultPosition = 'br';
const defaultAutoDismiss = 5; // In seconds

export function notificationCreateNotebookFail() {
  return {
    title: 'Create notebook failed',
    message: 'The notebook could not be created',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationCreateFileFail() {
  return {
    title: 'Create file failed',
    message: 'The file could not be created',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationCreateAskernelFail() {
  return {
    title: 'Create Kernel failed',
    message: 'The Kernel could not be created',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationDeleteSessionSuccess(name) {
  return {
    title: 'Session stopped successfully',
    message: `The session ${name} was stopped succesfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationDeleteSessionFail(name) {
  return {
    title: 'Stop session failed',
    message: `The session ${name} could not be stopped`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationPostSessionSuccess() {
  return {
    title: 'Session created successfully',
    message: `The session was created succesfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationPostSessionFail() {
  return {
    title: 'Create session failed',
    message: `The session could not be created`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationDeleteFileSuccess(name, type) {
  const upperType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
  return {
    title: `${upperType} deleted successfully`,
    message: `The ${type} ${name} was deleted succesfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationDeleteFileFail(name, type) {
  return {
    title: `Delete ${name} failed`,
    message: `The ${type} ${name} could not be deleted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationSaveNotebookSuccess(path) {
  return {
    title: 'Notebook saved successfully',
    message: `The notebook ${path} was saved successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationSaveNotebookFail(path) {
  return {
    title: 'Save notebook failed',
    message: `The notebook ${path} could not be saved`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationEditNotebookSuccess(path) {
  return {
    title: 'Notebook edited successfully',
    message: `The notebook ${path} was edited successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationEditNotebookFail(path) {
  return {
    title: 'Edit notebook failed',
    message: `The notebook ${path} could not be edited`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationPasteNotebookSuccess(path) {
  return {
    title: 'Notebook pasted successfully',
    message: `The notebook ${path} was saved successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationPasteNotebookFail(path) {
  return {
    title: 'Paste notebook failed',
    message: `The notebook ${path} could not be pasted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationRestartKernelTriggered() {
  return {
    title: 'Kernel restart requested',
    message: 'This may take a moment ...',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationRestartKernelSuccess() {
  return {
    title: 'Kernel restarted successfully',
    message: 'The kernel was restarted successfully',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationRestartKernelFail() {
  return {
    title: 'Restart kernel failed',
    message: 'The kernel could not be restarted',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function kernelNotReady() {
  return {
    title: 'Kernel is not ready',
    message: "Cell couldn't be executed, the Kernel is not ready",
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationInterruptKernelSuccess() {
  return {
    title: 'Kernel interrupted successfully',
    message: 'The kernel was interruped successfully',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationInterruptKernelFail() {
  return {
    title: 'Interrupt kernel failed',
    message: 'The kernel could not be interrupted',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationCopy(type, plural = true) {
  const upperType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
  return {
    title: `${upperType} copied`,
    message: `The ${type} ${plural ? 'were' : 'was'} copied successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationCut(type, plural = true) {
  const upperType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
  return {
    title: `${upperType} cut`,
    message: `The ${type} ${plural ? 'were' : 'was'} cut successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationStartServerTriggered() {
  return {
    title: 'Triggered successfully',
    message: 'The Workbench Server was succesfully triggered to start',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationStartServerFailed() {
  return {
    title: 'Trigger failed',
    message: 'The Workbench Server could not be started',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationStartAppTriggered() {
  return {
    title: 'Triggered successfully',
    message: 'The App was succesfully triggered to start',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function notificationStartAppFailed() {
  return {
    title: 'Trigger failed',
    message: 'The App could not be started',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

// Used for the singleCommandSessions
export function actionSuccessful() {
  return {
    title: 'Success',
    message: 'Action was successfully completed',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function actionFailed(errorMessage) {
  return {
    title: 'Error',
    message: errorMessage,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function fetchRepositoryInfoFailed(message = '') {
  return {
    title: 'Fetch repository info failed',
    message: `This notebook seems to belong to a repository, but no repository info could be fetched ${message}`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function deleteRepositorySuccessNotification() {
  return {
    title: 'Repository Deleted',
    message: `The repository was successfully deleted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function deleteRepositoryFailNotification() {
  return {
    title: 'Deleting failed',
    message: `The repository could not be deleted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function deleteDirectorySuccessNotification() {
  return {
    title: 'Directory Deleted',
    message: `The directory was successfully deleted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function deleteDirectoryFailNotification() {
  return {
    title: 'Deleting failed',
    message: `The directory could not be deleted`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}
export function notificationEditDirectoryFail(path) {
  return {
    title: 'Edit directory failed',
    message: `The directory ${path} could not be edited`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}
export function notificationEditDirectorySuccess(path) {
  return {
    title: 'Directory edited successfully',
    message: `The directory ${path} was edited successfully`,
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}
export function openMergeRequestSuccessful() {
  return {
    title: 'Open Merge Request success',
    message: 'The Merge Request was opened successfully',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}

export function openMergeRequestFailed() {
  return {
    title: 'Open Merge Request failed',
    message: 'The Merge Request could not be opened',
    position: defaultPosition,
    autoDismiss: defaultAutoDismiss,
  };
}
