import commonMessages from 'common/dist/messages/common';
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'remark-rehype';

import Button from '../../../atoms/button/Button';
import TextInputArea from '../../../atoms/input-elements/text-input-area/TextInputArea';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';

export type OwnProps = {
  path: string;
  isArrangeEditor: boolean;
};

type ContainerProps = {
  appIntro?: {
    title?: string;
    description?: string;
  };
  updateAppIntro: (path: string, title: string, description: string) => void;
};

type Props = OwnProps & ContainerProps;

type State = {
  isEditing: boolean;
  newTitle?: string;
  newDescription?: string;
};

const DEFAULT_TITLE = 'Welcome to your app';
const DEFAULT_DESCRIPTION =
  'Clicking next will guide you through the execution';

export default class IntroStep extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  renderNotEditing() {
    const { appIntro, isArrangeEditor } = this.props;
    const titleOrDefault = appIntro?.title || DEFAULT_TITLE;
    const descriptionOrDefault = appIntro?.description || DEFAULT_DESCRIPTION;
    return (
      <div className={'step-parent beginning-step-parent'}>
        <div className={'beginning-message-parent'}>
          <span className={'headline'}>{titleOrDefault}</span>
          <div className={'description-container markdown-con'}>
            <ReactMarkdown
              skipHtml={false}
              rehypePlugins={[rehypeRaw]}
              className={'description app-intro-description'}
            >
              {descriptionOrDefault}
            </ReactMarkdown>
          </div>
        </div>

        {isArrangeEditor && (
          <div className={'beginning-buttons-parent'}>
            <Button
              className={'bbp-button'}
              color={'secondary'}
              label={'Edit App Intro'}
              onClick={() =>
                this.setState({
                  isEditing: true,
                  newTitle: titleOrDefault,
                  newDescription: descriptionOrDefault,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }

  renderEditing() {
    const { updateAppIntro, path } = this.props;
    const { newTitle, newDescription } = this.state;
    return (
      <div className={'step-parent beginning-step-parent'}>
        <div className={'beginning-message-parent'}>
          <TextInputLine
            touched
            hasLabel={false}
            placeholderDefault={'Title'}
            value={newTitle}
            onChange={(e) => this.setState({ newTitle: e.target.value })}
            onBlur={() => {}}
            onFocus={() => {}}
          />

          <TextInputArea
            touched
            hasLabel={false}
            disabled={false}
            placeholder={{
              id: 'no-id',
              defaultMessage: 'Description',
            }}
            value={newDescription}
            onChange={(e) => this.setState({ newDescription: e.target.value })}
            onBlur={() => {}}
            onFocus={() => {}}
            /** Amount of rows for the text area. Defaults to 5 */
            amountRows={10}
          />
        </div>

        <div className={'beginning-buttons-parent'}>
          <Button
            className={'bbp-button'}
            color={'primary'}
            label={commonMessages.submit}
            onClick={() => {
              updateAppIntro(path, newTitle, newDescription);
              this.setState({ isEditing: false });
            }}
          />
          <Button
            className={'bbp-button'}
            color={'white'}
            label={commonMessages.cancel}
            onClick={() => this.setState({ isEditing: false })}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isEditing) {
      return this.renderEditing();
    } else {
      return this.renderNotEditing();
    }
  }
}
