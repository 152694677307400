import React from 'react';

import TextContent from './component/TextContent.container';
import { File, FileTypeWrapper } from '../FileTypeWrapper';

export const FileText: FileTypeWrapper = {
  component: (file: File, paneId: string) => (
    <TextContent
      path={file.path}
      content={file.content}
      unsavedChanges={file.unsavedChanges}
      showCloseConfirm={file.showCloseConfirm}
      paneId={paneId}
      readOnly={false}
    />
  ),
};

export const FileTextReadOnly: FileTypeWrapper = {
  component: (file: File, paneId: string) => (
    <TextContent
      path={file.path}
      content={file.content}
      unsavedChanges={file.unsavedChanges}
      showCloseConfirm={file.showCloseConfirm}
      paneId={paneId}
      readOnly={true}
    />
  ),
};
