import React, { FC } from 'react';
import { FiAward } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';

export const adminUserPermissions = (userId) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.adminUserDetails.path}/${userId}`;

export interface Props {
  id: number;
  firstName: string;
  lastName: string;
  account: {
    name: string;
  };
  email: string;
  isAdmin?: boolean;
  realmRoles: { name: string }[];
  attributes?: {
    isTechnical: string[];
  };
}

const User: FC<Props> = ({
  id,
  firstName,
  lastName,
  account,
  email,
  realmRoles,
  attributes,
}) => {
  const isTechnical = (attributes?.isTechnical || [''])[0] === 'true';
  if (isTechnical) {
    return (
      <div className={'ct-row'}>
        <div className={'ct-col ct-col-200px'} style={{ color: '#9f9f9f' }}>
          [Technical User]
        </div>
      </div>
    );
  }
  return (
    <Link
      data-testid={`user-${email}`}
      to={adminUserPermissions(id)}
      style={{ textDecoration: 'none' }}
    >
      <div className={'ct-row'}>
        <div data-testid={'UserFirstName'} className={'ct-col ct-col-200px'}>
          {firstName}
        </div>
        <div data-testid={'UserLastName'} className={'ct-col ct-col-200px'}>
          {lastName}
        </div>
        <div data-testid={'UserEmail'} className={'ct-col ct-col-200px'}>
          {email}
        </div>
        <div className={'ct-col ct-col-120px ct-col-admin'}>
          {
            // TODO: this should probably be configurable (and maybe use the id...)
            realmRoles.find((role) => role.name === 'altasigma-admin') && (
              <FiAward className='workbench-icon' size='16px' />
            )
          }
        </div>
      </div>
    </Link>
  );
};
export default User;
