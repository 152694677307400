import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from '../styles.module.scss';

type Props = {
  id?: string;
  gridColumn: number;
  gridRow: number;
  detailKey?: string;
  detailValue?: string;
};

const DetailValue: FC<Props> = (props: Props) => {
  const { gridRow, gridColumn, detailKey, detailValue, id } = props;
  return (
    <div
      id={id}
      className={styles.detailValue}
      style={{
        gridRow,
        gridColumn,
      }}
    >
      {detailKey ? (
        <div className={styles.detailValueKey}>{detailKey}</div>
      ) : (
        <Skeleton className={styles.detailValueKey} style={{ width: '40px' }} />
      )}
      <div className={styles.detailValueSpacer} />
      {detailValue ? (
        <div className={styles.detailValueValue}>{detailValue}</div>
      ) : (
        <Skeleton
          className={styles.detailValueValue}
          style={{ width: '60px' }}
        />
      )}
    </div>
  );
};

export default DetailValue;
