import classNames from 'classnames';
import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import Button, { ButtonProps } from '../../atoms/button/Button';
import IntlWrapper from '../../atoms/intl-wrapper/IntlWrapper';
import { DeIntl } from '../../atoms/intl-wrapper/type';

export type Props = {
  /** Additional class name for the main container component */
  additionalClassname?: string;
  /** Headline of the wizard */
  headline?: string | MessageDescriptor;
  /** Specs for the buttons to render */
  buttons: ButtonProps[];
  /** Optional description for the entire wizard. */
  description?: string | MessageDescriptor;
};

/**
 * Parent component for all Wizards.
 * It's supposed to be used as a (in the best case: direct) child of a MainContainer
 */
const Wizard: FC<DeIntl<Props>> = ({
  additionalClassname,
  children,
  headline,
  buttons,
  description,
}) => {
  return (
    <div className={classNames(styles.container, additionalClassname)}>
      <div className={styles.innerContainer}>
        {headline && <span className={styles.headline}>{headline}</span>}
        {description && <div className={styles.description}>description</div>}
        {children}
        <div className={styles.buttons}>
          {buttons.map((buttonArgs) => (
            <Button {...buttonArgs} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const IntlWizard: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={Wizard} props={props} />;
};

export default IntlWizard;
