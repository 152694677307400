import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { ConfigFormComponentProps } from '../../../common/utils';
import { ConfigTextInputLineConfig, TextInputLineConfig } from './type';

const TextInputLineConfigFormComponent: FC<
  ConfigFormComponentProps<TextInputLineConfig>
> = ({ configValue, onConfigChange }) => {
  const { control, getValues } = useForm<ConfigTextInputLineConfig>({
    defaultValues: configValue,
  });

  return (
    <Controller
      name='placeholder.value'
      control={control}
      render={({ field }) => (
        <IntlTextInputLine
          label={augurElementsMessages.placeholder}
          placeholder={augurElementsMessages.placeholder}
          {...field}
          onChange={(e) => {
            field.onChange(e);
            onConfigChange(getValues());
          }}
        />
      )}
    />
  );
};

export default TextInputLineConfigFormComponent;
