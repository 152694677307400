import { BiographyType } from 'common/dist/types/augurBiography';
import qs from 'qs';

import { apiRequest, CompletedApiRequest } from '../../core/api/_tools';

export function fetchAugurBiography(
  augurCode: string,
  offset?: number,
  limit?: number,
  jobTypes?: ('prediction' | 'learning' | 'evaluation')[],
  excludeErrors?: boolean
): CompletedApiRequest<BiographyType> {
  const query = qs.stringify(
    {
      offset,
      limit,
      jobTypes: jobTypes?.join(','),
      excludeErrors,
    },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/augurs/${augurCode}/biography${query}`);
}
