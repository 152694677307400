import React, { Component } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  circle: {
    r: number;
    cx: number;
    cy: number;
  };
  isActive?: boolean;
  isSelected?: boolean;
  isLeaf: boolean;
}

export default class NodeShape extends Component<Props> {
  static defaultProps = {
    className: '',
    isActive: false,
  };

  render() {
    const { circle, className, isActive, isSelected, isLeaf } = this.props;

    const nodeClassName = classNames(`node-shape ${className}`, {
      active: isActive,
      selected: isSelected,
    });

    let element = null;
    if (isLeaf) {
      element = (
        <rect
          className={nodeClassName}
          x={circle.cx - circle.r}
          y={circle.cy - circle.r}
          width={circle.r * 2}
          height={circle.r * 2}
        />
      );
    } else {
      element = <circle className={nodeClassName} {...circle} />;
    }
    return element;
  }
}
