import { connect } from 'react-redux';
import FileStep1 from './FileStep1';
import { fieldName, formName } from './file.form';

function safeFileName(state) {
  if (!state.form[formName]) return '';
  if (!state.form[formName].values) return '';
  return state.form[formName].values[fieldName];
}

function safeFileNameTouched(state) {
  if (!state.form[formName]) return false;
  if (!state.form[formName].fields) return false;
  if (!state.form[formName].fields[fieldName]) return false;
  return state.form[formName].fields[fieldName].touched;
}

function safeFileNameError(state) {
  if (!state.form[formName]) return undefined;
  if (!state.form[formName].syncErrors) return undefined;
  if (!state.form[formName].syncErrors[fieldName]) return undefined;
  return state.form[formName].syncErrors[fieldName].message;
}

export function mapStateToProps(state) {
  return {
    fileName: safeFileName(state),
    fileNameTouched: safeFileNameTouched(state),
    fileNameError: safeFileNameError(state),
  };
}

export default connect(mapStateToProps)(FileStep1);
