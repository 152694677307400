import * as React from 'react';
import { FC, PropsWithChildren } from 'react';

import {
  buttonBarButtons,
  contextMenuEntries,
  dnd,
  icons,
  onClickListeners,
} from './WorkbenchFileBrowser.defaults';
import { Result } from '../../../../utils';
import GenericFileBrowser, {
  ContentElement,
} from '../generic-file-browser/GenericFileBrowser';

export type Props = {
  content: Result<ContentElement[], string>;
  path: string[];
  width: number;
};

const WorkbenchFileBrowser: FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <GenericFileBrowser
      content={props.content}
      buttons={buttonBarButtons}
      onClickListeners={onClickListeners}
      contextMenuEntries={contextMenuEntries}
      dnd={dnd}
      icons={icons}
      width={props.width}
      showFileSizes
      path={props.path}
    />
  );
};

export default WorkbenchFileBrowser;
