import { DeepPartial } from 'react-hook-form';
import { Config } from '../../../../../pages/augur/type';
import { CassandraKeyspaceSelectAugurSettings } from '../../cassandraKeyspaceSelect/v1/type';
import { CassandraTableSelectAugurSettings } from '../../cassandraTableSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { CassandraTableColumnSelectErrorType } from './CassandraTableColumnSelect';
import { CassandraTableColumnSelectV1AugurSettings as CassandraTableColumnSelectAugurSettings } from 'common/dist/types/augurSettings/cassandraTableColumnSelectV1AugurSettings';

export type { CassandraTableColumnSelectAugurSettings };

export type CassandraTableColumnSelectConfig = {
  keyspace: CassandraKeyspaceSelectAugurSettings;
  table: CassandraTableSelectAugurSettings;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
};
export type ConfigCassandraTableColumnSelectConfig =
  Config<CassandraTableColumnSelectConfig>;

export type PartialCassandraTableColumnSelectAugurSettings =
  CassandraTableColumnSelectAugurSettings;

export type CassandraTableColumnSelectValidationError =
  DeepPartial<CassandraTableColumnSelectErrorType>;

export const configAllowedInputsCassandraTableColumnSelect: AllowedInputs<CassandraTableColumnSelectConfig> =
  {
    keyspace: [
      { type: SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT, version: 'v1' },
    ],
    table: [
      { type: SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_SELECT, version: 'v1' },
    ],
  };

export const configTypeCassandraTableColumnSelect = `{ 
  keyspace: { source: 'input-element', elementUuid: string } | 
      { source: 'hard-coded', value: {
          dataSourceCode: string,
          keyspaceName: string
      }},
  table: { source: 'input-element', elementUuid: string } | 
      { source: 'hard-coded', value: string },
  isMulti?: {source: 'hard-coded', value: boolean },
  isClearable?: {source: 'hard-coded', value: boolean },
  isSearchable?: {source: 'hard-coded', value: boolean },
}`;

export const augurSettingsTypeCassandraTableColumnSelect = `string[]`;

export const defaultConfigCassandraTableColumnSelect: ConfigCassandraTableColumnSelectConfig =
  {
    keyspace: { source: 'input-element', elementUuid: '' },
    table: { source: 'input-element', elementUuid: '' },
    isMulti: { source: 'hard-coded', value: false },
    isClearable: { source: 'hard-coded', value: true },
    isSearchable: { source: 'hard-coded', value: true },
  };

export const defaultAugurSettingsDefaultCassandraTableColumnSelect: PartialCassandraTableColumnSelectAugurSettings =
  [];

// JSONSchemaType<CassandraTableColumnSelectAugurSettings>
export const defaultValidationSchemaCassandraTableColumnSelect = {};
