import classNames from 'classnames';
import { CommunicationGroup } from 'common/dist/types/module.optimization';
import React, { FC, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import CommunicationGroupsTable from './CommunicationGroupsTable';
import {
  CampaignOptimizationCommunicationGroupsAugurSettings,
  CampaignOptimizationCommunicationGroupsConfig,
  CampaignOptimizationCommunicationGroupsValidationError,
} from './type';
import InputError from '../../../../../atoms/input-error/InputError';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationCommunicationGroupsAugurSettings,
  CampaignOptimizationCommunicationGroupsConfig,
  CampaignOptimizationCommunicationGroupsValidationError
>;

export type CommunicationGroupsErrorType = {
  global: string;
  rows: {
    [constraintId: string]: {
      name?: string;
      description?: string;
      communicationIds?: string;
    };
  };
};

const CampaignOptimizationCommunicationGroups: FC<Props> = (props) => {
  const {
    config,
    value = [],
    onChange,
    onBlur,
    error,
    invalid,
    readOnly,
  } = props;
  const { communications = [] } = config;

  const [addedNewRow, setAddedNewRow] = useState(false);

  const handleChange = (updatedCommunicationGroups: CommunicationGroup[]) => {
    onChange?.(updatedCommunicationGroups);
  };

  // Update communicationGroups if the config changes
  useEffect(() => {
    const updatedCommunicationGroups = value.map((communicationGroup) => {
      const updatedCommunicationGroup = { ...communicationGroup };

      // Filter out invalid communicationIds
      updatedCommunicationGroup.communicationIds =
        communicationGroup.communicationIds.filter((communicationId) =>
          communications.some(
            (communication) => communication.id === communicationId
          )
        );

      return updatedCommunicationGroup;
    });

    handleChange(updatedCommunicationGroups);
  }, [communications]);
  const handleAddRow = () => {
    if (!readOnly) {
      handleChange([
        ...value,
        { id: uuidv4(), name: '', communicationIds: [] },
      ]);
      setAddedNewRow(true);
    }
  };
  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && <InputError touched={true} error={error.global} />}
      </div>

      <div className={commonStyles.tableContainer}>
        <CommunicationGroupsTable
          communications={communications || []}
          addedNewRow={addedNewRow}
          rowIndex={value ? value?.length - 1 : 0}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          invalid={invalid}
          error={error}
          readOnly={readOnly}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={classNames(commonStyles.AddIcon, {
            [commonStyles.readOnly]: readOnly,
          })}
          onClick={handleAddRow}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationCommunicationGroups;
