import React, { FC, ReactChild, ReactChildren, useState } from 'react';

import SideMenu, { MenuEntryType } from './SideMenu';
import './styles.scss';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

export type Props = {
  /** List of entries for the menu */
  menuEntries: MenuEntryType[];
  /** Which of the tabs is selected? …*/
  selectedTab: string;
  /**
   * Handle the selection of an entry
   * @deprecated Just use the menuEntry .to
   * */
  handleSelect?: (selectedTab: string, query?: string) => void;
  /** Child components */
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
  collapsable?: boolean;
  preserveWhitelistQueryOnLink?: string[];
};

const WithSidemenu: FC<Props> = ({
  menuEntries,
  selectedTab,
  handleSelect,
  children,
  collapsable = false,
  preserveWhitelistQueryOnLink = [],
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className={'WithSidemenu'}>
      {expanded && (
        <SideMenu
          menuEntries={menuEntries}
          selectedTab={selectedTab}
          handleSelect={handleSelect}
          collapsable={collapsable}
          isExpanded={expanded}
          setExpanded={() => setExpanded(false)}
          preserveWhitelistQueryOnLink={preserveWhitelistQueryOnLink}
        />
      )}
      <ErrorBoundary
        errorDescription={{
          id: 'error.sidemenu.description',
        }}
      >
        <div className={'WithSidemenu--content'}>
          {!expanded && (
            <BrowserCollapseFlap
              isExpanded={expanded}
              onClick={() => setExpanded(true)}
            />
          )}
          {children}
        </div>
      </ErrorBoundary>
    </div>
  );
};
export default WithSidemenu;
