import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BiographyType } from 'common/dist/types/augurBiography';
import { ApiError } from 'common/dist/types/responseBodies/errors';

import { fetchAugurBiography as fetchAugurBiographyApi } from './api';

export type AugurBiographyState = {
  loaded?: boolean;
  loading?: boolean;
  data?: BiographyType;
  error?: ApiError;
};

export const initial: AugurBiographyState = {};
export const test: AugurBiographyState = {};

export const fetchAugurBiography = createAsyncThunk<
  { data: BiographyType },
  { augurCode: string; offset?: number; limit?: number },
  { rejectValue: { error: ApiError } }
>('fetchAugurBiography', async ({ augurCode, offset, limit }, thunkAPI) => {
  const { response, error } = await fetchAugurBiographyApi(
    augurCode,
    offset,
    limit
  );

  if (response) {
    return { data: response };
  } else {
    return thunkAPI.rejectWithValue({ error });
  }
});

const slice = createSlice({
  name: 'augurBiography',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAugurBiography.pending, (state, { payload, meta }) => {
      state.loaded = false;
      state.loading = true;
    });
    builder.addCase(
      fetchAugurBiography.fulfilled,
      (state, { payload, meta }) => {
        const { data } = payload;
        state.data = data;
        state.loading = false;
        state.loaded = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchAugurBiography.rejected,
      (state, { payload, meta }) => {
        const { error } = payload;
        state.error = error;
        state.loading = false;
        state.loaded = false;
      }
    );
  },
});

const reducer = slice.reducer;
export { reducer as augurBiographyReducer };
