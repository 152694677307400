import React, { FC } from 'react';

import { SharedInputProps } from '../../common/utils';
import { ReportElementProps } from '../types/meta';

/**
 * Extracts the reportData and config props and passes them to the given generic report component.
 * @param InnerComponent the actual report component.
 */
export function buildSingleReportWrapper<
  TReportData,
  TConfig extends Record<string, unknown>
>(InnerComponent: FC<TReportData & TConfig & SharedInputProps>) {
  return function SingleReportWrapper(
    props: ReportElementProps<TReportData, TConfig>
  ) {
    const {
      input: { reportValue },
      config,
      ...rest
    } = props;
    return <InnerComponent {...reportValue} {...config} {...rest} />;
  };
}
