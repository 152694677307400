import { push } from 'connected-react-router';
import { createAction } from 'redux-act';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { findAndAdjustOpenedNotebookPath } from './notebook.module';
import { RECYCLE_BIN_PATH } from '../../../components/workbench/part-right/config';
import * as ContentApi from '../../../core/api/workbench/content';
import { contentKeys } from '../../../core/api/workbench/content';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import { workbenchRoutes } from '../../../workbench/common/workbenchRoutes';
import { invalidateQueries } from '../../modules/reactQuery.module';
import { notebookUser } from '../selectors/notebookUser.selector';

export const setOpenContextMenu = createAction('open context menu', (path) => ({
  path,
}));

export const renameContent = createAction(
  'rename content',
  (oldPath, newPath) => ({ oldPath, newPath })
);

export const renameContentSuccess = createAction(
  'rename content - success',
  (success) => success
);

export const renameContentFail = createAction(
  'rename content - fail',
  (error) => error
);

export const showDeleteContent = createAction(
  'show delete confirm',
  (path, type, permanently = false) => ({ path, type, permanently })
);

export const restoreContent = createAction(
  'restore content',
  (name, targetPath) => ({ name, targetPath })
);

export const restoreContentSuccess = createAction(
  'restore content - success',
  (targetPath) => ({ targetPath })
);

export const restoreContentFailure = createAction(
  'restore content - failure',
  (targetPath, error) => ({ targetPath, error })
);

export const emptyRecycleBin = createAction('empty recycle bin');

export const emptyRecycleBinSuccess = createAction(
  'empty recycle bin - success'
);

export const emptyRecycleBinFailure = createAction(
  'empty recycle bin - failure',
  () => ({})
);

export const openRemoveModuleArtifactsWizard = createAction(
  'Open remove module artifacts wizard'
);
export const toggleHidden = createAction('toggle hidden files');

export const reducer = {
  [setOpenContextMenu]: (state, { path }) => ({
    ...state,
    content: {
      ...state.content,
      openContextMenu: path,
    },
  }),
  [showDeleteContent]: (state, { path, type, permanently }) => ({
    ...state,
    showDeleteContent: { path, type, permanently },
  }),
  [toggleHidden]: (state) => ({
    ...state,
    settings: {
      ...state.settings,
      showHidden: !state.settings.showHidden,
    },
  }),
};

export function* renameContentSaga({ payload: { oldPath, newPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.renameContent,
    oldPath,
    newPath,
    jupyterUser
  );
  if (response) {
    yield put(renameContentSuccess(response));
    yield put(invalidateQueries(contentKeys.all()));
    yield put(findAndAdjustOpenedNotebookPath(oldPath, newPath));
  } else {
    yield put(renameContentFail(error));
  }
}

export function* watchRenameContent() {
  yield takeEvery(renameContent.getType(), renameContentSaga);
}

export function* restoreContentSaga({ payload: { name, targetPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.restoreContent,
    name,
    targetPath,
    jupyterUser
  );
  if (response) {
    yield put(restoreContentSuccess(targetPath));
    yield put(invalidateQueries(contentKeys.all()));
  } else {
    yield put(restoreContentFailure(targetPath, error));
  }
}

export function* watchRestoreContent() {
  yield takeEvery(restoreContent.getType(), restoreContentSaga);
}

export function* emptyRecycleBinSaga() {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteContent],
    RECYCLE_BIN_PATH,
    true
  );
  if (response) {
    yield put(emptyRecycleBinSuccess());
    // we have to remove the query here as otherwise old data is served (deleting the __recycleBin folder causes in error when fetching)
    yield put(
      invalidateQueries(contentKeys.content(jupyterUser, RECYCLE_BIN_PATH))
    );
  } else {
    yield put(emptyRecycleBinFailure(error));
  }
}

export function* watchEmptyRecycleBin() {
  yield takeEvery(emptyRecycleBin.getType(), emptyRecycleBinSaga);
}

export function* openRemoveModuleArtifactsWizardSaga() {
  try {
    const routerState = yield select((state) => state.router);

    if (!routerState) {
      throw new Error('Router state not found');
    }

    const { location } = routerState;

    // Navigate to new route
    yield put(
      push({
        pathname: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.removeModuleArtifacts}`,
        search: location.search,
      })
    );
  } catch (error) {
    console.error('An error occurred in the saga:', error);
  }
}

export function* watchOpenRemoveModuleArtifactsWizard() {
  yield takeEvery(
    openRemoveModuleArtifactsWizard.getType(),
    openRemoveModuleArtifactsWizardSaga
  );
}
