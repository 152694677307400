import dataManagement from 'common/dist/messages/dataManagement';
import messagesForm from 'common/dist/messages/form';
import React, { Component } from 'react';
import { WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { InjectedFormProps } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';

import {
  ErrorType,
  fieldBucket,
  fieldPath,
  fieldUpload,
  formName,
  UploadWizardS3Data,
} from './form';
import StepBucket from './step-bucket/StepBucket.container';
import StepPath from './step-path/StepPath';
import StepUpload from './step-upload/StepUpload.container';
import { ButtonProps } from '../../../../atoms/button/Button';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import Wizard from '../../../../pages/wizard/Wizard';
import { dataManagementRoutes } from '../../../routes';

export interface Props {
  /** Code of the data source */
  dataSourceCode: string;
  uploadFile: (...args: unknown[]) => void;
  formValues: UploadWizardS3Data;
  [fieldPath]: unknown;
  [fieldUpload]: unknown;
  /** Is the whole form valid? */
  // valid: bool,
  uploading: boolean;
  uploaded: boolean;
  progress: number;
  destroyUploadForm: (...args: unknown[]) => void;
}

export type InnerProps = Props & RouteComponentProps & WrappedComponentProps;
type CombinedProps = InnerProps &
  InjectedFormProps<UploadWizardS3Data, InnerProps, ErrorType>;
export default class WizardUpload extends Component<CombinedProps> {
  render() {
    const {
      dataSourceCode,
      uploadFile,
      formValues,
      valid,
      uploading,
      uploaded,
      destroyUploadForm,
      handleSubmit,
      asyncValidating,
    } = this.props;

    const uploadButtonDisabled =
      !valid || Boolean(asyncValidating) || uploading || uploaded;
    const buttons: ButtonProps[] = [
      {
        label: messagesForm.cancel,
        color: 'white',
        linkTo: `${dataManagementRoutes.basePath}/s3/${dataSourceCode}`,
        onClick: () => destroyUploadForm(),
      },
      {
        label: dataManagement.msgS3UploadConfirm,
        color: 'secondary',
        disabled: uploadButtonDisabled,
        isBusy: Boolean(asyncValidating) || uploading,
        onClick: () => {
          // Replace the '-' from the uuid for nicer HTTP requests later (the uuid is send in GET requests and needs to be part of the URL)
          const uploadCode = uuidv4().replaceAll('-', '');
          !uploadButtonDisabled &&
            formValues.upload &&
            handleSubmit(() =>
              uploadFile(
                formValues.upload.file,
                {
                  bucket: formValues[fieldBucket],
                  path: formValues[fieldPath],
                },
                uploadCode, // Generate the uploadCode that will be used to identify the file in the next "ping-pong" requests
                // between the File-Management-API and the Dashboard
                dataSourceCode
              )
            )();
        },
      },
    ];
    return (
      <Wizard
        headline={dataManagement.msgS3UploadHeader}
        buttons={buttons}
        additionalClassname={'wizard-parent-upload'}
      >
        <GenericFormStep
          fieldName={fieldBucket}
          formName={formName}
          // @ts-ignore
          component={StepBucket}
          title={dataManagement.msgS3UploadBucketTitle}
          description={dataManagement.msgS3UploadBucketDescription}
          num={1}
          fieldProps={{
            dataSourceCode,
          }}
        />

        <GenericFormStep
          fieldName={fieldPath}
          formName={formName}
          component={StepPath}
          renderError={false}
          title={dataManagement.msgS3UploadPathTitle}
          description={dataManagement.msgS3UploadPathDescription}
          num={2}
        />

        <GenericFormStep
          fieldName={fieldUpload}
          formName={formName}
          // @ts-ignore
          component={StepUpload}
          title={dataManagement.msgS3UploadUploadTitle}
          description={dataManagement.msgS3UploadUploadDescription}
          num={3}
          fieldProps={{
            dataSourceCode,
          }}
        />
      </Wizard>
    );
  }
}
