import React, { FC, useState } from 'react';
import { Cron, CronType } from 'react-js-cron';
import { WrappedFieldProps } from 'redux-form';
import 'react-js-cron/dist/styles.css';
import './antstyles.scss';

import styles from './styles.module.scss';

// Info: The Cron component from 'react-js-cron' has no parameter to edit the inputs via text from the keybord
const CronScheduleSelect: FC<WrappedFieldProps> = (props) => {
  const {
    input: { value, onChange },
  } = props;

  const currentCron = value.trigCron || '0 * * * *';
  const [cronExpression, setCronExpression] = useState(currentCron);

  // Options change based on user choice
  // user can only select one of month-days or week-days
  const [, , dayOfTheMonth, , dayOfTheWeek] = cronExpression.split(' ');

  const allowedDropdowns: CronType[] = [
    'period',
    'months',
    ...(dayOfTheMonth !== '*' ? [] : ['week-days']),
    ...(dayOfTheWeek !== '*' ? [] : ['month-days']),
    'hours',
    'minutes',
  ] as CronType[];

  return (
    <div className={styles.cronContainer}>
      <Cron
        allowedDropdowns={allowedDropdowns}
        clearButton={false}
        value={cronExpression}
        setValue={(cronString) => {
          onChange({ trigger: value.trigger, trigCron: cronString });
          setCronExpression(cronString);
        }}
        leadingZero={true}
        clockFormat='24-hour-clock'
      />
    </div>
  );
};

export default CronScheduleSelect;
