import Ajv from 'ajv';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

const ajv = new Ajv();

export type GlobalErrorType = {
  global: string;
};

export function validateBySchema(validationSchema) {
  return (value: unknown) => {
    const validate = ajv.compile(validationSchema);
    const error: DeepPartial<GlobalErrorType> = {};

    if (!validate(value)) {
      error.global = ajv.errorsText(validate.errors);
    }

    return cleanErrorObject(error);
  };
}
