import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  convertSpace,
  getOperatingSystemFactor,
} from 'common/dist/utils/storage';
import React, { Component } from 'react';

import styles from './styles.module.scss';

export type Props = {
  /** Fetch the information about the disk usage */
  getDiskUsage: () => void;
  /** Is the disk usage data loading? */
  loading: boolean;
  /** Is the disk usage data loaded? */
  loaded: boolean;
  /** Was there an error while loading the disk usage data? */
  error?: ToBeRefined;
  /** Data about the disk usage */
  data?: {
    /** Total available disk in bytes */
    total: number;
    /** Total used disk in bytes */
    used: number;
    /** Total free disk in bytes */
    free: number;
  };
};

export default class DiskUsage extends Component<Props, {}> {
  componentDidMount() {
    const { getDiskUsage } = this.props;
    getDiskUsage();
  }

  renderLoaded() {
    const { data } = this.props;
    const factor = getOperatingSystemFactor();
    const unit = factor === 1000 ? 'GB' : 'GiB';
    const usedFormatted = convertSpace(data?.used);
    const totalFormatted = convertSpace(data?.total);
    const message = `Used ${usedFormatted} ${unit} of ${totalFormatted} ${unit}`;

    return (
      <div className={styles.diskUsage}>
        <span className={styles.title}>Disk Usage</span>
        <span className={styles.usage}>{message}</span>
      </div>
    );
  }

  renderLoading() {
    return <div className={styles.diskUsage}>Loading ...</div>;
  }

  renderError() {
    const { error } = this.props;
    return <div className={styles.diskUsage}>{JSON.stringify(error)};</div>;
  }

  render() {
    const { loading, error, data } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (data) return this.renderLoaded();
    else return null;
  }
}
