import {
  PerformanceDriftV1ReportData as PerformanceDriftReportData,
  PerformanceDriftValueFormat,
  PerformanceDriftData,
} from 'common/dist/types/reportData/performanceDriftV1ReportData';

import { Config } from '../../../../../pages/augur/type';
export type {
  PerformanceDriftReportData,
  PerformanceDriftValueFormat,
  PerformanceDriftData,
};

export type PerformanceDriftConfig = {};

export type ConfigPerformanceDriftConfig = Config<PerformanceDriftConfig>;

export const configTypePerformanceDrift = `{}`;

export const reportDataTypePerformanceDrift = `{
  data: { 
    [label: string]: {
      value: number;
      format: Formats;
    }
  }
}`;

export const defaultConfigPerformanceDrift: ConfigPerformanceDriftConfig = {};

export const defaultReportDataDefaultPerformanceDrift: PerformanceDriftReportData =
  {
    Accuracy: { value: 0.7, format: 'percentage' },
    RoC: { value: 0.8, format: 'decimal' },
    'False Positives': { value: 281, format: 'integer' },
    'False Negatives': { value: 394, format: 'integer' },
  };
