import {
  AUGUR_JOBS,
  JOB_TYPE,
  JobType,
  JobTypeToSpeaking,
  K8sResources,
} from 'common/dist/types/job';
import { GeneralConfiguration } from 'common/dist/types/moduleVersion';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { AugurResources } from './types';
import { useNodeInfo } from '../../../../core/api/codeCapsules';
import { K8sResourcesSelect } from '../../../molecules/k8s-resources-select/K8sResourcesSelect';
import { validateK8sResources } from '../../../molecules/k8s-resources-select/validate';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';
import Busy from '../../busy/Busy';
import { ButtonProps } from '../../button/Button';

export type Props = {
  supportedJobs: Pick<
    GeneralConfiguration,
    | 'supportsLearning'
    | 'supportsEvaluation'
    | 'supportsPrediction'
    | 'supportsRealtimePrediction'
  >;
  resources: AugurResources;
  onResourcesChange: (value: AugurResources) => void;
};

export const ResourceInput: FC<Props> = (props) => {
  const { supportedJobs, resources, onResourcesChange } = props;

  const [activeJobTab, setActiveJobTab] = useState<JobType | ''>('');

  function isCategorySupported(category: string): boolean {
    switch (category) {
      case 'learning':
        return supportedJobs.supportsLearning;
      case 'evaluation':
        return supportedJobs.supportsEvaluation;
      case 'prediction':
        return supportedJobs.supportsPrediction;
      case 'realtimePrediction':
        return supportedJobs.supportsRealtimePrediction;
      default:
        return false;
    }
  }

  useEffect(() => {
    if (_.isEmpty(activeJobTab) && supportedJobs.supportsLearning) {
      setActiveJobTab(JOB_TYPE.LEARNING);
    }
  }, [activeJobTab, supportedJobs.supportsLearning]);

  // If the user is currently on a tab and disables the supports flag for exactly this tab he will be sent to the first tab
  if (
    (!supportedJobs.supportsRealtimePrediction &&
      activeJobTab === 'realtimePrediction') ||
    (!supportedJobs.supportsPrediction && activeJobTab === 'prediction') ||
    (!supportedJobs.supportsEvaluation && activeJobTab === 'evaluation')
  ) {
    setActiveJobTab(AUGUR_JOBS?.[0]);
  }

  //Create the buttons for the tabs to show
  const buttons = AUGUR_JOBS.map((category) => {
    return {
      id: category,
      label: JobTypeToSpeaking[category],
      onClick: () => {
        setActiveJobTab(category);
      },
      disabled: !isCategorySupported(category),
    } satisfies ButtonProps;
  });
  const { data: nodeInfo, isLoading } = useNodeInfo();
  if (!nodeInfo) return <Busy isBusy={isLoading} />;
  return !supportedJobs.supportsLearning ? (
    <div>No resource configuration available.</div>
  ) : (
    <div>
      <TabLineBubbles
        design={'tabs'}
        buttons={buttons}
        activeButton={activeJobTab}
      />
      <div className={styles.ModelSettingsCategory}>
        {activeJobTab &&
          AUGUR_JOBS.map((category, key) => {
            return (
              <div
                key={key}
                style={{
                  display: activeJobTab === category ? 'block' : 'none',
                }}
              >
                <K8sResourcesSelect
                  nodeInfo={nodeInfo}
                  headerLabel={`${JobTypeToSpeaking[category]} Resources`}
                  activeCategory={category}
                  value={resources?.[category]}
                  onChange={(value: K8sResources) => {
                    const updatedResources = { ...(resources || {}) };
                    if (value && Object.keys(value).length > 0) {
                      updatedResources[category] = value;
                    } else {
                      delete updatedResources[category];
                    }

                    onResourcesChange(updatedResources);
                  }}
                  validateResources={validateK8sResources}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ResourceInput;
