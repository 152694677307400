import React, { FC } from 'react';
import { FiDatabase, FiKey } from 'react-icons/fi';
import { Route, Switch, useLocation } from 'react-router-dom';

import Authentication from './authentication/Authentication';
import Keyspaces from './keyspaces/Keyspaces.container';
import TabLineBubbles from '../../molecules/tab-line-bubbles/TabLineBubbles';
import { dataManagementRoutes } from '../routes';

export type Props = {
  code: string;
};
const Tabs: FC<Props> = (props) => {
  const urlParts = useLocation().pathname.split('/');
  const activeTab = `${props.code}-${urlParts[urlParts.length - 1]}`;
  return (
    <div className={'data-source-body-tabs'}>
      <div className={'data-source-body-tabs-line'}>
        <TabLineBubbles
          buttons={[
            {
              id: `${props.code}-${dataManagementRoutes.keyspaces}`,
              linkTo: `${dataManagementRoutes.basePath}/cassandra/${props.code}/${dataManagementRoutes.keyspaces}`,
              label: {
                id: 'datapools.cassandra.keyspaces',
                defaultMessage: 'Keyspaces',
              },
              Icon: () => <FiDatabase size={16} />,
            },
            {
              id: `${props.code}-${dataManagementRoutes.auth}`,
              linkTo: `${dataManagementRoutes.basePath}/cassandra/${props.code}/${dataManagementRoutes.auth}`,
              label: {
                id: 'datapools.cassandra.auth',
                defaultMessage: 'Authentication',
              },
              Icon: () => <FiKey size={16} />,
            },
          ]}
          activeButton={activeTab}
        />
      </div>
      <div className={'data-source-body-tabs-content'}>
        <Switch>
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/cassandra/${props.code}/${dataManagementRoutes.keyspaces}`}
          >
            <Keyspaces dataSourceCode={props.code} />
          </Route>
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/cassandra/${props.code}/${dataManagementRoutes.auth}`}
          >
            <Authentication dataSourceCode={props.code} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Tabs;
