import React, { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { useAugur } from '../../../../core/api/augurs';
import { AugurDetailsRouteParams } from '../../../index/routes';
import DetailValue from '../detail-value/DetailValue';

const DetailValues: FC = () => {
  const { augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isLoading } = useAugur(augurCode);

  if (isLoading) {
    return (
      <>
        <DetailValue detailKey={'Type'} gridColumn={2} gridRow={1} />
        {<DetailValue gridColumn={2} gridRow={2} />}
        {<DetailValue gridColumn={3} gridRow={1} />}
        {<DetailValue gridColumn={3} gridRow={2} />}
      </>
    );
  }

  const detailValues = Object.entries(augur?.healthInfo || {})
    .sort((a, b) => (a[0] < b[0] ? -1 : 1))
    .slice(0, 3);

  const renderInfos = () => {
    return (
      <>
        {detailValues[0] && (
          <DetailValue
            detailKey={detailValues[0][0]}
            detailValue={detailValues[0][1]}
            gridColumn={2}
            gridRow={2}
          />
        )}
        {detailValues[1] && (
          <DetailValue
            detailKey={detailValues[1][0]}
            detailValue={detailValues[1][1]}
            gridColumn={3}
            gridRow={1}
          />
        )}
        {detailValues[2] && (
          <DetailValue
            detailKey={detailValues[2][0]}
            detailValue={detailValues[2][1]}
            gridColumn={3}
            gridRow={2}
          />
        )}
      </>
    );
  };

  return (
    <Fragment>
      <DetailValue
        detailKey={'Type'}
        detailValue={augur.moduleVersion.module.name}
        gridColumn={2}
        gridRow={1}
      />
      {renderInfos()}
    </Fragment>
  );
};

export default DetailValues;
