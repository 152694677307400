import { createAction } from 'redux-act';

export const hideDeleteHabitatConfirm = createAction(
  'hide delete data source confirm'
);

export const showDeleteHabitatConfirm = createAction(
  'show delete data source confirm'
);

export const reducer = {
  [hideDeleteHabitatConfirm]: (state) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      showDeleteHabitatConfirm: false,
    },
  }),
  [showDeleteHabitatConfirm]: (state) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      showDeleteHabitatConfirm: true,
    },
  }),
};
