import { Config } from '../../../../../pages/augur/type';
import {
  FeatureImportanceV1ReportData as FeatureImportanceReportData,
  Feature,
} from 'common/dist/types/reportData/featureImportanceV1ReportData';

export type { FeatureImportanceReportData, Feature };

export type FeatureImportanceConfig = {};

export type ConfigFeatureImportanceConfig = Config<FeatureImportanceConfig>;

export const configTypeFeatureImportance = `{}`;

export const reportDataTypeFeatureImportance = `{
    features: {
        feature: string;
        importance: number;
        featureType: "numerical" | "categorical";
        discarded? : string[];
    }[],
    featureImportanceCalculatedWith: string;
}`;

export const defaultConfigFeatureImportance: ConfigFeatureImportanceConfig = {};

export const defaultReportDataDefaultFeatureImportance: FeatureImportanceReportData =
  {
    featureImportanceCalculatedWith: 'random-forest',
    features: [
      {
        feature: 'Contract',
        importance: 0.9763572402452959,
        featureType: 'categorical',
      },
      {
        feature: 'tenure',
        importance: 0.6582056739250167,
        featureType: 'numerical',
      },
      {
        feature: 'InternetService',
        importance: 0.5325014662025842,
        featureType: 'categorical',
      },
      {
        feature: 'PaymentMethod',
        importance: 0.3859264420527464,
        featureType: 'categorical',
      },
      {
        feature: 'TotalCharges',
        importance: 0.2857624025525912,
        featureType: 'numerical',
        discarded: ['Too many missing'],
      },
      {
        feature: 'OnlineSecurity',
        importance: 0.2359561347654832,
        featureType: 'categorical',
        discarded: ['Too many categorical values'],
      },
    ],
  };
