import { connect, ConnectedProps } from 'react-redux';

import MergeRequests from './MergeRequests';
import { loadMergeRequests } from '../../../../redux/workbench/modules/collab.module';
import { DeprecatedRootState } from '../../../../store/state.type';

// @ts-ignore

export enum MergeRequestOrdering {
  LastCreated,
  OldestCreated,
  NameAsc,
  NameDesc,
  CreatedByAsc,
  CreatedByDesc,
}

export const mergeRequestOrderings = [
  { label: 'Last Created', value: MergeRequestOrdering.LastCreated },
  { label: 'Oldest Created', value: MergeRequestOrdering.OldestCreated },
  { label: 'Name A-Z', value: MergeRequestOrdering.NameAsc },
  { label: 'Name Z-A', value: MergeRequestOrdering.NameDesc },
  { label: 'Created by A-Z', value: MergeRequestOrdering.CreatedByAsc },
  { label: 'Created by Z-A', value: MergeRequestOrdering.CreatedByDesc },
];

export type MergeRequest = {
  /** Timestamp of when the MergeRequest has been created */
  created_at: string;
  /** ID of the MergeRequest */
  id: number;
  /** Title of the Merge Request */
  title: string;
  /** Name of the user that opened the MergeRequest */
  user: {
    full_name: string;
  };
};

// Props passed from "outside" into the component
export type OwnProps = {
  group?: string;
  repositoryName?: string;
  moduleCode?: string;
};

export type Props = OwnProps & ConnectedProps<typeof connector>;

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    mergeRequests:
      ((state.workbench.collab || {}).mergeRequests || {}).data || [],
  };
}

export const mapDispatchToProps = {
  loadMergeRequests,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MergeRequests);
