import { AugurResources } from 'common/dist/types/augurSettings';
import { ModuleConfiguration as BackendModuleConfiguration } from 'common/dist/types/moduleVersion';
import { AugurReport } from 'common/dist/types/reports';
import { Layout } from 'react-grid-layout';

import { ElementVersions } from '../../molecules/augur-layout-elements/common/utils';
import {
  AugurReportElementMeta,
  ReportElementMetas,
} from '../../molecules/augur-layout-elements/report-elements/types/meta';
import {
  REPORT_ELEMENT_TYPES,
  ReportElementTypes,
} from '../../molecules/augur-layout-elements/report-elements/types/type';
import {
  AugurSettingsElementMeta,
  SettingsElementMetas,
} from '../../molecules/augur-layout-elements/settings-elements/types/meta';
import {
  SETTINGS_ELEMENT_TYPES,
  SettingsElementTypes,
} from '../../molecules/augur-layout-elements/settings-elements/types/type';
import { AugurMenuIcons } from '../../molecules/augur-menu/icons';
import { AUGUR_CATEGORY } from '../../molecules/augur-menu/types';

/**
 * File that contains the general settings, the module layout and module settings
 * This file can be missing entirely. Will be added automatically after entering edit mode in the dev augur
 * configuring settings and saving them. Can also be added via templates.
 */
export interface ModuleConfiguration
  extends Omit<
    BackendModuleConfiguration,
    'augurSettingsConfiguration' | 'augurReportConfiguration'
  > {
  // Augur Settings Configuration
  augurSettingsConfiguration: AugurSettingsPage[];

  // Augur Reports Configuration
  augurReportConfiguration: {
    [AUGUR_CATEGORY.LEARNING]: AugurReportPage[];
    [AUGUR_CATEGORY.EVALUATION]: AugurReportPage[];
    [AUGUR_CATEGORY.PREDICTION]: AugurReportPage[];
  };
}

/**
 * This is an interface for a generic Augur page that contains a DragAndDropEditor.
 */
export interface AugurLayoutPage {
  uuid: string;
  title: string;
  description?: string;
  iconId: keyof typeof AugurMenuIcons;
  elementArrangement: Layout[];
}

/**
 * Augur settings specific DragAndDropEditor page.
 */
export interface AugurSettingsPage extends AugurLayoutPage {
  elements: AugurSettingsElement[];
}

/**
 * Augur report specific DragAndDropEditor page.
 */
export interface AugurReportPage extends AugurLayoutPage {
  elements: AugurReportElement[];
}

/**
 * A single config entry.
 * Is given a generic type T that corresponds to a field in a ConfigType of a layout element.
 */
export type ConfigEntry<T> =
  | {
      source: 'hard-coded';
      value: T;
    }
  | {
      source: 'input-element';
      elementUuid: string;
    };
/**
 * Describes the non-resolved config of a layout element.
 */
export type Config<T> = {
  [F in keyof T]: undefined extends T[F]
    ? ConfigEntry<Exclude<T[F], undefined>> | undefined
    : ConfigEntry<T[F]>;
};

/**
 * Extracts the ConfigType of a given LayoutElementType.
 */
export type LayoutElementConfigType<TLayoutElementType> =
  TLayoutElementType extends SettingsElementTypes
    ? SettingsElementMetas[TLayoutElementType][keyof SettingsElementMetas[TLayoutElementType]] extends AugurSettingsElementMeta<
        unknown,
        infer TConfigType
      >
      ? TConfigType
      : Record<string, unknown>
    : TLayoutElementType extends ReportElementTypes
    ? ReportElementMetas[TLayoutElementType][keyof ReportElementMetas[TLayoutElementType]] extends AugurReportElementMeta<
        Record<string, unknown>,
        infer TConfigType
      >
      ? TConfigType
      : Record<string, unknown>
    : never;

/**
 * A layout element that can be used for the DragAndDropEditor.
 */
export interface LayoutElement<
  TLayoutElementType extends SettingsElementTypes | ReportElementTypes =
    | SettingsElementTypes
    | ReportElementTypes
> {
  //These are editable by the user
  title: string;
  description?: string;
  config?: Config<LayoutElementConfigType<TLayoutElementType>>;

  //Set after drop event
  uuid: string;
  type: TLayoutElementType;
  version: ElementVersions<TLayoutElementType>;
}

export interface AugurReportElement<
  E extends ReportElementTypes = ReportElementTypes
> extends LayoutElement<E> {
  reportKey?: string;
}

export interface AugurSettingsElement<
  E extends SettingsElementTypes = SettingsElementTypes
> extends LayoutElement<E> {
  //These are editable by the user
  defaultAugurSettings?: Partial<
    SettingsElementMetas[E][keyof SettingsElementMetas[E]] extends AugurSettingsElementMeta<
      infer TSettingsType
    >
      ? TSettingsType
      : never
  >;
  settingsKey?: string;
}

export function isAugurReportsType(
  type: ReportElementTypes | SettingsElementTypes
): type is ReportElementTypes {
  return Object.values(REPORT_ELEMENT_TYPES).includes(
    type as ReportElementTypes
  );
}

export function isAugurReportsElement(
  element: Pick<LayoutElement, 'type'>
): element is AugurReportElement {
  return isAugurReportsType(element.type);
}

export function isAugurSettingsType(
  type: ReportElementTypes | SettingsElementTypes
): type is SettingsElementTypes {
  return Object.values(SETTINGS_ELEMENT_TYPES).includes(
    type as SettingsElementTypes
  );
}

export function isAugurSettingsElement(
  element: Pick<LayoutElement, 'type'>
): element is AugurSettingsElement {
  return isAugurSettingsType(element.type);
}

/**
 * Transforms a LayoutElement to its resolved type.
 * config: Config<TConfigType> -> TConfigType
 */
type TransformLayoutElement<TLayoutElement> =
  TLayoutElement extends LayoutElement<infer TLayoutElementType>
    ? Omit<TLayoutElement, 'config'> & {
        config: LayoutElementConfigType<TLayoutElementType>;
      }
    : TLayoutElement;

/**
 * Type of AugurReportsElement after the transformation.
 */
export type AugurReportElementProps<
  E extends ReportElementTypes = ReportElementTypes
> = TransformLayoutElement<AugurReportElement<E>> & {
  reportEntries: AugurReportEntry[];
};

/**
 * Type of AugurSettingsElement after the transformation.
 */
export type AugurSettingsElementProps<
  E extends SettingsElementTypes = SettingsElementTypes
> = TransformLayoutElement<AugurSettingsElement<E>> & {
  settingsEntry?: {
    settingsValue: unknown;
  };
};

export type AugurReportEntry<TReportData = Record<string, unknown>> = Omit<
  AugurReport<TReportData>,
  'reportData'
> & {
  reportValue: TReportData;
};

export type ModuleSelection = {
  moduleCode: string;
  moduleVersionCode: string;
};

export interface GeneralAugurSettings {
  augurName: string;
  module: ModuleSelection;
  attributes: Record<string, string>;
  resources: AugurResources;
}

/* This type includes both the input values of the conventional Augur Settings and the inputs of the General Augur Settings */
export interface AugurSettingsWithAugurProperties<TSettingsData = unknown> {
  general: GeneralAugurSettings;
  settingsData: Record<string, TSettingsData>;
}
