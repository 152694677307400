import classNames from 'classnames';
import React, { ComponentType, FocusEventHandler } from 'react';
import {
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import Select, { GroupBase } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import {
  OnChangeValue,
  OptionsOrGroups,
  PropsValue,
} from 'react-select/dist/declarations/src/types';

import { customStyles } from './customStyles';
import DefaultOptionComponent from './DefaultOptionComponent';
import styles from './styles.module.scss';
import InputError from '../../input-error/InputError';

export type Option = {
  label: string;
  value: string;
};

export type Props<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  id?: string;
  name?: string;
  containerClassName?: string;
  className?: string;
  appearance?: 'regular' | 'small' | 'multiLines';
  isMulti?: IsMulti;
  isClearable?: boolean;
  searchable?: boolean;
  scrollMenuIntoView?: boolean;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  options: OptionsOrGroups<Option, Group>;
  customComponents?: {
    Option?: ComponentType<OptionProps<Option, IsMulti, Group>>;
  };
  disabled?: boolean;
  onChange?: (newValue: OnChangeValue<Option, IsMulti>) => void;
  value?: PropsValue<Option>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  menuPortalTarget?: HTMLElement | null;
  error?: FieldError | string;
  isTouched?: boolean;
  menuPosition?: 'fixed' | 'absolute';
};

export const DropdownSelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  const {
    id,
    name,
    label,
    placeholder,
    className,
    isLoading,
    options,
    isClearable,
    searchable,
    isMulti,
    scrollMenuIntoView,
    containerClassName,
    customComponents,
    disabled,
    value,
    onChange,
    onBlur,
    appearance = 'regular',
    menuPortalTarget,
    error,
    isTouched,
    menuPosition = 'fixed',
  } = props;
  return (
    <div
      className={classNames(styles.dropdownSelectInput, containerClassName, {
        [styles.error]: error,
      })}
      style={{
        overflow: 'visible',
      }}
    >
      <div className={styles.header}>
        {label && <p className={styles.label}>{label}</p>}
        {error && (
          <div className={styles.error}>
            <InputError touched={Boolean(isTouched)} error={error} />
          </div>
        )}
      </div>

      <Select
        data-testid={`dropdown-select-${name}`}
        id={id}
        name={name}
        className={className}
        //@ts-ignore
        styles={customStyles<Option, IsMulti>(appearance)}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={searchable}
        isLoading={isLoading}
        isDisabled={disabled}
        menuShouldScrollIntoView={scrollMenuIntoView}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={(newValue, actionMeta) => {
          onChange?.(newValue);
        }}
        onBlur={onBlur}
        components={{
          ...(customComponents || {}),
          //@ts-ignore - TODO how to type the DefaultOptionComponent?
          Option: customComponents?.Option || DefaultOptionComponent,
        }}
        menuPortalTarget={menuPortalTarget}
        menuPosition={menuPosition}
      />
    </div>
  );
};

type useHookType = {
  rules?: RegisterOptions<FieldValues, string>;
};
//Specify explicitly which property has to be required
type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export type WrappedDropdownSelectInputProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = WithRequiredProperty<Props<Option, IsMulti, Group>, 'name'> & useHookType;

export const WrappedRHFDropdownSelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: WrappedDropdownSelectInputProps<Option, IsMulti, Group>
) => {
  const { name, rules } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState, formState }) => {
        const { ref, ...rest } = field; // extract ref to pass as inputRef
        return (
          <DropdownSelectInput
            {...props}
            {...rest}
            {...fieldState}
            {...formState}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

// DON'T ADD DEFAULT EXPORTS; IT'S TOO CONFUSING
