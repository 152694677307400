import qs from 'qs';
import React, { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

// TODO: could add support for LocationDescriptor objects if necessary

export const LinkWithQuery: FC<Omit<LinkProps, 'to'> & { to: string }> = ({
  children,
  to,
  ...props
}) => {
  let { search } = useLocation();
  // Avoid duplicate query parameters from search and to by combining them
  const split = to.split('?');
  const query = split[1];
  if (query) {
    to = split[0];
    const existingParams = qs.parse(query);
    if (existingParams) {
      search = qs.stringify(
        {
          ...qs.parse(search, { ignoreQueryPrefix: true }),
          ...existingParams,
        },
        { addQueryPrefix: true }
      );
    }
  }

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};
