import GroupRepresentation from '@keycloak/keycloak-admin-client/lib/defs/groupRepresentation';
import classNames from 'classnames';
import { habitatFromGroupName } from 'common/dist/constants/keycloak';
import _ from 'lodash';
import React, { FC, useEffect } from 'react';

import { GroupPermission } from './ResourcePermissions';
import styles from './styles.module.scss';
import { fetchGroupPermissions } from '../../../../redux/modules/admin.users.module';
import { NamesState } from '../../../../store/names/state.types';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

type Props = {
  resourceName: string;
  scopes: {
    id: string;
    displayName: string;
  }[];
  resourceType: string;
  groupsData: GroupRepresentation[];
  groupsLoading?: boolean;
  loadGroups: (fetchPermissions: boolean) => void;
  fetchNamesForAdminPermission: () => void;
  addGroupPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    group: GroupRepresentation,
    refreshAction: () => void
  ) => void;
  removeGroupPermission: (
    resourceName: string,
    scopeName: string,
    group: GroupRepresentation,
    refreshAction: () => void
  ) => void;
  groupPermissions?: GroupPermission;
  names: NamesState;
};

const Habitats: FC<Props> = ({
  groupsData,
  groupsLoading,
  resourceName,
  resourceType,
  addGroupPermission,
  removeGroupPermission,
  scopes,
  names,
  groupPermissions,
  loadGroups,
  fetchNamesForAdminPermission,
}) => {
  useEffect(() => {
    loadGroups(true);
    fetchNamesForAdminPermission();
  }, [loadGroups, fetchNamesForAdminPermission]);

  const hasGroupPermission = (groupId: string, scopeName: string): boolean => {
    if (!groupPermissions) return false;

    const groupPermission = groupPermissions[groupId];
    if (!groupPermission || !groupPermission.data) return false;

    const resourceData = groupPermission.data[resourceName];
    if (!resourceData) return false;

    return resourceData.some((item) => item.name === scopeName);
  };

  const habitats = groupsData.filter((g) =>
    _.isEqual(g.attributes.permissions, ['edit'])
  );

  const renderHeadline = () => (
    <div className={'ct-row ct-headline'}>
      <div className={'ct-col ct-col-flex-grow'}>
        <span>Group Name</span>
      </div>
      {scopes.map((scope) => (
        <div key={scope.id} className={'ct-col ct-col-80px'}>
          <span>{scope.displayName}</span>
        </div>
      ))}
    </div>
  );

  if ((!groupsData || groupsData.length === 0) && groupsLoading) {
    return (
      <div className={'groups-permissions-container ct-list'}>
        {renderHeadline()}
        {Array.from({ length: 10 }).map((_, i) => (
          <div key={i} className={'ct-row'}>
            <div className={'ct-col ct-col-200px'}>
              <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={'groups-permissions-container ct-list'}>
      {renderHeadline()}
      {habitats
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((group) => {
          const speakingName =
            names.habitatNames[habitatFromGroupName(group.name)];

          if (!speakingName) {
            return (
              <div
                key={group.id}
                className={classNames('ct-row', styles.permissionsRow)}
              >
                <div className={'ct-col ct-col-flex-grow-200px'}>
                  <span style={{ fontStyle: 'italic' }}>
                    {'<Unknown Habitat>'}
                  </span>
                </div>
              </div>
            );
          }

            return (
              <div
                key={group.id}
                data-testid={`${speakingName}`}
                className={classNames('ct-row', styles.permissionsRow)}
              >
                <div className={'ct-col ct-col-flex-grow-200px'}>
                  <span>{speakingName}</span>
                </div>

              {scopes.map((scope) => (
                <div
                  key={`${group.id}_${scope.id}`}
                  className={'ct-col ct-col-80px'}
                >
                  <ThemedSwitch
                    onChange={() => {
                      if (hasGroupPermission(group.id, scope.id)) {
                        removeGroupPermission(
                          resourceName,
                          scope.id,
                          group,
                          () => fetchGroupPermissions(group.id)
                        );
                      } else {
                        addGroupPermission(
                          resourceName,
                          resourceType,
                          scope.id,
                          group,
                          () => fetchGroupPermissions(group.id)
                        );
                      }
                    }}
                    checked={hasGroupPermission(group.id, scope.id)}
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    themeColor={'primary'}
                    isLoading={groupPermissions?.[group.id]?.loading}
                  />
                </div>
              ))}
            </div>
          );
        })}
    </div>
  );
};

export default Habitats;
