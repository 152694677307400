import commonMessages from 'common/dist/messages/common';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';

import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';

export type Props = {
  emptyRecycleBin: () => void;
};

const EmptyRecycleBinWizard: FC<Props> = ({ emptyRecycleBin }) => {
  const recycleBinPath = `${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}`;

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>Empty Recycle Bin</p>
        <div className={'step-container'}>
          <div className={'description-container'}>
            <div className={'bubble bubble-delete'}>
              <FiX className={'icon-x'} size={'20px'} />
            </div>
            <div className={'description'}>
              <div className={'title'}>
                <p>Are you sure you want to empty the Recycle Bin?</p>
              </div>
            </div>
          </div>
        </div>
        <div className={'dialog-button-container'}>
          <Button
            label={commonMessages.cancel}
            color='white'
            linkTo={recycleBinPath}
          />

          <Button
            label='Empty Recycle Bin'
            onClick={emptyRecycleBin}
            linkTo={recycleBinPath}
            color='red'
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyRecycleBinWizard;
