/**
 * Derive the file ending from a file name (or file path works too)
 * @param fileName
 */
export function getFileEnding(fileName: string): string {
  if (!fileName) return '';
  const parts = fileName.split('.');
  if (parts.length === 0) return '';
  else return parts[parts.length - 1];
}

/**
 * Converts a path array into its string representation.
 * Example: [ 'abc', 'xyz' ] -> '/abc/xyz'
 * @param path path in array representation, [] represents root path
 * @param precedingSlash flag whether to add a preceding slash.
 */
export const contentArrayToPath = (
  path: string[],
  precedingSlash = true
): string => `${precedingSlash ? '/' : ''}${path.join('/')}`;

/**
 * Convert a string path into its array representation.
 * Example: '/abc/xyz' -> [ 'abc', 'xyz' ]
 * @param path path in string representation, '' or '/' represents root
 */
export const contentPathToArray = (path: string): string[] =>
  path.split('/').filter((s) => s);
