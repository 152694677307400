import { ReactNode } from 'react';

export type GridLayoutElement = {
  id: string;
  element: ReactNode;
  error?: string;
};

export type TileSizeRestriction = {
  minH: number;
  minW: number;
  maxH?: number;
  maxW?: number;
};

export type GridLayoutElementTransferData = {
  payload: GridLayoutElementPayload;
} & TileSizeRestriction;

export type GridLayoutElementPayload = {
  type: string;
  version: string;
  title?: string;
  category: PayloadCategory;
};

export const PAYLOAD_CATEGORY = {
  REPORT: 'report',
  SETTINGS: 'settings',
  NONE: 'none',
} as const;
export type PayloadCategory =
  typeof PAYLOAD_CATEGORY[keyof typeof PAYLOAD_CATEGORY];

export function isDragEvent(event: unknown): event is DragEvent {
  return (event as DragEvent).dataTransfer !== undefined;
}

export function isGridLayoutElementTransferData(
  transferData: unknown
): transferData is GridLayoutElementTransferData {
  const data = transferData as GridLayoutElementTransferData;
  return (
    data.payload !== undefined &&
    data.payload.type !== undefined &&
    data.payload.version !== undefined &&
    data.minH !== undefined &&
    data.minW !== undefined
  );
}
