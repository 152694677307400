import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import TreePathNode from './TreePathNode';
import { BinaryTreeState } from './BinaryTreeShadowModel';

interface Props {
  state: BinaryTreeState;
}

export type Path = {
  id: string | number;
  type: string;
  label: string;
  recordCount: string;
  operation: string;
  operands: string[];
  unary: boolean;
  depth: number;
  positive: boolean;
  negative: boolean;
}[];

const TreePath: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { state } = props;
  const path: Path = (state.previewNodePath.length && state.previewNodePath) || state.selectedNodePath;

  if (path.length <= 1) return null;
  return (
    <div className={styles.selectionInfoContainer}>
      <div className={styles.selectionInfoTitle}>Decisions</div>
      {path.slice(1).map((node) => (
        <TreePathNode key={node.id} node={node} />
      ))}
    </div>
  );
};

export default TreePath;
