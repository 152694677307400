import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { TabContent } from 'react-tabs-redux';

import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import ModelManagement from '../../pages/model-management/ModelManagement.container';

export default class TabContentModelMan extends Component {
  state = {
    redirect: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedMainTab !== this.props.selectedMainTab &&
      this.props.selectedMainTab === 'models'
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.state.redirect) {
        this.setState({ redirect: false });
      }
    }
  }
  render() {
    const { match } = this.props;
    const isVisible = 'models' === match.params.mainTab;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/app/models' />;
    }
    return (
      <TabContent
        className={'main-tab-content'}
        for={'models'}
        isVisible={isVisible}
        renderActiveTabContentOnly={true}
        style={{
          overflowY: 'hidden', // to prevent programmatic scrolling for example by the Dropdown-Select component
        }}
      >
        <ErrorBoundary
          errorDescription={{
            id: 'error.tab.description',
          }}
          fullScreenHeight={true}
        >
          <ModelManagement />
        </ErrorBoundary>
      </TabContent>
    );
  }
}
TabContentModelMan.propTypes = {
  match: PropTypes.object,
  selectedMainTab: PropTypes.string,
};
