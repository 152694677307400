import React, { Component } from 'react';
import { FiEdit, FiFile, FiFolder } from 'react-icons/fi';

interface RepoContentElementProps {
  /** Type of the content element:  file / dir / symlink / submodule */
  type: string;
  /** Name of the content element */
  name: string;
  /** Path of the content element */
  path: string;
  /** sha of the content element */
  sha: string;
  setSelectedFile?: (selectedFile: string) => void;
  setSelectedPath?: (selectedPath: string) => void;
}

export default class RepositoryContentElement extends Component<RepoContentElementProps> {
  render() {
    const { type, name, path, sha, setSelectedFile, setSelectedPath } =
      this.props;
    return (
      <div className={'ct-row'}>
        <div className={'ct-col ct-col-40px'}>
          {type === 'dir' && (
            <FiFolder className={'content-element-icon'} size={'20px'} />
          )}
          {type === 'file' &&
            (name && (name.endsWith('.ipynb') || name.endsWith('.asapp')) ? (
              <FiEdit className={'content-element-icon'} size={'20px'} />
            ) : (
              <FiFile className={'content-element-icon'} size={'20px'} />
            ))}
        </div>
        <div
          className={'ct-col ct-col-flex-grow'}
          onClick={() => {
            if (type === 'dir') return setSelectedPath(`/${path}`);
            setSelectedFile(path);
          }}
        >
          <span>{name}</span>
        </div>
      </div>
    );
  }
}
