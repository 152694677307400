/**
 * Combination of phase and container status like kubectl shows it.
 * May not be exhaustive.
 */
export const K8S_POD_STATUS = {
  // Phases
  PENDING: 'Pending',
  RUNNING: 'Running',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  // Container Statuses for waiting.reason and terminated.reason
  CRASH_LOOP_BACKOFF: 'CrashLoopBackoff',
  // If it was deleted
  TERMINATING: 'Terminating',
} as const;

export type K8sPodStatus = (typeof K8S_POD_STATUS)[keyof typeof K8S_POD_STATUS];

export interface RealtimeSummary {
  augurCode: string;
  modelCode: string;
  servingType: string;
  started: string;
  status: K8sPodStatus;
}
