import React, { Component, ComponentProps, ComponentType } from 'react';
import { FiCheck } from 'react-icons/fi';
import { Field } from 'redux-form';
import './step.scss';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

export type Props<Value, Error> = {
  /** Name of the form this Field belongs to */
  formName: string;
  /** Name of this field (redux-form) */
  fieldName: string;

  /** Number of this step */
  num: number;
  /** Default title of the step (= headline) */
  title: React.ComponentProps<typeof FormattedMessage>;
  /** Default description of the step */
  description: React.ComponentProps<typeof FormattedMessage>;

  /** Render error, or is this provided by the component itself? */
  renderError?: boolean;

  /** Validation function for the form field */
  validate?: (value: Value) => Error;
  /** Component for the form field */
  component: ComponentType;

  /** Optional, additional props that will be passed down to the Field Component */
  fieldProps?: ComponentProps<ComponentType>;
  /** Put the component with full width underneath the title / description instead of next to it? Default: false */
  putComponentBelow?: boolean;
};

function errorMessage<Error>(
  touched: boolean,
  error: Error | React.ComponentProps<typeof FormattedMessage>
) {
  if (touched && error) {
    if (typeof error === 'string') {
      return <div className={'error'}>{error}</div>;
    } else if ((error as React.ComponentProps<typeof FormattedMessage>).id) {
      return (
        <FormattedMessage
          // @ts-ignore className actually works, maybe the types are not up to date
          className={'error'}
          id={(error as React.ComponentProps<typeof FormattedMessage>).id}
          defaultMessage={
            (error as React.ComponentProps<typeof FormattedMessage>)
              .defaultMessage
          }
        >
          {(text) => <div className={'error'}>{text}</div>}
        </FormattedMessage>
      );
    } else {
      return <p className={'error'}>{JSON.stringify(error)}</p>;
    }
  }
}

export default class GenericFormStep<Value, Error> extends Component<
  Props<Value, Error>
> {
  static defaultProps = {
    renderError: true,
    fieldProps: {},
    putComponentBelow: false,
  };

  render() {
    const {
      fieldName,
      validate,
      component,
      num,
      title,
      description,
      renderError,
      fieldProps,
      putComponentBelow,
    } = this.props;
    return (
      <Field
        name={fieldName}
        label={fieldName}
        validate={validate}
        // @ts-ignore the manually typed input and meta fields in StepComponentProps can't match WrappedFieldProps
        component={StepComponentIntl}
        InputComponent={component}
        num={num}
        title={title}
        description={description}
        renderError={renderError}
        putComponentBelow={putComponentBelow}
        {...fieldProps}
      />
    );
  }
}

type StepComponentProps<Value, Error> = {
  InputComponent: ComponentType<Props<Value, Error>>;
  meta: {
    touched: boolean;
    valid: boolean;
    error: Error;
  };
  input: {
    value: Value;
  };
} & WrappedComponentProps &
  Props<Value, Error>;

const StepComponentIntl = injectIntl(StepComponent);

function StepComponent<Value, Error>(props: StepComponentProps<Value, Error>) {
  const {
    meta: { touched, error },
    InputComponent,
    num,
    description,
    title,
    renderError,
    putComponentBelow,
  } = props;

  return (
    <div
      className={
        'GenericFormStep' +
        (putComponentBelow ? ' GenericFormStep--component-below' : '')
      }
      data-testid={title.defaultMessage}
    >
      <div className={'description-container'}>
        {(() => {
          if (touched && error) {
            return (
              <div className={'bubble bubble-delete'}>
                <p>{num}</p>
              </div>
            );
          } else if (touched) {
            return (
              <div className={'bubble bubble-ok'}>
                <FiCheck className={'checked-icon'} size={'20px'} />
              </div>
            );
          } else {
            return (
              <div className={'bubble bubble-info'}>
                <p>{num}</p>
              </div>
            );
          }
        })()}
        <div className={'description'}>
          <div className={'title'}>
            <FormattedMessage
              id={title.id || '?'}
              defaultMessage={title.defaultMessage}
              values={title.values}
            />
          </div>
          <div className={'text'}>
            <FormattedMessage
              id={description.id || '?'}
              defaultMessage={description.defaultMessage}
              values={description.values}
            />
          </div>
        </div>
      </div>
      <div
        className={`GenericFormStep--input-container${
          renderError && touched && error ? ' input-container-error' : ''
        }`}
      >
        {renderError && (
          <div className={'error-container'}>
            {errorMessage<Error>(touched, error)}
          </div>
        )}

        <InputComponent {...props} />
      </div>
    </div>
  );
}
