import React, { Component } from 'react';
import SvgPlain from './icons/SvgPlain';
import SvgCodeCapsule from './icons/SvgCodeCapsule';
import SvgApp from './icons/SvgApp';
import { REPO_TYPE } from 'common/dist/types/repository';

interface RepositoryTypeCardProps {
  name: string;
  displayName: string;
  onChange(...args: unknown[]): unknown;
  /** Selected repository type */
  selectedRepositoryType?: string;
  disabled?: boolean;
}

// import SvgPython from './icons/SvgPython';;

export default class RepositoryTypeCard extends Component<RepositoryTypeCardProps> {
  icon() {
    switch (this.props.name) {
      case REPO_TYPE.PLAIN: {
        return <SvgPlain className={'icon'} />;
      }
      case REPO_TYPE.CODE_CAPSULE: {
        return <SvgCodeCapsule className={'icon'} />;
      }
      case REPO_TYPE.APP: {
        return <SvgApp className={'icon'} />;
      }
      default:
        return null;
    }
  }

  render() {
    const { selectedRepositoryType, onChange, name, displayName, disabled } =
      this.props;
    return (
      <div
        className={`repository-type-card ${
          selectedRepositoryType === name ? ' selected' : ''
        } ${
          selectedRepositoryType && selectedRepositoryType !== name
            ? ' unselected'
            : ''
        } ${!disabled ? ' selectable' : ''}`}
        onClick={() => {
          if (!disabled) {
            onChange(name);
          }
        }}
      >
        <div className={'icon-container'}>{this.icon()}</div>
        <p className={'display-name'}>{displayName}</p>
      </div>
    );
  }
}
