import { createAction } from 'redux-act';

import { call, put, take, takeEvery, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import * as Api from '../../core/api';
import keycloak from '../../../keycloak';
import { invalidateQueries } from './reactQuery.module';
import { userSummaryKeys } from '../../core/api/users';

export const fetchAvatar = createAction('fetch avatar');

export const fetchAvatarSuccess = createAction(
  'fetch avatar - success',
  (data) => ({ data })
);

export const fetchAvatarFail = createAction('fetch avatar - fail', (error) => ({
  error,
}));

export const deleteAvatar = createAction('delete avatar');

export const deleteAvatarSuccess = createAction('delete avatar - success');

export const deleteAvatarFail = createAction('delete avatar - fail');

export const uploadAvatar = createAction('upload avatar', (file, meta) => ({
  file,
  meta,
}));

export const uploadAvatarProgress = createAction(
  'upload avatar - progress',
  (progress) => ({ progress })
);

export const uploadAvatarSuccess = createAction('upload avatar - success');

export const uploadAvatarFail = createAction(
  'upload avatar - fail',
  (error) => ({ error })
);

export const reducer = {
  [fetchAvatar]: (state) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        loading: true,
      },
    },
  }),
  [fetchAvatarSuccess]: (state, { data }) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        loading: false,
        loaded: true,
        error: undefined,
        data,
      },
    },
  }),
  [fetchAvatarFail]: (state, { error }) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),

  [deleteAvatar]: (state) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        data: undefined,
      },
    },
  }),

  [uploadAvatar]: (state, { file, meta }) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        uploading: true,
        uploaded: false,
        progress: 0,
      },
    },
  }),
  [uploadAvatarProgress]: (state, { progress }) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        uploading: true,
        progress,
      },
    },
  }),
  [uploadAvatarSuccess]: (state) => ({
    ...state,
    account: {
      ...state.account,
      photo: {
        ...state.account.photo,
        uploading: false,
        progress: 100,
        uploaded: true,
      },
    },
  }),
  [uploadAvatarFail]: (state, { error }) => ({
    ...state,
    uploading: false,
    progress: 100,
    uploaded: true,
    uploadError: error,
  }),
};

export function* uploadAvatarSaga({ payload: { file, meta } }) {
  const channel = yield call(uploadAvatarAsForm, file, meta);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* watchUploadAvatar() {
  yield takeEvery(uploadAvatar.getType(), uploadAvatarSaga);
}

/**
 * Starts the upload stream to the Dasboard API
 * @param file
 * @param meta
 * @returns {Generator<*, *, *>}
 */
function uploadAvatarAsForm(file, meta) {
  return eventChannel((emitter) => {
    const formData = new FormData();
    formData.append('meta', JSON.stringify(meta));
    formData.append('file', file);

    const http = axios.create();
    http
      .post('/api/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${keycloak.token}`,
        },
        onUploadProgress: (e) => {
          const progress = Math.round((100 * e.loaded) / e.total);
          // --- still uploading
          emitter(uploadAvatarProgress(progress));
        },
      })
      .then(() => {
        // --- done
        emitter(uploadAvatarSuccess());
      })
      .catch((e) => {
        emitter(uploadAvatarFail(e));
      });

    return () => {};
  });
}

export function* uploadAvatarSuccessSaga() {
  yield put(fetchAvatar());
  const userId = yield select((state) => state.currentUser.id);
  yield put(invalidateQueries(userSummaryKeys.userSummary(userId)));
}

export function* watchUploadAvatarSuccess() {
  yield takeEvery(uploadAvatarSuccess.getType(), uploadAvatarSuccessSaga);
}

export function* fetchAvatarSaga() {
  const { response, error } = yield call(Api.accounts.fetchPhoto);
  if (response) {
    yield put(fetchAvatarSuccess(response));
  } else {
    yield put(fetchAvatarFail(error));
  }
}

export function* watchFetchAvatar() {
  yield takeEvery(fetchAvatar.getType(), fetchAvatarSaga);
}

export function* deleteAvatarSaga() {
  const { response, error } = yield call(Api.accounts.deletePhoto);
  if (response) {
    yield put(deleteAvatarSuccess(response));
    yield put(fetchAvatar());
    const userId = yield select((state) => state.currentUser.id);
    yield put(invalidateQueries(userSummaryKeys.userSummary(userId)));
  } else {
    yield put(deleteAvatarFail(error));
  }
}

export function* watchDeleteAvatar() {
  yield takeEvery(deleteAvatar.getType(), deleteAvatarSaga);
}
