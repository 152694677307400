import { Config } from '../../../../../pages/augur/type';
import { S3BucketSelectAugurSettings } from '../../s3BucketSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { S3FileSelectV1AugurSettings as S3FileSelectAugurSettings } from 'common/dist/types/augurSettings/s3FileSelectV1AugurSettings';
export type { S3FileSelectAugurSettings };

export type S3FileSelectConfig = {
  s3Bucket: S3BucketSelectAugurSettings;
  fileSuffix?: string;
  showTable?: boolean;
};
export type ConfigS3FileSelectConfig = Config<S3FileSelectConfig>;

export type PartialS3FileSelectAugurSettings = S3FileSelectAugurSettings; // Partial<S3FileSelectAugurSettings>;

export const configAllowedInputsS3FileSelect: AllowedInputs<S3FileSelectConfig> =
  {
    s3Bucket: [
      { type: SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT, version: 'v1' },
    ],
    fileSuffix: [],
  };

export const configTypeS3FileSelect = `{ 
  fileSuffix:  { source: 'hard-coded', value: string },
  s3Bucket: { source: 'input-element', elementUuid: string } |
      { source: 'hard-coded', value: {
              dataSourceCode: string,
              bucketName: string
      }},
  showTable?:  { source: 'hard-coded', value: boolean },
}`;

export const augurSettingsTypeS3FileSelect = `string`;

export const defaultConfigS3FileSelect: ConfigS3FileSelectConfig = {
  s3Bucket: { source: 'input-element', elementUuid: '' },
  showTable: { source: 'hard-coded', value: true },
};

export const defaultAugurSettingsDefaultS3FileSelect: PartialS3FileSelectAugurSettings =
  '';

// JSONSchemaType<S3FileSelectAugurSettings>
export const defaultValidationSchemaS3FileSelect = {
  type: 'string',
};
