import classNames from 'classnames';
import React, { FC } from 'react';

import { RejectedFeaturesCassandraAugurSettings } from './type';
import RejectAutoForceToggle from '../../../../../atoms/reject-auto-force-toggle/RejectAutoForceToggle';
import styles from '../../../report-elements/feature-importance/v1/single/styles.module.scss';

export type Props = {
  colName: string;
  value: RejectedFeaturesCassandraAugurSettings;
  onChange?: (updatedFeatures: RejectedFeaturesCassandraAugurSettings) => void;
  readOnly?: boolean;
};

const InfluencerBar: FC<Props> = (props: Props) => {
  const { colName, value, onChange, readOnly } = props;

  const update = (newValue: 'rejected' | 'auto' | 'forced') => {
    if (readOnly) return;

    const updatedValue: RejectedFeaturesCassandraAugurSettings = {
      rejected: [...value.rejected],
      enforced: [...value.enforced],
    };

    // ... (rest of the update function remains the same)

    onChange?.(updatedValue);
  };

  return (
    <div
      className={classNames(styles.featureRow, { [styles.readOnly]: readOnly })}
    >
      <div className={classNames(styles.featureCell, styles.rejected)}>
        <RejectAutoForceToggle
          value={
            value.rejected.includes(colName)
              ? 'rejected'
              : value.enforced.includes(colName)
              ? 'forced'
              : 'auto'
          }
          onChange={update}
        />
      </div>

      <div className={classNames(styles.featureCell, styles.feature)}>
        <span className={classNames('feature')}>{colName}</span>
      </div>
    </div>
  );
};

export default InfluencerBar;
