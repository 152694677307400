import { JOB_STATUS } from 'common/dist/constants/enums';
import {
  AugurJobsWithoutRealtime,
  DashboardJob,
  isAugurJobWithoutRealtime,
} from 'common/dist/types/job';
import { AugurReport } from 'common/dist/types/reports';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useTimeTravel } from './hooks';
import TimeTravel from './TimeTravel';
import { useSettings, useSettingsHistory } from '../../../core/api/augurs';
import {
  useInfiniteJobs,
  useLatestModelJob,
  useTimeTravelJobsSelection,
} from '../../../core/api/jobs';
import { useActiveModel, useModels } from '../../../core/api/mlModels';
import { useInfiniteModuleFiles } from '../../../core/api/modules';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import { AugurCategory } from '../../molecules/augur-menu/types';
import { useSelectedDirPath } from '../../workbench/hooks';

export type Props = {
  selectedPageCategory?: AugurCategory;
  isDev?: boolean;
};

function isJob(
  job: AugurReport | DashboardJob | null | undefined
): job is DashboardJob {
  return job !== null && job !== undefined && 'augurSettingsCode' in job;
}

const UnifiedTimeTravel: FC<Props> = ({
  selectedPageCategory,
  isDev = false,
}) => {
  const location = useLocation();
  const { augurCode } = useParams<AugurDetailsRouteParams>();
  const pathPrefix = useRouteMatch().url;

  const { singleSelection } = useTimeTravel();

  const selectedDirPath = useSelectedDirPath();
  const index = isDev
    ? selectedDirPath.findIndex((x) => x.endsWith('.asr'))
    : -1;

  const {
    isLoading: isModuleFilesLoading,
    error: moduleFilesError,
    data: moduleFilesData,
    fetchNextPage: fetchNextFilesPage,
    hasNextPage: hasNextFilesPage,
    isFetchingNextPage: isFetchingNextFilesPage,
  } = useInfiniteModuleFiles(
    isDev ? contentArrayToPath(selectedDirPath.slice(0, index + 1), false) : '',
    isDev && index !== -1
  );

  const { data: activeModel, isLoading: isActiveModelLoading } =
    useActiveModel(augurCode);
  const { data: activeSettings, isLoading: isActiveSettingsLoading } =
    useSettings(augurCode);
  const {
    data: jobsData,
    isLoading: isJobsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteJobs(augurCode, JOB_STATUS.FINISHED, !isDev);
  const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];

  // load latest job for active model in current category in case it was not loaded with sidebar
  const { data: latestModelJob } = useLatestModelJob(
    activeModel?.code,
    selectedPageCategory as AugurJobsWithoutRealtime,
    !isDev && isAugurJobWithoutRealtime(selectedPageCategory) // only enabled if we are on a report page
  );
  // load selected jobs in case the were not loaded with sidebar
  const { data: selectedJobs } = useTimeTravelJobsSelection(!isDev);
  // jobs that were loaded separately from the ones in view in TimeTravel
  const additionalJobs = [...(latestModelJob || []), ...(selectedJobs || [])];
  // add the additional jobs to the jobs data
  additionalJobs.forEach((additionalJob) => {
    if (!jobs.some((job) => job.code === additionalJob.code)) {
      jobs.push(additionalJob);
    }
  });

  const reports = moduleFilesData
    ? moduleFilesData.pages.flatMap((page) => page.info.reports)
    : [];
  const latestAugurReports = moduleFilesData?.pages[0].info.latestReports
    ? Object.values(moduleFilesData?.pages[0].info.latestReports)
    : [];
  if (latestAugurReports) {
    latestAugurReports.forEach((additionalJob) => {
      if (!reports.some((job) => job.jobCode === additionalJob.jobCode)) {
        reports.push(additionalJob);
      }
    });
  }
  const { data: settingsHistory, isLoading: isSettingsHistoryLoading } =
    useSettingsHistory(augurCode);
  const { data: models, isLoading: isModelsLoading } = useModels(augurCode);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if ((!isDev && isJobsLoading) || (isDev && isModuleFilesLoading)) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          console.log('Last element is intersecting. Fetching next page...');
          void fetchNextPage();
        } else if (
          entries[0].isIntersecting &&
          hasNextFilesPage &&
          !isFetchingNextFilesPage
        ) {
          console.log('Last element is intersecting. Fetching next page...');
          void fetchNextFilesPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isJobsLoading,
      isModuleFilesLoading,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      fetchNextFilesPage,
      hasNextFilesPage,
      isFetchingNextFilesPage,
    ]
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const entries = useMemo(() => {
    if (isDev) {
      const reports = moduleFilesData
        ? moduleFilesData.pages.flatMap((page) => page.info.reports)
        : [];
      const latestAugurReports = moduleFilesData?.pages[0].info.latestReports
        ? Object.values(moduleFilesData?.pages[0].info.latestReports)
        : [];
      if (latestAugurReports && Array.isArray(latestAugurReports)) {
        latestAugurReports.forEach((additionalJob) => {
          if (!reports.some((job) => job.jobCode === additionalJob.jobCode)) {
            reports.push(additionalJob);
          }
        });
      }
      return reports.map((report) => ({
        code: report.jobCode,
        title: report.jobCode,
        timestamp: report.createdAt,
        type: report.jobType,
        modelCode: report.modelCode,
        jobCode: report.jobCode,
      }));
    } else {
      const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];
      if (additionalJobs && Array.isArray(additionalJobs)) {
        additionalJobs.forEach((additionalJob) => {
          if (!jobs.some((job) => job.code === additionalJob.code)) {
            jobs.push(additionalJob);
          }
        });
      }
      return jobs.map((job) => ({
        code: job.code,
        type: job.type,
        settingsCode: job.augurSettingsCode,
        modelCode: job.modelCode,
        jobCode: job.code,
        timestamp: job.createdAt,
        title: job.reportCode,
      }));
    }
  }, [isDev, moduleFilesData, jobsData, additionalJobs]);

  const explicitlySelectedJob = useMemo(() => {
    if (!singleSelection) return null;

    if (isDev) {
      const reports = moduleFilesData
        ? moduleFilesData.pages.flatMap((page) => page.info.reports)
        : [];
      return (
        reports.find((report) => report.jobCode === singleSelection) || null
      );
    } else {
      const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];
      return jobs.find((job) => job.code === singleSelection) || null;
    }
  }, [isDev, moduleFilesData, jobsData, singleSelection]);

  const activeModelCode = useMemo(() => {
    if (isDev) {
      const latestAugurReports = moduleFilesData?.pages[0].info.latestReports
        ? Object.values(moduleFilesData?.pages[0].info.latestReports)
        : [];
      return latestAugurReports.find(
        (report) => report.jobType === selectedPageCategory
      )?.modelCode;
    } else {
      return activeModel?.code;
    }
  }, [isDev, moduleFilesData, selectedPageCategory, activeModel]);

  const foundSettings = useMemo(() => {
    if (!explicitlySelectedJob) return undefined;

    return settingsHistory?.find((settings) => {
      if (isJob(explicitlySelectedJob)) {
        return settings.code === explicitlySelectedJob.augurSettingsCode;
      }
      // If it's an AugurReport, we don't have a settingsCode to compare
      return false;
    });
  }, [settingsHistory, explicitlySelectedJob]);

  const memoizedFetchNextPage = useCallback(
    isDev ? fetchNextFilesPage : fetchNextPage,
    [isDev, fetchNextFilesPage, fetchNextPage]
  );

  // Pre-fetch data
  useEffect(() => {
    if (singleSelection && !explicitlySelectedJob) {
      if (isDev) {
        // Trigger fetch for more module files if needed
        void fetchNextFilesPage();
      } else {
        // Trigger fetch for more jobs if needed
        void fetchNextPage();
      }
    }
  }, [
    singleSelection,
    explicitlySelectedJob,
    isDev,
    fetchNextFilesPage,
    fetchNextPage,
  ]);

  const isLoading = useMemo(() => {
    if (isDev) {
      return isModuleFilesLoading;
    } else {
      return (
        isJobsLoading ||
        isActiveModelLoading ||
        isActiveSettingsLoading ||
        isSettingsHistoryLoading ||
        isModelsLoading
      );
    }
  }, [
    isDev,
    isModuleFilesLoading,
    isJobsLoading,
    isActiveModelLoading,
    isActiveSettingsLoading,
    isSettingsHistoryLoading,
    isModelsLoading,
  ]);

  if (isLoading) {
    return <Busy isBusy={true} />;
  }

  if (isDev && !moduleFilesData) return <div>{moduleFilesError}</div>;

  // when in selection mode, check whether the selected page category fits the selected job
  // redirect if this is not the case
  if (
    singleSelection &&
    isAugurJobWithoutRealtime(selectedPageCategory) &&
    selectedPageCategory !==
      (isDev
        ? (explicitlySelectedJob as AugurReport)?.jobType
        : (explicitlySelectedJob as DashboardJob)?.type)
  ) {
    return (
      <Redirect
        to={
          isDev
            ? `/${(explicitlySelectedJob as AugurReport)?.jobType}${
                location.search
              }`
            : `${pathPrefix}/${(explicitlySelectedJob as DashboardJob)?.type}${
                location.search
              }`
        }
      />
    );
  }

  return (
    <TimeTravel
      entries={entries}
      models={isDev ? undefined : models}
      settingsHistory={isDev ? undefined : settingsHistory}
      activeModelCode={activeModelCode}
      selectedPageCategory={selectedPageCategory}
      lastElementRef={lastElementRef}
      fetchNextPage={memoizedFetchNextPage}
    />
  );
};

export default React.memo(UnifiedTimeTravel);
