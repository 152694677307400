import { connect, ConnectedProps } from 'react-redux';

import AugurBiography, { Props } from './AugurBiography';
import { fetchDataSources } from '../../../redux/modules/data.dataSources.module';
import { fetchAugurBiography } from '../../../store/augurBiography/slice';
import { RootState } from '../../../store/store';

export function mapStateToProps(state: RootState, props: Props) {
  const { loading, loaded, error, data } = state.augurBiography || {};

  return {
    loading,
    loaded,
    error,
    data,
    // These do not come from the state, but are specific to this use of the AugurBiography component
    header: 'Augur Biography',
    selectable: false,
  };
}

export const mapDispatchToProps = {
  fetchAugurBiography,
  fetchDataSources: fetchDataSources as () => void,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(AugurBiography);
