/**
 * Converts an array of objects to a map: { key: object }
 * @param arr Array of objects
 * @param key Key of the objects to use as the mapping key
 */
export function arrayToMap(
  arr: Record<string, unknown>[],
  key: string
): { [value: string]: Record<string, unknown> } {
  const map: { [value: string]: Record<string, unknown> } = {};
  arr.forEach((obj: { [objKey: string]: any }) => {
    map[obj[key]] = obj;
  });
  return map;
}
