import { CASSANDRA_KEYSPACE_SCOPES } from 'common/dist/constants/keycloak';
import { Summary } from 'common/dist/types/habitat';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { CassandraPermissions } from '../../../../store/dataManagement/state.types';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import TextInputSearch from '../../../atoms/input-elements/text-input-search/TextInputSearch.container';
import PermissionTable from '../../../organisms/permission-table/PermissionTable';
import { dataManagementRoutes } from '../../routes';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';

export interface Props {
  /** Fetch list of all Keyspaces */
  fetchCassandraKeyspaces: (...args: unknown[]) => unknown;
  fetchCassandraPermissions: (...args: unknown[]) => unknown;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: {
    /** Name of the Keyspace */
    name: string;
  }[];
  /** Code of the data source */
  dataSourceCode: string;
  /** Set the active upload wizard */
  setActiveUploadWizard: (...args: unknown[]) => unknown;
  fetchSummary: (...args: unknown[]) => unknown;
  summary?: Summary;
  permissions?: CassandraPermissions;
  allowedToUpload: boolean;
}

const Keyspaces: React.FC<Props & RouteComponentProps> = ({
  data,
  dataSourceCode,
  error,
  loading,
  loaded,
  setActiveUploadWizard,
  summary,
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
  fetchSummary,
  permissions,
  allowedToUpload,
}) => {
  const [keyspaceSearch, setKeyspaceSearch] = useState('');

  useEffect(() => {
    fetchCassandraKeyspaces(dataSourceCode);
    fetchCassandraPermissions(dataSourceCode);
  }, [fetchCassandraPermissions, fetchCassandraKeyspaces, dataSourceCode]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  if (error) {
    return (
      <div className={'keyspaces'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  if (loading) {
    return <Busy isBusy />;
  }

  return (
    <div className={'keyspaces'}>
      <div className={'keyspaces-header'}>
        <span className={'keyspaces-headline'} />{' '}
        <div style={{ width: '260px' }}>
          <TextInputSearch
            initialValue={keyspaceSearch}
            submitSearchQuery={setKeyspaceSearch}
            submitOnChange
          />
        </div>
        {allowedToUpload && (
          <Button
            color={'secondary'}
            label={'Upload Data'}
            linkTo={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/upload`}
            onClick={() =>
              setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
            }
          />
        )}
      </div>

      {data && (
        <PermissionTable
          title={'Keyspace'}
          permissionColumns={CASSANDRA_KEYSPACE_SCOPES.map(
            (s) => s.displayName
          )}
          rowProps={data
            .filter((keyspace) =>
              keyspaceSearch ? keyspace.name.includes(keyspaceSearch) : true
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((keyspace) => {
              const permissionSet = new Set(permissions.data?.[keyspace.name]);
              return {
                title: keyspace.name,
                displayTitle: keyspace.name,
                withLink: true,
                linkTo: `${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/keyspace/${keyspace.name}`,
                permissionList: CASSANDRA_KEYSPACE_SCOPES.map((s) =>
                  permissionSet.has(s.id)
                ),
              };
            })}
        />
      )}
      {loaded && (!data || data.length === 0) && (
        <span>There are no Keyspaces yet.</span>
      )}
    </div>
  );
};

export default withRouter(Keyspaces);
