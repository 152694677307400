import classNames from 'classnames';
import msgsWorkbench from 'common/dist/messages/workbench';
import React, { FC, useEffect } from 'react';
import { FiStopCircle } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';
import {
  deleteSession,
  fetchSessions,
} from '../../../../../redux/workbench/modules/sessions.module';
import { RootState } from '../../../../../store/store';
import { Session } from '../../../../../store/workbench/state.types';
import Button from '../../../../atoms/button/Button';
import { FormattedRelativeTimeConvenient } from '../../../../atoms/formatted-date-time/FormattedDateTime';
import LoadError from '../../../part-left/LoadError';

export type SessionListProps = {
  data: Session[];
  deleteSession: (sessionId: string, sessionName: string) => void;
};

const SessionsList: FC<SessionListProps> = ({ data, deleteSession }) => {
  return (
    <div className={styles.gridContainer}>
      <span className={classNames(styles.headline, styles.name)}>Name</span>
      <span className={classNames(styles.headline, styles.lastActivity)}>
        Last Activity
      </span>
      <span className={classNames(styles.headline, styles.actions)}>
        Actions
      </span>
      {data
        ?.sort((a, b) =>
          // Latest activity sessions first
          a.kernel?.last_activity < b.kernel?.last_activity ? 1 : -1
        )
        .map((session) => (
          <>
            <p className={styles.name}>{session.name}</p>
            <FormattedRelativeTimeConvenient
              date={new Date(session.kernel?.last_activity)}
              tooltip={false}
            >
              {(text) => <span className={styles.lastActivity}>{text}</span>}
            </FormattedRelativeTimeConvenient>
            <div className={styles.actions}>
              <div className={styles.buttonsContainer}>
                <Button
                  Icon={() => <FiStopCircle size={18} />}
                  form={'squared'}
                  onClick={() => deleteSession(session.id, session.name)}
                  title={'Stop Session'}
                />
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

type Props = {};

const Sessions: FC<Props> = () => {
  // TODO Implement a useSessions hook based on react-query
  const data: Session[] = useSelector<RootState, Session[]>(
    (state) => state.workbench.sessions.data
  );
  const error = useSelector<RootState, Record<string, unknown>>(
    (state: RootState) => state.workbench.sessions.error
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSessions(false));
  }, []);

  return (
    <div className={styles.sessions}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Sessions</span>
        {data?.length > 0 && (
          <div
            className={styles.shutdownAllButton}
            onClick={() =>
              data?.forEach((session) =>
                // @ts-ignore
                dispatch(deleteSession(session.id, session.name))
              )
            }
          >
            <span>Stop all Sessions</span>
          </div>
        )}
      </div>
      {error && <LoadError message={error.message} />}
      {data && data.length > 0 && (
        <SessionsList
          data={data}
          deleteSession={(sessionId: string, sessionName: string) =>
            // @ts-ignore
            dispatch(deleteSession(sessionId, sessionName))
          }
        />
      )}
      {(!data || data.length === 0) && (
        <div className={styles.emptyList}>
          <FormattedMessage {...msgsWorkbench.workbenchSessionsEmpty} />
        </div>
      )}
    </div>
  );
};

export default Sessions;
