/** A datapool is the bucket associated with an augur */
export function datapoolName(augurCode: string): string {
  return 'augur-' + augurCode.toLowerCase();
}

/** Bucket names are only lowercase, so the augur code part is as well */
const datapoolBucketRegex = /augur-[0-9a-z]+/;

export const isDatapoolBucket = (bucketName: string): boolean =>
  datapoolBucketRegex.test(bucketName);

export const augurFromBucket = (bucketName: string): string =>
  isDatapoolBucket(bucketName) ? bucketName.split('-')[1] : bucketName;

/**
 * Get the augur name by extracting the augur code (lowercase) and looking it up in the names (uppercase)
 * @param bucketName
 * @param augurNames
 */
export function augurForBucket(
  bucketName: string,
  augurNames: Record<string, string>
): string {
  return augurNames[augurFromBucket(bucketName).toUpperCase()] || '';
}
