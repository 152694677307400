import classNames from 'classnames';
import { K8sResources } from 'common/dist/types/job';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  fromK8sResourcesSelectFormState,
  toK8sResourcesSelectFormState,
} from './k8sResources.form';
import styles from './styles.module.scss';
import { NodeInfo } from '../../../core/api/codeCapsules';
import { Checkbox } from '../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import { DropdownSelectInput } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { useValidateOnChange } from '../../pages/augur/utils/augurSettings.form';
import { GpuSelectOptionType } from '../../runCodeCapsuleModal/RunCodeCapsuleModal';
import { FieldInputProps } from '../augur-layout-elements/settings-elements/types/type';
import {
  extractGpuProductOptions,
  isGpuAvailable,
} from '../k8s-node-info/utils';

export type K8sResourceSelectProps = {
  nodeInfo: NodeInfo;
  headerLabel?: string;
  activeCategory?: string;
  validateResources: (resources: K8sResources) => ErrorType;
  gpuEnabled?: boolean;
  usePriorityClasses?: boolean;
  defaultPlaceholders?: K8sResources;
};

export type Props = K8sResourceSelectProps &
  Partial<FieldInputProps<K8sResources>>;

export type ErrorType = {
  [key in keyof K8sResources]: string;
};

export const K8sResourcesSelect: FC<Props> = (props) => {
  const {
    nodeInfo,
    headerLabel = 'Workbench Resources',
    validateResources,
    gpuEnabled = false,
    value,
    onChange,
    defaultPlaceholders,
  } = props;

  const gpuAvailable = isGpuAvailable(nodeInfo);
  const gpuOptions = extractGpuProductOptions(nodeInfo);

  const { control, trigger, getValues } = useForm<K8sResources>({
    mode: 'onChange',
    values: toK8sResourcesSelectFormState(value),
    resolver: (values) => {
      const errors = validateResources(values);

      return {
        values: errors ? {} : values,
        errors: errors ? errors : {},
      };
    },
  });

  useValidateOnChange(control, trigger);

  const onChangeValues = () => {
    onChange(fromK8sResourcesSelectFormState(getValues()));
  };

  const renderTextInput = (
    key: keyof K8sResources,
    labelDefault: string,
    description: string,
    placeholderDefault?: string
  ) => {
    return (
      <Controller
        name={key}
        control={control}
        render={({ field, fieldState }) => {
          const { ref, ...rest } = field; // extract ref to pass as inputRef
          return (
            <IntlTextInputLine
              label={labelDefault}
              description={description}
              placeholder={placeholderDefault}
              {...rest}
              {...fieldState}
              inputRef={ref}
              // @ts-expect-error Our custom resolver returns string not the RHF error type
              error={fieldState.error}
              value={field.value as string}
              onChange={(e) => {
                field.onChange(e);
                onChangeValues();
              }}
              // we always want to show errors
              isTouched={true}
            />
          );
        }}
      />
    );
  };

  return (
    <div className={styles.k8sResourcesSelect}>
      <span className={styles.title}>{headerLabel}</span>
      <div className={styles.grid}>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'cpuRequest',
              'CPU Request',
              'e.g. 1.0, 500m',
              defaultPlaceholders?.cpuRequest
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'cpuLimit',
              'CPU Limit',
              'e.g. 1.0, 500m',
              defaultPlaceholders?.cpuLimit
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'memoryRequest',
              'Memory Request',
              'e.g. 2Gi, 2.5Gi',
              defaultPlaceholders?.memoryRequest
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'memoryLimit',
              'Memory Limit',
              'e.g. 4Gi, 4.5Gi',
              defaultPlaceholders?.memoryLimit
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.grid}>
        <div className={classNames(styles.col, styles.gpuAvailableCol)}>
          <div
            className={classNames(styles.inputParent, {
              [styles.checkboxDisabled]: !gpuAvailable,
            })}
          >
            {!gpuAvailable && (
              <span className={styles.noGpuAvailableInfo}>
                There is no node with a GPU available in the cluster
              </span>
            )}
            <Controller
              name={'useGpu'}
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    label='Schedule to GPU node'
                    checked={field.value || false}
                    onChange={() => {
                      field.onChange(!field.value);
                      onChangeValues();
                    }}
                    disabled={
                      !gpuAvailable || // no gpu
                      !gpuEnabled
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        {gpuAvailable && value?.useGpu && (
          <>
            <div className={styles.col}>
              <div className={styles.inputParent}>
                {renderTextInput(
                  'gpuLimit',
                  'Optional: GPU Limit',
                  'e.g. 1, 2',
                  defaultPlaceholders?.gpuLimit
                )}
              </div>
            </div>
            <div className={styles.col}>
              <Controller
                name={'gpuProduct'}
                control={control}
                render={({ field }) => (
                  <DropdownSelectInput
                    id={'gpuProduct'}
                    name={'gpuProduct'}
                    label={'Optional: Select the GPU type'}
                    placeholder={'No GPU type selected'}
                    value={gpuOptions.find(
                      (o) => o?.value?.model === field.value?.model
                    )}
                    onChange={(option: GpuSelectOptionType) => {
                      field.onChange(option?.value);
                      onChangeValues();
                    }}
                    isLoading={false}
                    options={gpuOptions}
                    isClearable
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
