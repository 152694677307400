//TODO: Maybe remove
export const kubeQuantityRegex =
  /^([+-]?[0-9]+(\.[0-9]+)?)((m|n|u|E|P|T|G|M|k|Ei|Ki|Mi|Ti|Gi|Pi)|(e[-+]?[0-9]+))?$/gm;

// Adapted from the javascript kubernetes client
export function findSuffix(quantity: string): string {
  let ix = quantity.length - 1;
  while (ix >= 0 && !/[.0-9]/.test(quantity.charAt(ix))) {
    ix--;
  }
  return ix === -1 ? '' : quantity.substring(ix + 1);
}

/**
 * Validates whether a passed quantity is a valid Kubernetes memory specification
 * https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-memory
 * @param quantity
 * @param isUnitRequired if true, quantities without unit (e.g. "100") will be discarded as false
 */
export function isValidK8sMemorySpec(
  quantity: string,
  isUnitRequired: boolean
): boolean {
  if (quantity === null || quantity === undefined) return false;
  const suffix = findSuffix(quantity);
  if (suffix === '') {
    if (isUnitRequired) return false;
    return isValidNumber(quantity, true);
  }
  const quantityWithoutSuffix = quantity.slice(
    0,
    quantity.length - suffix.length
  );
  if (!isValidNumber(quantityWithoutSuffix, false)) {
    return false;
  }
  switch (suffix) {
    case 'k':
    case 'M':
    case 'G':
    case 'T':
    case 'P':
    case 'E':
    case 'Ki':
    case 'Mi':
    case 'Gi':
    case 'Ti':
    case 'Pi':
    case 'Ei':
      return true;
    default:
      return false;
  }
}

/**
 * Validates whether a passed quantity is a valid Kubernetes CPU specification
 * https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-cpu
 * @param quantity
 */
export function isValidK8sCpuSpec(
  quantity: string,
  onlyInteger = false,
  allowSuffix = true
): boolean {
  if (quantity === null || quantity === undefined) return false;
  let quantityWithoutSuffix = quantity;
  if (quantity.endsWith('m')) {
    if (!allowSuffix) return false;
    // Allow for the "m" suffix
    quantityWithoutSuffix = quantity.slice(0, -1);
    return isValidNumber(quantityWithoutSuffix, true);
  }

  return isValidNumber(quantityWithoutSuffix, onlyInteger);
}

const isValidNumber = (quantity: string, onlyInteger: boolean): boolean => {
  if (quantity.endsWith('.')) {
    // To prevent values like "1."
    return false;
  }

  if (onlyInteger) {
    const i = Math.floor(Number(quantity));
    return i !== Infinity && String(i) === quantity && i > 0;
  } else {
    const n = Number(quantity);
    return n !== Infinity && n > 0;
  }
};

export const getCpuValueInMilliunits = (value: string): number => {
  const numericValue = parseFloat(value);
  return value.endsWith('m') ? numericValue : numericValue * 1000;
};

export const getQuantityInBytes = (quantity: string) => {
  if (!isValidK8sMemorySpec(quantity, false)) return null;

  const suffix = findSuffix(quantity);
  const num = Number(
    quantity.slice(0, quantity.length - suffix.length)
  ).valueOf();

  let suffixIndex = ['', 'Ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei'].indexOf(suffix);
  let bytes = 0;
  if (suffixIndex < 0) {
    suffixIndex = ['', 'k', 'M', 'G', 'T', 'P', 'E'].indexOf(suffix);
    bytes = num * 1000 ** suffixIndex;
  } else bytes = num * 1024 ** suffixIndex;

  return bytes;
};

// https://stackoverflow.com/a/14919494/6235550
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['Ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei', 'Zi', 'Yi'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}
