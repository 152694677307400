import { defineMessages } from 'react-intl';

export default defineMessages({
  msgMisclassification: {
    id: 'details.accuracy.misclassification',
    defaultMessage: 'Misclassification',
  },
  msgPredicatedValues: {
    id: 'details.accuracy.predictedValuesDistribution',
    defaultMessage: 'Distribution of Predicted Values',
  },
  msgCapturedResponse: {
    id: 'details.accuracy.capturedResponse',
    defaultMessage: 'Captured Response',
  },
  msgCumulativeCaptureResponse: {
    id: 'details.accuracy.cumulativeCapturedResponse',
    defaultMessage: 'Cumulative Captured Response',
  },
  msgCumulativeLift: {
    id: 'details.accuracy.cumulativeLift',
    defaultMessage: 'Cumulative Lift',
  },
  msgCumulativeResponse: {
    id: 'details.accuracy.cumulativeResponse',
    defaultMessage: 'Cumulative Response',
  },
  msgLift: {
    id: 'details.accuracy.lift',
    defaultMessage: 'Lift',
  },
  msgResponse: {
    id: 'details.accuracy.response',
    defaultMessage: 'Response',
  },
  msgRoc: {
    id: 'details.accuracy.roc',
    defaultMessage: 'ROC',
  },
  jobBanner: {
    id: 'details.job_banner',
    defaultMessage: 'Viewing report for model {modelCode}.',
  },
  msgModelViewerNotAvailable: {
    id: 'augur.model_viewer.charts_not_available',
    defaultMessage: 'Model Viewer chart is not available',
  },
  msgInfluencersNotAvailable: {
    id: 'augur.influencers.not_available',
    defaultMessage: 'Influencers are not available',
  },
  msgFalsePositiveRate: {
    id: 'augur.accuracy.false_positive_rate',
    defaultMessage: 'False Positive Rate',
  },
  msgTruePositiveRate: {
    id: 'augur.accuracy.true_positive_rate',
    defaultMessage: 'True Positive Rate',
  },
  msgScoreBins: {
    id: 'augur.accuracy.score_bins',
    defaultMessage: 'Score Bins',
  },
  msgLiftValue: {
    id: 'augur.accuracy.lift_value',
    defaultMessage: 'Lift',
  },
  msgCumulativeLiftValue: {
    id: 'augur.accuracy.cumulative_lift_value',
    defaultMessage: 'Cumulative Lift',
  },
  msgPercentageCustomersContacted: {
    id: 'augur.accuracy.percentage_customers_contacted',
    defaultMessage: '% Customers Contacted',
  },
  msgResponseValue: {
    id: 'augur.accuracy.response_value',
    defaultMessage: 'Response',
  },
  msgCumulativeResponseValue: {
    id: 'augur.accuracy.cumulative_response_value',
    defaultMessage: 'Cumulative Response',
  },
  msgCapturedResponseValue: {
    id: 'augur.accuracy.captured_response_value',
    defaultMessage: 'Captured Response',
  },
  msgCumulativeCapturedResponseValue: {
    id: 'augur.accuracy.cumulative_captured_response_value',
    defaultMessage: 'Cumulative Captured Response',
  },
  msgAbsoluteAmount: {
    id: 'augur.accuracy.absolute_amount',
    defaultMessage: 'Absolute Amount',
  },
  msgChartNotAvailable: {
    id: 'augur.accuracy.chart_not_available',
    defaultMessage: 'Chart is not available',
  },
  jobRunLearning: {
    id: 'augur.job.run_learning',
    defaultMessage: 'Run Learning Now',
  },
  jobRunEvaluation: {
    id: 'augur.job.run_evaluation',
    defaultMessage: 'Run Evaluation Now',
  },
  jobRunPrediction: {
    id: 'augur.job.run_prediction',
    defaultMessage: 'Run Prediction Now',
  },
  jobStartRealtimePrediction: {
    id: 'augur.job.start_realtime_prediction',
    defaultMessage: 'Start Realtime Prediction Now',
  },
  augurDataGoto: {
    id: 'augur.augur_data.goto',
    defaultMessage: 'Go to Augur Data',
  },
  realtimeGoto: {
    id: 'augur.realtime.goto',
    defaultMessage: 'Go to Realtime Prediction',
  },
});
