import { Dispatch } from 'redux-act';
import { getFetchedNotebooks } from '../../redux/workbench/selectors/notebook.selectors';
import {
  fetchNotebook,
  selectNotebook,
  showWarningOpenFile,
} from '../../redux/workbench/modules/notebook.module';
import { RouteComponentProps } from 'react-router';
import { workbenchRoutes } from '../../workbench/common/workbenchRoutes';
import { ContentElement } from '../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { DeprecatedRootState } from '../../store/state.type';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';
import qs from 'qs';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';

// 50 MB
export const WarningSize = 50 * 1024 ** 2;

export const openFileFromBin = (
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  selectedPath: string[],
  element: Omit<ContentElement, 'content'>
) => {
  return openFile(state, dispatch, history, selectedPath, element, true);
};

export function openFile(
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  selectedPath: string[],
  element: Omit<ContentElement, 'content'>,
  fromRecycleBin: boolean = false
) {
  if (element.size > WarningSize) {
    // @ts-ignore: Type actions/reducers first
    dispatch(showWarningOpenFile(element.path, element.type));
    const targetRoute = fromRecycleBin ? workbenchRoutes.recycleBin : workbenchRoutes.fileBrowser;
    history.push(
      `${workbenchRoutes.basePath}${targetRoute}${workbenchRoutes.warningOpenFile}${history.location.search}`
    );
  } else {
    openFileNoCheck(state, dispatch, history, element.path, fromRecycleBin);
  }
}

export function openFileNoCheck(
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  elementPath: string,
  fromRecycleBin: boolean = false
) {
  const fetchedNotebooks = getFetchedNotebooks(state);
  const mostRecentPaneId = state.workbench.mostRecentPaneId;

  const targetRoute = fromRecycleBin ? workbenchRoutes.recycleBin : workbenchRoutes.fileBrowser;

  history.push(
    `${workbenchRoutes.basePath}${targetRoute}${history.location.search}`
  );

  if (fetchedNotebooks.includes(elementPath)) {
    dispatch(selectNotebook(elementPath));
  } else {
    // @ts-ignore: Type actions/reducers first
    dispatch(fetchNotebook(elementPath, mostRecentPaneId));
  }
}

/**
 * Strip non-standard fields from a notebook file / json / object, which should not be persisted, because
 * they are only relevant while the file is opened / in redux.
 *
 * executing: This is a field inside a cell, which we set to show that a cell is currently executing.
 *
 * @param notebook Notebook with nbformat: 4 nbformat_minor: 2 and additional fields
 *                 for example: an "executing" field in the CodeCell type
 */
export function stripInMemoryContent(notebook): JupyterNotebookFormat {
  return {
    ...notebook,
    cells: notebook.cells.map(({ executing, ...rest }) => rest),
  };
}

export const getSelectedDirPathQuery = (path: string[]): string =>
  qs.stringify({ path: contentArrayToPath(path) }, { addQueryPrefix: true });
