import io from 'socket.io-client';
import * as dashboardSummaryModule from './redux/modules/dashboard.summary.module';
import { predictionStarted } from './redux/modules/predictions.module';
import { bucketCreated } from './redux/modules/data.cassandra.module';
import socketTypes from 'common/dist/socket/socketTypes';
import keycloak from '../keycloak';
import {
  appendLogLines,
  updateJobKubernetesEvents,
  updateJobStatus,
  updateProgressSteps,
} from './redux/modules/orchestration.jobdetails.module';
import {
  createRealtimeSummary,
  deleteRealtimeSummary,
  updateRealtimeSummary,
} from './store/realtime/slice';

const getSocketUrl = () => '/';

export let socket;

export const configureSockets = (store) => {
  // eslint-disable-line
  if (socket) {
    socket.close();
  }

  socket = io(getSocketUrl(), {
    extraHeaders: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });

  socket.on(socketTypes.augur.created, (augur) => {
    store.dispatch(dashboardSummaryModule.newAugurAdded(augur));
  });

  socket.on(socketTypes.habitat.created, (habitat) => {
    store.dispatch(dashboardSummaryModule.newHabitatAdded(habitat));
  });

  socket.on(socketTypes.prediction.started, (prediction) => {
    const {
      augur: { code, name },
    } = prediction;
    store.dispatch(predictionStarted({ augurCode: code, augurName: name }));
  });

  socket.on(socketTypes.augur.progress, (payload) => {
    const { augurCode, progress, inProgress } = payload;
    store.dispatch(
      dashboardSummaryModule.augurProgres({ augurCode, progress, inProgress })
    );
  });

  socket.on(
    socketTypes.s3Bucket.created,
    ({ bucketName, bucketMessage, bucketStatusType }) => {
      store.dispatch(
        bucketCreated({ bucketName, bucketMessage, bucketStatusType })
      );
    }
  );

  socket.on(socketTypes.jobProgress.update, (data, roomId) =>
    store.dispatch(updateProgressSteps(roomId.split('-')[1], data))
  );

  socket.on(socketTypes.jobEvents.update, (data, roomId) =>
    store.dispatch(updateJobKubernetesEvents(roomId.split('-')[1], data))
  );

  socket.on(socketTypes.jobStatus.update, (status, roomId) =>
    store.dispatch(updateJobStatus(roomId.split('-')[1], status))
  );

  socket.on(socketTypes.jobLog.appendLines, (logLines, roomId) =>
    store.dispatch(appendLogLines(roomId.split('-')[2], logLines))
  );

  socket.on(socketTypes.realtimeAugur.create, (summary, roomId) =>
    store.dispatch(createRealtimeSummary(summary))
  );

  socket.on(socketTypes.realtimeAugur.update, (summary, roomId) =>
    store.dispatch(updateRealtimeSummary(summary))
  );

  socket.on(socketTypes.realtimeAugur.delete, (augurCode, roomId) =>
    store.dispatch(deleteRealtimeSummary(augurCode))
  );
};

export const realtimeAugurCodeToRoom = `realtime`;
