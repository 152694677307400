import msgsForm from 'common/dist/messages/form';
import { REPO_TYPE } from 'common/dist/types/repository';
import { PostAddRepositoryBody } from 'common/dist/types/requestBodies/repositories';
import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import {
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldName,
  fieldType,
  formName,
  repositoryForm,
} from './repository.form';
import RepositoryStepHabitat from './step-cc-habitat/RepositoryStepHabitat.container';
import RepositoryStepDescription from './step-description/RepositoryStepDescription';
import RepositoryStepName from './step-name/RepositoryStepName';
import RepositoryStepType from './step-type/RepositoryStepType.container';
import { useAddRepository } from '../../../../core/api/workbench/repositories';
import { ButtonProps } from '../../../atoms/button/Button';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import Wizard from '../../../pages/wizard/Wizard';
import {
  overviewAppsLink,
  overviewCodeCapsulesLink,
  overviewPlainLink,
} from '../../routes';

export interface AddRepositoryWizardProps {
  hideAddRepositoryWizard: () => void;
  addRepository: (...args: unknown[]) => void;
  formValid?: boolean;

  /** --- ENTERED FORM VALUES --- */
  /** Entered repository name */
  repositoryName?: {
    display: string;
    slug: string;
  };
  /** Entered repository type */
  repositoryType?: 'plain' | 'code-capsule' | 'app';
  /** Entered repository description */
  repositoryDescription?: string;
  /** Selected habitat for the code capsule (only set if repositoryType='code-capsule' */
  codeCapsuleHabitat?: {
    code: string;
    name: string;
  };
  /** The code of the currently logged in user */
  currentUserCode: string;

  isAsyncValidating?: boolean;
}

const AddRepositoryWizard: FC<AddRepositoryWizardProps & InjectedFormProps> = ({
  repositoryName,
  repositoryType,
  repositoryDescription,
  codeCapsuleHabitat,
  formValid,
  isAsyncValidating,
}) => {
  const addRepositoryMutation = useAddRepository(repositoryType);

  let buttonLink: string;
  if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
    buttonLink = overviewCodeCapsulesLink();
  } else if (repositoryType === REPO_TYPE.APP) {
    buttonLink = overviewAppsLink();
  } else if (repositoryType === REPO_TYPE.PLAIN) {
    buttonLink = overviewPlainLink();
  }

  const buttons: ButtonProps[] = [
    {
      linkTo: buttonLink,
      color: 'white',
      label: msgsForm.cancel,
    },
    {
      linkTo: buttonLink,
      color: 'secondary',
      label: msgsForm.submit,
      disabled: !formValid,
      onClick: () => {
        if (formValid && !isAsyncValidating) {
          let body: PostAddRepositoryBody;
          if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
            body = {
              repositoryType,
              repositoryName,
              repositoryDescription,
              codeCapsuleHabitat,
            };
          } else {
            body = {
              repositoryType,
              repositoryName,
              repositoryDescription,
            };
          }
          addRepositoryMutation.mutate(body);
        }
      },
    },
  ];

  return (
    <Wizard headline={'Add new Repository'} buttons={buttons}>
      <form
        onKeyPress={(e) => {
          if (e.which === 13) e.preventDefault();
        }}
      >
        {/* --- Wizard Steps */}
        <GenericFormStep
          fieldName={fieldName}
          formName={formName}
          title={{
            id: 'no-id',
            defaultMessage: 'Enter Name',
          }}
          description={{
            id: 'no-id',
            defaultMessage: 'Enter a name for the repository.',
          }}
          component={RepositoryStepName}
          num={1}
          renderError={false}
        />

        <GenericFormStep
          fieldName={fieldDescription}
          formName={formName}
          title={{
            id: 'no-id',
            defaultMessage: 'Enter Description',
          }}
          description={{
            id: 'no-id',
            defaultMessage: 'Enter a description for the repository.',
          }}
          component={RepositoryStepDescription}
          num={2}
          renderError={false}
        />

        <GenericFormStep
          fieldName={fieldType}
          formName={formName}
          title={{
            id: 'no-id',
            defaultMessage: 'Select Type',
          }}
          description={{
            id: 'no-id',
            defaultMessage: 'Select a type for the repository.',
          }}
          // @ts-ignore
          component={RepositoryStepType}
          num={3}
          renderError={false}
        />

        {repositoryType === REPO_TYPE.CODE_CAPSULE && (
          <GenericFormStep
            fieldName={fieldCodeCapsuleHabitat}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select Habitat',
            }}
            description={{
              id: 'no-id',
              defaultMessage:
                'Select a Habitat this Code Capsule will belong to.',
            }}
            // @ts-ignore
            component={RepositoryStepHabitat}
            num={4}
            renderError={false}
          />
        )}
      </form>
    </Wizard>
  );
};

export default reduxForm(repositoryForm)(AddRepositoryWizard);
