import { ParameterTuningSchema, SchemaNames, SingleParametersResult } from 'common/dist/types/module.nbc';
import { groupBy } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import AlgorithmTuningResults from './AlgorithmTuningResults';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import {
  ParameterTuningResultsConfig,
  ParameterTuningResultsReportData,
} from '../type';

export type Props = ParameterTuningResultsConfig &
  ParameterTuningResultsReportData;

export function ToSchemaNames(
  parameterTuningSchema: ParameterTuningSchema
): SchemaNames {
  return Object.fromEntries(
    parameterTuningSchema.availableAlgorithms.map((algo) => [
      algo.name,
      {
        speakingName: algo.speakingName,
        parameters: {
          ...Object.fromEntries(
            algo.parameters.map((param) => [param.name, param.speakingName])
          ),
        },
      },
    ])
  );
}

export const ParameterTuningResults: React.FC<Props> = (props) => {
  const {
    modelTuningResults: { kpi, bestModel, singleResults },
    parameterTuningSchema,
  } = props;
  const speakingNames = ToSchemaNames(parameterTuningSchema);

  const [ref] = useDimensions<HTMLDivElement>();

  function renderSelectedModel() {
    return (
      <div>
        <h2 className={styles.headline}>
          <FormattedMessage
            id='story.model_tuning_results.selected_model'
            defaultMessage='Selected Model: '
          />
          {speakingNames[bestModel.algorithm].speakingName}
        </h2>
        {bestModel.parameters && (
          <div className={styles.parameterBlock}>
            <ul>
              {bestModel.parameters.map(({ name, value }, index) => (
                <li key={index}>
                  <span className={styles.parameterName}>
                    {speakingNames[bestModel.algorithm].parameters[name]}:
                  </span>
                  <span className={styles.parameterValue}>{value}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={styles.parameterBlock}>
          <ul>
            <li key={'kpi'}>
              <span className={styles.label}>KPI: </span>
              <span>{kpi}</span>
            </li>
            <li key={'score'}>
              <span>Score: {bestModel.score}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  const algorithmGroups: { [name: string]: SingleParametersResult[] } = groupBy(
    singleResults,
    (smr) => smr.algorithm
  );

  return (
    <div ref={ref} className={styles.parameterTuningResults}>
      {renderSelectedModel()}
      {Object.entries(algorithmGroups).map(
        ([name, algorithmTuningResults], id) => (
          <AlgorithmTuningResults
            key={id}
            algorithmTuningResults={algorithmTuningResults}
            algorithmNames={speakingNames[name]}
            maxScore={Math.max(...singleResults.map((smr) => smr.score))}
            minScore={Math.min(...singleResults.map((smr) => smr.score))}
          />
        )
      )}
    </div>
  );
};

export const ParameterTuningResultsSingle: React.FC<
  ReportElementProps<
    ParameterTuningResultsReportData,
    ParameterTuningResultsConfig
  >
> = buildSingleReportWrapper<
  ParameterTuningResultsReportData,
  ParameterTuningResultsConfig
>(ParameterTuningResults);
