import React from 'react';
import {
  FiAlignLeft,
  FiAlignRight,
  FiAnchor,
  FiAward,
  FiBarChart2,
  FiBookOpen,
  FiCamera,
  FiCheckCircle,
  FiCompass,
  FiCpu,
  FiGift,
  FiGrid,
  FiLayers,
  FiLink2,
  FiMaximize2,
  FiMessageSquare,
  FiMoon,
  FiOctagon,
  FiPhoneForwarded,
  FiPieChart,
  FiSettings,
  FiShield,
  FiShoppingCart,
  FiShuffle,
  FiSliders,
  FiTarget,
  FiTrendingUp,
  FiTruck,
} from 'react-icons/fi';
import { PiSelectionPlus } from 'react-icons/pi';

// FIXME: name icons after form not use case
export const AugurMenuIcons = {
  accuracy: () => <span className={'icon-accuracy'} />,
  trendingUp: FiTrendingUp,
  modelViewer: () => <span className={'icon-model-viewer'} />,
  influencers: () => <span className={'icon-influencers'} />,
  cpu: FiCpu,
  barChart2: FiBarChart2,
  modelHistory: () => <span className={'icon-model-history'} />,
  bookOpen: FiBookOpen,
  sliders: FiSliders,
  selectionPlus: PiSelectionPlus,

  anchor: FiAnchor,
  award: FiAward,
  checkCircle: FiCheckCircle,
  compass: FiCompass,
  gift: FiGift,
  moon: FiMoon,
  /** Used for: Binary Classification Training Split */
  pieChart: FiPieChart,
  shoppingCart: FiShoppingCart,
  truck: FiTruck,
  camera: FiCamera,

  /** Used for: constraintExhaustion */
  alignLeft: FiAlignLeft,
  /** Used for: channels */
  phoneForwarded: FiPhoneForwarded,
  /** Used for: propensities */
  link2: FiLink2,
  /** Used for: communications */
  messageSquare: FiMessageSquare,
  /** Used for: communicationGroups */
  layers: FiLayers,
  /** Used for: customerTable */
  grid: FiGrid,
  /** Used for: constraints */
  octagon: FiOctagon,
  /** Used for: contactPolicies */
  shield: FiShield,
  /** Used for: targetFunction */
  maximize2: FiMaximize2,

  /** Used for: Binary Classification Pipeline Tuning */
  target: FiTarget,
  /** Used for: Binary Classification Candidate Conditions */
  shuffle: FiShuffle,
  /** Used for: Binary Classification Rejected Features */
  alignRight: FiAlignRight,
  /** Used for: Binary Classification Input Data */
  tables: () => <span className={'icon-tables'} />,
  /** Used for: Binary Classification Input Format */
  settings: FiSettings,
} as const;
