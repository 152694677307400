import classNames from 'classnames';
import { HabitatDetailsType } from 'common/dist/types/habitat';
import qs from 'qs';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import EmptyModelManagement from './EmptyModelManagement';
import styles from './styles.module.scss';
import utils from '../../../../scss/base/utils.module.scss';
import Busy from '../../atoms/busy/Busy';
import MainContainer from '../../pages/main-container/MainContainer';
import HabitatContainer from '../habitat/Habitat.container';

type Props = {
  habitats: HabitatDetailsType[];
  loaded?: boolean;
  loading?: boolean;
};

// TODO: Implement proper loading component
const HabitatsLoading = () => {
  return (
    <div
      className={classNames(styles.habitatsContainer, styles.habitatsLoading)}
    >
      <div className={styles.loadingPlaceholder}>
        <Busy isBusy />
      </div>
    </div>
  );
};

const HabitatsLoaded: FC<{ habitats: HabitatDetailsType[] }> = ({
  habitats,
}) => {
  return (
    <div
      className={classNames(styles.habitatsContainer, utils.hideScrollbar)}
      data-testid={'habitats-loaded'}
    >
      {habitats
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .map((habitat, index) => (
          <HabitatContainer habitat={habitat} key={index} />
        ))}
    </div>
  );
};

const Habitats: FC<Props> = ({ habitats, loaded, loading }) => {
  const location = useLocation();
  const { habitatCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (!habitatCode) return;

    const habitat = document.getElementById(`habitat-${habitatCode}`);
    if (habitat) {
      habitat.scrollIntoView({ behavior: 'smooth' });
    }
  }, [habitatCode, loaded]);

  if (loaded && (!habitats || habitats.length === 0)) {
    return <EmptyModelManagement />;
  }

  return (
    <MainContainer
      additionalInnerClassname={styles.modelManagement}
      transparent={true}
      fullWidth={true}
    >
      {loading ? <HabitatsLoading /> : <HabitatsLoaded habitats={habitats} />}
    </MainContainer>
  );
};

export default Habitats;
