import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import GitFileStatus from './file-status/GitFileStatus.container';
import GitBranches from './git-branches/GitBranches.container';
import GitNotPulled from './git-not-pulled/GitNotPulled.container';
import GitNotPushed from './git-not-pushed/GitNotPushed.container';
import GitListCommits from './list-commits/GitListCommits.container';
import RepositoryMeta from './repository-meta/RepositoryMeta.container';
import { useModules } from '../../../../../core/api/modules';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';
import {
  generateRepositoryDetailsLink,
  moduleDetailsLink,
  modulesBuildLink,
} from '../../../../collaborationSpace/routes';
import { useSelectedDirPath } from '../../../hooks';
import { WORKBENCH_FILENAMES } from '../../config';

export type RepositoryMeta = {
  repoName: string;
  repoFullName: string;
  name: string;
  repoType: string;
  codeCapsuleCode: string;
  appCode: string;
};
export type Props = {
  activeBranch: string;
  gitActiveBranch: (repositoryPath: string, showNotifications: boolean) => void;
  loadRepoMeta: (path: string) => void;
  repoMetas: {
    [path: string]: RepositoryMeta;
  };
  currentUserId: string;
};

const RepositoryInfo: FC<Props> = ({
  loadRepoMeta,
  gitActiveBranch,
  repoMetas,
}) => {
  const selectedDirPath = useSelectedDirPath();
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const repositoryPath = useMemo(
    () => contentArrayToPath(selectedDirPath.slice(0, index + 1), false),
    [index, selectedDirPath]
  );
  const infoFilePath = useMemo(
    () => `${repositoryPath}/${WORKBENCH_FILENAMES.REPOSITORY_META}`,
    [selectedDirPath]
  );
  //We cannot filter the modules directly. A module containing a hyphen will not get recognized e.g. "BC - Python" will be safed and displayed as "bc-python"
  //Therefore we will fetch all modules and check the repositoryname
  const modules = (useModules({ has_repository: 'true' })?.data || []).filter(
    (m) => m?.repository?.repoFullName === repoMetas[infoFilePath]?.repoFullName
  );
  useEffect(() => {
    if (!infoFilePath) return;

    gitActiveBranch(repositoryPath, false);
    loadRepoMeta(infoFilePath);
  }, [infoFilePath, loadRepoMeta, gitActiveBranch, repositoryPath]);

  function renderCodeCapsuleMetaIfRequired() {
    const meta = repoMetas[infoFilePath];
    const { repoFullName, repoType } = meta || {};
    const [group, repoName] = repoFullName?.split('/') || [];
    let buildVersionLinkRoute: string;
    let detailsPageLinkRoute: string;
    const moduleCode = modules?.[0]?.code;
    switch (repoType) {
      case 'code-capsule':
      case 'app':
      case 'plain':
        detailsPageLinkRoute = generateRepositoryDetailsLink(repoFullName);
        break;
      case 'module':
        console.log('module', { moduleCode, group, repoName, repoFullName });
        buildVersionLinkRoute = moduleCode
          ? modulesBuildLink(moduleCode, group, repoName)
          : undefined;
        detailsPageLinkRoute = moduleCode
          ? moduleDetailsLink(moduleCode)
          : undefined;
        break;
    }

    return (
      <Fragment>
        <h2 className='repository-info-category-title'>Repository Meta</h2>
        <div className='repository-meta'>
          <div className='buttons-horizontal'>
            {repoType === 'module' && (
              <Button
                label={'Build Version'}
                color='primary'
                disabled={!buildVersionLinkRoute}
                linkTo={buildVersionLinkRoute}
              />
            )}
            <Button
              label={'Show in Collaboration Space'}
              color='secondary'
              linkTo={detailsPageLinkRoute}
            />
          </div>
        </div>
        {repoType === 'code-capsule' && (
          <RepositoryMeta infoFilePath={infoFilePath} />
        )}
      </Fragment>
    );
  }

  if (!infoFilePath) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }

  return (
    <div className='workbench-repository-info'>
      <div
        className='dialog-container'
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className='dialog-inner-container'>
          <div style={{ display: 'flex' }}>
            <div className='dialog-header' style={{ flexGrow: 1 }}>
              <div className='dialog-headline dialog-header-item'>
                Repository Info for{' '}
                {repoMetas[infoFilePath]?.name ||
                  repoMetas[infoFilePath]?.repoName ||
                  (repositoryPath || '.').split('.').slice(0, -1).join('.')}
              </div>
            </div>
            <Button
              label={'Fork'}
              color={'secondary'}
              disabled={repoMetas[infoFilePath]?.repoType === 'module'}
              linkTo={`${workbenchRoutes.basePath}${workbenchRoutes.repositoryFork}`}
            />
          </div>

          {renderCodeCapsuleMetaIfRequired()}

          <span className='repository-info-category-title'>
            Branches
            <br />
          </span>
          <GitBranches />

          <span className='repository-info-category-title'>Remote Changes</span>
          <GitNotPulled repositoryPath={repositoryPath} />

          <span className='repository-info-category-title'>Local Changes</span>
          <GitFileStatus repositoryPath={repositoryPath} />

          <span className='repository-info-category-title'>
            Latest local Commits
          </span>
          <GitNotPushed repositoryPath={repositoryPath} />

          <GitListCommits repositoryPath={repositoryPath} />
        </div>
      </div>
    </div>
  );
};

export default RepositoryInfo;
