import { AUGUR_JOBS } from 'common/dist/types/job';

import { AugurResources } from './types';
import { removeEmptyStringKeysRecursively } from '../../../../utils';

//This function will extract the required resources keys for the module so it can be inserted in the Augur Settings
export function cleanAugurResources(resources: AugurResources): AugurResources {
  const result: AugurResources = {};

  for (const key of AUGUR_JOBS) {
    const jobResources = resources[key];
    if (jobResources) {
      const cleanedResources: typeof jobResources =
        removeEmptyStringKeysRecursively(jobResources);
      if (Object.keys(cleanedResources).length > 0) {
        result[key] = cleanedResources;
      }
    }
  }

  return result;
}
