import { Config } from '../../../../../pages/augur/type';
import { TupleListTableError } from '../../../../tuple-list-table/TupleListTable';
import { AllowedInputs } from '../../types/util';
import { CampaignOptimizationPropensitiesV1AugurSettings as CampaignOptimizationPropensitiesAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationPropensitiesV1AugurSettings';

export type { CampaignOptimizationPropensitiesAugurSettings };

export type CampaignOptimizationPropensitiesConfig = Record<string, never>;
export type ConfigCampaignOptimizationPropensitiesConfig =
  Config<CampaignOptimizationPropensitiesConfig>;

export type PartialCampaignOptimizationPropensitiesAugurSettings =
  Partial<CampaignOptimizationPropensitiesAugurSettings>;

export type CampaignOptimizationPropensitiesValidationError =
  TupleListTableError;

export const configAllowedInputsCampaignOptimizationPropensities: AllowedInputs<CampaignOptimizationPropensitiesConfig> =
  {};

export const configTypeCampaignOptimizationPropensities = `{}`;

export const augurSettingsTypeCampaignOptimizationPropensities = `{
    id: string;
    valueId: string;
    description?: string;
  }[]`;

export const defaultConfigCampaignOptimizationPropensities: ConfigCampaignOptimizationPropensitiesConfig =
  {};

export const defaultAugurSettingsDefaultCampaignOptimizationPropensities: PartialCampaignOptimizationPropensitiesAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationPropensitiesAugurSettings>
export const defaultValidationSchemaCampaignOptimizationPropensities = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'valueId'],
  },
};
