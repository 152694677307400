import msgsWorkbench from 'common/dist/messages/workbench';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import styles from './styles.module.scss';
import { ButtonProps } from '../../../../../atoms/button/Button';
import Modal from '../../../../../organisms/modal/Modal';

type Props = {
  show?: boolean;
  branch?: string;
  repositoryPath: string;
  hidePullConflictsModal: () => void;
  gitPull: (repositoryPath: string, branch: string, force: boolean) => void;
  conflicts?: string[];
  error?: string;
};

const PullConflictsModal: FC<Props & RouteComponentProps> = (props) => {
  const {
    hidePullConflictsModal,
    show,
    error,
    branch,
    repositoryPath,
    gitPull,
    conflicts,
  } = props;

  let buttons: ButtonProps[] = [
    {
      color: 'white',
      onClick: () => {
        hidePullConflictsModal();
      },
      label: 'Cancel',
    },
  ];
  if (!error) {
    buttons = [
      ...buttons,
      {
        color: 'red',
        onClick: () => {
          gitPull(repositoryPath, branch, true);
          hidePullConflictsModal();
        },
        label: 'Force Pull',
      },
    ];
  }

  return (
    <Modal
      show={show}
      hideModal={hidePullConflictsModal}
      headline={
        msgsWorkbench.workbenchRepositoryGitNotPulledPullConflictsModalHeadline
      }
      buttons={buttons}
    >
      <div className={styles.modalContent}>
        <span>
          <FormattedMessage
            {...msgsWorkbench.workbenchRepositoryGitNotPulledPullConflictsModalLine1}
          />
        </span>
        {error ? (
          <span>{error}</span>
        ) : (
          <>
            <span>
              <FormattedMessage
                {...msgsWorkbench.workbenchRepositoryGitNotPulledPullConflictsModalLine2}
              />
            </span>
            <span>
              <FormattedMessage
                {...msgsWorkbench.workbenchRepositoryGitNotPulledPullConflictsModalLine3}
              />
            </span>

            <span className={styles.affected}>
              <FormattedMessage
                {...msgsWorkbench.workbenchRepositoryGitNotPulledPullConflictsModalLine4}
              />
            </span>

            <ul className={styles.conflicts}>
              {conflicts?.map((fileName) => (
                <li className={styles.fileName}>{fileName}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </Modal>
  );
};

export default withRouter(PullConflictsModal);
