import React, { FC } from 'react';
import { FiDownload, FiEdit, FiTrash } from 'react-icons/fi';

import styles from './styles.module.scss';
import keycloak, { updateToken } from '../../../../../../keycloak';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import Button from '../../../../atoms/button/Button';

type Props = {
  element: S3Object;
  dataSourceCode: string;
  bucket: string;
  showRenameModal: (renameFile: string, renamePath: string) => void;
  showDeleteModal: (deletePath: string) => void;
};

const FileActions: FC<Props> = (props) => {
  const { element, dataSourceCode, bucket, showRenameModal, showDeleteModal } =
    props;

  return (
    <div className={styles.fileActions} onClick={(e) => e.stopPropagation()}>
      <Button
        Icon={() => <FiEdit size={16} />}
        form={'squared'}
        onClick={() => showRenameModal(element.name, element.path)}
      />
      <Button
        Icon={() => <FiDownload size={16} />}
        form={'squared'}
        onClick={() => {
          // sorry for what is about to happen here.
          // there were no good options. trust me.
          void updateToken().then(() => {
            void fetch(
              `/dataman/s3/${dataSourceCode}/download/bucket/${bucket}${element.path}`,
              {
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
              .then((res) => res.blob())
              .then((blob) => {
                const el = document.createElement('a');
                el.setAttribute('href', URL.createObjectURL(blob));
                el.setAttribute('download', element.name);
                el.style.display = 'none';
                document.body.appendChild(el);
                el.click();
                document.body.removeChild(el);
              });
          });
        }}
        // For a discussion about the download problem, see:
        // https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1233
      />
      <Button
        Icon={() => <FiTrash size={16} />}
        form={'squared'}
        onClick={() => showDeleteModal(element.path)}
      />
    </div>
  );
};

export default FileActions;
