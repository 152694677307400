import React, { FC } from 'react';
import { PathType, PipelineTuningValue, PipelineTuningValueNode } from 'common/dist/types/pipeline';
import styles from './styles.module.scss';
import ModelStep from './ModelStep';
import { convertTuningParameterOutputs } from '../../../../pipeline-tuning-single-results/v1/single/utils';
import { ParameterType } from 'common/dist/types/module.nbc';

type Props = {
  /** Schema of the pipeline */
  pipeline: PipelineTuningValue;
  /** Path taken through the pipeline */
  path: PathType;
  /** List of the tuning parameters used */
  parameters: ParameterType[];
  activeNodeId?: string;
};


const ModelParameters: FC<Props> = (props: Props) => {
  const { pipeline, path, activeNodeId, parameters } = props;

  // Create a "flat" dictionary for the nodes: nodeId -> node
  const nodesDict: { [key: string]: PipelineTuningValueNode} = {};
  pipeline.nodes.forEach((node) => {
    if (node.type === 'node') {
      nodesDict[node.id] = node;
    } else if (node.type === 'group') {
      node.nodes.forEach((innerNode) => {
        nodesDict[innerNode.id] = innerNode;
      });
    }
  });

  const tuningParameterMap = convertTuningParameterOutputs(parameters);

  return (
    <div className={styles.modelParameters}>
      {path.path.map((nodeId) => (
        <ModelStep
          node={nodesDict[nodeId]}
          isActive={nodeId === activeNodeId}
          tuningParameterMap={tuningParameterMap[nodeId]}
        />
      ))}
    </div>
  );

}

export default ModelParameters;