import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AugurJobsWithoutRealtime } from 'common/dist/types/job';
import { AugurReport } from 'common/dist/types/reports';
import qs from 'qs';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';

export const reportKeys = {
  all: () => ['reports'] as const,
  report: (reportCode: string) => [...reportKeys.all(), reportCode] as const,
  reports: (
    augurCode?: string,
    modelCode?: string,
    type?: AugurJobsWithoutRealtime,
    offset?: number,
    limit?: number
  ) =>
    [...reportKeys.all(), augurCode, modelCode, type, offset, limit] as const,
  reportsByCode: (reportCodes: string[]) =>
    [...reportKeys.all(), reportCodes] as const,
} as const;

export const getReports = (
  augurCode?: string,
  modelCode?: string,
  type?: AugurJobsWithoutRealtime,
  offset?: number,
  limit?: number
): CompletedApiRequest<AugurReport[]> => {
  const query = qs.stringify(
    { augurCode, modelCode, type, offset, limit },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/reports${query}`);
};

export const useReports = (
  augurCode?: string,
  modelCode?: string,
  type?: AugurJobsWithoutRealtime,
  offset?: number,
  limit?: number
): UseQueryResult<AugurReport[]> => {
  const key = reportKeys.reports(augurCode, modelCode, type, offset, limit);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getReports(augurCode, modelCode, type, offset, limit)
      ),
    {
      keepPreviousData: true,
    }
  );
};

export const getReportsByCode = (
  reportCodes: string[]
): CompletedApiRequest<AugurReport[]> => {
  const query = qs.stringify(
    { reportCodes },
    { addQueryPrefix: true, arrayFormat: 'comma' }
  );
  return apiRequest(`/api/reports${query}`);
};

export const useReportsByCode = (
  reportCodes: string[]
): UseQueryResult<AugurReport[]> => {
  const key = reportKeys.reportsByCode(reportCodes);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getReportsByCode(reportCodes)),
    {
      keepPreviousData: true,
      enabled: reportCodes.length > 0,
    }
  );
};

export const getReport = (
  reportCode: string
): CompletedApiRequest<AugurReport> => apiRequest(`/api/reports/${reportCode}`);

export const useReport = (reportCode: string): UseQueryResult<AugurReport> => {
  const key = reportKeys.report(reportCode);
  return useQuery(key, () => fetchQueryFn(key, () => getReport(reportCode)));
};
