import { AugurSettingsWithAugurProperties, ModuleConfiguration } from './type';

export const baseConfig: ModuleConfiguration = {
  apiVersion: 'v1',
  generalConfiguration: {
    supportsLearning: false,
    supportsEvaluation: false,
    supportsPrediction: false,
    supportsRealtimePrediction: false,
  },
  augurSettingsConfiguration: [],
  augurReportConfiguration: {
    learning: [],
    evaluation: [],
    prediction: [],
  },
};

//fill with dummy
export const baseSettings: AugurSettingsWithAugurProperties = {
  general: {
    module: {
      moduleCode: '',
      moduleVersionCode: 'v1',
    },
    augurName: '',
    resources: {
      learning: { resources: undefined },
      evaluation: { resources: undefined },
      prediction: { resources: undefined },
      realtimePrediction: {
        resources: undefined,
      },
    },
    attributes: {},
  },
  settingsData: {},
};
