// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-constraints/v1/type.ts' --type 'ConfigCampaignOptimizationConstraintsConfig'
export const schemaConfigCampaignOptimizationConstraints = {
    "$ref": "#/definitions/ConfigCampaignOptimizationConstraintsConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Channel": {
            "additionalProperties": false,
            "properties": {
                "description": {
                    "description": "Optional description of the channel",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the channel",
                    "type": "string"
                },
                "name": {
                    "description": "Name of the channel",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name"
            ],
            "type": "object"
        },
        "Communication": {
            "additionalProperties": false,
            "properties": {
                "boostFactor": {
                    "description": "Boost Factor of the communication =\"conversion rate of this communication\"\n-> \"If I contact someone via Channel A and ask him to buy product(=valueId via the propensityId) B the conversion rate is 0.5\"",
                    "type": "string"
                },
                "channelId": {
                    "description": "Channel ID (foreign key of a Channel)",
                    "type": "string"
                },
                "description": {
                    "description": "Optional description of the Communication",
                    "type": "string"
                },
                "fixedCosts": {
                    "description": "Fixed costs for this communication\n-> \"If I decide to contact someone via Channel A and ask him to buy product B this will initially cost 1000.0 (for example to design a catalogue or to train the call center). These costs are ignored if no one is contacted via this communication - and will fully be added if there is at least one person is contacted via this communication.",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the Communication",
                    "type": "string"
                },
                "name": {
                    "description": "Name of the Communication",
                    "type": "string"
                },
                "propensityId": {
                    "description": "Propensity ID (foreign key of a Propensity)",
                    "type": "string"
                },
                "variableCosts": {
                    "description": "Variable costs for this communication\n-> \"Each time I contact someone via Channel A and ask him to buy product B the costs are 10.0",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "channelId",
                "propensityId",
                "boostFactor"
            ],
            "type": "object"
        },
        "CommunicationGroup": {
            "additionalProperties": false,
            "properties": {
                "communicationIds": {
                    "description": "Communication IDs (foreign key of a Communication)",
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "description": {
                    "description": "Optional description of the Communication Group",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the Communication",
                    "type": "string"
                },
                "name": {
                    "description": "Name of the Communication Group",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "communicationIds"
            ],
            "type": "object"
        },
        "Config<CampaignOptimizationConstraintsConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "properties": {
                "channels": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-1562112972-469-681-1562112972-0-3782%5B%5D%3E"
                },
                "communicationGroups": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-1562112972-1932-2279-1562112972-0-3782%5B%5D%3E"
                },
                "communications": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-1562112972-681-1932-1562112972-0-3782%5B%5D%3E"
                }
            },
            "required": [
                "channels",
                "communications",
                "communicationGroups"
            ],
            "type": "object"
        },
        "ConfigCampaignOptimizationConstraintsConfig": {
            "$ref": "#/definitions/Config%3CCampaignOptimizationConstraintsConfig%3E"
        },
        "ConfigEntry<def-alias-1562112972-1932-2279-1562112972-0-3782[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/CommunicationGroup"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigEntry<def-alias-1562112972-469-681-1562112972-0-3782[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/Channel"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigEntry<def-alias-1562112972-681-1932-1562112972-0-3782[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/Communication"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        }
    }
}


// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-constraints/v1/type.ts' --type 'PartialCampaignOptimizationConstraintsAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationConstraints = {
    "$ref": "#/definitions/PartialCampaignOptimizationConstraintsAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Constraint": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "channelId": {
                            "type": "string"
                        },
                        "constraintLevel": {
                            "const": "channel",
                            "type": "string"
                        },
                        "constraintType": {
                            "enum": [
                                "quantity",
                                "cost",
                                "individualValue"
                            ],
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "id": {
                            "type": "string"
                        },
                        "name": {
                            "type": "string"
                        },
                        "operator": {
                            "$ref": "#/definitions/Operator"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "channelId",
                        "constraintLevel",
                        "constraintType",
                        "id",
                        "name",
                        "operator",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "communicationId": {
                            "type": "string"
                        },
                        "constraintLevel": {
                            "const": "communication",
                            "type": "string"
                        },
                        "constraintType": {
                            "enum": [
                                "quantity",
                                "cost",
                                "individualValue"
                            ],
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "id": {
                            "type": "string"
                        },
                        "name": {
                            "type": "string"
                        },
                        "operator": {
                            "$ref": "#/definitions/Operator"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "communicationId",
                        "constraintLevel",
                        "constraintType",
                        "id",
                        "name",
                        "operator",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "communicationGroupId": {
                            "type": "string"
                        },
                        "constraintLevel": {
                            "const": "communicationGroup",
                            "type": "string"
                        },
                        "constraintType": {
                            "enum": [
                                "quantity",
                                "cost",
                                "individualValue"
                            ],
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "id": {
                            "type": "string"
                        },
                        "name": {
                            "type": "string"
                        },
                        "operator": {
                            "$ref": "#/definitions/Operator"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "communicationGroupId",
                        "constraintLevel",
                        "constraintType",
                        "id",
                        "name",
                        "operator",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "constraintLevel": {
                            "const": "allCommunications",
                            "type": "string"
                        },
                        "constraintType": {
                            "enum": [
                                "quantity",
                                "cost",
                                "individualValue"
                            ],
                            "type": "string"
                        },
                        "description": {
                            "type": "string"
                        },
                        "id": {
                            "type": "string"
                        },
                        "name": {
                            "type": "string"
                        },
                        "operator": {
                            "$ref": "#/definitions/Operator"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "constraintLevel",
                        "constraintType",
                        "id",
                        "name",
                        "operator",
                        "value"
                    ],
                    "type": "object"
                }
            ]
        },
        "Operator": {
            "enum": [
                "leq",
                "geq",
                "eq"
            ],
            "type": "string"
        },
        "PartialCampaignOptimizationConstraintsAugurSettings": {
            "items": {
                "$ref": "#/definitions/Constraint"
            },
            "type": "array"
        }
    }
}