import React, { FC, useEffect } from 'react';

import MergeRequestListItem, {
  Props as ItemProps,
} from './MergeRequestListItem';
import {
  MergeRequest,
  MergeRequestOrdering,
  mergeRequestOrderings,
  Props,
} from './MergeRequests.container';
import { useModuleByCode } from '../../../../core/api/modules';
import RepositoryBreadcrumbs from '../../../molecules/repository-header/RepositoryBreadcrumbs';
import SortableSearchableList from '../../../organisms/sortable-searchable-list/SortableSearchableList.container';
import { moduleMergeLink, repositoryMergeRequestLink } from '../../routes';

const MergeRequests: FC<Props> = (props) => {
  const { loadMergeRequests, mergeRequests, moduleCode } = props;
  let { group, repositoryName } = props;
  const module = useModuleByCode(moduleCode, Boolean(moduleCode));
  if (module?.data) {
    const parts = (module.data.repository.repoFullName || '').split('/');
    group = parts[0];
    repositoryName = parts[1];
  }

  useEffect(() => {
    // @ts-ignore
    loadMergeRequests(group, repositoryName, 'open', 1, 20);
  }, [group, repositoryName, loadMergeRequests]);

  const sortData = (data, ordering) => {
    if (!data) return [];
    switch (ordering) {
      case MergeRequestOrdering.LastCreated:
        return data.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      case MergeRequestOrdering.OldestCreated:
        return data.sort((a, b) => (a.created_at < b.created_at ? -1 : 1));
      case MergeRequestOrdering.NameAsc:
        return data.sort((a, b) => (a.title < b.title ? -1 : 1));
      case MergeRequestOrdering.NameDesc:
        return data.sort((a, b) => (a.title > b.title ? -1 : 1));
      case MergeRequestOrdering.CreatedByAsc:
        return data.sort((a, b) =>
          a.user.full_name < b.user.full_name ? -1 : 1
        );
      case MergeRequestOrdering.CreatedByDesc:
        return data.sort((a, b) =>
          a.user.full_name > b.user.full_name ? -1 : 1
        );
    }
    return data;
  };
  const List = SortableSearchableList<
    MergeRequest,
    MergeRequestOrdering,
    ItemProps
  >();

  let mrButtonLink = repositoryMergeRequestLink(group, repositoryName);
  if (moduleCode) {
    mrButtonLink = moduleMergeLink(moduleCode, group, repositoryName);
  }
  return (
    <div className={'CollaborationSpace--content'}>
      <div className={'repository branches'}>
        {moduleCode ? (
          <RepositoryBreadcrumbs repositoryType={'module'} />
        ) : (
          <RepositoryBreadcrumbs />
        )}
        <List
          orderings={mergeRequestOrderings}
          defaultOrdering={MergeRequestOrdering.LastCreated}
          data={mergeRequests}
          contentProps={(mr, i) => ({
            mr,
            group,
            repositoryName,
            moduleCode: moduleCode,
            key: mr.id,
          })}
          ContentComponent={MergeRequestListItem}
          search={(mr, searchString) => mr.title.includes(searchString)}
          sort={sortData}
          title={'Merge Requests'}
          button={{
            buttonText: 'Open Merge Request',
            link: mrButtonLink,
          }}
          style={{
            marginTop: '20px',
          }}
        />
      </div>
    </div>
  );
};

export default MergeRequests;
