import { defineMessages } from 'react-intl';

export default defineMessages({
  msgIndexTitle: {
    id: 'admin.index.title',
    defaultMessage: 'Administration',
  },
  msgUserDetailsStepTitle: {
    id: 'admin.userDetails.stepTitle',
    defaultMessage: 'User Details',
  },
  msgUserDetailsStepDescription: {
    id: 'admin.userDetails.stepDescription',
    defaultMessage: 'Enter details for the new user.',
  },
  msgUsersCompanyName: {
    id: 'admin.users.companyName',
    defaultMessage: 'Company Name',
  },
  msgUsersEmail: {
    id: 'admin.users.email',
    defaultMessage: 'Email',
  },
  msgUsersFirstName: {
    id: 'admin.users.firstName',
    defaultMessage: 'First Name',
  },
  msgUsersLastName: {
    id: 'admin.users.lastName',
    defaultMessage: 'Last Name',
  },
  msgUsersIsAdmin: {
    id: 'admin.users.isAdmin',
    defaultMessage: 'Administrator',
  },
  msgUsersMember: {
    id: 'admin.users.member',
    defaultMessage: 'Member',
  },
  msgUsersTitle: {
    id: 'admin.users.title',
    defaultMessage: 'Users',
  },
  msgUsersCredentialsTitle: {
    id: 'admin.users.credentials.title',
    defaultMessage: 'Credentials',
  },
  msgUsersCredentialsDescription: {
    id: 'admin.users.credentials.description',
    defaultMessage:
      'Enter the E-Mail and Password for the new user. ' +
      'The password needs to be at least 8 characters long ' +
      'and have at least one upper case and one lower case letter, as well as a digit.',
  },
  msgUsersValidationEmail: {
    id: 'admin.users.validation.email',
    defaultMessage: 'Please enter an E-Mail Address',
  },
  msgUsersValidationEmailFailed: {
    id: 'admin.users.validation.email.failed',
    defaultMessage: 'Please enter a valid E-Mail Address',
  },
  msgUsersValidationPassword: {
    id: 'admin.users.validation.password',
    defaultMessage: 'Please enter a Password',
  },
  msgUsersValidationPasswordFailed: {
    id: 'admin.users.validation.password.failed',
    defaultMessage: 'Please enter a valid Password.',
  },
  msgUsersValidationFirstname: {
    id: 'admin.users.validation.firstname',
    defaultMessage: 'Please enter the First Name',
  },
  msgUsersValidationLastname: {
    id: 'admin.users.validation.lastname',
    defaultMessage: 'Please enter the Last Name',
  },
  msgGroupsEdit: {
    id: 'admin.groups.edit',
    defaultMessage: 'Edit',
  },
  msgGroupsView: {
    id: 'admin.groups.view',
    defaultMessage: 'View',
  },

  usersOrigin: {
    id: 'admin.users.origin',
    defaultMessage: 'Users',
  },
  groupsOrigin: {
    id: 'admin.groups.origin',
    defaultMessage: 'Groups',
  },
  msgAddUser: {
    id: 'admin.user.add',
    defaultMessage: 'Add User',
  },
  msgAddGroup: {
    id: 'admin.group.add',
    defaultMessage: 'Add Group',
  },
  msgAddHabitat: {
    id: 'admin.habitat.add',
    defaultMessage: 'Add Habitat',
  },
});
