import React, { FC } from 'react';
import { colors, hashString } from '../../../atoms/avatar-icon/AvatarIcon';
import styles from './styles.module.scss';

type Props = {
  name?: string;
};

const ModuleIconFallback: FC<Props> = ({ name }) => {
  return (
    <div
      className={styles.fallbackAvatar}
      style={{
        backgroundColor: colors[hashString(name, colors.length)],
      }}
    >
      {(name || '')
        .split(' ')
        .map((w) => w.charAt(0))
        .slice(0, 2)
        .join('')}
    </div>
  );
};

export default ModuleIconFallback;
