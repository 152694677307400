import classNames from 'classnames';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { resetEventSource } from '../../../../redux/workbench/modules/connection.module';
import Button from '../../../atoms/button/Button';
import GoTo from '../../../organisms/header/profile-menu/GoTo';
import { logoutLink } from '../../../organisms/header/profile-menu/TopMenu';
import MainContainer from '../../../pages/main-container/MainContainer';

const ReloginRequired: FC<Record<string, any>> = (props) => {
  const dispatch = useDispatch();
  dispatch(resetEventSource());
  return (
    <MainContainer
      // additionalClassname={styles.reloginRequired}
      transparent={true}
      fullWidth={false}
    >
      <div className={styles.reloginRequired}>
        <div className={styles.innerContainer}>
          <span className={styles.headline}>
            Authentication against the Workbench failed.
            <br />
            Please Re-Login.
          </span>
          <div
            className={classNames(
              'start-server-button-parent',
              styles.buttonsParent
            )}
          >
            {/* @ts-ignore */}
            <GoTo link={logoutLink} className='' onClick={() => {}}>
              <Button color={'orange'} label={'Logout'} onClick={() => {}} />
            </GoTo>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ReloginRequired;
