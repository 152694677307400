import classNames from 'classnames';
import React, { FC } from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

import { AugurMenuIcons } from './icons';
import styles from './styles.module.scss';
import { AugurMenuEntry } from './types';

type Props = {
  entry: AugurMenuEntry;
  /** ID of the category this entry belongs to */
  categoryId: string;
  /** Index inside the given category */
  index: number;
  /** Is this entry currently selected? */
  isActive?: boolean;
  /** Callback if the entry was selected */
  handleSelect: () => void;
  isDisabled: boolean;
};

const EntryStatic: FC<Props> = (props) => {
  const { entry, categoryId, index, isActive, handleSelect, isDisabled } =
    props;

  const renderEnabled = () => {
    const Icon = AugurMenuIcons[entry.iconId];
    return (
      <div
        data-testid={entry.name}
        className={classNames(styles.entry, styles.static, {
          [styles.isActive]: isActive,
        })}
      >
        <div
          key={`${categoryId}_${index}`}
          className={styles.tabLink}
          onClick={handleSelect}
        >
          <div className={styles.tabLinkContent}>
            <div className={styles.icon}>
              <Icon size={16} />
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.label}>{entry.name}</div>
              {!!entry.error && (
                <div className={styles.errorIndicator} title={entry.error}>
                  <FiAlertOctagon size={16} color={'red'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDisabled = () => {
    const Icon = AugurMenuIcons[entry.iconId];
    return (
      <div className={classNames(styles.entry, styles.static, styles.disabled)}>
        <div className={classNames(styles.tabLink, styles.disabled)}>
          <div className={styles.disabledLink}>
            <div className={styles.icon}>
              <Icon size={16} />
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.label}>{entry.name}</div>
              {!!entry.error && (
                <div className={styles.errorIndicator} title={entry.error}>
                  <FiAlertOctagon size={16} color={'red'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isDisabled) {
    return renderDisabled();
  } else {
    return renderEnabled();
  }
};

export default EntryStatic;
