import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  CampaignOptimizationConstraintsConfig,
  ConfigCampaignOptimizationConstraintsConfig,
} from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { ElementOptionType } from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import InputElementSelect from '../../../../../atoms/react-hook-form-input-elements/input-element-select/InputElementSelect';

const CampaignOptimizationConstraintsConfigFormComponent: FC<
  ConfigFormComponentProps<CampaignOptimizationConstraintsConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } =
    useForm<ConfigCampaignOptimizationConstraintsConfig>({
      defaultValues: configValue,
    });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const channelOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type === SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  const communicationOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type ===
        SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  const communicationGroupOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type ===
        SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='channels'
        control={control}
        render={({ field }) => {
          return (
            <InputElementSelect
              value={
                field.value.source === 'input-element'
                  ? field.value.elementUuid
                  : ''
              }
              isInputElement={field.value.source === 'input-element'}
              onChange={(newValue) => {
                field.onChange(newValue);
                updateConfig();
              }}
              options={channelOptions}
              label={augurElementsMessages.channelsLabel}
              placeholder={augurElementsMessages.selectChannelElement}
            />
          );
        }}
      />
      <Controller
        name='communications'
        control={control}
        render={({ field }) => {
          return (
            <InputElementSelect
              value={
                field.value.source === 'input-element'
                  ? field.value.elementUuid
                  : ''
              }
              isInputElement={field.value.source === 'input-element'}
              onChange={(newValue) => {
                field.onChange(newValue);
                updateConfig();
              }}
              options={communicationOptions}
              label={augurElementsMessages.communicationsLabel}
              placeholder={augurElementsMessages.selectCommunicationsElement}
            />
          );
        }}
      />
      <Controller
        name='communicationGroups'
        control={control}
        render={({ field }) => {
          return (
            <InputElementSelect
              value={
                field.value.source === 'input-element'
                  ? field.value.elementUuid
                  : ''
              }
              isInputElement={field.value.source === 'input-element'}
              onChange={(newValue) => {
                field.onChange(newValue);
                updateConfig();
              }}
              options={communicationGroupOptions}
              label={augurElementsMessages.communicationGroupsLabel}
              placeholder={
                augurElementsMessages.selectCommunicationGroupsElement
              }
            />
          );
        }}
      />
    </div>
  );
};

export default CampaignOptimizationConstraintsConfigFormComponent;
