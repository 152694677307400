import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { ConfigLineChartConfig, LineChartConfig } from './type';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';

const LineChartConfigForm: FC<ConfigFormComponentProps<LineChartConfig>> = ({
  configValue,
  onConfigChange,
}) => {
  const { control, getValues } = useForm<ConfigLineChartConfig>({
    defaultValues: configValue,
  });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='xLabel.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label={augurElementsMessages.xAxisLabel}
            placeholder={augurElementsMessages.xAxis}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='yLabel.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label={augurElementsMessages.yAxisLabel}
            placeholder={augurElementsMessages.yAxis}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
    </div>
  );
};

export default LineChartConfigForm;
