import { ShapChartV1ReportData as ShapChartReportData } from 'common/dist/types/reportData/shapChartV1ReportData';

import { exampleSmall } from './single/stories/exampleSmall';
import { Config } from '../../../../../pages/augur/type';

export type { ShapChartReportData };

export type ShapChartConfig = {
  //There is no specific configuration
};

export type ConfigShapChartConfig = Config<ShapChartConfig>;

export const configTypeShapChart = `{}`;

export const reportDataTypeShapChart = `{
        feature: string;
        featureType: 'numerical' | 'categorical';
        distribution: [number, number][];
    }[]`;

export const defaultConfigShapChart: ConfigShapChartConfig = {};

export const defaultReportDataDefaultShapChart: ShapChartReportData = exampleSmall;
