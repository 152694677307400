import classNames from 'classnames';
import React, { FC, SyntheticEvent } from 'react';
import { MessageDescriptor } from 'react-intl';

import ReactCombobox from 'react-widgets/lib/Combobox';
import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';
import InputError from '../../input-error/InputError';

export interface ComboboxProps {
  data: unknown[]; //An array of possible values for the Combobox
  dataKey?: string | ((dataItem?: unknown) => string); //Provides the value of the data items (e.g. ID)
  textField?: string | ((dataItem?: unknown) => string); //Provides the text content of the data items
  defaultValue?: string;
  onSelect?: (
    dataItem: unknown,
    metadata: { originalEvent: SyntheticEvent }
  ) => void;
  label?: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  description?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  disabledOptions?: boolean | unknown[];
  dropUp?: boolean; //Direction of the popup
  open?: boolean; //Visibility of the popup
  onFocusOpen?: boolean; //Opens the popup on focus
  readOnly?: boolean;
  autoFocus?: boolean;
  showInfo?: boolean;
  autoSelectMatches?: boolean; //Selects the current typed value automatically if it matches a data item
  filter?: 'startsWith' | 'eq' | 'contains';
}

export type Props = ComboboxProps & Partial<FieldInputProps<string>>;
// Don't use this directly, use the intld version
const Combobox: FC<DeIntl<Props>> = (props) => {
  const {
    placeholder,
    label,
    wrapMultipleErrorLines,
    isTouched = false,
    isDirty = false,
    invalid = false,
    error,
    disabled,
    onChange,
    name,
    value,
    data,
    dataKey,
    textField,
    dropUp,
    onSelect,
    open,
    onFocusOpen,
    readOnly,
    autoFocus,
    autoSelectMatches,
    filter,
    disabledOptions,
  } = props;
  const fieldClassName = classNames(styles.comboboxInput, {
    [styles.error]: isTouched && error,
    [styles.success]: !invalid && isDirty,
  });

  const [localOpen, setLocalOpen] = React.useState(false);

  function renderBody() {
    return (
      <>
        <ReactCombobox
          containerClassName={fieldClassName}
          placeholder={placeholder}
          disabled={disabledOptions}
          onChange={onChange}
          name={name}
          value={value || ''}
          data={data}
          dataKey={dataKey}
          textField={textField}
          dropUp={dropUp}
          onSelect={onSelect}
          open={localOpen || open}
          readOnly={readOnly}
          autoFocus={autoFocus}
          autoSelectMatches={autoSelectMatches}
          aria-invalid={error ? 'true' : 'false'}
          filter={filter}
          onFocus={() => {
            if (onFocusOpen) setLocalOpen(true);
          }}
          onBlur={() => {
            setLocalOpen(false);
          }}
        />
        {!invalid && !disabled && isDirty && (
          <div className={styles.comboboxSuccess}>
            <span className='icon-ok' />
          </div>
        )}
      </>
    );
  }

  function renderHeader() {
    return (
      <div className={styles.header}>
        {label && <p className={styles.label}>{label}</p>}
        {error && (
          <div
            className={styles.error}
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.combobox}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

export const IntlCombobox: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={Combobox} props={props} />;
};

export default IntlCombobox;
