import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { resourceRoutes } from './routes';
import RunningApps from './running-apps/RunningAppsLogin';
import RunningWorkbench from './running-workbench/RunningWorkbenchLogin';
import { RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';

class Resources extends Component<RouteComponentProps> {
  render() {
    return (
      <Fragment>
        <div className={styles.Resources}>
          <Switch>
            <Route
              path={`${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`}
              component={RunningWorkbench}
            />
            <Route
              path={`${resourceRoutes.basePath}/${resourceRoutes.runningApps.path}`}
              component={RunningApps}
            />
            <Redirect
              exact
              path={resourceRoutes.basePath}
              to={`${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`}
            />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Resources);
