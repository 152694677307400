import React, { FC } from 'react';

import { useDimensions } from '../../../../../../../utils';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import styles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { ImageReportData } from '../type';

export type ImageConfig = {};

export type Props = ImageReportData & ImageConfig;

export const Image: FC<Props> = ({ image }) => {
  const [ref, { height, width }] = useDimensions<HTMLDivElement>();

  return (
    <div ref={ref} className={styles.chart}>
      <img
        style={{ width: width, height: height, objectFit: 'contain' }}
        alt='Image'
        src={`data:image/png;base64, ${image}`}
      />
    </div>
  );
};

export const ImageSingle: FC<ReportElementProps<ImageReportData, ImageConfig>> =
  buildSingleReportWrapper<ImageReportData, ImageConfig>(Image);
