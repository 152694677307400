import { defineMessages } from 'react-intl';

export default defineMessages({
  elementInvalid: {
    id: 'augur.elements.invalid',
    defaultMessage:
      'Element is invalid. Open the sidebar to fix the invalid field(s).',
  },
  elementNoReports: {
    id: 'augur.elements.no_reports',
    defaultMessage: 'No reports selected.',
  },
  elementInvalidReportData: {
    id: 'augur.elements.invalid_report_data',
    defaultMessage: `Invalid report data. The json data might be corrupted or the specified key doesn't exist.`,
  },
  elementIsHardCoded: {
    id: 'augur.elements.is_hard_coded',
    defaultMessage:
      'The value is currently hard-coded. You can overwrite it by selecting an element from the dropdown.',
  },
  elementIsInputElement: {
    id: 'augur.elements.is_input-element',
    defaultMessage: 'Selected element will be used as input-element.',
  },
  xAxis: {
    id: 'augur.elements.xAxis',
    defaultMessage: 'X-Axis',
  },
  xAxisLabel: {
    id: 'augur.elements.xAxis.label',
    defaultMessage: 'X-Axis Label',
  },
  yAxis: {
    id: 'augur.elements.yAxis',
    defaultMessage: 'Y-Axis',
  },
  yAxisLabel: {
    id: 'augur.elements.yAxis.label',
    defaultMessage: 'Y-Axis Label',
  },
  barChartLabel: {
    id: 'augur.elements.barChart.label',
    defaultMessage: 'Label',
  },
  barChartLabelPlaceholder: {
    id: 'augur.elements.barChart.label.placeholder',
    defaultMessage: 'Enter legend label',
  },
  barChartColor: {
    id: 'augur.elements.barChart.color',
    defaultMessage: 'Color',
  },
  barChartColorPlaceholder: {
    id: 'augur.elements.barChart.color.placeholder',
    defaultMessage: 'Enter legend color',
  },
  channelsLabel: {
    id: 'augur.elements.channels.label',
    defaultMessage: 'Channels',
  },
  selectChannelElement: {
    id: 'augur.elements.channels.select',
    defaultMessage: 'Select Channels Element',
  },
  propensitiesLabel: {
    id: 'augur.elements.propensities.label',
    defaultMessage: 'Propensities',
  },
  selectPropensitiesElement: {
    id: 'augur.elements.propensities.select',
    defaultMessage: 'Select Propensities Element',
  },
  communicationsLabel: {
    id: 'augur.elements.communications.label',
    defaultMessage: 'Communications',
  },
  selectCommunicationsElement: {
    id: 'augur.elements.communications.select',
    defaultMessage: 'Select Communications Element',
  },
  communicationGroupsLabel: {
    id: 'augur.elements.communicationGroups.label',
    defaultMessage: 'CommunicationGroups',
  },
  selectCommunicationGroupsElement: {
    id: 'augur.elements.communicationGroups.select',
    defaultMessage: 'Select CommunicationGroups Element',
  },
  taxonomyLabel: {
    id: 'augur.elements.taxonomy.label',
    defaultMessage: 'Target Taxonomy',
  },
  selectTaxonomyElement: {
    id: 'augur.elements.taxonomy.select',
    defaultMessage: 'Select Target Taxonomy Element',
  },
  label: {
    id: 'augur.elements.label',
    defaultMessage: 'Label',
  },
  enterLabel: {
    id: 'augur.elements.label.enter',
    defaultMessage: 'Enter Label',
  },
  placeholder: {
    id: 'augur.elements.placeholder',
    defaultMessage: 'Placeholder',
  },
  value: {
    id: 'augur.elements.value',
    defaultMessage: 'Value',
  },
  enterValue: {
    id: 'augur.elements.value',
    defaultMessage: 'Value',
  },
  dependants: {
    id: 'augur.elements.dependants',
    defaultMessage: 'Dependants',
  },
  selectDependants: {
    id: 'augur.elements.dependants.select',
    defaultMessage: 'Select Dependants',
  },
  heatmapShowNumbers: {
    id: 'augur.elements.heatmap.showNumbers',
    defaultMessage: 'Show Numbers',
  },
  primaryLabel: {
    id: 'augur.element.primaryLabel',
    defaultMessage: 'Primary Label',
  },
  secondaryLabel: {
    id: 'augur.element.secondaryLabel',
    defaultMessage: 'Secondary Label',
  },
  samplingStrategyTraining: {
    id: 'augur.element.samplingStrategy.training',
    defaultMessage: 'Training',
  },
  samplingStrategyEvaluation: {
    id: 'augur.element.samplingStrategy.evaluation',
    defaultMessage: 'Evaluation',
  },
  s3BucketLabel: {
    id: 'augur.elements.s3Bucket.label',
    defaultMessage: 'S3 Bucket',
  },
  selectS3Bucket: {
    id: 'augur.elements.s3Bucket.select',
    defaultMessage: 'Select S3 Bucket',
  },
  showTable: {
    id: 'augur.elements.showTable',
    defaultMessage: 'Show Table',
  },
  cassandraKeyspaceLabel: {
    id: 'augur.elements.cassandraKeyspace.label',
    defaultMessage: 'Cassandra Keyspace',
  },
  selectCassandraKeyspace: {
    id: 'augur.elements.cassandraKeyspace.select',
    defaultMessage: 'Select Cassandra Keyspace',
  },
  s3FileLabel: {
    id: 'augur.elements.s3File.label',
    defaultMessage: 'S3 File',
  },
  selectS3File: {
    id: 'augur.elements.s3File.select',
    defaultMessage: 'Select S3 File',
  },
  cassandraTableLabel: {
    id: 'augur.elements.cassandraTable.label',
    defaultMessage: 'Cassandra Table',
  },
  selectCassandraTable: {
    id: 'augur.elements.cassandraTable.select',
    defaultMessage: 'Select Cassandra Table',
  },
  isOptional: {
    id: 'augur.elements.isOptional',
    defaultMessage: 'Optional',
  },
  isMulti: {
    id: 'augur.elements.isMulti',
    defaultMessage: 'Multi',
  },
  isClearable: {
    id: 'augur.elements.isClearable',
    defaultMessage: 'Clearable',
  },
  isSearchable: {
    id: 'augur.elements.isSearchable',
    defaultMessage: 'Searchable',
  },
});
