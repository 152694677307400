// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataSourceSettings {}

export interface CassandraSettings extends DataSourceSettings {
  host: string;
  port: number;
  user: string;
  password: string;
  datacenter: string;
}

export interface S3Settings extends DataSourceSettings {
  // Host with optional protocol https://s3.eu-central-1.amazonaws.com. Defaults to https
  host: string;
  port: number;
  accessKey: string;
  secretKey: string;
  // Amazon region: us-east-1, eu-central-1
  region: string;
}

export const DSType = {
  S3: 's3',
  Cassandra: 'cassandra',
} as const;

export type DSTypeType = typeof DSType[keyof typeof DSType];

export const DSRoles = {
  DefaultCassandra: 'default_cassandra',
  HostMount: 'host_mount',
  RGW: 'rgw',
} as const;

export type DSRolesType = typeof DSRoles[keyof typeof DSRoles];

export const DSRolesToSpeaking = {
  default_cassandra: 'Default Cassandra',
  host_mount: 'Host Mount MinIO',
  rgw: 'Ceph Rados Gateway',
};

// TODO create two subtypes? Not even scala backend does that...
export type DataSource = {
  code: string;
  name: string;
  ds_type: DSTypeType;
  role: DSRolesType;
  settings: DataSourceSettings;
  created_at: string;
};

export function typeToSpeaking(type: DSTypeType): string {
  switch (type) {
    case 'cassandra':
      return 'Apache Cassandra';
    case 's3':
      return 'S3 Object Storage';
    default:
      return type;
  }
}
