// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-channels/v1/type.ts' --type 'ConfigCampaignOptimizationChannelsConfig'
export const schemaConfigCampaignOptimizationChannels = {
  $ref: '#/definitions/ConfigCampaignOptimizationChannelsConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<CampaignOptimizationChannelsConfig>': {
      additionalProperties: {
        $ref: '#/definitions/ConfigEntry%3Cnever%3E',
      },
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigCampaignOptimizationChannelsConfig: {
      $ref: '#/definitions/Config%3CCampaignOptimizationChannelsConfig%3E',
    },
    'ConfigEntry<never>': {
      additionalProperties: false,
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      properties: {
        elementUuid: {
          type: 'string',
        },
        source: {
          const: 'input-element',
          type: 'string',
        },
      },
      required: ['source', 'elementUuid'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-channels/v1/type.ts' --type 'PartialCampaignOptimizationChannelsAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationChannels = {
  $ref: '#/definitions/PartialCampaignOptimizationChannelsAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    Channel: {
      additionalProperties: false,
      properties: {
        description: {
          description: 'Optional description of the channel',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the channel',
          type: 'string',
        },
        name: {
          description: 'Name of the channel',
          type: 'string',
        },
      },
      required: ['id', 'name'],
      type: 'object',
    },
    PartialCampaignOptimizationChannelsAugurSettings: {
      items: {
        $ref: '#/definitions/Channel',
      },
      type: 'array',
    },
  },
};
