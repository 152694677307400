import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  nextPage,
  prevPage,
  setActivePage,
  setReportCount,
} from './multiReportWrapper.state';

export type MultiReportWrapperHookProps = {
  activePage: string;
  setActivePage: (activePage: string) => void;
  prevPage: () => void;
  nextPage: () => void;
};

export function useMultiReportWrapperHook(
  augurReports: string[]
): MultiReportWrapperHookProps {
  const dispatch = useDispatch();

  const activePage = useSelector(
    (state: { timeline: { activePage: string } }) => state.timeline.activePage
  );

  const reportCount = useSelector(
    (state: { timeline: { reportCount: string[] } }) =>
      state.timeline.reportCount
  );
  useEffect(() => {
    if (augurReports.length !== reportCount.length) {
      dispatch(setReportCount(augurReports));
      const active = augurReports.find((report) => report === activePage);
      if (_.isEmpty(activePage) || !active)
        dispatch(setActivePage(augurReports[0]));
      else {
        dispatch(setActivePage(active));
      }
    }
  }, [activePage, augurReports, dispatch, reportCount]);

  return {
    activePage,
    setActivePage: (page: string) => dispatch(setActivePage(page)),
    prevPage: () => dispatch(prevPage()),
    nextPage: () => dispatch(nextPage()),
  };
}
