import {
  contentArrayToPath,
  contentPathToArray,
} from 'common/dist/utils/workbench/content';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { WORKBENCH_FILENAMES } from './part-right/config';
import { useContent } from '../../core/api/workbench/content';
import { RepoMeta } from '../../store/workbench/state.types';

export const useSelectedDirPath = (): string[] => {
  const location = useLocation();

  const queryParameter = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { path: string };

  return contentPathToArray(queryParameter.path || '/');
};

/**
 * Return the RepoMeta for the selected dir if it exists
 */
export const useSelectedDirRepoMeta = (): RepoMeta | undefined => {
  const selectedDirPath = useSelectedDirPath();
  //Get the index of the first asr file found
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  //Take only the path up to the asr file
  const { data } = useContent(
    contentArrayToPath(
      [
        ...selectedDirPath.slice(0, index + 1),
        WORKBENCH_FILENAMES.REPOSITORY_META,
      ],
      false
    ),
    index !== -1
  );
  if (typeof data?.content === 'string') {
    return JSON.parse(data.content);
  } else {
    return undefined;
  }
};
