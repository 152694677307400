import classNames from 'classnames';
import React, { FC, useState } from 'react';

import styles from './styles.module.scss';
import Header from '../organisms/header/header/Header.container';
import MainMenu from '../organisms/main-menu/MainMenu';

type Props = {
  children: React.ReactNode;
};

const ParentLayout: FC<Props> = ({ children }) => {
  const [isMenuCollapsed, setMenuCollapsed] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles.parentLayout, {
        [styles.menuCollapsed]: isMenuCollapsed,
      })}
    >
      <Header
        isMenuCollapsed={isMenuCollapsed}
        setMenuCollapsed={setMenuCollapsed}
      />
      <div className={styles.parentLayoutBody}>
        <div className={styles.parentLayoutLeft}>
          <MainMenu
            isMenuCollapsed={isMenuCollapsed}
            setMenuCollapsed={setMenuCollapsed}
          />
        </div>
        <div className={styles.parentLayoutRight}>{children}</div>
      </div>
    </div>
  );
};
export default ParentLayout;
