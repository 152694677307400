import { Summary } from 'common/dist/types/habitat';
import { connect } from 'react-redux';

import RealtimeSummary from './RealtimeSummary';
import { fetchSummary } from '../../../redux/modules/dashboard.summary.module';
import { RootState } from '../../../store/store';

export function mapStateToProps({ summary }: RootState): { summary: Summary } {
  const filteredRealtime = summary.map((element) => {
    return {
      ...element,
      augurs: element.augurs.filter(
        (augur) =>
          augur.moduleVersion.config.generalConfiguration
            .supportsRealtimePrediction
      ),
    };
  });
  return {
    summary: filteredRealtime,
  };
}

export const mapDispatchToProps = {
  fetchSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeSummary);
