import classNames from 'classnames';
import {
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import AddCommunicationGroup from './AddCommunicationGroup';
import { CommunicationGroupsErrorType } from './CampaignOptimizationCommunicationGroups';
import styles from './styles.module.scss';
import Table, { RenderColumn } from '../../../../../molecules/table/Table';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

type Props = {
  /** List of communications defined in a previous step */
  communications: Communication[];
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
  value: CommunicationGroup[];
  onChange: (updatedCommunicationGroups: CommunicationGroup[]) => void;
  onBlur?: React.FocusEventHandler;
  invalid?: boolean;
  error?: DeepPartial<CommunicationGroupsErrorType>;
  readOnly?: boolean;
};

const CommunicationGroupsTable: FC<Props> = (props) => {
  const {
    communications,
    addedNewRow,
    rowIndex,
    value,
    onChange,
    onBlur,
    invalid,
    error,
    readOnly,
  } = props;

  const communicationsNameDict = _.chain(communications)
    .keyBy('id')
    .mapValues('name')
    .value();

  const initialVisible = {
    index: addedNewRow ? value.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : value.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex, value.length]);
  const renderColumns: RenderColumn<
    CommunicationGroup,
    keyof CommunicationGroup
  >[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      // @ts-ignore
      renderCell: (name: string, communicationGroup: CommunicationGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communicationGroup.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      // @ts-ignore
      renderCell: (
        description: string,
        communicationGroup: CommunicationGroup
      ) => (
        <span
          className={classNames({
            [styles.erroneous]:
              !!error?.rows?.[communicationGroup.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'communicationIds',
      renderHeader: () => <span>Communications</span>,
      // @ts-ignore
      renderCell: (
        communicationIds: string[],
        communicationGroup: CommunicationGroup
      ) => {
        if (!communicationIds) return null;
        return (
          <div
            className={classNames(styles.communicationsList, {
              [styles.erroneous]:
                !!error?.rows?.[communicationGroup.id]?.communicationIds,
            })}
          >
            {communicationIds.map((cId, i) => (
              <span
                className={classNames(styles.communicationEntry, {
                  [styles.erroneous]:
                    !!error?.rows?.[communicationGroup.id]?.communicationIds,
                })}
                key={i}
              >
                {communicationsNameDict[cId]}
              </span>
            ))}
          </div>
        );
      },
    },
  ];

  const renderClickedRow = (
    element: CommunicationGroup,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddCommunicationGroup
                communications={communications}
                rowIndex={rowIndex}
                constraintId={element.id}
                removableRows={visible.shown}
                onRemoveRow={(removedRow) => {
                  const updatedCommunicationGroups = value.filter(
                    (commGroup) => commGroup.id !== removedRow.id
                  );
                  onChange?.(updatedCommunicationGroups);
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                error={error}
                readOnly={readOnly}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={value}
      renderColumns={renderColumns}
      showHeader
      removableRows={true}
      verticalAlignMiddle
      roundedBorder
      onRemoveRow={(removedRow) => {
        const updatedCommunicationGroups = value.filter(
          (commGroup) => commGroup.id !== removedRow.id
        );
        onChange?.(updatedCommunicationGroups);
      }}
      addlRowClassname={(
        _rowIndex: number,
        communicationGroup: CommunicationGroup
      ) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!error?.rows?.[communicationGroup.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(_row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
      readOnly={readOnly}
    />
  );
};

export default CommunicationGroupsTable;
