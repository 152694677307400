import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealtimeSummary } from 'common/dist/types/orchestration/realtime';
import {
  fetchRealtimeSummary,
  fetchRealtimeSummaryAugur,
} from '../../core/api/orchestration';

export type RealtimeAugurState = RealtimeSummary[];

export const initial: RealtimeAugurState = [];

export const test: RealtimeAugurState = [];

export const fetchSummary = createAsyncThunk<RealtimeSummary[]>(
  'fetchRealtimeSummary',
  async (_, thunkAPI) => {
    const { response, error } = await fetchRealtimeSummary();

    if (response) {
      return response;
    } else {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const fetchAugurSummary = createAsyncThunk<
  RealtimeSummary | undefined,
  { augurCode: string }
>('fetchAugurRealtimeSummary', async ({ augurCode }, thunkAPI) => {
  const { response, error } = await fetchRealtimeSummaryAugur(augurCode);

  if (response) {
    return response['rest'];
  } else {
    return thunkAPI.rejectWithValue({ error });
  }
});

const slice = createSlice({
  name: 'realtimeAugur',
  initialState: initial,

  reducers: {
    createRealtimeSummary: (state, action: PayloadAction<RealtimeSummary>) => {
      state.push(action.payload);
    },
    updateRealtimeSummary: (state, action: PayloadAction<RealtimeSummary>) => {
      const idx = state.findIndex(
        (s) => s.augurCode === action.payload.augurCode
      );
      if (idx !== -1) {
        state[idx] = action.payload;
      } else {
        state.push(action.payload);
      }
    },
    deleteRealtimeSummary: (state, action: PayloadAction<string>) => {
      const augurCode = action.payload;
      const index = state.findIndex((s) => s.augurCode === augurCode);
      if (index !== -1) state.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSummary.fulfilled, (state, action) => {
      // Return a new state and throw away the immer draft state. Assigning to it does nothing
      return action.payload;
    });
    builder.addCase(fetchAugurSummary.fulfilled, (state, action) => {
      if (!action.payload) return;
      const idx = state.findIndex(
        (s) => s.augurCode === action.payload.augurCode
      );
      if (idx !== -1) {
        state[idx] = action.payload;
      } else {
        state.push(action.payload);
      }
    });
  },
});

const realtimeAugurReducer = slice.reducer;
const {
  createRealtimeSummary,
  updateRealtimeSummary,
  deleteRealtimeSummary,
} = slice.actions;

export {
  realtimeAugurReducer,
  createRealtimeSummary,
  updateRealtimeSummary,
  deleteRealtimeSummary,
};
