import React, { FC, ReactNode, useEffect, useRef } from 'react';

export type Props = {
  onClickOutside: () => void;
  children: ReactNode;
};

/**
 * https://stackoverflow.com/a/42234988
 * Component that alerts if you click outside of it
 */
export const ClickOutsideDetector: FC<Props> = ({
  onClickOutside,
  children,
}) => {
  const wrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideDetector;
