import classNames from 'classnames';
import { GroupType } from 'common/src/types/pipeline';
import React, { Component } from 'react';

import Node from './Node';

export type Props = {
  data: GroupType;
};

export default class Group extends Component<Props, {}> {
  render() {
    const { data } = this.props;

    return (
      <div className={'PipelineTuningChart--group'}>
        <div className={'PipelineTuningChart--group-title-container'}>
          <span className={'PipelineTuningChart--group-title'}>
            {data.displayName}
          </span>
        </div>
        {data?.nodes.map((node) => (
          <div
            key={node.id}
            className={classNames(
              'PipelineTuningChart--group-node',
              {
                'PipelineTuningChart--group-node-selected':
                  data.selectedNodeId === node.id,
              },
              {
                'PipelineTuningChart--group-node-type-classifier':
                  node.nodeType === 'classifier',
              },
              {
                'PipelineTuningChart--group-node-type-transformer':
                  node.nodeType === 'transformer',
              }
            )}
            onClick={() => data.setSelectedNode && data.setSelectedNode(node)}
          >
            <Node data={node} />
          </div>
        ))}
      </div>
    );
  }
}
