import React, { FC } from 'react';
import { EnrichedExhaustion } from './ConstraintExhaustion';
import styles from './styles.module.scss';
import { SPEAKING_OPERATOR } from '../../../../settings-elements/campaign-optimization-constraints/v1/common';
import ExhaustionBar from './ExhaustionBar';
import _ from 'lodash';
import { FormattedNumber } from 'react-intl';

type Props = {
  enrichedExhaustion: EnrichedExhaustion;
};

const Exhaustion: FC<Props> = ({ enrichedExhaustion }) => {
  const percentage = enrichedExhaustion?.percentage;

  return (
    <div className={styles.exhaustion}>
      <div className={styles.speaking}>
        <FormattedNumber value={enrichedExhaustion?.exhaustion} />
        <span> {SPEAKING_OPERATOR[enrichedExhaustion?.operator]} </span>
        <FormattedNumber value={enrichedExhaustion?.value} />
      </div>

      <div className={styles.exhaustionBarContainer}>
        <ExhaustionBar
          percentage={percentage}
          operator={enrichedExhaustion?.operator}
        />
        <span className={styles.percentage}>
          <FormattedNumber
            value={percentage}
            style={'percent'}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        </span>
      </div>
    </div>
  );
};

export default Exhaustion;
