import { defineMessages } from 'react-intl';

export default defineMessages({
  pagingHeaderLabelDefault: {
    id: 'paging.header.label.default',
    defaultMessage: 'entries',
  },
  pagingHeaderInfoDefault: {
    id: 'paging.header.info.default',
    defaultMessage: 'Showing {label} {from}-{to}',
  },
  pagingHeaderInfoTotal: {
    id: 'paging.header.info.total',
    defaultMessage: 'Showing {from}-{to} of {total} {label}',
  },
  pagingHeaderInfoNoMoreItems: {
    id: 'paging.header.info.no_more_items',
    defaultMessage: 'No more items to show',
  },
});
