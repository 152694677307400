import classNames from 'classnames';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { JobGroupInputType, JobScheduleType } from 'common/dist/types/job';
import React, { FC } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CProps } from './JobGroupSchedule.container';
import styles from './styles.module.scss';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import Button from '../../../atoms/button/Button';
import { JOB_GROUP_VARIANTS } from '../../../orchestration/details/job-group-details/JobGroupDetails';
import ScheduleInfo from '../../../orchestration/job-schedules/ScheduleInfo';
import { orchestrationRoutes } from '../../../orchestration/routes';
import JobGroupTopologyChart from '../job-group-topology-chart/JobGroupTopologyChart';

export type Props = {
  /** Code of the schedule to display */
  scheduleCode: string;
  /** The schedule to render */
  schedule: {
    /** Description of the scheduled JobGroup */
    jobGroupInput: JobGroupInputType;
  } & JobScheduleType;
};

/**
 * Renders a single schedule
 */
const JobGroupSchedule: FC<Props & CProps> = ({
  schedule,
  scheduleCode,
  showDeleteSchedule,
  augurNames,
  codeCapsuleNames,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { jobGroupInput: jobGroup, createdBy } = schedule;

  return (
    <div
      className={classNames(styles.item, styles.schedule)}
      onClick={() => {
        dispatch(setDetailsOrigin(history.location.pathname));
        history.push(
          `${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobSchedule}/${schedule.scheduleCode}`
        );
      }}
    >
      <div className={classNames([styles.topology, styles.grid1_1])}>
        <JobGroupTopologyChart
          jobs={jobGroup.jobs}
          jobGroupTopology={jobGroup.jobGroupTopology}
          showJobStatus={false}
          augurNames={augurNames}
          codeCapsuleNames={codeCapsuleNames}
          slim
        />
      </div>
      <div className={classNames([styles.info, styles.grid1_2])}>
        <strong className={styles.field}>{jobGroup.name || ''}</strong>
        <div className={styles.description}>
          <div className={styles.box}>{jobGroup.description || ''}</div>
          <div className={styles.tooltip}>
            <span className={styles.tooltipText}>
              {jobGroup.description || ''}
            </span>
          </div>
        </div>
      </div>
      <div className={classNames([styles.info, styles.grind1_3])}>
        <ScheduleInfo
          trigger={schedule.trigger}
          trigDelayed={schedule.trigDelayed}
          trigTimed={schedule.trigTimed}
          trigCron={schedule.trigCron}
        />
      </div>
      <div className={classNames([styles.info, styles.grid1_4])}>
        {schedule.trigger === 'timed' && (
          <p>
            {intl.formatDate(schedule?.nextExecution) +
              ' ' +
              intl.formatTime(schedule?.nextExecution)}
          </p>
        )}
        {schedule.trigger === 'delayed' && (
          <p>
            {intl.formatDate(schedule?.trigDelayed) +
              ' ' +
              intl.formatTime(schedule?.trigDelayed)}
          </p>
        )}
      </div>
      <div className={classNames([styles.userIcon, styles.grid1_5])}>
        {createdBy && <AvatarIconContainer userId={createdBy} />}
      </div>
      <div className={classNames([styles.buttons, styles.grid1_6])}>
        <div
          className={styles.buttonsWrapper}
          onClick={(evt) => evt.stopPropagation()}
        >
          <Button
            Icon={() => <FiEdit size={18} />}
            form={'squared'}
            linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editSchedule.path}/${scheduleCode}`}
            title={orchestrationMessages.scheduleEdit}
          />
          <Button
            Icon={FiTrash2}
            form={'squared'}
            onClick={() => showDeleteSchedule(scheduleCode)}
            title={orchestrationMessages.scheduleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default JobGroupSchedule;
