import { connect } from 'react-redux';
import RepositoryInfo from './RepositoryInfo';
import { gitActiveBranch } from '../../../../../redux/workbench/modules/container-interactions.module';
import { loadRepoMeta } from '../../../../../redux/workbench/modules/notebook.module';
import * as selector from '../../../../../redux/selectors/user.selector';

export function mapStateToProps(state) {
  return {
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    repoMetas: state.workbench.repoMetas,
    currentUserId: selector.currentUser(state).id,
  };
}

export const mapDispatchToProps = {
  gitActiveBranch,
  loadRepoMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryInfo);
