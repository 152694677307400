import { REPO_TYPE } from 'common/dist/types/repository';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isAsyncValidating, isValid, reduxForm } from 'redux-form';

import ForkRepositoryWizard from './ForkRepositoryWizard';
import {
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldName,
  fieldType,
  form,
  formName,
} from './form';
import { fetchSingleCodeCapsule } from '../../../../../redux/modules/codeCapsules.module';
import { forkRepository } from '../../../../../redux/workbench/modules/collab.module';
import { fetchRepoDetails } from '../../../../../redux/workbench/modules/collab.repository.module';
import { loadRepoMeta } from '../../../../../redux/workbench/modules/notebook.module';
import { RepoDetails } from '../../../../../store/workbench/state.types';
import { selectValue } from '../../../../collaborationSpace/wizards/repository-add/selectorUtils';
import { WORKBENCH_FILENAMES } from '../../config';

export function mapStateToProps(state, { location }) {
  const path = qs.parse(location.search, { ignoreQueryPrefix: true })
    .path as string;
  const cleanedPath = path.startsWith('/') ? path.slice(1) : path; //remove the leading slash, should become obsolete with useSelectedDirPath hook
  const repoMeta =
    state.workbench.repoMetas[
      `${cleanedPath}/${WORKBENCH_FILENAMES.REPOSITORY_META}`
    ];
  const repoDetails: RepoDetails = state.workbench.collab.repoDetails?.data;
  const initialValues = repoDetails && {
    [fieldType]: repoDetails.repoType,
    [fieldDescription]: repoDetails.repoDescription,
    [fieldCodeCapsuleHabitat]:
      repoMeta?.repoType === REPO_TYPE.CODE_CAPSULE
        ? {
            code:
              state.codeCapsules.perCode[repoMeta?.codeCapsuleCode]?.data
                ?.habitatCode,
            name:
              state.codeCapsules.perCode[repoMeta?.codeCapsuleCode]?.data
                ?.habitatName,
          }
        : undefined,
  };
  return {
    repositoryName: selectValue(state, formName, fieldName),
    repositoryType: selectValue(state, formName, fieldType),
    repositoryDescription: selectValue(state, formName, fieldDescription),
    codeCapsuleHabitat: selectValue(state, formName, fieldCodeCapsuleHabitat),
    currentUserCode: state.currentUser.code,
    accountCode: state.currentUser.accountCode,
    contentInCurrentDir: state.workbench.collab.content.data,
    formValid: isValid(formName)(state),
    isAsyncValidating: isAsyncValidating(formName)(state),
    infoFilePath: `${cleanedPath}/${WORKBENCH_FILENAMES.REPOSITORY_META}`,
    repoMeta,
    initialValues,
    repoDetails,
  };
}

export const mapDispatchToProps = {
  forkRepository,
  loadRepoMeta,
  fetchRepoDetails,
  fetchSingleCodeCapsule,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
    //@ts-ignore
  )(reduxForm(form)(ForkRepositoryWizard))
);
