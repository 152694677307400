import { createAction } from 'redux-act';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import { UPLOAD_WIZARDS } from '../../components/dataManagement/cassandra/upload/uploadWizards';
import { sendNotification } from './notifications.module';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';
import { keyspaceNameFromResource } from 'common/dist/constants/keycloak';
import keycloak, { updateToken } from '../../../keycloak';
import notificationsMsgs from 'common/dist/messages/notifications';

export const fetchCassandraKeyspaces = createAction(
  'fetch cassandra keyspaces',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchCassandraKeyspacesSuccess = createAction(
  'fetch cassandra keyspaces - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchCassandraKeyspacesFailure = createAction(
  'fetch cassandra keyspaces - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const fetchCassandraPermissions = createAction(
  'fetch cassandra permissions',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchCassandraPermissionsSuccess = createAction(
  'fetch cassandra permissions - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchCassandraPermissionsFailure = createAction(
  'fetch cassandra permissions - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const fetchCassandraCredentials = createAction(
  'fetch cassandra credentials',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchCassandraCredentialsSuccess = createAction(
  'fetch cassandra credentials - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchCassandraCredentialsFailure = createAction(
  'fetch cassandra credentials - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const clearCassandraCredentials = createAction(
  'clear cassandra credentials',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchCassandraTables = createAction(
  'fetch cassandra tables',
  (dataSourceCode, keyspaceName) => ({ dataSourceCode, keyspaceName })
);

export const fetchCassandraTablesSuccess = createAction(
  'fetch cassandra tables - success',
  (data, dataSourceCode, keyspaceName) => ({
    data,
    dataSourceCode,
    keyspaceName,
  })
);

export const fetchCassandraTablesFailure = createAction(
  'fetch cassandra tables - failure',
  (error, dataSourceCode, keyspaceName) => ({
    error,
    dataSourceCode,
    keyspaceName,
  })
);

export const fetchCassandraTableSample = createAction(
  'fetch cassandra table sample',
  (dataSourceCode, keyspaceName, tableName) => ({
    dataSourceCode,
    keyspaceName,
    tableName,
  })
);

export const fetchCassandraTableSampleSuccess = createAction(
  'fetch cassandra table sample - success',
  (data, dataSourceCode, keyspaceName, tableName) => ({
    data,
    dataSourceCode,
    keyspaceName,
    tableName,
  })
);

export const fetchCassandraTableSampleFailure = createAction(
  'fetch cassandra table sample - failure',
  (error, dataSourceCode, keyspaceName, tableName) => ({
    error,
    dataSourceCode,
    keyspaceName,
    tableName,
  })
);

export const cleanupCassandraTableSample = createAction(
  'cleanup cassandra table sample',
  (dataSourceCode, keyspaceName, tableName) => ({
    dataSourceCode,
    keyspaceName,
    tableName,
  })
);

export const uploadFile = createAction(
  'cassandra - upload file',
  (file, meta, uploadCode, dataSourceCode) => ({
    file,
    meta,
    uploadCode,
    dataSourceCode,
  })
);

export const uploadFileSuccess = createAction(
  'cassandra - upload file success',
  (uploadCode, dataSourceCode) => ({ uploadCode, dataSourceCode })
);

export const uploadFileFail = createAction(
  'cassandra - upload file failure',
  (error, uploadCode, dataSourceCode) => ({ error, uploadCode, dataSourceCode })
);

export const uploadProgress = createAction(
  'cassandra - upload progress',
  (uploadCode, dataSourceCode, progress) => ({
    uploadCode,
    dataSourceCode,
    progress,
  })
);

export const listenToFileInfo = createAction(
  'cassandra - listen to file info',
  (uploadCode, dataSourceCode) => ({ uploadCode, dataSourceCode })
);

export const fileInfoSuccess = createAction(
  'cassandra - file info success',
  (uploadCode, dataSourceCode) => ({ uploadCode, dataSourceCode })
);

export const fileInfoFailure = createAction(
  'cassandra - file info failure',
  (uploadCode, dataSourceCode, error) => ({
    uploadCode,
    dataSourceCode,
    error,
  })
);

export const fileInfoProgress = createAction(
  'cassandra - file info progress',
  (uploadCode, dataSourceCode, message) => ({
    uploadCode,
    dataSourceCode,
    message,
  })
);

export const listenToWriteInfo = createAction(
  'cassandra - listen to write info',
  (uploadCode, dataSourceCode) => ({ uploadCode, dataSourceCode })
);

export const writeInfoSuccess = createAction(
  'cassandra - write info success',
  (uploadCode, dataSourceCode, data) => ({ uploadCode, dataSourceCode, data })
);

export const writeInfoFailure = createAction(
  'cassandra - write info failure',
  (uploadCode, dataSourceCode, error) => ({
    uploadCode,
    dataSourceCode,
    error,
  })
);

export const writeInfoProgress = createAction(
  'cassandra - write info progress',
  (uploadCode, dataSourceCode, amount) => ({
    uploadCode,
    dataSourceCode,
    amount,
  })
);

export const resetUploadFile = createAction(
  'cassandra - reset upload file',
  (dataSourceCode) => ({ dataSourceCode })
);

export const setActiveUploadWizard = createAction(
  'set active upload wizard',
  (activeWizard, uploadCode) => ({ activeWizard, uploadCode })
);

export const requestCommitInfo = createAction(
  'request upload commit info',
  (dataSourceCode, uploadCode) => ({ dataSourceCode, uploadCode })
);

export const requestCommitInfoSuccess = createAction(
  'request upload commit info - success',
  (dataSourceCode, uploadCode, data) => ({ dataSourceCode, uploadCode, data })
);

export const requestCommitInfoFail = createAction(
  'request upload commit info - failure',
  (dataSourceCode, uploadCode, error) => ({ dataSourceCode, uploadCode, error })
);

export const commitUpload = createAction(
  'cassandra - commit upload',
  (dataSourceCode, body) => ({ dataSourceCode, body })
);

export const commitUploadSuccess = createAction(
  'cassandra - commit upload success',
  (dataSourceCode) => ({ dataSourceCode })
);

export const commitUploadFail = createAction(
  'cassandra - commit upload failure',
  (dataSourceCode, error) => ({ dataSourceCode, error })
);

export const bucketCreated = createAction(
  's3 - bucket created message',
  ({ bucketName, bucketMessage, bucketStatusType }) => ({
    bucketName,
    bucketMessage,
    bucketStatusType,
  })
);

export const reducer = {
  [fetchCassandraCredentials]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          credentials: {
            ...((state.data.cassandra[dataSourceCode] || {}).keyspaces || {}),
            loading: true,
            error: undefined,
            data: undefined,
          },
        },
      },
    },
  }),
  [fetchCassandraCredentialsSuccess]: (state, { data, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          credentials: {
            loading: false,
            loaded: true,
            data,
          },
        },
      },
    },
  }),
  [fetchCassandraCredentialsFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          credentials: {
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  [clearCassandraCredentials]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {},
      },
    },
  }),
  [fetchCassandraKeyspaces]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          keyspaces: {
            ...((state.data.cassandra[dataSourceCode] || {}).keyspaces || {}),
            loading: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [fetchCassandraKeyspacesSuccess]: (state, { data, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          keyspaces: {
            ...((state.data.cassandra[dataSourceCode] || {}).keyspaces || {}),
            loading: false,
            loaded: true,
            data,
          },
        },
      },
    },
  }),
  [fetchCassandraKeyspacesFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          keyspaces: {
            ...((state.data.cassandra[dataSourceCode] || {}).keyspaces || {}),
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  [fetchCassandraPermissions]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          permissions: {
            ...((state.data.cassandra[dataSourceCode] || {}).permissions || {}),
            loading: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [fetchCassandraPermissionsSuccess]: (state, { data, dataSourceCode }) => {
    // Index the data directly by keyspace name not the whole keycloak resource name
    const dataWithKeyspaceNames = Object.fromEntries(
      Object.entries(data).map(([resource, scopes]) => [
        keyspaceNameFromResource(resource),
        scopes,
      ])
    );
    return {
      ...state,
      data: {
        ...state.data,
        cassandra: {
          ...state.data.cassandra,
          [dataSourceCode]: {
            ...(state.data.cassandra[dataSourceCode] || {}),
            permissions: {
              ...((state.data.cassandra[dataSourceCode] || {}).permissions ||
                {}),
              loading: false,
              loaded: true,
              data: dataWithKeyspaceNames,
            },
          },
        },
      },
    };
  },
  [fetchCassandraPermissionsFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          permissions: {
            ...((state.data.cassandra[dataSourceCode] || {}).permissions || {}),
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  [fetchCassandraTables]: (state, { dataSourceCode, keyspaceName }) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          tables: {
            ...((state.data.cassandra[dataSourceCode] || {}).tables || {}),
            [keyspaceName]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).tables || {})[
                keyspaceName
              ] || {}),
              loading: true,
              error: undefined,
            },
          },
        },
      },
    },
  }),
  [fetchCassandraTablesSuccess]: (
    state,
    { data, dataSourceCode, keyspaceName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          tables: {
            ...((state.data.cassandra[dataSourceCode] || {}).tables || {}),
            [keyspaceName]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).tables || {})[
                keyspaceName
              ] || {}),
              loading: false,
              loaded: true,
              data,
            },
          },
        },
      },
    },
  }),
  [fetchCassandraTablesFailure]: (
    state,
    { error, dataSourceCode, keyspaceName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          tables: {
            ...((state.data.cassandra[dataSourceCode] || {}).tables || {}),
            [keyspaceName]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).tables || {})[
                keyspaceName
              ] || {}),
              loading: false,
              loaded: false,
              error,
            },
          },
        },
      },
    },
  }),
  [fetchCassandraTableSample]: (
    state,
    { dataSourceCode, keyspaceName, tableName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          sample: {
            ...((state.data.cassandra[dataSourceCode] || {}).sample || {}),
            [`${keyspaceName}#${tableName}`]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).sample || {})[
                `${keyspaceName}#${tableName}`
              ] || {}),
              loading: true,
              error: undefined,
            },
          },
        },
      },
    },
  }),
  [fetchCassandraTableSampleSuccess]: (
    state,
    { data, dataSourceCode, keyspaceName, tableName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          sample: {
            ...((state.data.cassandra[dataSourceCode] || {}).sample || {}),
            [`${keyspaceName}#${tableName}`]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).sample || {})[
                `${keyspaceName}#${tableName}`
              ] || {}),
              error: undefined,
              loading: false,
              loaded: true,
              data,
            },
          },
        },
      },
    },
  }),
  [fetchCassandraTableSampleFailure]: (
    state,
    { error, dataSourceCode, keyspaceName, tableName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          sample: {
            ...((state.data.cassandra[dataSourceCode] || {}).sample || {}),
            [`${keyspaceName}#${tableName}`]: {
              ...(((state.data.cassandra[dataSourceCode] || {}).sample || {})[
                `${keyspaceName}#${tableName}`
              ] || {}),
              loading: false,
              loaded: false,
              error,
            },
          },
        },
      },
    },
  }),
  [cleanupCassandraTableSample]: (
    state,
    { dataSourceCode, keyspaceName, tableName }
  ) => ({
    ...state,
    data: {
      ...state.data,
      cassandra: {
        ...state.data.cassandra,
        [dataSourceCode]: {
          ...(state.data.cassandra[dataSourceCode] || {}),
          sample: {
            ...((state.data.cassandra[dataSourceCode] || {}).sample || {}),
            [`${keyspaceName}#${tableName}`]: undefined, // cleanup the sample data
          },
        },
      },
    },
  }),
  [uploadFile]: (state, { file, meta, uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...((state.data.cassandra[dataSourceCode] || {}).upload || {}),
        uploading: true,
        progress: 0,
        uploadCode,
        uploaded: false,
      },
    },
  }),
  [uploadProgress]: (state, { uploadCode, progress, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: true,
        progress,
      },
    },
  }),
  [resetUploadFile]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: false,
        uploaded: false,
        progress: 0,
      },
    },
  }),
  [uploadFileSuccess]: (state, { uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: false,
        progress: 100,
        uploaded: true,
      },
    },
  }),
  [uploadFileFail]: (state, { dataSourceCode, uploadCode, error }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: false,
        uploaded: false,
        error,
      },
    },
  }),
  [listenToFileInfo]: (state, { uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        analysis: {
          ...(state.data.upload || {}).analysis,
          analyzing: true,
          analyzed: false,
        },
      },
    },
  }),
  [fileInfoProgress]: (state, { uploadCode, dataSourceCode, message }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        analysis: {
          ...(state.data.upload || {}).analysis,
          message,
        },
      },
    },
  }),
  [fileInfoSuccess]: (state, { uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        analysis: {
          ...(state.data.upload || {}).analysis,
          analyzing: false,
          analyzed: true,
        },
      },
    },
  }),
  [fileInfoFailure]: (state, { uploadCode, dataSourceCode, error }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        analysis: {
          ...(state.data.upload || {}).analysis,
          analyzing: false,
          analyzed: false,
          error,
        },
      },
    },
  }),
  [listenToWriteInfo]: (state, { uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        write: {
          ...(state.data.upload || {}).write,
          writing: true,
          written: false,
        },
      },
    },
  }),
  [writeInfoProgress]: (state, { uploadCode, dataSourceCode, amount }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        write: {
          ...(state.data.upload || {}).write,
          amount,
          written: false,
        },
      },
    },
  }),
  [writeInfoSuccess]: (state, { uploadCode, dataSourceCode, data }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        write: {
          ...(state.data.upload || {}).write,
          writing: false,
          written: true,
          data,
        },
      },
    },
  }),
  [writeInfoFailure]: (state, { uploadCode, dataSourceCode, error }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        write: {
          ...(state.data.upload || {}).write,
          writing: false,
          written: false,
          error,
        },
      },
    },
  }),
  [setActiveUploadWizard]: (state, { activeWizard, uploadCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        activeWizard,
        uploadCode,
      },
    },
  }),
  [requestCommitInfo]: (state, { dataSourceCode, uploadCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        commitInfo: {
          ...state.data.upload.commitInfo,
          loading: true,
        },
      },
    },
  }),
  [requestCommitInfoSuccess]: (
    state,
    { dataSourceCode, uploadCode, data }
  ) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        commitInfo: {
          ...state.data.upload.commitInfo,
          loading: false,
          loaded: true,
          error: undefined,
          data,
        },
      },
    },
  }),
  [requestCommitInfoFail]: (state, { dataSourceCode, uploadCode, error }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        commitInfo: {
          ...state.data.upload.commitInfo,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* fetchCassandraCredentialsSaga({
  payload: { dataSourceCode },
}) {
  const { response, error } = yield call(
    Api.data.fetchCassandraCredentials,
    dataSourceCode
  );
  if (response) {
    yield put(fetchCassandraCredentialsSuccess(response, dataSourceCode));
  } else {
    yield put(fetchCassandraCredentialsFailure(error, dataSourceCode));
  }
}

export function* watchFetchCassandraCredentials() {
  yield takeEvery(
    fetchCassandraCredentials.getType(),
    fetchCassandraCredentialsSaga
  );
}

export function* fetchCassandraKeyspacesSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.fetchCassandraKeyspaces,
    dataSourceCode
  );
  if (response) {
    yield put(fetchCassandraKeyspacesSuccess(response, dataSourceCode));
  } else {
    yield put(fetchCassandraKeyspacesFailure(error, dataSourceCode));
  }
}

export function* watchFetchCassandraKeyspaces() {
  yield takeEvery(
    fetchCassandraKeyspaces.getType(),
    fetchCassandraKeyspacesSaga
  );
}

export function* fetchCassandraPermissionsSaga({
  payload: { dataSourceCode },
}) {
  const { response, error } = yield call(
    Api.data.fetchCassandraPermissions,
    dataSourceCode
  );
  if (response) {
    yield put(fetchCassandraPermissionsSuccess(response, dataSourceCode));
  } else {
    yield put(fetchCassandraPermissionsFailure(error, dataSourceCode));
  }
}

export function* watchFetchCassandraPermissions() {
  yield takeEvery(
    fetchCassandraPermissions.getType(),
    fetchCassandraPermissionsSaga
  );
}

export function* fetchCassandraTablesSaga({
  payload: { dataSourceCode, keyspaceName },
}) {
  const { response, error } = yield call(
    Api.data.fetchCassandraTables,
    dataSourceCode,
    keyspaceName
  );
  if (response) {
    yield put(
      fetchCassandraTablesSuccess(response, dataSourceCode, keyspaceName)
    );
  } else {
    yield put(fetchCassandraTablesFailure(error, dataSourceCode, keyspaceName));
  }
}

export function* watchFetchCassandraTables() {
  yield takeEvery(fetchCassandraTables.getType(), fetchCassandraTablesSaga);
}

export function* fetchCassandraTableSampleSaga({
  payload: { dataSourceCode, keyspaceName, tableName },
}) {
  const { response, error } = yield call(
    Api.data.fetchCassandraTableSample,
    dataSourceCode,
    keyspaceName,
    tableName
  );
  if (response) {
    yield put(
      fetchCassandraTableSampleSuccess(
        response,
        dataSourceCode,
        keyspaceName,
        tableName
      )
    );
  } else {
    yield put(
      fetchCassandraTableSampleFailure(
        error,
        dataSourceCode,
        keyspaceName,
        tableName
      )
    );
  }
}

export function* watchFetchCassandraTableSample() {
  yield takeEvery(
    fetchCassandraTableSample.getType(),
    fetchCassandraTableSampleSaga
  );
}

export function* uploadFileSaga({
  payload: { file, meta, uploadCode, dataSourceCode },
}) {
  const channel = yield call(
    uploadFileAsForm,
    uploadCode,
    file,
    meta,
    dataSourceCode
  );
  //Send notification start upload
  yield put(
    sendNotification(
      notificationsMsgs.msgTitleUploadFileCass.id,
      notificationsMsgs.msgDescriptionUploadFileCass.id,
      eventType
    )
  );
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* watchUploadFile() {
  yield takeEvery(uploadFile.getType(), uploadFileSaga);
}

/**
 * Starts the upload stream to the Data Management API
 * @param uploadCode
 * @param file
 * @param meta
 * @returns {Generator<*, *, *>}
 */
function uploadFileAsForm(uploadCode, file, meta, dataSourceCode) {
  return eventChannel((emitter) => {
    const formData = new FormData();
    formData.append('uploadCode', uploadCode);
    formData.append('meta', JSON.stringify(meta));
    formData.append('file', file);

    // TODO is the upload really required to be done with axios? This might be inconsistent to the other REST interactions
    updateToken()
      .then((refreshed) => {
        const http = axios.create();
        return http.post(
          `/dataman/cassandra/${dataSourceCode}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${keycloak.token}`,
            },
            onUploadProgress: (e) => {
              const progress = Math.round((100 * e.loaded) / e.total);
              // --- still uploading
              emitter(uploadProgress(uploadCode, dataSourceCode, progress));
            },
          }
        );
      })
      .then(() => {
        // --- done
        emitter(uploadFileSuccess(uploadCode, dataSourceCode));
        // -> Now that the file was successfully uploaded, listen to the second WebSocket channel that receives the
        // updates while the file is analyzed
        emitter(listenToFileInfo(uploadCode, dataSourceCode));
      })
      .catch((e) => {
        emitter(uploadFileFail(e, uploadCode, dataSourceCode));
      });

    return () => {};
  });
}

export function* listenToFileInfoSaga({
  payload: { uploadCode, dataSourceCode },
}) {
  const channel = yield call(
    listenToFileInfoChannel,
    uploadCode,
    dataSourceCode
  );
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* watchListenToFileInfo() {
  yield takeEvery(listenToFileInfo.getType(), listenToFileInfoSaga);
}

/**
 * Listen to the WebSocket channel that sends info while the file info is derived
 * @param uploadCode
 * @param dataSourceCode
 * @returns {EventChannel<unknown>}
 */
function listenToFileInfoChannel(uploadCode, dataSourceCode) {
  return eventChannel((emitter) => {
    const socketUrl = `${
      location.protocol.includes('https') ? 'wss://' : 'ws://'
    }${
      location.hostname + (location.port ? ':' + location.port : '')
    }/dataman/cassandra/upload/${uploadCode}/info/status`;
    const socket = new WebSocket(socketUrl);

    socket.onmessage = async (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      const messageType = message.msgType;

      switch (messageType) {
        case 'progress': {
          return emitter(
            fileInfoProgress(uploadCode, dataSourceCode, message.step)
          );
        }
        case 'success': {
          emitter(fileInfoSuccess(uploadCode, dataSourceCode));
          emitter(
            sendNotification(
              notificationsMsgs.msgTitleUploadFileCass.id,
              notificationsMsgs.msgDescriptionUploadFileCassSuccess.id,
              eventType
            )
          );
          return emitter(
            setActiveUploadWizard(UPLOAD_WIZARDS.COMMIT, uploadCode)
          );
        }
        case 'failure': {
          emitter(
            sendNotification(
              notificationsMsgs.msgTitleUploadFileCass.id,
              notificationsMsgs.msgDescriptionUploadFileCassFailure.id,
              errorType
            )
          );
          return emitter(
            fileInfoFailure(uploadCode, dataSourceCode, message.error)
          );
        }
      }
    };

    return () => {};
  });
}

export function* requestCommitInfoSaga({
  payload: { dataSourceCode, uploadCode },
}) {
  const { response, error } = yield call(
    Api.data.requestCommitInfo,
    dataSourceCode,
    uploadCode
  );
  if (response) {
    const {
      dataPreview: { colSpecs },
    } = response;
    let defaultHeader = false;
    // Modify commitInfo: Replace empty columnNames with "col_0,..." (Needed for example for react-table)
    // If this happened, also set defaultHeader, so that this fact can be displayed
    const safeColSpecs = colSpecs.map(({ colName, colType }, i) => {
      let safeColName = colName;
      if (colName === '') {
        defaultHeader = true;
        safeColName = `col_${i + 1}`;
      }
      return { colName: safeColName, colType };
    });
    const data = {
      ...response,
      dataPreview: { ...response.dataPreview, colSpecs: safeColSpecs },
      defaultHeader,
    };
    yield put(requestCommitInfoSuccess(dataSourceCode, uploadCode, data));
  } else {
    yield put(requestCommitInfoFail(dataSourceCode, uploadCode, error));
  }
}

export function* watchRequestCommitInfo() {
  yield takeEvery(requestCommitInfo.getType(), requestCommitInfoSaga);
}

export function* commitUploadSaga({ payload: { dataSourceCode, body } }) {
  const { response, error } = yield call(
    Api.data.commitUpload,
    dataSourceCode,
    body
  );
  if (response) {
    yield put(commitUploadSuccess(dataSourceCode));
    yield put(listenToWriteInfo(body.uploadCode, dataSourceCode));
    yield put(setActiveUploadWizard(UPLOAD_WIZARDS.CONFIRMATION, ''));
  } else {
    yield put(commitUploadFail(dataSourceCode, error));
    // TODO Display the error case somehow
  }
}

export function* watchCommitUpload() {
  yield takeEvery(commitUpload.getType(), commitUploadSaga);
}

export function* listenToWriteInfoSaga({
  payload: { uploadCode, dataSourceCode },
}) {
  const channel = yield call(
    listenToWriteInfoChannel,
    uploadCode,
    dataSourceCode
  );
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* watchListenToWriteInfo() {
  yield takeEvery(listenToWriteInfo.getType(), listenToWriteInfoSaga);
}

/**
 * Listen to the WebSocket channel that sends info while the file is written to Cassandra
 * @param uploadCode
 * @param dataSourceCode
 * @returns {EventChannel<unknown>}
 */
function listenToWriteInfoChannel(uploadCode, dataSourceCode) {
  return eventChannel((emitter) => {
    const socketUrl = `${
      location.protocol.includes('https') ? 'wss://' : 'ws://'
    }${
      location.hostname + (location.port ? ':' + location.port : '')
    }/dataman/cassandra/upload/${uploadCode}/commit/status`;
    const socket = new WebSocket(socketUrl);

    socket.onmessage = async (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      const messageType = message.msgType;

      switch (messageType) {
        case 'progress': {
          return emitter(
            writeInfoProgress(uploadCode, dataSourceCode, message.amount)
          );
        }
        case 'success': {
          return emitter(
            writeInfoSuccess(uploadCode, dataSourceCode, message.data)
          );
        }
        case 'failure': {
          return emitter(
            writeInfoFailure(uploadCode, dataSourceCode, message.error)
          );
        }
      }
    };

    return () => {};
  });
}

export function* bucketCreatedSaga({
  payload: { bucketName, bucketMessage, bucketStatusType },
}) {
  yield put(
    sendNotification(
      notificationsMsgs.titleS3BucketCreation.id,
      bucketMessage,
      bucketStatusType,
      { bucketName }
    )
  );
}

export function* watchKeyspaceCreated() {
  yield takeEvery(bucketCreated.getType(), bucketCreatedSaga);
}
