import messagesForm from 'common/dist/messages/form';
import { Job } from 'common/dist/types/job';
import React, { FC, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import {
  fieldBackupId,
  fieldJobType,
  fieldTemplateId,
  form,
  formName,
  Values,
} from './form';
import styles from './styles.module.scss';
import {
  fetchBackups,
  fetchTemplates,
} from '../../../../../store/admin/backups/actions';
import { AdminBackupsState } from '../../../../../store/admin/backups/state.types';
import { DeprecatedRootState } from '../../../../../store/state.type';
import Button from '../../../../atoms/button/Button';
import RfWrappedDropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/RfWrappedDropdownSelectInput';

//This styling needs to be in-place otherwise the defaultlayout is overwritten
//https://reactcommunity.org/react-modal/styles/transitions/
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible', // For dropdowns
  },
};

export interface Props {
  isOpen: boolean;
  close: () => void;
  onSubmit: (job: Partial<Job>) => void;
}
const dropdownOptions: OptionType[] = [
  {
    label: 'backup',
    value: 'backup',
  },
  {
    label: 'restore',
    value: 'restore',
  },
];
type OptionType = {
  label: string;
  value: string;
};

const BackupJobModal: FC<Props & InjectedFormProps<{}, Props>> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTemplates());
    dispatch(fetchBackups());
  }, [dispatch]);

  const { templates, backups } = useSelector<DeprecatedRootState>(
    (state) => state.adminBackups
  ) as AdminBackupsState;

  const formValues = useSelector(getFormValues(formName)) as Values;
  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel='Dialog Modal'
      onRequestClose={props.close}
      style={customStyles}
      appElement={document.getElementById('react-app')}
    >
      <div className={styles.backupJobModal}>
        <div
          title={'Close Dialog'}
          className={'close-button'}
          onClick={props.close}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={styles.backupJobHeadline}>
          <span>Create Backup Job</span>
        </div>
        <form
          onSubmit={props.handleSubmit((values: Values) => {
            const jobType = values[fieldJobType].value;
            const job: Partial<Job> = {
              superType: 'backup',
              jobType,
            };
            if (jobType === 'backup') {
              job.templateId = jobType;
            } else if (jobType === 'restore') {
              const backupId = values[fieldBackupId].value;
              const backup = backups.data.find(
                (bak) => bak.backupMeta.id === backupId
              );
              job.templateId = backup.templateId;
              job.backupId = backup.backupMeta.id;
            }
            props.onSubmit(job);
          })}
        >
          <div className={styles.backupJobDescription}>Type</div>
          <div className={styles.backupJobField}>
            <Field
              name={fieldJobType}
              component={RfWrappedDropdownSelectInput}
              // Props for component
              disabled={false}
              hasLabel={false}
              placeholderDefault={'Type'}
              isLoading={false}
              clearable={false}
              simpleValue={true}
              multi={false}
              openOnFocus={true}
              options={dropdownOptions}
            />
          </div>
          {formValues?.[fieldJobType].value === 'backup' && (
            <>
              <div className={styles.backupJobDescription}>Template</div>
              <div className={styles.backupJobField}>
                <Field
                  name={fieldTemplateId}
                  component={RfWrappedDropdownSelectInput}
                  // Props for component
                  disabled={false}
                  hasLabel={false}
                  placeholderDefault={'Template'}
                  isLoading={templates.loading}
                  clearable={false}
                  simpleValue={true}
                  multi={false}
                  openOnFocus={true}
                  options={templates.data.map((template) => ({
                    label: `${template.name} (${template.id})`,
                    value: template.id,
                  }))}
                />
              </div>
            </>
          )}
          {formValues?.[fieldJobType].value === 'restore' && (
            <>
              <div className={styles.backupJobDescription}>Backup</div>
              <div className={styles.backupJobField}>
                <Field
                  name={fieldBackupId}
                  component={RfWrappedDropdownSelectInput}
                  // Props for component
                  disabled={false}
                  hasLabel={false}
                  placeholderDefault={'Backup'}
                  isLoading={backups.loading && templates.loading}
                  clearable={false}
                  simpleValue={true}
                  multi={false}
                  openOnFocus={true}
                  options={backups.data.map((backup) => ({
                    label: `${backup.backupMeta.timestamp} (${
                      templates.data.find(
                        (temp) => temp.id === backup.templateId
                      )?.name
                    })`,
                    value: backup.backupMeta.id,
                  }))}
                />
              </div>
            </>
          )}
          <div className={styles.backupJobButtonBar}>
            <Button
              label={'Add Job'}
              color={'secondary'}
              disabled={props.invalid}
              type={'submit'}
            />
            <Button
              label={messagesForm.cancel}
              color={'white'}
              onClick={() => {
                props.close();
              }}
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default reduxForm<{}, Props>(form)(BackupJobModal);
