import React, { FC, useMemo } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { ReportElementProps } from '../../../types/meta';
import { ShapChartConfig, ShapChartReportData } from '../type';

function createPseudoRandomGenerator(seed: number) {
  let currentSeed = seed;

  const multiplier = 1664525;
  const increment = 1013904223;
  const modulus = 2 ** 32;

  return function () {
    currentSeed = (multiplier * currentSeed + increment) % modulus;
    return currentSeed / modulus - 0.5;
  };
}

export type Props = { data: ShapChartReportData } & ShapChartConfig;

export const ShapChart: FC<Props> = ({ data }) => {
  data = useMemo(() => {
    if (data.length > 200) {
      // This was just determined without much testing. Worked at 200, crashed at 2000: "CanvasRenderingContext2D.setTransform: Canvas is already in error state."
      console.warn(
        'Displaying only 200 columns in the ShapChart for performance reasons'
      );
      return data.slice(0, 200);
    } else return data;
  }, [data]);
  const primaryHighlightColor = useThemeColor('primary-highlight');
  const primaryColor = useThemeColor('primary');
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);

  const pseudoRandom = useMemo(() => createPseudoRandomGenerator(54321), []);

  const deviation = 0.05;
  const rowHeight = 60;
  const features: string[] = useMemo(
    () =>
      data.map((dataEntry) => {
        let feature = dataEntry.feature;
        if (dataEntry.featureValue) {
          feature = feature + `\n` + '= ' + dataEntry.featureValue;
        }
        return feature;
      }),
    [data]
  );

  const chartHeight = Math.max(data.length * rowHeight + 70, height);

  const flattendShapValues = useMemo(
    () =>
      data
        .map((dataEntry) => dataEntry.distribution.map((point) => point[1]))
        .flat(),
    [data]
  );

  const maxValue = useMemo(
    () => Math.max(...flattendShapValues),
    [flattendShapValues]
  );
  const minValue = useMemo(
    () => Math.min(...flattendShapValues),
    [flattendShapValues]
  );

  // const maxStackPerFeature: number[] = useMemo(() => {
  const allGatherPoints: { [val: number]: number }[] = useMemo(() => {
    // const currentMaxStackPerFeature: number[] = [];
    const currentAllGatherPoints: { [val: number]: number }[] = [];
    data.forEach((dataEntry) => {
      const gatherPoints: { [val: number]: number } = {};

      dataEntry.distribution.forEach((value) => {
        const keys = Object.keys(gatherPoints);
        let found = false;
        for (const key of keys) {
          if (
            Number(key) - deviation <= value[1] &&
            Number(key) + deviation >= value[1]
          ) {
            gatherPoints[Number(key)]++;
            found = true;
          }
        }
        if (!found) {
          gatherPoints[value[1]] = 1;
        }
      });
      currentAllGatherPoints.push(gatherPoints);
    });
    return currentAllGatherPoints;
  }, [data]);

  // Needed to cap the max-y randomization per feature
  const maxStackPerFeature: number[] = useMemo(() => {
    const currentMaxStackPerFeature: number[] = [];
    allGatherPoints.forEach((gatherPoints) => {
      const maxStack = Math.max(...Object.values(gatherPoints));
      currentMaxStackPerFeature.push(maxStack);
    });
    return currentMaxStackPerFeature;
  }, [allGatherPoints]);

  // Min-Max-Shap-Values
  const minMaxPerFeature: [number, number][] = useMemo(() => {
    const currentMinMaxPerFeature: [number, number][] = [];
    data.forEach((dataEntry, currentIndex) => {
      currentMinMaxPerFeature[currentIndex] = [
        dataEntry.distribution[0][0],
        dataEntry.distribution[0][0],
      ];

      dataEntry.distribution.forEach((value) => {
        const minMax = currentMinMaxPerFeature[currentIndex];
        if (minMax[0] > value[0])
          currentMinMaxPerFeature[currentIndex] = [value[0], minMax[1]];
        if (minMax[1] < value[0])
          currentMinMaxPerFeature[currentIndex] = [minMax[0], value[0]];
      });
    });

    return currentMinMaxPerFeature;
  }, [data]);

  // [ShapValue for x-Axis, Feature Index, Value for y-Axis randomization, Color Value]
  const extentedData: [number, number, number, number][] = useMemo(() => {
    const currentExtendData: [number, number, number, number][] = [];

    data.forEach((dataEntry, currentIndex) => {
      const gatherPoints: { [val: number]: number } =
        allGatherPoints[currentIndex];

      const keys = Object.keys(gatherPoints);

      dataEntry.distribution.forEach((value) => {
        let stackValue = 1;
        for (const key of keys) {
          if (
            Number(key) - deviation <= value[1] &&
            Number(key) + deviation >= value[1]
          ) {
            stackValue = gatherPoints[Number(key)];
          }
        }

        currentExtendData.push([
          Number(value[1]),
          currentIndex,
          stackValue,
          Number(value[0]),
        ]);
      });
    });

    return currentExtendData;
  }, [allGatherPoints, data]);

  // Calculate the relative color value for each feature, so min is 0 and max is 1, and coloring is simple
  const relativeData = useMemo(
    () =>
      extentedData.map((data) => {
        const minValue = minMaxPerFeature[data[1]][0];
        const maxValue = minMaxPerFeature[data[1]][1];
        const relativeVal = (data[3] - minValue) / (maxValue - minValue);

        // Here we calculate the randomeness the more stack value they have, the closer they can get to the maxOffset
        const maxYOffset = rowHeight;

        const valueStack = data[2];
        const xOffset = pseudoRandom() * 2.5;
        const yOffset =
          pseudoRandom() *
          maxYOffset *
          (valueStack / (maxStackPerFeature[data[1]] + 3));

        return [
          data[0],
          data[1],
          data[2],
          relativeVal ? relativeVal : 0,
          data[3],
          xOffset,
          yOffset,
        ];
      }),
    [extentedData, minMaxPerFeature, maxStackPerFeature, pseudoRandom]
  );

  const option: ReactEChartsProps['option'] = {
    animation: false,
    tooltip: {
      formatter: function (params) {
        const xValue = params.value[0];
        const featureName = features[params.value[1]];
        const featureValue = params.value[4];

        return `
          <strong>${featureName}</strong><br/>
          Shap Value: <strong>${xValue.toFixed(3)}</strong><br/>
         Feature Value:   <strong>${featureValue}   </strong><span style="display: inline-block; margin-right: 5px; border-radius: 50%; width: 10px; height: 10px; background-color: ${
          params.color
        };"></span>
        `;
      },
    },
    grid: {
      height:
        chartHeight === height ? chartHeight - 70 : data.length * rowHeight,
      top: 60,
      left: 2,
      bottom: 0,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      nameLocation: 'middle',
      nameTextStyle: { padding: 12 },
      type: 'value',
      min: -Math.max(Math.abs(minValue), Math.abs(maxValue)).toFixed(2),
      max: Math.max(Math.abs(minValue), Math.abs(maxValue)).toFixed(2),
      name: 'ShapValue',
      position: 'top',

      splitLine: {
        show: true,
      },
      alignTicks: true,
      axisLine: {
        show: true,
      },
      axisTick: { show: true },
      axisLabel: {
        backgroundColor: 'white',
        padding: 2,
        borderRadius: 2,
        alignMaxLabel: 'right',
        alignMinLabel: 'left',
      },
    },
    yAxis: {
      type: 'category',
      data: features,
      inverse: true,
      splitArea: {
        show: true,
      },
      axisLine: {
        show: true,
        onZero: false,
      },
      splitLine: {
        show: true,
      },
    },
    visualMap: {
      hoverLinkOnHandle: false,
      show: true,
      hoverLinkDataSize: 1,
      formatter: () => '',
      hoverLink: true,
      showLabel: false,
      name: 'Shap influence',
      dimension: 3,
      text: ['High Feature Value', 'Low Feature Value'],
      align: 'auto',
      left: 'center',
      orient: 'horizontal',
      min: 0,
      top: 0,
      max: 1,
      inRange: {
        color: [primaryHighlightColor + '60', primaryColor],
      },
    },

    series: [
      {
        animation: false,
        name: 'Punch Card',
        type: 'scatter',

        symbolSize: function () {
          return 5;
        },
        data: relativeData,

        symbolOffset: function (values) {
          return [values[5], values[6]];
        },
      },
    ],
  };

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <EChartWrapper
        option={option}
        style={{
          width: width,
          height: chartHeight,
        }}
      />
    </div>
  );
};

export const ShapChartSingle: FC<
  ReportElementProps<ShapChartReportData, ShapChartConfig>
> = ({ input: { reportValue }, config, ...rest }) => (
  <ShapChart data={reportValue} {...config} {...rest} />
);
