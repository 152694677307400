import { PipelineTuningValue } from 'common/dist/types/pipeline';
import React, { FC, useState } from 'react';

import './styles.scss';

import Pipeline from './Pipeline';
import { ButtonProps } from '../../../../../../atoms/button/Button';
import TabLineBubbles from '../../../../../tab-line-bubbles/TabLineBubbles';
import { PipelineTuningErrorType } from '../PipelineTuning';
import { PipelineTuningAugurSettings, PipelineTuningConfig } from '../type';

export type Props = {
  /** List of pipeline tuning schemas, each of them defines one pipeline structure */
  pipelineTuningSchemas: PipelineTuningConfig['pipelineTuningSchemas'];
  onChange: (...event: unknown[]) => void;
  value: PipelineTuningAugurSettings;
  /** The default values of the pipeline (used for the "reset to defaults" button) */
  defaultValues?: PipelineTuningValue[];
  error?: PipelineTuningErrorType;
  readOnly?: boolean;
};

export const PipelineTuningSelection: FC<Props> = (props) => {
  const {
    pipelineTuningSchemas,
    defaultValues,
    onChange,
    value,
    error,
    readOnly,
  } = props;
  const [activePipelineIndex, setActivePipelineIndex] = useState<number>(0);

  if (!pipelineTuningSchemas)
    return (
      <div className={'PipelineTuningSelection'}>
        No pipeline definitions found for this module
      </div>
    );

  const tabLineButtons = pipelineTuningSchemas?.map((pipeline, i) => ({
    id: i.toString(),
    label: pipeline.displayName,
    onClick: () => setActivePipelineIndex(i),
  })) satisfies ButtonProps[];

  return (
    <div className={'PipelineTuningSelection'}>
      <TabLineBubbles
        buttons={tabLineButtons}
        activeButton={activePipelineIndex.toString()}
        design={'tabs'}
      />
      {pipelineTuningSchemas.map((pipeline, i) => (
        <div
          key={pipeline.id}
          style={{
            // Hide the pipelines that are not selected instead of only changing the pipeline={} prop of <Pipeline>.
            //   The Pipeline component is not capable of properly re-render if the pipeline prop changes dynamically ...
            display: activePipelineIndex === i ? 'block' : 'none',
          }}
        >
          <Pipeline
            key={`pipeline_${i}`}
            pipeline={pipeline}
            pipelineIndex={i}
            value={value}
            pipelineValue={value[i]}
            onChange={onChange}
            error={error}
            defaultValues={(defaultValues || [])[i]}
            readOnly={readOnly}
          />
        </div>
      ))}
    </div>
  );
};

export default PipelineTuningSelection;
