import classNames from 'classnames';
import actionMessages from 'common/dist/messages/actions';
import { BiographyEntry } from 'common/dist/types/augurBiography';
import React, { FC, useState } from 'react';

import CommonEntryParts from './CommonEntryParts';
import TimestampAndLine from './TimestampAndLine';
import ActionButton from '../../sub-header/action-button/ActionButton';
import ErrorModal from '../../sub-header/overlay-augur-details/ErrorModal';
import subHeaderStyles from '../../sub-header/styles.module.scss';
import styles from '../styles.module.scss';

type Props = {
  entry: BiographyEntry;
  renderDate: boolean;
};

const ErroneousEntry: FC<Props> = (props: Props) => {
  const { entry, renderDate } = props;
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  return (
    <div
      className={classNames(styles.entry, styles.entryError, {
        [styles.entryLearning]: entry.jobType === 'learning',
        [styles.entryEvaluation]: entry.jobType === 'evaluation',
        [styles.entryPrediction]: entry.jobType === 'prediction',
      })}
    >
      <TimestampAndLine createdAt={entry.createdAt} renderDate={renderDate} />

      <div className={styles.entryBody}>
        <CommonEntryParts entry={entry} />

        <div className={styles.info}>
          <div className={styles.infoValue}>
            {entry.error}
            <div className={styles.infoLink}>
              {entry.error && (
                <ActionButton
                  gridColumn={1}
                  gridRow={1}
                  onClick={() => setErrorModalOpen(true)}
                  label={actionMessages.msgActionShowError}
                  addlClassName={classNames(
                    subHeaderStyles.hidden,
                    styles.infoLinkInner
                  )}
                />
              )}

              {entry.error && (
                <ErrorModal
                  isOpen={errorModalOpen}
                  error={entry.error}
                  closeModal={() => setErrorModalOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErroneousEntry;
