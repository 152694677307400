// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/dropdown-select-cfe/v1/type.ts' --type 'ConfigDropdownSelectCFEConfig'
export const schemaConfigDropdownSelect = {
  $ref: '#/definitions/ConfigDropdownSelectCFEConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<DropdownSelectCFEConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        isClearable: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
        isMulti: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
        isSearchable: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
        options: {
          $ref: '#/definitions/ConfigEntry%3Cdef-alias-1326653281-107-198-1326653281-0-1456%5B%5D%3E',
        },
        placeholder: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      required: ['options'],
      type: 'object',
    },
    ConfigDropdownSelectCFEConfig: {
      $ref: '#/definitions/Config%3CDropdownSelectCFEConfig%3E',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'boolean',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(string|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<def-alias-1326653281-107-198-1326653281-0-1456[]>': {
      anyOf: [
        {
          additionalProperties: false,
          properties: {
            source: {
              const: 'hard-coded',
              type: 'string',
            },
            value: {
              items: {
                $ref: '#/definitions/OptionsType',
              },
              type: 'array',
            },
          },
          required: ['source', 'value'],
          type: 'object',
        },
        {
          additionalProperties: false,
          properties: {
            elementUuid: {
              type: 'string',
            },
            source: {
              const: 'input-element',
              type: 'string',
            },
          },
          required: ['source', 'elementUuid'],
          type: 'object',
        },
      ],
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
    },
    OptionsType: {
      additionalProperties: false,
      properties: {
        dependants: {
          items: {
            type: 'string',
          },
          type: 'array',
        },
        label: {
          type: 'string',
        },
        value: {
          type: 'string',
        },
      },
      required: ['label', 'value'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/text-input-line/v1/type.ts' --type 'PartialDropdownSelectCFEAugurSettings'
export const schemaAugurSettingsDefaultDropdownSelect = {
  $ref: '#/definitions/PartialDropdownSelectCFEAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    DropdownSelectCFEAugurSettings: {
      items: {
        type: 'string',
      },
      type: 'array',
    },
    PartialDropdownSelectCFEAugurSettings: {
      $ref: '#/definitions/DropdownSelectCFEAugurSettings',
    },
  },
};
