export const lineChartImg = `
iVBORw0KGgoAAAANSUhEUgAAARMAAACMCAYAAABf7k2aAAAACXBIWXMAAAsTAAALEwEAmpwYAAAK
T2lDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjanVNnVFPpFj333vRCS4iAlEtvUhUIIFJCi4AU
kSYqIQkQSoghodkVUcERRUUEG8igiAOOjoCMFVEsDIoK2AfkIaKOg6OIisr74Xuja9a89+bN/rXX
Pues852zzwfACAyWSDNRNYAMqUIeEeCDx8TG4eQuQIEKJHAAEAizZCFz/SMBAPh+PDwrIsAHvgAB
eNMLCADATZvAMByH/w/qQplcAYCEAcB0kThLCIAUAEB6jkKmAEBGAYCdmCZTAKAEAGDLY2LjAFAt
AGAnf+bTAICd+Jl7AQBblCEVAaCRACATZYhEAGg7AKzPVopFAFgwABRmS8Q5ANgtADBJV2ZIALC3
AMDOEAuyAAgMADBRiIUpAAR7AGDIIyN4AISZABRG8lc88SuuEOcqAAB4mbI8uSQ5RYFbCC1xB1dX
Lh4ozkkXKxQ2YQJhmkAuwnmZGTKBNA/g88wAAKCRFRHgg/P9eM4Ors7ONo62Dl8t6r8G/yJiYuP+
5c+rcEAAAOF0ftH+LC+zGoA7BoBt/qIl7gRoXgugdfeLZrIPQLUAoOnaV/Nw+H48PEWhkLnZ2eXk
5NhKxEJbYcpXff5nwl/AV/1s+X48/Pf14L7iJIEyXYFHBPjgwsz0TKUcz5IJhGLc5o9H/LcL//wd
0yLESWK5WCoU41EScY5EmozzMqUiiUKSKcUl0v9k4t8s+wM+3zUAsGo+AXuRLahdYwP2SycQWHTA
4vcAAPK7b8HUKAgDgGiD4c93/+8//UegJQCAZkmScQAAXkQkLlTKsz/HCAAARKCBKrBBG/TBGCzA
BhzBBdzBC/xgNoRCJMTCQhBCCmSAHHJgKayCQiiGzbAdKmAv1EAdNMBRaIaTcA4uwlW4Dj1wD/ph
CJ7BKLyBCQRByAgTYSHaiAFiilgjjggXmYX4IcFIBBKLJCDJiBRRIkuRNUgxUopUIFVIHfI9cgI5
h1xGupE7yAAygvyGvEcxlIGyUT3UDLVDuag3GoRGogvQZHQxmo8WoJvQcrQaPYw2oefQq2gP2o8+
Q8cwwOgYBzPEbDAuxsNCsTgsCZNjy7EirAyrxhqwVqwDu4n1Y8+xdwQSgUXACTYEd0IgYR5BSFhM
WE7YSKggHCQ0EdoJNwkDhFHCJyKTqEu0JroR+cQYYjIxh1hILCPWEo8TLxB7iEPENyQSiUMyJ7mQ
AkmxpFTSEtJG0m5SI+ksqZs0SBojk8naZGuyBzmULCAryIXkneTD5DPkG+Qh8lsKnWJAcaT4U+Io
UspqShnlEOU05QZlmDJBVaOaUt2ooVQRNY9aQq2htlKvUYeoEzR1mjnNgxZJS6WtopXTGmgXaPdp
r+h0uhHdlR5Ol9BX0svpR+iX6AP0dwwNhhWDx4hnKBmbGAcYZxl3GK+YTKYZ04sZx1QwNzHrmOeZ
D5lvVVgqtip8FZHKCpVKlSaVGyovVKmqpqreqgtV81XLVI+pXlN9rkZVM1PjqQnUlqtVqp1Q61Mb
U2epO6iHqmeob1Q/pH5Z/YkGWcNMw09DpFGgsV/jvMYgC2MZs3gsIWsNq4Z1gTXEJrHN2Xx2KruY
/R27iz2qqaE5QzNKM1ezUvOUZj8H45hx+Jx0TgnnKKeX836K3hTvKeIpG6Y0TLkxZVxrqpaXllir
SKtRq0frvTau7aedpr1Fu1n7gQ5Bx0onXCdHZ4/OBZ3nU9lT3acKpxZNPTr1ri6qa6UbobtEd79u
p+6Ynr5egJ5Mb6feeb3n+hx9L/1U/W36p/VHDFgGswwkBtsMzhg8xTVxbzwdL8fb8VFDXcNAQ6Vh
lWGX4YSRudE8o9VGjUYPjGnGXOMk423GbcajJgYmISZLTepN7ppSTbmmKaY7TDtMx83MzaLN1pk1
mz0x1zLnm+eb15vft2BaeFostqi2uGVJsuRaplnutrxuhVo5WaVYVVpds0atna0l1rutu6cRp7lO
k06rntZnw7Dxtsm2qbcZsOXYBtuutm22fWFnYhdnt8Wuw+6TvZN9un2N/T0HDYfZDqsdWh1+c7Ry
FDpWOt6azpzuP33F9JbpL2dYzxDP2DPjthPLKcRpnVOb00dnF2e5c4PziIuJS4LLLpc+Lpsbxt3I
veRKdPVxXeF60vWdm7Obwu2o26/uNu5p7ofcn8w0nymeWTNz0MPIQ+BR5dE/C5+VMGvfrH5PQ0+B
Z7XnIy9jL5FXrdewt6V3qvdh7xc+9j5yn+M+4zw33jLeWV/MN8C3yLfLT8Nvnl+F30N/I/9k/3r/
0QCngCUBZwOJgUGBWwL7+Hp8Ib+OPzrbZfay2e1BjKC5QRVBj4KtguXBrSFoyOyQrSH355jOkc5p
DoVQfujW0Adh5mGLw34MJ4WHhVeGP45wiFga0TGXNXfR3ENz30T6RJZE3ptnMU85ry1KNSo+qi5q
PNo3ujS6P8YuZlnM1VidWElsSxw5LiquNm5svt/87fOH4p3iC+N7F5gvyF1weaHOwvSFpxapLhIs
OpZATIhOOJTwQRAqqBaMJfITdyWOCnnCHcJnIi/RNtGI2ENcKh5O8kgqTXqS7JG8NXkkxTOlLOW5
hCepkLxMDUzdmzqeFpp2IG0yPTq9MYOSkZBxQqohTZO2Z+pn5mZ2y6xlhbL+xW6Lty8elQfJa7OQ
rAVZLQq2QqboVFoo1yoHsmdlV2a/zYnKOZarnivN7cyzytuQN5zvn//tEsIS4ZK2pYZLVy0dWOa9
rGo5sjxxedsK4xUFK4ZWBqw8uIq2Km3VT6vtV5eufr0mek1rgV7ByoLBtQFr6wtVCuWFfevc1+1d
T1gvWd+1YfqGnRs+FYmKrhTbF5cVf9go3HjlG4dvyr+Z3JS0qavEuWTPZtJm6ebeLZ5bDpaql+aX
Dm4N2dq0Dd9WtO319kXbL5fNKNu7g7ZDuaO/PLi8ZafJzs07P1SkVPRU+lQ27tLdtWHX+G7R7ht7
vPY07NXbW7z3/T7JvttVAVVN1WbVZftJ+7P3P66Jqun4lvttXa1ObXHtxwPSA/0HIw6217nU1R3S
PVRSj9Yr60cOxx++/p3vdy0NNg1VjZzG4iNwRHnk6fcJ3/ceDTradox7rOEH0x92HWcdL2pCmvKa
RptTmvtbYlu6T8w+0dbq3nr8R9sfD5w0PFl5SvNUyWna6YLTk2fyz4ydlZ19fi753GDborZ752PO
32oPb++6EHTh0kX/i+c7vDvOXPK4dPKy2+UTV7hXmq86X23qdOo8/pPTT8e7nLuarrlca7nuer21
e2b36RueN87d9L158Rb/1tWeOT3dvfN6b/fF9/XfFt1+cif9zsu72Xcn7q28T7xf9EDtQdlD3YfV
P1v+3Njv3H9qwHeg89HcR/cGhYPP/pH1jw9DBY+Zj8uGDYbrnjg+OTniP3L96fynQ89kzyaeF/6i
/suuFxYvfvjV69fO0ZjRoZfyl5O/bXyl/erA6xmv28bCxh6+yXgzMV70VvvtwXfcdx3vo98PT+R8
IH8o/2j5sfVT0Kf7kxmTk/8EA5jz/GMzLdsAAAAgY0hSTQAAeiUAAICDAAD5/wAAgOkAAHUwAADq
YAAAOpgAABdvkl/FRgAAEx1JREFUeNrs3WlTW2eaBuBbEtrQwiK0oQUJJFZjsN3ZEztJtzvppCbT
lenJzJf5F/ND5jdM1XzKLO2eZDpxEjsGx2Cw2TeB2AQIJDbtu3TmA21iGzthFRK+ryqqXNgHHT0+
ujnnPe95XpEgCAKIiE5IzBIQEcOEiBgmRMQwISJimBARw4SISljF898Ih8PweDyYn5/H9evXYbFY
WCUiOvqZSSaTgVKpRKFQQKFQYIWI6Hhhotfr0dnZCZlMxuoQ0fHDhIiIYUJEDBMiYpgQETFMiOh0
iJ5vQfDll18ikUggl8tBIpFAJBLh888/h0ajYbWI6PBhQkR0HPszYI+SKfl8npUjor0QqdiLEY6Z
ENGJPDkR4WUOEZ0KnpkQEcOEiBgmRMQwISJimBARw4SIGCZExDAhImKYEBHDhIgYJkTEMCEiYpgQ
EcOEiMrdsZojsWsBEe2fkYjFxw+TXC7HChIRAEAqlUIkErE5EhGd0hkKS0BEDBMiYpgQEcOEiIhh
QkQMEyJimBARw4SIiGFCRAwTImKYEBHDhIiIYUJEDBMiKnPsZ0JEJwuRigqIRKKfw+RIpzNintBc
FIIgQCQSsRB0bE+OHzZHeoXkcnmsbmxicS2AUDiKRCqDbDYLuVyK2motDLXV6HA7IJNWsFh09FBh
mFx8ge1dDE96sbG1A111FUz6GmjVKijkMijkMiSSKURicWyFolgPbOE3l5rhdlghkfAMlE4pTPL5
PCQSCatUpvzBbQxPziEST6DZaYNJr0PFrwREMp2Bd2kV0VgCH19/HUqFjIWk44dJJBLBd999h0wm
A4VCgZs3b0KtVh/Y2Ofz4d69e89877PPPkNVVRUre47y+QIejk5jeT0Id4MVVlPdkcdFxmcXsbSy
ji8+uQGtWsWi0vHC5NatW2htbUVLSwsmJyextLSETz/99MDGHo8HPp8P77///v73pFIpq3qONnfC
uNM/gpoqNTqbGyEWH39wdT24jYnZRXz227cYKPSrDpzzhsNhhMNhNDc3AwBaW1uxubmJeDx+YON0
Og2VSgWpVLr/RednZT2Ib3oG0NZkR1dr04mCBADMBh063A78371BJFMZFpiOFiaRSAQajWb/tFgi
kUClUiEajR7YOJPJIBqNoqenB319fdjZ2WFFz8mCbx09g2N4vasNxrqaU/u59cY6WIw6/DgwyiLT
0cIknU5DJnt20E0mkyGdTh/Y2GAwwGAwwO12QywW49atWwiFQqxqkS37A7jTP4w3rnSgWqs+9Z/v
dliRSKYw7V1msemlDkwoUCgUyGazB85A5HL5gY3tdjvsdvveKbHZjEAggIWFBVy9epWVLeKlTe/g
BN577TI0lcozeQ2RSIRrnS3oHRiDzWyAWqUs65rthKOY8i5jezeCUCQKpUKOSqUC7a4G2M0GVFTw
DuaphIlGo0EkEtmfGZnP5xGPx6HRaH71h9XU1HCqfRFt7oTx48AY3upuh0ZdeaavpZTL4LSZ0Tcy
hZvvXCvLeoUiMdx/PIFwJIamBgtaGm3QqlVIpTOIJ1MY9yzg4cg0fvv2VRh01TzATnqZU1VVBa1W
C6/XCwCYnZ1FXV0dVCoVUqkURkZGkM1mkUqlMDAwsB8e0WgUy8vLsFgsrGoRpDNZfP9gCJdbGs88
SJ5w2c3Y2g1jLbBVVrUSBAGDYx58dbcfZkMdfvvONThtZtRUaSCRiKGqVMCgq8abVzrQ1ebCX354
AO/yGg+yo57BvujW8O7uLm7fvo2Kigrk83l8/PHH0Gq1CAaD+Prrr3H9+nU4nU709/fD6/VCpVIh
Ho/jypUr6OzsZFXP/MMB/LVnACqlEq1NtqK+dmB7F97FVXz+0XtlUatEMoUfHgxDLBHjcksjZDLp
IbZJo/fRGK62u3Cp2ckD7iRhsl/URAKVlc/+1uvr64Pb7UZdXR2AvVmyiUQCKpWKDwAWycj0PHz+
IN7objuX1//p8QS6W5vQaDeXdJ2C2yF8/9NjOKxmNDXUH2nbVDqDB0MTuNruRkujjQfdcS5znvZ8
kCwsLCCVSu0HCbB361ij0TBIimQnFMW4ZwHdbU3ntg+trgY8mvCglJ/qmvf58e39R+hqdx05SABA
IZfh7auXMDjhwfompzycOEyeZzQa8cEHH7Bq56RQKODOwxFcanZCLj+/Z2Z0VRrIZVLM+/wlWaeh
SS8Gxmbw9pUO1NUc/9EOhVyGrtYm3O0fQTqT5QF4mmGiUnFK9Xl6ND6LSoUc9ca6c98Xl8OK4am5
kqqPIAD3BkaxuLqOd691nsotbIOuBlaTHnf7R3gAHnbMZGXn8Mn7b9/vsnJFFo+G4fOMwepqh0RS
Gv1G1hZmYLA5UVVrOPd9yeey8M2OQxAAo63xdBs+iURYm5+CzmRFrdHKg/E5//pRLcxVFT+HSSpb
OPTGy9ucS1LUD0o+j2/u3kdzkwO11aXzRPbWTggrq3787sbb57of0WgMvQ+HUKerRaPDirPoG5dI
pDA0Ponf33gXSqWcB+VTnHVSyCqeatuokB7+iqfFxB4XxdQ7OI5Opw6Xm/UltV9NBgPSkXWoEIHV
dD6XXr71IAYmR3HjigMW01nWRwYFbNhY9eDj937Dg/KkYyZUfKsbW1gJbKLD7SjJ/XM1WDAy5T2X
1x6dmUfvwDhe72o74yDZ43bYEI3FsewP8MBkmJSXTDaHnoFRdLe5SraFYr2xDrFkEhtFvH2azebw
cHQG3mU/rr9++UwebnwxAR3NTvQNTSFfKPAAZZiUjwdDEzDpa6Gr1pb0frodNgxNFufOTmBrF//5
bS9iiRTeudpxqBmtp0lXrUWVVoXR6XkeoAyT8uDzB7G+uYs2V0PJ76vFqEM4Fkdg++zu8uXzBQyO
e/DdT4/R0ezApWYHROc0UbLd5cDk3DLiyRQPVIZJaUtnsrj/aALdbU1lMbNYJBKhxWlD//DUmfz8
lfUgvvzmHnZCUdx4oxtGXc25vl+FXAaH1YjBMQ8PVoZJ6RIEAbd7H8FuMaK2xC9vnjk7MemRyebh
8wdP7WduhyK4ff8RHgxN4XJLE7rbXSWzpk9TgwVrgU1shyI8aBkmpal/ZAoisQhuR/m1crjc2oje
R+PIZk82D2k3HMW3vY/wzb1BaDVq3HijG7qa0gpWiViMZsfZnY2VIy7dVkLmfX4srQVx/bXLZbn/
1Vo19LXV6B+ZxnuvHa0VRTabw/zKOjyLK4glknDbLehqbTy3cZHDsFuMWFrbwMrGJmwm/St//HJF
vxKxG47iq7v9eOvapTNrv1gMhUIBPz4cgdNqwpvd7b/4byOxBPzBbfiDW1hZ34KhtgpWsx4GXS2A
8jgsA1u7mFtaxT+USX8XhskJ5fMFhKIx5PMFVCoVUFcqSmr/Mtkc/vt2L1qctpJ4iO/E9S4U8GP/
MIz6WnS6nVBXKhGOxRGNJRCN7335gzsQBAF1tVrUVmlRb6xDRZmuHvlgaAKdzY1wHaPVAcOkTKwH
t+FZXMXKehBKhRwSiRiJZAYSMWCvN8LVYDn3Xp+CIOCbnkHIZNIL1dUrXyjAM7+C3UgUyVQaKqUC
qkoFlAo5FHI5dNVaVF6QZ1x2wlGMTHnxT5+8f+K1ihgmJSQSS2BuaRWexVXIZBWwmQywGOsgfeou
QDyRxHpwB/7gFsRiMbpaG9Fkrz/dJ00PdUkg4Pb9RxCLRehqc/Far4wNjs3AaTWjw93wyr33eDIF
lVJxMcIkk81hwefHzMIqYokkLKY62Ez6QzVa3twJYcHnRyyRRGdzI9qa7EVZ6qBQEPDXew8hEotx
7VJL2YwR0ItFYwn0j0zhnz/94JVaKkMQgNnFFbQ02so3TARBwOrGJjyLq1jb2IShrgYWkx7GYw7e
RWIJLK74EdwOoau1Ce2uhjN7HiaTzeHf/+c2WhrtaHbaGCQXxMi0F/qaKlztcL8y73lybhlyWQVc
DZafw+QomZLP589t50PRODyLK/AurUGlVMBqNsCsr33mMuakv2Fml1YRCkXQ1e5Ca6PtVGehJlNp
fH1vALpqbck+CUzHk0im8NPjCfzpo/fOta1m0S5vEin8+fsH+PvfvY1qrbo8wkQQBCytBTA970M4
GoPNbITVpIdScXb/YdF4AnOLqwhHY+hud6PZYT3x4NrqxhZ6H4/DZauH3WLkp+8CmvL6oJRX/Opt
8Yvgq7v90NdWo7vdVfpjJslUGtPzPkzP+6CqVMJhMcGkrynqQGn4b2dCsUQSV9tcaHbacNSXLxQK
6BuZxvJaAF1tTSdqckylLZvL4W7fMP54811oynwZ1V8y7lmE1+fH21c7oK5UlG6YbO2GMTazgLXA
FuoNOjis5nNf3zYUiWF2cW+A95LbgdZG26EurdYCW3gwNIkqrRqX3A5IJFzH9qKbW1pFJpvDh292
X8j3txbYwo8PR/Hua5ehkElLM0yW1jYwNrOAWCIFp82MhnpjyTUFejJQG9jahdthQUujHTUvaM6z
tRvGw9EZxOIJtDY1wKSv5afsFVEoFHCnbxgfX3+t5HvRHFUskcSfv/sJ1y417z+IWjJhksvlMbPg
w/jsEhRyKRptZpj0upIvaiqTxfLqBtY2NiGTSWHW16K2SgOxRIy1wDb8G5todtpgt5jAuzWvHp8/
gI3NHfzdh29dmPeUzmTxv3f6YK83ouGpMb9zD5N0JovJuSVMzi1BX1eNRqsZVRp1WRY5FIlhNxxF
PJlCPp9HtUYNW73xlZ4NSUDPwBheu9wCxwUYbM9mc/jqx70BV7fj2eU+zi1M4skUxmYWMLe0Coux
Do32elQqFTzy6MLZDkUwNrOAL/5wo6x/sWSzOfzXt70w6KrR8YJHPooeJpFYHEOTXqxsbMJeb4DT
aoa8yP07iYrt0bgHdrMBnS3l+dxVMp3Bf9z6Hl1tLtjrX7zYWtHCJBSNY3jKi9X1IBpt9XDYzKiQ
sCcTvRqSqQx6B0fx+UfvQVVmZ+CJZBp/ufMAVpP+wKVNUcMkFInh8eQs1oM7cNrq4bSaSna5BqKz
NLe0hmQqhZvvXCubfd67/TsGt8PyzGBrUcMkEkvg0eQs1gPbcFrNcNhMkIgZIvTqEgoF3BsYxzvX
2mEtg45sj8Y98Cys4kqH+1DtMk89TGKJFB5PzMLnD6DJXg+nzVwWndWJimF7N4yRKS/+9IcbJdMU
++BnOInewb0evlc63Idek+jUwiSZzmB4cg7zPj8cVjOa7GbO8iR6gcm5ZQBCyc2MLRQEjHkWMO5Z
gNNm/sXxkTMJk2wuh9HpeUzP+2A3G+ByWsu27R5RsT609wZG8WZ3W8nMPQls7aJncAxKhRyXmp1Q
Ko7e/e7YYSIIAqa8yxie8sKoq0FLo+2VeNya6DREYgn0D0/ik/ffONep9qsbmxiemkc8kURHs/NE
7UuPFSbpTBbxZAp9w1NobbSf+8N3ROXIH9jG4wkP/uWPN4s610oQgOW1DQxNziFfEOByWGDW1574
KfwDYVL4hVXdc7k84skUBEGAQi7D1m6YRwTRCXiX1/af3TnrAdnA1i4WVzewsOKHWlUJp80EQ+3p
LbFaW62FulL5y82RCgUB8WQKuVwOcrls/01vh6I8GohOaHp+GaFIFJ/cePNUAyWbyyG4HcJaYAvz
y35IKyQwG3WwGA1n0lBMo1K+/DJHEAQkU2kk0xnIpFLIZdJnGgLthGM8EohOgWdhBSvrAbxz9RIc
VtORt8/l84gnUghuh7CxtYPAdgixWAI11RroqrSoN+qgOuNF3V46ZpLOZBFPpCCpEEMpk0H0ggeU
GCZEpycUiWFsZgFisQhWUx3UlUoIggBB2PvFLmDvz8DeXJB4IolYIoVEMoV8oQClXI5qrXr/q0qj
Kmo3wgNhspdwSRQKwv6CVS/DMCE6XYIgIBxLYHsnjHQ2sxcGAiASiSACABEAAZArZFAq5FDK5VA8
NfRwnp6Eyf6ehKNxKGQyyJRSsJkPUXGJRCJUa1So1qjK9j1U/DyIUvm3cREGCREdnfjnZGQxiOgU
woSIiGFCRAwTImKYEBExTIiIYUJEDBMiYpgQETFMiOis7U+nz2Rzh97I5w+wckQEAHA3WADlU2Ei
PsJ8eiV7vhLRc9mxHyYVFYfvLK/X1bCCRAQAkPwtOzhmQkSnc4bysr/I5/OsDhEd2oE2TZFIBHfv
3EEmk4FCocAHH34ItVrNShHR0c5Menp60Nbejn/84gu4XC7cv3+fVSKio4VJOBxGJByG2+0GADS3
tGBrcxPxeJyVIqLDh0k0GoVGo9nvbC2RSKBSqRCLcp0cIjpCmKTTaUhlz84hkcpkSGcyrBQRHT5M
FAoFctnsM/8gm8lALuMkNSI6Qpio1WpEIpH9pULz+Tzi8TjUGg0rRUSHD5OqqipotVrMz88DALxz
c9DV1UGlUrFSRPSL9lf0y+b2HvTb3d3FnR9+QEVFBfL5PH538ya0Wu0zG3FFPyLav6J5fnnQJ2Hy
RCKRQGVl5Qs3ZpgQ0fNh8tLp9C8LEiKiF+GDfkTEMCGi0rH/oJ/oCM2RpEfofUJEF/yMRLx3TrI/
AEtEdBL/PwB3KJB6mdE8ugAAAABJRU5ErkJggg==
`;
