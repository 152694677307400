import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useContent } from '../../../../core/api/workbench/content';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { DeprecatedRootState } from '../../../../store/state.type';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import { err, ok, Result } from '../../../../utils';
import {
  ContentElement,
  ContentElementType,
} from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import RecycleBinFileBrowser from '../../../organisms/workbench-browser/recycle-bin-file-browser/RecycleBinFileBrowser';
import { RECYCLE_BIN_PATH } from '../../part-right/config';
import { useSelectedDirPath } from '../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';

const RecycleBin: FC = () => {
  const selectedDirPath = useSelectedDirPath();

  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;
  const { data, error, isError } = useContent(
    contentArrayToPath([RECYCLE_BIN_PATH, ...selectedDirPath], false)
  );
  const content = (data?.content as JupyterContentElement[]) ?? [];

  const selectedContent: Result<ContentElement[], any> = isError
    ? err(error)
    : ok(prepareContent(content, selectedDirPath));

  if (selectedDirPath.length === 0 && selectedContent.isOk()) {
    // Add the "Directory Up" option at the top level of the Recycle Bin to allow navigation back to the regular file browser
    selectedContent.value.unshift({
      name: '..',
      path: '',
      type: ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN,
    });
  }
  return <RecycleBinFileBrowser content={selectedContent} width={width} path={[RECYCLE_BIN_PATH, ...selectedDirPath]}/>;
};

export default RecycleBin;
