export const textOutputImg = `
iVBORw0KGgoAAAANSUhEUgAAA0QAAAHECAMAAAAu6BrtAAAA8FBMVEX///8HGzLc
4uuTnKYJHTQQIzn6+/tPXW4NITc7SlzJzdJndIJjcH/8/P3M0NXs7e9NW2zl5+mO
mKPf4eSlrLNea3pseIYaLUL+/v73+PmqsbghM0cdMEUXKT8/T2FZZnZ4g4+YoKkI
HDPz9PbZ3OB0f4w9TV8yQlXp6+02Rlm+w8mCjJfP09iFjppUYnKdpa5ueocoOk0U
JzyhqLGLlZ97hpIsPVFJWGns7vKnrrXCx825v8Xw8vPi5OfT19u0usGIkp3AxcvR
1dmvtr2or7apsLckNkpGVWbV2d1DUmRxfYrEyc9/iZXk6OxLWmqQmaP4hpZnAAAa
Z0lEQVR42uzYwUoDMRRG4XtjO0VtsRqRmY3IuLDqRqnggAoiUhTN+7+OwWVNJtIy
9C7Ot5nNbA9/EgEAAAAAAAAAAAAAAAAAAAAAAAAAABtruuAAJISukX/oHICsTopC
/KuqBcAfddU5F8o7FCoBkFGF0hY1ztEQ0DdGzjWFIeoEwBaRBIYI6FcVbkXO8aYA
9KqdK0QkAHoREUBEwLaGj2iuqodi3kRVpwIU2Y3If0hO8N4vZM3MR8/DRLQ4eW8E
SDMa0eXyU88kZ19VJ7LmSKO9QSJ6G6muZgIkWYyovpmOVQ1FdKHRkwBJ9iLytytV
NRXRq0YHAiQZiyge41pVaxG93LftXIA0WxEtx/qr3SCixXARSf3lBciwFdG3Ru3o
8fQ6fu/sRATk2YuovXo4FjnfeUQ1Ef2wd69NaQNRGMefM5AU0cRS7uUmglyqEAHR
ClhUWm+19vt/m+4mIRsodRqRttDze+M6Ivgi/znJChP2e/61iNIPJgCOiP3HgkQU
PykVi9vfr6DEdOAVIxocJmvF9qGZwSzjaq9UrCVb5w3M+Wgl74spy9RnIrpIJBLn
cHwW6woAs9/eejqw7jArO9l+ap7tJD4CunjkBIytLKJYiVztBhR/REvuzo1r5Gpe
wUc/SZOre5mFj96KkKNehS+i92IZhkMT6zuYTXKEH3X4fE6TQ7vUY+JLGoytKqJo
gTzp6xVEZKTI5wSeu1PyyVXhqdTIoyWeicgsh8nTrGAqs03KfZQjYquMaKtLRPv5
LtnOXj+iyhunha0O2UJeQ3kSwqdv73NOA1m49HuyhXN2ItqvI0o6D4uQrYWpA3Lk
NbtPjoitMCKpNq4AjXcaCcNXj+iYhC1TB0Zl+RKFj24pcg6FUzEI10USDuCy7DT6
gwoMc2ef6JcRye+Spg4jvi0f1t2F45P9S2/GDSD+2CXiiNhqI0pmYDPDqojXi2iw
L59A9w2fPmxlEiw4jBoRRbJuXjdy/EzgGGvPRlSGI+Q/VyyR8GC4L9rhiNhqI6pl
4dohok7wiE7fzTn0RZSR86ZpwNWTeTQgvZ05e7wmwfQNojGmEs9F9ACXLlPZ9g2i
FKbuNI6IrTKimwYAdYRGjIARLaQiisqj3sSUUfcuXY5LpdIXeApyqqhBVIdS+3VE
b9WfmyKiWzWIwnF4Hjki9of+2WqSEH/diA7krIOyR0RJLFDzxsodCT0oz+zOJeA5
IaIb2PJi2YYS4ojYH4ooRkL0dSPK2RdByrmKatatdwY2lqFkA0eUkHMUUpaEMUfE
1ieiWnTOUEVUUTt+6tonD59Mw5wker1eXUbkHfI5BIhI5RlRo+yaI2LrE9Fzu3NV
WiQLV+OxEyElpc4Al4nIjniXI2KbEdGAFqnCFt9WBfkiaotVe5mIynKV4YjYZkQ0
oUWOIF3kSYrkmk/tdjvtRXQvV8tE9M4+H+SI2GZENCQhdTAnBmFUl0d9cqzPbyzs
iFVpmYhkuRpHxDYkIlNd489LEpH2YcHu3OGy10QmXxOxDYooTsI5FkkTUQ8LIkos
sTunXv+II2KbEVFGptLHLJXXt0URfZWh7L4wIvUTjohtRkQoybFSwc++EFEXiyJq
7Itla5mImmJZ44jYhkQ0oblRVBmoHCiGqaOIFxHa8pjPqqfLB43IIuErPE8cEVvj
iIxTeWybmGo0w62Mdzp3CdfFKalSruUoOsjAYZQoQETqLay1EVwh/igEW+eIMJZF
REJuEtWO93QF2cN7SMb7NEltqFFEySykUZsCR4QyCfULSEZrnyNiax0R+iTdnwxj
n8ZJ+6OtVTUfqNj6bO3dkKMI794Pwmmqd3V+3KUXRJTN2d0kQ1eT1g3xJ1vZmkdk
HNMM7YOaN0qn5n9nqkU++dvAEcHskhKuc0RsrSMCyl1StqpwjUrk2alckvDJq0ij
qdvGZfCI8DVHU7kr3p1j6x4RdvsdsoXPejqUQZMkbes9EJ39pWop4gyRQwPWCyLC
6KFAUj65i+8yJTC23jc+rk6s0JdBA3NG0YR1pGOR0dCyolm8nD5IhIYNCA9EdAbG
1juiv+qJiB7AGEf0UnqeiMpgjCMKwOpDafE9KhlHFNQwTHs6XEcaET2BMY7o91UK
RFQfGhCyjxEi6vJNKhlHFEhvn4T8m1SyqZEUAmMcUSC9AvloJxkwxhEF09iJ0NQb
PpdjP9i7s620oTAMw/+3SCgFwSqDIJMMIqiAgiOgKFinWnv/d9OEQDbSYEuomq5+
zxEKige860+2GRiRG2ftfC2TzYXWO0LEiIj+ACMi8hpGRMSIiBbCiIi8hhERMSKi
hTAiIq9hRESMiGghjIjIaxgR0btdMsvzrLtCEP2WFyPqBdvH9ZtIT+b5Ho1GqzIj
HDX03iai6vpNSoiceS6iZDONsdNLFxdvfIuIBhpwGxYiR16LaFCBoiVWPRHRFgxd
IXLksYjaGkaKsOTCXojoile/old4K6KoDsPz5iC1uvs4CqnuhYguDoEvQuTMUxGF
HwBkY2LpZABoQQ9EJMlvvGACzeWpiAowtGViE4ZtL0RENJ+3IjI/t7mk2B4A3DEi
+r8sF1EaQFOUOoAsI6L/yyIRRY/yuVzox7VMnMHwTZQhDKvuI+rsxSu5xl4kKS+F
r7fyuUq8fl6VGWfN+EMu0Yz4Xy4sxGKxc7HcGI9LIhI5bnx+3m7ey0upYei5droS
OxPxG68cCtGbRbSWx1ijKpZg1jDdTBdAIOw2on4FY7VrmeI/2sBY6yr14pl6AJad
snyaf6e8e4nUYNGe/DLlZgMW/cq/xttN0ptGFMzCtjEQZwUAO+IuonACU47Edn+C
Kemy2EoV2PTYKxFFChpstZJMJENQHoKMiN4yos8tAMVMCyOn4iwPIOEuotInq4XP
hzCpJ+4zMGgnXx7SVgMpew49YERLjxLR50cUt14WwEhdJrZhyeijPhkRvWFEpkq/
ZB7Zqc8/pKYaAHDpLqIDmKma+za9gg4gezYu5QSAllgTwyD3Yg29OUrjuFOScGSl
CMyNyPwqHvFLOBoyX9ZaFcul+RU+9asi0acWwIjobSOKJ2UkoqkiZoQAPIiriDpF
AAf+qeFzrLYQ0RRLuAIgkBrndQtAH4qlr78aUUEsvultxTwMj+Hxmx4yInrbiCop
GVsBcCgOdgEUO/MiOlmfsTcVUfIEQC0sY20zj6qYvrzYehzAEJkaRH2ZiL0W0aOM
+c1UQlODKCET9zojoreM6LYqE5tzluC+p9Vn0iEiByqiIAAtIhPhHXvX5SCfz38V
WxZAQQ2iHVEq8yP6EpaJBIA7NYi0qNieGBG90z9bIzBEZVbKLCXdcxfRNoCKKFsA
4uKgYo+VexjaoryyOhcT2xGAWxnJAGiI4mNE9E4RrTmfZxAHoO2Ku4jSAI5FOR9F
5eDOnnZ9AFpq4YhiAAJW9TD0GRF5JqIntfvuHFElOKOrIirNrvgNAGRkSrIaGcba
7faOHZEPQFoWiEjlGVCjbMCIyCsRFWA4EJenh5fhxB4z1afDAJSE2gJcJqJRxKuM
iDwS0VAD8MXvNqIOnJRlJBoKwKYiagBoLBNRwXyUZETkjYg6AQCVkriNaAgn+2K6
yMAUSNeeG43Ghh3Rg/lomYjWAaSFEZEnIhpkAeycieuIujAktmesiaG3AyAQ7/tn
FxZWAORdRmSXqzMi8kREF2kAh2viPqKI2sd3WvTTdx1W5/aW3SeKcJ+IvBLR6gmA
TFmWiCgKw7k42QDQFoeIYkuszqn332dE9PERlSoAWvuyTERJM5VjeUnl9d0pom9m
KKsuI1LPMCL68Ij85mdX78hSEUkeQLokv/pqFipOEVWLAOrLRFQDUGFE9OERhQBo
fVkyoiFmRlGpo3LAmkzsB+yIpAFgI6V+XWbRiJozZ7c/MyL6iIieABTbsmxE4RMA
gYhMVGtaPWlvzl3J2MUJVCmDIoDtpFjCeSwQkTqEtdKzBxFPhaCPiKgAww9ZOiLp
m0UEfOMkyof28Q9Zs4dNq5PNDZgaokYR4ikx9RpYKCL11+9ciClcLzIiet+I1IEK
6a0ZMRcRyTFMD0fdtct+XAeQLav5gFz9prl1C0tO1L0fgJNE+/r8oAUXEaXSo27i
vuth/RY8s5U+IqI8nDTcRBQ+wAv6rpo3ymFl+sjUJqZk7haOSCItKNoOI6J/OiKR
QgvK57KM9fKwrZSuYLi0K9IxcVe9Wjwi+ZbGRPqaq3P0r0ckq8eHGNFO235ROjWY
9M+bIsGXP1TOB6whsheWpouIpPeYhSkTX5UfZkpC9G/f+Lg8bPq+dqoyoxeMNff9
4qTXbTaDKXHP34n5uqN3fARwKkT/dkQf6hnAoxAxIrf8GQAFIWJEC2gei1LnPSqJ
ES2qq2HLL2P7OoBnIWJEf640OpewGxZD6ikAoMWbVBIjWki7CEPmUyJe02HyCREj
Wkg7iyn6UVKIGNFiqisBTHzithwxIjfO2vlaJpsLrXeEiBER/QFGROQ1jIiIEREt
hBEReQ0jImJERAthRERew4iIGBGRC+8b0S6Ar+IJlzzSmrxnuYh210O55wNfUBxU
fT5fR6aVjO90/1JEvfpzk8dckycsE9H9HcbyF/KLfQBbMm0NQPwvRXQE4FqIPGCJ
iH5osOndd45ohRcVoZ/s3W1T4lYYxvH7GpJsQALaiEABWR7kUWHDkxZQFFwUdNfv
/22ak0AIEbbEmda0vX8vnGw8ZPaF/zlwEpKg+HhEDxJQjD5evnSfAGTG/2xEDx1k
asRYAHw4ongG6Aw3DyWJ/rMRUSmiEWNB8OGICu73UwtA1g6LiFfn2H/NhyM6Et24
i9I5Ivb/5COi+KjXjZRopQ6EyWYPmfiO6GTyFnq4oS2pSPf+bfKs0BYlVihESltL
3Lqu24PMDfGfSr+8FR5qtOXHrDe7JaKSrvP9E5jLJ0U0MSB02mRTTLQ2BND1GdFd
QoJgNMhRes3CMn9MkWOcl2GSWvFNRL8BKJNJs3aVzyWYissSOWZzCMlTOgVUYszl
EyJ6vCpiJUHvhQBE/EV0qmKl+KqRLZaB48lJVK9iJVPYHdH9KIeVwbqiVLOIlW89
joh5fEJEcyDZ+tbKwDQkLyUMzBVfEb0BkBYX/WN58/CjaU5UcBG6ykuu1+pJEVo1
+qUDYHdEVQnqz7OEFdsV2b7B1Lk+EjtVjoh5fEJEQDNNROkLAOfkoYm9XfIT0aUE
zK0an6uAVCahIhLRyHQ3B4oxskRFJbpIdZjdGZHQOiHTHyrQuSGhLDpsioP9+AZw
RMzrEyK6WPVSBySFXJTy9yqAM9oVUSLmNnEiStUBWSdLrbPaG3M9HmgEoEeCLgH1
sTNV7Y6okibLEkCDhMRmBT4V5YiYx2dE9EUjWw9AmdZOVVWCSW5quyLyciIa2o3Y
roCiCKrx8+fPGNlSHSDvTEQTWpnsjmheI8HusL2eiAYp590mR8R2+pzzRDMAI1rr
wtbTyFdE3wBpTCtTKxOPOlAlIQsYztH3rM6duR8D2ydTe+vSugRHxNw+OaIHAI/v
ZiIMYjsjyg3cDCeiufskk6KKMjwqq4jSReArHRpRar1K8QqgxhGx3QIVkUnRRy0A
naGPhQVNAupNRw642HxD6aJVqdfr8iqiZwBv/iMS2aQ4IrZHwCISHlR/S9w1eLXs
ILtVbFTXn4J+9x9RBZgTR8T2CGBE1p90+/CI7uD1ZDW0gEnKVhdPT51VRF0AQ/8R
VYEwR8T2CmBEpSKQODwisXHcc3tcL8T9/D299ZmoAWDmP6JjYMARsb0CGBEZQOXw
iKgDHJHXSOSQ8iws6CIb/xGdATJHxPYJRkRjc0HggRx1fxGFgTB5vQJqiTwRKdKH
Vud6vDrH9gtIRJosgnDIwJGPiKKA/IM8roE6eSMiAzA03xF95/NEbL+ARETngKG4
gyn4iOgR7gSV6Xp6ytKK3llH9OpengsdGtE452rvxuCI2LZgRPQVwCvZUueANPUR
ES1caZSOpbfV5xhMViPrAAwS4h1gcEuWiXRoRNTcXNCdXvC1c8wjGBHdzAFpOSaT
/gVAn/xE9KwCWKaJSHkJA8XIanrKnaaISoUsxDY5U1F2qBHdtmUcHNFNR2zGiZRh
i6/iZl7BiIgiooPO+VGiLjbCiq+IqFcEoIajCyuMe6sA0SI69YEKQHauFr+pWrsX
hvh5cERUKMI0eJIBSBwR2xaQiGg0gCPxg/xFRA8G1uQQWeJVrORmR5uTrKVjrNTL
0sER0UyGTWrmOSK2LSgRkRKaF2FSz4dEfiOi0nIgWcGclWlFKyStPa0T6gJorne/
WrlmlmlKHh4Rxa47ANT6A+UBmRjb53MfrVKa/j7SNfqgdOxxNFXIJXXbmOkpeic+
GcXJN236+6Vir5fXibG1YEX075ADEsTYGkd0mPh063qie2LMwREdIn0uz9abBqA+
E2MOjugQF0DxtUymhwGAK2JsgyM6hJ6BKfnlKQtTVSHGHBzRYfRjrBWPboixDY7o
UKO8DFPy6YUYc+OIDpcuN4ZxYsyDI2IsWDgixjgixnzhiBgLGo6IMY6IMV84IsaC
5gMR3aJJbsqY9orTPhIg034KgBxtvAC4JsYC6C8jamS3Ke8iqqg67dHHI0fE/uv+
MqJy37JA1N7Q3kWUmNdoj0Jy9PdEdBkKhWK0h3cYY+98xtu5e8Tcb+d8+Fsi+u2w
rzf8xt+CYDtwRBwRC5aPR6TQe2naK707IoXcNGVnRMpfRZTmiJgPwYgo8m1QnEdv
SOifk6C06x1krxvk8nv4mYTGdRK5cFvxRFRaDopGfko2/cJQpWo+5olo8iWphttb
EV2F5wAy4bA4YGpynETy+jdNzFfhcPhY7OyZG01nGH8lj3kEIKKJnAi95ZEZkykv
k0l5QvWie2XggjYK9qtekY22ewtU0lsRSVUI8oyEyw4s0lJxR9QrQqi7IzrCikJU
ysNW0e3b7CNEdCsDqu4M44iYx+dHdJ67JFMbzU1EV/hutZTHzBvRA85ICGHpjsih
nhCRnsVa3xXRBI7dEeWxVkkRxYpAckwJ6ygcEdsjABEhRELKqG8iOjfIMpai3ohe
cUKWsLEdUaYdexTtLO1b8uC4EekXASmyiagCoD6JXEnuiMYnTQBfT06IZgDks+Fv
AwCPq776EQDZkmsYY1sCENEiRZZWbhPRzwzZJg1vRPfQyRJ5TLkjkqaruw3LmhVD
XbGKA6JOROK3nbiYxfYsLByv7ikXA3BORLUOIFcBnPLCAtsrABE1yXaGtBNRCH2F
bN6ILnEc37/EXQVwQtH188fjEmA4ET1aSe1f4i5JgFojkwFk7d9YKimOiO0XzIi0
r8hcNNK7IqLvcif6/WZPRC0AEXqyfgoZAPF1RPcAer+IqAxArgsdACUiUgYwFWO8
xM32C2hERA+JJOTWyBuRUL4wIFXayq6IogBm1nykk1AHUF5HtATQ/UVEQ7iVydSD
KcznidgvBDYiotS0Z+BryhOR7ba9wNN4R0QJAA90DGBKwhxAyT0TFX4R0R0ANbMm
KixlIDQ4IrZfgCMyKUsUPBE5HqX8jogWAHRaWvORKa0Cma3PRF9/EZGiAnKNXF5h
qSocEdsnoBGV9BJZtOQXT0SaHidbq6O4I5LEft1+sGQBQItMXVGLE1EEwFwjotn7
iPpkqq4ro2GNVoeTwwAKm2GMeQQzogjeyFYNeyJS5NZ6fHHsjgiVO226sOsZZwE0
x8okB2DkRJQSkUTj6VHOHZGdWl1XNGoDkAulVLkn1UurZYrXSwnIxd3DGNsSyIiU
pFEjYVhceiKilvpCwo9M1XvFggpBDDqFqajCasqJiE6dYVsRRWC5I7qGIMN0RtSw
T7MeiX+5hzHmFsyI6EXNRk/v2nnZiHsjihtyKzSdHWXlyFZERhKWPplSfQm2p5or
Ii0BS7i4FZEWXtdxco2VVom0KoCufcpVmophHBHbIaAR0XNeVCAvf7xf4o73OwCK
rUvaiiiqn0vAvEu2SEUGJCOU2r6K+z4LyEfpzFZEpIt2pGcydasSIJ/3NKICgLom
XiRidIbxE77YbsG7249SHuoK7aTdvjyX6L1SpOYeVY7tGqRfKvReLfKcJptyN9VI
2DeMMbcAR8TY/xJHxBhHxJgvHBFjQcMRMcYRMeYLR8RY0HBEjHFEjP3JPh0SAAAA
MAzq3/oZ5i6gA4lE8EYikAgSieCNRCARJBLBG4lAIkgkgjcSgUSQSARvJAKJIJEI
3kgEEkEiEbyRCCSCRCJ4IxFIBIlE8EYikAgSieCNRCARJBLBG4lAIkgkgjcSgUSQ
SARvJAKJIJEI3kgEEkEiEbyRCCSCRCJ4IxFIBIlE8EYikAgSieCNRCARJBLBG4lA
IkgkgjcSgUSQSARvJAKJIJEI3kgEEkEiEbyRCCSCRCJ4IxFIBIlEMPbpkAAAAIBh
UP/Wz3A3AR2okQgkgotEUCMRSAQXiaBGIpAILhJBjUQgEVwkghqJQCK4SAQ1EoFE
cJEIaiQCieAiEdRIBBLBRSKokQgkgotEUCMRSAQXiaBGIpAILhJBjUQgEVwkghqJ
QCK4SAQ1EoFEcJEIaiQCieAiEdRIBBLBRSKokQgkgotEUCMRSAQXiaBGIpAILhJB
jUQgEVwkghqJQCK4SAQ1EoFEcJEIaiQCieAiEdRIBBLBRSKokYixY8c2DMMwEEUN
FZrB3kRLaP91skBi5xAguOK9lvUHCSIiiIgI2ogIRAQREUEbEYGIICIiaCMiEBFE
RARtRAQigoiIoI2IQEQQERG0ERGICCIigjYiAhFBRETQRkQgIoiICNqICEQEERFB
GxGBiCAiImgjIhARREQEbUQEIoKIiKCNiEBEEBERtBERiAgiIoI2IgIRQURE0EZE
ICKIiAjaiAhEBBERQRsRgYggIiJoIyIQEUREBG1EBCKCiIigjYhARBD5f0TnAdw4
HyLaYx7AjTn27XyNdQA/RHINqwjuzDGup8q2iuCjuZ+vtT3Gmr4L8MY51xj7m4sP
+GgdX7jWHsAbe10HvNqDAxIAAAAAQf9ftyNQAQAAAAAAAAAAAADgLAeljZpZVorl
AAAAAElFTkSuQmCC
`;