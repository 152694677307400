import React, { Component, createRef } from 'react';
import { DataElement, SelectionElement } from './SelectableScatterPlot';
import * as d3 from 'd3';
import { max, min } from 'd3-array';
import { deepEquals } from 'common/dist/utils/deepEquals';

interface Props {
  /** Selected category that will be used to mark the data points */
  selectedCategory: string;
  defaultCategory: string;
  /** List of data points to display */
  data: DataElement[];
  selection: SelectionElement[];
  width: number;
  height: number;
  /** List of categories */
  categories: string[];
  /** Category -> Color mapping */
  colorsMap: {
    [category: string]: string;
  };
  /** Force the sizer to measure again (called after drawing the SVG for the first time) */
  requestSize: () => void;

  updateSelection(data: SelectionElement[]): void;
}

export default class Plot extends Component<Props> {
  svgRef = createRef<SVGSVGElement>();

  constructor(props: Props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseDown(event) {
    const p = d3.pointer(event);

    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        svg
          .append('rect')
          .attr('rx', 0)
          .attr('ry', 0)
          .attr('class', 'selection')
          .attr('x', p[0])
          .attr('y', p[1])
          .attr('width', 0)
          .attr('height', 0);
      }
    }
  }

  handleMouseMove(
    xScale: (x: number) => number,
    yScale: (y: number) => number,
    margin: {
      top: number;
      right: number;
    },
    event
  ) {
    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        const selection = svg.select('rect.selection');

        if (!selection.empty()) {
          const p = d3.pointer(event);
          const d = {
            x: parseInt(selection.attr('x'), 10),
            y: parseInt(selection.attr('y'), 10),
            width: parseInt(selection.attr('width'), 10),
            height: parseInt(selection.attr('height'), 10),
          };
          const move = {
            x: p[0] - d.x,
            y: p[1] - d.y,
          };

          if (move.x < 1 || move.x * 2 < d.width) {
            d.x = p[0];
            d.width -= move.x;
          } else {
            d.width = move.x;
          }

          if (move.y < 1 || move.y * 2 < d.height) {
            d.y = p[1];
            d.height -= move.y;
          } else {
            d.height = move.y;
          }

          selection
            .attr('x', d.x)
            .attr('y', d.y)
            .attr('width', d.width)
            .attr('height', d.height);

          svg.selectAll('circle.selected').classed('selected', false);

          const radius = 2; // TODO It's half the radius as in the render() method

          svg.selectAll('circle').each(function (data: DataElement) {
            if (
              !d3.select(this).classed('selected') &&
              // inner circle inside selection frame
              xScale(data.x) + margin.right - radius >= d.x &&
              xScale(data.x) + margin.right + radius <= d.x + d.width &&
              yScale(data.y) + margin.top - radius >= d.y &&
              yScale(data.y) + margin.top + radius <= d.y + d.height
            ) {
              d3.select(this).classed('selected', true);
            }
          });
        }
      }
    }
  }

  handleMouseUp(
    selectionMap: {
      [id: string]: string;
    },
    color: (id: string) => string
  ) {
    const { updateSelection, selectedCategory } = this.props;
    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        // remove selection frame
        svg.selectAll('rect.selection').remove();

        // Update the selection
        const updatedSelectionMap = selectionMap;
        svg
          .selectAll('.selected')
          .each((e: any) => (updatedSelectionMap[e.id] = selectedCategory));
        const updatedSelectionList = Object.keys(updatedSelectionMap).map(
          (id) => ({ id, category: updatedSelectionMap[id] })
        );

        this.updateCircleColor(color, selectionMap);

        updateSelection(updatedSelectionList);
        svg.selectAll('circle.selected').classed('selected', false);
      }
    }
  }

  handleMouseLeave(
    selectionMap: {
      [id: string]: string;
    },
    color: (id: string) => string
  ) {
    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        // -- Commenting out the following lines improved the usability, since the selection rectangle doesn't disappear when leaving the scatter plot while dragging
        // this.handleMouseUp(selectionMap, color)
        // svg.selectAll( "rect.selection").remove();
        // svg.selectAll('circle.selected').classed("selected", false);
      }
    }
  }

  updateCircleColor(
    color: (id: string) => string,
    selectionMap: {
      [id: string]: string;
    }
  ) {
    const { colorsMap, defaultCategory } = this.props;
    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        svg.selectAll('circle').style('fill', (item: DataElement) => {
          const category = selectionMap[item.id] || defaultCategory || '';
          const fallbackColor = color(category); // Only used if the point is not selected AND there is no defaultCategory specified
          const userDefinedColor = colorsMap[category];
          return userDefinedColor || fallbackColor;
        });
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const { width, height, selection, data } = this.props;
    // If the width or height of the SVG changed: draw the chart
    if (width !== prevProps.width || height !== prevProps.height) {
      // Calculate the scales
      this.drawChart();
    }
    // If the selection changed (for example since all points were selected via the "Mark all" button): draw the chart
    if (!deepEquals(prevProps.selection, selection)) {
      this.drawChart();
    }
    if (!deepEquals(prevProps.data, data)) {
      this.drawChart();
    }
  }

  drawChart() {
    const { data, selection, categories, width, height } = this.props;
    if (!data || data.length === 0) return;

    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(categories);
    const radius = 4;
    const margin = { top: 20, right: 20, bottom: 40, left: 20 };

    // --- Settings
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Calculate the scales
    const xScale = d3
      .scaleLinear()
      .domain([
        min(data, (d: { x: number }) => d.x),
        max(data, (d: { x: number }) => d.x),
      ])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        min(data, (d: { y: number }) => d.y),
        max(data, (d: { y: number }) => d.y),
      ])
      .range([innerHeight, 0]);

    // --- Derive the selection map
    const selectionMap: {
      [id: string]: string;
    } = {};
    selection.forEach((s) => {
      selectionMap[s.id] = s.category;
    });

    const svgElement = this.svgRef.current;
    if (svgElement) {
      const svg = d3.select(svgElement);
      if (svg) {
        // --- 1. clean old drawings
        svg.selectAll('g').remove();

        // --- 2. draw
        svg
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .append('circle')
          .attr('class', 'dot')
          .attr('r', radius)
          .attr(
            'transform',
            (item: DataElement) =>
              'translate(' +
              [xScale(item.x) + margin.left, yScale(item.y) + margin.top] +
              ')'
          );

        this.updateCircleColor(color, selectionMap);

        svg
          .on('mousedown', this.handleMouseDown)
          .on('mousemove', (event, d) =>
            this.handleMouseMove(xScale, yScale, margin, event)
          )
          .on('mouseup', () => this.handleMouseUp(selectionMap, color))
          .on('mouseleave', () => this.handleMouseLeave(selectionMap, color));
      }
    }
  }

  componentDidMount() {
    this.drawChart();
    this.props.requestSize();
  }

  render() {
    return (
      <div
        className={'Ssp--plot-parent'}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <svg
          style={{ width: '100%', height: '100%' }}
          ref={this.svgRef}
          onMouseDown={(e) => {
            e.stopPropagation(); // Otherwise the react-grid-layout elements will move too when a selection is being drawn
          }}
        />
      </div>
    );
  }
}
