import example from './stories/Example.json';
import { Config } from '../../../../../pages/augur/type';
import {
  ClassificationTreeV1ReportData as ClassificationTreeReportData,
  ScoreDistribution,
  SimplePredicate,
  ClassificationTreeNode,
  ClassificationTreeData,
} from 'common/dist/types/reportData/classificationTreeV1ReportData';
export type {
  ClassificationTreeReportData,
  ScoreDistribution,
  SimplePredicate,
  ClassificationTreeNode,
  ClassificationTreeData,
};

export type ClassificationTreeConfig = {};

export type ConfigClassificationTreeConfig = Config<ClassificationTreeConfig>;

export const configTypeClassificationTree = `{}`;

export const reportDataTypeClassificationTree = `{
    type ScoreDistribution = {
        confidence: number,
        recordCount: number,
        value: string
    };
    
    type SimplePredicate = {
        field: string,
        operator: string,
        value: number
    };
    
    type ClassificationTreeNode = {
        children: ClassificationTreeNode[],
        id: string,
        recordCount: string,
        score: string,
        scoreDistribution: ScoreDistribution[],
        simplePredicate: SimplePredicate
    };
    
    type ClassificationTreeData = {
        root: ClassificationTreeNode;
    };

    type ReportData = {
        data: ClassificationTreeData
    }
}`;

export const defaultConfigClassificationTree: ConfigClassificationTreeConfig =
  {};

export const defaultReportDataDefaultClassificationTree: ClassificationTreeReportData =
  example;
