// --- TODO try to move everything into common/dist/constants/frontendRoutes
import qs from 'qs';

export const app = {
  prefix: '/app/',

  models: 'models',
  newHabitat: 'newHabitat',
  newAugur: 'habitat/:habitatCode/new-augur',
  habitat: 'habitat',
  details: 'details',
  account: 'account',
  error: 'error',
  admin: {
    index: 'admin/',
    users: 'users',
    addUser: 'addUser',
    addGroup: 'addGroup',
    resources: 'resources',
    maintenance: 'maintenance',
    createBackup: 'createBackup',
    createTemplate: 'createTemplate',
    auditLog: 'auditlog',
  },
  codeCapsule: {
    prefix: 'habitat/:habitatCode/codecapsule/:codeCapsuleCode',
    versions: 'versions',
    executions: 'executions',
  },
};

export function getNewAugurLink(habitatCode) {
  return `${app.prefix}${app.models}/habitat/${habitatCode}/new-augur`;
}

/**
 * Returns the augur details link
 * @param habitatCode
 * @param augurCode
 * @param categoryId
 * @param tabId
 */
export function getAugurDetailsLink({
  habitatCode,
  augurCode,
  categoryId = undefined,
  tabId = undefined,
}) {
  if (categoryId && tabId) {
    return `/app/habitat/${habitatCode}/augur/${augurCode}/${categoryId}/${tabId}`;
  }

  if (categoryId) {
    return `/app/habitat/${habitatCode}/augur/${augurCode}/${categoryId}`;
  }

  return `/app/habitat/${habitatCode}/augur/${augurCode}`;
}

/**
 * Returns the code capsule details link
 * @param habitatCode
 * @param codeCapsuleCode
 * @param tabId
 * @returns {string}
 */
export function getCodeCapsulesDetailsLink({
  habitatCode,
  codeCapsuleCode,
  tabId,
}) {
  if (tabId) {
    return `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}`;
}

export interface HabitatRouteParams {
  habitatCode: string;
}

export interface AugurDetailsRouteParams extends HabitatRouteParams {
  augurCode: string;
}

export interface AugurDetailsQueryParams extends qs.ParsedQs {
  modelCode?: string;
}
