import { HierarchyPointNode } from "d3";
import { TreeNode } from "../Node";

const operators = {
  lessThan: '<',
  lessOrEqual: '<=',
  greaterOrEqual: '>=',
  greaterThan: '>',
  isIn: '[]',
};

/**
 * Parse array string from prediction
 * @param {String} arrayStr string which has the following structure: '"elem1" "elem2" "elem3"'
 * @returns {Array} parsed elements
 */
export function parseArray(arrayStr) {
  return arrayStr
    ? arrayStr.split(' ').map((element) => element.slice(1, element.length - 1))
    : [];
}

export function checkNodeHasPositiveScore(node: HierarchyPointNode<TreeNode>) {
  return !!+node.data.score;
}

export function checkNodeHasNegativeScore(node: HierarchyPointNode<TreeNode>) {
  return !+node.data.score;
}

export function getNodePredicateOperands(node) {
  const simpleValue = node.data.simplePredicate &&
    node.data.simplePredicate.value && [node.data.simplePredicate.value];
  const setValue =
    node.data.simpleSetPredicate &&
    node.data.simpleSetPredicate.array &&
    parseArray(node.data.simpleSetPredicate.array);

  return simpleValue || setValue || [];
}

export function getNodePredicateField(node) {
  const predicate = node.data.simplePredicate || node.data.simpleSetPredicate;

  return (predicate && predicate.field) || '';
}

export function getNodePredicateOperator(node) {
  const simpleOperator =
    node.data.simplePredicate && node.data.simplePredicate.operator;
  const setOperator =
    node.data.simpleSetPredicate &&
    node.data.simpleSetPredicate.booleanOperator;

  const operatorSymbol = operators[simpleOperator || setOperator];

  return operatorSymbol || '';
}

export function getNodeType(node: HierarchyPointNode<TreeNode>) {
  const hasChildren =
    node.data && node.data.children && node.data.children.length > 0;
  const hasParent = !!node.parent;

  if (!hasParent && hasChildren) {
    return 'root';
  } else if (hasChildren) {
    return 'node';
  }

  return 'leaf';
}

export function checkUnaryOperator(node: HierarchyPointNode<TreeNode>) {
  return (
    (node.data.simplePredicate && node.data.simplePredicate.operator && true) ||
    false
  );
}

export default function parseNode(node: HierarchyPointNode<TreeNode>) {
  const type = getNodeType(node);

  return {
    id: node.data.id,
    type,
    recordCount: node.data.recordCount,
    label: getNodePredicateField(node),
    operation: getNodePredicateOperator(node),
    unary: checkUnaryOperator(node),
    operands: getNodePredicateOperands(node),
    depth: node.depth,
    // TODO: positive and negative properties only for binary classification
    positive: checkNodeHasPositiveScore(node),
    negative: checkNodeHasNegativeScore(node),
  };
}
