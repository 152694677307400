import commonMessages from 'common/dist/messages/common';
import { StatusType } from 'common/dist/types/job';
import React, { FC } from 'react';
import { FiRotateCw } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import BackTo from '../../atoms/back-to/BackTo';
import Button from '../../atoms/button/Button';
import styles from '../details/styles.module.scss';
import { originName, OriginsType } from '../links';

export type Props = {
  /** Contains the id of the site from which it was opened (to show the "<< Return to" button) */
  origin: OriginsType;
  jobCode: string;
  jobStatus: StatusType;
  cancelJob: (jobCode: string) => void;
  /** Fetch (+ refresh) the job details */
  fetchJobDetails: (jobCode: string) => void;
};

const JobDetailsHeader: FC<Props> = ({
  origin,
  jobCode,
  jobStatus,
  cancelJob,
  fetchJobDetails,
}) => {
  const showCancelButton = (
    [
      'waiting',
      'waiting-in-execution-queue',
      'triggered',
      'running',
    ] as StatusType[]
  ).includes(jobStatus);

  const intl = useIntl();

  return (
    <div className={styles.detailsHeader}>
      <div className={styles.backButton}>
        <BackTo
          linkTo={origin}
          label={intl.formatMessage(commonMessages.backTo, {
            origin: intl.formatMessage(originName(origin)),
          })}
        />
      </div>
      <div className={styles.headerButtonContainer}>
        <Button
          Icon={() => <FiRotateCw size={16} className={'refresh-icon'} />}
          form={'squared'}
          onClick={() => fetchJobDetails(jobCode)}
          title={commonMessages.refresh}
        />
        {showCancelButton && (
          <Button
            color={'orange'}
            label={commonMessages.cancel}
            onClick={() => cancelJob(jobCode)}
          />
        )}
      </div>
    </div>
  );
};

export default JobDetailsHeader;
