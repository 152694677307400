import React, { FC, ReactChild, ReactChildren } from 'react';

import styles from './styles.module.scss';

export type Props = {
  children?: ReactChild | ReactChildren;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
};

const LoadingPlaceholder: FC<Props> = ({ children, width, height }) => {
  return (
    <div style={{ width, height }} className={styles.loadingPlaceholder}>
      <div className={styles.children}>{children && children}</div>
    </div>
  );
};

export default LoadingPlaceholder;
