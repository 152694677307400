import React, { FC, ReactElement } from 'react';
import {
  MessageDescriptor,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import styles from './styles.module.scss';
import ExpandCollapseIcon from '../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import GenericCollapsibleContainer from '../generic/GenericCollapsibleContainer';

export type Props = {
  /** Headline of the area */
  title: MessageDescriptor;
  /** Is the area initially collapsed? */
  initialCollapsed?: boolean;
  /** React component for the body of the area */
  renderBody: () => ReactElement;
  /** Callback for when the expand / collapse toggle is triggered */
  onToggle?: (isCollapsed: boolean) => void;
};

/** Component to render a collapsible area, including a headline and the expand
 * collapse toggle */
const CollapsibleHeadlineArea: FC<Props & WrappedComponentProps> = (props) => {
  const { initialCollapsed, title, intl, renderBody, onToggle } = props;
  const titleIntl = intl.formatMessage({
    id: title?.id || 'no-id',
    defaultMessage: title.defaultMessage,
  });
  return (
    <GenericCollapsibleContainer
      title={titleIntl}
      initialCollapsed={initialCollapsed}
      onToggle={(isCollapsed) => onToggle && onToggle(isCollapsed)}
      renderHeader={(title: string, collapsed: boolean) => (
        <div className={styles.CollapsibleHeadlineAreaHeader}>
          <div className={styles.CollapsibleHeadlineAreaHeaderIcon}>
            <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
          </div>
          <span>{title}</span>
        </div>
      )}
      renderBody={() => (
        <div className={styles.CollapsibleHeadlineAreaBody}>{renderBody()}</div>
      )}
    />
  );
};

export default injectIntl(CollapsibleHeadlineArea);
