import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';

import JobSchedules from './JobSchedules';
import { CProps } from './JobSchedulesPaginated.container';
import styles from './styles.module.scss';
import { useAppDispatch } from '../../../store/store';
import Button from '../../atoms/button/Button';
import GenericPagingHeader from '../../molecules/paging/GenericPagingHeader';
import Paging, { usePagingParameters } from '../../molecules/paging/Paging';

const links = {
  addSchedule: '/app/orchestration/add-schedule',
};

function renderHeader(offset: number, currentPageAmount?: number) {
  return (
    <div className={styles.JobSchedulesHeaderPaging}>
      {
        <GenericPagingHeader
          offset={offset}
          currentItems={currentPageAmount}
          itemLabel={'Job Schedules'}
        />
      }
      <div className={styles.JobSchedulesButton}>
        <Button
          linkTo={links.addSchedule}
          color={'secondary'}
          label={'Add Schedule'}
          Icon={() => <FiPlus size={16} />}
        />
      </div>
    </div>
  );
}

const JobSchedulesPaginated: FC<CProps> = (props) => {
  const { jobSchedules, fetchSchedules, pageSize, deleteScheduleThenFetch } =
    props;
  const appDispatch = useAppDispatch();

  const { offset, search } = usePagingParameters();
  useEffect(() => {
    appDispatch(fetchSchedules(undefined, undefined, offset, pageSize, search));
  }, [appDispatch, fetchSchedules, offset, search, pageSize]);

  return (
    <div className={styles.OrchestrationContent}>
      <div
        className={classNames(
          styles.OrchestrationInnerContent,
          styles.notScrollable
        )}
      >
        <Paging
          itemsPerPage={pageSize}
          currentItems={jobSchedules.codes?.length}
          searchEnabled
          Headline={() => renderHeader(offset, jobSchedules.codes?.length)}
        >
          <JobSchedules
            jobSchedules={jobSchedules}
            fetchSchedules={fetchSchedules}
            deleteScheduleThenFetch={deleteScheduleThenFetch}
          />
        </Paging>
      </div>
    </div>
  );
};
export default JobSchedulesPaginated;
