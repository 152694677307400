export const textInputAreaImg = `
iVBORw0KGgoAAAANSUhEUgAAAdoAAACkCAIAAAC/2Xj9AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAN
hklEQVR4nO3d6Vcb18GA8XtnNJIAbQiwAcvYxsHgeE+M3QQ3idN8aE//3+ac933jNI5TL/FS2zVG
BrPYArEJtO/S7YfrjlWWlCi85ZI8v09iNHM1jM95fLkagVRKCQDAQbMO+gQAAEKQYwAwBDkGACOQ
YwAwAjkGACOQYwAwAjkGACOQYwAwQvs5vnXnfrFU3uPOX9/6fu8j/+Wb23vfObmy9s339+7cf7zb
DpuZ7L1HT/c+oOvxs8nkylobB7bn5fTs9/ce/ddeDoBpPAd9Ar+IEuLxs8mvPv/U53Vat6+lNuff
LI5fOX9QJ7ajF1MzgUDnidjgjs8uJleFULV63fEc7n8UAO053IsVlUqls7NjS4sPo3yh6Pd7h44N
JJZWDvpcAByMfZ6IKSGeTcbXU2kpheM445fO+f0+/dTM/Js3iWSlWo1GwuOXz1uWJYTI5PJ//8fL
RqMphLh0brSnO7LbyIml5ZfTs/qoS+fGeqOR9Y300xfxTDb77Q8Phk/E3Fnnq9fz82+XSuXytz88
+OTqJb3x5fTs8up6tVqLDfafGz39bszkSnxmTkrp9TpXL74/1S02M9np2YVgoGvLCPGZOSHEWmqz
XKnU642PLp490tsjhPju7o/jly90dvj14V/fuvOnL288ePJ8dS1le+zkytrvPr605SUWEktDxwb6
eqIPnjw7NXRMb4zPzDWVSq6slcqVP3/1mRDixdRMcnXdsmR3OHTp3JhlyZ+44AAOl33OcXxmzmPZ
f/j9dSHE+sbm4+cvPx2/LIQolSpCiS9vXBdCPJuMx1/Pnx0ZrtXqPz55PnHtow6/r1yp3r778OaN
azv+qL6W2piamfvi02uO4ymVy7fvPvpiYrw3GpkYv3z30dObE9dadz5z+mR3JOwuVpTKleTKWmyw
/+zIsFLq2x8eHB88GgoG1jc2598s3py4ZlnW+kb6/pNnn38yvtv3tbi8On75/JYRhBBvFpNfTFxz
PJ58ofjd3Yd/vDlh2/aOI1y7cuEnFisWk6t/+P1127br9WalWnPn+7MLiZsT450dHUKIyVevLdv6
6rPfCSGmpudeTs+eGz292wUHcOjs82LFydjg2Jlh/bg32p3N5/Vjn8/7wakh/fjD0Q/eLCaFEPNv
F4dPHO/w+4QQfp/3+LH+5dX1HYedmpm/cuGs43iEEB1+/9jIqZm5N3s/q2gkHBs4KoSQUg4c7dvM
ZIUQU9NzVy6c1dPt3mhEKVGpVncdIRzaPoIQYvjEcf3/R6CrMzZwdGm5nbf+srl8Z6dfdzw2eDSx
tOw+dfrkcd1iJURiaeXsyLtrO/rByaXlFbH7BQdw6Ozz7Njv971ZTCaWVpRQQohara63W5Z8/5K2
rX+p52Ymm8nmFhJLQggpZa1WP33q+I7DZnP5aCTsftkTjSwmV/d+Vj6f131s23a93hBCZHL5B0+e
641SynKlUqlUfV7v3kfQB7rbw6FAvljc+1m55hNLQ8cG9OOhY/13Hz49ffLddfA676bJ5XIlXyh+
+8MD90X1NdztggM4dPY5x69mF/KF4vWPLtq2JYT4+tadHXdTqimEUEpcPn+2r6f7Pw5rW/82i282
VbPZ/OVn+8XENfmf99qrtn9zdGJxeWU1NT27oL9MZ3LlcmXLErBSKhIOblmWEXu+4ADMt885fruY
/OyTqzoNSr2PZmup6vWGXiIIhwJrqY295DgY6Epnct2RkP5yYzMdDgV/4akGuzpTG+ne6K5vHv5c
mWxevxXp8Xjq9fez1PeZ3qn9m+lsOBycGL/ibpmeXXiztHxm+ETrbh1+X7FYajQaW9amd7vgAA6d
fV477uzoWF1LCSGaSv39H1O1f1WpXC7Pv13UjydfvT5xbFAIcWro2NxCwl2HfRGfKVcqOw579szw
4+eTunHFUnlqes79cX5HXsez21CusZHhx88mq9WaEKLeaDx9Ed/7t+maXUjUGw0hRL5YXFpZHezv
E0JEw+G3i+/Wf1+9nm/8a2XDcZxyZevy9EJiaWhwoHVLbLD/7WJyy25SypNDxx49m9RxzxeK+r6O
3S44gEPnF82O79x/rOdl2pc3rl8+P3rv0bPJV68tyx774GQml9cTukBXV6Vau3XnfqVa7emOnB8b
EUL4vN4b1z9+/HyyXm8IoU4Nxfy+nW/SikbCI8Mnvrlz37YsKa2rl8+5t5HtKBwKNlXz/27fHb98
Ybd9jvRGPzwz/Ne//WhZUkp58cMzbVyBI33Rew+fVqrVWr1+9dJ5PXUdGT5x79HT//3urmXJ44P9
Xt+79d/YwNHbdx8uJlf0HSba4vLq+bMjrWPq9za3f+Lx/NjIi6mZ//nr32zb8vl8H1/8UAix2wVv
43sBcLAkfyuvbfGZOcdxhk/EDvpEAPwaHO5P5QHArwY5BgAjsFgBAEZgdgwARiDHAGAEcgwARiDH
AGAEcgwARiDHAGCE9j8kHY/HE4kE98kBgCaljMVio6OjbR7eXk/j8Xij0RgbG7Ms5tcAIIQQzWZz
amrKtu32itxmTBOJBC0GgFaWZY2NjSUSiTYPb+8wpRQtBoAtLMtqewmXpAKAEcgxABiBHAOAEcgx
ABiBHAOAEcgxABiBHAOAEcgxABiBHAOAEcgxABiBHAOAEcgxABiBHAOAEcgxABih/b8G0rZUKlWv
16WUUkohhGVZ4XDY4/EIITKZjMfj6erq2q/XWl5e7u/v/1mHJJPJgYGB7dtTqVQoFHIcZ59ODQD+
zQHkWAgRiUR8Pp9+XK1WU6lUX18fv0AZwG/ZwRfQ6/X6/f5SqXTQJwIAB+lgZsdbOI5Tr9e3bMzn
86VSSUrZbDaDwWBHR4feXq/X0+m0Ukop5ff7g8GgXvQoFArFYlEIIaUMh8PuqkKtVstkMkqpZrPZ
3d3t9Xr19kKhUCgUpJRKqdbxXc1mM51O12o1y7LcubxWqVSy2ax+HAgE9LEbGxt+v79QKDQajSNH
jjDZB/CzGJHjWq2m145dxWKxWq329vbqXK6trXm9Xtu2lVKpVCoajTqOo5TKZrO5XC4UCrXuX6/X
U6nUkSNHdKbz+XxPT4/evr6+rpeSi8ViqVTq6+vTuU+lUtubm81mHceJRqNCiHK5rF9ICFGv17PZ
bE9Pj2VZzWZzfX3d4/Ho+uvXsm37v3bpAPxqHPwMrlwul0qlLZNTn88XiUR0T6WUXq+3Wq0KIUql
kt/v1+2TUoZCoUajIYTI5XLu/h6Px+/3VyoVPVQoFHK36/gKIbLZbHd3t/teYiQSyeVyrSeglCqV
SoFAQH/p9/vdWOsu68mvZVnBYLBQKOinAoEALQbQnoOZHafTafdneY/Hs/19PNu2K5WKzpxSqlar
6UWGWq3Wem+DlLK7u1sppWe+7nallLtbax/1XxXURW7dvn21pNFoOI6je+2+ln5Qq9XclYqfeC0A
+FkO/s6KHRWLxXK5HIlEdKYzmYze3tpHl1LKtu2+vr49vrp7j10r3egtw+54uFKqt7eXpWEA+8vQ
phSLxWAw6CbPLaPH46nVaq175vN5vZtetdgL3eLW/m6ZdAshbNveMqB7Do7jlMvlvX8vALAXhubY
4/G4i7/lctldnO3o6CiXy26Rc7mcjmYgENjY2NCFVUplMpnts91WwWBwc3NTF1bfQREMBlt3kFL6
/X53QblSqbjnEwwGs9msu7hRKBTcpwCgbUbcWbFdKBTa3NwsFotSSsdxwuGwzquUMhqN6hvXlFJe
rzccDgshAoGAlHJ9fV3fiREIBH56MaGzs1Mptbq6qleTg8Gg3+/ffg7pdHplZcWyLH1ztN7uOE53
d/fm5qYQQinl8/k6Ozv/X64CgN+SA8hxT0/Pbk/ptgohLMvabTfHcXp7e7dv7+rq2v7p6i2fkG5d
X95xfyGE+wlpy7L0XW7b+Xy+7UvVu+0MAHth6GIFAPzWkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5
BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAj
kGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMA
MAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5
BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAj
kGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMA
MAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5
BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMEKbOZZSNpvN/T0VADjs
ms2mlLK9Y9vMcSwWm5qaosgA4Go2m1NTU7FYrL3DpVKqvSPj8XgikWj7cAD4lZFSxmKx0dHRNg+n
pwBgAt7KAwAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMAI5BgAjkGMAMMI/AbitFm+kSlsWAAAAAElF
TkSuQmCC
`;
