import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field,
  FormErrors,
  getFormError,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import styles from './styles.module.scss';
import { changePasswordAsAdmin } from '../../../../../redux/modules/account.module';
import Button from '../../../../atoms/button/Button';
import TextInputLinePassword from '../../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';
import ToggleInput from '../../../../atoms/input-elements/toggle-input/ToggleInput';
import { SettingsBlock } from '../../../../molecules/settings-block/SettingsBlock';
import { form } from '../../../../molecules/user-profile/change-password/ChangePassword';
import DescriptionPasswordRules from '../../../../molecules/user-profile/change-password/DescriptionPasswordRules';

export const AdminChangePasswordForm = 'AdminChangePassword';

export type Props = {
  userId: string;
};

const newPasswordField = (props) => (
  <TextInputLinePassword
    {...props.input}
    {...props.meta}
    id={'newPasswordInput'}
    label={{ defaultMessage: 'New Password' }}
  />
);
const confirmPasswordField = (props) => (
  <TextInputLinePassword
    {...props.input}
    {...props.meta}
    id={'confirmPasswordInput'}
    label={{ defaultMessage: 'Confirm New Password' }}
  />
);
const temporaryToggle = (props) => (
  <ToggleInput
    onChange={props.input.onChange}
    checked={props.input.value}
    disabled={false}
    description={{ defaultMessage: 'Temporary' }}
  />
);

const ChangePassword: FC<InjectedFormProps<FormData, Props> & Props> = (
  props
) => {
  const { handleSubmit, pristine, invalid, submitting } = props;
  const dispatch = useDispatch();
  return (
    <SettingsBlock
      color={'grey'}
      title={{
        id: 'account.password.title',
        defaultMessage: 'Password',
      }}
      settingsElements={[
        {
          title: {
            id: 'account.password.change_password.title',
            defaultMessage: 'Change Password',
          },
          DescriptionComponent: (
            <DescriptionPasswordRules
              error={useSelector(getFormError('AdminChangePassword'))}
            />
          ),
          Component: (
            <form
              onSubmit={handleSubmit((values: FormData) => {
                dispatch(
                  changePasswordAsAdmin(
                    props.userId,
                    // @ts-ignore
                    values.newPassword,
                    values.temporary
                  )
                );
              })}
            >
              <div className={styles.field}>
                <Field name='newPassword' component={newPasswordField} />
              </div>
              <div className={styles.field}>
                <Field name='confirmation' component={confirmPasswordField} />
              </div>
              <div className={styles.footer}>
                <Field name='temporary' component={temporaryToggle} />
                <Button
                  label={'Save'}
                  color={'secondary'}
                  type={'submit'}
                  disabled={pristine || invalid || submitting}
                  isBusy={submitting}
                  className={styles.saveButton}
                />
              </div>
            </form>
          ),
        },
      ]}
    />
  );
};

type FormData = {
  newPassword: string;
  confirmation: string;
  temporary: boolean;
};
export default reduxForm<FormData, Props>({
  form: AdminChangePasswordForm,
  validate(values: FormData): FormErrors<FormData, string> {
    return {
      newPassword:
        values.newPassword && values.newPassword.length > 0
          ? undefined
          : 'Specify new password.',
      confirmation:
        values.confirmation === values.newPassword
          ? undefined
          : 'Passwords do not match.',
    };
  },
})(ChangePassword);
