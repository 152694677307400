import React, { FC } from 'react';
import { scaleLinear } from 'd3-scale';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';

export const barHeight = 22;

export type Props = {
  importance: number;
};

const Bar: FC<Props> = (props: Props) => {
  const { importance } = props;
  const [ref, { width }] = useDimensions<SVGSVGElement>();

  const xScale = scaleLinear().domain([0, 1]).range([0, width]);

  const barWidth = xScale(importance > 1.0 ? 1.0 : importance);
  const adjustedBarHeight =
    barWidth >= barHeight / 2 ? barHeight : 2 * barWidth; // if the bar is smaller than barHeight/2 the height needs to be adjusted.
  const y = barWidth >= barHeight / 2 ? 0 : barHeight / 2 - barWidth;

  return (
    <svg ref={ref} width={'100%'} height={barHeight}>
      <rect
        className={styles.horizontalBarChartBackgroundBar}
        x={0}
        y={0}
        width={width}
        height={barHeight}
        rx={barHeight / 2}
      />
      <rect
        className={styles.horizontalBarChartBar}
        x={width - barWidth}
        y={y}
        width={barWidth}
        height={adjustedBarHeight}
        rx={barHeight / 2}
      />
    </svg>
  );
};

export default Bar;
