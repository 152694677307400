import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { PipelineTuningValueNode } from 'common/dist/types/pipeline';
import styles from './styles.module.scss';

type Props = {
  node: PipelineTuningValueNode;
  /** Is the corresponding node currently active? */
  isActive: boolean;
  tuningParameterMap: { [parameterId: string]: string | number };
};

const ModelStep: FC<Props> = (props: Props) => {
  /**
   * Renders the description of the node
   * @param description
   */
  const renderDescription = (description: MessageDescriptor) => {
    if (!description) return null;

    if (!description.id) {
      return (
        <span className={styles.description}>
          {description.defaultMessage}
        </span>
      );
    } else {
      return (
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        >
          {(text) => (
            <span className={styles.description}>
              {text}
            </span>
          )}
        </FormattedMessage>
      );
    }
  }

  const { node, isActive, tuningParameterMap } = props;

  if (!node) {
    return null;
  }

  const { displayName, description } = node;

  return (
    <div
      className={classNames(styles.modelStep, {
        [styles.modelStep_active]: isActive 
      })}
    >
      <span className={styles.displayName}>
        {displayName}
      </span>
      {isActive && renderDescription(description)}

      <div className={styles.parameters}>
        {node.staticParameters && (
          <div className={styles.static}>
            <span className={styles.paramsHeadline}>
              Static Parameters
            </span>
            <div className={styles.paramsContainer}>
              {node.staticParameters.map((param) => (
                <div className={styles.params}>
                  <span className={styles.paramsName}>
                    {param.displayName}:
                  </span>
                  <span
                    className={styles.paramsValue}
                  >
                    {param.value}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {node.tuningParameters && (
          <div className={styles.tuning}>
            <span className={styles.paramsHeadline}>
              Tunable Parameters
            </span>
            <div className={styles.paramsContainer}>
              {node.tuningParameters.map((param) => (
                <div className={styles.params}>
                  <span className={styles.paramsName}>
                    {param.displayName}:
                  </span>
                  <span
                    className={styles.paramsValue}
                  >
                    {tuningParameterMap[param.id]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelStep;