import React, { FC } from 'react';
import { useSelectedDirPath } from '../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import { WORKBENCH_FILENAMES } from '../../part-right/config';
import errorStyles from '../../../../../scss/partials/error.module.scss';
import NotebookApi from '../../../../core/api/workbench/git.notebook';

export interface SwaggerProps {
  /** The userId of the logged in user */
  notebookUser: string;
}

const Swagger: FC<SwaggerProps> = ({ notebookUser }) => {
  const selectedDirPath = useSelectedDirPath();
  //Get the index of the first asr file found
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  //Take only the path up to the asr file
  const path = contentArrayToPath(selectedDirPath.slice(0, index + 1));
  if (index === -1) {
    return <div className={errorStyles.error}>Invalid directory</div>;
  }
  const url =
    new NotebookApi(notebookUser).baseUrl() + '/modules/openapi' + path;
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        src={`/api/swagger-ui-dist/?url=${url}`}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default Swagger;
