import { Taxonomy } from 'common/dist/types/module.nbc';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  element: Taxonomy;
  getCheckedState: (elementId: string) => boolean;
  onChange: (element: Taxonomy) => void;
  isExpanded: boolean;
  hasChildren: boolean;
  toggleExpand: (elementId: string) => void;
  readOnly?: boolean;
};

export default class Item extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const { onChange, element, readOnly } = this.props;
    if (!readOnly) {
      onChange(element);
    }
  }

  handleToggle() {
    const { toggleExpand, element } = this.props;
    toggleExpand(element.id);
  }

  render() {
    const { getCheckedState, element, isExpanded, hasChildren, readOnly } =
      this.props;
    const disabled = readOnly || false;
    const defaultMessage = {
      id: 'no-id',
      defaultMessage: element.name,
    };
    return (
      <Fragment>
        <div
          className={`${styles.Checkbox} ${readOnly ? styles.readOnly : ''}`}
        >
          <input
            className={styles.Checkbox_mark_input}
            type='checkbox'
            checked={getCheckedState(element.id)}
            disabled={disabled}
            defaultChecked={getCheckedState(element.id)}
          />
          <span
            className={styles.Checkbox_mark}
            onClick={(event) => {
              if (!disabled) {
                this.handleChange();
              }
            }}
          />
          {hasChildren && (
            <span
              className={styles.Checkbox_chevron}
              onClick={this.handleToggle}
            >
              {isExpanded ? (
                <i className='fa fa-chevron-down' aria-hidden='true' />
              ) : (
                <i className='fa fa-chevron-right' aria-hidden='true' />
              )}
            </span>
          )}

          {defaultMessage && (
            <FormattedMessage {...defaultMessage}>
              {(text) => (
                <span
                  className={styles.Checkbox_label}
                  onClick={(event) => {
                    if (!disabled) {
                      this.handleChange();
                    }
                  }}
                >
                  {text}
                </span>
              )}
            </FormattedMessage>
          )}
        </div>
      </Fragment>
    );
  }
}
