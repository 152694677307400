import { ModuleWithAllRelations } from 'common/dist/types/module';
import _ from 'lodash';
import React, { FC } from 'react';

import ModuleCardLoading from './ModuleCardLoading';
import ModuleVersionSelectorTile from './ModuleVersionSelectorTile';
import styles from './styles.module.scss';
import { ModuleSelection } from '../../pages/augur/type';
import { FieldInputProps } from '../augur-layout-elements/settings-elements/types/type';

export type Props = {
  modules?: ModuleWithAllRelations[];
  loading?: boolean;
  /** Also call onBlur (additional to onChange) in the onClick listener? */
  blurOnClick?: boolean;
  beforeOnChange?: () => void;
};

const AMOUNT_MODULES_WHILE_LOADING = 3;
const ModuleType: FC<Props & Partial<FieldInputProps<ModuleSelection>>> = (
  props
) => {
  const {
    modules,
    value,
    onChange,
    onBlur,
    loading,
    blurOnClick,
    beforeOnChange,
  } = props;
  // Loading
  if (loading) {
    return (
      <div className={styles.moduleType}>
        {_.range(AMOUNT_MODULES_WHILE_LOADING).map((i) => (
          <ModuleCardLoading key={i} />
        ))}
      </div>
    );
  }
  // Loaded
  return (
    <div className={styles.moduleType}>
      {modules
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((module) => {
          const isSelected = value?.moduleCode === module.code;
          const explicitlyNotSelected =
            !!value && value?.moduleCode !== module.code;
          const selectedVersion =
            value?.moduleCode === module.code
              ? module.versions.find(
                  (version) => version.code === value?.moduleVersionCode
                )
              : undefined;
          return (
            <ModuleVersionSelectorTile
              {...module}
              key={module.code}
              selectedVersion={selectedVersion}
              onVersionSelected={(version) => {
                beforeOnChange?.();
                onChange({
                  moduleCode: module.code,
                  moduleVersionCode: version.code,
                });

                if (blurOnClick) {
                  // @ts-expect-error onBlur expects a blur event here which we don't have because we trigger it manually
                  onBlur();
                }
              }}
              isSelected={isSelected}
              explicitlyNotSelected={explicitlyNotSelected}
            />
          );
        })}
    </div>
  );
};

export default ModuleType;
