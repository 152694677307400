import { useRouteMatch } from 'react-router-dom';

import { AugurCategory } from '../../molecules/augur-menu/types';

export type AugurPageParams = {
  selectedPageCategory?: AugurCategory;
  selectedPageId?: string;
};

export const useSelectedAugurPage = (pathPrefix = '') => {
  const match = useRouteMatch<AugurPageParams>(
    `${pathPrefix}/:selectedPageCategory?/:selectedPageId?`
  );
  return match?.params || {};
};
