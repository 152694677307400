import React, { ChangeEvent, Component, FC, FocusEvent } from 'react';
import classNames from 'classnames';
import {
  injectIntl,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';
import InputError from '../../input-error/InputError';
import './styles.scss';
import { WrappedFieldProps } from 'redux-form';

export type Props = {
  /** Has the field been touched? */
  touched: boolean;
  /** Has the field an error? */
  error?: string | { id: string; values?: Record<string, unknown> };

  /** Is the field disabled? */
  disabled: boolean;

  /** ID of the input element */
  id?: string;
  /** Class name for the text area */
  classNameTextArea?: string;
  /** Name of the input field */
  name?: string;
  /** Render a label for the input field? */
  hasLabel: boolean;

  /** Intl specs for the label */
  label?: MessageDescriptor;
  /** Intl specs for the placeholder */
  placeholder: MessageDescriptor;

  /** Value of the input field */
  value: string;
  /** onChange callback */
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  /** onBlur callback */
  onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void;
  /** onFocus callback */
  onFocus: (e: FocusEvent<HTMLTextAreaElement>) => void;

  /** Amount of rows for the text area. Defaults to 5 */
  amountRows?: number;
};

class TextInputArea extends Component<Props & WrappedComponentProps> {
  static defaultProps = {
    amountRows: 5,
  };

  render() {
    const {
      id,
      name,
      touched,
      error,
      intl,
      disabled,
      label,
      hasLabel,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      amountRows,
      classNameTextArea,
    } = this.props;

    const fieldClassName = classNames(
      'TextInputArea--input',
      classNameTextArea,
      touched && {
        error,
      }
    );

    const placeholderFormatted = intl.formatMessage(placeholder);
    let labelFormatted = '';
    if (label) {
      labelFormatted = intl.formatMessage({
        id: label.id || 'no-id',
        defaultMessage: label.defaultMessage,
      });
    }

    return (
      <div className={'TextInputArea'}>
        <div className={'TextInputArea--header'}>
          {hasLabel && (
            <p className={'TextInputArea--label'}>{labelFormatted}</p>
          )}
          <div className='TextInputArea--error'>
            <InputError
              touched={touched}
              // @ts-ignore
              error={error}
            />
          </div>
        </div>

        <textarea
          id={id}
          name={name}
          rows={amountRows}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          className={fieldClassName}
          placeholder={placeholderFormatted}
          disabled={disabled}
          style={{ resize: 'none' }}
        />
      </div>
    );
  }
}

const IntlTextInputArea = injectIntl(TextInputArea);
/** @deprecated use react hook form variant instead*/
export default IntlTextInputArea;

/**
 * Same TextInputArea component, but assuming it is passed WrappedFieldProps from redux-form, e.g. as component of a <Field>
 * @param props
 * @constructor
 */
export const WrappedTextInputArea: FC<
  Omit<
    Props,
    'touched' | 'error' | 'value' | 'onChange' | 'onBlur' | 'onFocus'
  > &
    WrappedFieldProps
> = (props) => {
  const {
    input: { value, onChange, onBlur, onFocus },
    meta: { touched, error },
    ...otherProps
  } = props;
  return (
    <IntlTextInputArea
      touched={touched}
      value={value}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      {...otherProps}
    />
  );
};
