import React, { Component } from 'react';
import classNames from 'classnames';
import { scaleLinear, scaleBand } from 'd3-scale';
import {
  FormattedMessage,
  FormattedNumber,
  defineMessages,
  injectIntl,
  IntlShape,
} from 'react-intl';
import TooltipBarChart from './TooltipBarChart';
import augurMsgs from 'common/dist/messages/augurs';
import _ from 'lodash';
import { Path } from './TreePath';
import { ScoreDistribution } from './type';
import { BinaryTreeState } from './BinaryTreeShadowModel';

const getTotalRecordCount = (path: Path) => {
  return path && path[0] && path[0].recordCount;
};

defineMessages({
  confidence: {
    id: 'chart.tree.tooltip.confidence',
    defaultMessage: 'Confidence {className}',
  },
  instances: {
    id: 'chart.tree.tooltip.instances',
    defaultMessage: 'Instances {className}',
  },
  negativeClass: {
    id: 'chart.tree.tooltip.negative_class',
    defaultMessage: 'Class 0',
  },
  positiveClass: {
    id: 'chart.tree.tooltip.positive_class',
    defaultMessage: 'Class 1',
  },
});
interface Props {
  state: BinaryTreeState;
  intl: IntlShape;
  width?: number;
  height?: number;
  margin?: {
    top?: number;
    right?: number;
  };
  bandPadding?: number;
}
export class NodeTooltip extends Component<Props> {
  static defaultProps = {
    ratio: 1,
    width: 220,
    height: 100,
    margin: {
      top: 8,
      right: 0,
    },
    bandPadding: 0.1,
  };

  render() {
    const {
      state,
      intl,
      width,
      height,
      margin,
      bandPadding,
    } = this.props;

    const node = state.previewNode;
    const path = state.previewNodePath;
    const isShown = !_.isEmpty(node) && !_.isEmpty(path);
    const totalRecordCount = Number(getTotalRecordCount(state.previewNodePath));


    if (!isShown) return null;

    // The fallback for the key predictedValuesDistribution is required for the Spark Binary Classification job
    // Once the report creating is fixed / unified in the Spark Binary Classification job, this can be removed.
    const scoreDistributions = (
      node.data.scoreDistribution ||
      // node.data.predictedValuesDistribution ||
      []
    ).map((scoreDistribution: ScoreDistribution) => ({
      value: intl.formatMessage({
        id: `chart.tree.tooltip.${
          +scoreDistribution.value ? 'positive' : 'negative'
        }`,
        defaultMessage: `Class ${scoreDistribution.value}`,
      }),
      recordCount: scoreDistribution.recordCount,
      confidence: scoreDistribution.confidence,
      className: classNames({
        'tooltip-chart-bar--positive': !!+scoreDistribution.value,
        'tooltip-chart-bar--negative': !+scoreDistribution.value,
      }),
    }));

    const xScale = scaleBand()
      .domain(
        scoreDistributions.map((scoreDistribution: ScoreDistribution) => scoreDistribution.value)
      )
      .range([0, width])
      .padding(bandPadding);
    const yScale = scaleLinear()
      .domain([0, Number(node.data.recordCount)])
      .range([height, 0]);
    const additionalLabels = scoreDistributions.map((scoreDistribution: ScoreDistribution) => [
      intl.formatNumber(scoreDistribution.recordCount / Number(node.data.recordCount), {
        style: 'percent',
      }),
      intl.formatMessage(
        {
          id: 'chart.tree.tooltip.instances_count',
        },
        { recordCount: intl.formatNumber(scoreDistribution.recordCount) }
      ),
    ]);

    return (
      <div>
        <dl>
          <dt style={{ marginTop: '1em' }}>
            <FormattedMessage
              {...augurMsgs.msgModelViewerBinaryTreeTooltipTotalInstances}
            />
          </dt>
          <dd style={{ marginTop: '1em' }}>
            <FormattedNumber value={Number(node.data.recordCount)} style='decimal' />
          </dd>
          <dt>
            <FormattedMessage
              {...augurMsgs.msgModelViewerBinaryTreeTooltipTotalPercentage}
            />
          </dt>
          <dd>
            <FormattedNumber
              value={Number(node.data.recordCount) / totalRecordCount}
              style='percent'
            />
          </dd>
        </dl>
        <TooltipBarChart
          scoreDistributions={scoreDistributions}
          additionalLabels={additionalLabels}
          xScale={xScale}
          yScale={yScale}
          height={height ? height : 0}
          margin={margin}
        />
      </div>
    );
  }
}

export default injectIntl(NodeTooltip);
