import React, { FC } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import PullConflictsModal from './PullConflictsModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../atoms/button/Button';

export interface Props {
  loading?: boolean;
  loaded?: boolean;
  data?: {
    message: string;
    committed_date: string;
    committer: string;
    hexsha: string;
  }[];
  error?: string;
  repositoryPath: string;
  activeBranch: string;
  pullConflictsModal?: {
    show?: boolean;
    error?: string;
    branch?: string;
    conflicts?: string[];
  };

  gitListNotPulledCommits(...args: unknown[]): unknown;

  gitPull(...args: unknown[]): unknown;

  gitListCommits(...args: unknown[]): unknown;

  hidePullConflictsModal(...args: unknown[]): unknown;
}

const GitNotPulled: FC<Props> = ({
  data,
  repositoryPath,
  gitListNotPulledCommits,
  activeBranch,
  gitPull,
  pullConflictsModal,
  hidePullConflictsModal,
  gitListCommits,
  error,
  loading,
  loaded,
}) => {
  function renderLoaded() {
    return (
      <div className={'git-list-not-pulled-commits'}>
        <div className={'refresh-button-container'}>
          <Button
            Icon={() => <FiRefreshCw size={16} />}
            color={'transparent'}
            onClick={() =>
              gitListNotPulledCommits(repositoryPath, activeBranch, false)
            }
          />
        </div>

        <div className={'git-unpulled-status'}>
          {data && data.length > 0 ? (
            data.length > 1 ? (
              <span>There are {data.length} unpulled commits.</span>
            ) : (
              <span>There is {data.length} unpulled commit.</span>
            )
          ) : (
            <span>There are no unpulled commits.</span>
          )}
        </div>

        <div className={'git-button-bar'}>
          <Button
            color={'primary'}
            label={'Pull'}
            disabled={!data?.length}
            onClick={() => gitPull(repositoryPath, activeBranch)}
          />
        </div>
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no commits for this repository yet
      </div>
    );
  }

  function renderError() {
    return (
      <div className={'git-list-commits'}>
        {error}

        <Button
          Icon={() => <FiRefreshCw size={16} />}
          color={'transparent'}
          onClick={() => gitListCommits(repositoryPath, activeBranch, 20, 0)}
        />
      </div>
    );
  }

  function renderComponent() {
    if (loading) return renderLoading();
    if (loaded) return renderLoaded();
    if (error) return renderError();
    return renderEmpty();
  }

  return (
    <>
      {renderComponent()}

      <PullConflictsModal
        show={pullConflictsModal?.show}
        error={pullConflictsModal?.error}
        branch={pullConflictsModal?.branch}
        conflicts={pullConflictsModal?.conflicts}
        repositoryPath={repositoryPath}
        hidePullConflictsModal={hidePullConflictsModal}
        gitPull={gitPull}
      />
    </>
  );
};

export default GitNotPulled;
