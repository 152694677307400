import React, { FC, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import QuickMenuContent from './QuickMenuContent';
import styles from './styles.module.scss';

type Props = {};

const QuickMenu: FC<Props> = (props) => {
  const [isMenuShown, toggleMenu] = useState<boolean>(false);

  return (
    <div className={styles.quickMenu}>
      <div
        data-testid={'quickMenu'}
        className={styles.quickMenuIcon}
        onClick={() => toggleMenu(!isMenuShown)}
      >
        <FiPlus size={16} />
      </div>

      {isMenuShown && <QuickMenuContent closeMenu={() => toggleMenu(false)} />}
    </div>
  );
};

export default QuickMenu;
