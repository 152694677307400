import React, { FC, useMemo } from 'react';
import { PipelineTuningValueNode } from 'common/dist/types/pipeline';
import {
  convertTuningParameterOutputs,
  getStaticParamsMap,
} from './utils';
import { useTable } from 'react-table';
import { SinglePathResult } from 'common/dist/types/module.nbc';
import styles from './styles.module.scss';
import classNames from 'classnames';

type Props = {
  /** List of parameter settings for this pipeline path */
  singleResults: SinglePathResult[];
  /** List of nodes representing the path through this pipeline */
  pathNodes: PipelineTuningValueNode[];
  /** Formats the score with respect to the given tuning KPI*/
  scoreFormatter: (score: number) => string;
};

/** Holds the value for one table header column group (node) */
type TableHeaderGroup = {
  Header: string;
  columns: TableHeaderValue[];
};

/** Holds the value for one table header column value (parameter) */
type TableHeaderValue = {
  accessor: string;
  Header: string;
  parameterType: 'static' | 'tuning' | 'score';
};

/** List of values, each value describes the accessor for one column. Used to derive the data for the table */
type TableAccessor = {
  nodeId: string;
  parameterId: string;
  parameterType: 'static' | 'tuning';
};

/**
 * Takes a list of nodes representing the path through a pipeline and returns a list of TableHeaderValues
 * Whether the parameter is a static or a tuning parameter doesn't matter.
 * The resulting value is used
 * @param pathNodes
 */
function deriveTableHeaderGroups(
  pathNodes: PipelineTuningValueNode[]
): [TableHeaderGroup[], TableAccessor[]] {
  const tableHeaderGroups = [] as TableHeaderGroup[];
  const tableAccessors = [] as TableAccessor[];

  // --- Add the scores column
  tableHeaderGroups.push({
    Header: 'Score',
    columns: [
      {
        Header: '',
        accessor: 'score',
        parameterType: 'score',
      },
    ],
  });

  // --- Add the parameter columns
  pathNodes.forEach((node) => {
    const tableHeaderValues: TableHeaderValue[] = [];
    // 1. Collect the static parameters
    node.staticParameters?.forEach((staticParam) => {
      tableHeaderValues.push({
        accessor: `${node.id}__${staticParam.id}`,
        Header: staticParam.displayName,
        parameterType: 'static',
      });
      tableAccessors.push({
        nodeId: node.id,
        parameterId: staticParam.id,
        parameterType: 'static',
      });
    });

    // 2. Collect the tuning parameters
    node.tuningParameters?.forEach((tuningParam) => {
      tableHeaderValues.push({
        accessor: `${node.id}__${tuningParam.id}`,
        Header: tuningParam.displayName,
        parameterType: 'tuning',
      });
      tableAccessors.push({
        nodeId: node.id,
        parameterId: tuningParam.id,
        parameterType: 'tuning',
      });
    });

    // 3. If there is at least one parameter: Add the table header group to the table header groups
    if (tableHeaderValues.length > 0) {
      tableHeaderGroups.push({
        Header: node.displayName,
        columns: tableHeaderValues,
      });
    }
  });

  return [tableHeaderGroups, tableAccessors];
}

const SingleResultsTable: FC<Props> = (props: Props) => {
  const { singleResults, pathNodes, scoreFormatter } = props;

  const [tableHeaderGroups, tableAccessors] = useMemo(
    () => deriveTableHeaderGroups(pathNodes),
    []
  );

  // Derive a map (nodeId -> tuningParamId -> value) as part of the table values for the static parameters
  const staticParamsMap = getStaticParamsMap(pathNodes);

  // Derive a list of maps (nodeId -> tuningParamId -> value) as part of the table values for the tuning parameters
  const singleResultsTuningParamsMaps = singleResults.map((sr) => [
    convertTuningParameterOutputs(sr.parameters),
    sr.score,
  ]);

  const tableData = useMemo(
    () =>
      singleResultsTuningParamsMaps.map(([singleResultMap, score]) => {
        const values = {};
        // @ts-ignore
        values['score'] = scoreFormatter(score);

        tableAccessors.forEach((ta) => {
          if (ta.parameterType === 'static') {
            values[`${ta.nodeId}__${ta.parameterId}`] =
              staticParamsMap[ta.nodeId][ta.parameterId];
          } else if (ta.parameterType === 'tuning') {
            values[`${ta.nodeId}__${ta.parameterId}`] =
              singleResultMap[ta.nodeId][ta.parameterId];
          }
        });
        return values;
      }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: tableHeaderGroups,
      data: tableData,
    });

  return (
    <div className={classNames(styles.resultsTable, 'table-reset')}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr
              className={i === 0 ? styles.trHeaderNode : styles.trHeaderParameter}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SingleResultsTable;
