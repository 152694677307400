// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-target-function/v1/type.ts' --type 'ConfigCampaignOptimizationTargetFunctionConfig'
export const schemaConfigCampaignOptimizationTargetFunction = {
    "$ref": "#/definitions/ConfigCampaignOptimizationTargetFunctionConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<CampaignOptimizationTargetFunctionConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "type": "object"
        },
        "ConfigCampaignOptimizationTargetFunctionConfig": {
            "$ref": "#/definitions/Config%3CCampaignOptimizationTargetFunctionConfig%3E"
        }
    }
}

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-target-function/v1/type.ts' --type 'PartialCampaignOptimizationTargetFunctionAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationTargetFunction = {
    "$ref": "#/definitions/PartialCampaignOptimizationTargetFunctionAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "PartialCampaignOptimizationTargetFunctionAugurSettings": {
            "additionalProperties": false,
            "properties": {
                "minimizeOrMaximize": {
                    "enum": [
                        "minimize",
                        "maximize"
                    ],
                    "type": "string"
                },
                "summandGroups": {
                    "items": {
                        "$ref": "#/definitions/TargetFunctionSummandGroup"
                    },
                    "type": "array"
                }
            },
            "type": "object"
        },
        "TargetFunctionSummandGroup": {
            "additionalProperties": false,
            "properties": {
                "description": {
                    "type": "string"
                },
                "factor": {
                    "type": "string"
                },
                "id": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "sumOf": {
                    "enum": [
                        "costs",
                        "values"
                    ],
                    "type": "string"
                },
                "sumOver": {
                    "const": "allCommunications",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "sumOver",
                "sumOf",
                "factor"
            ],
            "type": "object"
        }
    }
}