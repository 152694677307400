export const BROWSER_TABS = {
  FILE_BROWSER: 'file-browser',
  RECYCLE_BIN: 'recycle-bin',
} as const;
export type BrowserTabs = (typeof BROWSER_TABS)[keyof typeof BROWSER_TABS];

export const WORKBENCH_TABS = {
  VERSION_CONTROL: 'version-control',
  GENERAL_CONFIG: 'general-config',
  DEV_AUGUR: 'dev-augur',
  TERMINAL: 'terminal',
  SWAGGER: 'swagger',
  ...BROWSER_TABS,
} as const;
export type WorkbenchTabs =
  (typeof WORKBENCH_TABS)[keyof typeof WORKBENCH_TABS];

export const workbenchRoutes = {
  basePath: '/app/workbench',
  createNotebook: '/create-notebook',
  pasteNotebook: '/paste-notebook',
  editNotebook: '/edit-notebook',
  warningOpenFile: '/warning-open-file',
  addFile: '/add-file',
  addDirectory: '/add-directory',
  editDirectory: '/edit-directory',
  fileBrowser: `/${WORKBENCH_TABS.FILE_BROWSER}`,
  recycleBin: `/${WORKBENCH_TABS.RECYCLE_BIN}`,
  versionControl: `/${WORKBENCH_TABS.VERSION_CONTROL}`,
  devAugur: `/${WORKBENCH_TABS.DEV_AUGUR}`,
  generalConfig: `/${WORKBENCH_TABS.GENERAL_CONFIG}`,
  terminal: `/${WORKBENCH_TABS.TERMINAL}`,
  swagger: `/${WORKBENCH_TABS.SWAGGER}`,
  repositoryFork: '/repository-fork',
  deleteContent: '/delete-content',
  emptyRecycleBin: '/empty-recycle-bin',
  vsCode: '/vscode',
  resourceManagement: '/resource-management',
  removeModuleArtifacts: '/remove-module-artifacts',
};
