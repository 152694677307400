import React, { FC, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import Category from './Category';
import styles from './styles.module.scss';
import { AugurCategory, AugurMenuCategory, AugurMenuEntry } from './types';

export type Props = {
  /** List of entries for the menu */
  categories: AugurMenuCategory[];
  selectedTab: string;
  onChangeCategories?: (categories: AugurMenuCategory[]) => void;
  onSelectEntry?: (
    entryId: string,
    category: AugurCategory,
    query?: string
  ) => void;

  /** Is the sidebar read-only or in edit mode? */
  isEditMode?: boolean;
  isLoading?: boolean;
};

const AugurMenu: FC<Props> = (props) => {
  const {
    categories,
    selectedTab,
    onChangeCategories,
    onSelectEntry,
    isEditMode,
    isLoading,
  } = props;

  const [editingEntry, setEditingEntry] = useState<null | string>(null);

  /** Example: { "evaluation": 0, "learning": 2, ... }*/
  const startIndices = (categories || [])
    .map((category, i) => ({
      startIndex: categories
        .slice(0, i)
        .reduce((j, cat) => (cat.entries || []).length + j, 0),
      categoryId: category.id,
    }))
    .reduce((prev, el) => {
      prev[el.categoryId] = el.startIndex;
      return prev;
    }, {});

  const addEntry = (index: number, categoryId: AugurCategory) => {
    const category = categories.find((c) => c.id === categoryId);

    if (!category) {
      console.warn(`Add Entry: Category ${categoryId} not found`);
      return;
    }

    const id = uuidv4(); // Leads to ugly links, but might be the easiest way to handle the "id" and "to" generation
    const newEntry: AugurMenuEntry = {
      id: id,
      name: 'New Entry',
      iconId: 'selectionPlus',
    };

    const newEntries = [
      ...category.entries.slice(0, index + 1),
      newEntry,
      ...category.entries.slice(index + 1),
    ];

    const newCategories = categories.map((c) => {
      if (c.id === categoryId) {
        return {
          ...category,
          entries: newEntries,
        };
      } else {
        return c;
      }
    });
    onChangeCategories?.(newCategories);
  };

  const deleteEntry = (entryId: string, categoryId: string) => {
    const category = categories.find((c) => c.id === categoryId);

    if (!category) {
      console.warn(`Add Entry: Category ${categoryId} not found`);
      return;
    }

    const newCategories = categories.map((c) => {
      if (c.id === categoryId) {
        return {
          ...category,
          entries: (category.entries || []).filter((e) => e.id !== entryId),
        };
      } else {
        return c;
      }
    });
    onChangeCategories?.(newCategories);
  };

  const renameEntry = (
    entryId: string,
    categoryId: string,
    newName: string
  ) => {
    const category = categories.find((c) => c.id === categoryId);

    if (!category) {
      console.warn(`Add Entry: Category ${categoryId} not found`);
      return;
    }

    const newCategories = categories.map((c) => {
      if (c.id === categoryId) {
        return {
          ...category,
          entries: (category.entries || []).map((e) =>
            e.id === entryId ? { ...e, name: newName } : e
          ),
        };
      } else {
        return c;
      }
    });
    onChangeCategories?.(newCategories);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const entryId = result.draggableId;
    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;
    const destinationIndex = destination.index; // This is the global index, not the category-local one. Means: The startIndex is already added here.
    const insertIndex = destinationIndex - startIndices[destinationCategoryId];

    const sourceCategory = categories.find((c) => c.id === sourceCategoryId);
    const destinationCategory = categories.find(
      (c) => c.id === destinationCategoryId
    );

    if (!sourceCategory || !destinationCategory) return;
    const entry = (sourceCategory.entries || []).find((e) => e.id === entryId);

    // Filter out the element in the source, then add the entry in the destination
    const newCategories = categories
      .map((c) =>
        c.id === sourceCategoryId
          ? {
              ...c,
              entries: c.entries.filter((e) => e.id !== entryId),
            }
          : c
      )
      .map((c) =>
        c.id === destinationCategoryId
          ? {
              ...c,
              entries: [
                ...c.entries.slice(0, insertIndex),
                entry,
                ...c.entries.slice(insertIndex),
              ],
            }
          : c
      );

    onChangeCategories?.(newCategories);
  };

  const renderEditMode = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.sidemenu}>
          {(categories || []).map((category) => (
            <Category
              key={category.id}
              category={category}
              isEditMode
              selectedEntry={selectedTab}
              handleSelect={onSelectEntry}
              addEntry={addEntry}
              deleteEntry={deleteEntry}
              startIndex={startIndices[category.id]}
              editingEntry={editingEntry}
              setEditingEntry={setEditingEntry}
              renameEntry={renameEntry}
            />
          ))}
        </div>
      </DragDropContext>
    );
  };

  const renderStaticMode = () => {
    return (
      <div className={styles.sidemenu}>
        {(categories || []).map((category) => {
          if (category.entries.length > 0)
            return (
              <Category
                key={category.id}
                category={category}
                selectedEntry={selectedTab}
                handleSelect={onSelectEntry}
                addEntry={addEntry}
                deleteEntry={deleteEntry}
                startIndex={startIndices[category.id]}
                editingEntry={editingEntry}
                setEditingEntry={setEditingEntry}
                renameEntry={renameEntry}
                isLoading={isLoading}
              />
            );

          return;
        })}
      </div>
    );
  };
  if (isLoading || !isEditMode) return renderStaticMode();
  else return renderEditMode();
};

export default AugurMenu;
