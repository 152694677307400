import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import CodeCapsuleDetails from '../../codeCapsuleDetails/CodeCapsuleDetails.container';
import * as routes from '../../index/routes';
import LiveAugur from '../augur/LiveAugur';
import NoPermissions from '../no-permissions/NoPermissions';
import NotFound from '../not-found/NotFound.container';

type Props = {
  match: {
    params: {
      habitatCode: string;
    };
  };
  /** true if the permissions for the Model Management are missing */
  missingPermissionsError: boolean;
};

type State = {
  loaded: boolean;
};

/**
 * This class contains the routes for the Model Management that don't use the top bar with the Main Tabs (for example
 * the Augur Details, which provide their own top bar).
 */
class ModelManagementNoTopBar extends Component<Props, State> {
  render() {
    const { missingPermissionsError } = this.props;

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight={true} />;
    }

    return (
      <Switch>
        {/* Augur Details */}
        <Route
          path={'/app/habitat/:habitatCode/augur/:augurCode'}
          component={LiveAugur}
        />

        {/* Code Capsule Details */}
        <Route
          path={routes.app.prefix + routes.app.codeCapsule.prefix}
          component={CodeCapsuleDetails}
        />

        {/* Fallback: 404 */}
        <Route>
          <NotFound fullViewHeight={true} />
        </Route>
      </Switch>
    );
  }
}

export default ModelManagementNoTopBar;
