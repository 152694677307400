import { connect } from 'react-redux';
import Step1 from './Step1';
import { fieldName, formName } from '../notebook.form';
import { getFormInitialValues } from 'redux-form';

// TODO the next 3 selectors are not how redux-form is supposed to be used. Use formValueSelector() instead!
function safeNotebookName(state) {
  if (!state.form.notebookWizard) return '';
  if (!state.form.notebookWizard.values) return '';
  return state.form.notebookWizard.values.notebookName;
}

function safeNotebookTouched(state) {
  if (!state.form.notebookWizard) return false;
  if (!state.form.notebookWizard.fields) return false;
  if (!state.form.notebookWizard.fields.notebookName) return false;
  return state.form.notebookWizard.fields.notebookName.touched;
}

function safeNotebookError(state) {
  if (!state.form.notebookWizard) return undefined;
  if (!state.form.notebookWizard.syncErrors) return undefined;
  if (!state.form.notebookWizard.syncErrors.notebookName) return undefined;
  return state.form.notebookWizard.syncErrors.notebookName.message;
}

export function mapStateToProps(state) {
  return {
    notebookName: safeNotebookName(state),
    notebookNameTouched: safeNotebookTouched(state),
    notebookNameError: safeNotebookError(state),
    isEditing: state.workbench.showEditNotebook?.path !== undefined,
    initialName: getFormInitialValues(formName)(state)?.[fieldName],
  };
}

export default connect(mapStateToProps)(Step1);
