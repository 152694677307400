import { Point } from 'common/dist/types/module.charts';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';

import Bar from './Bar';
import vars from '../../../../../../../../scss/base/var.module.scss';
import { getStringWidth } from '../../../../../../../utils';

type Props = {
  data?: Point[];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  position: number;
  colorScale: ScaleLinear<string, string>;
  jobCode: string;
};

const Bars: FC<Props> = (props) => {
  const { data, xScale, yScale, position, colorScale, jobCode } = props;

  const barHeight = yScale.range()[1] / yScale.domain()[0];
  const noDataBarHeight = barHeight - 8;
  const barRightEnd = xScale.range()[1];
  const substring = Math.floor(barHeight / 10);
  const isCut = substring * 2 < jobCode.length;

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <g>
      <g
        cursor='pointer'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {barHeight <= 20 && (
          <text
            fontSize={13}
            lengthAdjust='spacingAndGlyphs'
            fill={vars.colorNoDataText}
            x={barRightEnd + 8}
            y={yScale(position + 1) + 15}
            transform={`rotate(0 ${barRightEnd + 10} ${
              yScale(position + 1) + 8
            })`}
          >
            •••
          </text>
        )}
        {barHeight > 20 && (
          <g>
            <text
              fontSize={13}
              lengthAdjust='spacingAndGlyphs'
              fill={vars.colorNoDataText}
              x={barRightEnd + 8}
              y={yScale(position + 1) - 3}
              transform={`rotate(90 ${barRightEnd + 10} ${
                yScale(position + 1) + 8
              })`}
            >
              {jobCode.substring(0, substring)}
            </text>
            <text
              fontSize={13}
              fill={vars.colorNoDataText}
              x={barRightEnd + 7}
              y={yScale(position + 1) + 12}
              transform={`rotate(90 ${barRightEnd + 10} ${
                yScale(position + 1) + 8
              })`}
            >
              {jobCode.substring(substring, substring * 2 - (isCut ? 2 : 0)) +
                (isCut ? '•••' : '')}
            </text>
          </g>
        )}

        {showTooltip && (
          <>
            <rect
              rx={2}
              ry={2}
              fill='#444'
              width={getStringWidth(jobCode)}
              height={20}
              x={barRightEnd - getStringWidth(jobCode)}
              y={yScale.length - 22}
              transform={`rotate(0 ${barRightEnd + 10} ${
                yScale(position + 1) + 8
              })`}
            ></rect>
            <text
              fontSize={13}
              lengthAdjust='spacingAndGlyphs'
              fill='#eee'
              x={barRightEnd - getStringWidth(jobCode) + 5}
              y={yScale.length - 8}
            >
              {jobCode.substring(0)}
            </text>
          </>
        )}
      </g>
      {!data && (
        <>
          <rect
            rx={2}
            ry={2}
            height={noDataBarHeight}
            y={yScale(position) - barHeight + 6}
            width={barRightEnd}
            x={xScale.range()[0]}
            fill={vars.colorNoDataFill}
            stroke={vars.colorNoDataStroke}
            strokeWidth={1}
          ></rect>
          {/* Calculate No Data text based on the bar size */}
          {!(noDataBarHeight / 2 < 10) && !(barRightEnd < 100) && (
            <text
              fontSize={noDataBarHeight > 30 ? 14 : noDataBarHeight / 2}
              fill={vars.colorNoDataText}
              x={barRightEnd / 2 - 20}
              y={yScale(position + 0.5) + 7}
            >
              No Data
            </text>
          )}
        </>
      )}
      {data &&
        data.map((item) => (
          <Bar
            key={`${item[0]}_${item[1]}`}
            xScale={xScale}
            yScale={yScale}
            item={item}
            position={position}
            colorScale={colorScale}
          />
        ))}
    </g>
  );
};

export default Bars;
