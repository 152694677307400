import Ajv from 'ajv';
import { Layout } from 'react-grid-layout';
import {
  UseFormRegister,
  UseFormUnregister,
  ValidateResult,
} from 'react-hook-form';

import { CFEConfigProperties, hasCycle, isCFE } from './cfe';
import { transformConfigToConfigProps } from './transformation';
import {
  ElementVersions,
  findElementMeta,
} from '../../../molecules/augur-layout-elements/common/utils';
import { ReportElementTypes } from '../../../molecules/augur-layout-elements/report-elements/types/type';
import { DropdownSelectCFEConfig } from '../../../molecules/augur-layout-elements/settings-elements/dropdown-select-cfe/v1/type';
import { AugurSettingsElementMeta } from '../../../molecules/augur-layout-elements/settings-elements/types/meta';
import { SettingsElementTypes } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import { AugurMenuIcons } from '../../../molecules/augur-menu/icons';
import { AugurCategory } from '../../../molecules/augur-menu/types';
import {
  AugurReportElement,
  AugurReportPage,
  AugurSettingsElement,
  AugurSettingsPage,
  Config,
  isAugurReportsElement,
  isAugurReportsType,
  isAugurSettingsElement,
  isAugurSettingsType,
  LayoutElement,
  ModuleConfiguration,
} from '../type';

export const ajv = new Ajv({
  allErrors: true,
});

/**
 * Form type for the Dev Augur edit mode.
 */
export type ConfigForm = {
  pages: Record<string, ConfigPageForm>; // used by AugurMenu
  arrangements: Record<string, Layout[]>; // used by LayoutEditor
  elements: Record<string, ConfigPageElementForm>; // used by ConfigurationPage
};

/**
 * Form type for a single page in the Dev Augur.
 * Includes title & description form the AugurMenu, arrangement from the AugurLayoutEditor, and an array for the layout elements of this page.
 */
export type ConfigPageForm = {
  uuid: string; // not editable
  title: string;
  iconId: keyof typeof AugurMenuIcons;
  menuCategory: AugurCategory;
};

/**
 * Form type for the configuration sidebar.
 * Used for both report and settings elements.
 */
export type ConfigPageElementForm = {
  uuid: string; // not editable
  pageId: string; // not editable
  type: ReportElementTypes | SettingsElementTypes; // currently not editable but set on creation
  version: ElementVersions; // see type
  title: string;
  description: string;
  config: string;
  reportKey?: string;
  settingsKey?: string;
  defaultAugurSettings?: string;
};

/**
 * Transforms a LayoutElement to a ConfigPageElementForm object.
 * Also inserts defaults from the associated ElementMeta where values are empty.
 */
export function toFormStateElement(
  element: LayoutElement,
  pageId: string
): ConfigPageElementForm {
  const elementMeta = findElementMeta(element.type, element.version);

  return {
    uuid: element.uuid,
    pageId,
    type: element.type,
    version: element.version,
    title: element.title,
    description: element.description,
    config: JSON.stringify(
      element.config == null ? elementMeta.defaultConfig : element.config,
      null,
      2
    ),
    ...(isAugurReportsElement(element)
      ? {
          reportKey: element.reportKey,
        }
      : {}),
    ...(isAugurSettingsElement(element)
      ? {
          settingsKey: element.settingsKey,
          defaultAugurSettings: JSON.stringify(
            element.defaultAugurSettings == null
              ? (elementMeta as AugurSettingsElementMeta)
                  .defaultAugurSettingsDefault
              : element.defaultAugurSettings,
            null,
            2
          ),
        }
      : {}),
  };
}

export function toConfigElement(
  element: ConfigPageElementForm
): AugurReportElement | AugurSettingsElement {
  return {
    uuid: element.uuid,
    type: element.type,
    version: element.version,
    title: element.title,
    description: element.description,
    config: JSON.parse(element.config),
    ...(isAugurReportsType(element.type)
      ? {
          reportKey: element.reportKey,
        }
      : {}),
    ...(isAugurSettingsType(element.type)
      ? {
          settingsKey: element.settingsKey,
          defaultAugurSettings: JSON.parse(element.defaultAugurSettings),
        }
      : {}),
  } as AugurReportElement | AugurSettingsElement;
}

/**
 * Transforms a ModuleConfiguration object to a config form state record.
 * Also injects default values for certain fields if no value was specified.
 */
export function configToFormState(config: ModuleConfiguration): ConfigForm {
  const configPageToFormData = (
    page: AugurReportPage | AugurSettingsPage,
    category: AugurCategory
  ) => {
    return {
      uuid: page.uuid,
      title: page.title,
      iconId: page.iconId,
      menuCategory: category,
      arrangement: page.elementArrangement.map((layout) => {
        const element = page.elements.find(
          (element) => element.uuid === layout.i
        );
        const meta = findElementMeta(element.type, element.version);
        const { minH, minW, maxH, maxW } = meta.tileSizeRestrictions;
        return {
          ...layout,
          minW,
          maxW,
          minH,
          maxH,
        };
      }),
      elements: page.elements.reduce(
        (acc, element) => ({
          ...acc,
          [element.uuid]: toFormStateElement(element, page.uuid),
        }),
        {} as Record<string, ConfigPageElementForm>
      ),
    };
  };

  const formData = [
    ...config.augurReportConfiguration.learning.map((page) =>
      configPageToFormData(page, 'learning')
    ),
    ...config.augurReportConfiguration.evaluation.map((page) =>
      configPageToFormData(page, 'evaluation')
    ),
    ...config.augurReportConfiguration.prediction.map((page) =>
      configPageToFormData(page, 'prediction')
    ),
    ...config.augurSettingsConfiguration.map((page) =>
      configPageToFormData(page, 'settings')
    ),
  ];

  return formData.reduce(
    (
      { pages, elements, arrangements },
      { arrangement, elements: pageElements, ...page }
    ) => {
      return {
        pages: {
          ...pages,
          [page.uuid]: page,
        },
        arrangements: {
          ...arrangements,
          [page.uuid]: arrangement,
        },
        elements: {
          ...elements,
          ...pageElements,
        },
      };
    },
    { pages: {}, elements: {}, arrangements: {} } as ConfigForm
  );
}

/**
 * Transforms a config form state to a ModuleConfiguration object.
 */
export function formStateToConfig(configForm: ConfigForm): ModuleConfiguration {
  return Object.values(configForm.pages).reduce(
    (acc, pageForm) => {
      const page = {
        uuid: pageForm.uuid,
        title: pageForm.title,
        iconId: pageForm.iconId,
        elements: Object.values(configForm.elements)
          .filter((element) => element.pageId === pageForm.uuid)
          .map(toConfigElement),
        elementArrangement: configForm.arrangements[pageForm.uuid] ?? [],
      };

      switch (pageForm.menuCategory) {
        case 'learning':
          return {
            ...acc,
            augurReportConfiguration: {
              ...acc.augurReportConfiguration,
              learning: [
                ...acc.augurReportConfiguration.learning,
                page as AugurReportPage,
              ],
            },
          };
        case 'evaluation':
          return {
            ...acc,
            augurReportConfiguration: {
              ...acc.augurReportConfiguration,
              evaluation: [
                ...acc.augurReportConfiguration.evaluation,
                page as AugurReportPage,
              ],
            },
          };
        case 'prediction':
          return {
            ...acc,
            augurReportConfiguration: {
              ...acc.augurReportConfiguration,
              prediction: [
                ...acc.augurReportConfiguration.prediction,
                page as AugurReportPage,
              ],
            },
          };
        case 'settings':
          return {
            ...acc,
            augurSettingsConfiguration: [
              ...acc.augurSettingsConfiguration,
              page as AugurSettingsPage,
            ],
          };
      }
    },
    {
      apiVersion: 'v1',
      augurReportConfiguration: {
        learning: [],
        evaluation: [],
        prediction: [],
      },
      augurSettingsConfiguration: [],
    } as ModuleConfiguration
  );
}

const keyRegex = /[A-Za-z]+/;

export function isValidJson(jsonString: string): ValidateResult {
  try {
    void JSON.parse(jsonString);
    return true;
  } catch (error) {
    if (typeof error === 'string') {
      return error;
    } else if (error instanceof Error) {
      return error.message;
    }
    return 'An unknown error occurred while trying to validate JSON string.';
  }
}

function isValidJsonSchema(schemaString: string): ValidateResult {
  try {
    const schema = JSON.parse(schemaString);
    void ajv.validateSchema(schema);
    return true;
  } catch (error) {
    if (typeof error === 'string') {
      return error;
    } else if (error instanceof Error) {
      return error.message;
    }
    return 'An unknown error occurred while trying to validate JsonSchema.';
  }
}

function isSettingsKeyDuplicateForDifferentTypes(
  formValues: ConfigForm,
  settingsKey: string,
  currentElementType: SettingsElementTypes
) {
  const dependencies = Object.values(formValues.elements).filter(
    (element) => element.settingsKey === settingsKey
  );

  if (dependencies.length > 1) {
    // Check for duplicate settings keys across different element types.
    // Duplicate settings keys are allowed for multiple elements of the same type.
    return dependencies.some((element) => element.type !== currentElementType);
  }

  return false;
}

export function registerElementFields(
  register: UseFormRegister<ConfigForm>,
  element: ConfigPageElementForm
) {
  const elementMeta = findElementMeta(element.type, element.version);
  register(`elements.${element.uuid}.uuid`);
  register(`elements.${element.uuid}.type`);
  register(`elements.${element.uuid}.version`);
  register(`elements.${element.uuid}.title`, {
    maxLength: { value: 64, message: 'Max length 64' },
  });
  register(`elements.${element.uuid}.description`, {
    maxLength: { value: 255, message: 'Max length 255' },
  });
  register(`elements.${element.uuid}.config`, {
    validate: (value, formValues) => {
      // Basic json validation
      const isValidJsonResult = isValidJson(value);
      if (isValidJsonResult !== true) {
        return isValidJsonResult;
      }
      // Jsonschema validation
      const parsedValue: Config<Record<string, unknown>> = JSON.parse(value);
      const validation = elementMeta.validateConfig;
      if (!validation(parsedValue)) {
        return ajv.errorsText(validation.errors);
      }

      if (isAugurSettingsElement(element) && isCFE(element)) {
        const config: DropdownSelectCFEConfig = transformConfigToConfigProps(
          parsedValue as Config<CFEConfigProperties>
        );

        // make sure that all dependants exist
        const unknownDependants = [];
        config.options.map((option) => {
          return option.dependants.map((dependant) => {
            if (!formValues.elements[dependant]) {
              unknownDependants.push(dependant);
            }
          });
        });
        if (unknownDependants.length > 0) {
          return `Unknown CFE dependencies: ${unknownDependants.join(', ')}`;
        }

        // prefill elements map for faster access inside cycle detection algorithm
        const mappedElements = Object.fromEntries(
          Object.values(formValues.elements)
            .map((element) => {
              try {
                return toConfigElement(element);
              } catch (e) {
                // element is invalid, e.g. due to invalid config --> ignore element
                return undefined;
              }
            })
            // we filter the invalid elements which results in an error being thrown if the cycle detection tries to access this element
            .filter((element) => !!element)
            .map((element) => [element.uuid, element])
        );

        // check for cycles
        if (hasCycle(mappedElements, element.uuid)) {
          return 'Invalid CFE dependency tree: Cycle Detection Error!';
        }
      }

      // Dependency validation: This is primarily for settings elements, but since reports should not have source: input-element it also works and validates that as well
      const inputValidateResults: [string, ValidateResult][] = Object.entries(
        parsedValue
      ).map(([configKey, configEntry]) => {
        if (configEntry.source !== 'input-element') {
          return [configKey, true];
        }
        if (!isAugurSettingsElement(element)) {
          return [configKey, 'Only settings elements can have dependencies.'];
        }
        // Check uuid
        const dependency = formValues.elements[configEntry.elementUuid];
        if (!dependency) return [configKey, 'Invalid uuid'];
        // Check if the uuid belongs to an allowed element
        const allowedInputsForConfigKey =
          (elementMeta as AugurSettingsElementMeta).configAllowedInputs[
            configKey
          ] || [];
        if (
          !allowedInputsForConfigKey.find(
            (si) =>
              si.type === dependency.type && si.version === dependency.version
          )
        ) {
          return [
            configKey,
            `Invalid dependency. Allowed types: [${allowedInputsForConfigKey
              .map((a) => a.type)
              .join(',')}]`,
          ];
        }
        return [configKey, true];
      });
      // Filter the ValidateResults to get the actual errors. (rhf: not true => error)
      const inputErrors = inputValidateResults.filter(
        ([key, res]) => res !== true
      );
      if (inputErrors.length > 0) {
        return `Dependency problem(s): ${inputErrors
          .map(([key, res]) => `${key}: ${String(res)}`)
          .join(',')}`;
      }

      return true;
    },
  });
  if (isAugurSettingsElement(element)) {
    register(`elements.${element.uuid}.settingsKey`, {
      required: { value: true, message: `Key can't be missing` },
      maxLength: { value: 64, message: 'Max length 64' },
      pattern: {
        value: keyRegex,
        message: 'Key can only contain A-Za-z',
      },
      validate: (value, formValues) => {
        if (
          isSettingsKeyDuplicateForDifferentTypes(
            formValues,
            value,
            element.type
          )
        )
          return `Duplicate keys for different types.`;
      },
    });
    register(`elements.${element.uuid}.defaultAugurSettings`, {
      validate: (value) => {
        // Basic json validation
        const isValidJsonResult = isValidJson(value);
        if (isValidJsonResult !== true) {
          return isValidJsonResult;
        }
        // Jsonschema validation
        const parsedValue: Config<Record<string, unknown>> = JSON.parse(value);
        const validation = (elementMeta as AugurSettingsElementMeta)
          .validateAugurSettingsDefault;
        if (!validation(parsedValue)) {
          return ajv.errorsText(validation.errors);
        }
        return true;
      },
    });
  } else {
    register(`elements.${element.uuid}.reportKey`, {
      required: { value: true, message: `Key can't be missing` },
      maxLength: { value: 64, message: 'Max length 64' },
      pattern: {
        value: keyRegex,
        message: 'Key can only contain A-Za-z',
      },
    });
  }
}

export function unregisterElementFields(
  unregister: UseFormUnregister<ConfigForm>,
  element: ConfigPageElementForm
) {
  unregister(`elements.${element.uuid}.uuid`);
  unregister(`elements.${element.uuid}.type`);
  unregister(`elements.${element.uuid}.version`);
  unregister(`elements.${element.uuid}.title`);
  unregister(`elements.${element.uuid}.description`);
  unregister(`elements.${element.uuid}.config`);
  if (isAugurSettingsElement(element)) {
    unregister(`elements.${element.uuid}.settingsKey`);
    unregister(`elements.${element.uuid}.defaultAugurSettings`);
  } else {
    unregister(`elements.${element.uuid}.reportKey`);
  }
}

export function registerPageFields(
  register: UseFormRegister<ConfigForm>,
  page: ConfigPageForm
) {
  register(`pages.${page.uuid}.uuid`);
  register(`pages.${page.uuid}.title`, {
    required: { value: true, message: 'Page title is required' },
    minLength: { value: 3, message: 'Min length 3' },
    maxLength: { value: 64, message: 'Max length 64' },
  });
  register(`pages.${page.uuid}.iconId`);
  register(`pages.${page.uuid}.menuCategory`);
}

export function unregisterPageFields(
  unregister: UseFormUnregister<ConfigForm>,
  page: ConfigPageForm
) {
  unregister(`pages.${page.uuid}.uuid`);
  unregister(`pages.${page.uuid}.title`);
  unregister(`pages.${page.uuid}.iconId`);
  unregister(`pages.${page.uuid}.menuCategory`);
}
