import React, { FC } from 'react';
import { FiBook, FiGitBranch } from 'react-icons/fi';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import Branches from './branches/Branches.container';
import RepositoryOverview from './repositoryOverview/RepositoryOverview.container';
import styles from './styles.module.scss';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import { collaborationSpaceRoutes } from '../routes';

function getActiveCategory(path: string) {
  if (!path) return null;
  const rep = path.replace('/app/collab/', '');
  const parts = rep.split('/');
  // We're in the repository details - drop the first 3 elements,
  // since they're the repository base name, group and name
  if (parts.length > 2) return parts[2];
  // This is fine and will be treated by the routing with a proper redirect to the repository overview
  else return undefined;
}

interface RepositoryDetailsProps {}

const RepositoryDetails: FC<RepositoryDetailsProps> = () => {
  const history = useHistory();
  const { path, url, params } = useRouteMatch<{
    group: string;
    repositoryName: string;
  }>();

  const selectedCategory = getActiveCategory(history.location.pathname);

  const repositoryDetailsMenuEntries: MenuEntryType[] = [
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Repository',
      },
    },
    {
      type: 'link',
      id: 'overview',
      activePerDefault: true,
      to: `${url}/overview`,
      nameIntlId: 'dashboard.tab.collab.repositories.overview',
      nameDefault: 'Overview',
      icon: FiBook,
    },
    {
      type: 'link',
      id: 'branches',
      to: `${url}/branches`,
      nameIntlId: 'dashboard.tab.collab.repositories.branches',
      nameDefault: 'Branches',
      icon: FiGitBranch,
    },
  ];

  return (
    <div className={styles.repositoryDetails}>
      <WithSidemenu
        menuEntries={repositoryDetailsMenuEntries}
        selectedTab={selectedCategory}
        handleSelect={() => {}}
      >
        <Switch>
          {/*/:group/:repositoryName/branches*/}
          <Route
            path={`${path}/${collaborationSpaceRoutes.repositories.branches}`}
          >
            <Branches />
          </Route>

          {/* Repository Details */}
          <Route path={`${path}`}>
            <RepositoryOverview />
          </Route>
        </Switch>
      </WithSidemenu>
    </div>
  );
};

export default RepositoryDetails;
