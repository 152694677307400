import React, { FC } from 'react';
import styles from './styles.module.scss';
import SingleResultsPerPipeline from './SingleResultsPerPipeline';
import { mapSingleResultsToPipelineAndPaths } from './utils';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import { PipelineResultType } from 'common/dist/types/pipeline';
import { ReportElementProps } from '../../../types/meta';
import { PipelineTuningSingleResultsConfig, PipelineTuningSingleResultsReportData } from '../type';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';


export type Props = PipelineTuningSingleResultsReportData & PipelineTuningSingleResultsConfig;


const PipelineTuningSingleResults: FC<Props> = (props: Props) => {
  const { pipelines, singleResults, kpi } = props;

  if (!singleResults) {
    // Only show the component if the pipelineTuningResults are actually there
    return <div className={styles.pipelineTuningResultsSingleResults} />;
  }

  const singleResultsMap = mapSingleResultsToPipelineAndPaths(singleResults);
  const scoreFormatter = kpiOptionsGrouped[kpi]?.format;

  return (
    <div className={styles.pipelineTuningResultsSingleResults}>
      {pipelines.map(
        (pipelineResult: PipelineResultType) => (
          <SingleResultsPerPipeline
            pipelineResult={pipelineResult}
            singleResultsMap={singleResultsMap[pipelineResult.id]}
            scoreFormatter={scoreFormatter}
          />
        )
      )}
    </div>
  );
}

export const PipelineTuningSingleResultsSingle: FC<
  ReportElementProps<PipelineTuningSingleResultsReportData, PipelineTuningSingleResultsConfig>
> = buildSingleReportWrapper<PipelineTuningSingleResultsReportData, PipelineTuningSingleResultsConfig>(PipelineTuningSingleResults);

export default PipelineTuningSingleResults;