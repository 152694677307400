import { connect } from 'react-redux';

import Habitat, { HABITAT_TAB } from './Habitat';
import * as habitatSelector from '../../../redux/selectors/habitat.selector';
import { RootState } from '../../../store/store';
import {
  setActiveTab,
  setHabitatView,
} from '../../../redux/modules/dashboard.module';
import { fetchCodeCapsules } from '../../../redux/modules/codeCapsules.module';
import { setOrder } from '../../../redux/modules/augurs.module';

type OwnProps = {
  habitat: {
    code: string;
  };
};

export function mapStateToProps(
  state: RootState,
  { habitat: { code } }: OwnProps
) {
  const hasNoAugurs = habitatSelector.hasNoAugurs(state, code);
  const hasNoCodeCapsules = habitatSelector.hasNoCodeCapsules(state, code);

  // Decide for the active tab
  const activeTabState = state.dashboard.habitats[code].activeTab;
  let activeTab: string = undefined;
  if (activeTabState) {
    // -- The user selected a tab: Use this as the active tab
    activeTab = activeTabState;
  } else {
    // -- The user hasn't selected an active tab yet
    if (hasNoAugurs && !hasNoCodeCapsules) {
      activeTab = HABITAT_TAB.CODE_CAPSULES;
    } else {
      activeTab = HABITAT_TAB.AUGURS;
    }
  }

  return {
    isListView: state.dashboard.habitats[code].isListView,
    activeTab,
    hasNoAugurs,
    codeCapsules: state.codeCapsules.perHabitatCode[code],
    order: state.dashboard.habitats[code].order,
  };
}

export const mapDispatchToProps = {
  setActiveTab,
  fetchCodeCapsules,
  setHabitatView,
  setOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Habitat);
