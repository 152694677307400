import { AvailableAlgorithm } from 'common/dist/types/module.nbc';
import React, { FC } from 'react';

import ParameterTuningParameter from './ParameterTuningParameter';
import styles from './styles.module.scss';
import {
  ParameterTuningAugurSettings,
  ParameterTuningValidationError,
} from './type';
import { Checkbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';

export interface Props {
  availableAlgorithm: AvailableAlgorithm;
  value?: ParameterTuningAugurSettings['algorithms'][number];
  onChange: (val?: ParameterTuningAugurSettings['algorithms'][number]) => void;
  isTouched: boolean;
  error?: ParameterTuningValidationError;
  readOnly?: boolean;
}

const ParameterTuningAlgorithm: FC<Props> = ({
  availableAlgorithm,
  value,
  onChange,
  isTouched,
  error,
  readOnly,
}) => {
  const selected = !!value;

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.checkbox}>
          <Checkbox
            disabled={readOnly}
            checked={selected}
            onChange={() => {
              if (selected) {
                onChange(undefined);
              } else {
                const defaultParameterValues =
                  availableAlgorithm.parameters.map((parameter) => ({
                    name: parameter.name,
                    values: parameter.default,
                  }));

                onChange({
                  name: availableAlgorithm.name,
                  parameters: defaultParameterValues,
                });
              }
            }}
          />
        </div>
        <div>
          <h3 className={styles.headline}>{availableAlgorithm.speakingName}</h3>
          <p>{availableAlgorithm.description}</p>
        </div>
      </div>

      <div className={styles.element}>
        {selected && (
          <div className={styles.grid}>
            {availableAlgorithm.parameters.map((parameter) => {
              const parameterValue = value.parameters.find(
                (param) => parameter.name === param.name
              );
              return (
                <ParameterTuningParameter
                  error={error}
                  key={`${availableAlgorithm.name}/${parameter.name}`}
                  algorithmName={availableAlgorithm.name}
                  parameter={parameter}
                  value={
                    parameterValue || {
                      name: parameter.name,
                      values: parameter.default,
                    }
                  }
                  isTouched={isTouched}
                  onChange={(parameterValue) => {
                    const filteredParameters = value.parameters.filter(
                      (param) => parameter.name !== param.name
                    );
                    const parameters = [...filteredParameters, parameterValue];

                    onChange({
                      name: availableAlgorithm.name,
                      parameters,
                    });
                  }}
                  readOnly={readOnly}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParameterTuningAlgorithm;
