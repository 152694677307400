import { PipelineTuningBestResultV1ReportData as PipelineTuningBestResultReportData } from 'common/dist/types/reportData/pipelineTuningBestResultV1ReportData';
export type { PipelineTuningBestResultReportData };
import { Config } from '../../../../../pages/augur/type';
import { example1 } from '../../pipeline-tuning-single-results/v1/single/stories/example1';

export type PipelineTuningBestResultConfig = {};

export type ConfigPipelineTuningBestResultConfig =
  Config<PipelineTuningBestResultConfig>;

export const configTypePipelineTuningBestResult = `{}`;

export const reportDataTypePipelineTuningBestResult = `{
    kpi: string;
    pipelines: {
        id: string;
        displayName: string;
        schema: {
            id: string;
            displayName: string;
            optimizationMethod: {
                id: 'grid_search_cross_validation';
                nSplits: number;
            };
            node: {
                id: string;
                type: 'node';
                nodeType: 'transformer' | 'classifier';
                displayName: string;
                description: string;
                isActive?: boolean;
                isTuneable: boolean;
                tuningParameters?: {
                    id: string;
                    displayName: string;
                    value: (string | number)[];
                }[];
                isEditable: boolean;
                staticParameters?: {
                    id: string;
                    displayName: string;
                    value: string | number;
                }[];
            }[] | {
                id: string;
                displayName: string;
                nodes: {
                    id: string;
                    type: 'node';
                    nodeType: 'transformer' | 'classifier';
                    displayName: string;
                    description: string;
                    isActive?: boolean;
                    isTuneable: boolean;
                    tuningParameters?: {
                        id: string;
                        displayName: string;
                        value: (string | number)[];
                    }[];
                    isEditable: boolean;
                    staticParameters?: {
                        id: string;
                        displayName: string;
                        value: string | number;
                    }[];
                }[][];
            }[];
            edges: {
                sourceID: string;
                targetID: string;
            }[];
        };
        paths: {
            pathId: string;
            pipelineId: string;
            path: string[];
        }[];
    }[];
    bestModel: {
        parameters: {
            name: string;
            value: string | number;
        }[];
        score: number;
        pipelineId: string;
        pathId: string;
    };
    bestModels: {
        parameters: {
            name: string;
            value: string | number;
        }[];
        score: number;
        pipelineId: string;
        pathId: string;
    }[];
}`;

export const defaultConfigPipelineTuningBestResult = {};

export const defaultReportDataDefaultPipelineTuningBestResult = example1;
