import classNames from 'classnames';
import { DiscreteProgressSteps } from 'common/dist/types/job';
import React, { Component, ComponentType, Fragment, MouseEvent } from 'react';
import {
  injectIntl,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { DefaultTooltip } from '../../atoms/tooltip/Tooltip';

export type Props = {
  /** Optional link for the whole card */
  linkTo?: string;
  /** Optional ID for the element card */
  id?: string;
  /** Optional additional classname for the element card */
  addlClassName?: string;
  /** Color of the bar on the left */
  barColor?: string;
  /** Header/Title of the card */
  header: string;
  /** Optional subheader of the card */
  subHeader?: string;
  /** Optional info (centered) of the card */
  info?: Record<string, string>;
  /** Optional error of the card */
  error?: string;
  /** Is the whole card greyed out? */
  greyedOut?: boolean;
  /** Turns the card all red */
  alerting?: boolean;
  /** Show a progress indicator for this element? Will show the amount of jobs e.g. 5 jobs */
  progress?: string;
  /** Show a progress indicator for this element? */
  progressSteps?: DiscreteProgressSteps;
  /** List of icons to show in the right top */
  icons?: IconProps[];
  /** Optional amount of lines the 'info' can take. Defaults to 3. */
  maxAmountInfoLines?: number;
  /** Current running jobType **/
  jobType?: string;
};

export type IconProps = {
  Icon: ComponentType;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  linkTo?: string;
  tooltip?: MessageDescriptor;
};

type State = {};

class ElementCard extends Component<Props & WrappedComponentProps, State> {
  static defaultProps = {
    maxAmountInfoLines: 3,
  };

  renderInner() {
    const {
      barColor,
      header,
      subHeader,
      info,
      error,
      icons,
      progress,
      progressSteps,
      alerting,
      maxAmountInfoLines,
      intl,
      jobType,
    } = this.props;
    const infoString = Object.entries(info || {})
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    return (
      <Fragment>
        <div
          className={styles.colorBar}
          style={{ backgroundColor: barColor || '#9f9f9f' }}
        />
        <div className={styles.contentHeader} data-testid={header}>
          <div className={styles.contentHeaderLeft}>
            <span className={styles.header}>{header}</span>
            <span className={styles.subheader}>{subHeader || ''}</span>
          </div>
          <div className={styles.contentHeaderRight}>
            <div className={styles.iconLine}>
              {(progress !== undefined || progressSteps !== undefined) && (
                <div className={styles.progress}>
                  <span className={styles.progressNumber}>
                    {progressSteps !== undefined
                      ? progressSteps.total > 0
                        ? `${jobType}: ${progressSteps.started}/${progressSteps.total}`
                        : jobType
                      : `${progress}`}
                  </span>
                  <div
                    className={classNames(
                      styles.progressIcon,
                      'icon-refresh',
                      'icon-spin'
                    )}
                  />
                </div>
              )}
              {alerting && (
                <div className={styles.iconParent}>
                  <div
                    className={'icon-alert'}
                    style={{
                      color: '#EC3D3D',
                      fontSize: 19,
                    }}
                  />
                </div>
              )}
              {icons &&
                icons.map((icon, index) => {
                  if (!icon.linkTo) {
                    return (
                      <div
                        key={index}
                        className={styles.iconParent}
                        data-tooltip-id={
                          icon.tooltip ? `tooltip-${index}` : undefined
                        }
                        onClick={(e) => icon.onClick && icon.onClick(e)}
                      >
                        <icon.Icon />

                        {icon.tooltip && (
                          <DefaultTooltip id={`tooltip-${index}`}>
                            {intl.formatMessage(icon.tooltip)}
                          </DefaultTooltip>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <Link
                        key={index}
                        to={icon.linkTo}
                        className={styles.link}
                      >
                        <div
                          className={styles.iconParent}
                          data-tooltip-id={
                            icon.tooltip ? `tooltip-${index}` : undefined
                          }
                          onClick={(e) => icon.onClick && icon.onClick(e)}
                        >
                          <icon.Icon />

                          {icon.tooltip && (
                            <DefaultTooltip id={`tooltip-${index}`}>
                              {intl.formatMessage(icon.tooltip)}
                            </DefaultTooltip>
                          )}
                        </div>
                      </Link>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className={styles.contentSpacer} />
        {error ? (
          <div className={styles.error}>
            <LinesEllipsis
              text={error}
              maxLine={3}
              ellipsis={'...'}
              trimRight
              basedOn={'letters'}
            />
          </div>
        ) : (
          <div className={styles.contentInfo} title={infoString}>
            {Object.entries(info || {})
              .sort((a, b) => (a[0] < b[0] ? -1 : 1))
              .slice(0, 3)
              .map(([key, value]) => {
                return (
                  <div key={key} className={styles.infoEntry}>
                    <span className={styles.infoKey}>{key}</span>
                    <span className={styles.infoValue}>{value}</span>
                  </div>
                );
              })}
          </div>
        )}
      </Fragment>
    );
  }

  renderContent() {
    const { linkTo } = this.props;
    if (linkTo) {
      return (
        <Link className={styles.content} to={linkTo}>
          {this.renderInner()}
        </Link>
      );
    } else {
      return <div className={styles.content}>{this.renderInner()}</div>;
    }
  }

  render() {
    const { greyedOut, alerting, addlClassName, id } = this.props;
    return (
      <div
        id={id}
        className={classNames(
          styles.elementCard,
          addlClassName,
          { [styles.greyedOut]: greyedOut },
          { [styles.alerting]: alerting }
        )}
      >
        {this.renderContent()}
      </div>
    );
  }
}

export default injectIntl(ElementCard);
