// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraTableSelect/v1/type.ts' --type 'ConfigCassandraTableSelectConfig'
export const schemaConfigCassandraTableSelect = {
  "$ref": "#/definitions/ConfigCassandraTableSelectConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "CassandraKeyspaceSelectAugurSettings": {
      "additionalProperties": false,
      "properties": {
        "dataSourceCode": {
          "type": "string"
        },
        "keyspaceName": {
          "type": "string"
        }
      },
      "required": [
        "dataSourceCode",
        "keyspaceName"
      ],
      "type": "object"
    },
    "Config<CassandraTableSelectConfig>": {
      "additionalProperties": false,
      "description": "Describes the non-resolved config of a layout element.",
      "properties": {
        "keyspace": {
          "$ref": "#/definitions/ConfigEntry%3CCassandraKeyspaceSelectAugurSettings%3E"
        },
        "showTable": {
          "$ref": "#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E"
        }
      },
      "required": [
        "keyspace"
      ],
      "type": "object"
    },
    "ConfigCassandraTableSelectConfig": {
      "$ref": "#/definitions/Config%3CCassandraTableSelectConfig%3E"
    },
    "ConfigEntry<CassandraKeyspaceSelectAugurSettings>": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "source": {
              "const": "hard-coded",
              "type": "string"
            },
            "value": {
              "$ref": "#/definitions/CassandraKeyspaceSelectAugurSettings"
            }
          },
          "required": [
            "source",
            "value"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "elementUuid": {
              "type": "string"
            },
            "source": {
              "const": "input-element",
              "type": "string"
            }
          },
          "required": [
            "source",
            "elementUuid"
          ],
          "type": "object"
        }
      ],
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
    },
    "ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "source": {
              "const": "hard-coded",
              "type": "string"
            },
            "value": {
              "type": "boolean"
            }
          },
          "required": [
            "source",
            "value"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "elementUuid": {
              "type": "string"
            },
            "source": {
              "const": "input-element",
              "type": "string"
            }
          },
          "required": [
            "source",
            "elementUuid"
          ],
          "type": "object"
        }
      ],
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
    }
  }
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraTableSelect/v1/type.ts' --type 'PartialCassandraTableSelectAugurSettings'
export const schemaAugurSettingsDefaultCassandraTableSelect = {
  $ref: '#/definitions/PartialCassandraTableSelectAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    CassandraTableSelectAugurSettings: {
      type: 'string',
    },
    PartialCassandraTableSelectAugurSettings: {
      $ref: '#/definitions/CassandraTableSelectAugurSettings',
    },
  },
};
