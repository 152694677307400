import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Habitat, HabitatDetailsType } from 'common/dist/types/habitat';
import {
  PostHabitatRequestBody,
  PutHabitatRequestBody,
} from 'common/dist/types/requestBodies/habitats';
import {
  ExistsResponseBody,
  PostPutDeleteResponseBody,
} from 'common/dist/types/responseBodies/base';

import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  postApiRequest,
  putApiRequest,
} from './_tools';

export const habitatKeys = {
  habitats: () => ['habitats'] as const,
  habitat: (habitatCode: string) =>
    [...habitatKeys.habitats(), habitatCode] as const,
  add: () => [...habitatKeys.habitats(), 'add'] as const,
  update: (habitatCode: string) =>
    [...habitatKeys.habitat(habitatCode), 'update'] as const,
  delete: (habitatCode: string) =>
    [...habitatKeys.habitat(habitatCode), 'delete'] as const,
  exists: (habitatName: string) =>
    [...habitatKeys.habitats(), 'exists', habitatName] as const,
};

export function getHabitat(
  habitatCode: string
): CompletedApiRequest<HabitatDetailsType> {
  return apiRequest(`/api/habitats/${habitatCode}`);
}

export function useHabitat(habitatCode: string): UseQueryResult<Habitat> {
  const key = habitatKeys.habitat(habitatCode);
  return useQuery(key, () => fetchQueryFn(key, () => getHabitat(habitatCode)));
}

export function getHabitats(): CompletedApiRequest<HabitatDetailsType[]> {
  return apiRequest('/api/habitats');
}

export function useHabitats(): UseQueryResult<Habitat[]> {
  const key = habitatKeys.habitats();
  return useQuery(key, () => fetchQueryFn(key, () => getHabitats()));
}

// TODO: this should be a PUT request (actually even a HEAD request)
export function checkHabitatNameExists(
  habitatName: string
): CompletedApiRequest<ExistsResponseBody> {
  return postApiRequest(`/api/habitats/exists`, { name: habitatName });
}

export function postHabitat(
  payload: PostHabitatRequestBody
): CompletedApiRequest {
  return postApiRequest('/api/habitats', payload);
}

export function useAddHabitat(): UseMutationResult {
  const queryClient = useQueryClient();
  const key = habitatKeys.add();
  return useMutation(
    key,
    (payload: PostHabitatRequestBody) =>
      fetchQueryFn(key, () => postHabitat(payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: habitatKeys.habitats(),
        });
      },
    }
  );
}

export function putHabitat(
  habitatCode: string,
  payload: PutHabitatRequestBody
): CompletedApiRequest<PostPutDeleteResponseBody> {
  return putApiRequest(`/api/habitats/${habitatCode}`, payload);
}

export function useUpdateHabitat(habitatCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const key = habitatKeys.update(habitatCode);
  return useMutation(
    key,
    (payload: PutHabitatRequestBody) =>
      fetchQueryFn(key, () => putHabitat(habitatCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: habitatKeys.habitats(),
        });
      },
    }
  );
}

export function deleteHabitat(habitatCode: string): CompletedApiRequest {
  return deleteApiRequest(`/api/habitats/${habitatCode}`);
}

export function useDeleteHabitat(habitatCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const key = habitatKeys.delete(habitatCode);
  return useMutation(
    key,
    () => fetchQueryFn(key, () => deleteHabitat(habitatCode)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: habitatKeys.habitats(),
        });
      },
    }
  );
}
