import React, { Component } from 'react';
import {
  FiBarChart2,
  FiBook,
  FiHelpCircle,
  FiList,
  FiLogOut,
  FiServer,
  FiTerminal,
  FiTool,
  FiTrendingUp,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { Link } from 'react-router-dom';

import GoTo from './GoTo';
import './styles.scss';

import { logout } from '../../../../../keycloak';
import * as routes from '../../../index/routes';

import auditLogMsgs from 'common/dist/messages/auditLog';
import { FrontendConfig } from 'common/dist/types/frontendConfig';
import messagesHeader from 'common/dist/messages/header';

export const logoutLink = '/logout';
export const accountLink = routes.app.prefix + routes.app.account;

export type Props = {
  closeMenu: () => void;
  isAdmin: boolean;
  frontendConfig?: FrontendConfig;
  showWhatsNewModal: () => void;
};

export class TopMenu extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    const { closeMenu } = this.props;

    closeMenu();
  }

  render() {
    const { isAdmin, frontendConfig, showWhatsNewModal } = this.props;

    return (
      <div className='TopMenu'>
        <div className={'TopMenu--category'}>
          {frontendConfig?.changelog && (
            <>
              <div className={'TopMenu--category-title'}>
                <span>Help</span>
              </div>
              <ul className='TopMenu--list'>
                <li className='TopMenu--item'>
                  <div
                    className='TopMenu--link'
                    onClick={() => {
                      showWhatsNewModal();
                      this.handleClickOutside();
                    }}
                  >
                    <FiHelpCircle size={14} className={'TopMenu--icon'} />
                    <FormattedMessage {...messagesHeader.menuWhatsNew} />
                  </div>
                </li>
              </ul>
            </>
          )}

          <div className={'TopMenu--category-title'}>
            <span>User Actions</span>
          </div>
          <ul className='TopMenu--list'>
            <li className='TopMenu--item'>
              <Link
                className='TopMenu--link'
                onClick={this.handleClickOutside}
                to={accountLink}
              >
                <FiUser size={14} className={'TopMenu--icon'} />
                <FormattedMessage
                  id='header.menu.profile'
                  defaultMessage='Profile'
                />
              </Link>
            </li>
            <li id={'LogoutButton'} className='TopMenu--item'>
              <a // Just for styling
                className='TopMenu--link'
                onClick={() => {
                  logout(frontendConfig);
                  this.handleClickOutside();
                }}
              >
                <FiLogOut size={14} className={'TopMenu--icon'} />
                <FormattedMessage
                  id='header.menu.logout'
                  defaultMessage='Logout'
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default onClickOutside(TopMenu);
