import { connect, ConnectedProps } from 'react-redux';
import Tables, { Props } from './Tables';
import {
  fetchCassandraPermissions,
  fetchCassandraTables,
  setActiveUploadWizard,
} from '../../../../redux/modules/data.cassandra.module';
import { withRouter } from 'react-router-dom';
import { CASSANDRA_KEYSPACE_SCOPE_IDS } from 'common/dist/constants/keycloak';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/store';

export function mapStateToProps(
  state: RootState,
  {
    dataSourceCode,
    match,
  }: Props &
    RouteComponentProps<{
      // Either one of these seem to be set, depending on use
      /** Name of the Keyspace (from the route) */
      keyspaceName: string;
    }>
) {
  const keyspaceName = match.params.keyspaceName || '';
  const { loading, loaded, error, data } =
    ((state.data.cassandra[dataSourceCode] || {}).tables || {})[keyspaceName] ||
    {};
  const permissions = state.data.cassandra[dataSourceCode]?.permissions;
  const allowedToUpload = new Set(
    keyspaceName?.length > 0
      ? permissions?.data?.[keyspaceName]
      : Object.values(permissions?.data || {}).flat()
  ).has(CASSANDRA_KEYSPACE_SCOPE_IDS.MODIFY);
  const allowedToSelect = new Set(
    keyspaceName?.length > 0
      ? permissions?.data?.[keyspaceName]
      : Object.values(permissions?.data || {}).flat()
  ).has(CASSANDRA_KEYSPACE_SCOPE_IDS.SELECT);
  return {
    loading,
    loaded,
    error,
    data,
    keyspaceName,
    allowedToUpload,
    allowedToSelect,
  };
}

export const mapDispatchToProps: {
  /** Fetch list of all Keyspaces */
  fetchCassandraTables: (dataSourceCode: string, keyspaceName: string) => void;
  /** Set the active upload wizard */
  setActiveUploadWizard: (activeWizard: string, uploadCode: string) => void;
  fetchCassandraPermissions: (dataSourceCode: string) => void;
} = {
  fetchCassandraTables,
  setActiveUploadWizard,
  fetchCassandraPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(Tables));
