import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
  },
  reset: {
    id: 'form.reset',
    defaultMessage: 'Reset',
  },
  restore: {
    id: 'form.restore',
    defaultMessage: 'Restore Defaults',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Submit',
  },
  clear: {
    id: 'form.clear',
    defaultMessage: 'Clear',
  },
  delete: {
    id: 'form.delete',
    defaultMessage: 'Delete',
  },
});
