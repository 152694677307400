import { Branch, REPO_TYPE, RepoType } from 'common/dist/types/repository';
import React, { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import ContentElement from './ContentElement.container';
import { useModuleByCode } from '../../../../core/api/modules';
import DropdownSelectInput, {
  Option,
} from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import RepositoryBreadcrumbs from '../../../molecules/repository-header/RepositoryBreadcrumbs';
import { repositoryDetailsLink2 } from '../../routes';

interface BranchesProps {
  fetchBranches: (group: string, repositoryName: string) => void;
  switchBranch: (branchName: string) => void;
  branches: Branch[]; // Adjust the type according to your data structure
  moduleCode?: string;
  repoType?: RepoType;
}

interface BranchesState {
  searchString: string;
  ordering: string;
}

interface RouteParams {
  group?: string;
  repositoryName?: string;
}

const Branches: FC<BranchesProps & RouteComponentProps<RouteParams>> = (
  props
) => {
  const [searchString, setSearchString] = useState('');
  const [ordering, setOrdering] = useState('name-asc');
  const { fetchBranches, moduleCode, repoType } = props;
  const module = useModuleByCode(moduleCode, !!moduleCode);
  let { group, repositoryName } = props.match.params;
  if (module && module?.data?.repository) {
    const parts = (module?.data?.repository.repoFullName || '').split('/');
    group = parts[0];
    repositoryName = parts[1];
  }
  useEffect(() => {
    fetchBranches(group, repositoryName);
  }, [props.fetchBranches, props.match.params, props.moduleCode]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleSortingChange = (option: Option) => {
    setOrdering(option.value);
  };

  function sortData(data, ordering) {
    if (!data) return [];
    if (ordering === 'last-updated') {
      return data.sort((a, b) => {
        if (!a.commit.timestamp || !b.commit.timestamp) return -1;
        return a.commit.timestamp > b.commit.timestamp ? -1 : 1;
      });
    } else if (ordering === 'oldest-updated') {
      return data.sort((a, b) => {
        if (!a.commit.timestamp || !b.commit.timestamp) return -1;
        return a.commit.timestamp < b.commit.timestamp ? -1 : 1;
      });
    } else if (ordering === 'name-desc') {
      return data.sort((a, b) => {
        if (!a.name || !b.name) return -1;
        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
      });
    } else {
      return data.sort((a, b) => {
        if (!a.name || !b.name) return -1;
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
    }
  }

  const searchedData = searchString
    ? props.branches.filter((branch) => branch.name.includes(searchString))
    : props.branches;

  const options: Option[] = [
    { label: 'Name A-Z', value: 'name-asc' },
    { label: 'Name Z-A', value: 'name-desc' },
    { label: 'Last Updated', value: 'last-updated' },
    { label: 'Oldest Updated', value: 'oldest-updated' },
  ];
  return (
    <div className={'CollaborationSpace--content'}>
      <div className={'repository branches'}>
        <RepositoryBreadcrumbs repositoryType={repoType} />

        <div className={'repository-top'}>
          <div className={'repository-info'}>
            <div className={'repository-top-line'}>
              <span className={'repository-name'}>Branches</span>
            </div>
          </div>
        </div>
        <div className={'headline'}>
          <div className={'search'}>
            <input
              placeholder={'Filter by name'}
              type={'text'}
              value={searchString}
              onChange={handleSearchInputChange}
            />
          </div>
          <DropdownSelectInput
            id='sorting-selector'
            name='order'
            containerClassName={'sorting-selector'}
            options={options}
            value={options.find((o) => o.value === ordering)}
            onChange={handleSortingChange}
            clearable={false}
          />
        </div>
        {sortData(searchedData, ordering).map((branch, i) => {
          return repoType === REPO_TYPE.MODULE ? (
            <ContentElement
              name={branch.name}
              commit={branch.commit}
              group={group}
              repositoryName={repositoryName}
            />
          ) : (
            <Link
              key={i}
              to={repositoryDetailsLink2(group, repositoryName)}
              style={{ textDecoration: 'none' }}
              onClick={() => props.switchBranch(branch.name)}
            >
              <ContentElement
                name={branch.name}
                commit={branch.commit}
                group={group}
                repositoryName={repositoryName}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(Branches);
