// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraTableColumnSelect/v1/type.ts' --type 'ConfigCassandraTableColumnSelectConfig'
export const schemaConfigCassandraTableColumnSelect = {
  "$ref": "#/definitions/ConfigCassandraTableColumnSelectConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "CassandraKeyspaceSelectAugurSettings": {
      "additionalProperties": false,
      "properties": {
        "dataSourceCode": {
          "type": "string"
        },
        "keyspaceName": {
          "type": "string"
        }
      },
      "required": [
        "dataSourceCode",
        "keyspaceName"
      ],
      "type": "object"
    },
    "CassandraTableSelectAugurSettings": {
      "type": "string"
    },
    "Config<CassandraTableColumnSelectConfig>": {
      "additionalProperties": false,
      "description": "Describes the non-resolved config of a layout element.",
      "properties": {
        "isClearable": {
          "$ref": "#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E"
        },
        "isMulti": {
          "$ref": "#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E"
        },
        "isSearchable": {
          "$ref": "#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E"
        },
        "keyspace": {
          "$ref": "#/definitions/ConfigEntry%3CCassandraKeyspaceSelectAugurSettings%3E"
        },
        "table": {
          "$ref": "#/definitions/ConfigEntry%3CCassandraTableSelectAugurSettings%3E"
        }
      },
      "required": [
        "keyspace",
        "table"
      ],
      "type": "object"
    },
    "ConfigCassandraTableColumnSelectConfig": {
      "$ref": "#/definitions/Config%3CCassandraTableColumnSelectConfig%3E"
    },
    "ConfigEntry<CassandraKeyspaceSelectAugurSettings>": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "source": {
              "const": "hard-coded",
              "type": "string"
            },
            "value": {
              "$ref": "#/definitions/CassandraKeyspaceSelectAugurSettings"
            }
          },
          "required": [
            "source",
            "value"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "elementUuid": {
              "type": "string"
            },
            "source": {
              "const": "input-element",
              "type": "string"
            }
          },
          "required": [
            "source",
            "elementUuid"
          ],
          "type": "object"
        }
      ],
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
    },
    "ConfigEntry<CassandraTableSelectAugurSettings>": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "source": {
              "const": "hard-coded",
              "type": "string"
            },
            "value": {
              "$ref": "#/definitions/CassandraTableSelectAugurSettings"
            }
          },
          "required": [
            "source",
            "value"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "elementUuid": {
              "type": "string"
            },
            "source": {
              "const": "input-element",
              "type": "string"
            }
          },
          "required": [
            "source",
            "elementUuid"
          ],
          "type": "object"
        }
      ],
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
    },
    "ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "source": {
              "const": "hard-coded",
              "type": "string"
            },
            "value": {
              "type": "boolean"
            }
          },
          "required": [
            "source",
            "value"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "elementUuid": {
              "type": "string"
            },
            "source": {
              "const": "input-element",
              "type": "string"
            }
          },
          "required": [
            "source",
            "elementUuid"
          ],
          "type": "object"
        }
      ],
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
    }
  }
}

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraTableColumnSelect/v1/type.ts' --type 'PartialCassandraTableColumnSelectAugurSettings'
export const schemaAugurSettingsDefaultCassandraTableColumnSelect = {
  "$ref": "#/definitions/PartialCassandraTableColumnSelectAugurSettings",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "CassandraTableColumnSelectAugurSettings": {
      "items": {
        "type": "string"
      },
      "type": "array"
    },
    "PartialCassandraTableColumnSelectAugurSettings": {
      "$ref": "#/definitions/CassandraTableColumnSelectAugurSettings"
    }
  }
}
