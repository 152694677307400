import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  CampaignOptimizationCommunicationGroupsConfig,
  ConfigCampaignOptimizationCommunicationGroupsConfig,
} from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { ElementOptionType } from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import InputElementSelect from '../../../../../atoms/react-hook-form-input-elements/input-element-select/InputElementSelect';

const CampaignOptimizationCommunicationGroupsConfigFormComponent: FC<
  ConfigFormComponentProps<CampaignOptimizationCommunicationGroupsConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } =
    useForm<ConfigCampaignOptimizationCommunicationGroupsConfig>({
      defaultValues: configValue,
    });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const communicationOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type ===
        SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <Controller
      name='communications'
      control={control}
      render={({ field }) => {
        return (
          <InputElementSelect
            value={
              field.value.source === 'input-element'
                ? field.value.elementUuid
                : ''
            }
            isInputElement={field.value.source === 'input-element'}
            onChange={(newValue) => {
              field.onChange(newValue);
              updateConfig();
            }}
            options={communicationOptions}
            label={augurElementsMessages.communicationsLabel}
            placeholder={augurElementsMessages.selectCommunicationsElement}
          />
        );
      }}
    />
  );
};

export default CampaignOptimizationCommunicationGroupsConfigFormComponent;
