import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdminAddGroupContainer from './add-group/AdminAddGroup.container';
import AdminAddUserContainer from './add-user/AdminAddUserWizard.container';
import GroupDetails from './group-details/GroupDetails.container';
import Groups from './groups/Groups.container';
import HabitatDetails from './habitat-details/HabitatDetails.container';
import Habitats from './habitats/Habitats.container';
import { usersRoutes } from './routes';
import styles from './styles.module.scss';
import AdminUserDetails from './user-details/AdminUserDetails.container';
import UsersList from './user-list/UsersList';
import * as routes from '../../index/routes';

const UsersAndPermissions: FC = () => {
  return (
    <div className={styles.container}>
      {/* <HeaderUsers /> */}
      <div className={styles.content}>
        <Switch>
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.userList.path}`}
            component={UsersList}
          />
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.groups.path}/:groupId`}
            component={GroupDetails}
          />
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.groups.path}`}
            component={Groups}
          />
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.habitats.path}/:groupIds`}
            component={HabitatDetails}
          />
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.habitats.path}`}
            component={Habitats}
          />
          <Route
            path={`${usersRoutes.basePath}/${usersRoutes.adminUserDetails.path}/:userId`}
            component={AdminUserDetails}
          />
          <Route
            path={`${usersRoutes.basePath}/${routes.app.admin.addUser}`}
            component={AdminAddUserContainer}
          />
          <Route
            path={`${usersRoutes.basePath}/${routes.app.admin.addGroup}`}
            component={AdminAddGroupContainer}
          />
          <Redirect
            path={usersRoutes.basePath}
            to={`${usersRoutes.basePath}/${usersRoutes.userList.path}`}
          />
        </Switch>
      </div>
    </div>
  );
};

export default UsersAndPermissions;
