import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactLoading from 'react-loading';

import CodeCapsulesCardView from './CodeCapsulesCardView';
import CodeCapsulesListView from './CodeCapsulesListView';
import vars from '../../../../../scss/base/var.module.scss';
import CollapsibleArea from '../../../molecules/collapsable-area/CollapsibleArea';
import RunCodeCapsuleModal from '../../../runCodeCapsuleModal/RunCodeCapsuleModal.container';
import { HABITAT_GRID_MAX_HEIGHT } from '../augurs/Augurs';
import NoElements from '../no-elements/NoElements';

type Props = {
  habitatCode: string;
  isListView: boolean;
  codeCapsules: {
    data: CodeCapsule[];
    loading: boolean;
    loaded: boolean;
    error: string;
  };
  triggerRunCodeCapsuleJob: () => void; // The parameters are not complete
};

type State = {
  runCodeCapsuleModalOpen: boolean;
  codeCapsuleModalProps: ToBeRefined;
};

class CodeCapsules extends Component<Props & WrappedComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      runCodeCapsuleModalOpen: false,
      codeCapsuleModalProps: {},
    };
    this.openRunCodeCapsuleModal = this.openRunCodeCapsuleModal.bind(this);
    this.closeRunCodeCapsuleModal = this.closeRunCodeCapsuleModal.bind(this);
  }

  openRunCodeCapsuleModal(codeCapsuleModalProps: ToBeRefined) {
    this.setState({
      runCodeCapsuleModalOpen: true,
      codeCapsuleModalProps,
    });
  }

  closeRunCodeCapsuleModal() {
    this.setState({
      runCodeCapsuleModalOpen: false,
      codeCapsuleModalProps: {},
    });
  }

  renderLoaded() {
    const { codeCapsules, isListView, habitatCode } = this.props;

    if (isListView) {
      return (
        <CodeCapsulesListView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={this.openRunCodeCapsuleModal}
        />
      );
    } else {
      return (
        <CodeCapsulesCardView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={this.openRunCodeCapsuleModal}
        />
      );
    }
  }

  renderLoading() {
    return (
      <div>
        <ReactLoading
          className={'busy'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  renderError() {
    const {
      codeCapsules: { error },
    } = this.props;
    return <div>{JSON.stringify(error)}</div>;
  }

  renderInner() {
    const {
      codeCapsules: { loading, error, loaded },
    } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (loaded) return this.renderLoaded();
    else return null;
  }

  render() {
    const { triggerRunCodeCapsuleJob, codeCapsules, habitatCode } = this.props;

    if (!codeCapsules.data || codeCapsules.data.length === 0)
      return (
        <NoElements
          title={{
            id: 'dashboard.code_capsules.no_code_capsules',
            defaultMessage: 'There are no Code Capsules for this Habitat yet.',
          }}
        />
      );

    return (
      <div className={'code-capsules'}>
        <CollapsibleArea
          maxHeight={HABITAT_GRID_MAX_HEIGHT}
          contentStyle={{
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: 0,
          }}
          childrenStyle={{
            paddingBottom: '20px',
          }}
        >
          {this.renderInner()}
        </CollapsibleArea>
        {this.state.runCodeCapsuleModalOpen && (
          <RunCodeCapsuleModal
            close={this.closeRunCodeCapsuleModal}
            onSubmit={triggerRunCodeCapsuleJob}
            {...this.state.codeCapsuleModalProps}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(CodeCapsules);
