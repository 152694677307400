import qs from 'qs';
import { connect } from 'react-redux';

import DeleteBranchModal from './DeleteBranchModal';
import {
  gitDeleteBranch,
  gitDeleteRemoteBranch,
  gitForceDeleteBranch,
} from '../../../../../../../redux/workbench/modules/container-interactions.module';
import { hideDeleteBranchModal } from '../../../../../../../redux/workbench/modules/repository.module';
import { WORKBENCH_FILENAMES } from '../../../../config';

export const inputDeleteRemote = 'input-delete-remote';

export function mapStateToProps(state) {
  // FIXME: use useSelectedDirPath
  const path = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  return {
    isDeleteBranchModalOpen:
      state.workbench.showRepositoryInfo.isDeleteBranchModalOpen,
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    isForceDeleteVisible:
      state.workbench.showRepositoryInfo.isForceDeleteVisible,
    infoFilePath: `${path}/${WORKBENCH_FILENAMES.REPOSITORY_META}`,
    remote: (state.workbench.showRepositoryInfo || {}).remote,
  };
}

export const mapDispatchToProps = {
  hideDeleteBranchModal,
  gitForceDeleteBranch,
  gitDeleteBranch,
  gitDeleteRemoteBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBranchModal);
