import messagesHeader from 'common/dist/messages/header';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/store';
import Modal, { Props as ModalProps } from '../../modal/Modal';



export type Props = Pick<ModalProps, 'show' | 'hideModal'> & {};

const WhatsNew: FC<Props> = ({ show, hideModal }) => {
  const changelog = useSelector<RootState, string | undefined>(
    (state) => state.config?.changelog
  );
  return (
    <Modal
      show={show}
      hideModal={hideModal}
      // This can't be attached to #MainContainer, as that may get unmounted or not even exist yet
      parentSelector={() => document.getElementById('react-app')}
    >
      <div>
        <h1>
          <FormattedMessage {...messagesHeader.menuWhatsNew} />
        </h1>
        <hr />
        <div style={{ padding: '10px' }}>
          <ReactMarkdown>{changelog}</ReactMarkdown>
        </div>
      </div>
    </Modal>
  );
};

export default WhatsNew;
