import { createAction } from 'redux-act';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import { bucketFromResource } from 'common/dist/constants/keycloak';
import keycloak, { updateToken } from '../../../keycloak';
import { sendNotification } from './notifications.module';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';
import notificationsMsgs from 'common/dist/messages/notifications';

import { UPLOAD_WIZARDS } from '../../components/dataManagement/s3/upload/uploadWizards';
import { setActiveUploadWizard } from './data.cassandra.module';

export const fetchPreview = createAction(
  'fetch s3 preview',
  (dataSourceCode, path) => ({ dataSourceCode, path })
);

export const fetchPreviewSuccess = createAction(
  'fetch s3 preview - success',
  (data, dataSourceCode, path) => ({ data, dataSourceCode, path })
);

export const fetchPreviewFailure = createAction(
  'fetch s3 preview - failure',
  (error, dataSourceCode, path) => ({ error, dataSourceCode, path })
);

export const fetchBuckets = createAction(
  'fetch s3 buckets',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchBucketsSuccess = createAction(
  'fetch s3 buckets - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchBucketsFailure = createAction(
  'fetch s3 buckets - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const fetchBucketContent = createAction(
  'fetch s3 bucket content',
  (dataSourceCode, bucket, bucketPath) => ({
    dataSourceCode,
    bucket,
    bucketPath,
  })
);

export const fetchBucketContentSuccess = createAction(
  'fetch s3 bucket content - success',
  (data, dataSourceCode, bucket, bucketPath) => ({
    data,
    dataSourceCode,
    bucket,
    bucketPath,
  })
);

export const fetchBucketContentFailure = createAction(
  'fetch s3 bucket content - failure',
  (error, dataSourceCode, bucket, bucketPath) => ({
    error,
    dataSourceCode,
    bucket,
    bucketPath,
  })
);

export const fetchS3Permissions = createAction(
  'fetch s3 permissions',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchS3PermissionsSuccess = createAction(
  'fetch s3 permissions - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchS3PermissionsFailure = createAction(
  'fetch s3 permissions - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const fetchS3Credentials = createAction(
  'fetch s3 credentials',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchS3CredentialsSuccess = createAction(
  'fetch s3 credentials - success',
  (data, dataSourceCode) => ({ data, dataSourceCode })
);

export const fetchS3CredentialsFailure = createAction(
  'fetch s3 credentials - failure',
  (error, dataSourceCode) => ({ error, dataSourceCode })
);

export const clearS3Credentials = createAction(
  'clear s3 credentials',
  (dataSourceCode) => ({ dataSourceCode })
);

export const uploadFile = createAction(
  's3 - upload file',
  (files, meta, uploadCode, dataSourceCode) => ({
    files,
    meta,
    uploadCode,
    dataSourceCode,
  })
);

export const uploadFileSuccess = createAction(
  's3 - upload file success',
  (uploadCode, dataSourceCode) => ({ uploadCode, dataSourceCode })
);

export const uploadFileFail = createAction(
  's3 - upload file failure',
  (error, uploadCode, dataSourceCode) => ({ error, uploadCode, dataSourceCode })
);

export const uploadProgress = createAction(
  's3 - upload progress',
  (uploadCode, dataSourceCode, progress) => ({
    uploadCode,
    dataSourceCode,
    progress,
  })
);

export const deleteObject = createAction(
  's3 - delete object',
  (bucket, path, dataSourceCode) => ({
    bucket,
    path,
    dataSourceCode,
  })
);

export const moveObject = createAction(
  's3 - move object',
  (srcBucket, srcPath, dstBucket, dstPath, dataSourceCode) => ({
    srcBucket,
    srcPath,
    dstBucket,
    dstPath,
    dataSourceCode,
  })
);

export const resetUploadFile = createAction(
  's3 - reset upload file',
  (dataSourceCode) => ({ dataSourceCode })
);

export const validatePath = createAction(
  's3 - validate path',
  (dataSourceCode, bucket, path, callbacks) => ({
    dataSourceCode,
    bucket,
    path,
    callbacks,
  })
);

export const reducer = {
  [fetchPreview]: (state, { dataSourceCode, path }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          preview: {
            ...((state.data.s3[dataSourceCode] || {}).preview || {}),
            [path]: {
              ...((state.data.s3[dataSourceCode]?.preview || {})[path] || {}),
              loading: true,
              error: undefined,
            },
          },
        },
      },
    },
  }),
  [fetchPreviewSuccess]: (state, { data, dataSourceCode, path }) => {
    return {
      ...state,
      data: {
        ...state.data,
        s3: {
          ...state.data.s3,
          [dataSourceCode]: {
            ...(state.data.s3[dataSourceCode] || {}),
            preview: {
              ...((state.data.s3[dataSourceCode] || {}).preview || {}),
              [path]: {
                loading: false,
                loaded: true,
                error: undefined,
                data,
              },
            },
          },
        },
      },
    };
  },
  [fetchPreviewFailure]: (state, { error, dataSourceCode, path }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          preview: {
            ...((state.data.s3[dataSourceCode] || {}).preview || {}),
            [path]: {
              ...((state.data.s3[dataSourceCode]?.preview || {})[path] || {}),
              loading: false,
              loaded: false,
              error,
            },
          },
        },
      },
    },
  }),
  [fetchBuckets]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          buckets: {
            ...((state.data.s3[dataSourceCode] || {}).buckets || {}),
            loading: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [fetchBucketsSuccess]: (state, { data, dataSourceCode }) => {
    return {
      ...state,
      data: {
        ...state.data,
        s3: {
          ...state.data.s3,
          [dataSourceCode]: {
            ...(state.data.s3[dataSourceCode] || {}),
            buckets: {
              ...((state.data.s3[dataSourceCode] || {}).buckets || {}),
              loading: false,
              loaded: true,
              error: undefined,
              data,
            },
          },
        },
      },
    };
  },
  [fetchBucketsFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          buckets: {
            ...((state.data.s3[dataSourceCode] || {}).buckets || {}),
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  // state.data.s3[dataSourceCode].bucketContent[bucket][bucketPath]
  [fetchBucketContent]: (state, { dataSourceCode, bucket, bucketPath }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          bucketContent: {
            ...((state.data.s3[dataSourceCode] || {}).bucketContent || {}),
            [bucket]: {
              ...(((state.data.s3[dataSourceCode] || {}).bucketContent || {})[
                bucket
              ] || {}),
              [bucketPath]: {
                ...((((state.data.s3[dataSourceCode] || {}).bucketContent ||
                  {})[bucket] || {})[bucketPath] || {}),
                loading: true,
                error: undefined,
              },
            },
          },
        },
      },
    },
  }),
  [fetchBucketContentSuccess]: (
    state,
    { data, dataSourceCode, bucket, bucketPath }
  ) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          bucketContent: {
            ...((state.data.s3[dataSourceCode] || {}).bucketContent || {}),
            [bucket]: {
              ...(((state.data.s3[dataSourceCode] || {}).bucketContent || {})[
                bucket
              ] || {}),
              [bucketPath]: {
                ...((((state.data.s3[dataSourceCode] || {}).bucketContent ||
                  {})[bucket] || {})[bucketPath] || {}),
                loading: false,
                loaded: true,
                error: undefined,
                data,
              },
            },
          },
        },
      },
    },
  }),
  [fetchBucketContentFailure]: (
    state,
    { error, dataSourceCode, bucket, bucketPath }
  ) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          bucketContent: {
            ...((state.data.s3[dataSourceCode] || {}).bucketContent || {}),
            [bucket]: {
              ...(((state.data.s3[dataSourceCode] || {}).bucketContent || {})[
                bucket
              ] || {}),
              [bucketPath]: {
                ...((((state.data.s3[dataSourceCode] || {}).bucketContent ||
                  {})[bucket] || {})[bucketPath] || {}),
                loading: false,
                loaded: false,
                error,
              },
            },
          },
        },
      },
    },
  }),
  [fetchS3Permissions]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          permissions: {
            ...((state.data.s3[dataSourceCode] || {}).permissions || {}),
            loading: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [fetchS3PermissionsSuccess]: (state, { data, dataSourceCode }) => {
    // Index the data directly by keyspace name not the whole keycloak resource name
    const dataWithBucketNames = Object.fromEntries(
      Object.entries(data).map(([resource, scopes]) => [
        bucketFromResource(resource),
        scopes,
      ])
    );
    return {
      ...state,
      data: {
        ...state.data,
        s3: {
          ...state.data.s3,
          [dataSourceCode]: {
            ...(state.data.s3[dataSourceCode] || {}),
            permissions: {
              ...((state.data.s3[dataSourceCode] || {}).permissions || {}),
              loading: false,
              loaded: true,
              data: dataWithBucketNames,
            },
          },
        },
      },
    };
  },
  [fetchS3PermissionsFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          permissions: {
            ...((state.data.s3[dataSourceCode] || {}).permissions || {}),
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  [fetchS3Credentials]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          credentials: {
            ...((state.data.s3[dataSourceCode] || {}).keyspaces || {}),
            loading: true,
            error: undefined,
            data: undefined,
          },
        },
      },
    },
  }),
  [fetchS3CredentialsSuccess]: (state, { data, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          credentials: {
            loading: false,
            loaded: true,
            data,
          },
        },
      },
    },
  }),
  [fetchS3CredentialsFailure]: (state, { error, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {
          ...(state.data.s3[dataSourceCode] || {}),
          credentials: {
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    },
  }),
  [clearS3Credentials]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      s3: {
        ...state.data.s3,
        [dataSourceCode]: {},
      },
    },
  }),
  [uploadFile]: (state, { files, meta, uploadCode, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...((state.data.cassandra[dataSourceCode] || {}).upload || {}),
        uploading: true,
        progress: 0,
        uploadCode,
        uploaded: false,
      },
    },
  }),
  [uploadProgress]: (state, { uploadCode, progress, dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: true,
        progress,
      },
    },
  }),
  [resetUploadFile]: (state, { dataSourceCode }) => ({
    ...state,
    data: {
      ...state.data,
      upload: {
        ...(state.data.upload || {}),
        uploading: false,
        uploaded: false,
        progress: 0,
      },
    },
  }),
  [uploadFileSuccess]: (state, { uploadCode, dataSourceCode }) => {
    return {
      ...state,
      data: {
        ...state.data,
        upload: {
          ...(state.data.upload || {}),
          uploading: false,
          progress: 100,
          uploaded: true,
        },
      },
    };
  },
  [uploadFileFail]: (state, { dataSourceCode, uploadCode, error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        upload: {
          ...(state.data.upload || {}),
          uploading: false,
          uploaded: false,
          error,
        },
      },
    };
  },
};

export function* fetchPreviewSaga({ payload: { dataSourceCode, path } }) {
  const { response, error } = yield call(
    Api.data.fetchS3Preview,
    dataSourceCode,
    path
  );
  if (response) {
    yield put(fetchPreviewSuccess(response, dataSourceCode, path));
  } else {
    yield put(fetchPreviewFailure(error, dataSourceCode, path));
  }
}

export function* watchFetchPreview() {
  yield takeEvery(fetchPreview.getType(), fetchPreviewSaga);
}

export function* fetchBucketsSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.fetchS3Buckets,
    dataSourceCode
  );
  if (response) {
    yield put(fetchBucketsSuccess(response, dataSourceCode));
  } else {
    yield put(fetchBucketsFailure(error, dataSourceCode));
  }
}

export function* watchFetchBuckets() {
  yield takeEvery(fetchBuckets.getType(), fetchBucketsSaga);
}

export function* fetchBucketContentSaga({
  payload: { dataSourceCode, bucket, bucketPath },
}) {
  const { response, error } = yield call(
    Api.data.fetchS3BucketContent,
    dataSourceCode,
    bucket,
    bucketPath
  );
  if (response) {
    yield put(
      fetchBucketContentSuccess(response, dataSourceCode, bucket, bucketPath)
    );
  } else {
    yield put(
      fetchBucketContentFailure(error, dataSourceCode, bucket, bucketPath)
    );
  }
}

export function* watchFetchBucketContent() {
  yield takeEvery(fetchBucketContent.getType(), fetchBucketContentSaga);
}

export function* fetchS3PermissionsSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.fetchS3Permissions,
    dataSourceCode
  );
  if (response) {
    yield put(fetchS3PermissionsSuccess(response, dataSourceCode));
  } else {
    yield put(fetchS3PermissionsFailure(error, dataSourceCode));
  }
}

export function* watchFetchS3Permissions() {
  yield takeEvery(fetchS3Permissions.getType(), fetchS3PermissionsSaga);
}

export function* fetchS3CredentialsSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.fetchS3Credentials,
    dataSourceCode
  );
  if (response) {
    yield put(fetchS3CredentialsSuccess(response, dataSourceCode));
  } else {
    yield put(fetchS3CredentialsFailure(error, dataSourceCode));
  }
}

export function* watchFetchS3Credentials() {
  yield takeEvery(fetchS3Credentials.getType(), fetchS3CredentialsSaga);
}

export function* uploadFileSaga({
  payload: { files, meta, uploadCode, dataSourceCode },
}) {
  const channel = yield call(
    uploadFileAsForm,
    uploadCode,
    files,
    meta,
    dataSourceCode
  );
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* watchUploadFile() {
  yield takeEvery(uploadFile.getType(), uploadFileSaga);
}

/**
 * Starts the upload stream to the Data Management API
 * @param uploadCode
 * @param files
 * @param meta
 * @param dataSourceCode
 * @returns {Generator<*, *, *>}
 */
function uploadFileAsForm(uploadCode, files, meta, dataSourceCode) {
  return eventChannel((emitter) => {
    const formData = new FormData();
    formData.append('uploadCode', uploadCode);
    formData.append('meta', JSON.stringify(meta));
    // We simply add multiple file fields to the form and take the name from the metadata
    for (const file of files) {
      // If not "" webkitRelativePath contains the directory structure + filename
      formData.append('file', file, file.webkitRelativePath || file.name);
    }

    // TODO is the upload really required to be done with axios? This might be inconsistent to the other REST interactions
    // TODO try to find a better way to include the keycloak token
    updateToken()
      .then((refreshed) => {
        const http = axios.create();
        return http.post(`/dataman/s3/${dataSourceCode}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${keycloak.token}`,
          },
          onUploadProgress: (e) => {
            const progress = Math.round((100 * e.loaded) / e.total);
            // --- still uploading
            emitter(uploadProgress(uploadCode, dataSourceCode, progress));
          },
        });
      })
      .then(() => {
        // --- done
        emitter(uploadFileSuccess(uploadCode, dataSourceCode));
        emitter(setActiveUploadWizard(UPLOAD_WIZARDS.CONFIRMATION, ''));
        emitter(
          sendNotification(
            notificationsMsgs.msgTitleUploadFile.id,
            notificationsMsgs.msgDescriptionUploadFileSuccess.id,
            eventType
          )
        );
      })
      .catch((e) => {
        emitter(uploadFileFail(e, uploadCode, dataSourceCode));
        emitter(
          sendNotification(
            notificationsMsgs.msgTitleUploadFile.id,
            notificationsMsgs.msgDescriptionUploadFileFailure.id,
            errorType
          )
        );
      });

    return () => {};
  });
}

export function* validatePathSaga({
  payload: { dataSourceCode, bucket, path, callbacks },
}) {
  const { response, error, status } = yield call(
    Api.data.validatePath,
    dataSourceCode,
    bucket,
    path
  );
  // if (response) {
  callbacks.resolve(status);
  // } else {
  //   callbacks.reject(error);
  // }
}

export function* watchValidatePath() {
  yield takeEvery(validatePath.getType(), validatePathSaga);
}

export function* deleteObjectSaga({
  payload: { dataSourceCode, bucket, path },
}) {
  const { response, error, status } = yield call(
    Api.data.deleteObject,
    dataSourceCode,
    bucket,
    path
  );
  if (error || status >= 400)
    yield put(
      sendNotification(
        'Delete failed',
        `Failed to delete file ${path}: ${error}`,
        errorType
      )
    );
  else
    yield put(
      fetchBucketContent(
        dataSourceCode,
        bucket,
        path.substring(0, path.lastIndexOf('/')).replace(/^\//, '')
      )
    );
}

export function* watchDeleteObject() {
  yield takeEvery(deleteObject.getType(), deleteObjectSaga);
}

export function* moveObjectSaga({
  payload: { dataSourceCode, srcBucket, srcPath, dstBucket, dstPath },
}) {
  const { response, error, status } = yield call(
    Api.data.moveObject,
    dataSourceCode,
    srcBucket,
    srcPath,
    dstBucket,
    dstPath
  );
  if (error || status >= 400)
    yield put(
      sendNotification(
        'Move failed',
        `Failed to move file ${srcPath}: ${error}`,
        errorType
      )
    );
  else
    yield put(
      fetchBucketContent(
        dataSourceCode,
        srcBucket,
        srcPath.substring(0, srcPath.lastIndexOf('/')).replace(/^\//, '')
      )
    );
}

export function* watchMoveObject() {
  yield takeEvery(moveObject.getType(), moveObjectSaga);
}
