import { RejectedFeaturesCassandraAugurSettings } from "./type";

export type SortByType = 'rejected' | 'feature';
export type OrderByType = 'ascending' | 'descending';


const sortByFeature = (col1: string, col2: string) => {
  const el1 = col1.toLowerCase();
  const el2 = col2.toLowerCase();

  if (el1 < el2) {
    return -1;
  }

  if (el1 > el2) {
    return 1;
  }

  return 0;
};

const sortByRejected = (col1: string, col2: string, value: RejectedFeaturesCassandraAugurSettings) => {
  const col1Rejected = value.rejected.includes(col1);
  const col2Rejected = value.rejected.includes(col2);
  const col1Forced = value.enforced.includes(col1);
  const col2Forced = value.enforced.includes(col2);

  if (col1Rejected === col2Rejected && col1Forced === col2Forced) {
    return 0;
  } else if (col1Rejected === col2Rejected) {
    return col1Forced ? 1 : -1;
  }

  return col1Rejected ? -1 : 1;
};


export const sortFeatures = (
  f1: string,
  f2: string,
  value: RejectedFeaturesCassandraAugurSettings,
  sort: SortByType,
  order: OrderByType
): number => {

  if (sort === 'rejected') {
    return order === 'descending'
      ? sortByRejected(f2, f1, value)
      : sortByRejected(f1, f2, value);
  }

  if (sort === 'feature') {
    return order === 'descending'
      ? sortByFeature(f2, f1)
      : sortByFeature(f1, f2);
  }

  return 0;
};
