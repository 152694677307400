import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getRunningApps,
  getRunningWorkbench,
  stopApp,
  stopWorkbench,
} from './api';
import {
  fetchRunningApps,
  fetchRunningAppsFail,
  fetchRunningAppsSuccess,
  fetchRunningWorkbench,
  fetchRunningWorkbenchFail,
  fetchRunningWorkbenchSuccess,
  stopAppFail,
  stopAppSuccess,
  stopWorkbenchFail,
  stopWorkbenchSuccess,
} from './actions';
import {
  FETCH_RUNNING_APPS,
  FETCH_RUNNING_WORKBENCHES,
  STOP_APP,
  STOP_WORKBENCH,
  StopAppAction,
  StopWorkbenchAction,
} from './actions.types';
// @ts-ignore
import { sendNotification } from '../../../redux/modules/notifications.module';
// @ts-ignore
import {
  error as errorType,
  event as eventType,
} from '../../../core/notifications';
import * as selector from '../../../redux/selectors/user.selector';
import { clearWorkbenchCache } from '../../../redux/workbench/modules/sessions.module';

export function* fetchRunningWorkbenchSaga() {
  const { response, error } = yield call(getRunningWorkbench);

  if (response) {
    yield put(fetchRunningWorkbenchSuccess(response));
  } else {
    yield put(fetchRunningWorkbenchFail(error));
  }
}

export function* watchFetchRunningWorkbench() {
  yield takeEvery(FETCH_RUNNING_WORKBENCHES, fetchRunningWorkbenchSaga);
}

export function* stopWorkbenchSaga({
  payload: { userCodeLower },
}: StopWorkbenchAction) {
  const { error } = yield call(stopWorkbench, userCodeLower);
  if (!error) {
    // check if the stopped workbench belongs to the current user, if true delete cache
    const currentUser = yield select(selector.currentUser);
    if (userCodeLower === currentUser.id) yield put(clearWorkbenchCache());

    yield put(stopWorkbenchSuccess(userCodeLower));
    yield put(
      sendNotification(
        'notifications.title.stop_workbench_success',
        // @ts-ignore
        'notifications.description.stop_workbench_success',
        // @ts-ignore
        eventType
      )
    );
    yield put(fetchRunningWorkbench());
  } else {
    yield put(stopWorkbenchFail(userCodeLower, error));
    yield put(
      sendNotification(
        'notifications.title.stop_workbench_fail',
        // @ts-ignore
        'notifications.description.stop_workbench_fail',
        // @ts-ignore
        errorType
      )
    );
    yield put(fetchRunningWorkbench());
  }
}

export function* watchStopWorkbench() {
  yield takeEvery(STOP_WORKBENCH, stopWorkbenchSaga);
}

export function* fetchRunningAppsSaga() {
  const { response, error } = yield call(getRunningApps);

  if (response) {
    yield put(fetchRunningAppsSuccess(response));
  } else {
    yield put(fetchRunningAppsFail(error));
  }
}

export function* watchFetchRunningApps() {
  yield takeEvery(FETCH_RUNNING_APPS, fetchRunningAppsSaga);
}

export function* stopAppSaga({
  payload: { userCodeLower, appVersionCodeLower },
}: StopAppAction) {
  const { error } = yield call(stopApp, userCodeLower, appVersionCodeLower);
  if (!error) {
    yield put(stopAppSuccess(userCodeLower, appVersionCodeLower));
    yield put(
      sendNotification(
        'notifications.title.stop_app_success',
        // @ts-ignore
        'notifications.description.stop_app_success',
        // @ts-ignore
        eventType
      )
    );
    yield put(fetchRunningApps());
  } else {
    yield put(stopAppFail(userCodeLower, appVersionCodeLower, error));
    yield put(
      sendNotification(
        'notifications.title.stop_app_fail',
        // @ts-ignore
        'notifications.description.stop_app_fail',
        // @ts-ignore
        errorType
      )
    );
    yield put(fetchRunningApps());
  }
}

export function* watchStopApp() {
  yield takeEvery(STOP_APP, stopAppSaga);
}
