import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface Props {
  /** The unique identifier e.g. keyspace name */
  title: string;
  /** A more user-relevant title to display e.g. the augur name */
  displayTitle?: string;
  /** Shown below the displayName and title.
   * For example relevant for the S3 buckets, shows the creation date */
  subInfo?: string;
  /** List of whether the permission is granted or not - ordering depends on the permissionColumns prop of the PermissionTable */
  permissionList: boolean[];
  withLink: boolean;
  /** Target URL of the link */
  linkTo?: string;
  /** On Click listener */
  onClick?: () => void;
}

const PermissionRow: React.FC<Props> = ({
  displayTitle,
  title,
  subInfo,
  permissionList,
  withLink,
  linkTo,
  onClick,
}) => {
  const history = useHistory();
  const handleOnClick = useCallback(
    () => history.push(linkTo),
    [history, linkTo]
  );
  return (
    // Don't use <Link> since tables don't seem to deal well with <a> especially for a whole row
    <tr
      onClick={() => {
        if (withLink) {
          handleOnClick();
        }
        onClick?.();
      }}
      style={withLink ? { cursor: 'pointer' } : { cursor: 'default' }}
    >
      <td className={styles.resourceCell}>
        {displayTitle && (
          <span className={styles.displayName}>{displayTitle}</span>
        )}
        <span className={styles.title}>{title}</span>
        {subInfo && <span className={styles.subInfo}>{subInfo}</span>}
      </td>
      {permissionList.map((p, i) => (
        <td key={i}>
          <div className={styles.permissionCell}>
            <span
              title={p ? 'Permissions' : 'No Permissions'}
              className={classNames(styles.permissionMark, {
                [styles.enabledMark]: p,
                [styles.disabledMark]: !p,
              })}
            />
          </div>
        </td>
      ))}
    </tr>
  );
};

export default PermissionRow;
