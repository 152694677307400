import * as d3 from 'd3';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC } from 'react';

import Bar from './Bar';
import { PerformanceDriftValueFormat } from '../type';

type Props = {
  data: [number, number?][];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  performanceDriftValueFormat: PerformanceDriftValueFormat;
};

const Bars: FC<Props> = (props) => {
  const { data, xScale, yScale, performanceDriftValueFormat } = props;

  xScale.domain(data.map(([x]) => +x));
  yScale.domain([0, d3.max(data, ([, y]) => y)!]);
  return (
    <g>
      {data.map((item) => (
        <Bar
          performanceDriftValueFormat={performanceDriftValueFormat}
          key={`${item[0]}_${item[1]}`}
          xScale={xScale}
          yScale={yScale}
          item={item}
        />
      ))}
    </g>
  );
};

export default Bars;
