import { Keyspace } from 'common/dist/types/dataManagement/cassandra';
import { DSType } from 'common/dist/types/dataManagement/dataSource';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchCassandraKeyspaces } from '../../../../../redux/modules/data.cassandra.module';
import { RootState } from '../../../../../store/store';
import ResourceList from '../../../../organisms/permissions/resouce-list/ResourceList';
import { bucketOrKeyspaceRoute } from '../DataSourcePermissions';

type Props = {};

const CassandraPermissions: FC<Props> = () => {
  const { dataSourceCode } = useParams<{
    dataSourceCode: string;
  }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCassandraKeyspaces(dataSourceCode));
  }, [dispatch]);

  const keyspaces = useSelector<RootState, Keyspace[]>(
    (state) => state.data.cassandra[dataSourceCode]?.keyspaces?.data
  );

  const keyspacesLoading = useSelector<RootState, boolean>(
    (state) => state.data.cassandra[dataSourceCode]?.keyspaces?.loading
  );

  return (
    <ResourceList
      headlines={[
        {
          headlineId: 'keyspaceName',
          label: {
            id: 'no-id',
            defaultMessage: 'Keyspace Name',
          },
        },
      ]}
      entries={(keyspaces || []).map((keyspace) => {
        return {
          linkTo: bucketOrKeyspaceRoute(
            DSType.Cassandra,
            dataSourceCode,
            keyspace.name
          ),
          columnValues: {
            keyspaceName: keyspace.name,
          },
        };
      })}
      isLoading={(!keyspaces || keyspaces.length === 0) && keyspacesLoading}
    />
  );
};

export default CassandraPermissions;
