import ResourceRepresentation from '@keycloak/keycloak-admin-client/lib/defs/resourceRepresentation';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import HttpStatusCodes from 'common/dist/constants/httpStatusCodes';
import {
  cassandraResourceType,
  s3ResourceType,
} from 'common/dist/constants/keycloak';
import notificationsMsgs from 'common/dist/messages/notifications';
import { Keyspace } from 'common/dist/types/dataManagement/cassandra';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { Bucket } from 'common/dist/types/dataManagement/s3';
import { PostBucketRequestBody } from 'common/dist/types/requestBodies/data/s3';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import qs from 'qs';

import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  postApiRequest,
} from './_tools';
import { parquetPreviewToCassandraDataPreview } from '../../components/dataManagement/s3/table-browser/TableBrowser.container';
import {
  nameToTableName,
  objectIsTable,
  s3ObjectToTableObject,
} from '../../components/dataManagement/util';
import { sendNotification } from '../../redux/modules/notifications.module';
import {
  CassandraDataPreview,
  ParquetPreview,
  S3Object,
  Table,
} from '../../store/dataManagement/state.types';
import { useAppDispatch } from '../../store/store';
import { error as errorType, event as eventType } from '../notifications';

// TODO replace with direct calls to data-management-api when authorization is implemented
export function fetchDataSources(): CompletedApiRequest<DataSource[]> {
  return apiRequest(`/api/data`);
}

export function fetchDataSourceSettings(dataSourceCode) {
  return apiRequest(`/api/data/datasource/${dataSourceCode}/settings`);
}

export function addDataSource(dataSource) {
  const body = {
    code: dataSource.code,
    name: dataSource.name,
    ds_type: dataSource.ds_type,
    role: dataSource.role,
    settings: dataSource.settings,
  };
  return postApiRequest(`/api/data`, body);
}

export function deleteDataSource(dataSourceCode) {
  return deleteApiRequest(`/api/data/datasource/${dataSourceCode}`);
}

export function updateDataSource(dataSourceCode, dataSource) {
  const body = {
    code: dataSource.code,
    name: dataSource.name,
    ds_type: dataSource.ds_type,
    role: dataSource.role,
    settings: dataSource.settings,
  };
  return postApiRequest(`/api/data/datasource/${dataSourceCode}/update`, body);
}

export const dataSourceKeys = {
  all: () => ['dataSources'] as const,
};

export function useDataSources(): UseQueryResult<DataSource[]> {
  const key = dataSourceKeys.all();
  return useQuery(key, () => fetchQueryFn(key, () => fetchDataSources()), {
    keepPreviousData: true,
  });
}

export function useDataSource(dataSourceCode?: string): DataSource | undefined {
  const dataSources = useDataSources();
  return dataSources.data?.find((ds) => ds.code === dataSourceCode);
}

/**
 * Call directly (there's an extra ingress listening for /data) the data management api and request the
 * Cassandra Keyspaces
 * @param dataSourceCode
 * @returns {*}
 */
export function fetchCassandraKeyspaces(
  dataSourceCode: string
): CompletedApiRequest<Keyspace[]> {
  return apiRequest(`/dataman/cassandra/${dataSourceCode}/keyspaces`);
}

export const keyspacesKeys = {
  all: () => ['keyspaces'] as const,
  some: (dataSourceCode: string) =>
    [...keyspacesKeys.all(), dataSourceCode] as const,
  permission: (dataSourceCode: string, habitatCode: string) =>
    [...keyspacesKeys.all(), dataSourceCode, habitatCode] as const,
};

export function useKeyspaces(
  dataSourceCode: string
): UseQueryResult<Keyspace[]> {
  const key = keyspacesKeys.some(dataSourceCode);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => fetchCassandraKeyspaces(dataSourceCode)),
    {
      keepPreviousData: true,
    }
  );
}

export function fetchCassandraKeyspacesPermission(
  dataSourceCode: string,
  habitatCode: string
): CompletedApiRequest<Keyspace[]> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(
    `/api/data/cassandra/dataSourceCode/${dataSourceCode}/keyspaces${query}`
  );
}

export function useKeyspacesWithPermission(
  dataSourceCode: string,
  habitatCode: string | undefined
): UseQueryResult<Keyspace[], Error> {
  const key = keyspacesKeys.permission(dataSourceCode, habitatCode);
  return useQuery(
    key,
    () => {
      const data = fetchQueryFn(key, () =>
        fetchCassandraKeyspacesPermission(dataSourceCode, habitatCode)
      );

      //Will check if the response is of type {response:{error:string},status:number}
      if (
        data &&
        data.hasOwnProperty('status') &&
        (data as unknown as ApiError).status === HttpStatusCodes.FORBIDDEN
      ) {
        //@ts-ignore
        throw new Error(data.response.error);
      } else {
        return data;
      }
    },
    {
      enabled: !!dataSourceCode,
    }
  );
}

export function fetchCassandraPermissions(
  dataSourceCode: string
): CompletedApiRequest<{ [resourceName: string]: string[] }> {
  const body: { resources: ResourceRepresentation[] } = {
    resources: [{ type: cassandraResourceType(dataSourceCode) }],
  };
  return postApiRequest(`/api/permissions/evaluate`, body);
}

export function fetchCassandraCredentials(
  dataSourceCode: string
): CompletedApiRequest<{
  username: string;
  password: string;
}> {
  return apiRequest(`/dataman/cassandra/${dataSourceCode}/credentials`);
}

/**
 * Call directly (there's an extra ingress listening for /data) the data management api and request the
 * Cassandra Tables for a given Keyspace
 * @param dataSourceCode
 * @param keyspaceName
 * @returns {*}
 */
export function fetchCassandraTables(
  dataSourceCode: string,
  keyspaceName: string
): CompletedApiRequest<Table[]> {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/tables`
  );
}

export const cassandraTablesKeys = {
  all: () => ['cassandraTables'] as const,
  some: (dataSourceCode: string, keyspaceName: string) =>
    [...cassandraTablesKeys.all(), dataSourceCode, keyspaceName] as const,
  permissions: (
    habitatCode: string,
    dataSourceCode: string,
    keyspaceName: string
  ) =>
    [
      ...cassandraTablesKeys.all(),
      habitatCode,
      dataSourceCode,
      keyspaceName,
    ] as const,
};

export function useCassandraTables(
  dataSourceCode?: string,
  keyspaceName?: string
): UseQueryResult<Table[]> {
  const key = cassandraTablesKeys.some(dataSourceCode, keyspaceName);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        fetchCassandraTables(dataSourceCode, keyspaceName)
      ),
    {
      keepPreviousData: true,
      enabled: Boolean(dataSourceCode && keyspaceName),
    }
  );
}

export function fetchCassandraKeyspaceContentForHabitat(
  habitatCode: string,
  dataSourceCode: string,
  keyspaceName: string
): CompletedApiRequest<Table[]> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(
    `/api/data/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/tables${query}`
  );
}

export function useCassandraTablesForHabitat(
  habitatCode: string | undefined,
  dataSourceCode: string | undefined,
  keyspaceName: string | undefined
): UseQueryResult<Table[], Error> {
  const key = cassandraTablesKeys.permissions(
    habitatCode,
    dataSourceCode,
    keyspaceName
  );
  const enabled = Boolean(dataSourceCode && keyspaceName);
  return useQuery(
    key,
    async () => {
      return await fetchQueryFn(key, () =>
        fetchCassandraKeyspaceContentForHabitat(
          habitatCode,
          dataSourceCode,
          keyspaceName
        )
      );
    },
    {
      enabled: Boolean(enabled),
    }
  );
}

export function fetchCassandraTableSample(
  dataSourceCode: string,
  keyspaceName: string,
  tableName: string
): CompletedApiRequest<CassandraDataPreview> {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/table/${tableName}`
  );
}

export const cassandraTablesSamplesKeys = {
  all: () => ['cassandraTableSamples'] as const,
  some: (dataSourceCode: string, keyspaceName: string, tableName: string) =>
    [
      ...cassandraTablesSamplesKeys.all(),
      dataSourceCode,
      keyspaceName,
      tableName,
    ] as const,
};

export function useCassandraTableSamples(
  dataSourceCode?: string,
  keyspaceName?: string,
  tableName?: string
): UseQueryResult<CassandraDataPreview, Error> {
  const key = cassandraTablesSamplesKeys.some(
    dataSourceCode,
    keyspaceName,
    tableName
  );
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        fetchCassandraTableSample(dataSourceCode, keyspaceName, tableName)
      ),
    {
      keepPreviousData: true,
      enabled: Boolean(dataSourceCode && keyspaceName && tableName),
    }
  );
}

export function requestCommitInfo(dataSourceCode, uploadCode) {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/upload/${uploadCode}/info`
  );
}

export function commitUpload(dataSourceCode, body) {
  return postApiRequest(`/dataman/cassandra/${dataSourceCode}/commit`, body);
}

export function fetchS3Preview(
  dataSourceCode: string,
  path: string
): CompletedApiRequest<ParquetPreview> {
  return apiRequest(`/dataman/s3/${dataSourceCode}/preview/${path}`);
}

export const s3TablesSamplesKeys = {
  all: () => ['s3TableSamples'] as const,
  some: (dataSourceCode: string, bucketName: string, tableName: string) =>
    [
      ...s3TablesSamplesKeys.all(),
      dataSourceCode,
      bucketName,
      tableName,
    ] as const,
};

export function useS3TableSamples(
  dataSourceCode?: string,
  bucketName?: string,
  tableName?: string
): UseQueryResult<CassandraDataPreview, Error> {
  const key = s3TablesSamplesKeys.some(dataSourceCode, bucketName, tableName);
  return useQuery(
    key,
    async () => {
      const path = `${bucketName}/${nameToTableName(tableName)}`;
      const data = await fetchQueryFn(key, () =>
        fetchS3Preview(dataSourceCode, path)
      );
      return parquetPreviewToCassandraDataPreview(data);
    },
    {
      keepPreviousData: true,
      enabled: Boolean(dataSourceCode && bucketName && tableName),
    }
  );
}

export function fetchS3Buckets(
  dataSourceCode: string
): CompletedApiRequest<Bucket[]> {
  return apiRequest(`/dataman/s3/${dataSourceCode}/buckets`);
}

export function fetchS3BucketsPermission(
  habitatCode: string,
  dataSourceCode: string
): CompletedApiRequest<string> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(
    `/api/data/s3/dataSourceCode/${dataSourceCode}/buckets${query}`
  );
}

export const bucketsKeys = {
  all: () => ['buckets'] as const,
  some: (dataSourceCode: string) =>
    [...bucketsKeys.all(), dataSourceCode] as const,
  permission: (dataSourceCode: string, habitatCode: string) =>
    [...bucketsKeys.all(), dataSourceCode, habitatCode] as const,
  create: (dataSourceCode: string) => [
    ...bucketsKeys.some(dataSourceCode),
    'create',
  ],
};

export function useBucketsWithPermission(
  dataSourceCode: string | undefined,
  habitatCode: string | undefined
): UseQueryResult<Bucket[], Error> {
  const key = bucketsKeys.permission(dataSourceCode, habitatCode);
  return useQuery(
    key,
    () => {
      const data = fetchQueryFn(key, () =>
        fetchS3BucketsPermission(habitatCode, dataSourceCode)
      );

      //Will check if the response is of type {response:{error:string},status:number}
      if (
        data &&
        data.hasOwnProperty('status') &&
        (data as unknown as ApiError).status === HttpStatusCodes.FORBIDDEN
      ) {
        //@ts-ignore
        throw new Error(data.response.error);
      } else {
        return data;
      }
    },
    {
      enabled: !!dataSourceCode,
    }
  );
}

export function useBuckets(dataSourceCode?: string): UseQueryResult<Bucket[]> {
  const key = bucketsKeys.some(dataSourceCode);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => fetchS3Buckets(dataSourceCode)),
    {
      keepPreviousData: true,
      enabled: !!dataSourceCode,
    }
  );
}

export function postS3Bucket(
  dataSourceCode: string,
  body: PostBucketRequestBody
) {
  return postApiRequest(
    `/api/data/datasource/${dataSourceCode}/s3/create`,
    body
  );
}

export function useCreateS3Bucket(dataSourceCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const key = bucketsKeys.create(dataSourceCode);
  return useMutation(
    key,
    (bucketName: string) =>
      fetchQueryFn(key, () => postS3Bucket(dataSourceCode, { bucketName })),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: bucketsKeys.some(dataSourceCode),
        });
      },
      onSuccess: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleBucketCreateSuccess.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionBucketCreateSuccess.id,
            eventType
          )
        );
      },
      onError: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleBucketCreateFailure.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionBucketCreateFailure.id,
            errorType
          )
        );
      },
    }
  );
}

export function fetchS3BucketContent(
  dataSourceCode: string,
  bucket: string,
  bucketPath: string
): CompletedApiRequest<S3Object[]> {
  return apiRequest(
    `/dataman/s3/${dataSourceCode}/bucket/${bucket}/${bucketPath}`
  );
}

export function fetchS3BucketContentForHabitat(
  habitatCode: string,
  dataSourceCode: string,
  bucket: string,
  bucketPath: string
): CompletedApiRequest<S3Object[]> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(
    `/api/data/s3/${dataSourceCode}/bucket/${bucket}/${bucketPath}${query}`
  );
}

export function useS3FilesForHabitat(
  habitatCode: string,
  dataSourceCode: string | undefined,
  bucketName: string | undefined,
  bucketPath = '',
  fileSuffix = ''
): UseQueryResult<S3Object[], Error> {
  const key = s3FilesKeys.permissions(
    habitatCode,
    dataSourceCode,
    bucketName,
    bucketPath
  );
  const enabled = Boolean(dataSourceCode && bucketName);
  return useQuery(
    key,
    async () => {
      const data = await fetchQueryFn(key, () =>
        fetchS3BucketContentForHabitat(
          habitatCode,
          dataSourceCode,
          bucketName,
          bucketPath
        )
      );
      //If its a S3Object, we want to prepare the array
      return data?.filter(
        (file) =>
          (file.objectType === 'file' && file.name.endsWith(fileSuffix)) ||
          file.objectType === 'directory'
      );
    },
    {
      enabled: Boolean(enabled),
    }
  );
}

export const s3TablesKeys = {
  all: () => ['s3Tables'] as const,
  some: (dataSourceCode: string, bucketName: string) =>
    [...s3TablesKeys.all(), dataSourceCode, bucketName] as const,
};
export const s3FilesKeys = {
  all: () => ['s3Files'] as const,
  some: (dataSourceCode: string, bucketName: string, bucketPath: string) =>
    [...s3FilesKeys.all(), dataSourceCode, bucketName, bucketPath] as const,
  permissions: (
    habitatCode: string,
    dataSourceCode: string,
    bucketName: string,
    bucketPath: string
  ) =>
    [
      ...s3FilesKeys.all(),
      habitatCode,
      dataSourceCode,
      bucketName,
      bucketPath,
    ] as const,
};

/**
 * Tables in S3 are top-level parquet files
 * @param dataSourceCode
 * @param bucketName
 */
export function useS3Tables(
  dataSourceCode?: string,
  bucketName?: string
): UseQueryResult<Table[]> {
  const key = s3TablesKeys.some(dataSourceCode, bucketName);
  return useQuery(
    key,
    async () => {
      const data = await fetchQueryFn(key, () =>
        fetchS3BucketContent(dataSourceCode, bucketName, '')
      );
      return data.filter(objectIsTable).map(s3ObjectToTableObject);
    },
    {
      keepPreviousData: true,
      enabled: Boolean(dataSourceCode && bucketName),
    }
  );
}

/**
 * Fetch S3 Files from a defined bucket
 * @param dataSourceCode
 * @param bucketName
 */
export function useS3Files(
  dataSourceCode?: string,
  bucketName?: string,
  bucketPath = '',
  fileSuffix = ''
): UseQueryResult<S3Object[], Error> {
  const key = s3FilesKeys.some(dataSourceCode, bucketName, bucketPath);
  return useQuery(
    key,
    async () => {
      const data = await fetchQueryFn(key, () =>
        fetchS3BucketContent(dataSourceCode, bucketName, bucketPath)
      );
      return data.filter(
        (file) =>
          (file.objectType === 'file' && file.name.endsWith(fileSuffix)) ||
          file.objectType === 'directory'
      );
    },
    {
      enabled: Boolean(dataSourceCode && bucketName),
    }
  );
}

export function fetchS3Permissions(
  dataSourceCode: string
): CompletedApiRequest<{ [resourceName: string]: string[] }> {
  const body: { resources: ResourceRepresentation[] } = {
    resources: [{ type: s3ResourceType(dataSourceCode) }],
  };
  return postApiRequest(`/api/permissions/evaluate`, body);
}

export function fetchS3Credentials(
  dataSourceCode: string
): CompletedApiRequest<{
  accessKey: string;
  secretKey: string;
}> {
  return apiRequest(`/dataman/s3/${dataSourceCode}/credentials`);
}

export function validatePath(dataSourceCode, bucket, bucketPath) {
  return apiRequest(
    `/dataman/s3/${dataSourceCode}/exists/bucket/${bucket}/${bucketPath}`
  );
}

export function deleteObject(dataSourceCode, bucket, path) {
  return deleteApiRequest(
    `/dataman/s3/${dataSourceCode}/bucket/${bucket}/${path.replace(/^\//, '')}`
  );
}

export function moveObject(
  dataSourceCode: string,
  srcBucket: string,
  srcPath: string,
  dstBucket: string,
  dstPath: string
) {
  return postApiRequest(
    `/dataman/s3/${dataSourceCode}/move/bucket/${srcBucket}/${srcPath.replace(
      /^\//,
      ''
    )}`,
    { newBucket: dstBucket, newPath: dstPath }
  );
}
