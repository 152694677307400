import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import AddEntry from './AddEntry';
import EntryEditable from './EntryEditable';
import EntryStatic from './EntryStatic';
import styles from './styles.module.scss';
import { AugurCategory, AugurMenuCategory } from './types';

type Props = {
  category: AugurMenuCategory;
  isEditMode?: boolean;
  /** ID of the selected entry */
  selectedEntry: string;
  /** Callback if an entry is selected */
  handleSelect?: (selectedEntry: string, category: AugurCategory) => void;
  addEntry: (index: number, categoryId: string) => void;
  deleteEntry: (entryId: string, categoryId: string) => void;
  /** The entries elements have to be counted independent of the categories. "startIndex" is the index of the first entry in this category */
  startIndex: number;
  /** The entry that is currently being edited (the text input element is shown instead of the static label) */
  editingEntry: string | null;
  /** Set the editing entry Id*/
  setEditingEntry: (entryId: string | null) => void;
  renameEntry: (entryId: string, categoryId: string, newName: string) => void;
  isLoading?: boolean;
};

const Category: FC<Props & RouteComponentProps> = (props) => {
  const {
    category,
    isEditMode,
    selectedEntry,
    handleSelect,
    addEntry,
    startIndex,
    deleteEntry,
    editingEntry,
    setEditingEntry,
    renameEntry,
    isLoading,
  } = props;

  // --- add observer to detect when the headline is stuck (when "position: sticky" is actually applied)
  const headlineRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (headlineRef.current) {
      const observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle(styles.isStuck, e.intersectionRatio < 1),
        { threshold: [1] }
      );
      observer.observe(headlineRef.current);
    }
  }, [headlineRef.current]);
  // ---

  const renderHeadline = () => {
    if (!category.title) return null;
    return (
      <div className={styles.headline} ref={headlineRef}>
        <FormattedMessage
          id={category.title?.id || 'no-id'}
          defaultMessage={category.title?.defaultMessage}
        />
      </div>
    );
  };

  const renderEditable = () => {
    const amountEntries = (category.entries || []).length;
    return (
      <div className={styles.category}>
        {renderHeadline()}
        <div className={styles.entriesContainer}>
          <Droppable droppableId={category.id} type={category.type}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classNames(styles.droppable, {
                  [styles.isDraggingOver]: snapshot.isDraggingOver,
                })}
              >
                {(category?.entries || []).map((entry, i) => {
                  if (entry.isFixedAtTop) {
                    return (
                      <EntryStatic
                        key={entry.id}
                        entry={entry}
                        categoryId={category.id}
                        index={startIndex + i}
                        isActive={selectedEntry === entry.id}
                        handleSelect={() =>
                          handleSelect && handleSelect(entry.id, category.id)
                        }
                        isDisabled={category.isDisabled || entry.isDisabled}
                      />
                    );
                  } else {
                    return (
                      <>
                        {
                          <AddEntry
                            addEntry={() => addEntry(i - 1, category.id)}
                          />
                        }
                        <EntryEditable
                          entry={entry}
                          categoryId={category.id}
                          index={startIndex + i}
                          isActive={selectedEntry === entry.id}
                          handleSelect={() =>
                            handleSelect && handleSelect(entry.id, category.id)
                          }
                          deleteEntry={() => deleteEntry(entry.id, category.id)}
                          isEditing={editingEntry === entry.id}
                          setEditing={(isEditing) =>
                            setEditingEntry(isEditing ? entry.id : null)
                          }
                          renameEntry={(newName: string) =>
                            renameEntry(entry.id, category.id, newName)
                          }
                          isDisabled={category.isDisabled || entry.isDisabled}
                          categoryType={category.type}
                        />
                      </>
                    );
                  }
                })}
                <AddEntry
                  addEntry={() => addEntry(amountEntries - 1, category.id)}
                />
                {provided.placeholder}
                <hr className={styles.categoryHr} />
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  };

  const renderStatic = () => {
    return (
      <div className={styles.category}>
        {renderHeadline()}
        <div className={styles.entriesContainer}>
          {(category?.entries || []).map((entry, i) => {
            return (
              <EntryStatic
                key={entry.id}
                entry={entry}
                categoryId={category.id}
                index={startIndex + i}
                isActive={selectedEntry === entry.id}
                handleSelect={() =>
                  handleSelect && handleSelect(entry.id, category.id)
                }
                isDisabled={category.isDisabled || entry.isDisabled}
              />
            );
          })}
        </div>
        <hr className={styles.categoryHr} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.category}>
        {renderHeadline()}
        <div className={styles.entriesContainer}>
          {[0, 1].map(() => (
            <div className={styles.entry}>
              <div className={styles.loadingBar} />
            </div>
          ))}
        </div>
        <hr className={styles.categoryHr} />
      </div>
    );
  };

  if (isLoading) {
    return renderLoading();
  } else if (isEditMode && !category.isFixed) {
    return renderEditable();
  } else {
    return renderStatic();
  }
};

export default withRouter(Category);
