import { axisBottom } from 'd3-axis';
import { ScaleBand } from 'd3-scale';
import { select } from 'd3-selection';
import React, { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  scale: ScaleBand<number>;
  scaleCount: number;
};

const AxisBottom: FC<Props> = (props) => {
  const { scale, scaleCount } = props;

  const intl = useIntl();

  const ticksRef = useRef<SVGGElement>(null);

  useEffect(() => {
    renderAxisTicks();
  }, [scale]);

  const renderAxisTicks = () => {
    const axis = axisBottom(scale).tickFormat((domainValue: number) => {
      const date = new Date(domainValue);
      return intl.formatDate(date);
    });

    if (ticksRef.current) {
      select(ticksRef.current).call(axis);

      select(ticksRef.current)
        .selectAll('text')
        .attr('transform', 'translate(-10, 20) rotate(-30)');
    }
  };

  return (
    <g className='chartAxis'>
      <g className='bottomTicks' ref={ticksRef} />
    </g>
  );
};

export default AxisBottom;
