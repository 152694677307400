import React, { Component } from 'react';
import DropdownSelectInput, {
  Option,
} from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

const storedHabitatInfo = (summary, habitatCode) => {
  if (!habitatCode) return {};

  const habitat = summary.find((habitat) => habitat.code === habitatCode);
  if (!habitat) return {};

  return {
    code: habitat.code,
    name: habitat.name,
  };
};

interface RepositoryStepHabitatProps {
  summaryLoading?: boolean;
  /** Summary of all Habitats / Datapools */
  summary?: {
    /** Name of the Habitat */
    name: string;
    /** Code of the Habitat */
    code: string;
  }[];
  /** Redux action to fetch the summary of Habitats / Datapools */
  fetchSummary(...args: unknown[]): unknown;
  meta: {
    touched?: boolean;
    asyncValidating?: boolean;
    valid?: boolean;
    error?: {
      id: string;
      defaultMessage: string;
    };
  };
  input?: {
    value?: {
      code: string;
      [unknown: string]: unknown;
    };
    onChange(...args: unknown[]): unknown;
    onFocus(...args: unknown[]): unknown;
    onBlur(...args: unknown[]): unknown;
  };
}

export default class RepositoryStepHabitat extends Component<RepositoryStepHabitatProps> {
  componentDidMount() {
    const { summary, fetchSummary } = this.props;
    if (!summary) fetchSummary();
  }

  render() {
    const {
      input: { onChange, value, onFocus, onBlur },
      meta: { touched, valid, error },
      summary,
      summaryLoading,
    } = this.props;

    const options = !summary
      ? []
      : summary.map((habitat) => ({
          label: habitat.name,
          value: habitat.code,
        }));

    return (
      <div className={'GenericFormStep--field'}>
        <DropdownSelectInput
          touched={touched}
          error={error}
          valid={touched && valid}
          disabled={false}
          id={'habitat'}
          name={'habitat'}
          placeholder={{
            id: 'habitat',
            defaultMessage: 'Select Habitat',
          }}
          value={options.find((o) => o.value === value?.code)}
          onChange={(option: Option) =>
            onChange(storedHabitatInfo(summary, option.value))
          }
          isLoading={summaryLoading}
          options={options}
          autoSelectIfSingle
          scrollMenuIntoView={false}
        />
      </div>
    );
  }
}
