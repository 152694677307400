import { defineMessages } from 'react-intl';

export default defineMessages({
  dropzoneUploadFile: {
    id: 'atoms.dropzone.upload_file',
    defaultMessage: 'Upload File',
  },
  dropzoneUploadFiles: {
    id: 'atoms.dropzone.upload_files',
    defaultMessage: 'Upload Files',
  },
  dropzoneUploadFolder: {
    id: 'atoms.dropzone.upload_folder',
    defaultMessage: 'Upload Folder',
  },
});
