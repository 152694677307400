import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  CassandraTableColumnSelectAugurSettings,
  CassandraTableColumnSelectValidationError,
} from './type';

export const validateCassandraTableColumnSelect = (
  value: CassandraTableColumnSelectAugurSettings
): DeepPartial<CassandraTableColumnSelectValidationError> | undefined => {
  const error: DeepPartial<CassandraTableColumnSelectValidationError> = {};

  if (!value || value.length === 0) {
    error.global = 'Please select a column';
  }

  return cleanErrorObject(error);
};
