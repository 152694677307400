import { Scope } from '../types/keycloak';

export const adminRoleName = 'altasigma-admin';

export const habitatResourceName = (habitatCode: string): string =>
  `habitat:${habitatCode}`;
export const habitatGroupName = (
  habitatCode: string,
  permission: 'view' | 'edit'
): string => `habitat:${habitatCode}:${permission}`;
export const habitatFromGroupName = (groupName: string): string =>
  isHabitatGroup(groupName) ? groupName.split(':')[1] : groupName;
const habitatGroupRegex = /habitat:[0-9A-z]+:(view|edit)/;
export const isHabitatGroup = (groupName: string): boolean =>
  habitatGroupRegex.test(groupName);

export const appResourceName = (appCode: string): string =>
  `${RESOURCE_TYPE_APP}:${appCode}`;

export const s3BucketResourceName = (
  dataSourceCode: string,
  bucketName: string
): string =>
  `${RESOURCE_TYPE_S3_BUCKET_PREFIX}:${dataSourceCode}:${bucketName}`;

export const cassandraResourceType = (dataSourceCode: string): string =>
  `${RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX}:${dataSourceCode}`;
/** Extract datapool_0qty21s from cassandra:GEYDAMBQGA:datapool_0qty21s */
export const keyspaceNameFromResource = (resource: string): string =>
  resource.split(':')[2];

export const s3ResourceType = (dataSourceCode: string): string =>
  `${RESOURCE_TYPE_S3_BUCKET_PREFIX}:${dataSourceCode}`;
/** Extract test-bucket from s3-bucket:GEYDAMBQGA:test-bucket */
export const bucketFromResource = (resource: string): string =>
  resource.split(':')[2];

/**
 * Allowed scopes for the dashboard components
 */
export const DASHBOARD_COMPONENT_SCOPE_IDS = {
  ACCESS: 'dashboard-component:access',
};

export const DASHBOARD_COMPONENT_SCOPES: Scope[] = [
  { id: DASHBOARD_COMPONENT_SCOPE_IDS.ACCESS, displayName: 'Access' },
];

/**
 * Allowed scopes for the Habitats
 */
export const HABITAT_SCOPES = {
  VIEW: 'scopes:view',
  EDIT: 'scopes:edit',
} as const;
export type HabitatScopes =
  (typeof HABITAT_SCOPES)[keyof typeof HABITAT_SCOPES];

/**
 * Allowed scopes for the Cassandra Keyspaces
 */
export const CASSANDRA_KEYSPACE_SCOPE_IDS = {
  CREATE: 'cassandra:create',
  SELECT: 'cassandra:select',
  ALTER: 'cassandra:alter',
  DROP: 'cassandra:drop',
  EXECUTE: 'cassandra:execute',
  MODIFY: 'cassandra:modify',
};

export const CASSANDRA_KEYSPACE_SCOPES: Scope[] = [
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.SELECT, displayName: 'Select' },
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.CREATE, displayName: 'Create' },
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.ALTER, displayName: 'Alter' },
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.DROP, displayName: 'Drop' },
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.EXECUTE, displayName: 'Execute' },
  { id: CASSANDRA_KEYSPACE_SCOPE_IDS.MODIFY, displayName: 'Modify' },
];

export const RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX = 'dashboard-component';

export const RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX =
  'model-management';
export const RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX = 'apps';
export const RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX =
  'workbench-collab';
export const RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX = 'orchestration';
export const RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX = 'data-management';

export type GENERAL_RESOURCE_SUFFIXES =
  | typeof RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
  | typeof RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX
  | typeof RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  | typeof RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX
  | typeof RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX;

/**
 * Allowed scopes for the S3 Buckets
 */
export const S3_BUCKET_SCOPE_IDS = {
  READ: 's3:read',
  WRITE: 's3:write',
};

export const S3_BUCKET_SCOPES: Scope[] = [
  { id: S3_BUCKET_SCOPE_IDS.READ, displayName: 'Read' },
  { id: S3_BUCKET_SCOPE_IDS.WRITE, displayName: 'Write' },
];

export const RESOURCE_TYPE_HABITAT_PREFIX = 'habitat';
export const RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX = 'cassandra-keyspace';
export const RESOURCE_TYPE_S3_BUCKET_PREFIX = 's3-bucket';
export const RESOURCE_TYPE_APP = 'app';

/**
 * Mapping of resource type prefixes to the available scopes
 */
export const RESOURCE_TYPE_PREFIX_SCOPE_MAP = {
  [RESOURCE_TYPE_APP]: Object.values(DASHBOARD_COMPONENT_SCOPE_IDS),
  [RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX]: Object.values(
    CASSANDRA_KEYSPACE_SCOPE_IDS
  ),
  [RESOURCE_TYPE_HABITAT_PREFIX]: Object.values(HABITAT_SCOPES),
  [RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX]: Object.values(
    DASHBOARD_COMPONENT_SCOPE_IDS
  ),
  [RESOURCE_TYPE_S3_BUCKET_PREFIX]: Object.values(S3_BUCKET_SCOPE_IDS),
};
