import { SettingsElementTypes } from './type';
import { ElementVersions } from '../../common/utils';
import _ from 'lodash';

export type SettingsIdentifier = {
  type: SettingsElementTypes;
  version: ElementVersions<SettingsElementTypes>;
};
export type AllowedInputs<C extends Record<string, unknown>> = Partial<
  Record<keyof C, SettingsIdentifier[]>
>;

export function typeVersionToAugurSettingsName(
  type: string,
  version: string
): string {
  return (
    _.upperFirst(_.camelCase(type)) + version.toUpperCase() + 'AugurSettings'
  );
}
