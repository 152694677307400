import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  Axis as d3Axis,
  axisBottom,
  axisLeft,
  axisRight,
  axisTop,
} from 'd3-axis';
import { NumberValue, ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  isPercent?: boolean;
  scale: ScaleLinear<number, number>;
  ticks: number;
  tickSize: number;
  tickPadding: number;
  baseline: number;
  helplineType: 'off' | 'dashed' | 'solid';
  direction: 'top' | 'right' | 'bottom' | 'left';
};

const tickFormat = (value: any) => `${value} %`;
export default class Axis extends Component<Props, {}> {
  static defaultProps = {
    ticks: 4,
    tickPadding: 8,
    baseline: 0,
    helplineType: 'off',
  };

  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props: Props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props: Props) {
    const axis = this.getAxisType(props);

    this.renderAxisTicks(props, axis);
    this.renderHelpLines(props, axis);
    this.renderBaseLine(props, axis);
  }

  getAxisType(props: Props): d3Axis<NumberValue> {
    const { scale, direction } = props;

    switch (direction) {
      case 'top':
        return axisTop(scale);
      case 'right':
        return axisRight(scale);
      case 'bottom':
        return axisBottom(scale);
      default:
        return axisLeft(scale);
    }
  }

  getHelplineStyling(helplineType: string): string {
    return helplineType === 'dashed'
      ? 'accuracy-axis__help-line-dashed'
      : 'accuracy-axis__help-line';
  }

  renderAxisTicks(props: Props, axis: d3Axis<NumberValue>) {
    const { scale, baseline, ticks, tickPadding } = props;

    const tickValues = scale.ticks(ticks);

    if (baseline !== 0) {
      tickValues.push(baseline);
    }

    axis = axis.tickValues(tickValues).tickSize(0).tickPadding(tickPadding);

    if (this.props.isPercent) {
      axis.tickFormat(tickFormat);
    }

    const axisNode = ReactDOM.findDOMNode(this.refs.ticks);
    select<ToBeRefined, ToBeRefined>(axisNode).call(axis);
  }

  renderHelpLines(props: Props, axis: d3Axis<NumberValue>) {
    const { scale, ticks, tickSize, baseline, helplineType } = props;

    if (helplineType === 'off') return;

    let tickValues = scale.ticks(ticks);

    if (baseline !== 0) {
      tickValues = _.without(tickValues, baseline);
    }
    axis = axis.tickValues(tickValues).tickSize(-tickSize);

    const axisNode = ReactDOM.findDOMNode(this.refs.helpLine);
    select<ToBeRefined, ToBeRefined>(axisNode).call(axis);
  }

  renderBaseLine(props: Props, axis: d3Axis<NumberValue>) {
    const { tickSize, baseline } = props;

    if (baseline === 0) return;

    const tickValues = [baseline];
    axis = axis.tickValues(tickValues).tickSize(-tickSize);

    const axisNode = ReactDOM.findDOMNode(this.refs.baseLine);
    select<ToBeRefined, ToBeRefined>(axisNode).call(axis);
  }

  render() {
    const { helplineType } = this.props;
    return (
      <g className='chart-axis'>
        <g className='accuracy-axis__tick' ref='ticks'></g>
        <g className={this.getHelplineStyling(helplineType)} ref='helpLine'></g>
        <g className='accuracy-axis__base-line' ref='baseLine'></g>
      </g>
    );
  }
}
