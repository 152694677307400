import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';

export type Value = string;
export type Error = string;
export type Props = {
  input: {
    value: Value;
    onBlur: (v: Value) => ToBeRefined;
    onChange: (v: Value) => ToBeRefined;
  };
  valid: boolean;
  error: Error;
  touched: boolean;
};

export default class StepName extends Component<Props> {
  render() {
    const {
      valid,
      error,
      touched,
      input: { value, onChange, onBlur },
    } = this.props;
    return (
      <div className={'input-container'}>
        <div className={'input'}>
          <div className={'input-parent'}>
            <input
              data-testid={'SpecifyNameInput'}
              type={'text'}
              value={value || ''}
              onChange={(e) => onChange(e.target.value)}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}
