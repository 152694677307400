import commonMessages from 'common/dist/messages/common';
import s3Messages from 'common/dist/messages/s3';
import React, { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { CreateBucketForm } from './form';
import { useBuckets, useCreateS3Bucket } from '../../../../core/api/data';
import Busy from '../../../atoms/busy/Busy';
import { ButtonProps } from '../../../atoms/button/Button';
import { IntlTextInputLine } from '../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import BubbleStep from '../../../molecules/bubble-step/BubbleStep';
import Wizard from '../../../pages/wizard/Wizard';
import { dataManagementRoutes } from '../../routes';

const CreateBucketWizard: FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { dataSourceCode } = useParams<{ dataSourceCode: string }>();

  const { isLoading, data } = useBuckets(dataSourceCode);
  const existingBucketNames = (data || []).map((bucket) => bucket.name);

  const { mutate, isLoading: isLoadingMutate } =
    useCreateS3Bucket(dataSourceCode);

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = useForm<CreateBucketForm>({
    mode: 'onChange',
    defaultValues: {
      bucketName: '',
    },
  });
  const onSubmit: SubmitHandler<CreateBucketForm> = (formValues) => {
    mutate(formValues.bucketName, {
      onSuccess: () =>
        history.push(
          `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${formValues.bucketName}/path/`
        ),
    });
  };

  const buttons: ButtonProps[] = [
    {
      label: commonMessages.cancel,
      color: 'white',
      linkTo: `${dataManagementRoutes.basePath}/s3/${dataSourceCode}`,
    },
    {
      label: commonMessages.create,
      color: 'secondary',
      type: 'submit',
      isBusy: isSubmitting || isLoadingMutate,
      disabled: !isValid,
    },
  ];

  const renderContent = () => {
    if (isLoading) return <Busy isBusy={true} />;

    return (
      <BubbleStep
        title={s3Messages.wizardBucketNameTitle}
        description={s3Messages.wizardBucketNameDescription}
        stepNumber={1}
      >
        <Controller
          name={'bucketName'}
          control={control}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage(
                s3Messages.wizardBucketNameRuleRequired
              ),
            },
            maxLength: {
              value: 63,
              message: intl.formatMessage(
                s3Messages.wizardBucketNameRuleMinMaxLength
              ),
            },
            minLength: {
              value: 3,
              message: intl.formatMessage(
                s3Messages.wizardBucketNameRuleMinMaxLength
              ),
            },
            validate: (value) => {
              if (existingBucketNames.includes(value)) {
                return intl.formatMessage(
                  s3Messages.wizardBucketNameRuleExists
                );
              }

              if (!/^[a-z0-9\.-]*$/.test(value)) {
                return intl.formatMessage(
                  s3Messages.wizardBucketNameRuleAllowedCharacters
                );
              }

              if (!/^[a-z0-9].*[a-z0-9]$/.test(value)) {
                return intl.formatMessage(
                  s3Messages.wizardBucketNameRuleLetterNumberStartEnd
                );
              }

              if (!/^(?!.*\.\.).*$/.test(value)) {
                return intl.formatMessage(
                  s3Messages.wizardBucketNameRuleNoSubsequentDots
                );
              }

              return true;
            },
          }}
          render={({ field, fieldState }) => {
            const { ref, ...restField } = field; // extract ref to pass as inputRef
            const { error, ...restFieldState } = fieldState;
            return (
              <IntlTextInputLine
                {...restField}
                {...restFieldState}
                label={s3Messages.wizardBucketNameTitle}
                inputRef={ref}
                error={error?.message}
              />
            );
          }}
        />
      </BubbleStep>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wizard headline={s3Messages.wizardBucketHeadline} buttons={buttons}>
        {renderContent()}
      </Wizard>
    </form>
  );
};

export default CreateBucketWizard;
