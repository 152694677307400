import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TupleListTable, {
  TableRow,
} from '../../../../tuple-list-table/TupleListTable';
import { ConfigPieChartConfig, PieChartConfig } from './type';
import { ConfigFormComponentProps } from '../../../common/utils';
import { useIntl } from 'react-intl';

const PieChartConfigFormComponent: FC<
  ConfigFormComponentProps<PieChartConfig>
> = ({ configValue, onConfigChange }) => {
  const { control, getValues } = useForm<ConfigPieChartConfig>({
    defaultValues: configValue,
  });

  const intl = useIntl();

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const prepareTableData = (
    legendEntries: { color: string; label: string }[]
  ): TableRow[] => {
    return legendEntries.map((entry, index) => ({
      id: `legend-${index}`,
      value1: entry.label,
      value2: entry.color,
    }));
  };

  return (
    <Controller
      name='legend.value'
      control={control}
      render={({ field }) => (
        <TupleListTable
          label1={intl.formatMessage(augurElementsMessages.barChartLabel)}
          placeholder1={intl.formatMessage(
            augurElementsMessages.barChartLabelPlaceholder
          )}
          label2={intl.formatMessage(augurElementsMessages.barChartColor)}
          placeholder2={intl.formatMessage(
            augurElementsMessages.barChartColorPlaceholder
          )}
          data={prepareTableData(field.value || [])}
          onChange={(updatedRows) => {
            const updatedLegend = updatedRows.map((row) => ({
              label: row.value1 || '',
              color: row.value2 || '',
            }));
            field.onChange(updatedLegend);
            updateConfig();
          }}
        />
      )}
    />
  );
};

export default PieChartConfigFormComponent;
