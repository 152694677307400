import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserSummary } from 'common/dist/types/users';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';

export const userSummaryKeys = {
  all: () => ['userSummary'] as const,
  userSummary: (userId: string) => [...userSummaryKeys.all(), userId] as const,
};

export const useUserSummary = (
  userId?: string
): UseQueryResult<UserSummary> => {
  const key = userSummaryKeys.userSummary(userId);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        return apiRequest(`/api/users/summary/id/${userId}`);
      }),
    {
      keepPreviousData: true,
      enabled: userId !== undefined,
    }
  );
};

export const useUserSummaries = (
  userIds: string[]
): UseQueryResult<UserSummary>[] => {
  return useQueries({
    queries: userIds.map((userId) => {
      const key = userSummaryKeys.userSummary(userId);
      return {
        queryKey: key,
        queryFn: () =>
          fetchQueryFn(key, () => {
            return apiRequest<UserSummary>(`/api/users/summary/id/${userId}`);
          }),
      };
    }),
  });
};

export function fetchUserSummaryById(
  userId: string
): CompletedApiRequest<UserSummary> {
  return apiRequest(`/api/users/summary/id/${userId}`);
}
