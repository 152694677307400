// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/s3BucketSelect/v1/type.ts' --type 'ConfigS3BucketSelectConfig'
export const schemaConfigS3BucketSelect = {
  $ref: '#/definitions/ConfigS3BucketSelectConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<S3BucketSelectConfig>': {
      additionalProperties: {
        $ref: '#/definitions/ConfigEntry%3Cnever%3E',
      },
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    'ConfigEntry<never>': {
      additionalProperties: false,
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      properties: {
        elementUuid: {
          type: 'string',
        },
        source: {
          const: 'input-element',
          type: 'string',
        },
      },
      required: ['source', 'elementUuid'],
      type: 'object',
    },
    ConfigS3BucketSelectConfig: {
      $ref: '#/definitions/Config%3CS3BucketSelectConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/s3BucketSelect/v1/type.ts' --type 'PartialS3BucketSelectAugurSettings'
export const schemaAugurSettingsDefaultS3BucketSelect = {
  $ref: '#/definitions/PartialS3BucketSelectAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialS3BucketSelectAugurSettings: {
      additionalProperties: false,
      properties: {
        bucketName: {
          type: 'string',
        },
        dataSourceCode: {
          type: 'string',
        },
      },
      type: 'object',
    },
  },
};
