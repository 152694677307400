import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { CassandraKeyspaceSelectV1AugurSettings as CassandraKeyspaceSelectAugurSettings } from 'common/dist/types/augurSettings/cassandraKeyspaceSelectV1AugurSettings';

export type { CassandraKeyspaceSelectAugurSettings };

export type CassandraKeyspaceSelectConfig = Record<string, never>;
export type ConfigCassandraKeyspaceSelectConfig =
  Config<CassandraKeyspaceSelectConfig>;

export type PartialCassandraKeyspaceSelectAugurSettings =
  Partial<CassandraKeyspaceSelectAugurSettings>;

export const configAllowedInputsCassandraKeyspaceSelect: AllowedInputs<CassandraKeyspaceSelectConfig> =
  {};

export const configTypeCassandraKeyspaceSelect = `{}`;

export const augurSettingsTypeCassandraKeyspaceSelect = `{
  dataSourceCode: string,
  keyspaceName: string
}`;

export const defaultConfigCassandraKeyspaceSelect: ConfigCassandraKeyspaceSelectConfig =
  {};

export const defaultAugurSettingsDefaultCassandraKeyspaceSelect: PartialCassandraKeyspaceSelectAugurSettings =
  {};

// JSONSchemaType<CassandraKeyspaceSelectAugurSettings>
export const defaultValidationSchemaCassandraKeyspaceSelect = {
  type: 'object',
  required: ['dataSourceCode', 'keyspaceName'],
};
