import _ from 'lodash';

export function isAlphanumeric(input: string): boolean {
  const regexp = /^[a-zA-Z0-9-_]+$/;
  return input.search(regexp) !== -1;
}

/**
 * Takes an object and removes keys with values that are null or an empty object.
 * This is done arbitrary deep.
 */
export const cleanErrorObject = (obj: any) => {
  if (!obj || !(typeof obj === 'object')) {
    return undefined;
  }

  const objCleaned = _.cloneDeep(obj);
  Object.keys(objCleaned || {}).forEach((key) => {
    if (typeof objCleaned[key] === 'object') {
      const cleaned = cleanErrorObject(objCleaned[key]);
      objCleaned[key] =
        Object.keys(cleaned || {}).length > 0 ? cleaned : undefined;
    }
  });

  const cleaned = _.pickBy(objCleaned, _.identity);
  return Object.keys(cleaned || {}).length > 0 ? cleaned : undefined;
};
