export const pieChartImg = `
iVBORw0KGgoAAAANSUhEUgAAAZgAAADpCAYAAAAZMiGFAAAABHNCSVQICAgIfAhkiAAAABl0RVh0
U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAAqdEVYdENyZWF0aW9uIFRpbWUAVHVlIDEz
IEZlYiAyMDI0IDEwOjE3OjUxIENFVHn+F8IAACAASURBVHic7d13eFRl+jfw72RKZiYzkzYpEAIk
JCEBBKWGLlgQC0hRxF1cFWmiKLj72133XVcXF8u6NhQpgggIwlrpIB1pJrQkkEAS0vuUJNP7+0dI
lkB6ZnJmzrk/1+WFmZw55w5k5jvPeRrP5XK5QAghhLiZH9MFEEIIYScKGEIIIR5BAUMIIcQjKGAI
IYR4BAUMIYQQj6CAIYQQ4hEUMIQQQjyCAoYQQohHUMAQQgjxCAoYQgghHkEBQwghxCMoYAghhHgE
BQwhhBCPoIAhhBDiERQwhBBCPIIChhBCiEdQwBBCCPEIAdMFkI6zWq2w2+2QSqWNHjfZXCjVOiAV
8RDgz4NCQp8jCCFdjwLGC+l0OlgsloYAMRqNjb53O6lUisTERPD5fJzNsWDzqTuPEfIBqcgPUn8e
Avz9ECrzQ5iCD6WMjzAFH2FyPgL8eR79uQgh3EIBwxCHwwGj0Qij0QiHw4Ha2tqGUGkvo9EIrVYL
pVKJI1eNTR5jcwA1JidqTADgQE7Fncf4C3iICOSjezAfvZVC9FQK0CuUfkUIIR1D7x5dqLq6GrW1
tdDpdI1aJe5gsVgAACarq+PnsLtQqLajUG3H2RxLw+Ox4QL0ChUiNlyAPhFCBNItN0JIG1DAeJBO
p4NOp2sIFV91o9KOG5V2HM2s+zoqmI/+PUQY0EOEmDAh/OjOGiGkCRQwbqZSqaDValFdXc10KR5T
onWgRGvCwXQTxEIeErsLMTDaHwN7iiARUtoQQupQwLhBdXU1tFottFotHA4H0+V0KbPNhUsFVlwq
sELgB9wVLUJynBj9okTUsiGE4yhgOshoNEKlUkGlUnEuVJpjdwIXC6y4WGCFTMzD8FgxRsSJ0SOY
z3RphBAGUMC0k0qlQklJSYdGe3GJ3uzCkasmHLlqQvdgPiYkSTAqXsx0WYSQLkQB0wYOhwMVFRWo
qqqiYOmAUq0D35zWY/clIyb2k2BsXzH8BXT/jBC2o4BpgdVqRXl5Od0Gc5MaoxM/phqw77IRY/uK
MbGfhFYZIITFKGCaYLVaUVJSApVKxXQprGS2ufBLhgm/ZJgwpq8YU+4JoFUECGEhCphbOBwOFBYW
UrB0oV+vmZGSa8GkgRJM7CeFkMYDEMIaFDA3lZaWory8nG6FMcBid2HnBSNOZJnx+JAADI31B7Vn
CPF9nA8YrVaLwsJC6rz3AtVGJzae1OHQFROeSpYhJozzv56E+DTOvoKNRiMKCwt9egkXtirW2PHB
3mqMSxTj8SEy+HP2t5QQ38a5l67D4UBJSQkqKppYTph4lRNZZqQVWvGHsXIkRAqZLocQ0k6cGiNq
NBqRlZVF4eJDqo1OfHKgBt+e1cNiZ7oaQkh7cKYFU1paipKSEqbLIB108poZV4qtmDOGWjOE+ArW
t2CsViuuXLlC4cICGoMTnx6owa6LRjg7vu0NIaSLsLoFU1FRgZKSEhp6zCIuAPvTjMgqs2L+BAVt
fkaIF2Plq9PhcCA7OxuFhYUULiyVX2XH8p+0yCy1MV0KIaQZrAsYq9WKrKwsVm/4ReqYrC589ksN
fkw10C0zQrwQqwLGaDQiIyPD7fvdE+926IoJnxyogdlGKUOIN2FNwKhUKly5coVuiXFUToUNH+yt
RrXRyXQphJCbWBEwhYWFyMvLY7oMwrCyagfe261FWTV9yCDEG/h0wNR35tPESVKv1uTCv/dUI6uM
Ov8JYZpPB0xJSQl15pM7WOwurDxYg3O5FqZLIYTTfDpgqDOftGTTrzqczjYzXQYhnOXTARMcHMx0
CcTLfXNajxPXKGQIYYJPzeS32+1ITU2FxWLBsGHDEBERAaPRSDtQkhZtP6sHAIzrK2a4EkK4xWcC
xm63Y+fOnQ1hUlxcjKlTpyImJgYAKGRIi7af1cPucGFiPwnTpRDCGT5xi8zhcGDv3r2NQsRgMGDX
rl0wGo2IiYmBUqlksELiC75PMeDIVRPTZRDCGV4fME6nE/v370d5efkd39PpdNi5cyfMZjOFDGmT
71MMuFhA22MT0hW8PmB++eWXFpfar62txe7du2GxWChkSJtsPFGLAjXtXkaIp3l1wJw5cwYFBQWt
HqfRaLBr1y7YbDYKGdIquxP4/JcaqPW0rAwhnuS1AXPt2jWkp6e3+XiNRoM9e/bAbrcjJiYGQUFB
HqyO+DqDpW4yJi2QSYjneGXAlJWV4cSJE+1+XmVlJfbu3QuHw4HY2FhIJDRiiDSvSufAF4dr4aCG
DCEe4XUBo9frceDAAbhcHftkWV5ejgMHDoDP5yMpKYlChrQop8KG71L0TJdBCCt5VcA4nU788ssv
sFo7N8qnuLgY+/btA4/Ho5AhrTqRZUZaEY0sI8TdvCpgfvvtN1RVVbnlXEVFRTh06BD8/PwoZEir
Np7QUac/IW7mNQFTVFSEtLQ0t54zPz8fR44codtlpFUWuwtrjtRQfwwhbuQVAWM0GnH48GGPnDs3
NxcnTpygkCGtKtE68N/fqD+GEHfxioA5duxYp/tdWpKVlYVTp041hIxIJPLYtYhvO3mN+mMIcRfG
AyYzMxPFxcUev86VK1dw9uxZ8Pl8xMfHg8/ne/yaxDdt+lUHg4XmxxDSWYwGTG1tLc6cOdNl10tL
S8P58+chlUqRmJhIIUOaZLK6sOMc3SojpLMYDZijR4/Cbu/aNaHOnz+Py5cvU8iQFqXmWXC93MZ0
GYT4NMYC5urVq6ioqGDk2ufOnUNmZiaFDGnRllM62GlUGSEdxkjAmEwmnD17lolLNzh58iSys7Mp
ZEiz1Hon9lwyMF0GIT6LkYA5fvx4l98aa8rRo0eRm5tLIUOadSjDhIpaB9NlEOKTujxg8vPzUVhY
2NWXbdbhw4eRl5fXEDKE3MrpAr45pWO6DEJ8UpcGjMvlYvzWWFMOHTqEwsJCSKVSxMTEMF0O8TK5
lXZcKaa5MYS0V5cGzNWrV1FbW9uVl2wTl8uFgwcPoqSkBEqlkkKG3OGn8wZ0cIFvQjirywLGarUi
JSWlqy7Xbk6nE/v370dZWRmFDLlDabUDKXkWpssgxKd0WcBcvnzZo8vBuIPD4cDevXtRWVnpsyHD
9+MxXQJr7bpII8oIaY8uCRibzdau7Y+ZVB8yGo3Gp0ImLCwMADA01p/hSthLo3fiWKaJ6TII8Rk8
V0e3jmyHCxcuIDU11dOXcSt/f39MnToVQUFBUKlUyMvLY7qkBhKJBAKBAHw+H1KpFMHBwZBKpQ3f
v1RgRYHahlqTE3qzCzUmJ8qr7bDRaNtOk/nz8M+ZIfAXUEuRkNZ4PGDsdju2bNni9bfHmiIWizF1
6lQEBgaioqKCkeHVEokECoUCcrkc/v7+jYKkvTQGJ8q0dlwvtyG73IYCNfNzkXzR9KEBuK8/bftA
SGs8HjDp6elduqClu0mlUkydOhVyuRx5eXlQqVQevR6fz0dwcDCCgoKgUCg8OvnTZHPhYr4FF/It
uFZmg5NGSbVJoNQPb88MAXV3EdIyjwaM0+nE1q1bYTQaPXWJLhEQEICpU6dCJpN5LGTkcjmUSiWC
g4MZWVFAZ3bheJYJJ6+ZoDdT0rRmzmg5kuOov4uQlng0YLKzs3H06FFPnb5LyeVyTJs2DWKx2K0h
o1QqERER0albX+52LteCg+lGlNdQp01zIgP5+PvjwUyXQYhX82jA/Pe//4VWq/XU6btcUFAQpk6d
Cn9//06HjFKpRFRUlFfvrplZasPeywbcqKS+mqYsnKjAXdHe++9HCNM8FjDFxcXYu3evJ07NqJCQ
EEyZMgUikahDISOXy9GzZ0+varG05lKBFT+e10Olo7XrbxUbLsBrk4OYLsNrpNyw4NfrZuSr6j6Q
9FYKMCZBjGE0dJ6zPBYw+/fv96pFLd1JqVTiscceg1AobHPI8Pl8xMTEIDjYd2+r/HTegF8yaB7I
rf70SBB6KwVMl8G4H1INOHyl6d+N+/pLMH1owB2Pl1dU4tvvfgAAXE5PR0JcHCQSCSIiwjB75gy3
1JVy4SK+3rIVMb17g8cDjEYTnpo5HXF9Ytt9ro8/X41XFy+sW5XkwkWMTh7R6HFyJ48EjNFoxJYt
W9x9Wq8SHh6ORx99FAKBANnZ2aiurm722KCgIMTGxrJiO4C8Kjs2HK+FxkCtGQAYlyjGrBEypstg
VMoNCzaebHnF6WfHyltsyaxcvRazZkxHeJjSvbVduIjiklJMe+wRAHWTvv/f8n/hvX++2eFz6vV6
rFq3Af+3dImbqmQvj8zkv379uidO61UqKyuxd+9eOBwOxMbGQiJpel5Ez549ER8fz4pwAYCYMAH+
OiUYcRFCpkvxCik3LJwf3v3rdbNbjrnVj7v2YOXqtVj+3r+Rk3sDb614r9H3679Wa7T4z6ef4+PP
V+OdDz6CppU+X6FQCLG/GC6XC2q1Bv96/z/46LNVWPHBh8jLL2g454oPPsQnq1bjrXfeR0FhEQDg
jeUroNPpsX7TFlzJysJ3P+1seBwA3lzxbsN1TCYT3v3wYwDAnv0H8eHKz/Hvjz/Frn372/X34Os8
0ra/evWqJ07rdcrLy7F//35MnjwZSUlJyMzMhMlUd5uAz+cjMTHRp/pa2koq4mHpQ4HYflaPE9fa
98bBNiarC9fKbEjqzt3Are9z6ewxt1OGhuLlhfNbPGbztu14cf5cSMRiaLRabPl2B5YsWtDs8Sq1
Gg6HAzweD5+v+xIvzpsLZWgorFYr3lzxLla8+QYOHDqMmY9PQUJcHPR6PXbvP4hePaMBAHK5DHOf
+T0s6zZg5uNTGp07OioKxSWl6BHVHcd/PY17x45BTu4NmMxmLHt5MYC6lppao0VoiO/eKm8PtwdM
RUUF9Hq9u0/rtUpKSnDo0CE8+OCDSEpKgkqlgt1uR2RkJGtaLc2ZlSxDeCAf3/9mAJc/xKfmmTkd
MJ7SL6lvq8dcTk/HV5u/afjaz+/OmzJHjp9AQWEReLy6D371/SV2mx3K0FAAgEgkQkR4OFRqNcaM
SsbmbdsxYugQDBsyGE/NnN6meh964H4cOHwEz8/5HVIvXMTrf1qGrTu+Q3FpKVatWw8AqK3VQaPR
UMB01I0bN9x9Sq+Xn5+Ps2fPIjk5GREREUyX06UmJEkQJudj3bFa2Dk6beZivhVzRjNdBXN6KwXI
qbC1eownRISH48V5c1s8ZuL4cQ19MLe6fYqAUCiEzWZHzx498Nc/LsP169nYsGkLBt89COPHtP4P
HNW9G0pLy6DRahEUFAg/Pz84nA5MfeRhJPVNaN8PxhJu74PJzc119yl9Ahf6nZozoIcIi+4L5OzS
KRa7C5cLfW+tPXcZkyB2yzEtkclk0N4cSKNSq2G21O3N48f3Q2l5OYC6dQ/r+1Hags/no7qmBkBd
539+QSG6RUZg23ffw2I2I7FvAl5aMA/HTp6643kOR9Ofpvr3S8LKL9bigYkTAADDBg/Grr3/63e5
kZff7HPZyK0fK1Qqlc8vC9NR4eHhTJfAqMRuQrxwrwLrjtVycufH1DwLBvXk5qTLYbH+KNLYWxym
3Nm5ME9Mm4qPVq5CZEQExBIxJJK6wFq6eBFWrdsAuSwAJpMZs2ZMa/M55z/3B6zd8DXEYn9U19Rg
3rPPAABGjxiBT1atgUwWgOrqGjwxbWqj50kkEhiMRqzbuKnhOfXuv3c8ftq9B33j4wAA/RL7Iut6
Nt5+7wOIJWIEKRR44bbnsJlbhymnpKTg4sWL7jqdzxCJRJg5cyZkMm4PVwWA41lm7DjHnT64ekI+
8O/ZSgjZ3e3WIppoSW7n1hZMfn6+O0/nMx566CEKl5vGJ4pRoLLhXC63the2OYDLhRYMjeHum+mw
WH8KE9KI2/pgjEYjq9Yda6sJEyYgMjKS6TK8yjNj5OgRwr3Z7Zml3O2HIaQpbguYgoK2d66xRVJS
EuLj45kuwyvNn6Dg3K6P2eUtj6QihGvcFjBFRUXuOpVPUCgUGDVqFNNleK1QmR+eGStnuowupdY7
UWOkJXQIqeeWgHG5XCguLnbHqXwCn8/HpEmTWD+RsrPu7iniXJ/ENWrFENLALQFTVVUFu507e4YM
Hz7cp1dF7kpPjZRBJubOrbIcChhCGrglYCoqKtxxGp8gk8kwYMAApsvwGRIhD7OTuXOrLLuVGe2E
cAkFTDuNGDECPB53PpG7w929ROgTzo1RZZW1DhgsHJxpSkgTKGDaITg4GH369GG6DJ80Yzh35gld
K6PhyoQAbggYo9EIg8Hgjlq8XnJyMtMl+KxeoQL078GNpVToNhkhdTodMO3dk95XBQYGIjo6muky
fNqku9i3N05TCtXcGfBCSEs6fWNco9G4ow6vN3DgQKZL8Hl9wgXoEy5EbiW7P+FX1XJntdxb7UtV
4/vTVcjIr1uLbkBvGWaMCsPkoaEMV0aY0umA4cLyMCKRCH37tr75EWnd/QMkyD3C7oAxWFww21wQ
C7kzGOSjn4qw+Uh5o8cu5OhwIUeHrGIjlj5+Z+u/vKIS3373A4C6jcMS4uIgkUgQERGG2TNnuKWu
lAsX8c23O/Dx++80PKbWaLF52/aGjcfc4WxKKgYN6A+JRAK1Rou9Bw5izuxZbju/r+p0wKjVanfU
4dUSEhKa3CmPtF//KBEkIh5MVnaPtKqsdaBnKDdGzu1LVd8RLrfafKQciT2kd7RkIiPCG97kV65e
i1kzpiM8TOn2+swWC3bt24/HJj/k9nPXO/tbKuL79IFEIkFoSDCFy010i6wNYmNjmS6BNfh+wOBe
/jiVbWa6FI+q0nEnYL4/XdWmY9pzq+zHXXtQXFKC6poazJ45A99s/y/+8fqfG77/1or38I/X/wy1
RouNW7aCz+fDZDJhwdxnEXLbJOhJ99+H1AuXkDxsKMKUjQPM4XBg/aYtMBiNMBqNePyRh9G/XxJS
LlzEnv0H0C0iEjJZAAwGA+Y//ywupaXj0NFjkEgk0On1WDzvBaRcuIC0K1fw9TdbMWPqFMhkMmze
th194+MQFBSIkcOHAQDWbdyEhx64D1KJtNWa2aJTrwAujB6TSCS0WrKbDevD/oCp5FA/TH2fS2eP
uZ0yNBQvL5zf4jGbt23Hi/PnQiIWQ6PVYsu3O7Bk0YI7jls8/wWsWru+UUgBwA87d2Pi+HGIi42B
0+XC8nfeR1JSIr776We889Y/4Mfj4cxvKUjPuAKg7rbea0teAo/HQ3ZOLvYcOIinZk5HWsZVzJk9
C6EhwVBr6roN7h03Bp9+saYhYEpKSxEdFYWPP1/dpprZoFMBo9ezf2Mpar24X3yEEDJ/HvQsnpCo
0nEnYDylX1Lr/Z6X09Px1eZvGr5u7lZ2eJgS9wwaiAOHj2DoPfc0PH4pLR1VKhUOHq772mqzobKy
Ej179IDfzQnV0VFRDQEzbMhgfLVlK1wuF3Q6PaSS5reClojF8OPxYDKbceNGHu7q369dNbMBBUwr
aGiyZ8RGCJHG4n3sudSCGdBbhgs5ulaP8YSI8HC8OG9um46d8shkvLF8BfrExDQ85nQ6seiF5xut
zlFRVdXsah0rV6/BspcXQyGXo6y8Arv27mvxmuPHjMbJU2eQdf06nnn6qXbX7Os6FZ1cCJhu3box
XQIrxYULmS7Bo7g0VHnGqDC3HNMSmUwGbXU1AEClVsNsqdsx1Y/vh9LyugEGdrsdefkt70u1aN5c
fPHlhoavB/RLwqGjxxq+vp6TgzClEsWlpXDe3E2+7JaVSkxmMxTyurX10jIyUL/hPJ/vB4fjzn/z
oUMGI+X8BRgMRgQFBnaoZl9GLZgWhISEQChk9xshU/pEsPvvVWd2weYAhBzY0WHy0FBkFRubHUk2
Z2Jkp+fCPDFtKj5auQqREREQS8SQ3Lw1tXTxIqxatwFyWQBMJjNmzZjW4nm6RUZg5PBhKC4pBQDM
mjENK9esw+X0DNgdDgy5exAS4uIwc+oULH/nfUREhMOPx2vYmmP8mNF46533ERoSjOioqIZQGTFs
KD74ZCWen/M7hIX9L0z9eDwEBiqQdMs0h/bW7Mt4LperwzfCDx8+jNzcXHfW41X69++P0aNHM10G
ay3+mt2rQKx4MgSBEvbeX78dmyZaqjVahIbUjew6duJXuODChHFjGa7K93SqBWM2s3skUGio770w
fElEIB8VNey9lWSxuQAJ01V0nclDQ30yTJqSlpGBjKuZAOo64RdxpM/E3ShgWhB4854p8YwwOQcC
hvikCePGUovFDTrVfqeAIZ0RpmB3B4XZTgFDuK1TAWOzsXdNKYFAAKmUG6v/MiVMzu6AoRYM4bpO
BUwnxgd4PYmEQzfPGRLgz+7FIM0UMITjuDPEpZ0EAm6sI8Uktq82TC0YwnXUgmkGBYznsT5gqA+G
cBy1YJpBAeN5/iwPGLpFRriuUwHD5kXamluLiLgP2/+GHU6mKyCEWRQwzWDz7T9vwfZP+AJ2D5Ij
pFUUMM1g8xBsb8H2TnCBH9vbaIS0rFMJUb8AHCEdwfaJiNSCIVzXqZ5sNrdghgwZwnQJrFdrYncn
hZDPrRZMTk4OMjMzUVlZCQAIDw9HUlIS4uLimn3O2g0bUVFVBblM3vDYow89iLg+TW/09/5Hn+LF
ec9DJuvY/jIOhwNPPzcPw4cOAY9Xt/x+8rBhuO/ece0+18efr8arixfCarUi5cJFjE4e0ehx0smA
YcNIK4VCAblcDoVC0fD/ERERCAgIYLo01mP7nilsH4Z9q7NnzyItLa3RY2VlZSgrK4NKpUJycnKz
z31m9lPo1bPrNvbrGx+H15Ysbvj6X//+D+6+awBCQ0PadZ76ELFarTh15lxDwFC4/E+nEkIsbn67
UG8hkUgaAuT2PwMCAmi0GINUOna3YAL82dvCv1VOTs4d4XKrtLQ0KJXKFlsyt7uUlo5DR49BIpFA
p9dj8bwXIJf/r9XidDrx+dov4XA4YTAYMHb0SIwbPQpqjRYbt2wFn8+HyWTCgrnPIiQ4uMVrJSbE
o7isbn+Y1eu/glQqgclsxuyZMxDTuxfUGi3WbPgKAVIpqmtq8ezvZqNXz2i8sXwF/vTqEmzYvAVX
srLw3U87MfPxKXhj+Qr88++v480V7+LN1/8CADCZTPjkizX4y7JXsWf/QVzLzobD4UBi3wQ8Nvmh
Nv+9+BqfDxiBQAC5XN5siDTXyqqqsSH7hgElajNK1FaUqMwoVltQoragqsaGgb1l+OKlvpCIuPEm
wQSVnt0tGJmYGx9eMjMz23RMewKmvKISry15CTweD9k5udhz4CCemjm94fvXs+t2nqx/bN3GTRg3
ehQ2b9uOF+fPhUQshkarxZZvd2DJogXNXsdms+H8pct4cOJEfPT5Krw4by6UoaGwWq14c8W7WPHm
Gzhw6DBmPj4FCXFx0Ov12L3/YEOLSy6XYe4zv4dl3QbMfHxKo3NHR0WhuKQUPaK64/ivp3Hv2DHI
yb0Bk9mMZS/XtaBWrl7baO8ZtvGJgAkICGh0C+vWEGluQUqT1YkblRaUqHQoUVlQorGgWGVBicqC
Uo0F1lY6mNPy9Vh/sBQvPdrDEz8SAVi9VD8AyDjSgqnvc+noMZu2fdvQBxMREYbZM2dg2JDB+GrL
VrhcLuh0ekgljd9rYnr3ws979+H7n3di5PBhmPfsMwCAy+np+GrzNw3HNdVPfD0nFx9/vho8HmC3
OzDnqVmQy2Ww2+xQ3twDSiQSISI8HCq1GmNGJWPztu0YMXQIhg0Z3CjoWvLQA/fjwOEjeH7O75B6
4SJe/9MybN3xHYpLS7Fq3XoAQG2tDhqNhgKmKe4KGJFIdEc/yK1h0tQvicMJVFRbkVFci1K1pa71
oaprgRSrLajW2ztd18mMagoYD8mt7Py/j7eTibkRMJ3VVB/MytVrsOzlxVDI5Sgrr8Cuvfsafd/f
3x9/XvoKSsvKcOzkKegNBsx/7g+ICA/Hi61sDpYQ16fJfhKRSNToa6FQCJvNjp49euCvf1yG69ez
sWHTFgy+exDGj2l9p9uo7t1QWloGjVaLoKBA+Pn5weF0YOojDyOpb0Krz2eDTgVMUFBQm49tqjO9
/k9/f/8mn1NjtCOr2HRHeJSoLCjXWjw+Uzq71IRaowMKKY03dbfcCnbPM5KKeBBy5NcmPDwcZWVl
rR7THiazGQp5XasmLSMDt897/u38BUglEgzol4Snn5yJv731NgDAj++H0vJydI+MhN1uR1FxCWJ6
92rTNfl8PqprahAUGAibzYb8gkJ0i4zAtu++x+OPPIzEvgnoExuDFR981Chg+Hw+HI6mW+P9+yVh
5Rdr8dQTMwAAwwYPxq69+xsC5kZePnr1jGbtlI9OBUyfPn2Qnp6OyspKiMXiJvtAFApFs0MKbXYX
SjQWlKhrUKKqC49S9c1bWWozDGbmO4HPZNVg0uD2jS4hrcupZHfAhMi403pJSkpqNWCSkpLadc7x
Y0bjrXfeR2hIMKKjou54Ax84oD8+W7MOR0+chNlsxqgRwwEASxcvwqp1GyCXBcBkMmPWjGltvub8
5/6AtRu+hljsj+qamobbbqNHjMAnq9ZAJgtAdXUNnpg2tdHzJBIJDEYj1m3c1PCcevffOx4/7d6D
vvF1/U/9Evsi63o23n7vA4glYgQpFHjhtuewCc/lwTVRXC5ApbM1GR4lagsqq73/TWbayDD8fXZv
pstgnde2qlm9VMxd0SIsnKhguowu09Qw5XoDBw5scZgyYS+3TGSp1ttxOU+PoipzuzvTvV1qdi3T
JbDOpUIrq8MFAIIDuNOCAYDk5GQolcp2T7Qk7NbpgEnJ1mHByix31OKVilQW5JWbERPJ/JBstki9
YWa6BI8LCWDnPfWWxMXFUZiQRjr9MeuH060PUfR1209WMF0Ca1jsLlwutDJdhsd1C+JewBByu04H
TLBM6I46vNrOsyqYbcwPOGCDszkWONl9dwwA0D3Y95dRIqSzOh0wST2anujIJmabEz+dUTFdhs9z
ATh8xch0GR4nEgAhHOuDIaQpypKsawAAEz5JREFUnX4VJEZzY1HIbcfKmS7B56UXWaHWs78lSK0X
Qup0OmDiu0sgErB/zaUilQWnM2uYLsOnHcowMV1Cl+gRQgFDCOCGgAGAuO7sv00GAP/5sZDpEnxW
ZqkNuSyfXFkvNoz9/ZKEtIVbAiYpmhsBk1duxnenqpguwyd9n6JnuoQuExtOAUMI4LaA4UY/DACs
2l3sFUvY+JLUPAvKqtm9cnK9AH8ewuTUwU8I4KaAGRovb/0glqg22LFmfwnTZfgMmwPYecHAdBld
Jj6SWi+E1HNLwPQMEyM8iDsvrC1HypFfyf7Z6O7w8wUDJ0aO1UuIFLV+ECEc4ba2/Oikti/dzwZ/
Wp8Du4MDMwY74UalHUevcmPkWL3E7tz5oHU7tVqNrKwspKamIjU1FVlZWVCr1S0+Z+2GjVj+3r/x
8eerG/7Lyb3R7PHvf/Qp9PqO9+c5HA48OvMp1Op0jR5/Y/mKDp+zKQWFRbiRlw8AUGu02Lxtu1vP
7yvcNp4yOVGBH89wpwM8t8yED34oxF+eaNteE1xjsQMbTnBroVCZPw8RCm4uEVNUVITy8sZzxXQ6
HXQ6HYxGI6Kjo5t5ZtMbjnlSeJgSq9atx1+WveqxaxQUFcFqtSI2pjdCQ4IxZ/Ysj13Lm7ktYEYm
BrrrVD5jx8lKjOkXhDH9ufezt8TlAr48VgOtgTu3xgBgQDQ3b4+p1eo7wuVW5eXlkEqlCL25HXFb
XEpLx6GjxyCRSKDT67F43guQy/+3r5TT6cTna7+Ew+GEwWDA2NEjMW70KKg1WmzcshV8Ph8mkwkL
5j6LkODG2xH36N4d0VFROH32N4xKHt7oew6HA+s3bYHBaITRaMTjjzyM/v2SUFFZidVffgWlMhRB
gYHIyb2Bf7z+5ybrLCkrw/5fDsHhcCJAKkVCfDw2b9uOp2ZOx4+7dmPRC88DAM5fvISS0jKMHpnc
as2+ym0BI5Pw0a9nAK4WcqdDFwD+tikXO/46ABFB3Hxzacr2c3pcLeHGnJdbJXXn5u9AVVXrdy6q
qqraFTDlFZV4bclL4PF4yM7JxZ4DB/HUzOkN37+enYMwpbLhsXUbN2Hc6FHYvG07Xpw/FxKxGBqt
Flu+3YElixbccf7ZT8zA3956GwPv6g9ZwP9Gwf6wczcmjh+HuNgYOF0uLH/nffTvl4Qvv96MlxfN
R0hwMKpravDWO++3WOdDD9wPq9WKkSOGQ63RAgAiI8JRWaWCy+UCj8fD0ZO/YuHzz+LLr7e0qWZf
5NYpx+MGBHEuYHQmB/64PgdrXkqE1J+Gp/6SYcLJa9wbAOHHAwb04GbAtKVPpKVjNm37FnJZ3UjU
iIgwzJ45A8OGDMZXW7bC5XJBp9NDKmm8XUZM7174ee8+fP/zTowcPqxhJ8nL6en4avM3Dcf5+TX9
mvTz88O8Z5/BmvUb8dqSxQ2PX0pLR5VKhYOH67622mxwuVywmC0NrYqgwEDIb4ZSa3XebvDdA3Ep
LR0D+iXBbrNDJpO1uWZf5NaAmTwkFKv3cm8I75UCAxZ+loVVi/tCJubmPXgAKK9x4Kfz3PqAUa9/
DxHEQvYvmeQJTfXBrFy9BsteXgyFXI6y8grs2ruv0ff9/f3x56WvoLSsDMdOnoLeYMD85/6AiPBw
vDhvbpuuW98/knLhYsNjTqcTi154Hjxe439LP37Tb/qt1Xm7iePHYe1XX6Omphajk0cAQLtq9jVu
jcroMH/ERkrceUqfkVFgwPxPs6A3c2NCYVPyVXamS2DMPb38mS6BMTKZzC3H3MpkNkMhr2vVpGVk
4PaN3X87fwEZVzPRvVs3PP3kTBQUFgGoC4LSm/1BdrsdefkFLV5nzuxZ+P6nnTBbLACAAf2ScOjo
sYbvX8/JAQCIxWJoq6sBADqdHgajscU6+Xw+HI473wsCpFLYbTacOH0aI0cM61DNvsTtq/I9ODiE
k60YAMgqNmL+p1lYuySRky2ZxG5CSEU8GK3cG749qCc3b48BQFhYGHS3Dftt6pj2GD9mNN56532E
hgQjOirqjjfrgQP647M163D0xEmYzWaMGlHXWb908SKsWrcBclkATCYzZs2Y1uJ1+Hw+nn/md3j3
w08AALNmTMPKNetwOT0DdocDQ+4ehIS4ODw353f49Iu1CA0Jhkwmg0QiabHOpL4JeGvFe+Dz+bhn
0KBG1xydnIzT585BIBB0qGZfwnO5bv9s0DkFlWZMezvdnaf0OQlRUnw4Lx7dQ9j/ppOarUNGgR7j
7wpGTIQYJVoHPtxXDbONOyEzqKcI8ycomC6DUU0NU64XGRnZ4jBlX6CtrkZgYCD8eDxotFps2rod
ry5eyHRZXs/tAQMAs97NQHYptybY3U4m5uODuXEY3pe9bzxfHy7Hyp1FDTtU/n12b0wbGYZCtR0f
76+GhSN3zBZOVOAujg5RvpVarUZVVVVDh75MJkNYWFi7Ro95q9y8PPy8ey9EIhGMRhNeeHYOa4YS
e5JHAmbDwTJ8trvY3af1SS9M6o4XH4liugy3Mlmd+PvmGzhyWXvH9/7xdAymJiuRV2XHxweqYWd5
l1SAPw/vzQoFj/r3CbmDR8bDPZas9MRpfdKXB0qx8LNrUOvYMS/kWrERcz642mS4AMBbW/Nw8KIG
MWECLLovEM0MvmGN5DgxhQshzfDIyz9MIcR9g6j5WO+367WYtjwd3x6vgMNHJ7frTA6s2FGAp/99
BTfKW779+frGXBxLr0ZiNyEWsLxvYlR8y/MeCOEyj9wiA4CUbB0WrMzyxKl9WkyEGP/vqRjc06d9
wzaZ9N2vlfhsdzFqje2737VyYQJG9wvE5UIr1h2rvWOoqa9LiBTilUm0TBAhzfFYwADAjH+lI6+C
e7O62+LhoaF46bEeiAz23s7h1Gwd3v+uADllHRuwIRLw8OmCBAzvq8D5fAu+Oq4DmzJm/gQFp4cn
E9IajwbMt8cr8P73tI99SyYOCsb0UWEYleQ9n4TT8vT4+nA5jqY13c/SHv5CHla9mIh7+shwLteC
Tb+2PF/CVwRJ/fD2zBDqfyGkBR4NGIPZiQf+dhFmm492PHShaKU/nhgTjqkjwyCXdP0kTbPNiX2p
auw4WYlrxUa3nlsi8sOXryQhKVqKU9fN2Hqm4/t5eIvHhwTggQHcXLWCkLbyaMAAwH9+KMQ3xyo8
eQlWEfjxMDBWhlGJgRiZFIjEHlKPfUq+XmrCuawanLtWiws5Oo9+EJCJ+Vj/ahLiu0tw6IoJP6b6
7pplYiEPK54Mhb/b18EghF08HjBavR0P/+MSLBya2e1OUn8/DOgtQ7/oAAzoFYDeEeIOr/dWqrHi
bFYNUq7X4ty1WlQbunYmZKBUgA1LkxATIcbey0bsueTellJXefRuKSYPkjJdBiFez+MBAwAf/liE
LUeb35CItF+YQogopT8CAwRQSAVQSPgIuG39M5PViWKVBWUaC4pVFq9YiDNYJsBXS5PQM0yMH1IN
OHzFt1Z8oNYLIW3XJQFTbbBj8hvUiiF1lAohNi7rh+4hIuw4p8fxLN8ZafjYPVI8NJBaL4S0RZfM
sw4KEGDm6PCuuBTxAapaG+Z+nAlVrQ1PjpBhdIJvTFaUiXmY0I/ChZC26rKFPOZO6g5/2pCJ3FRR
bcXcTzJRrbdj9kgZhsd6/34qj9wdQLfGCGmHLguYoAABnpnYrasuR3xAUZUFcz/JhM7owDNj5Bjo
xZMWw+R+GOMjLS1CvEWXLkX4wqTuUCqEXXlJ4uXyKsyY92kmDBYH5t2rQP8o7wyZGcNk8KMGOCHt
0qUBIxTw8McZPbvyksQHZJeasGBlFiw2J168X4Gk7t71ISQhUkj7vRDSAV2+mPqD94RgcB95V1+W
eLnMIiMWfpYFs82JBRMDERfhHSHjxwOeGuk7C5MS4k0Y2a3jb7N60+0Gcof0fANeWnUdPLiw+P5A
9A5jvkd9Yj8JIhRdv3QPIWzASMDERIrx5FgatkzudCFXh2VfZkMkAJY8GIjoUOZCRiHh4dF7Ahi7
PiG+jrH9BpdMiUY3L16qnjDn1ys1WLYuG0I+Dy8/EIjuQcy0IJ4cIYeQGi+EdBhjASMW+WHFs32Y
ujzxcsfSq/H6xlxIRTy8MikIYfKufae/q4cI9/SiD0CEdAajO6YPipHhd/dGMFkC8WIHL2rwxpYb
kIl5WDo5EKGyrvl1VUj88MxYGohCSGcxGjAA8MrUaPRQev8sbsKMPSlqLN+Wj0CJH5Y+FIQgqed/
ZZ8fL4dURKNQCOksxgNGwOfh3Wf70M6ApFk/nqnCe98VIDjAD8smB0Eh8dwvy71JEsR7yRBpQnwd
4wEDAP16BuDlx3owXQbxYttPVOKLPSUIldW1ZGT+7g+ZqGA+pg+lUWOEuItXBAwAPHt/Nzx4TwjT
ZRAvtu5AKTYfKUe4go+lk4MgduPiqf4CHhZMDATfa14RhPg+r3o5LZ8Ti749aDl00ryPfirC9hOV
iAzk45VJgW5b3fjZcfIuG0RACFd41StKKOBh5cIEBMmYn8FNvNd73xXgxzNV6BkqwJJJQRB18tdl
XKIYA2mtMULczqsCBqjb7XDlggQIaC0Z0oLl2/KxJ0WN3koBFt8fCEEHp8nERQgxcxitNUaIJ3hd
wABA/14BePP3MUyXQbzc3zffwMGLGsRFCLHovvb3n4TJ+Vh0n4L6XQjxEK99aT08NBSLH6WRZaRl
r2/MxamrNUjsJsSCCYo2L6IqEfHw8oOBbh0oQAhpzGsDBgDmPtgN00eFMV0G8WJOF7B0XTZ+u1aL
/j1EeH68ok3PW3x/160MQAhXef0r7G+zemN0v0CmyyBezO5wYcma67iQq8M9vUR4fnzzy7z48YCX
HghEjBdsBUAI23l9wPB4wAdz43FXb5oAR5pntbvw0qrrSM83YEhvfzzdxCZhPADPj/e+HTMJYSuv
DxgA8Bfy8OnCBMR1kzBdCvFiZpsTiz7PwtVCA0YniPHkiMYh8/vRclohmZAuxHO5XC6mi2irWqMD
L3ySiZwyE9OlEC8mE/Ox/tUkxHeX4GKBFSk3zEiOo7kuhHQ1nwoYAKg22DH/0ywKGdKiULkQO/8x
EBKRTzTSCWEln3v1BQUIsHZJIt0uIy1S62w4dbWG6TII4TSfCxigLmTWvUIhQ1oWGylmugRCOM0n
AwYAAqUCrH81CcPiaedBcqf/m9ETsZH0AYQQJvlcH8ztnC7gn1vzsPOciulSiBcIEPvhP3PjMbxv
2yZcEkI8x+cDpt624xX4zw+FcLLipyEd0S1EhNUvJSKatuAmxCuwJmAA4LdrtVi6Lhsmq5PpUkgX
Gxwnx0fz4iGXdHBZZUKI27EqYAAgr9yM177MRn6lmelSSBd5+t4I/HF6T6bLIITchnUBA9TN6H53
RwH1y7Ac3w/4++wYTBmhZLoUQkgTWBkw9fakqPGvb/NhttEtM7bpFizCO8/1wcDetFkYId6K1QED
AEVVFrz2ZTbN/GeRmWPCsWxaNMRCnx1lTwgnsD5g6n3wQyG2HqtgugzSCUqFEG8/E4vhCTQEmRBf
wJmAAYDLeXq8/nUuyjRWpksh7fTYCCX+b0YvBIip1UKIr+BUwAB1AwA+21WMbccrwK2f3DeFyAV4
6/exGJ1Em84R4ms4FzD1Lt3Q440tN1CssjBdCmnGlBFKvDa9J81tIcRHcTZgAMBmd2Hr8QqsP1gK
vcnBdDnkpsFxcvz1iV7oQ4uZEuLTOB0w9WqNDqzdX4IdJythd3D+r4MxsZESvDo1GmP60+0wQtiA
AuYWpRorPvm5CL9c1DBdCqeEyoVY+HAUpo0Kgx+P6WoIIe5CAdOEq0VGrNtXguMZ1UyXwmoiAQ/P
PdAdz9wXSTtPEsJCFDAtyC41Yc2+Ehy5rGW6FFYJkQvw5NgIPDk2HEEBAqbLIYR4CAVMG+RXmrFm
XwkOXtDQ0OZOiO8uwdP3RuLhoaEQCuheGCFsRwHTDkVVFnx/uhI/n1GhxmhnuhyfMaZ/IJ4eH4nk
RJqBTwiXUMB00NE0LX46o8LJK9RP05QAsR8mDQ7FnImR6BUuZrocQggDKGA6qarWhl1nVdh/Xs35
BTXDFEKMvysIEwaFYCS1VgjhPAoYNyrTWHH4sgbH0qtxKVfHie2be4aJMXFQMCYMDMaAXgHgUdcK
IeQmChgPqTU6cDRNi1+vVON0Zg1rtnEWCXgYFCPDiL6BuHdgEGIjabY9IaRpFDBd5Ldrtbicr0dG
vgEZBXpo9b4zSGBInBzDExQYEi/H4D5ypsshhPgIChiGlGqsyCwy4HKeHlcL6kLHamf+nyIsUIiY
SAnujpFhWIICQ+IoUAghHUMB4yVcLkBVa0OZxoIyjRWlWgvKNVaUaeu+LtNYYLR0/jabgM9D9xB/
RCv90SPMH9FKMXoo//cnzU8hhLgLBYyPMZidsNmdsNidsNpcsNqdsNhdsNqccDhdEAp4EAn8IBLw
ILz5p0jgB6GAB5mYlr0nhHQdChhCCCEeQSsMEkII8QgKGEIIIR5BAUMIIcQjKGAIIYR4BAUMIYQQ
j6CAIYQQ4hEUMIQQQjyCAoYQQohHUMAQQgjxCAoYQgghHkEBQwghxCMoYAghhHjE/wfL6jacvjCS
wgAAAABJRU5ErkJggg==
`;
