import classNames from 'classnames';
import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import InputError from '../../input-error/InputError';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export type TextInputAreaProps = {
  className?: string;
  label: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  amountRows?: number;
};

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputAreaProps & Partial<FieldInputProps<string>>;

export const TextInputArea: FC<DeIntl<Props>> = (props) => {
  const {
    label,
    placeholder,
    wrapMultipleErrorLines,
    amountRows = 5,
    className,
    error,
    disabled,
    onChange,
    isTouched = false,
    value,
    onBlur,
    inputRef,
    name,
  } = props;

  const fieldClassName = classNames(
    styles.TextInputAreaInput,
    className,
    isTouched && {
      [styles.error]: error,
    }
  );

  function renderBody() {
    return (
      <textarea
        rows={amountRows}
        onChange={onChange}
        onBlur={onBlur}
        className={fieldClassName}
        placeholder={placeholder}
        disabled={disabled}
        style={{ resize: 'none' }}
        ref={inputRef}
        value={value || ''}
        aria-invalid={error ? 'true' : 'false'}
        name={name}
      />
    );
  }

  function renderHeader() {
    return (
      <div className={styles.TextInputAreaHeader}>
        {label && <p className={styles.TextInputAreaLabel}>{label}</p>}
        {error && (
          <div
            className={styles.TextInputAreaError}
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputArea}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  }

  return renderDefaultElement();
};
export const IntlTextInputArea: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputArea} props={props} />;
};

export default IntlTextInputArea;
