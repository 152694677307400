import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import HabitatsContainer from '../../dashboard/habitats/Habitats.container';
import * as routes from '../../index/routes';
import NewAugurWizard from '../augur/NewAugurWizard';
import {
  AddHabitatWizard,
  UpdateHabitatWizard,
} from '../habitat-wizard/HabitatWizard';
import NoPermissions from '../no-permissions/NoPermissions';

interface ModelManagementProps {
  missingPermissionsError?: boolean;
  fetchSummary(...args: unknown[]): unknown;
}

export default class ModelManagement extends Component<ModelManagementProps> {
  componentDidMount() {
    // On first load fetchSummary is called twice, once from here and once from the dashboard init saga.
    // Both may be needed: this for later switching to the tab and the saga for loading arbitrary urls
    const { fetchSummary } = this.props;
    fetchSummary();
  }

  render() {
    const { missingPermissionsError } = this.props;

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight />;
    }
    return (
      <Switch>
        {/* New Augur */}
        <Route
          path={`${routes.app.prefix}${routes.app.models}/${routes.app.newAugur}`}
          component={NewAugurWizard}
        />

        {/* Update Habitat */}
        <Route
          path={`${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}/:habitatCode`}
          component={UpdateHabitatWizard}
        />

        {/* New Habitat */}
        <Route
          path={
            routes.app.prefix + routes.app.models + '/' + routes.app.newHabitat
          }
          component={AddHabitatWizard}
        />

        <Route component={HabitatsContainer} />
      </Switch>
    );
  }
}
