import { defineMessages } from 'react-intl';

export default defineMessages({
  msgCode: {
    id: 'augur.modelArchive.code',
    defaultMessage: 'Code',
  },
  msgActiveSince: {
    id: 'augur.modelArchive.activeSince',
    defaultMessage: 'Active Since',
  },
  msgActiveUntil: {
    id: 'augur.modelArchive.activeUntil',
    defaultMessage: 'Active Until',
  },
  msgStatus: {
    id: 'augur.modelArchive.status',
    defaultMessage: 'Status',
  },
  msgActive: {
    id: 'augur.modelArchive.active',
    defaultMessage: 'Active Model',
  },
  msgActivate: {
    id: 'augur.modelArchive.activate',
    defaultMessage: 'Activate',
  },
  msgHeadline: {
    id: 'augur.modelArchive.headline',
    defaultMessage: 'Model Archive',
  },
  msgConfirmationModal: {
    id: 'augur.modelArchive.confirmation',
    defaultMessage:
      'Are you sure you want to restore the selected model with Code {code} for the Augur?\n\n' +
      '<strong>Warning:</strong> The settings that were active while this model was trained will be restored too.',
  },
  msgPagingHeader: {
    id: 'augur.modelArchive.paging.header',
    defaultMessage: 'Showing Models {from}-{to}',
  },
  msgNotificationSuccessTitle: {
    id: 'augur.modelArchive.notification.success.title',
    defaultMessage: 'Activated successfully',
  },
  msgNotificationSuccessDescription: {
    id: 'augur.modelArchive.notification.success.description',
    defaultMessage:
      'The model with code {modelCode} was activated successfully',
  },
  msgNotificationErrorTitle: {
    id: 'augur.modelArchive.notification.error.title',
    defaultMessage: 'Error',
  },
  msgNotificationErrorDescription: {
    id: 'augur.modelArchive.notification.error.description',
    defaultMessage: 'The model with code {modelCode} could not be activated',
  },
});
