// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/shap-chart/v1/type.ts' --type 'ConfigShapChartConfig'
export const schemaConfigShapChart = {
  $ref: '#/definitions/ConfigShapChartConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ShapChartConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigShapChartConfig: {
      $ref: '#/definitions/Config%3CShapChartConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'ShapChartV1ReportData'
export const schemaReportTypeShapChart = {
  $ref: '#/definitions/ShapChartV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    Point: {
      items: [
        {
          title: 'x',
          type: 'number',
        },
        {
          title: 'y',
          type: 'number',
        },
      ],
      maxItems: 2,
      minItems: 2,
      type: 'array',
    },
    ShapChartData: {
      additionalProperties: false,
      properties: {
        distribution: {
          items: {
            $ref: '#/definitions/Point',
          },
          type: 'array',
        },
        feature: {
          type: 'string',
        },
        featureType: {
          enum: ['numerical', 'categorical'],
          type: 'string',
        },
      },
      required: ['feature', 'featureType', 'distribution'],
      type: 'object',
    },
    ShapChartV1ReportData: {
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      items: {
        $ref: '#/definitions/ShapChartData',
      },
      type: 'array',
    },
  },
};
