import React, { Component } from 'react';
import TextInputArea from '../../../../atoms/input-elements/text-input-area/TextInputArea';

interface RepositoryStepDescriptionProps {
  meta: {
    touched?: boolean;
    asyncValidating?: boolean;
    valid?: boolean;
    error?: {
      id: string;
      defaultMessage: string;
    };
  };
  input?: {
    value?: string;
    onChange(...args: unknown[]): unknown;
    onFocus(...args: unknown[]): unknown;
    onBlur(...args: unknown[]): unknown;
  };
}

export default class RepositoryStepDescription extends Component<RepositoryStepDescriptionProps> {
  render() {
    const {
      meta: { touched, error },
      input: { value, onChange, onFocus, onBlur },
    } = this.props;
    return (
      <div className={'GenericFormStep--field'}>
        <TextInputArea
          amountRows={5}
          touched={touched}
          error={error}
          disabled={false}
          hasLabel={true}
          label={{
            id: 'no-id',
            defaultMessage: 'Enter Description',
          }}
          placeholder={{
            id: 'no-id',
            defaultMessage: 'Enter Repository Description',
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={() => onBlur(value)}
          onFocus={onFocus}
        />
      </div>
    );
  }
}
