import { DeepPartial } from 'react-hook-form';
import { Config } from '../../../../../pages/augur/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { S3ParquetColumnSelectErrorType } from './S3ParquetColumnSelect';
import { S3FileSelectAugurSettings } from '../../s3FileSelect/v1/type';
import { S3BucketSelectAugurSettings } from '../../s3BucketSelect/v1/type';
import { S3ParquetColumnSelectV1AugurSettings as S3ParquetColumnSelectAugurSettings } from 'common/dist/types/augurSettings/s3ParquetColumnSelectV1AugurSettings';

export type { S3ParquetColumnSelectAugurSettings };

export type S3ParquetColumnSelectConfig = {
  s3Bucket: S3BucketSelectAugurSettings;
  file: S3FileSelectAugurSettings;
  isOptional?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
};
export type ConfigS3ParquetColumnSelectConfig =
  Config<S3ParquetColumnSelectConfig>;

export type PartialS3ParquetColumnSelectAugurSettings =
  S3ParquetColumnSelectAugurSettings;

export type S3ParquetColumnSelectValidationError =
  DeepPartial<S3ParquetColumnSelectErrorType>;

export const configAllowedInputsS3ParquetColumnSelect: AllowedInputs<S3ParquetColumnSelectConfig> =
  {
    s3Bucket: [
      { type: SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT, version: 'v1' },
    ],
    file: [{ type: SETTINGS_ELEMENT_TYPES.S3_FILE_SELECT, version: 'v1' }],
  };

export const configTypeS3ParquetColumnSelect = `{ 
  s3Bucket: { source: 'input-element', elementUuid: string } |
      { source: 'hard-coded', value: {
              dataSourceCode: string,
              bucketName: string
      }},
  file: { source: 'input-element', elementUuid: string } | 
      { source: 'hard-coded', value: string },
  isOptional?: {source: 'hard-coded', value: boolean },
  isMulti?: {source: 'hard-coded', value: boolean },
  isClearable?: {source: 'hard-coded', value: boolean },
  isSearchable?: {source: 'hard-coded', value: boolean },
}`;

export const augurSettingsTypeS3ParquetColumnSelect = `string[]`;

export const defaultConfigS3ParquetColumnSelect: ConfigS3ParquetColumnSelectConfig =
  {
    s3Bucket: { source: 'input-element', elementUuid: '' },
    file: { source: 'input-element', elementUuid: '' },
    isOptional: { source: 'hard-coded', value: false },
    isMulti: { source: 'hard-coded', value: false },
    isClearable: { source: 'hard-coded', value: true },
    isSearchable: { source: 'hard-coded', value: true },
  };

export const defaultAugurSettingsDefaultS3ParquetColumnSelect: PartialS3ParquetColumnSelectAugurSettings =
  [];

// JSONSchemaType<S3ParquetColumnSelectAugurSettings>
export const defaultValidationSchemaS3ParquetColumnSelect = {};
