import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  CassandraTableSelectConfig,
  ConfigCassandraTableSelectConfig,
} from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { ElementOptionType } from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import InputElementSelect from '../../../../../atoms/react-hook-form-input-elements/input-element-select/InputElementSelect';
import { IntlCheckbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';

const CassandraTableSelectConfigFormComponent: FC<
  ConfigFormComponentProps<CassandraTableSelectConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } = useForm<ConfigCassandraTableSelectConfig>({
    defaultValues: configValue,
  });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const keyspaceOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type === SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='keyspace'
        control={control}
        render={({ field }) => {
          return (
            <InputElementSelect
              value={
                field.value.source === 'input-element'
                  ? field.value.elementUuid
                  : ''
              }
              isInputElement={field.value.source === 'input-element'}
              onChange={(newValue) => {
                field.onChange(newValue);
                updateConfig();
              }}
              options={keyspaceOptions}
              label={augurElementsMessages.cassandraKeyspaceLabel}
              placeholder={augurElementsMessages.selectCassandraKeyspace}
            />
          );
        }}
      />
      <Controller
        name='showTable.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.showTable}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />
    </div>
  );
};

export default CassandraTableSelectConfigFormComponent;
