import React, { FC } from 'react';

import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import styles from '../styles.module.scss';

type Props = {
  headline: string;
  description: string;
};

const EmptyAugur: FC<Props> = ({ headline, description }) => {
  return (
    <div className={styles.emptyViewAugur}>
      <IndicatorEmpty
        classNameImage={'view-augur-empty-pic'}
        headline={{
          id: 'no-id',
          defaultMessage: headline,
        }}
        description={{
          id: 'no-id',
          defaultMessage: description,
        }}
      />
    </div>
  );
};

export default EmptyAugur;
