import { Config } from '../../../../../pages/augur/type';
import { TextOutputV1ReportData as TextOutputReportData } from 'common/dist/types/reportData/textOutputV1ReportData';
export type { TextOutputReportData };

export type TextOutputConfig = {};

export type ConfigTextOutputConfig = Config<TextOutputConfig>;

export const configTypeTextOutput = `{}`;

export const reportDataTypeTextOutput = `{
    text: string
}`;

export const defaultConfigTextOutput: ConfigTextOutputConfig = {};

export const defaultReportDataDefaultTextOutput: TextOutputReportData = {
  text:
    '# H1 Heading\n' +
    '## H2 Heading\n' +
    '### H3 Heading\n' +
    'This is **bold text**',
};
