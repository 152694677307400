import { directoryExtension as repositoryDirectoryExension } from './config';

export const formName = 'cloneRepository';
export const fieldPath = 'path';

export const form = {
  form: formName,
};

/**
 * Validation function for the Field "path"
 * @param selectedDirPath
 * @param allValues (just a placeholder, since the signature of the validate function for redux-form requires it)
 * @param notebooksInCurrentDir
 */
export function validatePath(
  selectedDirPath,
  allValues,
  { repoName, notebooksInCurrentDir }
) {
  // 1. Make sure the repository isn't cloned into another repository
  if (selectedDirPath) {
    // --- Should always be true, but just to be sure.
    for (const dir of selectedDirPath) {
      if (dir.endsWith(repositoryDirectoryExension)) {
        return "A repository can't be cloned into another repository";
      }
    }
  }
  // 2. Make sure there is no file / directory with the name of the repository in this path
  if (notebooksInCurrentDir && notebooksInCurrentDir.value.length > 0) {
    // --- Should always be true, but just to be sure.
    for (const file of notebooksInCurrentDir.value) {
      if (
        file.name === repoName ||
        file.name === `${repoName}${repositoryDirectoryExension}`
      ) {
        return 'A file with the given name already exists in the selected directory';
      }
    }
  }
  return true;
}
