import React, { FC } from 'react';
import './styles.scss';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';

export type Props = {
  /** Is the button expanded? */
  isExpanded: boolean;
  /** Callback for when the button is clicked */
  onClick: (isExpanded: boolean) => void;
  /** Size of the icon, default: 17 */
  size?: number;
  /** Show a gray background like for the IconButton? */
  withBackground?: boolean;
  /** Color of the icon, default: blue */
  color?: 'blue' | 'black';
  /** title HTML property of the icon. Index 0 is shown if isExpanded=true, index 1 of isExpanded=false*/
  title?: [string, string];
};

/**
 * Simple arrow icon that for example can act as a button to expand / collapse
 * @param props
 * @constructor
 */
const ExpandCollapseIcon: FC<Props> = (props) => {
  const { isExpanded, onClick, size, withBackground, color, title } = props;
  return (
    <div
      className={classNames('ExpandCollapseIcon', {
        'ExpandCollapseIcon--with-background': withBackground,
      })}
      onClick={() => onClick(!isExpanded)}
      title={isExpanded ? title?.[0] : title?.[1]}
    >
      <FiChevronDown
        data-testid={'Collapse'}
        size={size || 17}
        className={classNames(
          'ExpandCollapseIcon-icon',
          { ['ExpandCollapseIcon-icon-' + color]: color },
          { 'ExpandCollapseIcon-icon-expanded': isExpanded },
          { 'ExpandCollapseIcon-icon-collapsed': !isExpanded }
        )}
      />
    </div>
  );
};

export default ExpandCollapseIcon;
