import {
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { RootState } from '../../../../store/store';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import * as routes from '../../../index/routes';

type Props = {
  closeMenu: () => void;
};

type Entry = {
  title: string;
  description: string;
  icon: ReactNode;
  linkTo: string;
};

type QuickMenuEntryProps = {
  closeMenu: () => void;
  entry: Entry;
};

const QuickMenuEntry: FC<QuickMenuEntryProps> = ({ closeMenu, entry }) => {
  return (
    <div className={styles.entry}>
      <Link
        to={entry.linkTo}
        onClick={closeMenu}
        data-testid={`quickMenuLink_${entry.title}`}
      >
        <div className={styles.entryInner}>
          <div className={styles.iconContainer}>{entry.icon}</div>
          <div className={styles.textContainer}>
            <span className={styles.title}>{entry.title}</span>
            <span className={styles.description}>{entry.description}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const QuickMenuContent: FC<Props> = ({ closeMenu }) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu]);

  // --- Derive the menu entries
  const permissions = useSelector<RootState, Record<string, string[]>>(
    (state) => state.dashboard?.permissions?.data
  );

  const entries: Entry[] = [];

  if (
    hasAccessPermission(
      permissions,
      RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
    )
  ) {
    entries.push({
      title: 'Add Habitat',
      description: 'Create a new Habitat to the Model Management',
      linkTo: `${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}`,
      icon: <span className={'icon-home'} style={{ fontSize: '20px' }} />,
    });
  }

  if (
    hasAccessPermission(
      permissions,
      RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
    )
  ) {
    entries.push({
      title: 'Add Notebook',
      description: 'Create a new Notebook in the AI Workbench',
      linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.createNotebook}`,
      icon: <FiEdit size={16} />,
    });
  }

  return (
    <div ref={menuRef} className={styles.quickMenuContent}>
      {entries.length === 0 && (
        <div className={styles.empty}>
          <span className={styles.emptyMessage}>
            No actions available, insufficient permissions
          </span>
        </div>
      )}
      {entries.map((entry) => (
        <QuickMenuEntry entry={entry} closeMenu={closeMenu} />
      ))}
    </div>
  );
};
export default QuickMenuContent;
