import { flow } from 'lodash';
import React, { FC } from 'react';
import { IntlShape } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  intl: IntlShape;
  showSuggestions: () => void;
  search: (query: string) => void;
  input: {
    onChange: (value: string) => void;
    onFocus: () => void;
    value: string;
  };
};

const QueryInput: FC<Props> = ({ showSuggestions, intl, search, input }) => {
  const onChange = flow(
    input.onChange,
    () => search(input.value),
    showSuggestions
  );
  const onFocus = flow(input.onFocus, showSuggestions);

  const placeholder = intl.formatMessage({
    id: 'dashboard.search.search_placeholder',
  });

  return (
    <input
      type='text'
      className={styles.inputGroupField}
      placeholder={placeholder}
      autoComplete='off'
      {...input}
      // @ts-ignore
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default QueryInput;
