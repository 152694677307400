import React from 'react';
import styles from './styles.module.scss';

/* eslint-disable max-len */

function LogoIconOnly() {
  // TODO: Allow to specify a theme logo, as it's done in: frontend/src/js/components/atoms/icons/logo/Logo.tsx
  return (
    <svg width='29' height='29' viewBox='0 0 29 29' className={styles.logo}>
      <path
        fill='#fff'
        d='M29 23.294C29 26.444 26.446 29 23.295 29H5.705C2.555 29 0 26.443 0 23.294V5.704C0 2.554 2.555 0 5.705 0h17.59C26.445 0 29 2.554 29 5.704v17.59zm-6.28-10.702c-1.142 0-2.068.926-2.068 2.07 0 .14.014.277.04.41.005.058.012.115.012.174 0 1.038-.84 1.877-1.876 1.877-.27 0-.528-.06-.762-.166-.05-.03-.098-.06-.15-.088-.474-.437-.77-1.06-.77-1.755 0-.748.346-1.416.886-1.853 1.178-.58 1.99-1.79 1.99-3.19 0-1.963-1.592-3.554-3.555-3.554-1.858 0-3.384 1.428-3.54 3.248-.323 1.777-1.876 3.125-3.747 3.125-.377 0-.74-.055-1.084-.156-.35-.165-.74-.258-1.154-.258-1.51 0-2.73 1.222-2.73 2.73s1.22 2.732 2.73 2.732c1.428 0 2.598-1.098 2.72-2.494.395-1.547 1.796-2.692 3.468-2.692.428 0 .84.077 1.22.214.828.39 1.402 1.228 1.402 2.203 0 .698-.295 1.33-.77 1.774-.866.52-1.45 1.468-1.45 2.555 0 1.644 1.335 2.98 2.982 2.98 1.646 0 2.98-1.337 2.98-2.98 0-.187-.02-.366-.05-.54v.004c0-1.275 1.02-2.352 2.294-2.352.16 0 .314.018.464.05.167.043.34.067.52.067 1.144 0 2.07-.928 2.07-2.07-.002-1.142-.928-2.068-2.07-2.068z'
      />
    </svg>
  );
}

export default LogoIconOnly;

/* eslint-enable max-len */
