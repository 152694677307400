export const barChartImg = `
iVBORw0KGgoAAAANSUhEUgAAAdUAAAEbCAYAAACMWx4zAAAABHNCSVQICAgIfAhkiAAAABl0RVh0
U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAAqdEVYdENyZWF0aW9uIFRpbWUAVHVlIDA1
IE1hciAyMDI0IDA5OjEyOjI2IENFVBF/y38AABOiSURBVHic7d1LU1t3msfx39EVCYHBGMzV2Ca+
EV9iO9V0OpNOVW+mplJds5xlXkBewOxnNy8gLyCzm6rZ9XoWmRp3TTozJsYBc7FlYxvfAIORkASS
zpkFICMBDpIeCcl8P1Ve6Ojo4bGkPz/+/3OO5Hie5wkAAFTNd9QNAADwsSBUAQAwQqgCAGCEUAUA
wAihCgCAEUIVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACOEKgAA
RghVAACMEKoAABghVAEAMEKoAgBgJHDUDTSq735Yqvix3397yrATAECzIFQB4BiqZuIgMXk4iHmo
zj56onRmQ/I8ZXN5dXd1aniov3B/OrOhickZhUNBbWxmNXppRG2x1sL9c/F5JRJJyXHUEg5p9NIn
1i0CAFAT5qF6ceRs0e3//WVSA32nFQj4JUnjE1P6/OZVhYJBua6rOz+N66svbkuSVlbXlEpndOvG
p5Kk2YdP9PL1ovpOd1u3CQCAuZqfqJTZ2FBmY0OS5LqePEmhYHDrh/t8ikRalNnYlCQ9XXip88OD
hceeGx7Qs4VXtW4RAAATNTmm+vzFa71eXNLCi9cKh0OKtUYlSal0WrFopGjfE20xJZPragmHlFxP
FS0FB4NB5XK5WrQIAIC5moTqYP9pDfaf1o2rl7W8vFLYnsvnFdyepe4IhgLK5fOSJMfZW8vnO9xk
+scff9x3+9dff33IrovrvJh+V7S9//IfKqqzm+M4+uMf/1h1Hany/xd16l+H1506zVrnxfRf991u
8fuwkn6s6tRSTc/+Dfj9yubyWlx+q+6uk1u3s9mifbKbOcWiWzNZz9tbw3XdQ/2sW7duVd3v7jrd
08smdapFHepQhzpHVaf77HWTOo32/6qlml9S43meUqmM1CVFIxElU+mi+98lkhoa7JMkxVqjSiTX
C0vA2WxWgcDhWmxrazPpd6dOsGXDpE61qEMd6lDnqOoEW2ImdRrt/1VLpicq5fN5JZLrRdsezz9X
18mOrR/mc+RIhdmq67pKpzNqCYckSWcG+hSff77rsQsaGui1bBEAgJoxnak6jk9Pni5snVzkOEql
0joz1Fc4UUmSbl4f1d2JB4XrVD+7drlwX2dHu5aWV3T33mThOlUupwEANAvTUPX5HF0bvfjBfSIt
YY3dPnid/sLIsGVLAADUDR+oDwCAEUIVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDA
CKEKAIARQhUAACOEKgAARghVAACMEKoAABghVAEAMEKoAgBghFAFAMAIoQoAgBFCFQAAI4QqAABG
CFUAAIwQqgAAGCFUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAjhCoAAEYIVQAAjASOugEAQPP77oel
ih/7/benDDs5WsxUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBC
qAIAYIRQBQDACKEKAIARQhUAACOEKgAARghVAACMEKoAABghVAEAMEKoAgBghFAFAMAIoQoAgBFC
FQAAI4QqAABGCFUAAIwQqgAAGCFUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAjgaNuwMp3PyxV9fjv
vz1l1AkA4LhipgoAgBFCFQAAI4QqAABGPppjqgBQLc7NQLUIVQCoEUL6+GH5FwAAI8xUgQbAjAb4
ODBTBQDAiOlM1fOkmYdxpdIZBfx+5fJ5DQ/2q+tkR2GfdGZDE5MzCoeC2tjMavTSiNpirYX75+Lz
SiSSkuOoJRzS6KVPLFsEAKBmjEPVVW/PKXWcaC9su/PTXX05dqtwe3xiSp/fvKpQMCjXdXXnp3F9
9cVtSdLK6ppS6Yxu3fhUkjT78Ilevl5U3+luyzaBjx7LycDRMF3+9fl8RYEqSS0tYXmeJ0lyXU+e
pFAwWNg/EmlRZmNTkvR04aXODw8WHntueEDPFl5ZtggAQM3U/JhqPp+X4ziSpFQ6rVg0UnT/ibaY
ksl1SVJyPVW0FBwMBpXL5WrdIgAAJmp69u/9qVkNDfQVbufyeQW3Z6k7gqGAcvm8JGk7e4v4fIfL
/ZUXM/tu7+y/dMhut0xNTW3XWzGps5vjOLpy5UrVdSRpdHSUOk1Sp5LX3er9bD0uSjXS82xRZ2fc
V/o8T029Kbmnp6I67/vbqndUz8/H+j6spZqF6vRcXK3RSNHx0IDfr2w2W7RfdjOnWDQqaetEp1Ku
6x7q5/kC4cqb3SUcDpvU26mzm7PfXw0V1KkEdY6uTiWvu9X72XpcfOx1Kn2+dh5XbV+lP7/SelbP
z8f6PqylmoTqXHxefr9f588OFW2PRiJKptJF294lkhoa3JrNxlqjSiTXC0vA2WxWgcDhWjzRc7b6
xiWNjIxs16vuRI+dOtWizvGsY/V+th4XH3udSsf9zvM8MlJygtd/l1ev9PXaU++QeB8eHfNjqvH5
5/JcTxfOD+/9YT5HjlSYrbquq3Q6o5ZwSJJ0ZqBP8fnnhf0fzy9oaKDXukUAAGrCdKaaXE/p/tSs
hof69cuv04XtA3096u46KUm6eX1UdyceFK5T/eza5cJ+nR3tWlpe0d17k4XrVLmcBgC2VHOpFJdJ
1YdpqMZao/rHf/jTB/eJtIQ1dvv6gfdfGNk7wwUAoBnwMYUAABghVAEAMEKoAgBghFAFAMAI36cK
4Ddx1ilwOMxUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAjhCoAAEa4pKYJcXkDADQmQhUA0FCqmThI
Rzt5YPkXAAAjhCoAAEZY/gVQV828tAf8FmaqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARzv4FKsQn
WwEoxUwVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACN8ohIaGp9a
BKCZMFMFAMAIoQoAgBFCFQAAI4QqAABGCFUAAIwQqgAAGOGSmmOumktWJC5bAYDdmKkCAGCEUAUA
wAihCgCAEUIVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACOEKgAA
RghVAACMEKoAABghVAEAMMKXlANoat/9sFTxY7//9pRhJwAzVQAAzBCqAAAYYfkXx0Y1y4QSS4UA
fhszVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACOEKgAARsw//CGVzujR46daerui
C+eHNdjfW3R/OrOhickZhUNBbWxmNXppRG2x1sL9c/F5JRJJyXHUEg5p9NIn1i0CAFAT5qG6urqm
iyNndbLzhDzP23P/+MSUPr95VaFgUK7r6s5P4/rqi9uSpJXVNaXSGd268akkafbhE718vai+093W
bQIAYM58+be/r0fhcGjf+1zXkycpFAxu/XCfT5FIizIbm5KkpwsvdX54sLD/ueEBPVt4Zd0iAAA1
Uddjqql0WrFopGjbibaYksl1SVJyPVW0FBwMBpXL5erZIgAAFavrB+rn8nkFt2epO4KhgHL5vCTJ
cfY+xuc7XO6nE8v7bo+0dZXV4+Li4na9tyZ1JOmf//19rXLrpBPL+td/Olmy7W1Fdbb6Kl2S3+dJ
P0SdHTv1urvLW6Lf/fzsVm4dq9edOvWps7i4uGdsVVrnfW+Vj6/S9+FOrWYfX/u9XouLHuOrDuoa
qgG/X9lstmhbdjOnWDQqSdrnEKxc1z1U7bU3T/bdXu6THY/Ht+utmtTZXctxnLLrrL15onh8tWTb
akV1tvpaLblnpKI6O3bq7QzWw34TzOtHd/fd/h//8vdV9bOj0uenFnUqfd1r1c9R1onH43vGVqV1
3vf2vl41dXbXatTxVWmdnVoWdaTmfx/WUl1DNRqJKJlKF217l0hqaLBPkhRrjSqRXC8sAWezWQUC
h2vx9Mhtkx7Hxsa26k1X9zVhO3WqrXV65LbGxoq/cqySejvPT2mtfyuzVunzXFqv0jqVok5z1Rkb
G6t6bO3U2VFNvd11qqnVDOOrklqN9v6xqlNLdT2m6vM5cqTCbNV1XaXTGbVsn9h0ZqBP8fnnhf0f
zy9oaKB3v1IAADQc++tUU2nNbl9r6nnS0ttVXTg/rNbtE5RuXh/V3YkHhetUP7t2ufDYzo52LS2v
6O69ycJ1qlxOAwBoFuahGo1G9NnVywfeH2kJa+z29QPvvzAybN0SAAB1wccUAgBghFAFAMAIoQoA
gBFCFQAAI4QqAABGCFUAAIwQqgAAGCFUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAjhCoAAEYIVQAA
jBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACOEKgAARghVAACMEKoAABghVAEAMEKoAgBg
hFAFAMAIoQoAgBFCFQAAI4QqAABGCFUAAIwQqgAAGCFUAQAwQqgCAGCEUAUAwAihCgCAEUIVAAAj
hCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAIARQhUAACOEKgAARghVAACMEKoAABgh
VAEAMEKoAgBghFAFAMAIoQoAgBFCFQAAI4QqAABGCFUAAIwQqgAAGCFUAQAwQqgCAGCEUAUAwAih
CgCAEUIVAAAjhCoAAEYIVQAAjBCqAAAYIVQBADBCqAIAYIRQBQDACKEKAICRwFE3UGouPq9EIik5
jlrCIY1e+uSoWwIA4FAaaqa6srqmVDqjWzc+1a3rowr4A3r5evGo2wIA4FAaKlSfLrzU+eHBwu1z
wwN6tvDqCDsCAODwGipUk+sptcVaC7eDwaByudwRdgQAwOE1VKg6zt5tPl9DtQgAwIEcz/O8o25i
x52/jevL390s2vbXv43rDyXb9vOXv/xl3+1//vOfy+qhlnV8Pp+++eabhumHOvWpw+tOHeo0Vp1a
aqhQvTc5o/PDg4Ul4Gw2q/H70/rdrWtH3BkAAL+todZWzwz0KT7/vHD78fyChgZ6j7AjAAAOr6Fm
qpI092heiSTXqQIAmk/DhSoAAM2qoZZ/AQBoZoQqAABGCFUAAIw03AfqH8TzPP1y/4HkOMrl8urv
7dZA3+kD93+z9FZPni4oGAwon8/r5rUr8vv9dey4MaUzG5qYnFE4FNTGZlajl0aKPsVqh+dJMw/j
SqUzCvj9yuXzGh7sV9fJjiPouvFU8sUPyyur+vHOz/rTV2PqONFehy4bW7ljOpvN6t7krAJ+nxzH
UVusVefPDtWx48Z02DG9Y2JyRrl8Xj7Hket5unntipz9PnkHFWmaUJ15+Fj9vT063XNKkvTXn8fV
3XVSoVBwz76u62pmLq6vvvhckvRuLamJqVndvHalrj03ovGJKX1+86pCwaBc19Wdn8b11Re39+zn
ea56e04V/fK/89NdfTl2q57tNqTdX/wgSbMPn+jl60X1ne4+8DGeJ01NP9KlC+fq1WbDK2dMS9L/
3ZvSZ9cuqyUcrmebDe+wY1qSXrx6o0ikRRfOD0uSXr1Z0qMnz/TJuTP1bPmj1jTLv4vLK4XBJ0ln
BvoP/AabxeUV9fa8/wV3oj2mVCpd8x4bnet68iSFglu/tHw+nyKRFmU2Nvfs6/P59symWlrC4mTx
yr74YeZhXJ+cPyM/H7tZUM6YTmc21BqNEKglyhnTkrS+nlZnx/tx3dnRrrVEsh6tHhtNM8J9JcsT
J9pjSiTX9903kVxXe3usaBvLG1IqnVYsGinadqItpuQBz2OpfD7P86jyv/ghndnQ6rvEB2eyx1E5
Y3r13ZraYq2anHmoX+4/0K8P5pTP5+vRZkMrd0wPDfRqaXmlcPvps5e8L401zfKv4ysegB/6RZbL
5RUMFP/X/P6m+fuhZnL5vILB4qW1YCig3CF+Od2fmtXQQF+tWmsq5X7xwy/3H+gzDj3sUc6YzmZz
movP6+9+f1uRlrCS6yn9PP6rfv/5jXq02rDKHdPhcEhLyyv6cfFnBQMBbW5m9fWXv6tHq8dG04Sq
5xYvO2azWQUC+7cfCPiVLRmc+bxbs96aRcDvVzabLdqW3cwpFo1+8HHTc3G1RiP8RbttvxVw193/
/fXi1Rt1drQr0sKyZalyx3RX54nC8xhrjUry5Lrusf4mq3LH9PTcY53uOVV0TPXXB7O6Nnqx5r0e
F03zbiw9lvduLXngGW5tsVatrRUfJ+BYoBSNRJQsObb8LpFU7ANnCs7F5+X3+znLcpdYa7RomfJD
YbD8dlWb2ZzuT83q/tSsXrx6o7lH81peWa1Xuw2rnDF9or1N/tLVJ59/3z9wjpNyx/RaIqGRs+9P
SurtOaV1zjcx1TSheqqrU6/fLBVuP1s4+FhAd1enXr15f8LDu7WkoiXHHY4jn8+RIxX+snVdV+l0
Ri3h0L77x+efy3O9wl+12FLOFz9cG72o66MXdW37X39vjy6MDKurk0uTyhnTrdGINjc3CyHqup5y
+fyxP6xT7pg+2dGht7v+oEulMwofcLY1KtM0n/37W9e0TU7P6dPLFwq3i65TzeV18zrXqUofvqYt
lc7o9eKSzp0ZVHI9pf/8r//R8FB/0eMH+nrU3XXyKFpvKB/64ofnL16pvS2m9rbYnsdNz8X3XKp0
XJU7phPJdc08fLx1LDCb1ZWLI9vLwMfbYce0tPWc3/t1Wo7jyHEcbWxmdePqpcLZw6he04QqAACN
7nivnQAAYIhQBQDACKEKAIARQhUAACOEKgAARghVAACMEKoAABj5f0KqrsRCAK3hAAAAAElFTkSu
QmCC
`;
