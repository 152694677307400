import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  ElementOptionComponent,
  ElementOptionType,
} from '../../../molecules/augur-layout-elements/settings-elements/common/ElementOptionComponent';
import { DropdownSelectInput } from '../dropdown-select-input/DropdownSelectInput';
import { MdInput } from 'react-icons/md';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ConfigEntry } from '../../../pages/augur/type';

type InputElementSelectProps = {
  onChange: (value: ConfigEntry<unknown> ) => void;
  isInputElement: boolean;
  value: string;
  options: ElementOptionType[];
  label: MessageDescriptor;
  placeholder: MessageDescriptor;
};

const InputElementSelect: FC<InputElementSelectProps> = ({
  isInputElement,
  value,
  options,
  label,
  placeholder,
  onChange
}: InputElementSelectProps) => {
  const intl = useIntl();

  return (
    <div>
      <div className={classNames(styles.lightText, styles.labelContainer)}>
        {intl.formatMessage(label)}
        <MdInput
          title={intl.formatMessage(
            augurElementsMessages.elementIsInputElement
          )}
        />
      </div>
      <DropdownSelectInput<ElementOptionType, false>
        onChange={(option) => {
          onChange({
            source: 'input-element',
            elementUuid: option?.uuid || ''
          })
        }}
        value={options.find((option) => option.uuid === value)}
        placeholder={
          isInputElement
            ? intl.formatMessage(placeholder)
            : intl.formatMessage(augurElementsMessages.elementIsHardCoded)
        }
        options={options}
        customComponents={{ Option: ElementOptionComponent }}
      />
    </div>
  );
};

export default InputElementSelect;
