import { Config, ConfigEntry } from '../type';

export function transformConfigToConfigProps<T>(
  config: Config<T>,
  inputs: Record<string, unknown> = {}
): T {
  return Object.entries<ConfigEntry<T[keyof T]>>(config).reduce(
    (acc, [key, value]) => {
      if (value.source === 'input-element') {
        return { ...acc, [key]: inputs[value.elementUuid] } as T;
      } else if (value.source === 'hard-coded') {
        return { ...acc, [key]: value.value } as T;
      } else {
        return { ...acc, [key]: value } as T;
      }
    },
    {} as T
  );
}
