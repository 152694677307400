import {
  GroupType,
  NodeType,
  PipelineTuningValue,
  PipelineTuningValueNode,
} from 'common/dist/types/pipeline';
import React, { FC } from 'react';

import PipelineTuningSelection from './pipeline-tuning-selection/PipelineTuningSelection';
import {
  PipelineTuningAugurSettings,
  PipelineTuningConfig,
  PipelineTuningValidationError,
} from './type';
import { isValidNumber } from '../../../../../../utils';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  PipelineTuningAugurSettings,
  PipelineTuningConfig,
  PipelineTuningValidationError
>;

export type PipelineTuningErrorType = {
  // errorId: pipelineIndex-node.id-parameter.id
  [parameterPath: string]: string | undefined;
};

const PipelineTuning: FC<Props> = (props) => {
  const {
    config: { pipelineTuningSchemas },
    value,
    onChange,
    error,
    readOnly,
  } = props;

  const unfoldNode = (node: NodeType): PipelineTuningValueNode => {
    return {
      id: node.id,
      type: node.type,
      nodeType: node.nodeType,
      displayName: node.displayName,
      description: node.description,
      isActive: !node.isInactive,
      isTuneable: node.isTuneable,
      isEditable: node.isEditable,
      staticParameters: node.staticParameters.map((staticParameter) => {
        const isValidNum = isValidNumber(staticParameter.default.toString());
        return {
          displayName: staticParameter.displayName,
          id: staticParameter.id,
          value: isValidNum
            ? Number(staticParameter.default)
            : staticParameter.default,
        };
      }),
      tuningParameters: node.tuningParameters.map((tuningParameter) => {
        return {
          displayName: tuningParameter.displayName,
          id: tuningParameter.id,
          valueCandidates: tuningParameter.default
            ? tuningParameter.default.map((val) => {
                const isValidNum = isValidNumber(val.toString());
                if (isValidNum) {
                  return Number(val);
                } else {
                  return val;
                }
              })
            : [],
        };
      }),
    };
  };

  if (!value || value.length === 0) {
    const defaultValue: PipelineTuningValue[] = pipelineTuningSchemas?.map(
      (schema) => {
        const defaultNodes = schema.nodes.map((node) => {
          if (node.type.toString() === 'node') {
            return unfoldNode(node as NodeType);
          } else {
            const groupNode = node as GroupType;
            return {
              id: groupNode.id,
              type: groupNode.type,
              nodes: groupNode.nodes.map((node: NodeType) => {
                return unfoldNode(node);
              }),
            };
          }
        });

        return {
          displayName: schema.displayName,
          edges: schema.edges,
          id: schema.id,
          optimizationMethod: schema.optimizationMethod,
          nodes: defaultNodes,
        };
      }
    ) as PipelineTuningValue[];
    if (onChange) onChange(defaultValue);
  }

  return (
    <>
      {pipelineTuningSchemas && value && (
        <PipelineTuningSelection
          pipelineTuningSchemas={pipelineTuningSchemas}
          onChange={onChange ? onChange : () => {}}
          value={value}
          error={error}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default PipelineTuning;
