import React, { Component } from 'react';
import AceEditor from 'react-ace';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import 'brace/mode/markdown';
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';

import rehypeRaw from 'remark-rehype';

import { CProps } from './MarkdownCell.container';
import { AltaSigmaMarkdownCell } from '../../../../../../../../store/workbench/state.types';

import msgsWorkbench from 'common/dist/messages/workbench';

import rehypePlugins = ReactMarkdown.propTypes.rehypePlugins;

export interface Props {
  cell: AltaSigmaMarkdownCell;
  path: string;
  selectedCells?: string[];
  selectCells?: (path: string, cellIds: string[]) => void;
  changeSource: (...args: unknown[]) => void;
  readOnly?: boolean;
}

interface State {
  focus: boolean;
}

class MarkdownCell extends Component<
  Props & CProps & WrappedComponentProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }

  componentDidMount() {
    if (this.props.forceFocus === this.props.cell.id) {
      this.setState({ focus: true });
      this.props.clearForceFocus(this.props.path);
    }
  }

  render() {
    const { cell, readOnly, intl } = this.props;

    const sourceSafe = Array.isArray(cell.source)
      ? cell.source.join('')
      : cell.source;
    const sourceWithDefault =
      sourceSafe && sourceSafe.trim()
        ? sourceSafe
        : intl.formatMessage(
            msgsWorkbench.workbenchNotebookNotebookCellMarkdownEdit
          );
    const markDown = (
      <ReactMarkdown skipHtml={false} rehypePlugins={[rehypeRaw]}>
        {sourceWithDefault}
      </ReactMarkdown>
    );
    if (readOnly) return markDown;

    return (
      <div
        onClick={() => {
          this.props.selectCells(this.props.path, [this.props.cell.id]);
          this.setState({ focus: true });
        }}
        onBlur={() => this.setState({ focus: false })}
      >
        {this.state.focus ? (
          <div className={'editor-parent-container'}>
            <div className={'editor-container'}>
              <AceEditor
                focus={this.state.focus}
                width={'100%'}
                className={'markdown-cell-editor'}
                mode='markdown'
                theme='tomorrow'
                onChange={(source) => {
                  this.props.changeSource({
                    path: this.props.path,
                    cellId: cell.id,
                    source,
                  });
                }}
                showGutter
                highlightActiveLine
                value={
                  Array.isArray(cell.source)
                    ? cell.source.join('')
                    : cell.source
                }
                setOptions={{
                  maxLines: Infinity,
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: Infinity }}
                readOnly={!!readOnly}
              />
            </div>
          </div>
        ) : (
          markDown
        )}
      </div>
    );
  }
}

export default injectIntl(MarkdownCell);
