import { connect, ConnectedProps } from 'react-redux';
import ButtonBar from './ButtonBar';
import {
  firstSelectedCellIndex,
  firstSelectedCellType,
} from '../../../../../../redux/workbench/selectors/notebook.selectors';
import {
  saveNotebook,
  changeCellType,
  addCell,
  clearAllOutputs,
  copyCellsToClipboard,
  deleteCells,
  selectCells,
  pasteCellsFromClipboard,
  moveCellsUp,
  moveCellsDown,
  closeNotebook,
  showEditNotebook,
} from '../../../../../../redux/workbench/modules/notebook.module';
import { executeCells } from '../../../../../../redux/workbench/modules/notebook.websocket.module';
import {
  interruptKernel,
  restartKernel,
} from '../../../../../../redux/workbench/modules/kernel.module';
import { info as infoNotification } from 'react-notification-system-redux';
import { showAppArrangeModal } from '../../../../../../redux/workbench/modules/app.module';

function kernelIdSafe(session) {
  if (!session) return null;
  if (!session.kernel) return null;
  return session.kernel.id;
}

export function mapStateToProps(state, ownProps) {
  return {
    selectedCellType: firstSelectedCellType(state, ownProps.path),
    selectedCellIndex: firstSelectedCellIndex(state, ownProps.path),
    kernelId: kernelIdSafe(ownProps.session),
    clipboard: state.workbench.clipboard,
  };
}
export const mapDispatchToProps: {
  addCell: (...args: unknown[]) => void;
  executeCells: (...args: unknown[]) => void;
  selectCells: (...args: unknown[]) => void;
  changeCellType: (...args: unknown[]) => void;
  restartKernel: (...args: unknown[]) => void;
  clearAllOutputs: (...args: unknown[]) => void;
  saveNotebook: (...args: unknown[]) => void;
  copyCellsToClipboard: (...args: unknown[]) => void;
  infoNotification: (...args: unknown[]) => void;
  deleteCells: (...args: unknown[]) => void;
  pasteCellsFromClipboard: (...args: unknown[]) => void;
  moveCellsUp: (...args: unknown[]) => void;
  moveCellsDown: (...args: unknown[]) => void;
  closeNotebook: (...args: unknown[]) => void;
  showAppArrangeModal: (...args: unknown[]) => void;
  interruptKernel: (...args: unknown[]) => void;
  showEditNotebook: (name: string, path: string) => void;
} = {
  saveNotebook,
  changeCellType,
  addCell,
  executeCells,
  restartKernel,
  clearAllOutputs,
  copyCellsToClipboard,
  infoNotification,
  deleteCells,
  selectCells,
  pasteCellsFromClipboard,
  moveCellsUp,
  moveCellsDown,
  closeNotebook,
  showAppArrangeModal,
  interruptKernel,
  showEditNotebook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(ButtonBar);
