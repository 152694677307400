import React, { FC } from 'react';
import styles from './styles.module.scss';

type Props = {
  isOver?: boolean;
  canDrop?: boolean;
};

interface PlaceholderRendererProps {
  isOver?: boolean;
  canDrop?: boolean;
}

const PlaceholderRenderer: FC<Props> = ({
  isOver,
  canDrop,
}: PlaceholderRendererProps) => {
  return (
    <div className={styles.placeholder}>
      <h1 className={styles.placeholderLabel}>Drag & Drop new elements here</h1>
    </div>
  );
};

PlaceholderRenderer.defaultProps = {
  isOver: false,
  canDrop: false,
};

export default PlaceholderRenderer;
