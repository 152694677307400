import { ContentsResponse } from 'common/dist/types/repository';
import React, { FC } from 'react';
import AceEditor from 'react-ace';
import { FiCode } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import ReactMarkdown from 'react-markdown';

import styles from './styles.module.scss';
import vars from '../../../../scss/base/var.module.scss';

type Props = {
  selectedFile: string;
  fileLoading: boolean;
  content: string;
  file: ContentsResponse;
};

const RepositoryFilePreview: FC<Props> = (props) => {
  const { selectedFile, fileLoading, content, file } = props;

  if (!selectedFile) return null;

  const renderLoading = () => {
    return (
      <div className={styles.content}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  };

  const renderContent = () => {
    return file.name.endsWith('.md') ? (
      <ReactMarkdown className={styles.content}>{content}</ReactMarkdown>
    ) : (
      <AceEditor
        width={'100%'}
        className={'code-cell-editor'}
        mode=''
        theme='tomorrow'
        showGutter
        highlightActiveLine
        value={content}
        setOptions={{
          maxLines: Infinity,
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          autoScrollEditorIntoView: true,
        }}
        editorProps={{ $blockScrolling: Infinity }}
        readOnly={true}
      />
    );
  };

  const renderEmpty = () => {
    return <div>No content</div>;
  };

  return (
    <div className={styles.repositoryFilePreview}>
      <div className={styles.header}>
        <FiCode size={16} />
        <span className={styles.fileName}>{selectedFile}</span>
      </div>
      {fileLoading
        ? renderLoading()
        : content
        ? renderContent()
        : renderEmpty()}
    </div>
  );
};

export default RepositoryFilePreview;
