import { MessageDescriptor } from 'react-intl';

import habitatMessages from '../messages/habitats';
import { PostHabitatRequestBody } from '../types/requestBodies/habitats';
import { ToBeRefined } from '../types/todo_type';

const isEmpty = (value: unknown): boolean =>
  value === undefined || value === null || value === '';

/**
 * Backend
 * @param body
 */
export const validateAddHabitat = (
  body: PostHabitatRequestBody
): ToBeRefined => {
  const errors: ToBeRefined = {};

  const nameError = validateHabitatName(body.name);
  if (nameError) {
    errors.habitatName = nameError;
  }

  return errors;
};

const habitatNameRegExp = /^[\w\s\d[\]äöüßÄÖÜ()-]+$/;

export const validateHabitatName = (
  habitatName?: string
): MessageDescriptor | undefined => {
  if (!habitatName || isEmpty(habitatName)) {
    return habitatMessages.habitatWizardNameErrorMissing;
  } else if (habitatName.length < 3 || habitatName.length > 65) {
    return habitatMessages.habitatWizardNameErrorLength;
  } else if (!habitatNameRegExp.test(habitatName)) {
    return habitatMessages.habitatWizardNameErrorSymbols;
  }
};
