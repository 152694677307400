import { Config } from '../../../../../pages/augur/type';
import { CFEConfigProperties } from '../../../../../pages/augur/utils/cfe';
import { AllowedInputs } from '../../types/util';
import {
  DropdownSelectV1AugurSettings as DropdownSelectCFEAugurSettings,
  CFEAugurSettings,
} from 'common/dist/types/augurSettings/dropdownSelectV1AugurSettings';

export type { DropdownSelectCFEAugurSettings, CFEAugurSettings };

export type {};

export type OptionsType = {
  label: string;
  value: string;
  dependants?: string[];
};

export type DropdownSelectCFEConfig = CFEConfigProperties & {
  isMulti?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
};
export type ConfigDropdownSelectCFEConfig = Config<DropdownSelectCFEConfig>;

export type PartialDropdownSelectCFEAugurSettings =
  DropdownSelectCFEAugurSettings; // Partial<DropdownSelectCFEAugurSettings>

export const configAllowedInputsDropdownSelect: AllowedInputs<DropdownSelectCFEConfig> =
  {};

export const configTypeDropdownSelect = `{ 
  options: {
    label: string;
    value: string;
    dependants?: string[];
  }[];
  isMulti?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
}`;

export const augurSettingsTypeDropdownSelect = `string[]`;

export const defaultConfigDropdownSelect: ConfigDropdownSelectCFEConfig = {
  options: {
    source: 'hard-coded',
    value: [
      {
        label: 'Option 1',
        value: 'x',
        dependants: [],
      },
      {
        label: 'Option 2',
        value: 'y',
        dependants: [],
      },
    ],
  },
  placeholder: { source: 'hard-coded', value: 'placeholder' },
};

export const defaultAugurSettingsDefaultDropdownSelect: PartialDropdownSelectCFEAugurSettings =
  [];

// JSONSchemaType<DropdownSelectCFEAugurSettings>
export const defaultValidationSchemaDropdownSelect = {
  type: 'array',
  items: {
    type: 'string',
  },
};
