import {
  CompletedWorkbenchRequest,
  getApiRequest,
  patchApiRequest,
  postApiRequest,
} from './_apiRequests';

export function createDirectory(
  jupyterUser: string
): CompletedWorkbenchRequest<{ name: string }> {
  const url = `/jupyter/user/${jupyterUser}/api/contents/`;
  const body = {
    type: 'directory',
  };
  return postApiRequest(url, body);
}

export function renameDirectory(
  oldPath: string,
  newPath: string,
  jupyterUser: string
) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    oldPath
  )}`;
  const body = {
    path: newPath,
  };
  return patchApiRequest(url, body);
}

/**
 *
 * @param jupyterUser
 * @param path
 * @return status is only present in the error case (i.e. 404 = does not exist) and no status means success = exists
 */
export async function checkDirectoryExists(
  jupyterUser: string,
  path: string
): // @ts-ignore
CompletedWorkbenchRequest {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;

  return await getApiRequest(url);
}
