import commonMessages from 'common/dist/messages/common';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { formName } from './file.form';
import FileStep1 from './FileStep1.container';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';
import { useSelectedDirPath } from '../../../hooks';

export type Props = {
  addFile: (fileName: string, dirPath: string) => void;
  filledCompletely: boolean;
  fileName: string;
};

const AddFile: FC<Props & InjectedFormProps> = ({
  addFile,
  fileName,
  filledCompletely,
}) => {
  const selectedDirPath = useSelectedDirPath();

  const fileBrowserRoute = `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`;

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>Add new File</p>
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          <FileStep1 />
          <div className={'dialog-button-container'}>
            <Button
              label={commonMessages.cancel}
              color='white'
              linkTo={fileBrowserRoute}
            />

            <Button
              label='Finish'
              onClick={() => {
                if (filledCompletely) {
                  addFile(fileName, contentArrayToPath(selectedDirPath));
                }
              }}
              linkTo={fileBrowserRoute}
              color='secondary'
              disabled={!filledCompletely}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const form = { form: formName };
export default reduxForm(form)(AddFile);
