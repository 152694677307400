import classNames from 'classnames';
import React, { ComponentType, FC, Fragment } from 'react';
import { FiAlertOctagon } from 'react-icons/fi';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from '../styles.module.scss';

export type ActionButtonProps = {
  /** Column in the grid layout */
  gridColumn: number;
  /** Row in the grid layout */
  gridRow: number;
  /** Use an actual link for the button? */
  hasLink?: boolean;
  /** Link-URL to follow */
  linkTo?: string;
  /** onClick callback for when the button is clicked */
  onClick: () => void;
  /** Icon for the action button */
  Icon?: ComponentType;
  /** Label of the button */
  label: MessageDescriptor;
  /** Additional class name for the action button (for example for the error button) */
  addlClassName?: string;
  /** Is the action button disabled? */
  disabled?: boolean;
  /** Useful to explain why a button is disabled. Is ignored if not disabled. */
  error?: MessageDescriptor;
};

const ActionButton: FC<ActionButtonProps> = (props: ActionButtonProps) => {
  const {
    gridColumn,
    gridRow,
    hasLink,
    linkTo,
    onClick,
    Icon,
    label,
    addlClassName,
    disabled,
    error,
  } = props;
  const intl = useIntl();

  const renderInner = () => {
    return (
      <Fragment>
        {Icon && (
          <div className={styles.actionButtonIcon}>
            <Icon />
          </div>
        )}
        <FormattedMessage {...label}>
          {(text) => (
            <span className={styles.actionButtonText} data-testid={text}>
              {text}
            </span>
          )}
        </FormattedMessage>
      </Fragment>
    );
  };

  if (disabled) {
    return (
      <div
        className={classNames(
          styles.actionButton,
          styles.disabled,
          addlClassName
        )}
        style={{
          gridColumn,
          gridRow,
        }}
      >
        <div className={styles.actionButtonLink}>{renderInner()}</div>
        {error && (
          <div
            className={styles.actionButtonError}
            title={intl.formatMessage(error)}
          >
            <FiAlertOctagon size={17} color={'red'} />
          </div>
        )}
      </div>
    );
  }

  if (hasLink) {
    // Render with Link component
    return (
      <div
        className={styles.actionButton}
        style={{
          gridColumn,
          gridRow,
        }}
      >
        <Link to={linkTo} onClick={onClick} className={styles.actionButtonLink}>
          {renderInner()}
        </Link>
      </div>
    );
  } else {
    // Render with <a> component (only with an onClick method)
    return (
      <div
        className={classNames(styles.actionButton, addlClassName)}
        style={{
          gridColumn,
          gridRow,
        }}
      >
        <a className={styles.actionButtonLink} onClick={onClick}>
          {renderInner()}
        </a>
      </div>
    );
  }
};

export default ActionButton;
