import classNames from 'classnames';
import {
  ContactPoliciesValueType,
  ContactPolicy,
} from 'common/dist/types/module.optimization';
import React, { FC, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import AddContactPolicy from './AddContactPolicy';
import { ContactPoliciesErrorType } from './CampaignOptimizationContactPolicies';
import { SPEAKING_CONTACT_POLICY_TYPE } from './common';
import styles from './styles.module.scss';
import Table, { RenderColumn } from '../../../../../molecules/table/Table';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { SPEAKING_OPERATOR } from '../../campaign-optimization-constraints/v1/common';

type Props = {
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
  value: ContactPoliciesValueType;
  onChange?: (updatedContactPolicies: ContactPoliciesValueType) => void;
  error?: DeepPartial<ContactPoliciesErrorType>;
  onBlur?: React.FocusEventHandler;
  readOnly?: boolean;
};

const ContactPoliciesTable: FC<Props> = (props) => {
  const { addedNewRow, rowIndex, value, onChange, onBlur, error, readOnly } =
    props;

  const contactPolicies: ContactPoliciesValueType = value;

  const initialVisible = {
    index: addedNewRow ? contactPolicies.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };

  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : contactPolicies?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<ContactPolicy, keyof ContactPolicy>[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      // @ts-ignore
      renderCell: (name: string, contactPolicy: ContactPolicy) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[contactPolicy.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      // @ts-ignore
      renderCell: (description?: string, contactPolicy: ContactPolicy) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[contactPolicy.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'contactPolicyType',
      renderHeader: () => <span>Contact Policy Type</span>,
      // @ts-ignore
      renderCell: (
        contactPolicyType: ContactPolicy['contactPolicyType'],
        contactPolicy: ContactPolicy
      ) => (
        <span
          className={classNames({
            [styles.erroneous]:
              !!error?.rows?.[contactPolicy.id]?.contactPolicyType,
          })}
        >
          {SPEAKING_CONTACT_POLICY_TYPE[contactPolicyType]}
        </span>
      ),
    },
    {
      key: 'operator',
      renderHeader: () => <span>Operator</span>,
      // @ts-ignore
      renderCell: (
        operator: 'leq' | 'geq' | 'eq',
        contactPolicy: ContactPolicy
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[contactPolicy.id]?.operator,
          })}
        >
          {SPEAKING_OPERATOR[operator]}
        </span>
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      // @ts-ignore
      renderCell: (value: string, contactPolicy: ContactPolicy) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[contactPolicy.id]?.value,
          })}
        >
          {value}
        </span>
      ),
    },
  ];

  const renderClickedRow = (
    element: ContactPolicy,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddContactPolicy
                rowIndex={rowIndex}
                constraintId={element.id}
                removableRows={visible.shown}
                onRemoveRow={(removedRow) => {
                  const updatedContactPolicy = contactPolicies.filter(
                    (con) => con.id !== removedRow.id
                  );
                  onChange?.(updatedContactPolicy);
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                readOnly={readOnly}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={contactPolicies}
      renderColumns={renderColumns}
      showHeader
      removableRows={true}
      verticalAlignMiddle
      onRemoveRow={(removedRow) => {
        const updatedContactPolicies = contactPolicies.filter(
          (con) => con.id !== removedRow.id
        );
        onChange?.(updatedContactPolicies);
      }}
      addlRowClassname={(_rowIndex: number, contactPolicy: ContactPolicy) =>
        classNames(commonStyles.row, {
          [styles.erroneous]: !!error?.rows?.[contactPolicy.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(_row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
      readOnly={readOnly}
    />
  );
};

export default ContactPoliciesTable;
