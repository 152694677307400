import { REPO_TYPE } from 'common/dist/types/repository';
import { AiOutlineClear } from 'react-icons/ai';
import {
  FiCheckSquare,
  FiClipboard,
  FiFilePlus,
  FiFolderPlus,
  FiPlus,
  FiRefreshCcw,
  FiSettings,
  FiSquare,
  FiUpload,
  FiXSquare,
} from 'react-icons/fi';
import { Dispatch } from 'redux-act';

import { IconButtonBarButton } from './IconButtonBar';
import { contentKeys } from '../../../../core/api/workbench/content';
import { invalidateQueries } from '../../../../redux/modules/reactQuery.module';
import {
  openRemoveModuleArtifactsWizard,
  toggleHidden,
} from '../../../../redux/workbench/modules/content.module';
import { showPasteNotebook } from '../../../../redux/workbench/modules/notebook.module';
import { showUploadModal } from '../../../../redux/workbench/modules/upload.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';

export const AddNotebook: IconButtonBarButton = {
  Icon: FiPlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.createNotebook}`,
  title: 'Add Notebook',
  id: 'wb-btn-add-notebook',
};

export const AddFile: IconButtonBarButton = {
  Icon: FiFilePlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.addFile}`,
  title: 'Add File',
  id: 'wb-btn-add-file',
};

export const AddDirectory: IconButtonBarButton = {
  Icon: FiFolderPlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.addDirectory}`,
  title: 'Add Directory',
  id: 'wb-btn-add-directory',
};

export const Paste: IconButtonBarButton = {
  Icon: FiClipboard,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.pasteNotebook}`,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    // Check whether there is a notebook in the clipboard, if not: cancel.
    if (
      !state.workbench.clipboard ||
      state.workbench.clipboard.type !== 'notebook'
    ) {
      return;
    }
    dispatch(showPasteNotebook());
  },
  title: 'Paste',
  id: 'wb-btn-paste',
  disabled: (state) => state.workbench.clipboard.data === undefined,
};

export const Upload: IconButtonBarButton = {
  Icon: FiUpload,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    dispatch(showUploadModal());
  },
  title: 'Upload',
  id: 'wb-btn-upload',
};

export const Reload: IconButtonBarButton = {
  Icon: FiRefreshCcw,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    dispatch(invalidateQueries(contentKeys.all()));
  },
  title: 'Reload Content',
  id: 'wb-btn-reload-content',
};

export const Settings: IconButtonBarButton = {
  Icon: FiSettings,
  title: 'Settings',
  id: 'wb-btn-settings',
  contextMenu: [
    {
      title: {
        id: 'no-id',
        defaultMessage: 'Show hidden files',
      },
      icon: (state: DeprecatedRootState) =>
        state.workbench.settings.showHidden ? FiCheckSquare : FiSquare,
      onClick: (dispatch: Dispatch): void => {
        dispatch(toggleHidden());
      },
    },
    {
      type: 'spacer',
      isVisible: (repoMeta) => repoMeta?.repoType === REPO_TYPE.MODULE,
    },
    {
      title: {
        id: 'no-id',
        defaultMessage: 'Remove module artifacts',
      },
      icon: () => AiOutlineClear,
      onClick: (dispatch: Dispatch): void => {
        dispatch(openRemoveModuleArtifactsWizard());
      },
      isVisible: (repoMeta) => repoMeta?.repoType === REPO_TYPE.MODULE,
    },
  ],
};

export const EmptyRecycleBin: IconButtonBarButton = {
  Icon: FiXSquare,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}${workbenchRoutes.emptyRecycleBin}`,
  title: 'Empty Recycle Bin',
  id: 'wb-btn-empty-recycle-bin',
};
