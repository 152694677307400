import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import IntlDropdownSelectInput, { OwnProps } from './DropdownSelectInput';
import { GroupBase } from 'react-select';

/**
 * Wrapper to easily use the DropdownSelectInput in redux-forms
 * Fill the redux-form props of the component with the input and meta props which are passed if the component is wrapped directly
 * inside a <Field component={RfWrappedDropdownSelectInput} option={options}> (the other props are passed as is by <Field>, e.g. options)
 * Ignores the onBlur function
 * @param props
 * @constructor
 */
class RfWrappedDropdownSelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Component<OwnProps<Option, IsMulti, Group> & WrappedFieldProps> {
  render() {
    const {
      input: { value, onChange, onFocus, onBlur },
      meta: { touched, error, valid },
    } = this.props;

    return (
      <IntlDropdownSelectInput
        /** Has the field been touched? */
        touched={touched}
        /** Has the field an error? */
        error={error}
        /** Is the field valid? -> Shows a checkmark */
        valid={valid}
        /** Value of the input field */
        value={value}
        /** onChange callback */
        onChange={(v) => {
          onChange(v);
          onBlur(v); // To mark the redux-form fields as visited
        }}
        /** onFocus callback */
        onFocus={onFocus}
        /**
         * onBlur callback
         * If searchable={true} (the default) there is a bug that makes the onBlur event target.value empty, so directly
         * passing that event to redux-form resets the value. See: https://github.com/JedWatson/react-select/issues/805
         */
        onBlur={() => {}}
        {...this.props}
      />
    );
  }
}
/** @deprecated use react hook form variant instead*/
export default RfWrappedDropdownSelectInput;
