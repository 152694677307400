import React, { FC } from 'react';

import HierarchyCheckboxes from './HierarchyCheckboxes';
import styles from './styles.module.scss';
import { TargetHierarchyAugurSettings, TargetHierarchyConfig } from './type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  TargetHierarchyAugurSettings,
  TargetHierarchyConfig
>;

export const TargetHierarchy: FC<Props> = (props) => {
  const { onChange, value, config, readOnly } = props;
  const options = config.taxonomy || [];

  return (
    <div className={styles.targetHierarchy}>
      <HierarchyCheckboxes
        onChange={onChange}
        value={value || []}
        options={options}
        loading={false}
        loaded={true}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TargetHierarchy;
