import classNames from 'classnames';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import SummandGroupsTable from './SummandGroupsTable';
import {
  CampaignOptimizationTargetFunctionAugurSettings,
  CampaignOptimizationTargetFunctionConfig,
  CampaignOptimizationTargetFunctionValidationError,
} from './type';
import RadioButtonsInput from '../../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import InputError from '../../../../../atoms/input-error/InputError';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationTargetFunctionAugurSettings,
  CampaignOptimizationTargetFunctionConfig,
  CampaignOptimizationTargetFunctionValidationError
>;

export type TargetFunctionValueType = {
  summandGroups: TargetFunctionSummandGroup[];
  minimizeOrMaximize: 'minimize' | 'maximize';
};

export type TargetFunctionErrorType = {
  global?: string;
  minimizeOrMaximize?: string;
  rows?: {
    [constraintId: string]: {
      id?: string;
      name?: string;
      description?: string;
      sumOf?: string;
      sumOver?: string;
      factor?: string;
    };
  };
};

export interface State {
  addedNewRow: boolean;
}

const CampaignOptimizationTargetFunction: FC<Props> = (props) => {
  const { value, onChange, error, onBlur, readOnly } = props;
  const [addedNewRow, setAddedNewRow] = useState(false);

  const expandAutomatically = () => {
    const isEmpty = _.isEmpty(value);
    if (isEmpty && !addedNewRow) {
      setAddedNewRow(true);
      onChange?.({
        ...(value || {}),
        summandGroups: [
          ...(value?.summandGroups || []),
          {
            id: uuidv4(),
          },
        ],
      });
    }
  };

  expandAutomatically();
  const handleAddRow = () => {
    if (!readOnly) {
      onChange?.({
        ...(value || {}),
        summandGroups: [
          ...(value?.summandGroups || []),
          {
            id: uuidv4(),
          },
        ],
      });
      setAddedNewRow(true);
    }
  };
  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {' '}
        {/* This empty
         container makes the Layout for each component in the optimization augur
         identical*/}
      </div>

      <div className={styles.minMaxContainer}>
        <RadioButtonsInput
          id={'optTargetFunction_minimizeOrMaximize'}
          touched={true}
          error={error?.minimizeOrMaximize}
          disabled={readOnly ?? false}
          hasLabel={true}
          labelId={'no-id'}
          labelDefault={'Minimize or Maximize the target function?'}
          value={value?.minimizeOrMaximize || ''}
          onChange={(minimizeOrMaximize: string) => {
            const newValue = {
              ...(value || {}),
              minimizeOrMaximize,
            };
            onChange?.(newValue);
          }}
          radioButtons={[
            {
              value: 'minimize',
              labelId: 'no-id',
              labelDefault: 'Minimize',
            },
            {
              value: 'maximize',
              labelId: 'no-id',
              labelDefault: 'Maximize',
            },
          ]}
          entriesPerRow={1}
        />
      </div>
      <div className={commonStyles.errorContainer}>
        {error?.global && <InputError touched={true} error={error.global} />}
      </div>
      <div className={commonStyles.tableContainer}>
        <SummandGroupsTable
          addedNewRow={addedNewRow}
          rowIndex={value ? value?.summandGroups?.length - 1 : 0}
          value={value || { summandGroups: [], minimizeOrMaximize: 'minimize' }}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          readOnly={readOnly}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={classNames(commonStyles.AddIcon, {
            [commonStyles.readOnly]: readOnly,
          })}
          onClick={handleAddRow}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationTargetFunction;
