import { SingleResultType } from 'common/dist/types/responseBodies/dashboard';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

type Props<ResultType extends SingleResultType> = {
  /** Habitat, Augur, Datapool results, ... */
  elements: ResultType[];
  onElementClick: () => void;
  getLink: (element: ResultType) => string | undefined;
  label: MessageDescriptor;
};

const SingleResultList = <ResultType extends SingleResultType>({
  elements,
  onElementClick,
  getLink,
  label,
}: Props<ResultType>) => {
  return (
    <div className={styles.group}>
      <div className={styles.label}>
        <FormattedMessage
          id={label?.id || 'no-id'}
          defaultMessage={label?.defaultMessage || ''}
        />
      </div>
      <div className={styles.list}>
        <ul>
          {elements.map((element) => (
            <li className={styles.li}>
              {getLink(element) ? (
                <Link to={getLink(element)} onClick={() => onElementClick()}>
                  {element.name}
                </Link>
              ) : (
                <span>{element.name}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SingleResultList;
