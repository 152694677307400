import React, { ComponentType } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DeIntl } from './type';

type Props<T extends {}> = {
  WrappedComponent: ComponentType<DeIntl<T>>;
  props: T;
};

function isFormattedMessage(
  str: unknown
): str is React.ComponentProps<typeof FormattedMessage> {
  return (
    (str as React.ComponentProps<typeof FormattedMessage>)?.id !== undefined ||
    (str as React.ComponentProps<typeof FormattedMessage>)?.defaultMessage !==
      undefined
  );
}

/**
 * Wrapper component to convert MessageDescriptor fields to string.
 */
function IntlWrapper<T extends {}>({ WrappedComponent, props }: Props<T>) {
  const intl = useIntl();

  const newProps: DeIntl<T> = Object.entries(props).reduce(
    (acc, [key, prop]) => {
      if (isFormattedMessage(prop)) {
        return { ...acc, [key]: intl.formatMessage(prop) };
      } else {
        return { ...acc, [key]: prop };
      }
    },
    {} as DeIntl<T>
  );

  return <WrappedComponent {...newProps} />;
}

export default IntlWrapper;
