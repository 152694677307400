import { Constraint } from "common/dist/types/module.optimization";

export const SPEAKING_OPERATOR = {
  leq: '≤',
  geq: '≥',
  eq: '=',
};

export const SPEAKING_CONSTRAINT_LEVEL: {
  [x in Constraint['constraintLevel']]: string;
} = {
  channel: 'Channel',
  communication: 'Communication',
  communicationGroup: 'Communication Group',
  allCommunications: 'All Communications',
};

export const SPEAKING_CONSTRAINT_TYPE: {
  [x in Constraint['constraintType']]: string;
} = {
  quantity: 'Quantity',
  cost: 'Cost',
  individualValue: 'Individual Value',
};
