import classNames from 'classnames';
import React, { FC } from 'react';

import { CProps } from './NotFound.container';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';

export type Props = {
  fullViewHeight?: boolean;
};

const NotFound: FC<Props & CProps> = ({
  fullViewHeight,
  mainTabsDefaultLink,
}) => {
  return (
    <div
      className={classNames(styles.notFound, {
        [styles.fullViewHeight]: fullViewHeight,
      })}
    >
      <span className={styles.headline}>404</span>
      <span className={styles.description}>Page Not Found</span>

      <Button
        color={'primary'}
        linkTo={mainTabsDefaultLink}
        label={'Back to Dashboard'}
      />
    </div>
  );
};

export default NotFound;
