import classNames from 'classnames';
import React, { Component } from 'react';
import { FiPlus } from 'react-icons/fi';

import { AppOutputCell } from '../../../../../../../../../../store/workbench/state.types';
import Button from '../../../../../../../../../atoms/button/Button';
import ExpandCollapseIcon from '../../../../../../../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import { getElementByType } from '../elements/_interface/Python3OutputElementManager';

type Props = {
  /** The "parent" cell object */
  cell: AppOutputCell;
  /** Path of the notebook */
  path: string;
  /** Callback to open the "Add Output Element" Modal */
  handleOpenDialogModal: () => void;
  /** Open or collapse the output elements */
  toggleOutputElements: (path: string, cellId: string, expand: boolean) => void;
};

export default class OutputElements extends Component<Props, {}> {
  render() {
    const { handleOpenDialogModal, cell, path, toggleOutputElements } =
      this.props;
    const elements = cell.as_elements ? cell.as_elements : [];

    const elementsExpanded = Object.keys(cell || {}).includes(
      'as_elements_expanded'
    )
      ? cell.as_elements_expanded
      : true; // Default: Expanded

    return (
      <div>
        <div
          className={classNames(
            'input-output-cell-headline-container' // This one is important for the common styling with the input-output-cell-headline-container
          )}
        >
          <div className={'input-output-cell-headline-left'}>
            <ExpandCollapseIcon
              isExpanded={elementsExpanded}
              onClick={() =>
                toggleOutputElements(path, cell.id, !elementsExpanded)
              }
              color={'black'}
              size={20}
              title={['Minimize Variables', 'Maximize Variables']}
              withBackground
            />

            <div
              className={classNames(
                'visual-output-headline' // This one is important for the common styling with the visual-input-headline
              )}
            >
              <span>Output Elements</span>
            </div>

            <div className={'visual-input-amount-indicator'}>
              <span>{(elements || []).length}</span>
            </div>
          </div>

          <div className={'input-output-cell-headline-right'}>
            <Button
              title={'Add Output Element'}
              form={'squared'}
              Icon={() => <FiPlus size={20} />}
              onClick={handleOpenDialogModal}
            />
          </div>
        </div>

        <div className={'elements-parent'}>
          {elementsExpanded &&
            elements.map((e, i) => {
              const element = getElementByType(e.type);
              if (!element) return null;
              else {
                return element.renderWrappedComponent({
                  key: `${cell.id}_${i}`,
                  cell,
                  path,
                  element: e,
                });
              }
            })}

          {elementsExpanded && (!elements || elements.length === 0) && (
            <span className={'empty'}>
              This Output Cell doesn't have Output Elements yet.
            </span>
          )}
        </div>
      </div>
    );
  }
}
