import _ from 'lodash';
import validator from 'validator';

import { scheduleMode } from '../constants/enums';
import {
  PostAugurRequestBody,
  PutAugurRequestBody,
} from '../types/requestBodies/augurs';
import { ToBeRefined } from '../types/todo_type';

const isEmpty = (value: ToBeRefined): ToBeRefined =>
  value === undefined || value === null || value === '';

export const validateSchedule = ({
  trigger,
  trigDelayed,
  period,
  periodValue,
  time,
  mode,
}: ToBeRefined = {}): ToBeRefined => {
  const errors: ToBeRefined = {};
  if (!validator.isIn(mode, [scheduleMode.SCHEDULED, scheduleMode.MANUAL])) {
    errors.mode = { id: 'newAugur.schedule.error.mode_invalid' };
  }

  if (mode === scheduleMode.MANUAL) {
    return errors;
  }

  return errors;
};

export const validateAddAugur = (body: PostAugurRequestBody): ToBeRefined => {
  const errors: ToBeRefined = {};

  if (!body.name) {
    errors.augurName = 'AugurName is required';
  }

  return _.pickBy(errors, _.identity);
};

export const validateUpdateAugur = (body: PutAugurRequestBody): ToBeRefined => {
  let errors = {};

  if (body.name) {
    errors = {
      ...errors,
      ...validateAugurName(body.name),
    };
  }

  return errors;
};

const augurNameRegexp = /^[a-zA-Z 0-9.,+\-äöüßÄÖÜ()]*$/;

export function validateAugurName(augurName: string): ToBeRefined {
  if (isEmpty(augurName)) {
    return { id: 'newAugur.stepOne.error.missing_augur_name' };
  } else if (augurName.length < 3 || augurName.length > 65) {
    return { id: 'newAugur.stepOne.error.augur_name_length' };
  } else if (!augurNameRegexp.test(augurName)) {
    return {
      id: 'newAugur.stepOne.error.it_can_contain_only_alphanumeric_symbols_and_spaces',
    };
  }

  return undefined;
}
