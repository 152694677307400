import React from 'react';

import { FileCode } from './fileCode/FileCode';
import { FileDefault } from './fileDefault/FileDefault';
import { FileMarkdown } from './fileMarkdown/FileMarkdown';
import { FileText, FileTextReadOnly } from './fileText/FileText';
import { File, FileTypeWrapper } from './FileTypeWrapper';
import { Launcher } from './launcher/Launcher';
import { Notebook } from './notebook/Notebook';
import { JupyterContentType } from '../../../store/workbench/state.types';
import { ContentElementType } from '../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { getFileType } from '../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';

/**
 * Helper function to pick out the wrapper for the given file name
 * @param fileName
 * @param type type of content to show ("notebook" | "launcher" | "file" | "directory") - directory should never happen here
 */
function getWrapper(
  fileName: string,
  type: JupyterContentType | 'launcher'
): FileTypeWrapper {
  console.assert(
    type !== 'directory',
    'A Directory should not be displayed in a pane.'
  );
  if (type === 'launcher') return Launcher;

  // Calculate the more Specific ContentElementType. For the sidebar this is already calculated, but we need it here for the panes. Could probably be streamlined/optimized
  const contentElementType = getFileType(type, fileName);
  switch (contentElementType) {
    case ContentElementType.FILE_NOTEBOOK:
      return Notebook;
    case ContentElementType.FILE_CODE:
      return FileCode;
    case ContentElementType.FILE_TEXT:
    case ContentElementType.FILE_CONFIG:
    case ContentElementType.FILE_AUGUR_SETTINGS:
    case ContentElementType.FILE_GITIGNORE:
      return FileText;
    case ContentElementType.FILE_REPOSITORY_INFO:
      return FileTextReadOnly;
    case ContentElementType.FILE_MARKDOWN:
      return FileMarkdown;
    default:
      return FileDefault;
  }
}

/**
 * Returns the editor component for the given fileEnding
 * @param fileName
 * @param type type of content to show ("notebook" | "launcher")
 */
export function getComponentForFileName(
  fileName: string,
  type: JupyterContentType | 'launcher'
): (file: File, paneId: string) => JSX.Element {
  const wrapper = getWrapper(fileName, type);
  if (wrapper) {
    return wrapper.component;
  } else {
    // eslint-disable-next-line react/display-name
    return (_: File) => <div />;
  }
}
