import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { SamplingStrategyV1AugurSettings as SamplingStrategyAugurSettings } from 'common/dist/types/augurSettings/samplingStrategyV1AugurSettings';

export type { SamplingStrategyAugurSettings };

export type SamplingStrategyConfig = {
  valueLabel: string;
  otherLabel: string;
};

export type ConfigSamplingStrategyConfig = Config<SamplingStrategyConfig>;

export type PartialSamplingStrategyAugurSettings =
  SamplingStrategyAugurSettings;

export const configAllowedInputsSamplingStrategy: AllowedInputs<SamplingStrategyConfig> =
  {};

export const configTypeSamplingStrategy = `{
    valueLabel: { source: "hard-coded", value: string },
    otherLabel: { source: "hard-coded", value: string },
  }`;

export const augurSettingsTypeSamplingStrategy = `number`;

export const defaultConfigSamplingStrategy: ConfigSamplingStrategyConfig = {
  valueLabel: { source: 'hard-coded', value: 'Training' },
  otherLabel: { source: 'hard-coded', value: 'Evaluation' },
};

export const defaultAugurSettingsDefaultSamplingStrategy: PartialSamplingStrategyAugurSettings = 0.7;

export const defaultValidationSchemaSamplingStrategy = {
  type: 'number',
};
