export const targetTaxonomyImg = `
iVBORw0KGgoAAAANSUhEUgAAAzQAAAHGCAMAAACVaT5+AAABwlBMVEX////u8PJqoOumrrWf01bsPT3c4usiTpAHGzLj4+PCMDBy
pSt1p+3k5OTs7vDCyM7o6uwyQlUVKD3r7fB9rO7g4+fd4OPd6frt8/3l7vuav/Lf4uVCUWP8/Pzo6OgZLEH4+fj29vby8vIgMkfv
7++XoKklNkoMHzbm5ubj5umwtr240vWmp6jq6urm6Orl5+m6wMZZZnYvQFP7z8+jq7OYmJj6+voTJj3T1Na0trfb3eC0u8EQIzlO
XW3+/v7X2t2ur7Hs7OwXKkDExceAipZseIbHzNKqsbqQuPDq7O68vb/2np56hZL39/fQ1dmwsbOTnKfi5ObLzc4pO07o8Pyys7Vq
doSsra6qq6zX2Nrxbm7P0dO2uLmoqapdanrN0dbn9NVGVWd0gI1ve4k2RlmpsbjP6auHkZy5urycpa40RljHyMrT19t8h5O4vsSO
mKK/xMpLWmq40fU8TF7Bw8S/wMJkcX9TYXEtPlL09PRuouzzgYGxuL/19fX1np6Kte9zpuzV5PrKz9X+8vL4trar2Wv95ub729vN
09jM3visyvSHsu96qu35w8PtSUj3qKjJ5qDuVVXb78C94Yvz+urz+epyP1jpAAAbNUlEQVR42uzTQWrCUBRA0S+2had0CRbrqCtw
WOgCsv/tVCGIShBfcPCSnDO5K7gNAAAAAAAAAAAAAAAAAAAAmL/teGuy2rXNeGuSXjvN21jbFUl307yPtVmRY5rJMs3TTINpkkyD
aZJMg2mSTINpkkyDaZIWMM0Hg0xTRMlpGgNMU4VpJsM0VZhmMkxThWkmwzRV1Jym6+3bc3Zdd7x0rkxTRclpdtH7alceDPEd8fN7
6j7i0ObKNFWUnebz7GaaiL8H08TBNKYZtpRp+j8S08TRNKYZtLRp/tk7mxc3gSiAz0B78KAIgVwWxYgHF9bkEBDNITF4iiWIgqhN
Aj2VgNfkUEJ76dep3+3/2/cmsUlrst1sP1DjDzq+Gee5l/n1jW7ijvVxR+Z0lTzXofroHUJMTcc+Ibquary2l8b4IY0qc7yhQFfX
p2OeNyQFBjS2f4OurJBKcj9p3n/oE+Blf9NIcynSwGrnEEVlh+dE0lkAsnB46oc0MNzZSWNwCG9il2XLHKKiVABOrCL3k6ZPcjaN
NDWXhtcBGaXhVFMFOYYwaEhDMIJ/poxxM4Y2aJ1cGhXq0ZBJA42GIxpGsqLokGg+h4YMeU6fTnEiqSD3kuY9Ia8eA5s+6TfSVE6a
1x9fn/v0jEdpdIKL3djd0wxZcTFRjv3DNSYNusKkGUrgFkuB7jNCNDSMXUjBUkWecVwln0vfS5rHhGyDDXnZSFM5aZ6Qj2dIYyjI
Thqyl0bZFiEQBPuH0qAcKh7ZXQ+QS6OiJFihMIJcvqL7s3Ol+fTiw7sPrwh5wcDg1asXm/eNNJWS5unZ9zQFaVAPnlGURuI5Pj+O
Nf6INFqefBHSvCPH2PxlaYQBIN518sjqFlKrR9WkMbHGMArS4L4LjiiJQsgRaXDCc1JZzpVm038DD81evukzMCDk7btPf1ka8cbz
vMgO7+TM3Hej7PxUxGrR0lBWaQwFMffSYCNLhOgggiRpWlEaNGy3TVPAl6I0aJw+HZpGNc05/57m0S83/4Q8hvZvS4OrubvM7rLw
HUonsXh2KrLo0tJQVmm26IfSaEwJhWPw5hFpTDZjiqcxuSANhMjFPD37f9JQwZ5RhrBvC2QuNP7VnVOFu0ojnB4Qjsy+IGlMg6lh
Yj2RzWKlATR2VHk4BZOlgjSko4NQY4lUkVJLQwc2xL7oJSENvCSJLApM1rHj9ZZBXmnmlKb2banU8mJnhbUotCw78VEnxPNvHP+a
0vbaieFy+WjbdZZs0FlNoGbZLddPvIDO7IXDMrtuFNspzFhSxHLBmNCO7VENpSEnkCSCDE3yG8zTpWRYTWP+QJpXb+HJc3/zj6UR
khbEy1lXCJKZ2EoTXJj2ShQG8yRf5S0/nfndW1KpFV3RXuaANW7kXtO2kxchYREIlAZOKohW0qZbonkGo0E0oC0XxOstvLR17dqZ
G7SsZADO2mFLGMA1BIelLMHklTuBy45KLs3rJ1sU8m0bfG6+T/M/pUFVMPrH0lB/ADGu8dkKu+6a0tQXIGovArpjtFiKt6VeJz3s
r13IX2Ku5QiH2zM/xXBm7wYjj2VjphCntAfXwKvaFPBcFASjq7hLM4y6IGfKLjiLSi7N08Ks1400NZTGHkEs0Jx0TqmbsTDOpelF
XjS5LdVaU2QQQ27IMm6CA2l6DguF3MJosFMBcDOoNAK7msXMAqGc7bzljAYxnApX4FFIMeemVW5pXn98yvhKnmyDL02lqaM0Dgcx
iwTL9ZYRSOPNfpJG9GesgLS9E6loCjK5meShPzqQJl3mkh1IkyY24qxBGorMU2wtj4ogEZJlMAgpURv+RTayCMotzcE9TfN15/pK
00qE3crv+msr6OJN/3IvTf6fv+CtqLU6lbqa7UqJmEtj76VhIuRa7KWxlteMbkGaXkIZYcZqWOCjn6NrhtBIcxGUWxprSfOVH/7Y
nnk/SeNZaIgdztNTqaGLLS7vXJqYO5Cm7VOG0z2QJojoloI0AuQgawt/PTTJQozTuv6ehjRUTZquM8hX/tzCNgNprJidyvdC6AHO
9IVTqVexyHJXMJn5045xCpJcg3AwExg59EAaGg9OSEO9ECMx6TFzHDzMlo00l0SJpelZ8YjFFMjsHhVDx6dU8ENwYp5Lc5VYLSqE
Ttw+lUpX+HTNiqBxF5ZAxf1nbJYWmuAHWG/aP0kz8K/wp1wVpQliCMX5jM2KmS7C0hVAnXUjzWVwX2nevXn/cPNy88+kSZJknrW3
MbatzPHj7OpGwKUc+/7gx9OzQbSw47U4cnonUkGp2Pa9gJUl17GTjOakTjRgbTQf0b00SDqPIj89UmloYDv2zjvBsSjSWvm2s57U
UBpzusUkd2I4hUaC+UolPx9T6U8EHKW7d0qkvRuR5kx69Hf0hHwvJ2CYk/fECS0yEekJWt1jP6Ey27PP5MudpdF0g6GSA0yFnEDi
QJYODwm6UclP/ddNmpwAm9Cn54PSlJb/JA3y+sHdpdFIEVW7XRoZQ0WvrTX3kebtYffT/5amZWfBJHVGjTT3kwb599JgzFf0ZTP/
QJoNIW/7e94Q8p+/7twK577XpvchtGhpKbs007E01nlDghLC8zoOPzd4XWVnFJlTCtKgW/XkTGmQt+RnHjXvCLgEaTqy0RkqBr5S
Qx0Ph+CMbBJJ1uCMLnckUpRGkUk9OVcaZPPokMfNizXqLI3RQRRQgRUThctLiMmZrC9tzxSkwU5NH6E1b9gsCyWVRh8jaq7CkJN2
0jw3CKJP8cwJaSr7jZlGmmpQUmkOtmcEyaUBnWSEV09KY/KknjTSlIWqSTPWTIZ0UpqpQepJI01ZqJo02DBOSjOu698NaKQpC1WS
Zsw2X9Lt0kz1mt7SNNKUhgpJo+hDiFQDQ3l4XBpT0yv5nuZGmipRUmk4njE+lGZoQB+skWVdNo9UGswx1LrWmUaa8lBOaW5/hZNU
01/ENNJUhEpJc9k00pSFRprK0EhTFhppKkMjTVlopPnO3h2rNBAEARjOeeBNpYWk0ie4SlsN2GghiPgSPoPWPrmkkCSQQGa5wN7m
+4qdhan/emZDNLWoMhr2EU0taoyG/URTCdHMlmiOVnc05XqydqMp15O0AAAAAAAAAAAAAAAAAAAAAAAAAAAA4MxdAsdzUAkAAAAA
AAAAAAAAAAAAAAAAAAAAgHM2lOvJWtCC4aLU0JEkmjaI5jDRIJok0SCaJNEgmiTRIJok0SCaJNEgmiTRIJok0SCaJNEgmiTRcNpo
fsfxdj0/xnHZ5T0/du0TTRsmi+Y14mE93yPuu6Tl5118de0TTRumjCaeiqK5/l5FiIbZmDSan7etaG42u6vN+//b8hKxEs0fO3f3
mjYUxnGcBy/6u+iSaat7UXAQjOigTHEIS0QQpxVECo5dWppaiqWtLYUiY7B1V3v7o2fUTreTgoWoOSfP90rB2w8nPJ4nnDz5igb1
ezSJXhfpXpVoaJqNlG2814ajrDmgcVYhbd9YNG/gXJUYDSdPfqIZwT6foqkaQBbo7s2OEaAPtwaRlYVbiRZTGI2eZDSq5YXm067Q
72XQNG1UpmjqwMlTByi5nwqNAQCncQy0KRdDt5kvwmyFAk0nClwwGsXyQvPludDdMmgadaA5QROvVknTgbqL5pAoCoMoDvToMzAg
ygCNMKDRY+V2BRFGo1ZeaPbFn31YCk1rBNNx0ZB1bWBce4Ym5aIhoOB+N0zTACIhQDM2oxOZFUajVr6iIQuouWhOgO7trReaY6A2
qaQ+mqmZZKzHaNTK8/HshdDdcmioALhojlCL0yFwIaA5AAbkpv4gYGYmCovRqJXnIGBb6OuSaN7FJmiiSMe1utdJ07FhNlvfUgPl
0fw1k+FBgGL5OHJ20dBwgsYBbJdPQUBDpwCywEj16dncDI+cVctvNNqZi6Z1m4V9mkZRREORLlArJBQ/aeZm+M9NuXqy0CPQbL8U
2n/sasDe9x16qNw5EV1iVpwUTI/FZmb4RoBkvV1ohdOzx6c6mrkZvkYjWwKaLaGNodmJTwuRGUYjQ/8/nr0KEBqVK9+b4QubSuaF
Zve10E9Gs3wWMlMzfMtZzfgdAf73EcmJGV4NUDT/0LxJELXy+bwVJyFNpxAVjeptExnep1E1/9AUm0QJXFcqo36d/unQMYoUnpIY
Z2Z4CU3ZvND8eib0Y1k0OSKqphxa6NpsH4QJTSdayXR4c1PhfJieCWhI6w9pnq5RPkxoeN1Z9VaChqw+LcZoGI1KrQbNjr3HaBiN
qvmxGiCioe4lo2E0Ere18umZiOYswmgYjcRtAo2hMxpGI3EbQJMra4yG0UjcBtAcHPH0jNHI3PrRVA2L0TAamVs3mvNM+oQYDaOR
ubWiscuxG+eKGA2jkbp1oBE7TU+7II7RSNcW79MEJEYjTYwmKDEaaWI0QYnRSBOjCUqMRpoYTVBiNNLEaIISo/nD3h0iAQjEQBAU
CP7/YzQCqAFzKbp95NjNGKJZhWjGEM0qRDPGfTTvbUSiGWP/cgp/JBoQDZyIBiLRQCQaiEQDkWggEg1EooFINBCJBiLRQCQaiEQD
kWggEg1EooFINBCJBiLRQCQaiEQDkWggEg1EooFINBCNicYM9DUr0MPtHg6MJ5onokE0kWgQTSQaRBOJBtFEokE0Bzv39qJEGMZx
nB9d9LsodZzcDgpdDM6Lc2EaReABQTotiAiGl4ZbseyipQkVsdDhrsMf3aSbjs3GzBTVzDvP50IQvP3yyjPvMxFJNEKiiUiiERJN
RBKNkGgikmiERBNR2qKpOScZuObOCaLq3Bo0M9CeRBMkbdEY5ACuEhWiqUzoenMfupNogqQwmu7e70STW5K2TQ5NaE6iCZLCaPjK
G42ngcz6c/vVq0k+yWTapPZHjUQTJI3RsPYjmuzCUnavCozt+jNDDcv7I0s9nQOo9pbKHt3AxqFlfQRekE1oTqIJkr5o7lg0zHU0
TotU5BgosUWXOqbLysFs87tjE7vGVFXoKFuUaEKLTzSufxLN9Vvk4TqaBfPTYoncQ4m8e/8JqY6yC7KJGfkk2yMH2DFXrENDfYOc
STRhJTeat5d8voaKBhPe6ayiaXT2YB6SNZRoAUWyDpwjy1hymEHG5mt4NSzaBegnm7/Ta/OcRBNScqN5d8XncrhoHivWV9FkBssu
Xc11NCDHQJMcmC22bNtu8RgeBYOqBv24zWQBuy3RhJTcaB76f/UoXDTokV0q4IBc3lucEU2FVN3vnmLrxpLqHPSzbqaYH0s0YaUx
mpxNUsFssQQMzogGlv95TG5CHkE/p80YbEo0YSU2mndXfT6EjAbOKpoCOTGr7bOieUXemxYGpe3M2WyTB1NXA1rZNFOWQUBYyY3m
7QWf92GjwWtSAUuyq0gOfNEULLq8R8stnipBJ9tmZOQcWnKjOUvoaDotKmBaIq1DcuSLBnvjFnnzCBtHWkazbUYeboaXtmh+Upzi
VzLTCoAZ1wzoKJvPnzYjNwIiiEM0Fz3CR3Phms/DgGii0j2abTNyjSaCWERz2+PvTs+iyxXWKtDPthm5exZJLKM57xMyGtncjOLO
j2bkwmY0cYjmokfcThqNNVleNyO3nCOKQzS/Nwi49NLns0QTwYjFVTOyGhBVcqORF2v8IcPI9myWZZ8msrRFs++YQMNxnFoOP6me
vHD2kRpFuuyyLKH9d7GPZo83gFv5dnsynJyD17Pr45E1/Ii06Bvtcl82N2PgH0bz5bLPp7DRHMP1fOitZm71AXO8ROpJNEGSG03A
9Cw4GnS6c2wczFbpUMcHMhJNrCUqGswOsPEiC1dF6biZKdHEWrKiaS7xk2fy90yiCZbYaAJXA4Kjaah97Mjd1HHNTKKJt4RMz06j
KbCAHa9eQ0g0QVIdzeNuBl4D4waERBMk1dE8WMDrgd2BkGgCpTqa+l14zC3tX9Ms0cRRoqJxdk6WvnUCIdGEkNpovrFzh0gEBVAY
hRlFt4AXNBuQVFGxA0Uh2H8xQzEKR/L4vvrnU264i+1huXsc9g5nonnPf0YzX6+Hy/H0OJznw81P/c0QzRh8fTTPNsPdaopo3vTv
0SCaTDSIJhINoolEg2gi0SCaSDSIJhINoolEg2iisUTzuRmNaF4ZSTQviprAaIkGRANPRAORaCASDUSigUg0EIkGItFAJBqIRAOR
aCASDUSigUg0EIkGItFAJBqIRAORaCASDUSigUg0EIkGItFc2buX1jSiAIrjPbg5i+IYrekjAy6CM9RCMgkFoVUK0tgWgggWl0o0
pSSkUSkkUAp9rPr60FVj4phR680qvXP+CxeOLn/MzJ177yhlmNAoZZj9aLQL9OK0DfRt6Dai0fsGFqUXDtyKhCbWCY3QKKGZl9AI
jdAYJjRCIzSGCY3QCI1hQiM0QmOY0AiN0BgmNEIjNIYJjdAIjWFCsyqaTKHQx6hCwYFhqaD3tA37ExqhmSlL+lUMIw9gViHNYTsp
2J7QCM11NHRvhCbw6X+skE3YntAITQQNj0JokpiWnP0iiXBdso+UxwpsT2iEJopmMztBk9k/9otuAPQ9r9DKFQcbpx/9XPcNgB/N
hr/bC7k5qbYB7NC3/vpMaITmOpoG2ZygcUmfLFVxSpY4bHf82QOeeSSj12LtCgewMmdDaIRmIZrzJumM0fTJTiZBNkdodoPPHjlw
jkgXcFmq98/pVxHKbZDHJ7Cw2hr5QWjsR/PpXqQ/K6HJ5niYHKHZevEilUxVeDBCUweaZBtYo4eUzxZQI3sIlSPZzcC+nHRxv8yE
0FiP5suDSN9WQoMjsjdCg/aZ55N0J2g+kDXA5SYCMud5HrmHUKfvmiWWYV1OOu0g6ZWFxno0r6O/ersaGrisjNCc5Jjb6ZTmoHlH
liqj9jDbGbkOyxqbwUa6JTRCsxhN1ecIzUvyFNicg+YHeYbZujtd4OIvdjUxs8bnQmM9mi8PI31bEQ06YzR7ZIDP/hw0yQb9evbF
XkhOmextBx6ZhVVdmclrIMB+NJ/uRvq6KpqtzRGaAskcyc0IGgT+xWh0G5c5JY57Caty0sWJGQ05xwDN8pajQWKEBr002RqQ6xE0
qLo+Sx8dTOu7JL16EjY1NaOHm0Kz2tKATG0bi3pTywDgpASAVNuyS7OQGc0IiAWau48ivV6OxrwwGgub3s9oGk080JiMnt287CQ7
n2lemtHcs/8VzbJWRKOVmyYVL81owuZ/iubOnVt5prG458xfmNEs59igufck0i+hMajJjbEZLQ2ID5qbjZ49CwB8LxQK1SSuH0rU
A8SotTVn32Ne62mE5h9onh4CaDbKZbdxXkW4l365lT54jLi0wWFeXovQhGY1NF0Mqzd+YFr9cB1IuXuIS7W1cr6mlZvxQvP7fqSf
RmiQ8ELnlUECw44aiH1CYy8a09GzKBq4r3CVs41hfQ+xT2iEZgmaDx2EuzgS+4RGaJagOXIRav00f96Kz0CA0MQQjfHSgCiaxDFC
BYPDzXdQQmMvmnkZoqmXMdtGuYPYJzRCswRN9z2ulc3ZODNTaOYkNDdCk/ICXPbmLIVhyXQVcU9ohGYxms4gg6uOn2NYwd9C3BMa
oVmAZqtfdlOYlm8kUnBiNCNAaITGCI1fLHo79W2ES+xWvNK+9dubC80koVkNTbQgd9EBhj0+sWvXDKFZltDonZtCY5jQCI3QGCY0
QiM0hgmN0AiNYUIjNEJjmNAIjdAYJjRCIzSGCY3QCI1hQiM0QmNYDNDcuIQyTGgsQaPU7U5olBIapWYSGqUMExqlDBMapQwTGqUM
ExqlDBMapQwTGqUMExqlDBMapQwTGqUMExqlDBMapQwTGqUMExr1l517eU0jiqM4zmkXPYui0dT0EcGFOCFTSLUEAp0RIRhTIUjB
0mVKbYokJE1LIUgp9LHq64+uxphJO6Z4S7Rzr+ezEAWXfpnxzv1dMaRoRAwpGhFDikbEkKIRMaRoRAwpGhFDikbEkKIRMaRoRAzN
NpobOiH2cjohdm79PZprOov8MjqLfH4pmqRQNNZQNEmhaKyhaJJC0VhD0SSForGGokkKRWMNRZMUisYaiiYpFI01FE1SKBprKJqk
UDTWUDRJoWisMZtovvj+Mvpavp+DsUXfT8N5isYas4lmi6ygb4csw1iXrMJ5isYaM4uGwb9G0woVjSTJ7KLpNS9Es4hzqeg1enfR
CyoaSZLZRcP6KJql3TYLuzXg2PMa+bC4lTrurXj76AsqhfBdgIv2WQwVjSTIzKLpMTwcRlMrkitkew11MmTfHgcaQLDCgXVElgpc
z7oaTXpZ0VjIOJr3t2J+ThLNdsjqMJo6+WqhQ64P3lUa+yQ7jZfkDlazbG/7D+k1cW6Xb+FoNK0MeaRo7GMczcc7MZ8niaZRJ7dP
o8nVakilyTrq5HMgwyKQI3fxgdwHSmQDIwHDQ0ejSWc3dqq8rmisYxzN4/jXnk4UTbNHr0OWgeBtkX07Z9HkWQRAVgafi55XZPRL
WmizCzej6TeTBryqorHO7KJBQG6SZbwi2ycn46J5SW6eWseZY4649nhz2MxydlfRWMf89uxuzOfJokGFfWW84GYOz8mjWDRPyH0M
xaJ5A6ecNZNhoGisY74QcDPm04TRPMqeRpNhIZeqj7vStEJ6280v+aic1dbABvOtBbjkvJmSFgLsM7Ml58bZdaOMDhlmSVZi0aBL
cmX4HDTi4H+aqBktOVtoptGkDsgymicrDLsFPoxHg+ttcrOyBLejiZrRw00bGUdz817MY9PRgLWvi7jM6iGA1zyTg4OiZrQjwEpT
Xz0z53o0UTPaRmOnG3/z36JZzA3NUTOKxh7JjMZlG6NmtGHTSeOiuXU/5ruimVzA0rAZ7XJ2k84IuHpbXD5tRqMBjrq6aMpLQNP3
/SCHMcqHmBuZTPrIY0nzNK66umgebgNLfFut9vbq+NMHdjEvltnnlTSE5qxx0fy4HfNt0mhWAdTyHfzuQftgfqJpZaqlliY3HTbp
6plJNEjtHeM3zzqV+YlG486um0o0CPZwUdBbUDSKxhnTiWYxXENkrV2GolE0zjAYDTCIBu3XiGxtQdEoGncYrJ6ZRHNwHefK7TVF
o2gcMqVoimmMLLQ/QNEoGodMJ5rVjRRGXr7z+/In/nPMO0XjhulE8+QFzj2rDHgHlXXMO0XjhqlEUysGuEi3Z4rGJVOI5rBUeAVF
o2icddXRhBvZd503UDSKxl1TGw3oFoaOIIrGLZqnuZyiEUVjSNGIojGkaETRGFI0omgMKRpRNIYUjSgaQ4pGFI0hRSPjo/l318WQ
ohERERERERERERERERERkV/twSEBAAAAgKD/r71hAAAAAAAAAPgLCdDuGdsqDbAAAAAASUVORK5CYII=
`;
