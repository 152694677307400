import { deepEquals } from 'common/dist/utils/deepEquals';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AppArrangeModal from './app/arrange-modal/AppArrangeModal.container';
import EditorParent from './editor/editor-parent/EditorParent.container';
import DirectoryWizard from './wizards/directory/AddDirectoryWizard.container';
import EditDirectoryWizard from './wizards/directory/EditDirectoryWizard.container';
import EmptyRecycleBinWizard from './wizards/emptyRecycleBin/EmptyRecycleBinWizard.container';
import AddFileWizard from './wizards/file/AddFileWizard.container';
import WarningOpenFileWizard from './wizards/file/WarningOpenFileWizard';
import AddNotebookWizard from './wizards/notebook/AddNotebookWizard.container';
import EditNotebookWizard from './wizards/notebook/EditNotebookWizard.container';
import PasteNotebookWizard from './wizards/notebook/PasteNotebookWizard.container';
import RemoveModuleArtifactsWizard from './wizards/removeModuleArtifactsWizard/RemoveModuleArtifactsWizard';
import { workbenchRoutes } from '../../../workbench/common/workbenchRoutes';
import UploadModal from '../../organisms/workbench/upload-modal/UploadModal.container';
import DeleteContent from '../part-left/content/delete/DeleteContent.container';

export default class Workspace extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !deepEquals(this.props, nextProps);
  }

  renderScreenElement() {
    const { showDeleteContent } = this.props;

    /** Screen-filling parts */
    return (
      <Switch>
        {/*- Create Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.createNotebook}`}
          component={AddNotebookWizard}
        />
        {/*- Create File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.addFile}`}
          component={AddFileWizard}
        />
        {/*- Create Directory Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.addDirectory}`}
          component={DirectoryWizard}
        />
        {/*- Edit Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.editDirectory}`}
          component={EditDirectoryWizard}
        />

        {/*- Paste Notebook/File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.pasteNotebook}`}
          component={PasteNotebookWizard}
        />
        {/*- Edit Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.editNotebook}`}
          component={EditNotebookWizard}
        />
        {/*- Warning Open File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.warningOpenFile}`}
          component={WarningOpenFileWizard}
        />
        {/*- Delete Content Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}/:tab${workbenchRoutes.deleteContent}`}
        >
          <DeleteContent
            path={showDeleteContent.path}
            type={showDeleteContent.type}
            permanently={showDeleteContent.permanently}
          />
        </Route>
        {/* - Empty Recycle Bin Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}${workbenchRoutes.emptyRecycleBin}`}
        >
          <EmptyRecycleBinWizard />
        </Route>
        {/* - Remove Module Artifacts Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.removeModuleArtifacts}`}
          component={RemoveModuleArtifactsWizard}
        />
        {/*- Editor -*/}
        <Route path={workbenchRoutes.basePath} component={EditorParent} />
      </Switch>
    );
  }

  render() {
    return (
      <div className={'notebook-tab-content-container'}>
        {this.renderScreenElement()}
        <UploadModal />
        <AppArrangeModal />
      </div>
    );
  }
}

Workspace.propTypes = {
  /** Determines which screen is shown on the right */
  showOnRight: PropTypes.string,
  /** Does not determine which screen is displayed. Contains the information for the delete content wizard */
  showDeleteContent: PropTypes.shape({
    path: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    permanently: PropTypes.bool,
  }),
};
