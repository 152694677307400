// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/target-taxonomy/v1/type.ts' --type 'ConfigTargetTaxonomyConfig'
export const schemaConfigTargetTaxonomy = {
    "$ref": "#/definitions/ConfigTargetTaxonomyConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<TargetTaxonomyConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "type": "object"
        },
        "ConfigTargetTaxonomyConfig": {
            "$ref": "#/definitions/Config%3CTargetTaxonomyConfig%3E"
        }
    }
}

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/target-taxonomy/v1/type.ts' --type 'PartialTargetTaxonomyAugurSettings'
export const schemaAugurSettingsDefaultTargetTaxonomy = {
    "$ref": "#/definitions/PartialTargetTaxonomyAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "PartialTargetTaxonomyAugurSettings": {
            "$ref": "#/definitions/TargetTaxonomyAugurSettings"
        },
        "TargetTaxonomyAugurSettings": {
            "items": {
                "$ref": "#/definitions/Taxonomy"
            },
            "type": "array"
        },
        "Taxonomy": {
            "additionalProperties": false,
            "properties": {
                "id": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "sub": {
                    "items": {
                        "$ref": "#/definitions/Taxonomy"
                    },
                    "type": "array"
                }
            },
            "required": [
                "id",
                "name",
                "sub"
            ],
            "type": "object"
        }
    }
}