import { DashboardJob } from 'common/dist/types/job';
import { AugurReport } from 'common/dist/types/reports';
import {
  FiCrosshair,
  FiPieChart,
  FiPlayCircle,
  FiSettings,
  FiTarget,
} from 'react-icons/fi';

import { TimeTravelCategory, TimeTravelEntry } from './types';
import { AugurCategory } from '../../molecules/augur-menu/types';

export const timeTravelCategories: Record<string, TimeTravelCategory> = {
  learning: {
    Icon: FiCrosshair,
    name: 'Learning',
    placeholderName: 'No learning',
  },
  modelReactivation: {
    Icon: FiCrosshair,
    name: 'Model Reactivation',
  },
  evaluation: {
    Icon: FiPieChart,
    name: 'Evaluation',
    placeholderName: 'No evaluation',
  },
  prediction: {
    Icon: FiTarget,
    name: 'Prediction',
    placeholderName: 'No prediction',
  },
  realtime: {
    Icon: FiPlayCircle,
    name: 'Realtime',
  },
  settings: {
    Icon: FiSettings,
    name: 'Settings',
    placeholderName: 'Current Settings',
  },
};

export const getFirstActiveJobCodeOfCurrentCategory = (
  entries: TimeTravelEntry[],
  selectedPageCategory: AugurCategory,
  activeModelCode: string
) => {
  return entries
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
    .find(
      (entry) =>
        entry.type === selectedPageCategory &&
        entry.modelCode === activeModelCode
    );
};

export const getTimeTravelEntryFromReport = (
  report: AugurReport
): TimeTravelEntry => ({
  type: report.jobType as AugurCategory,
  code: report.code,
  jobCode: report.jobCode,
  modelCode: report.modelCode,
  timestamp: report.createdAt,
});

export const getTimeTravelEntryFromJob = (
  job: DashboardJob
): TimeTravelEntry => ({
  type: job.type as AugurCategory,
  code: job.code,
  jobCode: job.code,
  modelCode: job.modelCode,
  timestamp: job.createdAt,
});
