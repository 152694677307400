import { REPO_TYPE, RepoType } from 'common/dist/types/repository';
import { formatDate } from 'common/dist/utils/dates';
import React, { Component, Fragment } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

import ContextMenuRepo from './ContextMenuRepo.container';
import { iconFromRepoType } from '../../../atoms/altasigma-icons/AltaSigmaIcons';

function menuId(path) {
  return `cm-collab-${(path || '').replace('/', '').toLowerCase()}`;
}

interface ContentElementProps {
  /** Name of the element - Can be missing if the extra information besides git is not available */
  name?: string;
  /** Path of the element - Can be missing if the extra information besides git is not available */
  path?: string;
  /** Description of the repository */
  repoDescription: string;
  /** Name of the repository */
  repoName: string;
  /** Type of the repository. */
  repoType?: RepoType;
  /** Creation timestamp */
  createdAt: string;
  /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
  full_name: string;
  createdBy?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    code?: string;
  };
  /** If available can replace the group */
  speakingOwner?: string;
  currentUserId?: string;
}

export default class ContentElement extends Component<ContentElementProps> {
  render() {
    const {
      full_name,
      path,
      repoDescription,
      repoName,
      createdAt,
      repoType,
      createdBy,
      speakingOwner,
      name,
    } = this.props;

    const parts = (full_name || '').split('/');
    const group = parts[0];
    const pathName = parts[1];
    // Choose a menuId that works even if the extra repo information like path is missing
    const menuIdSafe = path !== undefined ? menuId(path) : menuId(full_name);
    const ContentIcon = iconFromRepoType(repoType);

    const Inner = (
      <div className={'repository'}>
        <div className={'repo-icon-parent'}>
          <ContentIcon size={'20px'} />
        </div>

        <div className={'repo-name-parent'}>
          <div className={'repo-name'}>
            <span className={'repo-group'}>{speakingOwner ?? group}</span> /{' '}
            <span className={'name'}>{name ?? pathName}</span>
          </div>
          <div className={'repo-description'}>
            {repoDescription ? (
              <span>{repoDescription}</span>
            ) : (
              <span>
                <i>No description available</i>
              </span>
            )}
          </div>
          <div className={'repo-created'}>
            <span>
              Created at{' '}
              {createdAt &&
                formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss')}
              {createdBy && ` by ${createdBy.firstName} ${createdBy.lastName}`}{' '}
            </span>
          </div>
        </div>
      </div>
    );

    // Only allow the delete dialog for plain repositories. For apps, code-capsules it would just delete the repository part
    //  and leave the other tables and code capsule versions/reports etc. alone. Better to be consistent.
    if (REPO_TYPE.PLAIN === repoType) {
      return (
        <Fragment>
          <ContextMenuTrigger id={menuIdSafe} holdToDisplay={-1}>
            {Inner}
          </ContextMenuTrigger>

          <ContextMenuRepo
            menuId={menuIdSafe}
            repoName={repoName}
            repoFullName={full_name}
            repoType={repoType}
          />
        </Fragment>
      );
    } else {
      return Inner;
    }
  }
}
