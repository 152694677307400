import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import PushConflictsModal from './PushConflictsModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../atoms/button/Button';

export default class GitNotPushed extends Component {
  renderLoaded() {
    const {
      data,
      repositoryPath,
      gitListNotPushedCommits,
      activeBranch,
      gitPush,
      disabled,
    } = this.props;
    return (
      <div className={'git-list-not-pushed-commits'}>
        <div className={'refresh-button-container'}>
          <Button
            Icon={() => <FiRefreshCw size={16} />}
            color={'transparent'}
            onClick={() =>
              gitListNotPushedCommits(repositoryPath, activeBranch, 20, 0)
            }
          />
        </div>

        <div className={'git-unpushed-status'}>
          {data && data.length > 0 ? (
            data.length > 1 ? (
              <span>There are {data.length} unpushed commits.</span>
            ) : (
              <span>There is {data.length} unpushed commit.</span>
            )
          ) : (
            <span>There are no unpushed commits.</span>
          )}
        </div>

        <div className={'git-button-bar'}>
          <Button
            color={'primary'}
            label={'Push'}
            disabled={disabled || !data?.length}
            onClick={() => gitPush(repositoryPath, activeBranch)}
          />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no commits for this repository yet
      </div>
    );
  }

  renderError() {
    const { error, repositoryPath, gitListCommits, activeBranch } = this.props;
    return (
      <div className={'git-list-commits'}>
        {error}

        <div className={'refresh-button-container'}>
          <Button
            Icon={() => <FiRefreshCw size={16} />}
            color={'transparent'}
            onClick={() => gitListCommits(repositoryPath, activeBranch, 20, 0)}
          />
        </div>
      </div>
    );
  }

  renderComponent() {
    const { loading, loaded, error } = this.props;
    if (loading) return this.renderLoading();
    if (loaded) return this.renderLoaded();
    if (error) return this.renderError();
    return this.renderEmpty();
  }

  render() {
    const {
      hidePushConflictsModal,
      pushConflictsModal,
      repositoryPath,
      gitPull,
    } = this.props;
    return (
      <Fragment>
        {this.renderComponent()}

        <PushConflictsModal
          show={pushConflictsModal.show}
          branch={pushConflictsModal.branch}
          commitsBehind={pushConflictsModal.commitsBehind}
          hidePushConflictsModal={hidePushConflictsModal}
          gitPull={gitPull}
          repositoryPath={repositoryPath}
        />
      </Fragment>
    );
  }
}
GitNotPushed.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      committed_date: PropTypes.string.isRequired,
      committer: PropTypes.string.isRequired,
      hexsha: PropTypes.string.isRequired,
    })
  ),
  error: PropTypes.string,
  repositoryPath: PropTypes.string.isRequired,
  activeBranch: PropTypes.string.isRequired,
  gitListNotPushedCommits: PropTypes.func.isRequired,
  gitPull: PropTypes.func.isRequired,
  gitPush: PropTypes.func.isRequired,
  pushConflictsModal: PropTypes.shape({
    show: PropTypes.bool,
    branch: PropTypes.string,
    commitsBehind: PropTypes.string,
  }),
  hidePushConflictsModal: PropTypes.func.isRequired,
  /** Whether the button is disabled */
  disabled: PropTypes.bool,
};
