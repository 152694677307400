import classNames from 'classnames';
import React, { FC, LegacyRef } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export type Props = {
  label?: string | MessageDescriptor;
  name?: string;
  onChange: (value?: boolean) => void;
  checked: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
  disabled?: boolean;
};

export const Checkbox: FC<DeIntl<Props>> = ({
  label,
  name,
  inputRef,
  onChange,
  checked,
  disabled,
}) => {
  return (
    <div
      className={classNames(styles.CheckBoxContainer, {
        [styles.disabled]: disabled,
      })}
      onClick={!disabled ? () => onChange?.(!checked) : undefined}
    >
      <div
        className={classNames(styles.Checkbox, {
          [styles.disabled]: disabled,
        })}
      >
        <input
          ref={inputRef}
          className={styles.CheckboxMarkInput}
          type='checkbox'
          name={name}
          checked={checked || false}
          disabled={disabled}
        />
        <span className={styles.CheckboxMark} />
      </div>
      {label && <span className={styles.CheckboxLabel}>{label}</span>}
    </div>
  );
};

export const IntlCheckbox: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={Checkbox} props={props} />;
};
