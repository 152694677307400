import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useMemo } from 'react';
import { FiCheck } from 'react-icons/fi';
import { Field } from 'redux-form';

import { fieldName } from './file.form';
import { validateFileName } from './validation';
import { useContent } from '../../../../../core/api/workbench/content';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import { useSelectedDirPath } from '../../../hooks';
import TextInput from '../_components/TextInput';

export interface Props {
  fileName: string;
  fileNameTouched: boolean;
  fileNameError: string;
}

const FileStep1: FC<Props> = ({ fileName, fileNameTouched, fileNameError }) => {
  const selectedDirPath = useSelectedDirPath();

  const { data } = useContent(contentArrayToPath(selectedDirPath, false));
  const content = useMemo(() => data?.content ?? [], [data]);

  const validate = useMemo(() => {
    return (value) => validateFileName(value, content);
  }, [content]);

  function errorMessage() {
    if (fileNameTouched && fileNameError) {
      return <p className={'error'}>{fileNameError}</p>;
    }
  }

  return (
    <div className={'step-container step-1-container'}>
      <div className={'description-container'}>
        {fileNameTouched ? (
          validateFileName(fileName, content) ? (
            <div className={'bubble bubble-delete'}>
              <p>1</p>
            </div>
          ) : (
            <div className={'bubble bubble-ok'}>
              <FiCheck className={'checked-icon'} size={'20px'} />
            </div>
          )
        ) : (
          <div className={'bubble bubble-info'}>
            <p>1</p>
          </div>
        )}

        <div className={'description'}>
          <div className={'title'}>
            <p>Select Name</p>
          </div>
          <div className={'text'}>
            <p>
              Select a name for the file. Browse on the left to change the path
              where the file will be created.
            </p>
          </div>
        </div>
      </div>
      <div
        className={
          'input-container' +
          (fileNameTouched && validateFileName(fileName, content)
            ? ' input-container-error'
            : '')
        }
      >
        <div className={'error-container'}>{errorMessage()}</div>
        <div className={'name-input'}>
          <Field
            name={fieldName}
            label={fieldName}
            type='text'
            component={TextInput}
            validate={validate}
          />
          <SelectedDirs
            dirs={[...selectedDirPath, fileName]}
            highlightLastEntry
          />
        </div>
      </div>
    </div>
  );
};

export default FileStep1;
