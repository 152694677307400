import React, { ChangeEvent, Component } from 'react';
import InputError from '../../input-error/InputError';
import './styles.scss';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

export type Props = {
  /** Has the field been touched? */
  touched: boolean;
  /** Has the field an error? */
  error?: string | { id: string; default: string; values: object };
  /** Is the field disabled? */
  disabled: boolean;
  intl: IntlShape;
  /** ID of the radio buttons group */
  id?: string;
  /** Render a label for the input field? */
  hasLabel: boolean;
  /** Intl ID for the label */
  labelId?: string;
  /** Default message for the label */
  labelDefault?: string;
  /** Selected value */
  value: string;
  /** onChange callback */
  onChange: (value: string) => void;
  /** List with the specs for the radio buttons to render */
  radioButtons: RadioButton[];
  /** Defaults to 1, if higher, puts more radio buttons in a row */
  entriesPerRow?: number;
  /** Defaults to '1fr' but can be overwritten to control the column width, for example with "25%" or "200px" */
  columnWidth?: string;
  /** Optional: ID of the initially selected value */
  defaultValue?: string;
};

export type RadioButton = {
  /** Value of the radio button (not displayed) */
  value: string;
  /** Intl ID of the label */
  labelId?: string;
  /** Default message of the label */
  labelDefault: string;
  /** Is the radio button disabled? */
  disabled?: boolean;
};

class RadioButtonsInput extends Component<Props, {}> {
  static defaultProps = {
    entriesPerRow: 1,
    columnWidth: '1fr',
  };

  componentDidMount() {
    // --- If a default value is given: Select it.
    const { value, onChange, defaultValue } = this.props;
    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }

  render() {
    const {
      hasLabel,
      labelId,
      labelDefault,
      intl,
      touched,
      error,
      radioButtons,
      value,
      onChange,
      disabled,
      entriesPerRow,
      columnWidth,
    } = this.props;

    const label = intl.formatMessage({
      id: labelId || 'no-id',
      defaultMessage: labelDefault,
    });

    return (
      <div className={'RadioButtonsInput'}>
        <div className={'RadioButtonsInput--header'}>
          {hasLabel && <p className={'RadioButtonsInput--label'}>{label}</p>}
          <div className='RadioButtonsInput--error'>
            <InputError
              touched={touched}
              // @ts-ignore
              error={error}
            />
          </div>
        </div>

        <div
          className={'RadioButtonsInput--buttons'}
          style={{
            gridTemplateColumns: `repeat(${entriesPerRow}, ${columnWidth})`,
          }}
        >
          {radioButtons.map((radioButton, index) => (
            <div key={`rb_${index}`} className={'RadioButtonsInput--button'}>
              <label
                className={
                  'RadioButtonsInput--radio' +
                  (disabled || radioButton.disabled ? ' disabled' : '')
                }
              >
                <input
                  type={'radio'}
                  value={radioButton.value}
                  checked={value === radioButton.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e.currentTarget.value);
                  }}
                  disabled={disabled || radioButton.disabled} // The whole line is disabled, or only the single button is disabled
                />
                <span>
                  <FormattedMessage
                    id={radioButton.labelId || 'no-id'}
                    defaultMessage={radioButton.labelDefault}
                  />
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

/** @deprecated use react hook form variant instead*/
export default injectIntl(RadioButtonsInput);
