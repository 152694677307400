// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/text-input-line/v1/type.ts' --type 'ConfigTextInputLineConfig'
export const schemaConfigTextInputLine = {
  $ref: '#/definitions/ConfigTextInputLineConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<TextInputLineConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        placeholder: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      type: 'object',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(string|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    ConfigTextInputLineConfig: {
      $ref: '#/definitions/Config%3CTextInputLineConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/text-input-line/v1/type.ts' --type 'PartialTextInputLineAugurSettings'
export const schemaAugurSettingsDefaultTextInputLine = {
  $ref: '#/definitions/PartialTextInputLineAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialTextInputLineAugurSettings: {
      $ref: '#/definitions/TextInputLineAugurSettings',
    },
    TextInputLineAugurSettings: {
      type: 'string',
    },
  },
};
