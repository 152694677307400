import classNames from 'classnames';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import React, { FC, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import AddSummandGroup from './AddSummandGroup';
import {
  TargetFunctionErrorType,
  TargetFunctionValueType,
} from './CampaignOptimizationTargetFunction';
import { SPEAKING_SUM_OF, SPEAKING_SUM_OVER } from './common';
import styles from './styles.module.scss';
import Table, { RenderColumn } from '../../../../../molecules/table/Table';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

type Props = {
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
  value: TargetFunctionValueType;
  onChange?: (...event: any[]) => void;
  error?: DeepPartial<TargetFunctionErrorType>;
  onBlur?: React.FocusEventHandler;
  readOnly?: boolean;
};

const SummandGroupsTable: FC<Props> = (props) => {
  const { addedNewRow, rowIndex, value, error, onChange, onBlur, readOnly } =
    props;

  const summandGroups: TargetFunctionSummandGroup[] = value.summandGroups;

  const initialVisible = {
    index: addedNewRow ? summandGroups.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };

  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : summandGroups.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<
    TargetFunctionSummandGroup,
    keyof TargetFunctionSummandGroup
  >[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      // @ts-ignore
      renderCell: (name: string, summandGroup: TargetFunctionSummandGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[summandGroup.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,

      renderCell: (
        description?: string,
        // @ts-ignore
        summandGroup: TargetFunctionSummandGroup
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[summandGroup.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'sumOver',
      renderHeader: () => <span>Sum Over</span>,
      // @ts-ignore
      renderCell: (
        sumOver: TargetFunctionSummandGroup['sumOver'],
        summandGroup: TargetFunctionSummandGroup
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[summandGroup.id]?.sumOf,
          })}
        >
          {SPEAKING_SUM_OVER[sumOver]}
        </span>
      ),
    },
    {
      key: 'sumOf',
      renderHeader: () => <span>Sum Of</span>,
      // @ts-ignore
      renderCell: (
        sumOf: TargetFunctionSummandGroup['sumOf'],
        summandGroup: TargetFunctionSummandGroup
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[summandGroup.id]?.sumOf,
          })}
        >
          {SPEAKING_SUM_OF[sumOf]}
        </span>
      ),
    },
    {
      key: 'factor',
      renderHeader: () => <span>Factor</span>,
      // @ts-ignore
      renderCell: (
        factor: string,
        summandGroup: TargetFunctionSummandGroup
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[summandGroup.id]?.factor,
          })}
        >
          {factor}
        </span>
      ),
    },
  ];

  const onRemove = (removedRow: TargetFunctionSummandGroup) => {
    const updatedSummandGroups = summandGroups.filter(
      (con) => con.id !== removedRow.id
    );
    onChange?.({
      ...value,
      summandGroups: updatedSummandGroups,
    });
  };

  const renderClickedRow = (
    element: TargetFunctionSummandGroup,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddSummandGroup
                rowIndex={rowIndex}
                constraintId={element.id}
                removableRows={visible.shown}
                onRemoveRow={onRemove}
                value={value}
                onChange={onChange}
                error={error}
                onBlur={onBlur}
                readOnly={readOnly}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={summandGroups}
      renderColumns={renderColumns}
      showHeader
      removableRows={true}
      verticalAlignMiddle
      onRemoveRow={onRemove}
      addlRowClassname={(
        rowIndex: number,
        summandGroup: TargetFunctionSummandGroup
      ) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!error?.rows?.[summandGroup.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
      readOnly={readOnly}
    />
  );
};

export default SummandGroupsTable;
