// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/heatmap/v1/type.ts' --type 'ConfigHeatmapConfig'
export const configSchemaHeatmap = {
  $ref: '#/definitions/ConfigHeatmapConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<HeatmapConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        showNumbers: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
        xAxisLabel: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
        yAxisLabel: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      type: 'object',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'boolean',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(string|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    ConfigHeatmapConfig: {
      $ref: '#/definitions/Config%3CHeatmapConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'HeatmapV1ReportData'
export const schemaReportTypeHeatmap = {
  $ref: '#/definitions/HeatmapV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    HeatmapV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        data: {
          items: {
            items: {
              type: 'number',
            },
            maxItems: 3,
            minItems: 3,
            type: 'array',
          },
          type: 'array',
        },
        xAxis: {
          items: {
            type: 'string',
          },
          type: 'array',
        },
        yAxis: {
          items: {
            type: 'string',
          },
          type: 'array',
        },
      },
      required: ['data', 'xAxis', 'yAxis'],
      type: 'object',
    },
  },
};
