import classNames from 'classnames';
import repositoryMsgs from 'common/dist/messages/repository';
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactModal from 'react-modal';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import infoStyles from './../../info/styles.module.scss';
import FieldCommitMessage from './FieldCommitMessage';
import { gitCommitForm } from './gitCommit.form';
import {
  connectedProps,
  GitCommitModalProps,
} from './GitCommitModal.container';
import Button from '../../../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

type Props = connectedProps &
  GitCommitModalProps &
  WrappedComponentProps &
  InjectedFormProps<
    connectedProps & GitCommitModalProps & WrappedComponentProps
  >;

const GitCommitModal: React.FC<Props> = (props) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    const {
      pristine,
      valid,
      commitMessage,
      gitAddAllAndCommit,
      repositoryPath,
      activeBranch,
    } = props;
    if (!pristine && valid && !buttonClicked) {
      setButtonClicked(true);
      gitAddAllAndCommit(repositoryPath, commitMessage, activeBranch);
    }
  };

  const { isOpen, hideGitCommitModal, valid, pristine, intl } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={intl.formatMessage(repositoryMsgs.msgGitCommitModal)}
      onRequestClose={hideGitCommitModal}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={
        () => document.querySelector('#MainContainer')
        /* Function that will be called to get the parent element
           that the modal will be attached to. */
      }
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={intl.formatMessage(repositoryMsgs.msgGitCommitModalClose)}
          className={classNames('workbench-button', 'close-button')}
          onClick={hideGitCommitModal}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={infoStyles.modalCommitElements}>
          <Field
            name={gitCommitForm.fieldCommitMessage}
            label={intl.formatMessage(repositoryMsgs.msgGitCommitMessage)}
            placeholder={'Commit Message'}
            component={FieldCommitMessage}
          />
        </div>

        <div className={infoStyles.modalCommitButtons}>
          <Button
            color={'primary'}
            label={'Add & Commit'}
            disabled={pristine || !valid || buttonClicked}
            onClick={handleClick}
          />
        </div>
      </div>
    </ReactModal>
  );
};

//@ts-ignore
export default reduxForm(gitCommitForm)(injectIntl(GitCommitModal));
