import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import * as form from './rename.form';
import { fieldName } from './rename.form';
import styles from './styles.module.scss';
import { moveObject } from '../../../../redux/modules/data.s3.module';
import { ButtonProps } from '../../../atoms/button/Button';
import { WrappedTextInputLine } from '../../../atoms/input-elements/text-input-line/TextInputLine';
import Modal from '../../../organisms/modal/Modal';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  fileName: string;
  filePath: string;
  fileBucket: string;
  fileDataSourceCode: string;
};

const ModalDialog: FC<
  Props & InjectedFormProps<{ [fieldName]: string }, Props>
> = (props) => {
  const {
    isOpen,
    closeDialog,
    valid,
    fileName,
    fileBucket,
    filePath,
    fileDataSourceCode,
  } = props;
  const nameVal = useSelector((state) =>
    formValueSelector(form.form.form)(state, fieldName)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change(form.form.form, fieldName, fileName));
  }, [isOpen]);

  const buttons: ButtonProps[] = [
    {
      color: 'white',
      label: 'Cancel',
      onClick: () => {
        closeDialog();
      },
    },
    {
      color: 'primary',
      label: 'OK',
      onClick: () => {
        if (valid) {
          dispatch(
            moveObject(
              fileBucket,
              // @ts-ignore
              filePath,
              fileBucket,
              filePath.replace(new RegExp(`${fileName}$`), nameVal),
              fileDataSourceCode
            )
          );
          closeDialog();
        }
      },
    },
  ];

  return (
    <Modal
      show={isOpen}
      buttons={buttons}
      hideModal={() => {
        closeDialog();
      }}
    >
      <div className={classNames('modal-parent', styles.renameModal)}>
        <div
          className={classNames('modal-body-headline', styles.modalHeadline)}
        >
          <span>Rename file {fileName}</span>
        </div>
        <div>
          <Field name={form.fieldName} component={WrappedTextInputLine} />
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm<{ [fieldName]: string }, Props>(form.form)(
  ModalDialog
);
