import { connect } from 'react-redux';
import CloneRepositoryWizard from './CloneRepositoryWizard';

import { formName } from './cloneRepository.form';
import { cloneGitRepository } from '../../../../redux/workbench/modules/container-interactions.module';
import { checkWhetherNotebookIsRunning } from '../../../../redux/workbench/modules/connection.module';
import { fetchRepoDetails } from '../../../../redux/workbench/modules/collab.repository.module';

import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';

function getGitDisplayName(currentUser) {
  if (!currentUser) return '';
  return `${currentUser.firstName} ${currentUser.lastName}`;
}

export function mapStateToProps(state) {
  const currentUser = state.currentUser;
  const gitDisplayName = getGitDisplayName(currentUser);
  const gitMail = currentUser.email;

  const { repoCode } = state.workbench.showCloneRepository;
  const repoDetails = state.workbench.collab.repoDetails;
  const { activeBranch } = state.workbench.collab.repository;

  return {
    filledCompletely: !state.form[formName] || !state.form[formName].syncErrors,
    repoCode,
    activeBranch,
    gitDisplayName,
    gitMail,
    repoDetails,
    notebookRunning: state.workbench.notebookRunning,
    notebookUser: notebookUser(state),
  };
}

export const mapDispatchToProps = {
  fetchRepoDetails,
  cloneGitRepository,
  checkWhetherNotebookIsRunning,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloneRepositoryWizard);
