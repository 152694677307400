import { createAction } from 'redux-act';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { findIndex, includes, keys, values } from 'lodash';

import * as dashboard from '../../core/dashboard';
import { addNewAugur, addNewHabitat } from '../../core/summary';
import * as ModelManagementApi from '../../core/api/modelManagement';
import { sendNotification } from './notifications.module';
import { event } from '../../core/notifications';
import * as summaryState from '../state/summary.state';

export const fetchSummary = createAction('fetch summary');

export const fetchSummarySuccess = createAction(
  'summary fetch success',
  (summary) => summary
);

export const fetchSummaryFail = createAction(
  'summary fetch fail',
  (error) => error
);

export const newAugurAdded = createAction('new augur added', (augur) => ({
  augur,
}));

export const newHabitatAdded = createAction(
  'new habitat added',
  (habitat) => habitat
);

export const augurProgres = createAction('augur in progress');

export const reducer = {
  [fetchSummary]: (state) => ({
    ...state,
    summary: summaryState.initial,
    dashboard: {
      ...state.dashboard,
      loading: true,
      loaded: false,
    },
  }),

  [fetchSummarySuccess]: (state, summary) => {
    const oldHabitats = state.dashboard.habitats;
    const habitats = dashboard.composeHabitats(oldHabitats, summary);

    // Also extract the augur and habitat names
    const augurNames = Object.fromEntries(
      summary.flatMap((habitat) =>
        habitat.augurs.map((augur) => [augur.code, augur.name])
      )
    );
    const habitatNames = Object.fromEntries(
      summary.map((habitat) => [habitat.code, habitat.name])
    );
    return {
      ...state,
      summary,
      dashboard: {
        ...state.dashboard,
        loading: false,
        loaded: true,
        habitats,
      },
      names: {
        ...state.names,
        augurNames: {
          ...state.names.augurNames,
          ...augurNames,
        },
        habitatNames: {
          ...state.names.habitatNames,
          ...habitatNames,
        },
      },
    };
  },

  [fetchSummaryFail]: (state, error) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      error,
      loading: false,
      loaded: false,
      habitats: {},
    },
  }),

  [augurProgres]: (state, { augurCode, progress, inProgress }) => {
    const oldDashboard = state.dashboard;
    const oldHabitats = state.dashboard.habitats;
    const targetHabitatIndex = findIndexOfHabitatWithAugur(
      values(oldHabitats),
      augurCode
    );
    const targetHabitatName = keys(oldHabitats)[targetHabitatIndex];
    const targetHabitat = oldHabitats[targetHabitatName];
    if (!targetHabitat) return state; // Happens for example if the user doesn't have permissions on this Habitat

    const targetAugurIndex = findIndex(
      targetHabitat.augurs.map((a) => a.code),
      (a) => a === augurCode
    );
    const targetAugur = targetHabitat.augurs[targetAugurIndex];

    return {
      ...state,
      dashboard: {
        ...oldDashboard,
        habitats: {
          ...oldHabitats,
          [targetHabitatName]: {
            ...targetHabitat,
            augurs: [
              ...targetHabitat.augurs.slice(0, targetAugurIndex),
              {
                ...targetAugur,
                inProgress,
                progress,
              },
              ...targetHabitat.augurs.slice(targetAugurIndex + 1),
            ],
          },
        },
      },
    };
  },
};

function findIndexOfHabitatWithAugur(habitats, augurCode) {
  return findIndex(habitats, (habitat) =>
    includes(
      habitat.augurs.map((a) => a.code),
      augurCode
    )
  );
}

export function* fetchSummarySaga() {
  const { response, error } = yield call(ModelManagementApi.fetchSummary);
  if (response) {
    yield put(fetchSummarySuccess(response));
  } else {
    yield put(fetchSummaryFail(error));
  }
}

export function* newAugurAddedSaga({ payload: { augur } }) {
  const state = yield select();

  const summary = yield call(addNewAugur, state.summary, augur);
  yield put(fetchSummarySuccess(summary));
  yield put(
    sendNotification(
      'notifications.title.new_augur',
      'notifications.description.augur_was_created',
      event,
      { augurCode: augur.code, augurName: augur.name }
    )
  );
}

export function* newHabitatAddedSaga({ payload: habitat }) {
  let state = yield select();

  if (!state.dashboard.loaded) {
    yield call(fetchSummarySaga);
    state = yield select();
  }

  const summary = yield call(addNewHabitat, state.summary, habitat);
  yield put(fetchSummarySuccess(summary));
}

export function* watchNewAugurAdded() {
  yield takeEvery(newAugurAdded.getType(), newAugurAddedSaga);
}

export function* watchNewHabitatAdded() {
  yield takeEvery(newHabitatAdded.getType(), newHabitatAddedSaga);
}

export function* watchFetchSummary() {
  yield takeEvery(fetchSummary.getType(), fetchSummarySaga);
}
