import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styles from './styles.module.scss';

export type Props<T> = {
  data?: T[];
  title: string;
  initialCollapsed: boolean;
  renderHeader: (title: string, collapsed: boolean) => ReactElement;
  renderBody: (data: T[]) => ReactElement;
  onToggle?: (isCollapsed: boolean) => void;
};

const GenericCollapsibleContainer = <T extends unknown>(
  props: PropsWithChildren<Props<T>>
): ReactElement<Props<T>> => {
  const { data, title, initialCollapsed, renderHeader, renderBody, onToggle } =
    props;
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  useEffect(() => {
    setCollapsed(initialCollapsed);
  }, [initialCollapsed]);

  const handleToggle = useCallback(() => {
    setCollapsed((prev) => {
      const newState = !prev;
      onToggle?.(newState);
      return newState;
    });
  }, [onToggle]);

  const headerContent = useMemo(
    () => renderHeader(title, collapsed),
    [renderHeader, title, collapsed]
  );
  const bodyContent = useMemo(
    () => (!collapsed ? renderBody(data || []) : null),
    [collapsed, data, renderBody]
  );

  return (
    <>
      <div className={styles.header} onClick={handleToggle}>
        {headerContent}
      </div>
      {bodyContent}
    </>
  );
};

export default React.memo(GenericCollapsibleContainer);
