import { createAction } from 'redux-act';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import * as SessionApi from '../../../core/api/workbench/session';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import {
  notificationDeleteSessionFail,
  notificationDeleteSessionSuccess,
  notificationPostSessionFail,
  notificationPostSessionSuccess,
} from '../notifications/notifications';
import { notebookUser } from '../selectors/notebookUser.selector';
import { updateKernelState } from './notebook.websocket.module';
import { ExecutionState } from '../../../store/workbench/state.types';
import { initialState } from '../state/initial';

export const clearWorkbenchCache = createAction('clear workbench cache');

export const fetchSessions = createAction(
  'fetch sessions',
  (retry = false) => ({ retry })
);

export const fetchSessionsSuccess = createAction(
  'fetch sessions - success',
  (nodes) => nodes
);

export const fetchSessionsFail = createAction(
  'fetch sessions - fail',
  (error) => error
);

export const postSession = createAction(
  'create session',
  (
    notebookName,
    notebookPath,
    kernelName,
    jupyterUser,
    notification = false
  ) => ({ notebookName, notebookPath, kernelName, jupyterUser, notification })
);

export const postSessionSuccess = createAction(
  'create session - success',
  (session) => ({ session })
);

export const postSessionFailure = createAction(
  'create session - failure',
  (error) => error
);

export const deleteSession = createAction('delete session', (id, name) => ({
  id,
  name,
}));

export const deleteSessionSuccess = createAction(
  'delete session - success',
  (id) => ({ id })
);

export const deleteSessionFailure = createAction(
  'delete session - failure',
  (id, error) => ({ id, error })
);

export const mainBrowserResize = createAction(
  'main browser resize',
  (newWidth) => ({ newWidth })
);

export const getSessionDetails = createAction(
  'get session details',
  (sessionId) => ({ sessionId })
);

export const getSessionDetailsSuccess = createAction(
  'get session details - success',
  (data) => data
);

export const getSessionDetailsNotFound = createAction(
  'get session details - not found',
  (sessionId) => ({ sessionId })
);

export const getSessionDetailsFailure = createAction(
  'get session details - failure',
  (error) => error
);

export const reducer = {
  [clearWorkbenchCache]: () => initialState,
  [fetchSessions]: (state) => ({
    ...state,
  }),
  [fetchSessionsSuccess]: (state, data) => ({
    ...state,
    sessions: {
      ...state.sessions,
      error: null,
      data,
    },
  }),
  [fetchSessionsFail]: (state, error) => ({
    ...state,
    sessions: {
      ...state.sessions,
      error,
      data: null,
    },
  }),
  [postSessionSuccess]: (state, { session }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [session.path]: {
        ...(state.notebooks[session.path] || {}),
        session,
      },
    },
    sessions: {
      ...state.sessions,
      data: [
        // Jupyter will reuse sessions, so creating one may simply return one we already know
        ...state.sessions.data.filter((s) => s.id !== session.id),
        session,
      ],
    },
  }),
  [mainBrowserResize]: (state, { newWidth }) => ({
    ...state,
    browserWidth: newWidth,
  }),
  [getSessionDetailsNotFound]: (state, { sessionId }) => ({
    ...state,
    sessions: {
      ...state.sessions,
      error: null,
      data: state.sessions.data.filter((session) => session.id !== sessionId),
    },
  }),
};

export function* fetchSessionsSaga({ payload: { retry } }) {
  try {
    const jupyterUser = yield select((state) => notebookUser(state));
    const { response, error } = yield call(
      SessionApi.fetchSessions,
      jupyterUser
    );
    if (response) {
      yield put(fetchSessionsSuccess(response));
    } else {
      yield put(fetchSessionsFail(error));
    }
  } catch (e) {
    // This happens if the connection to the notebook can't be established (yet)
    if (retry) {
      yield delay(3000);
      yield put(fetchSessions(retry));
    }
  }
}

export function* watchFetchSessions() {
  yield takeEvery(fetchSessions().type, fetchSessionsSaga);
}

export function* postSessionSaga({
  payload: {
    notebookName,
    notebookPath,
    kernelName,
    jupyterUser,
    notification,
  },
}) {
  const { response, error } = yield call(
    SessionApi.postSession,
    notebookName,
    notebookPath,
    kernelName,
    jupyterUser
  );
  if (response) {
    yield put(postSessionSuccess(response));
    if (notification)
      yield put(successNotification(notificationPostSessionSuccess()));
  } else {
    yield put(postSessionFailure(error));
    if (notification)
      yield put(successNotification(notificationPostSessionFail()));
  }
}

export function* watchPostSession() {
  yield takeEvery(postSession().type, postSessionSaga);
}

export function* deleteSessionSaga({ payload: { id, name } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    SessionApi.deleteSession,
    id,
    jupyterUser
  );
  if (response) {
    yield put(deleteSessionSuccess(id));
    yield put(fetchSessions());
    yield put(successNotification(notificationDeleteSessionSuccess(name)));
  } else {
    yield put(deleteSessionFailure(id, error));
    yield put(fetchSessions());
    yield put(errorNotification(notificationDeleteSessionFail(name)));
  }
}

export function* watchDeleteSession() {
  yield takeEvery(deleteSession().type, deleteSessionSaga);
}

export function* getSessionDetailsSaga({ payload: { sessionId } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error, status } = yield call(
    SessionApi.fetchSessionDetails,
    jupyterUser,
    sessionId
  );
  if (response) {
    yield put(getSessionDetailsSuccess(response));
    yield put(updateKernelState(sessionId, response?.kernel?.execution_state));
  } else {
    yield put(getSessionDetailsFailure(error));
    if (status === 404) {
      yield put(getSessionDetailsNotFound(sessionId));
      yield put(updateKernelState(sessionId, ExecutionState.NotFound));
    } else {
      yield put(updateKernelState(sessionId, ExecutionState.Unknown));
    }
  }
}

export function* watchGetSessionDetails() {
  yield takeEvery(getSessionDetails().type, getSessionDetailsSaga);
}
