import { defineMessages } from 'react-intl';

export default defineMessages({
  msgPermissionSyncErr: {
    id: 'cassandra.permissions.sync.error',
    defaultMessage: 'Permissions could not be synced to cassandra',
  },
  msgPermissionSyncErrDetails: {
    id: 'cassandra.permissions.sync.error.details',
    defaultMessage: 'Will automatically retry later.',
  },
  msgPermissionHabitatUser: {
    id: 'cassandra.permissions.habitat.user',
    defaultMessage: 'Keyspaces fetched with Habitat Permissions.',
  },
  msgPermissionUser: {
    id: 'cassandra.permissions.logged.in.user',
    defaultMessage: 'Keyspaces fetched with User Permissions.',
  },
});
