import { HealthValue } from 'common/dist/constants/keyPerformanceIndicators';
import React, { FC } from 'react';

import vars from '../../../../../scss/base/var.module.scss';
import NameAndIcon from '../name-and-icon/NameAndIcon';
import NameAndIconLoading from '../name-and-icon/NameAndIconLoading';

type Props = {
  isLoaded: boolean;
  augurName: string;
  augurHealth: HealthValue;
  habitatName: string;
};

const NameContainer: FC<Props> = ({
  isLoaded,
  augurName,
  augurHealth,
  habitatName,
}) => {
  if (!isLoaded) {
    return (
      <NameAndIconLoading
        gridColumnStart={1}
        gridColumnEnd={1}
        gridRowStart={1}
        gridRowEnd={'span 2'}
        Icon={() => (
          <span
            className='icon-augur'
            style={{
              color: vars.colorPrimary,
              fontSize: '20px',
            }}
          />
        )}
      />
    );
  }

  return (
    <NameAndIcon
      gridColumnStart={1}
      gridColumnEnd={1}
      gridRowStart={1}
      gridRowEnd={'span 2'}
      Icon={() => (
        <span
          className='icon-augur'
          style={{
            color: vars.colorPrimary,
            fontSize: '20px',
          }}
        />
      )}
      name={augurName}
      subName={habitatName}
      showHealthBar
      health={augurHealth}
    />
  );
};

export default NameContainer;
