import Anime from 'animejs';
import classNames from 'classnames';
import habitatMessages from 'common/dist/messages/habitats';
import _ from 'lodash';
import React, { Component, ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import MainTab, { MainTabIdType, MainTabType } from './main-tabs/MainTab';
import { deriveMainTabs } from './main-tabs/mainTabs';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import ExpandCollapseIcon from '../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import * as routes from '../../index/routes';

export type Props = {
  selectMainTab: (mainTabId: MainTabIdType) => void;
  fetchComponentPermissions: () => void;
  hasOverlay?: boolean;
  Overlay?: ComponentType<any>;
  /** Tab that is selected per default */
  defaultSelectedTabId?: MainTabIdType;
  permissions: Record<string, string[]>;
};

export type RouteParams = {
  mainTab: string;
};

type State = {
  showOverlay: boolean;
  mainTabs: MainTabType[];
  mainTabIds: string[];
};

class SubHeader extends Component<
  Props & RouteComponentProps<RouteParams>,
  State
> {
  tabWidths = {}; // Mapping of tabId -> width

  overlayContainerRef;
  overlayContainerHeight = 0; // Measured dynamically

  throttledFetchComponentPermissions = undefined; // Will be set in the constructor
  animateUnderline = _.throttle(
    (target: string, selectedTabId: string, prevSelectedMainTab: string) => {
      const { defaultSelectedTabId } = this.props;
      Anime.remove(target); // Stop and remove this element from current animation if present...

      // If the selectedTabId is undefined - use the default value (if given)
      let selectedTabIdOrDefault = selectedTabId;
      if (defaultSelectedTabId && !selectedTabIdOrDefault) {
        selectedTabIdOrDefault = defaultSelectedTabId;
      }

      // const PADDING = 30;
      const width = this.tabWidths[selectedTabIdOrDefault];

      // Sum of the main tabs left of the selected tab
      const transX = _.sum(
        this.state.mainTabIds
          .slice(0, this.state.mainTabIds.indexOf(selectedTabIdOrDefault))
          .map((tabId) => this.tabWidths[tabId])
      );

      if (
        prevSelectedMainTab &&
        this.state.mainTabIds.includes(prevSelectedMainTab)
      ) {
        // Regular elastic animation
        Anime({
          targets: target,
          translateX: transX,
          width,
          duration: 1500,
          elasticity: 200,
        });
      } else if (!selectedTabId) {
        // Simply show the line (no tab was selected)
        Anime({
          targets: target,
          translateX: [
            {
              value: transX,
              duration: 0,
              delay: 0,
            },
          ],
          width: [
            {
              value: width,
              duration: 0,
              delay: 0,
            },
          ],
          duration: 1500,
          elasticity: 200,
        });
      } else {
        // Only animate the width
        Anime({
          targets: target,
          translateX: [
            {
              value: transX + width / 2,
              duration: 0,
              delay: 0,
            },
            {
              value: transX,
              duration: 1500,
              delay: 0,
            },
          ],
          width,
          duration: 1500,
          elasticity: 200,
        });
      }
    },
    200,
    { trailing: true, leading: true }
  );

  constructor(props: Props & RouteComponentProps<RouteParams>) {
    super(props);

    const { fetchComponentPermissions } = props;

    const { mainTabs, mainTabIds } = deriveMainTabs(props.permissions);
    this.overlayContainerRef = React.createRef();
    this.handleMeasure = this.handleMeasure.bind(this);

    this.state = {
      showOverlay: true,
      mainTabs,
      mainTabIds,
    };

    // Invoke `fetchComponentPermissions` when the tab is changed, but not more than once every 30 seconds.
    this.throttledFetchComponentPermissions = _.throttle(
      fetchComponentPermissions,
      30 * 1000,
      { trailing: false }
    );
  }

  showOverlay() {
    this.animateOverlay(-1 * this.overlayContainerHeight, 0);
  }

  hideOverlay() {
    this.animateOverlay(0, -1 * this.overlayContainerHeight);
  }

  animateOverlay(startTop: number, endTop: number) {
    const target = '#sub-header-overlay';
    Anime.remove(target); // Stop and remove this element from current animation if present...

    const animationDuration = 300;

    Anime({
      targets: target,
      top: [
        {
          value: startTop,
          duration: 0,
          delay: 0,
        },
        {
          value: endTop,
          duration: animationDuration,
          delay: 0,
        },
      ],
      duration: animationDuration,
      easing: 'easeInQuad',
    });
  }

  componentDidMount() {
    // Move the underline to the correct position
    const {
      match: {
        params: { mainTab },
      },
      hasOverlay,
    } = this.props;
    this.animateUnderline('#underline', mainTab, undefined);

    if (hasOverlay) {
      this.overlayContainerHeight =
        this.overlayContainerRef.current.clientHeight;
      // Uncomment the following line to enable the "intro"-animation of the headline again
      // this.showOverlay();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      permissions,
      match: {
        params: { mainTab },
      },
    } = this.props;
    const {
      match: {
        params: { mainTab: prevMainTab },
      },
    } = prevProps;
    // Move the underline properly to the selected main tab
    if (!prevProps.match || !prevMainTab || mainTab !== prevMainTab) {
      this.animateUnderline('#underline', mainTab, prevMainTab);
    }

    if (!_.isEqual(prevProps.permissions, permissions)) {
      const { mainTabs, mainTabIds } = deriveMainTabs(permissions);
      this.setState({
        mainTabs,
        mainTabIds,
      });
    }
  }

  renderRightPartForModelManagement() {
    const addHabitatLink =
      routes.app.prefix + routes.app.models + '/' + routes.app.newHabitat;

    return (
      <div className={styles.subheaderRight}>
        <Button
          id={'newHabitatButton'}
          label={habitatMessages.habitatAdd}
          color={'secondary'}
          linkTo={addHabitatLink}
          Icon={() => (
            <span
              className={'icon-home'}
              style={{
                fontSize: '18px',
                opacity: '.5',
                marginLeft: '-5px',
              }}
            />
          )}
        />
      </div>
    );
  }

  handleMeasure(mainTabId, width) {
    const {
      match: {
        params: { mainTab },
      },
    } = this.props;
    this.tabWidths = {
      ...this.tabWidths,
      [mainTabId]: width,
    };

    this.animateUnderline('#underline', mainTab, mainTab);
  }

  render() {
    const {
      match: {
        params: { mainTab },
      },
      history,
      selectMainTab,
      hasOverlay,
      Overlay,
      defaultSelectedTabId,
    } = this.props;
    const { showOverlay } = this.state;

    // If the selectedTabId is undefined - use the default value (if given)
    let selectedTabIdOrDefault = mainTab;
    if (defaultSelectedTabId && !selectedTabIdOrDefault) {
      selectedTabIdOrDefault = defaultSelectedTabId;
    }

    return (
      <div className={styles.subheader}>
        <div className={classNames(styles.subheaderRow)}>
          <div className={styles.subheaderRowInner}>
            {hasOverlay && (
              <div className={styles.overlayIcon}>
                <ExpandCollapseIcon
                  isExpanded={showOverlay}
                  onClick={() => {
                    this.setState({ showOverlay: !showOverlay });
                    if (!showOverlay) {
                      this.showOverlay();
                    } else {
                      this.hideOverlay();
                    }
                  }}
                />
              </div>
            )}

            <div className={styles.subheaderLeft}>
              <div
                className={classNames(styles.mainTabContainer, {
                  [styles.mainTabsHidden]: hasOverlay && showOverlay,
                })}
              >
                {this.state.mainTabs.map((tab) => (
                  <MainTab
                    key={tab.id}
                    id={tab.id}
                    path={tab.path}
                    title={tab.title}
                    isActive={selectedTabIdOrDefault === tab.id}
                    history={history}
                    handleMainTabSelect={(mainTabId: MainTabIdType) => {
                      this.throttledFetchComponentPermissions();
                      selectMainTab(mainTabId);
                    }}
                    onMeasure={(mainTabId: string, width: number) => {
                      this.handleMeasure(mainTabId, width);
                    }}
                    Icon={tab.Icon}
                  />
                ))}
                <div className={styles.mainTabUnderline} id='underline' />
              </div>
            </div>

            {selectedTabIdOrDefault === 'models' &&
              this.renderRightPartForModelManagement()}
          </div>

          {hasOverlay && (
            <div
              id='sub-header-overlay'
              className={classNames(
                styles.subheaderRow,
                styles.subheaderOverlayRow
              )}
              ref={this.overlayContainerRef}
            >
              <div className={styles.subheaderRowInner}>
                <Overlay />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SubHeader);
