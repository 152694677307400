import React, { FC, useEffect } from 'react';

import Header from './header/Header';
import CodeCapsulesContainer from './code-capsules/CodeCapsules.container';
import { HabitatDetailsType } from 'common/dist/types/habitat';
import styles from './styles.module.scss';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import Augurs from './augurs/Augurs';

export type OrderType = {
  label: string; // Refine list of available labels
  value: string;
};

type Props = {
  habitat: HabitatDetailsType;
  activeTab: HABITAT_TAB;
  isListView: boolean;
  hasNoAugurs: boolean;
  /** Set the active tab for this Habitat */
  setActiveTab: (activeTab: HABITAT_TAB) => void;
  fetchCodeCapsules: (affiliationType: 'habitat', habitatCode: string) => void;
  setHabitatView: (habitatCode: string, isListView: boolean) => void;

  codeCapsules: {
    loading: boolean;
    loaded: boolean;
    error?: string | object;
    data: CodeCapsule[];
  };

  order: OrderType;
  setOrder: (habitatCode: string, order: OrderType) => void;
};

export enum HABITAT_TAB {
  AUGURS = 'augurs',
  CODE_CAPSULES = 'code-capsules',
}

const Habitat: FC<Props> = (props) => {
  const {
    habitat,
    activeTab,
    setActiveTab,
    fetchCodeCapsules,
    codeCapsules,
    isListView,
    setHabitatView,
    order,
    setOrder,
  } = props;

  // componentDidMount
  useEffect(() => fetchCodeCapsules('habitat', habitat.code), []);

  return (
    <div className={styles.habitat} id={`habitat-${habitat.code}`}>
      <Header
        habitat={habitat}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        codeCapsulesCount={(codeCapsules?.data || []).length}
        isListView={isListView}
        setHabitatView={(isListView: boolean) =>
          setHabitatView(habitat.code, isListView)
        }
        order={order}
        setOrder={(order: OrderType) => setOrder(habitat.code, order)}
      />

      <div className={styles.habitatContent}>
        {activeTab === HABITAT_TAB.AUGURS && (
          <div>
            <Augurs habitatCode={habitat.code} />
          </div>
        )}

        {activeTab === HABITAT_TAB.CODE_CAPSULES && (
          <div>
            <CodeCapsulesContainer habitatCode={habitat.code} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Habitat;
