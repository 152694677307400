// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraKeyspaceSelect/v1/type.ts' --type 'ConfigCassandraKeyspaceSelectConfig'
export const schemaConfigCassandraKeyspaceSelect = {
  $ref: '#/definitions/ConfigCassandraKeyspaceSelectConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<CassandraKeyspaceSelectConfig>': {
      additionalProperties: {
        $ref: '#/definitions/ConfigEntry%3Cnever%3E',
      },
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigCassandraKeyspaceSelectConfig: {
      $ref: '#/definitions/Config%3CCassandraKeyspaceSelectConfig%3E',
    },
    'ConfigEntry<never>': {
      additionalProperties: false,
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      properties: {
        elementUuid: {
          type: 'string',
        },
        source: {
          const: 'input-element',
          type: 'string',
        },
      },
      required: ['source', 'elementUuid'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/cassandraKeyspaceSelect/v1/type.ts' --type 'PartialCassandraKeyspaceSelectAugurSettings'
export const schemaAugurSettingsDefaultCassandraKeyspaceSelect = {
  $ref: '#/definitions/PartialCassandraKeyspaceSelectAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialCassandraKeyspaceSelectAugurSettings: {
      additionalProperties: false,
      properties: {
        dataSourceCode: {
          type: 'string',
        },
        keyspaceName: {
          type: 'string',
        },
      },
      type: 'object',
    },
  },
};
