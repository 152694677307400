import { ContentElement, ContentElementType } from './GenericFileBrowser';
import {
  JupyterContentElement,
  JupyterContentType,
} from '../../../../store/workbench/state.types';
import {
  RECYCLE_BIN_PATH,
  WORKBENCH_FILENAMES,
} from '../../../workbench/part-right/config';

export function getFileType(
  type: JupyterContentType,
  name: string
): ContentElementType {
  switch (type) {
    case 'notebook':
      return ContentElementType.FILE_NOTEBOOK;
    case 'directory':
      if (name.endsWith('.asr')) {
        return ContentElementType.DIRECTORY_REPOSITORY;
      } else if (name === RECYCLE_BIN_PATH) {
        return ContentElementType.RECYCLE_BIN;
      } else {
        return ContentElementType.DIRECTORY_PLAIN;
      }
    case 'file':
      if (name === WORKBENCH_FILENAMES.REPOSITORY_META) {
        return ContentElementType.FILE_REPOSITORY_INFO;
      } else if (name === WORKBENCH_FILENAMES.GITIGNORE) {
        return ContentElementType.FILE_GITIGNORE;
      } else if (name === WORKBENCH_FILENAMES.MODULE_CONFIG) {
        return ContentElementType.FILE_CONFIG;
      } else if (name === WORKBENCH_FILENAMES.AUGUR_SETTINGS) {
        return ContentElementType.FILE_AUGUR_SETTINGS;
      } else if (name.endsWith('.py')) {
        return ContentElementType.FILE_CODE;
      } else if (name.endsWith('.asapp')) {
        return ContentElementType.FILE_NOTEBOOK;
      } else if (
        name.endsWith('.txt') ||
        name.endsWith('.csv') ||
        name.endsWith('.json') ||
        name.endsWith('.yaml') ||
        name.startsWith('.') ||
        name === 'Dockerfile'
      ) {
        return ContentElementType.FILE_TEXT;
      } else if (name.endsWith('.md') || name.endsWith('.MD')) {
        return ContentElementType.FILE_MARKDOWN;
      }
      return ContentElementType.FILE_DEFAULT;
  }
}

/**
 * Replace the general jupyter type with a more specific type for each content element.
 * @param content
 */
export function computeFileTypes(
  content: JupyterContentElement[]
): ContentElement[] {
  return content.map((c) => ({
    ...c,
    type: getFileType(c.type, c.name),
  }));
}

/**
 * Add the file types to each content element and if in a subdirectory add a content element representing the parent directory.
 * @param content
 * @param selectedDirPath
 */
export function prepareContent(
  content: JupyterContentElement[],
  selectedDirPath: string[]
): ContentElement[] {
  const selectedContent = computeFileTypes(content);
  if (selectedDirPath.length > 0) {
    selectedContent.unshift({
      name: '..',
      path: '',
      type: ContentElementType.SPECIAL_ONE_DIRECTORY_UP,
    });
  }
  return selectedContent;
}
