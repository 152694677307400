import { info as infoNotification } from 'react-notification-system-redux';
import { connect } from 'react-redux';

import ButtonBar from './ButtonBar';
import {
  closeNotebook,
  saveNotebook,
} from '../../../../../redux/workbench/modules/notebook.module';

export const connectDispatchToProps = {
  saveNotebook,
  infoNotification,
  closeNotebook,
};

export default connect(null, connectDispatchToProps)(ButtonBar);
