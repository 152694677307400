import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface DevAugurState {
  selectedPageId?: string;
  selectedElementId?: string;
}

const initialState: DevAugurState = {};

export const devAugurSlice = createSlice({
  name: 'devAugur',
  initialState,
  reducers: {
    selectPage: (state, action) => {
      state.selectedPageId = action.payload;
    },
    selectElement: (state, action) => {
      state.selectedElementId = action.payload;
    },
  },
});

export const { selectElement, selectPage } = devAugurSlice.actions;

export const selectSelectedPageId = (state: RootState): string =>
  state.workbench.devAugur.selectedPageId;

export const selectSelectedElementId = (state: RootState): string =>
  state.workbench.devAugur.selectedElementId;

export default devAugurSlice.reducer;
