import {
  AugurReportElement,
  AugurSettingsElement,
  ModuleConfiguration,
} from '../type';

/**
 * Returns all pages of a given Module Configuration.
 * @param config Module Configuration
 */
export function getPages(config: ModuleConfiguration) {
  return [
    ...config.augurReportConfiguration.learning,
    ...config.augurReportConfiguration.evaluation,
    ...config.augurReportConfiguration.prediction,
    ...config.augurSettingsConfiguration,
  ];
}

/**
 * Returns the AugurPage with the given uuid.
 * Returns undefined if uuid was undefined or page could not be found.
 * @param config Module Configuration
 * @param uuid Augur page string uuid
 */
export function getPage(config: ModuleConfiguration, uuid: string) {
  if (!uuid) return undefined;

  const pages = getPages(config);
  return pages.find((page) => page.uuid === uuid);
}

/**
 * Returns all layout elements of a given Module Configuration.
 * @param config Module Configuration
 */
export function getElements(config: ModuleConfiguration) {
  const pages = getPages(config);
  return pages.reduce(
    (acc, page) => acc.concat(page.elements),
    [] as Array<AugurReportElement | AugurSettingsElement>
  );
}

/**
 * Returns the Augur layout element with the given uuid.
 * Returns undefined if uuid was undefined or element could not be found.
 * @param config Module Configuration
 * @param uuid Augur layout element string uuid
 */
export function getElement(config: ModuleConfiguration, uuid?: string) {
  if (!uuid) return undefined;

  const elements = getElements(config);
  return elements.find((element) => element.uuid === uuid);
}

export const FALLBACK_CONFIG: ModuleConfiguration = {
  apiVersion: 'fallback-version',
  generalConfiguration: {
    supportsLearning: false,
    supportsEvaluation: false,
    supportsPrediction: false,
    supportsRealtimePrediction: false,
  },
  augurReportConfiguration: {
    learning: [],
    evaluation: [],
    prediction: [],
  },
  augurSettingsConfiguration: [],
};
