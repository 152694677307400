import { scaleSqrt } from 'd3-scale';
import { line, curveBundle } from 'd3-shape';

export const linearLinkThickness = (domain, range) =>
  scaleSqrt().domain(domain).range(range);

export const lineLink = line()
  // @ts-ignore
  .x(({ x }) => x)
  // @ts-ignore
  .y(({ y }) => y)
  .curve(curveBundle.beta(1.0));

export const elbowLink = (radius) => (source, target) =>
  lineLink([
    source,
    { x: target.x - radius, y: source.y },
    { x: target.x, y: source.y - radius },
    target,
  ]);
