// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/parameter-tuning-results/v1/type.ts' --type 'ConfigParameterTuningResultsConfig'
export const schemaConfigParameterTuningResults = {
  $ref: '#/definitions/ConfigParameterTuningResultsConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ParameterTuningResultsConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigParameterTuningResultsConfig: {
      $ref: '#/definitions/Config%3CParameterTuningResultsConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'ParameterTuningResultsV1ReportData'
export const schemaReportTypeParameterTuningResults = {
  $ref: '#/definitions/ParameterTuningResultsV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    AvailableAlgorithm: {
      additionalProperties: false,
      properties: {
        description: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        parameters: {
          items: {
            $ref: '#/definitions/Parameter',
          },
          type: 'array',
        },
        speakingName: {
          type: 'string',
        },
      },
      required: ['name', 'speakingName', 'description', 'parameters'],
      type: 'object',
    },
    Parameter: {
      additionalProperties: false,
      properties: {
        default: {
          items: {
            type: 'number',
          },
          type: 'array',
        },
        description: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        speakingName: {
          type: 'string',
        },
        validValues: {
          items: {
            $ref: '#/definitions/ValueType',
          },
          type: 'array',
        },
      },
      required: [
        'name',
        'speakingName',
        'description',
        'validValues',
        'default',
      ],
      type: 'object',
    },
    ParameterTuningResultsType: {
      additionalProperties: false,
      properties: {
        bestModel: {
          anyOf: [
            {
              $ref: '#/definitions/SingleParametersResult',
            },
            {
              $ref: '#/definitions/SinglePathResult',
            },
          ],
        },
        kpi: {
          description:
            'KPI the model has been optimized against during training',
          type: 'string',
        },
        singleResults: {
          items: {
            anyOf: [
              {
                $ref: '#/definitions/SingleParametersResult',
              },
              {
                $ref: '#/definitions/SinglePathResult',
              },
            ],
          },
          type: 'array',
        },
      },
      required: ['bestModel', 'kpi', 'singleResults'],
      type: 'object',
    },
    ParameterTuningResultsV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        modelTuningResults: {
          $ref: '#/definitions/ParameterTuningResultsType',
        },
        parameterTuningSchema: {
          $ref: '#/definitions/ParameterTuningSchema',
        },
      },
      required: ['modelTuningResults', 'parameterTuningSchema'],
      type: 'object',
    },
    ParameterTuningSchema: {
      additionalProperties: false,
      description:
        'Top level schema describing the available algorithms for tuning',
      properties: {
        availableAlgorithms: {
          items: {
            $ref: '#/definitions/AvailableAlgorithm',
          },
          type: 'array',
        },
        availableSearchStrategies: {
          items: {
            additionalProperties: false,
            properties: {
              description: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
              speakingName: {
                type: 'string',
              },
            },
            required: ['name', 'speakingName', 'description'],
            type: 'object',
          },
          type: 'array',
        },
      },
      required: ['availableSearchStrategies', 'availableAlgorithms'],
      type: 'object',
    },
    ParameterType: {
      additionalProperties: false,
      description:
        'Describes the actual used parameters during one single parameter optimization run',
      properties: {
        name: {
          description:
            'Parameter name in the form: "clf|preprocessing__<node_id>__<parameter_id>" For example: "clf__decision_tree_classifier__min_samples_leaf"',
          type: 'string',
        },
        value: {
          description:
            'Actual used value of the parameter during one parameter optimization run',
          type: ['string', 'number'],
        },
      },
      required: ['name', 'value'],
      type: 'object',
    },
    SingleParametersResult: {
      additionalProperties: false,
      properties: {
        algorithm: {
          type: 'string',
        },
        parameters: {
          items: {
            $ref: '#/definitions/ParameterType',
          },
          type: 'array',
        },
        score: {
          type: 'number',
        },
      },
      required: ['algorithm', 'parameters', 'score'],
      type: 'object',
    },
    SinglePathResult: {
      additionalProperties: false,
      description:
        'Type for the result of one tuning step of one path followed through one pipeline. Since:\n- there are several pipeline schemas, the pipelineId is tracked\n- there are several paths through a pipeline, the pathId is tracked\n- there are several parameter constellations for a path, the parameters are tracked',
      properties: {
        parameters: {
          items: {
            $ref: '#/definitions/ParameterType',
          },
          type: 'array',
        },
        pathId: {
          type: 'string',
        },
        pipelineId: {
          type: 'string',
        },
        score: {
          type: 'number',
        },
      },
      required: ['parameters', 'pathId', 'pipelineId', 'score'],
      type: 'object',
    },
    ValueType: {
      additionalProperties: false,
      properties: {
        max: {
          type: 'number',
        },
        min: {
          type: 'number',
        },
        type: {
          enum: ['int', 'double'],
          type: 'string',
        },
      },
      required: ['type', 'min', 'max'],
      type: 'object',
    },
  },
};
