import { RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX } from 'common/dist/constants/keycloak';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import { connect } from 'react-redux';

import ModelManagement from './ModelManagement';
import { fetchSummary } from '../../../redux/modules/dashboard.summary.module';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
  );

  return {
    missingPermissionsError,
  };
}

export const mapDispatchToProps = {
  fetchSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelManagement);
