import { getSelectedDirPathQuery } from '../../../workbench/utils';
import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';

export const onClickListeners = {
  [ContentElementType.FILE_CODE]: undefined,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: undefined,
  [ContentElementType.FILE_NOTEBOOK]: undefined,
  [ContentElementType.FILE_REPOSITORY_INFO]: undefined,
  [ContentElementType.FILE_TEXT]: undefined,
  [ContentElementType.FILE_AUGUR_SETTINGS]: undefined,
  [ContentElementType.FILE_CONFIG]: undefined,
  [ContentElementType.FILE_GITIGNORE]: undefined,
  [ContentElementType.DIRECTORY_PLAIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) =>
    // FIXME: not sure if worth it but would be nice if we could also just use the hook here OR get rid of state and dispatch
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    ),
  [ContentElementType.DIRECTORY_REPOSITORY]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    );
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery(
        selectedPath.slice(0, -1)
      )}`
    );
  },
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
  [ContentElementType.RECYCLE_BIN]: undefined,
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: undefined,
};

export const contextMenuEntries = {
  [ContentElementType.FILE_CODE]: [],
  [ContentElementType.FILE_DEFAULT]: [],
  [ContentElementType.FILE_MARKDOWN]: [],
  [ContentElementType.FILE_NOTEBOOK]: [],
  [ContentElementType.FILE_REPOSITORY_INFO]: [],
  [ContentElementType.FILE_TEXT]: [],
  [ContentElementType.FILE_AUGUR_SETTINGS]: [],
  [ContentElementType.FILE_CONFIG]: [],
  [ContentElementType.FILE_GITIGNORE]: [],
  [ContentElementType.DIRECTORY_PLAIN]: [],
  [ContentElementType.DIRECTORY_REPOSITORY]: [],
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
  [ContentElementType.SPECIAL_LAUNCHER]: [],
  [ContentElementType.RECYCLE_BIN]: [],
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: [],
};
