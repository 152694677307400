import React, { FC, useState } from 'react';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { Motion, spring } from 'react-motion';

import styles from './styles.module.scss';
import vars from '../../../../../../../../scss/base/var.module.scss';

import { Point } from 'common/dist/types/module.charts';
type Props = {
  item: Point;
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
};

const Bar: FC<Props> = (props) => {
  const { xScale, yScale, item } = props;

  const x = item[0];
  const y = item[1];

  if (!y) return <></>;

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const indexOfDot = y.toString().indexOf('.');
  const digitsAfterDot =
    indexOfDot === -1 ? 0 : y.toString().length - indexOfDot - 1;
  const toFixed =
    indexOfDot === -1 ? 0 : digitsAfterDot >= 2 ? 2 : digitsAfterDot;

  return (
    <>
      <Motion defaultStyle={{ y: 0 }} style={{ y: spring(y) }}>
        {(value: { y: number }) => (
          <rect
            className={styles.bar}
            fill={vars.colorPrimaryHighlight}
            width={xScale.bandwidth()}
            height={Math.abs(yScale(0) - yScale(value.y))}
            x={xScale(x)}
            y={yScale(value.y)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </Motion>

      {showTooltip && (
        <>
          <rect
            rx={2}
            fill='#444'
            width={y.toFixed(toFixed).length * 9 + 4}
            height={20}
            x={xScale(x)! - y.toFixed(toFixed).length * 5 - 2}
            y={yScale(y) - 22}
          ></rect>
          <text
            x={xScale(x)! - y.toFixed(toFixed).length * 5}
            y={yScale(y) - 7}
            fill='#eee'
          >
            {y.toFixed(toFixed)}
          </text>
        </>
      )}
    </>
  );
};

export default Bar;
