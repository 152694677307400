import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Button from '../../../../atoms/button/Button';
import { FormattedRelativeTimeConvenient } from '../../../../atoms/formatted-date-time/FormattedDateTime';
import LoadableData from '../../../../molecules/loadable-data/LoadableData';
import {
  moduleMergeRequestEditLink,
  repositoryMergeRequestEditLink,
} from '../../../routes';

type MergeRequestData = {
  title: string;
  user: {
    full_name: string;
  };
  created_at: string;
};

export type OwnProps = {
  id: number;
  group: string;
  repositoryName: string;
};

export type ContainerProps = {
  mergeRequest: {
    loading: boolean;
    loaded: boolean;
    error: string;
    data: MergeRequestData;
  };
};

export type Props = OwnProps & ContainerProps;

const MergeRequestDetailsHeader: FunctionComponent<Props> = (props) => {
  const LoadableDataMrInfo = LoadableData<MergeRequestData, string>();
  const {
    params: { moduleCode },
  } = useRouteMatch<{ moduleCode: string }>();
  let linkTo = repositoryMergeRequestEditLink(
    props.group,
    props.repositoryName,
    props.id
  );
  if (moduleCode)
    linkTo = moduleMergeRequestEditLink(
      moduleCode,
      props.group,
      props.repositoryName,
      props.id
    );

  return (
    <div className={'repository-top'}>
      <div className={'repository-info'}>
        <div className={'repository-top-line'}>
          <LoadableDataMrInfo
            loadableData={props.mergeRequest}
            renderData={(info: MergeRequestData) => (
              <div className={'top-side-by-side-parent'}>
                <div>
                  <div>
                    <span className={'repository-name'}>{info.title}</span>
                  </div>
                  <div className={'repository-description'}>
                    <span>Opened </span>
                    <span>
                      <FormattedRelativeTimeConvenient
                        date={new Date(info.created_at)}
                      />
                    </span>
                    &nbsp;by&nbsp;
                    <span>{info.user.full_name}</span>
                  </div>
                </div>
                <Button color={'primary'} label={'Edit'} linkTo={linkTo} />
              </div>
            )}
            renderError={(error: string) => (
              <span className={'name error'}>{error}</span>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default MergeRequestDetailsHeader;
