import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { SharedInputProps } from '../../../../common/utils';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import styles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { HeatmapConfig, HeatmapReportData } from '../type';

const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
});

export type Props = HeatmapReportData & HeatmapConfig & SharedInputProps;

const Heatmap: FC<Props> = ({
  data,
  xAxis,
  yAxis,
  showNumbers,
  xAxisLabel,
  yAxisLabel,
}) => {
  const colors = [
    useThemeColor('primary-highlight', '-lighter90'),
    useThemeColor('primary-highlight'),
    useThemeColor('primary'),
  ];

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  let lowestValue: number = data[0][0];
  let highestValue: number = data[0][0];
  data.forEach((val) => {
    if (val[2] < lowestValue) {
      lowestValue = val[2];
    }
    if (val[2] > highestValue) {
      highestValue = val[2];
    }
  });

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      position: 'top',
    },
    grid: {
      top: '50px',
      left: '85px',
      bottom: '85px',
      right: '95px',
    },
    xAxis: {
      name: xAxisLabel,
      type: 'category',
      nameLocation: 'middle',
      data: xAxis,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      name: yAxisLabel,
      nameLocation: 'middle',
      type: 'category',
      data: yAxis,
      splitArea: {
        show: true,
      },
    },

    visualMap: {
      show: false,
      min: lowestValue,
      max: highestValue,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '5%',
      inRange: {
        color: colors,
      },
    },
    series: [
      {
        name: '',

        type: 'heatmap',
        data: data.map((d) => [d[0], d[1], formatter.format(d[2])]),
        label: {
          show: !!showNumbers,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <div ref={ref} className={styles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export default Heatmap;

export const HeatmapSingle: FC<
  ReportElementProps<HeatmapReportData, HeatmapConfig>
> = buildSingleReportWrapper<HeatmapReportData, HeatmapConfig>(Heatmap);
