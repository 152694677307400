import {
  DataSource,
  DSType,
} from 'common/dist/types/dataManagement/dataSource';
import React, { FC, useEffect } from 'react';
import { FiDatabase } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import CassandraPermissions from './cassandra/CassandraPermissions';
import KeyspacePermissions from './cassandra/KeyspacePermissions';
import BucketPermissions from './s3/BucketPermissions';
import S3Permissions from './s3/S3Permissions';
import styles from './styles.module.scss';
import { fetchDataSources } from '../../../../redux/modules/data.dataSources.module';
import { RootState } from '../../../../store/store';
import { toLinkWhitelistQuery } from '../../../../utils';
import * as routes from '../../../index/routes';
import {
  LinkType,
  LoadingType,
  MenuEntryType,
} from '../../../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../../../molecules/with-sidemenu/WithSidemenu';
import { MAIN_MENU_QUERY_WHITELIST } from '../../../organisms/main-menu/MainMenu';
import MainContainer from '../../../pages/main-container/MainContainer';
import NotFound from '../../../pages/not-found/NotFound.container';
import { usersRoutes } from '../routes';

export const detailsRoute = (
  dataSourceType: string,
  dataSourceCode: string
): string =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.dataSourcePermissions.path}/${dataSourceType}/${dataSourceCode}`;

export const bucketOrKeyspaceRoute = (
  dataSourceType: string,
  dataSourceCode: string,
  bucketOrKeyspaceName: string
): string =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.dataSourcePermissions.path}/${dataSourceType}/${dataSourceCode}/${bucketOrKeyspaceName}`;

function getActiveCategory(path: string) {
  if (!path) return null;
  const rep = path.replace(
    `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.dataSourcePermissions.path}/`,
    ''
  );
  const parts = rep.split('/');
  // parts[0]: data source type, parts[1]: data source code
  return parts.length >= 2 ? parts[1] : null;
}

const DataSourcePermissions: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  const dataSources = useSelector<RootState, DataSource[]>(
    (state) => state.data?.dataSources?.data
  );

  const dataSourcesLoading = useSelector<RootState, boolean>(
    (state) => state.data?.dataSources?.loading
  );

  const deriveMenuEntries = () => {
    const showLoading =
      (!dataSources || dataSources.length === 0) && dataSourcesLoading;

    if (showLoading) {
      return Array.from({ length: 3 }).map(
        (_, i) =>
          ({
            type: 'loading',
          } as LoadingType)
      );
    }

    const entries: MenuEntryType[] = [];
    const cassandraDataSources = dataSources
      .filter((ds) => ds.ds_type === DSType.Cassandra)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    const s3DataSources = dataSources
      .filter((ds) => ds.ds_type === DSType.S3)
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    if (s3DataSources?.length > 0) {
      entries.push({
        type: 'headline',
        headline: {
          id: 'todo',
          defaultMessage: 'S3',
        },
      });
    }

    s3DataSources.forEach((ds: DataSource) =>
      entries.push({
        type: 'link',
        id: ds.code,
        to: detailsRoute(ds.ds_type, ds.code),
        nameIntlId: 'todo',
        nameDefault: ds.name,
        icon: FiDatabase, // TODO Think about this icon
      } as LinkType)
    );

    if (cassandraDataSources?.length > 0) {
      if (entries.length > 0) {
        entries.push({ type: 'hr' });
      }

      entries.push({
        type: 'headline',
        headline: {
          id: 'todo',
          defaultMessage: 'Cassandra',
        },
      });

      cassandraDataSources.forEach((ds: DataSource) =>
        entries.push({
          type: 'link',
          id: ds.code,
          to: detailsRoute(ds.ds_type, ds.code),
          nameIntlId: 'todo',
          nameDefault: ds.name,
          icon: FiDatabase, // TODO Think about this icon
        } as LinkType)
      );
    }

    return entries;
  };

  const getDefaultRoute = () => {
    const firstDataSource = dataSources?.[0];
    if (!firstDataSource)
      return `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.dataSourcePermissions.path}/`;
    else return detailsRoute(firstDataSource.ds_type, firstDataSource.code);
  };

  const menuEntries: MenuEntryType[] = deriveMenuEntries();

  const history = useHistory();
  const selectedCategory = getActiveCategory(history.location.pathname);
  const location = useLocation();

  return (
    <MainContainer fullWidth transparent>
      <div className={styles.dataSourcePermissions}>
        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          preserveWhitelistQueryOnLink={['search']}
        >
          <div className={styles.permissionsContainer}>
            <Switch>
              {/* Cassandra Keyspace permissions */}
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}/${DSType.Cassandra}/:dataSourceCode/:keyspace`}
                component={KeyspacePermissions}
              />

              {/* Cassandra Keyspace list */}
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}/${DSType.Cassandra}/:dataSourceCode`}
                component={CassandraPermissions}
              />

              {/* S3 Bucket permissions */}
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}/${DSType.S3}/:dataSourceCode/:bucket`}
                component={BucketPermissions}
              />

              {/* S3 Bucket list */}
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}/${DSType.S3}/:dataSourceCode`}
                component={S3Permissions}
              />

              <Redirect
                to={toLinkWhitelistQuery(
                  getDefaultRoute(),
                  location,
                  MAIN_MENU_QUERY_WHITELIST
                )}
                path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}/`}
                exact
              />

              {/*Fallback: 404*/}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </div>
        </WithSidemenu>
      </div>
    </MainContainer>
  );
};

export default DataSourcePermissions;
