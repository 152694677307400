/** Suffix of the Repository directory name saved to Jupyter */
export const DIRECTORY_EXTENSION = '.asr';

export const RECYCLE_BIN_PATH = '__recycleBin';

export const WORKBENCH_FILENAMES = {
  /** Name of the meta file that is being placed inside every repository directory */
  REPOSITORY_META: 'repository.asr',
  GITIGNORE: '.gitignore',
  AUGUR_SETTINGS: 'augur_settings.json',
  MODULE_CONFIG: '.asconfig.json',
  SWAGGER: 'openapi.yaml',
} as const;
