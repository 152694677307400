const directoryRegex = /^[a-zA-Z0-9\ \[\]\(\)\!\?äöüßÄÖÜ\-_\.]+$/;
const fileEnding = '.ipynb';

export function validateFileName(value, filesInCurrentDir) {
  // No empty names
  if (!value) {
    return { message: 'Please enter a name' };
  }
  // Specify the allowed characters
  if (!directoryRegex.test(value)) {
    return { message: 'Please remove invalid characters' };
  }
  // Files can't be created when they are .ipynb
  if (value.endsWith(fileEnding)) {
    return {
      message: 'Please consider creating a notebook.',
    };
  }
  //No duplicated names
  if (filesInCurrentDir) {
    const sameName = filesInCurrentDir.find((c) => c.name === value);
    if (sameName) {
      return {
        message: 'A file / directory with the given name already exists',
      };
    }
  }
}
