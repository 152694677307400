import { connect } from 'react-redux';
import RepositoryWarning from './RepositoryWarning';
import {
  addNotebook,
  loadRepoMeta,
  saveEditNotebook,
} from '../../../../redux/workbench/modules/notebook.module';

export function mapStateToProps(state) {
  return {
    repoMeta: state.workbench?.content?.repoMeta || {},
  };
}

export const mapDispatchToProps = {
  loadRepoMeta,
  addNotebook,
  saveEditNotebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryWarning);
