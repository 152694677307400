// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/constraint-exhaustion/v1/type.ts' --type 'ConfigConstraintExhaustionConfig'
export const schemaConfigConstraintExhaustion = {
  $ref: '#/definitions/ConfigConstraintExhaustionConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ConstraintExhaustionConfig>': {
      additionalProperties: {
        $ref: '#/definitions/ConfigEntry%3Cnever%3E',
      },
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigConstraintExhaustionConfig: {
      $ref: '#/definitions/Config%3CConstraintExhaustionConfig%3E',
    },
    'ConfigEntry<never>': {
      additionalProperties: false,
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      properties: {
        elementUuid: {
          type: 'string',
        },
        source: {
          const: 'input-element',
          type: 'string',
        },
      },
      required: ['source', 'elementUuid'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'ConstraintExhaustionV1ReportData'
export const schemaReportTypeConstraintExhaustion = {
  $ref: '#/definitions/ConstraintExhaustionV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    AugurSettingsDataOpt: {
      additionalProperties: false,
      properties: {
        channels: {
          items: {
            $ref: '#/definitions/Channel',
          },
          type: 'array',
        },
        communicationGroups: {
          items: {
            $ref: '#/definitions/CommunicationGroup',
          },
          type: 'array',
        },
        communications: {
          items: {
            $ref: '#/definitions/Communication',
          },
          type: 'array',
        },
        constraints: {
          $ref: '#/definitions/ConstraintsValueType',
        },
        contactPolicies: {
          $ref: '#/definitions/ContactPoliciesValueType',
        },
        customerTable: {
          $ref: '#/definitions/CustomerTableValueType',
        },
        propensities: {
          items: {
            $ref: '#/definitions/Propensity',
          },
          type: 'array',
        },
        targetFunction: {
          $ref: '#/definitions/TargetFunctionValueType',
        },
      },
      required: ['channels', 'propensities', 'communications'],
      type: 'object',
    },
    Channel: {
      additionalProperties: false,
      properties: {
        description: {
          description: 'Optional description of the channel',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the channel',
          type: 'string',
        },
        name: {
          description: 'Name of the channel',
          type: 'string',
        },
      },
      required: ['id', 'name'],
      type: 'object',
    },
    Communication: {
      additionalProperties: false,
      properties: {
        boostFactor: {
          description:
            'Boost Factor of the communication ="conversion rate of this communication"\n-> "If I contact someone via Channel A and ask him to buy product(=valueId via the propensityId) B the conversion rate is 0.5"',
          type: 'string',
        },
        channelId: {
          description: 'Channel ID (foreign key of a Channel)',
          type: 'string',
        },
        description: {
          description: 'Optional description of the Communication',
          type: 'string',
        },
        fixedCosts: {
          description:
            'Fixed costs for this communication\n-> "If I decide to contact someone via Channel A and ask him to buy product B this will initially cost 1000.0 (for example to design a catalogue or to train the call center). These costs are ignored if no one is contacted via this communication - and will fully be added if there is at least one person is contacted via this communication.',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the Communication',
          type: 'string',
        },
        name: {
          description: 'Name of the Communication',
          type: 'string',
        },
        propensityId: {
          description: 'Propensity ID (foreign key of a Propensity)',
          type: 'string',
        },
        variableCosts: {
          description:
            'Variable costs for this communication\n-> "Each time I contact someone via Channel A and ask him to buy product B the costs are 10.0',
          type: 'string',
        },
      },
      required: ['id', 'name', 'channelId', 'propensityId', 'boostFactor'],
      type: 'object',
    },
    CommunicationGroup: {
      additionalProperties: false,
      properties: {
        communicationIds: {
          description: 'Communication IDs (foreign key of a Communication)',
          items: {
            type: 'string',
          },
          type: 'array',
        },
        description: {
          description: 'Optional description of the Communication Group',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the Communication',
          type: 'string',
        },
        name: {
          description: 'Name of the Communication Group',
          type: 'string',
        },
      },
      required: ['id', 'name', 'communicationIds'],
      type: 'object',
    },
    Constraint: {
      anyOf: [
        {
          $ref: '#/definitions/ConstraintChannel',
        },
        {
          $ref: '#/definitions/ConstraintCommunication',
        },
        {
          $ref: '#/definitions/ConstraintCommunicationGroup',
        },
        {
          $ref: '#/definitions/ConstraintAllCommunications',
        },
      ],
    },
    ConstraintAllCommunications: {
      additionalProperties: false,
      properties: {
        constraintLevel: {
          const: 'allCommunications',
          type: 'string',
        },
        constraintType: {
          $ref: '#/definitions/ConstraintType',
        },
        description: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        operator: {
          $ref: '#/definitions/Operator',
        },
        value: {
          type: 'string',
        },
      },
      required: [
        'constraintLevel',
        'constraintType',
        'id',
        'name',
        'operator',
        'value',
      ],
      type: 'object',
    },
    ConstraintChannel: {
      additionalProperties: false,
      properties: {
        channelId: {
          type: 'string',
        },
        constraintLevel: {
          const: 'channel',
          type: 'string',
        },
        constraintType: {
          $ref: '#/definitions/ConstraintType',
        },
        description: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        operator: {
          $ref: '#/definitions/Operator',
        },
        value: {
          type: 'string',
        },
      },
      required: [
        'channelId',
        'constraintLevel',
        'constraintType',
        'id',
        'name',
        'operator',
        'value',
      ],
      type: 'object',
    },
    ConstraintCommunication: {
      additionalProperties: false,
      properties: {
        communicationId: {
          type: 'string',
        },
        constraintLevel: {
          const: 'communication',
          type: 'string',
        },
        constraintType: {
          $ref: '#/definitions/ConstraintType',
        },
        description: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        operator: {
          $ref: '#/definitions/Operator',
        },
        value: {
          type: 'string',
        },
      },
      required: [
        'communicationId',
        'constraintLevel',
        'constraintType',
        'id',
        'name',
        'operator',
        'value',
      ],
      type: 'object',
    },
    ConstraintCommunicationGroup: {
      additionalProperties: false,
      properties: {
        communicationGroupId: {
          type: 'string',
        },
        constraintLevel: {
          const: 'communicationGroup',
          type: 'string',
        },
        constraintType: {
          $ref: '#/definitions/ConstraintType',
        },
        description: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        operator: {
          $ref: '#/definitions/Operator',
        },
        value: {
          type: 'string',
        },
      },
      required: [
        'communicationGroupId',
        'constraintLevel',
        'constraintType',
        'id',
        'name',
        'operator',
        'value',
      ],
      type: 'object',
    },
    ConstraintExhaustion: {
      additionalProperties: false,
      properties: {
        constraint: {
          $ref: '#/definitions/Constraint',
        },
        exhaustion: {
          type: 'number',
        },
      },
      required: ['constraint', 'exhaustion'],
      type: 'object',
    },
    ConstraintExhaustionV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        augurSettings: {
          $ref: '#/definitions/AugurSettingsDataOpt',
        },
        constraintExhaustion: {
          items: {
            $ref: '#/definitions/ConstraintExhaustion',
          },
          type: 'array',
        },
      },
      required: ['constraintExhaustion', 'augurSettings'],
      type: 'object',
    },
    ConstraintType: {
      enum: ['quantity', 'cost', 'individualValue'],
      type: 'string',
    },
    ConstraintsValueType: {
      items: {
        $ref: '#/definitions/Constraint',
      },
      type: 'array',
    },
    ContactPoliciesValueType: {
      items: {
        $ref: '#/definitions/ContactPolicy',
      },
      type: 'array',
    },
    ContactPolicy: {
      additionalProperties: false,
      properties: {
        contactPolicyType: {
          const: 'count',
          type: 'string',
        },
        description: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        operator: {
          $ref: '#/definitions/Operator',
        },
        value: {
          type: 'string',
        },
      },
      required: ['id', 'name', 'contactPolicyType', 'operator', 'value'],
      type: 'object',
    },
    CustomerTableValueType: {
      additionalProperties: false,
      properties: {
        bucketName: {
          type: 'string',
        },
        customerId2Column: {
          type: 'string',
        },
        customerIdColumn: {
          type: 'string',
        },
        dataSourceCode: {
          type: 'string',
        },
        keyspaceName: {
          type: 'string',
        },
        tableName: {
          type: 'string',
        },
        valueColumn: {
          type: 'string',
        },
        valueIdColumn: {
          type: 'string',
        },
      },
      required: ['dataSourceCode', 'tableName'],
      type: 'object',
    },
    Operator: {
      enum: ['leq', 'geq', 'eq'],
      type: 'string',
    },
    Propensity: {
      additionalProperties: false,
      properties: {
        description: {
          description: 'Optional description of the propensity',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the propensity',
          type: 'string',
        },
        valueId: {
          description:
            'valueId of the propensity. The valueId will later be matched into the "value_id" field of the customer table. The customer table has to be uploaded by the User. To select the customer table is one step in the New Augur Wizard when creating a new Augur',
          type: 'string',
        },
      },
      required: ['id', 'valueId'],
      type: 'object',
    },
    TargetFunctionSummandGroup: {
      additionalProperties: false,
      properties: {
        description: {
          type: 'string',
        },
        factor: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        sumOf: {
          enum: ['costs', 'values'],
          type: 'string',
        },
        sumOver: {
          const: 'allCommunications',
          type: 'string',
        },
      },
      required: ['id', 'name', 'sumOver', 'sumOf', 'factor'],
      type: 'object',
    },
    TargetFunctionValueType: {
      additionalProperties: false,
      properties: {
        minimizeOrMaximize: {
          description: 'Minimization or maximization problem?',
          enum: ['minimize', 'maximize'],
          type: 'string',
        },
        summandGroups: {
          description: 'List of summand groups',
          items: {
            $ref: '#/definitions/TargetFunctionSummandGroup',
          },
          type: 'array',
        },
      },
      required: ['summandGroups', 'minimizeOrMaximize'],
      type: 'object',
    },
  },
};
