import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import AuditLog from './auditLog/AuditLog';
import CreateBackup from './maintenance/create-backup/CreateBackup.container';
import CreateTemplate from './maintenance/create-template/CreateTemplate.container';
import Maintenance from './maintenance/Maintenance';
import AppPermissions from './users/app-permissions/AppPermissions';
import DataSourcePermissions from './users/data-source-permissions/DataSourcePermissions';
import DomainPermissions from './users/domain-permissions/DomainPermissions';
import { usersRoutes } from './users/routes';
import UsersAndPermissions from './users/UsersAndPermissions';
import { isAdmin as isAdminSelector } from '../../redux/selectors/user.selector';
import { DeprecatedRootState } from '../../store/state.type';
import Resources from '../admin/resources/Resources';
import * as routes from '../index/routes';
import MainContainer from '../pages/main-container/MainContainer';
import NotFound from '../pages/not-found/NotFound.container';

const Admin: FC = () => {
  const isAdmin = useSelector<DeprecatedRootState, boolean>(isAdminSelector);
  return (
    <Switch>
      {!isAdmin && (
        <Route>
          <MainContainer fullWidth>
            <NotFound />
          </MainContainer>
        </Route>
      )}

      {/* Domain Permissions */}
      <Route
        path={`${usersRoutes.basePath}/${usersRoutes.domainPermissions.path}`}
      >
        <DomainPermissions />
      </Route>

      {/* App Permissions */}
      <Route
        path={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}`}
      >
        <AppPermissions />
      </Route>

      {/* Data Source Permissions */}
      <Route
        path={`${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}`}
      >
        <DataSourcePermissions />
      </Route>

      {/* Contains sub-routes to manage users and permissions /app/admin/users/... */}
      <Route
        path={
          routes.app.prefix + routes.app.admin.index + routes.app.admin.users
        }
      >
        <MainContainer fullWidth>
          <UsersAndPermissions />
        </MainContainer>
      </Route>

      {/* Contains sub-routes to manage the resources /app/admin/resources/... */}
      <Route
        path={
          routes.app.prefix +
          routes.app.admin.index +
          routes.app.admin.resources
        }
      >
        <MainContainer fullWidth>
          <Resources />
        </MainContainer>
      </Route>

      {/* Contains the route to manage backups /app/admin/maintenance */}
      <Route
        path={
          routes.app.prefix +
          routes.app.admin.index +
          routes.app.admin.maintenance
        }
      >
        <MainContainer fullWidth>
          <Maintenance />
        </MainContainer>
      </Route>

      {/* Create a backup /app/admin/createBackup */}
      <Route
        path={
          routes.app.prefix +
          routes.app.admin.index +
          routes.app.admin.createBackup
        }
      >
        <MainContainer fullWidth>
          {/* @ts-ignore */}
          <CreateBackup />
        </MainContainer>
      </Route>

      {/* Create a template /app/admin/createTemplate */}
      <Route
        path={
          routes.app.prefix +
          routes.app.admin.index +
          routes.app.admin.createTemplate
        }
      >
        <MainContainer fullWidth>
          <CreateTemplate />
        </MainContainer>
      </Route>

      {/* Audit Log /app/admin/auditLog */}
      <Route
        path={
          routes.app.prefix + routes.app.admin.index + routes.app.admin.auditLog
        }
      >
        <MainContainer fullWidth>
          <AuditLog />
        </MainContainer>
      </Route>

      {/*Fallback: 404*/}
      <Route>
        <MainContainer fullWidth>
          <NotFound />
        </MainContainer>
      </Route>
    </Switch>
  );
};

export default Admin;
