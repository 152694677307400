// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/pie-chart/v1/type.ts' --type 'ConfigPieChartConfig'
// !! For generation LegendEntryType had to be moved temporarily to ConfigPieChartConfig because of https://github.com/vega/ts-json-schema-generator/issues/1879 !!
export const schemaConfigPieChart = {
  $ref: '#/definitions/ConfigPieChartConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<PieChartConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        legend: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(structure-1196103202-319-363-1196103202-319-365-1196103202-308-366-1196103202-306-368-1196103202-276-369-1196103202-0-2694%5B%5D%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      type: 'object',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(structure-1196103202-319-363-1196103202-319-365-1196103202-308-366-1196103202-306-368-1196103202-276-369-1196103202-0-2694[]|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                items: {
                  additionalProperties: false,
                  properties: {
                    color: {
                      type: 'string',
                    },
                    label: {
                      type: 'string',
                    },
                  },
                  required: ['color', 'label'],
                  type: 'object',
                },
                type: 'array',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    ConfigPieChartConfig: {
      $ref: '#/definitions/Config%3CPieChartConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'PieChartV1ReportData'
export const schemaReportTypePieChart = {
  $ref: '#/definitions/PieChartV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PieChartData: {
      additionalProperties: false,
      properties: {
        data: {
          additionalProperties: {
            type: 'number',
          },
          type: 'object',
        },
      },
      required: ['data'],
      type: 'object',
    },
    PieChartV1ReportData: {
      $ref: '#/definitions/PieChartData',
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
    },
  },
};
