// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/sampling-strategy/v1/type.ts' --type 'ConfigSamplingStrategyConfig'
export const schemaConfigSamplingStrategy = {
  $ref: '#/definitions/ConfigSamplingStrategyConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<SamplingStrategyConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        otherLabel: {
          $ref: '#/definitions/ConfigEntry%3Cstring%3E',
        },
        valueLabel: {
          $ref: '#/definitions/ConfigEntry%3Cstring%3E',
        },
      },
      required: ['valueLabel', 'otherLabel'],
      type: 'object',
    },
    'ConfigEntry<string>': {
      anyOf: [
        {
          additionalProperties: false,
          properties: {
            source: {
              const: 'hard-coded',
              type: 'string',
            },
            value: {
              type: 'string',
            },
          },
          required: ['source', 'value'],
          type: 'object',
        },
        {
          additionalProperties: false,
          properties: {
            elementUuid: {
              type: 'string',
            },
            source: {
              const: 'input-element',
              type: 'string',
            },
          },
          required: ['source', 'elementUuid'],
          type: 'object',
        },
      ],
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
    },
    ConfigSamplingStrategyConfig: {
      $ref: '#/definitions/Config%3CSamplingStrategyConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/sampling-strategy/v1/type.ts' --type 'PartialSamplingStrategyAugurSettings'
export const schemaAugurSettingsDefaultSamplingStrategy = {
  $ref: '#/definitions/PartialSamplingStrategyAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialSamplingStrategyAugurSettings: {
      $ref: '#/definitions/SamplingStrategyAugurSettings',
    },
    SamplingStrategyAugurSettings: {
      type: 'number',
    },
  },
};
