import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';
import { Motion, PlainStyle, spring } from 'react-motion';

import styles from './styles.module.scss';
import vars from '../../../../../../../../scss/base/var.module.scss';
import { PerformanceDriftValueFormat } from '../type';
import { formatValue } from '../single/PerformanceDrift';

type Props = {
  item: [number, number?];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  performanceDriftValueFormat: PerformanceDriftValueFormat;
};

const Bar: FC<Props> = (props) => {
  const { xScale, yScale, item, performanceDriftValueFormat } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const x = item[0];
  const y = item[1];

  const fontSize =
    xScale.bandwidth() < 60
      ? 0
      : xScale.bandwidth() / 5 > 14
      ? 14
      : xScale.bandwidth() / 5;

  if (y === undefined) {
    return (
      <g>
        <rect
          stroke='#A0A7AF55'
          strokeWidth={1}
          fill='#A0A7AF11'
          width={xScale.bandwidth()}
          height={yScale.range()[0]}
          x={xScale(x)}
          y={yScale.range()[1]}
        ></rect>

        <text
          fill='#868d94'
          // Font size based on the bar width
          // with maximum of 14
          fontSize={yScale.range()[0] < 30 ? 0 : fontSize}
          // x position of the current bar + less then half of
          // the width of a bar, to center the text
          x={xScale(x)! + xScale.bandwidth() / 2 - 24}
          // y position is at 50%
          y={yScale.range()[0] / 2}
        >
          No Data
        </text>
      </g>
    );
  }

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const formattedY = String(
    formatValue({
      value: y,
      format: performanceDriftValueFormat,
    })
  );

  return (
    <>
      <Motion defaultStyle={{ y: 0 }} style={{ y: spring(y) }}>
        {(val: PlainStyle) => (
          <rect
            className={styles.bar}
            fill={vars.colorPrimaryHighlight}
            width={xScale.bandwidth()}
            height={Math.abs(yScale(0) - yScale(val.y))}
            x={xScale(x)}
            y={yScale(val.y)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </Motion>

      {showTooltip && (
        <>
          <rect
            rx={2}
            fill='#444'
            width={formattedY.length * 9}
            height={20}
            x={xScale(x)! - (formattedY.length * 5 - 2)}
            y={yScale(y) - 22}
          ></rect>
          <text
            x={xScale(x)! - (formattedY.length * 5 - 4)}
            y={yScale(y) - 7}
            fill='#eee'
          >
            {formattedY}
          </text>
        </>
      )}
    </>
  );
};

export default Bar;
