import { JOB_TYPE } from 'common/dist/types/job';
import { MessageDescriptor } from 'react-intl';

import { AugurMenuIcons } from './icons';

/** */
export type AugurMenuEntry = {
  /** Id of the entry */
  id: string;
  /** Name of the menu entry */
  name: string;
  /** Icon component */
  iconId: keyof typeof AugurMenuIcons;
  /** If selectedTab is undefined, this category is activated per default */
  activePerDefault?: boolean;
  /** if fixed at top, this entry must be placed as the first entry of the category
   * (or the first entries if there are several ones). It can't be moved around then */
  isFixedAtTop?: boolean;
  isDisabled?: boolean;
  error?: string;
};
export const AUGUR_CATEGORY_TYPE = {
  REPORT: 'report',
  SETTINGS: 'settings',
  MODEL_MANAGEMENT: 'modelManagement',
} as const;
export type AugurCategoryType =
  (typeof AUGUR_CATEGORY_TYPE)[keyof typeof AUGUR_CATEGORY_TYPE];
export const AUGUR_CATEGORY = {
  LEARNING: JOB_TYPE.LEARNING,
  EVALUATION: JOB_TYPE.EVALUATION,
  PREDICTION: JOB_TYPE.PREDICTION,
  MODEL_MANAGEMENT: 'modelManagement',
  SETTINGS: 'settings',
} as const;
export type AugurCategory =
  (typeof AUGUR_CATEGORY)[keyof typeof AUGUR_CATEGORY];
export const ID_GENERAL_SETTINGS = 'general' as const;
export const ID_MODEL_ARCHIVE = 'modelArchive' as const;
export const ID_AUGUR_BIOGRAPHY = 'augurBiography' as const;
export type AugurMenuCategory = {
  id: AugurCategory;
  /** Relevant to control which entries can be dropped into which category */
  type: AugurCategoryType;
  title?: MessageDescriptor;
  entries: AugurMenuEntry[];
  /** if fixed, ALL entries of this category can't be moved around and the "add entry" button is not shown */
  isFixed?: boolean;
  isDisabled?: boolean;
};
