import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';

import * as Api from '../../core/api';
import { sendNotification } from './notifications.module';
import { all } from '@redux-saga/core/effects';
import notifications from 'common/dist/messages/notifications';
import { RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX } from 'common/dist/constants/keycloak';
import { fetchComponentPermissions } from './dashboard.module';

export const loadUsersList = createAction(
  'load users list',
  (fetchPermissions, offset, limit, search) => ({
    fetchPermissions,
    offset,
    limit,
    search,
  })
);

export const loadUsersListSuccess = createAction(
  'load users list - success',
  (users) => users
);

export const loadUsersListFail = createAction(
  'load users list - fail',
  (error) => error
);

export const loadRoles = createAction('load roles list');

export const loadRolesSuccess = createAction(
  'load roles list - success',
  (roles) => roles
);

export const loadRolesFail = createAction(
  'load roles list - fail',
  (error) => error
);

export const adminAddUser = createAction(
  'admin add user',
  (data, fetchPermissions) => ({ data, fetchPermissions })
);

export const adminAddUserSuccess = createAction('admin add user - success');

export const adminAddUserFailure = createAction(
  'admin add user - fail',
  (error) => error
);

export const loadGroups = createAction(
  'load groups list',
  (fetchPermissions) => ({ fetchPermissions })
);

export const loadGroupsSuccess = createAction(
  'load groups list - success',
  (groups) => groups
);

export const loadGroupsFail = createAction(
  'load groups list - fail',
  (error) => error
);

export const loadGroupMembers = createAction(
  'load group member list',
  (groupId) => ({ groupId })
);

export const loadGroupMembersSuccess = createAction(
  'load group member list - success',
  (groupMembers) => groupMembers
);

export const loadGroupMembersFail = createAction(
  'load group member list - fail',
  (error) => error
);

export const adminAddGroup = createAction('admin add group', (data) => ({
  data,
}));

export const adminAddGroupSuccess = createAction('admin add group - success');

export const adminAddGroupFailure = createAction(
  'admin add group - fail',
  (error) => error
);

export const adminLoadUserDetails = createAction(
  'admin load user details',
  (userId) => ({ userId })
);

export const adminLoadUserDetailsSuccess = createAction(
  'admin load user details - success',
  (userId, userDetails) => ({ userId, userDetails })
);

export const adminLoadUserDetailsFail = createAction(
  'admin load user details - fail',
  (userId, error) => ({ userId, error })
);

export const adminUpdateUser = createAction(
  'admin update user',
  (userCode, data) => ({ userCode, data })
);

export const adminUpdateUserSuccess = createAction(
  'admin update user - success'
);

export const adminUpdateUserFailure = createAction(
  'admin update user - fail',
  (error) => error
);

export const addRealmRoleMapping = createAction(
  'add realm role mapping',
  (userId, roleId, roleName) => ({ userId, roleId, roleName })
);

export const addRealmRoleMappingSuccess = createAction(
  'add realm role mapping - success'
);

export const addRealmRoleMappingFail = createAction(
  'add realm role mapping - fail',
  (error) => error
);

export const removeRealmRoleMapping = createAction(
  'remove realm role mapping',
  (userId, roleId, roleName) => ({ userId, roleId, roleName })
);

export const removeRealmRoleMappingSuccess = createAction(
  'remove realm role mapping - success'
);

export const removeRealmRoleMappingFail = createAction(
  'remove realm role mapping - fail',
  (error) => error
);

export const joinGroup = createAction('join group', (userId, groupId) => ({
  userId,
  groupId,
}));

export const joinGroupSuccess = createAction('join group - success');

export const joinGroupFail = createAction(
  'join group - fail',
  (error) => error
);

export const leaveGroup = createAction('leave group', (userId, groupId) => ({
  userId,
  groupId,
}));

export const leaveGroupSuccess = createAction('leave group - success');

export const leaveGroupFail = createAction(
  'leave group - fail',
  (error) => error
);

export const addGroupPermission = createAction(
  'add group permission',
  (resourceName, type, scopeName, group, refreshAction) => ({
    resourceName,
    type,
    scopeName,
    group,
    refreshAction,
  })
);

export const addGroupPermissionSuccess = createAction(
  'add group permission - success'
);

export const addGroupPermissionFail = createAction(
  'add group permission - fail',
  (error) => error
);

export const removeGroupPermission = createAction(
  'remove group permission',
  (resourceName, scopeName, group, refreshAction) => ({
    resourceName,
    scopeName,
    group,
    refreshAction,
  })
);

export const removeGroupPermissionSuccess = createAction(
  'remove group permission - success'
);

export const removeGroupPermissionFail = createAction(
  'remove group permission - fail',
  (error) => error
);

export const addUserPermission = createAction(
  'add user permission',
  (resourceName, type, scopeName, user, refreshAction) => ({
    resourceName,
    type,
    scopeName,
    user,
    refreshAction,
  })
);

export const addUserPermissionSuccess = createAction(
  'add user permission - success'
);

export const addUserPermissionFail = createAction(
  'add user permission - fail',
  (error) => error
);

export const removeUserPermission = createAction(
  'remove user permission',
  (resourceName, scopeName, user, refreshAction) => ({
    resourceName,
    scopeName,
    user,
    refreshAction,
  })
);

export const removeUserPermissionSuccess = createAction(
  'remove user permission - success'
);

export const removeUserPermissionFail = createAction(
  'remove user permission - fail',
  (error) => error
);

export const fetchGroupPermissions = createAction(
  'fetch group permissions',
  (groupId) => ({ groupId })
);

export const fetchGroupPermissionsSuccess = createAction(
  'fetch group permissions - success',
  (groupId, permissions) => ({ groupId, permissions })
);

export const fetchGroupPermissionsFail = createAction(
  'fetch group permissions - fail',
  (groupId, error) => ({ groupId, error })
);

export const fetchUserPermissions = createAction(
  'fetch user permissions',
  (userId) => ({ userId })
);

export const fetchUserPermissionsSuccess = createAction(
  'fetch user permissions - success',
  (userId, permissions) => ({ userId, permissions })
);

export const fetchUserPermissionsFail = createAction(
  'fetch user permissions - fail',
  (userId, error) => ({ userId, error })
);
export const fetchNamesForAdminPermission = createAction(
  'fetchNamesForAdminPermission'
);

export const fetchNamesForAdminPermissionSuccess = createAction(
  'fetchNamesForAdminPermission success',
  (summary) => summary
);

export const fetchNamesForAdminPermissionFail = createAction(
  'fetchNamesForAdminPermission fail',
  (error) => error
);
export const reducer = {
  [loadUsersList]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        loading: true,
        loaded: false,
      },
    },
  }),
  [loadUsersListSuccess]: (state, users) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        error: '',
        data: users,
        loading: false,
        loaded: true,
      },
    },
  }),
  [loadUsersListFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      users: {
        ...state.admin.users,
        error,
        loading: false,
        loaded: false,
      },
    },
  }),
  [loadGroups]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      groups: {
        ...state.admin.groups,
        loading: true,
        loaded: false,
      },
    },
  }),
  [loadGroupsSuccess]: (state, groups) => ({
    ...state,
    admin: {
      ...state.admin,
      groups: {
        ...state.admin.groups,
        error: '',
        data: groups,
        loading: false,
        loaded: true,
      },
    },
  }),
  [loadGroupsFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      groups: {
        ...state.admin.groups,
        error,
        loading: false,
        loaded: false,
      },
    },
  }),
  [adminLoadUserDetails]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      userDetails: {
        ...state.admin.userDetails,
        user: {
          ...(state.admin.userDetails.user || {}),
          loading: true,
          loaded: false,
        },
      },
    },
  }),
  [adminLoadUserDetailsSuccess]: (state, { userId, userDetails }) => {
    const userListIndex = state.admin.users.data.findIndex(
      (user) => user.id === userId
    );
    return {
      ...state,
      admin: {
        ...state.admin,
        userDetails: {
          ...state.admin.userDetails,
          user: {
            ...(state.admin.userDetails.user || {}),
            error: '',
            data: userDetails,
            loading: false,
            loaded: true,
          },
        },
        // Also add the userDetails to the list of all users
        users: {
          ...state.admin.users,
          data: [
            ...state.admin.users.data.slice(0, userListIndex),
            userDetails,
            ...state.admin.users.data.slice(userListIndex + 1),
          ],
        },
      },
    };
  },
  [adminLoadUserDetailsFail]: (state, { userId, error }) => ({
    ...state,
    admin: {
      ...state.admin,
      userDetails: {
        ...state.admin.userDetails,
        user: {
          ...(state.admin.userDetails.user || {}),
          error,
          loading: false,
          loaded: false,
        },
      },
    },
  }),
  [loadRoles]: (state) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        loading: true,
        loaded: false,
      },
    },
  }),
  [loadRolesSuccess]: (state, roles) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        error: '',
        data: roles,
        loading: false,
        loaded: true,
      },
    },
  }),
  [loadRolesFail]: (state, error) => ({
    ...state,
    admin: {
      ...state.admin,
      roles: {
        ...state.admin.roles,
        error,
        loading: false,
        loaded: false,
      },
    },
  }),
  [fetchUserPermissions]: (state, { userId }) => ({
    ...state,
    admin: {
      ...state.admin,
      permissions: {
        ...state.admin.permissions,
        [userId]: {
          ...(state.admin.permissions[userId] || {}),
          loading: true,
          loaded: false,
        },
      },
    },
  }),
  [fetchUserPermissionsSuccess]: (state, { userId, permissions }) => ({
    ...state,
    admin: {
      ...state.admin,
      permissions: {
        ...state.admin.permissions,
        [userId]: {
          data: permissions
            .map((p) => [p.resource.name, p.scopes])
            .reduce(
              (acc, e) => ({ ...acc, [e[0]]: [...e[1], ...(acc[e[0]] || [])] }),
              {}
            ),
          error: undefined,
          loading: false,
          loaded: true,
        },
      },
    },
  }),
  [fetchUserPermissionsFail]: (state, { userId, error }) => ({
    ...state,
    admin: {
      ...state.admin,
      permissions: {
        ...state.admin.permissions,
        [userId]: {
          ...(state.admin.permissions[userId] || {}),
          error,
          loading: false,
          loaded: false,
        },
      },
    },
  }),
  [fetchGroupPermissions]: (state, { groupId }) => ({
    ...state,
    admin: {
      ...state.admin,
      groupPermissions: {
        ...state.admin.groupPermissions,
        [groupId]: {
          ...(state.admin.groupPermissions?.[groupId] || {}),
          loading: true,
          loaded: false,
        },
      },
    },
  }),
  [fetchGroupPermissionsSuccess]: (state, { groupId, permissions }) => ({
    ...state,
    admin: {
      ...state.admin,
      groupPermissions: {
        ...state.admin.groupPermissions,
        [groupId]: {
          data: permissions
            .map((p) => [p.resource.name, p.scopes])
            .reduce(
              (acc, e) => ({ ...acc, [e[0]]: [...e[1], ...(acc[e[0]] || [])] }),
              {}
            ),
          error: undefined,
          loading: false,
          loaded: true,
        },
      },
    },
  }),
  [fetchGroupPermissionsFail]: (state, { groupId, error }) => ({
    ...state,
    admin: {
      ...state.admin,
      groupPermissions: {
        ...state.admin.groupPermissions,
        [groupId]: {
          ...(state.admin.groupPermissions[groupId] || {}),
          error,
          loading: false,
          loaded: false,
        },
      },
    },
  }),
  [fetchNamesForAdminPermission]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      loading: true,
      loaded: false,
    },
  }),
  [fetchNamesForAdminPermissionSuccess]: (state, summary) => {
    // Extract the augur and habitat names
    const augurNames = Object.fromEntries(
      summary.flatMap((habitat) =>
        habitat.augurs.map((augur) => [augur.code, augur.name])
      )
    );
    const habitatNames = Object.fromEntries(
      summary.map((habitat) => [habitat.code, habitat.name])
    );
    return {
      ...state,
      names: {
        ...state.names,
        augurNames: {
          ...state.names.augurNames,
          ...augurNames,
        },
        habitatNames: {
          ...state.names.habitatNames,
          ...habitatNames,
        },
      },
    };
  },
  [fetchNamesForAdminPermissionFail]: (state, error) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      error,
      loading: false,
      loaded: false,
      habitats: {},
    },
  }),
};

export function* loadUsersListSaga({
  payload: { fetchPermissions, offset, limit, search },
}) {
  const { response, error } = yield call(
    Api.admin.getUsersList,
    offset,
    limit,
    search
  );
  if (response) {
    yield put(loadUsersListSuccess(response));
    if (fetchPermissions) {
      yield all(response.map((user) => put(fetchUserPermissions(user.id))));
    }
  } else {
    yield put(loadUsersListFail(error));
  }
}

export function* watchLoadUsersList() {
  yield takeEvery(loadUsersList.getType(), loadUsersListSaga);
}

export function* adminAddUserSaga({ payload: { data, fetchPermissions } }) {
  const { response, error } = yield call(Api.admin.addUser, data);
  if (response) {
    yield put(adminAddUserSuccess());
    yield put(
      sendNotification(
        'User added',
        'The user was successfully added',
        eventType
      )
    );
    //After a user is added load the userlist again
    yield put(loadUsersList(fetchPermissions, 0, undefined, undefined));
  } else {
    yield put(adminAddUserFailure(error));
    yield put(
      sendNotification(
        'Adding user failed',
        `The user could not be added: ${JSON.stringify(error)}`,
        errorType
      )
    );
  }
}

export function* watchAdminAddUser() {
  yield takeEvery(adminAddUser.getType(), adminAddUserSaga);
}

export function* loadGroupsSaga({ payload: { fetchPermissions } }) {
  const { response, error } = yield call(Api.admin.getGroups);
  if (response) {
    yield put(loadGroupsSuccess(response));
    if (fetchPermissions) {
      yield all(response.map((group) => put(fetchGroupPermissions(group.id))));
    }
  } else {
    yield put(loadGroupsFail(error));
  }
}

export function* watchLoadGroups() {
  yield takeEvery(loadGroups.getType(), loadGroupsSaga);
}

export function* loadGroupMembersSaga({ payload: { groupId } }) {
  const { response, error } = yield call(Api.admin.getGroupMembers, groupId);
  if (response) {
    yield put(loadGroupsSuccess(response));
  } else {
    yield put(loadGroupsFail(error));
  }
}

export function* watchLoadGroupMembers() {
  yield takeEvery(loadGroupMembers.getType(), loadGroupMembersSaga);
}

export function* adminAddGroupSaga({ payload: { data } }) {
  const { response, error } = yield call(Api.admin.addGroup, data);
  if (response) {
    yield put(adminAddGroupSuccess());
    yield put(
      sendNotification(
        'Group added',
        'The group was successfully added',
        eventType
      )
    );
  } else {
    yield put(adminAddGroupFailure(error));
    yield put(
      sendNotification(
        'Adding group failed',
        `The group could not be added: ${JSON.stringify(error)}`,
        errorType
      )
    );
  }
  yield put(loadGroups(false));
}

export function* watchAdminAddGroup() {
  yield takeEvery(adminAddGroup.getType(), adminAddGroupSaga);
}

export function* adminLoadUserDetailsSaga({ payload: { userId } }) {
  const { response, error } = yield call(Api.admin.getUserDetails, userId);
  if (response) {
    yield put(adminLoadUserDetailsSuccess(userId, response));
  } else {
    yield put(adminLoadUserDetailsFail(userId, error));
  }
}

export function* watchAdminLoadUserDetails() {
  yield takeEvery(adminLoadUserDetails.getType(), adminLoadUserDetailsSaga);
}

export function* loadRolesSaga() {
  const { response, error } = yield call(Api.admin.getRoles);
  if (response) {
    yield put(loadRolesSuccess(response));
  } else {
    yield put(loadRolesFail(error));
  }
}

export function* watchLoadRoles() {
  yield takeEvery(loadRoles.getType(), loadRolesSaga);
}

export function* adminUpdateUserSaga({ payload: { userCode, data } }) {
  const { response, error } = yield call(Api.admin.updateUser, userCode, data);
  if (response) {
    yield put(adminUpdateUserSuccess());
    yield put(
      sendNotification(
        'User updated',
        'The user was successfully updated',
        eventType
      )
    );
    yield put(adminLoadUserDetails(userCode));
  } else {
    yield put(adminUpdateUserFailure(error));
    yield put(
      sendNotification(
        'Updating user failed',
        `The user could not be updated: ${JSON.stringify(error)}`,
        errorType
      )
    );
  }
}

export function* watchAdminUpdateUser() {
  yield takeEvery(adminUpdateUser.getType(), adminUpdateUserSaga);
}

export function* addRealmRoleMappingSaga({
  payload: { userId, roleId, roleName },
}) {
  const { response, error } = yield call(
    Api.admin.addRealmRoleMapping,
    userId,
    roleId,
    roleName
  );
  if (response) {
    yield put(addRealmRoleMappingSuccess());
    yield put(adminLoadUserDetails(userId));
  } else {
    yield put(addRealmRoleMappingFail(error));
  }
}

export function* watchAddRealmRoleMapping() {
  yield takeEvery(addRealmRoleMapping.getType(), addRealmRoleMappingSaga);
}

export function* removeRealmRoleMappingSaga({
  payload: { userId, roleId, roleName },
}) {
  const { response, error } = yield call(
    Api.admin.removeRealmRoleMapping,
    userId,
    roleId,
    roleName
  );
  if (response) {
    yield put(removeRealmRoleMappingSuccess());
    yield put(adminLoadUserDetails(userId));
  } else {
    yield put(removeRealmRoleMappingFail(error));
  }
}

export function* watchRemoveRealmRoleMapping() {
  yield takeEvery(removeRealmRoleMapping.getType(), removeRealmRoleMappingSaga);
}

export function* joinGroupSaga({ payload: { userId, groupId } }) {
  const { response, error } = yield call(Api.admin.joinGroup, userId, groupId);
  if (response) {
    yield put(joinGroupSuccess());
    yield put(adminLoadUserDetails(userId));
  } else {
    yield put(joinGroupFail(error));
  }
}

export function* watchJoinGroup() {
  yield takeEvery(joinGroup.getType(), joinGroupSaga);
}

export function* leaveGroupSaga({ payload: { userId, groupId } }) {
  const { response, error } = yield call(Api.admin.leaveGroup, userId, groupId);
  if (response) {
    yield put(leaveGroupSuccess());
    yield put(adminLoadUserDetails(userId));
  } else {
    yield put(leaveGroupFail(error));
  }
}

export function* watchLeaveGroup() {
  yield takeEvery(leaveGroup.getType(), leaveGroupSaga);
}

export function* addGroupPermissionSaga({
  payload: { resourceName, type, scopeName, group, refreshAction },
}) {
  const { response, error } = yield call(
    Api.admin.addGroupPermission,
    resourceName,
    type,
    scopeName,
    group
  );
  if (response) {
    yield put(addGroupPermissionSuccess());
    yield put(refreshAction());
    if (response.error)
      yield put(
        sendNotification(
          notifications.msgTitlePermissionSyncErr.id,
          notifications.msgDescriptionPermissionSyncErr.id,
          errorType
        )
      );
    if (type === RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX)
      yield put(fetchComponentPermissions());
  } else {
    yield put(addGroupPermissionFail(error));
    yield put(sendNotification('Permissions could not be set', '', errorType));
  }
}

export function* watchAddGroupPermission() {
  yield takeEvery(addGroupPermission.getType(), addGroupPermissionSaga);
}

export function* removeGroupPermissionSaga({
  payload: { resourceName, scopeName, group, refreshAction },
}) {
  const { response, error } = yield call(
    Api.admin.removeGroupPermission,
    resourceName,
    scopeName,
    group
  );
  if (response) {
    yield put(removeGroupPermissionSuccess());
    yield put(refreshAction());
  } else {
    yield put(removeGroupPermissionFail(error));
  }
}

export function* watchRemoveGroupPermission() {
  yield takeEvery(removeGroupPermission.getType(), removeGroupPermissionSaga);
}

export function* addUserPermissionSaga({
  payload: { resourceName, type, scopeName, user, refreshAction },
}) {
  const { response, error } = yield call(
    Api.admin.addUserPermission,
    resourceName,
    type,
    scopeName,
    user
  );
  if (response) {
    yield put(addUserPermissionSuccess());
    yield put(refreshAction());
    if (response.error)
      yield put(
        sendNotification(
          notifications.msgTitlePermissionSyncErr.id,
          notifications.msgDescriptionPermissionSyncErr.id,
          errorType
        )
      );
    if (type === RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX)
      yield put(fetchComponentPermissions());
  } else {
    yield put(addUserPermissionFail(error));
    yield put(
      sendNotification(
        notifications.msgTitlePermissionSetErr.id,
        notifications.msgDescriptionPermissionSetErr.id,
        errorType
      )
    );
  }
}

export function* watchAddUserPermission() {
  yield takeEvery(addUserPermission.getType(), addUserPermissionSaga);
}

export function* removeUserPermissionSaga({
  payload: { resourceName, scopeName, user, refreshAction },
}) {
  const { response, error } = yield call(
    Api.admin.removeUserPermission,
    resourceName,
    scopeName,
    user
  );
  if (response) {
    yield put(removeUserPermissionSuccess());
    yield put(refreshAction());
  } else {
    yield put(removeUserPermissionFail(error));
  }
}

export function* watchRemoveUserPermission() {
  yield takeEvery(removeUserPermission.getType(), removeUserPermissionSaga);
}

export function* fetchUserPermissionsSaga({ payload: { userId } }) {
  const { response, error } = yield call(Api.admin.getUserPermissions, userId);
  if (response) {
    yield put(fetchUserPermissionsSuccess(userId, response));
  } else {
    yield put(fetchUserPermissionsFail(userId, error));
  }
}

export function* watchFetchUserPolicy() {
  yield takeEvery(fetchUserPermissions.getType(), fetchUserPermissionsSaga);
}

export function* fetchGroupPermissionsSaga({ payload: { groupId } }) {
  const { response, error } = yield call(
    Api.admin.getGroupPermissions,
    groupId
  );
  if (response) {
    yield put(fetchGroupPermissionsSuccess(groupId, response));
  } else {
    yield put(fetchGroupPermissionsFail(groupId, error));
  }
}

export function* watchFetchGroupPolicy() {
  yield takeEvery(fetchGroupPermissions.getType(), fetchGroupPermissionsSaga);
}

export function* fetchNamesForAdminPermissionSaga() {
  const { response, error } = yield call(
    Api.admin.fetchNamesForAdminPermission
  );
  if (response) {
    yield put(fetchNamesForAdminPermissionSuccess(response));
  } else {
    yield put(fetchNamesForAdminPermissionFail(error));
  }
}
export function* watchFetchNamesForAdminPermission() {
  yield takeEvery(
    fetchNamesForAdminPermission.getType(),
    fetchNamesForAdminPermissionSaga
  );
}
