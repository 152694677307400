import classNames from 'classnames';
import { BiographyEntryEvaluation } from 'common/dist/types/augurBiography';
import React, { FC } from 'react';
import { FiChevronsRight } from 'react-icons/fi';

import CommonEntryParts from './CommonEntryParts';
import TimestampAndLine from './TimestampAndLine';
import styles from '../styles.module.scss';
import { renderBiographyInfo } from '../AugurBiographyList';

type Props = {
  entry: BiographyEntryEvaluation;
  renderDate: boolean;
  selected: boolean;
  selectable: boolean;
};

const EvaluationEntry: FC<Props> = ({
  entry,
  renderDate,
  selected,
  selectable,
}) => {
  return (
    <div
      className={classNames(styles.entry, styles.entryEvaluation, {
        [styles.selected]: selected,
        [styles.selectable]: selectable,
      })}
    >
      <TimestampAndLine createdAt={entry.createdAt} renderDate={renderDate} />

      <div className={styles.entryBody}>
        <CommonEntryParts entry={entry} />
        {entry.biographyInfo && renderBiographyInfo(entry.biographyInfo)}
      </div>
      {selected && (
        <div className={styles.selectedMarker}>
          <FiChevronsRight size={16} />
        </div>
      )}
    </div>
  );
};

export default EvaluationEntry;
