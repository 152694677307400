import { connect } from 'react-redux';
import WorkbenchMainContainer from './WorkbenchMainContainer';
import { setFullscreen } from '../../../../redux/workbench/modules/notebook.module';
import {
  fetchSessions,
  mainBrowserResize,
} from '../../../../redux/workbench/modules/sessions.module';
import { fetchKernelspecs } from '../../../../redux/workbench/modules/kernelspecs.module';
import {
  checkWhetherNotebookIsRunning,
  resetEventSource,
} from '../../../../redux/workbench/modules/connection.module';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';

export function mapStateToProps(state) {
  return {
    fullscreen: state.workbench.fullscreen,
    notebookUser: notebookUser(state),
  };
}

export const mapDispatchToProps = {
  fetchSessions,
  fetchKernelspecs,
  setFullscreen,
  mainBrowserResize,
  checkWhetherNotebookIsRunning,
  resetEventSource,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore: Type actions/reducers first
)(WorkbenchMainContainer);
