import { ModuleAvatar } from 'common/dist/types/module';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FiUpload } from 'react-icons/fi';
import { MdClear } from 'react-icons/md';

import styles from './styles.module.scss';
import { MAX_SIZE } from '../../atoms/account-add-photo/AccountAddPhoto';

export const ModuleIcon: FC<Partial<ModuleAvatar>> = (props) => {
  const { imageData, imageType } = props;
  const hiddenInputRef = useRef<HTMLInputElement>();
  const { control } = useFormContext();
  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (imageData) setPreview(`data:${imageType};base64, ${imageData}`);
  }, [imageType, imageData]);

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        if (typeof fileReader.result === 'string') {
          resolve(fileReader.result.replace('data:', '').replace(/^.+,/, ''));
        }
      };
      fileReader.readAsDataURL(file);
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Controller
      name={'moduleAvatar'}
      control={control}
      render={({ field }) => {
        // we have to extract value here
        const { ref, value, onChange, ...rest } = field;
        async function changeImage(event) {
          const file: File = event.target.files[0];
          //Use same max_size as the useravat does
          if (file.size < MAX_SIZE) {
            const urlImage = URL.createObjectURL(file);
            setPreview(urlImage);
            //extract data for the formfield
            const fileType = file.type;
            const base64 = await convertBase64(file);
            onChange({ imageType: fileType, imageData: base64 });
          } else {
            alert('The uploaded file is too large. The maximum size is 1 MB');
          }
        }

        function deleteImage() {
          onChange(null);
          setPreview('');
        }

        return (
          <>
            <input
              type='file'
              name='moduleAvatar'
              accept='image/png, image/gif, image/jpeg'
              {...rest}
              ref={(e) => {
                ref(e);
                hiddenInputRef.current = e;
              }}
              onChange={changeImage}
              style={{ display: 'none' }}
            />
            <div className={styles.container}>
              {
                /* We can't use the image tag with src="", since Chrome displays a broken image icon (and Firefox does not) */
                _.isEmpty(preview) ? (
                  <>
                    <span className={styles.image}>Upload</span>
                    <div
                      className={styles.middle}
                      onClick={() => hiddenInputRef?.current?.click()}
                    >
                      <FiUpload size={24} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src={preview} className={styles.image} />
                    <div className={styles.middle} onClick={deleteImage}>
                      <MdClear size={24} />
                    </div>
                  </>
                )
              }
            </div>
          </>
        );
      }}
    />
  );
};
