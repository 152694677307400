import { PipelineTuningResultsType } from "common/dist/types/module.nbc"

export const example1: PipelineTuningResultsType = {
  "kpi": "accuracy",
  "bestModel": {
    "pipelineId": "example1",
    "pathId": "example1_3",
    "parameters": [],
    "score": 0.7637557835739202
  },
  "singleResults": [
    {
      "pipelineId": "example1",
      "pathId": "example1_0",
      "parameters": [
        {
          "name": "clf__decision_tree_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_split",
          "value": 2
        }
      ],
      "score": 0.6823318202384949
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_0",
      "parameters": [
        {
          "name": "clf__decision_tree_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_split",
          "value": 2
        }
      ],
      "score": 0.7006852714083459
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.6949374571249252
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.6991468772349773
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7002875366718724
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.700291185614409
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7010589231240786
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7018164435946461
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7182695254915126
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7190328842701386
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7240100418898604
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7243822340285785
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7266803380380366
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7274407776626334
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7278304847255266
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7282099747493177
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7289718739509291
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7301227504269263
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_1",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7301242100039408
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.6976230788317546
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7083181294060982
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 5
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7152036839723848
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 3
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7220863193846424
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 2
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.729737422095077
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 5
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.730880270897494
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_2",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 2
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7335571351422359
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_4",
      "parameters": [
        {
          "name": "clf__decision_tree_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_split",
          "value": 2
        }
      ],
      "score": 0.677361960503846
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_4",
      "parameters": [
        {
          "name": "clf__decision_tree_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__decision_tree_classifier__min_samples_split",
          "value": 2
        }
      ],
      "score": 0.7025965875089399
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.6995300162013047
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.6999109658021105
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7002919154029164
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.700672865003722
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7006750543692439
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 12
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7029680498591508
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7217046399953293
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7240034737932948
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7243836936055932
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7251492417497409
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7255294615620393
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.72630230759126
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7266781486725147
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "auto"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7270620174273495
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7301198312728971
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "sqrt"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7301220206384189
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_5",
      "parameters": [
        {
          "name": "clf__random_forest_classifier__max_depth",
          "value": 4
        },
        {
          "name": "clf__random_forest_classifier__max_features",
          "value": "log2"
        },
        {
          "name": "clf__random_forest_classifier__min_samples_leaf",
          "value": 1
        },
        {
          "name": "clf__random_forest_classifier__min_samples_split",
          "value": 2
        },
        {
          "name": "clf__random_forest_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.730122020638419
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7052639645030869
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 8
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.70947046545911
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 5
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7117663801030462
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 5
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.720558872038883
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 3
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 100
        }
      ],
      "score": 0.7259126005283669
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 2
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7285865456190795
    },
    {
      "pipelineId": "example1",
      "pathId": "example1_6",
      "parameters": [
        {
          "name": "clf__gradient_boosting_classifier__learning_rate",
          "value": 0.1
        },
        {
          "name": "clf__gradient_boosting_classifier__max_depth",
          "value": 3
        },
        {
          "name": "clf__gradient_boosting_classifier__n_estimators",
          "value": 200
        }
      ],
      "score": 0.7289740633164509
    }
  ],
  "pipelines": [
    {
      "id": "example1",
      "displayName": "Example 1",
      "schema": {
        "id": "example1",
        "displayName": "Example 1",
        "optimizationMethod": {
          "id": "grid_search_cross_validation",
          "nSplits": 1
        },
        "edges": [
          {
            "sourceID": "column_rejector",
            "targetID": "type_discarder"
          },
          {
            "sourceID": "type_discarder",
            "targetID": "too_many_values"
          },
          {
            "sourceID": "too_many_values",
            "targetID": "imputer_group"
          },
          {
            "sourceID": "imputer_group",
            "targetID": "extra_column_rejector"
          },
          {
            "sourceID": "extra_column_rejector",
            "targetID": "low_information_discarder"
          },
          {
            "sourceID": "low_information_discarder",
            "targetID": "one_hot_encoder"
          },
          {
            "sourceID": "one_hot_encoder",
            "targetID": "classifier_group"
          },
          {
            "sourceID": "low_information_discarder",
            "targetID": "catboost_classifier"
          }
        ],
        "nodes": [
          {
            "id": "column_rejector",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "Column Rejector",
            "description": {
              "id": "no-id",
              "defaultMessage": "Drops the Rejected Features from the data. The Rejected Features can be defined in the Influencers tab of the Augur Details."
            },
            "isTuneable": false,
            "isEditable": false
          },
          {
            "id": "type_discarder",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "Type Discarder",
            "description": {
              "id": "no-id",
              "defaultMessage": "Drops columns containing unsupported data types (timestamps and dates) from the data"
            },
            "isTuneable": false,
            "isEditable": false
          },
          {
            "id": "too_many_values",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "Too Many Values Discarder",
            "description": {
              "id": "no-id",
              "defaultMessage": "Drops categorical columns with too many values from the data"
            },
            "isTuneable": false,
            "isEditable": true,
            "staticParameters": [
              {
                "id": "values_threshold",
                "displayName": "Distinct Values Threshold",
                "value": 10
              }
            ]
          },
          {
            "id": "imputer_group",
            "type": "group",
            "nodes": [
              {
                "id": "mean_inputer",
                "type": "node",
                "nodeType": "transformer",
                "displayName": "Mean Imputer",
                "description": {
                  "id": "no-id",
                  "defaultMessage": "Fills missing values in numeric columns with their mean"
                },
                "isTuneable": false,
                "isEditable": true,
                "staticParameters": [
                  {
                    "id": "na_threshold",
                    "displayName": "NA Threshold",
                    "value": 0.1
                  }
                ]
              },
              {
                "id": "median_imputer",
                "type": "node",
                "nodeType": "transformer",
                "displayName": "Median Imputer",
                "description": {
                  "id": "no-id",
                  "defaultMessage": "Fills missing values in numeric columns with their median"
                },
                "isTuneable": false,
                "isEditable": true,
                "staticParameters": [
                  {
                    "id": "na_threshold",
                    "displayName": "NA Threshold",
                    "value": 10
                  }
                ]
              }
            ]
          },
          {
            "id": "extra_column_rejector",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "Extra Column Rejector",
            "description": {
              "id": "no-id",
              "defaultMessage": "Drops columns from the Data that were not present during training."
            },
            "isTuneable": false,
            "isEditable": false
          },
          {
            "id": "low_information_discarder",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "Low Information Discarder",
            "description": {
              "id": "no-id",
              "defaultMessage": "Rejects Columns with too low information."
            },
            "isTuneable": false,
            "isEditable": true,
            "staticParameters": [
              {
                "id": "k_best",
                "displayName": "K Best Parameters",
                "value": 40
              }
            ]
          },
          {
            "id": "one_hot_encoder",
            "type": "node",
            "nodeType": "transformer",
            "displayName": "One Hot Encoder",
            "description": {
              "id": "no-id",
              "defaultMessage": "Replaces categorical columns with a one hot encoding."
            },
            "isTuneable": false,
            "isEditable": false
          },
          {
            "id": "classifier_group",
            "type": "group",
            "nodes": [
              {
                "id": "decision_tree_classifier",
                "type": "node",
                "nodeType": "classifier",
                "displayName": "Decision Tree Classifier",
                "description": {
                  "id": "no-id",
                  "defaultMessage": "Decision Tree Classifier"
                },
                "isTuneable": true,
                "tuningParameters": [
                  {
                    "id": "max_depth",
                    "displayName": "Max Depth",
                    "valueCandidates": [4, 8, 12]
                  },
                  {
                    "id": "min_samples_split",
                    "displayName": "Min Samples Split",
                    "valueCandidates": [2]
                  },
                  {
                    "id": "min_samples_leaf",
                    "displayName": "Min Samples Leaf",
                    "valueCandidates": [1]
                  }
                ],
                "isEditable": false
              },
              {
                "id": "random_forest_classifier",
                "type": "node",
                "nodeType": "classifier",
                "displayName": "Random Forest Classifier",
                "description": {
                  "id": "no-id",
                  "defaultMessage": "Random Forest Classifier"
                },
                "isTuneable": true,
                "tuningParameters": [
                  {
                    "id": "n_estimators",
                    "displayName": "Amount of Trees",
                    "valueCandidates": [100, 200]
                  },
                  {
                    "id": "max_depth",
                    "displayName": "Max Depth",
                    "valueCandidates": [4, 8, 12]
                  },
                  {
                    "id": "min_samples_split",
                    "displayName": "Min Samples Split",
                    "valueCandidates": [2]
                  },
                  {
                    "id": "min_samples_leaf",
                    "displayName": "Min Samples Leaf",
                    "valueCandidates": [1]
                  },
                  {
                    "id": "max_features",
                    "displayName": "Max Features",
                    "valueCandidates": ["sqrt", "log2", "auto"]
                  }
                ],
                "isEditable": false
              },
              {
                "id": "gradient_boosting_classifier",
                "type": "node",
                "nodeType": "classifier",
                "displayName": "Gradient Boosting Classifier",
                "description": {
                  "id": "no-id",
                  "defaultMessage": "Gradient Boosting Classifier"
                },
                "isTuneable": true,
                "tuningParameters": [
                  {
                    "id": "n_estimators",
                    "displayName": "Amount of Boosting Stages",
                    "valueCandidates": [100, 200]
                  },
                  {
                    "id": "learning_rate",
                    "displayName": "Learning rate",
                    "valueCandidates": [0.1]
                  },
                  {
                    "id": "max_depth",
                    "displayName": "Max Depth",
                    "valueCandidates": [2, 3, 5, 8]
                  }
                ],
                "isEditable": false
              }
            ]
          },
          {
            "id": "catboost_classifier",
            "type": "node",
            "nodeType": "classifier",
            "displayName": "Catboost Classifier",
            "description": {
              "id": "no-id",
              "defaultMessage": "Catboost Classifier"
            },
            "isTuneable": false,
            "isEditable": false
          }
        ]
      },
      "paths": [
        {
          "pathId": "example1_0",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "mean_inputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "decision_tree_classifier"
          ]
        },
        {
          "pathId": "example1_1",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "mean_inputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "random_forest_classifier"
          ]
        },
        {
          "pathId": "example1_2",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "mean_inputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "gradient_boosting_classifier"
          ]
        },
        {
          "pathId": "example1_3",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "mean_inputer",
            "extra_column_rejector",
            "low_information_discarder",
            "catboost_classifier"
          ]
        },
        {
          "pathId": "example1_4",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "median_imputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "decision_tree_classifier"
          ]
        },
        {
          "pathId": "example1_5",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "median_imputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "random_forest_classifier"
          ]
        },
        {
          "pathId": "example1_6",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "median_imputer",
            "extra_column_rejector",
            "low_information_discarder",
            "one_hot_encoder",
            "gradient_boosting_classifier"
          ]
        },
        {
          "pathId": "example1_7",
          "pipelineId": "example1",
          "path": [
            "column_rejector",
            "type_discarder",
            "too_many_values",
            "median_imputer",
            "extra_column_rejector",
            "low_information_discarder",
            "catboost_classifier"
          ]
        }
      ]
    }
  ]
}
