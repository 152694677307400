import { RepoType } from 'common/dist/types/repository';
import React from 'react';
import { IconType } from 'react-icons';
import { FiBook, FiGitBranch, FiMonitor } from 'react-icons/fi';

export const IconCodeCapsule: IconType = ({ size }) => (
  <span className={'icon-code-capsule'} style={{ fontSize: size }} />
);

export const IconApp: IconType = FiMonitor;
export const IconModule: IconType = FiBook;
export const IconPlainRepository: IconType = FiGitBranch;

export const IconAugur: IconType = ({ size }) => (
  <span className={'icon-augur'} style={{ fontSize: size }} />
);

export const IconDatapool: IconType = ({ size }) => (
  <span className={'icon-datapool'} style={{ fontSize: size }} />
);

export const IconHabitat: IconType = ({ size }) => (
  <span className={'icon-home'} style={{ fontSize: size }} />
);

export const iconFromRepoType = (repoType: RepoType): IconType => {
  switch (repoType) {
    case 'code-capsule':
      return IconCodeCapsule;
    case 'app':
      return IconApp;
    case 'module':
      return IconModule;
    case 'plain':
      return IconPlainRepository;
  }
};
