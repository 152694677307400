import errorMessages from 'common/dist/messages/error';
import React, { FC, useEffect } from 'react';

import JobGroupHistory from './JobGroupHistory.container';
import { connectedProps } from './JobHistory.container';
import Busy from '../../atoms/busy/Busy';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import Paging, { usePagingParameters } from '../../molecules/paging/Paging';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import { ORCHESTRATION_ORIGINS } from '../links';

export interface Props {
  fetchHistory: (offset?: number, limit?: number, search?: string) => void;
}

const JobHistory: FC<Props & connectedProps> = ({
  fetchHistory,
  jobGroupCodes,
  loading,
  error,
  pageSize,
  totalCount,
}) => {
  const renderInnerComponent = () => {
    if (loading) return <Busy isBusy positionAbsolute />;
    if (jobGroupCodes && jobGroupCodes.length === 0)
      return (
        <IndicatorEmpty
          classNameImage={'orchestration-history-empty-pic'}
          headlineId={'dummy'}
          headlineDefault={'There are no Jobs in the Job History yet'}
          descriptionId={'dummy'}
          descriptionDefault={
            'Once a Job has finished, it will be archived here'
          }
        />
      );
    if (error) return <span>Error: {JSON.stringify(error)}</span>;
    return (
      <>
        <div className={'JobHistory--Header'}>
          <strong>Job Group</strong>
          <strong>Name</strong>
          <strong>Created</strong>
          <strong>By</strong>
          <strong>Finished</strong>
          <strong>Actions</strong>
        </div>
        {jobGroupCodes.map((code) => (
          <JobGroupHistory
            key={code}
            jobGroupCode={code}
            detailsOrigin={ORCHESTRATION_ORIGINS.history}
          />
        ))}
      </>
    );
  };

  const { offset, search } = usePagingParameters();
  useEffect(() => {
    fetchHistory(offset, pageSize, search);
  }, [fetchHistory, offset, search, pageSize]);

  return (
    <ErrorBoundary
      errorHeadline={errorMessages.headline}
      errorDescription={errorMessages.description}
    >
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content not-scrollable'}>
          <Paging
            itemsPerPage={pageSize}
            currentItems={jobGroupCodes.length}
            totalItems={totalCount}
            showFirstLast
            searchEnabled
            headerLabel={'Job Groups'}
          >
            {renderInnerComponent()}
          </Paging>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default JobHistory;
