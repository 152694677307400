// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/target-hierarchy/v1/type.ts' --type 'ConfigTargetHierarchyConfig'
export const schemaConfigTargetHierarchy = {
    "$ref": "#/definitions/ConfigTargetHierarchyConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<TargetHierarchyConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "properties": {
                "taxonomy": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-498744792-875-958-498744792-0-2222%5B%5D%3E"
                }
            },
            "required": [
                "taxonomy"
            ],
            "type": "object"
        },
        "ConfigEntry<def-alias-498744792-875-958-498744792-0-2222[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/Taxonomy"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigTargetHierarchyConfig": {
            "$ref": "#/definitions/Config%3CTargetHierarchyConfig%3E"
        },
        "Taxonomy": {
            "additionalProperties": false,
            "properties": {
                "id": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "sub": {
                    "items": {
                        "$ref": "#/definitions/Taxonomy"
                    },
                    "type": "array"
                }
            },
            "required": [
                "id",
                "name",
                "sub"
            ],
            "type": "object"
        }
    }
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/target-hierarchy/v1/type.ts' --type 'PartialTargetHierarchyAugurSettings'
export const schemaAugurSettingsDefaultTargetHierarchy = {
    "$ref": "#/definitions/PartialTargetHierarchyAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "PartialTargetHierarchyAugurSettings": {
            "items": {
                "type": "string"
            },
            "type": "array"
        }
    }
};