import { DSType } from 'common/dist/types/dataManagement/dataSource';
import messages from 'common/src/messages/cassandra';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import {
  CassandraKeyspaceSelectAugurSettings,
  CassandraKeyspaceSelectConfig,
} from './type';
import {
  useDataSources,
  useKeyspacesWithPermission,
} from '../../../../../../core/api/data';
import { extractErrorMessage } from '../../../../../../utils';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import InputError from '../../../../../atoms/input-error/InputError';
import { HabitatRouteParams } from '../../../../../index/routes';
import ErrorPage from '../../../../../pages/error-boundary/ErrorPage';
import { OptionComponent } from '../../common/DSOptionComponent';
import { OptionType } from '../../common/type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CassandraKeyspaceSelectAugurSettings,
  CassandraKeyspaceSelectConfig
>;

const CassandraKeyspaceSelect: FC<Props> = (props) => {
  const {
    onChange,
    isTouched,
    error,
    value,
    disabled,
    readOnly,
    portalTarget,
  } = props;
  const { habitatCode } = useParams<HabitatRouteParams>();

  const dataSources = useDataSources();

  const dataSourceOptions: OptionType[] = (
    dataSources?.data?.filter((value) => value.ds_type === DSType.Cassandra) ||
    []
  ).map((ds) => ({
    ...ds,
    label: ds.name,
    value: ds.code,
  }));

  const {
    data: keyspacesData,
    isError: isKeyspacesError,
    error: keyspacesError,
  } = useKeyspacesWithPermission(
    value?.dataSourceCode ?? '',
    habitatCode ?? undefined
  );

  const renderKeyspacesDropdown = () => {
    if (isKeyspacesError) {
      const errorMessage = extractErrorMessage(keyspacesError);

      return (
        <ErrorPage
          errorHeadline={{
            id: 'no-id',
            defaultMessage: 'Error while fetching keyspaces',
          }}
          errorDescription={{
            defaultMessage: errorMessage,
            id: 'no-id',
          }}
        />
      );
    } else {
      const keyspaceOptions: OptionType[] = (keyspacesData || []).map((k) => ({
        label: k.name,
        value: k.name,
      }));
      return (
        <div>
          <DropdownSelectInput
            onChange={(e: OptionType) => {
              onChange({ ...value, keyspaceName: e.value });
            }}
            value={
              keyspaceOptions.find(
                (option) => option.value === value?.keyspaceName
              ) || ''
            }
            label={{ id: 'no-id', defaultMessage: 'Cassandra Keyspace' }}
            placeholder={{
              id: 'no-id',
              defaultMessage: 'No Cassandra Keyspace selected',
            }}
            options={keyspaceOptions}
            menuPortalTarget={portalTarget}
            disabled={disabled}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.TextInputLineError}>
          {error && <InputError touched={isTouched} error={error} />}
        </div>
        <DropdownSelectInput
          onChange={(e: OptionType) => {
            onChange({ ...value, dataSourceCode: e.value });
          }}
          value={
            dataSourceOptions.find(
              (option) => option.value === value?.dataSourceCode
            ) || ''
          }
          label={{ id: 'no-id', defaultMessage: 'Cassandra Data Source' }}
          placeholder={{
            id: 'no-id',
            defaultMessage: 'No Cassandra Data Source selected',
          }}
          options={dataSourceOptions}
          customComponents={{ Option: OptionComponent }}
          menuPortalTarget={portalTarget}
          disabled={disabled}
        />
      </div>
      {(value?.dataSourceCode || readOnly) && renderKeyspacesDropdown()}{' '}
      {habitatCode ? (
        <FormattedMessage {...messages.msgPermissionHabitatUser}>
          {(text) => <p className={styles.label}>{text}</p>}
        </FormattedMessage>
      ) : (
        <FormattedMessage {...messages.msgPermissionUser}>
          {(text) => <p className={styles.label}>{text}</p>}
        </FormattedMessage>
      )}
    </div>
  );
};
export default CassandraKeyspaceSelect;
