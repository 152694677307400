import { postApiRequest } from '../../../core/api/_tools';

export const validateModuleName = async (name: string) => {
  const result = await postApiRequest('/api/module/exists', { name: name });
  if (result.response) return 'A module with the given name already exists!';
  return true;
};
export const validateSlug = async (slug: string) => {
  const result = await postApiRequest('/api/module/exists', {
    repoName: slug,
  });
  if (result.response)
    return 'A repository with the given name already exists!';
  return true;
};
