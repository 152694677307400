import { Branch } from 'common/dist/types/repository';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';

type Props = {
  activeBranch?: Branch;
};

const LatestCommit: FC<Props> = ({ activeBranch }) => {
  if (!activeBranch?.commit) {
    return (
      <div className={styles.latestCommit}>
        <div className={styles.commitLineMessage}>
          <span className={styles.commitMessage}>
            <i>There is no commit for this repository yet</i>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.latestCommit}>
      <div className={styles.commitLineMessage}>
        <span className={styles.commitMessage}>
          {activeBranch?.commit?.message}
        </span>
      </div>
      <div className={styles.commitLineCommitter}>
        <span className={styles.openingText}>Latest commit by </span>
        <span className={styles.committerName}>
          {activeBranch?.commit?.committer?.name}
        </span>
        <span className={styles.fillerText}>, authored at </span>
        <span className={styles.commitDate}>
          <FormattedDateTime date={new Date(activeBranch?.commit?.timestamp)} />
        </span>
      </div>
    </div>
  );
};

export default LatestCommit;
