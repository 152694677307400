import classNames from 'classnames';
import React, { FC } from 'react';

import MenuEntries from './MenuEntries';
import styles from './styles.module.scss';
import utils from '../../../../scss/base/utils.module.scss';

type Props = {
  isMenuCollapsed: boolean;
  setMenuCollapsed: (isMenuCollapsed: boolean) => void;
};

// List of query parameter keys that are supposed to preserved when clicking on a menu entry
export const MAIN_MENU_QUERY_WHITELIST = [
  'search', // Search in the admin permission pages
  'path', // Selected path in the Workbench
];

const MainMenu: FC<Props> = ({ isMenuCollapsed, setMenuCollapsed }) => {
  return (
    <div
      className={classNames(styles.mainMenu, {
        [styles.menuCollapsed]: isMenuCollapsed,
      })}
    >
      <div className={classNames(styles.entriesContainer, utils.hideScrollbar)}>
        <MenuEntries />
      </div>
    </div>
  );
};

export default MainMenu;
