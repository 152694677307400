// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-contact-policies/v1/type.ts' --type 'ConfigCampaignOptimizationContactPoliciesConfig'
export const schemaConfigCampaignOptimizationContactPolicies = {
  "$ref": "#/definitions/ConfigCampaignOptimizationContactPoliciesConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "Config<CampaignOptimizationContactPoliciesConfig>": {
      "additionalProperties": false,
      "description": "Describes the non-resolved config of a layout element.",
      "type": "object"
    },
    "ConfigCampaignOptimizationContactPoliciesConfig": {
      "$ref": "#/definitions/Config%3CCampaignOptimizationContactPoliciesConfig%3E"
    }
  }
}

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-contact-policies/v1/type.ts' --type 'PartialCampaignOptimizationContactPoliciesAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationContactPolicies = {
  "$ref": "#/definitions/PartialCampaignOptimizationContactPoliciesAugurSettings",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "ContactPolicy": {
      "additionalProperties": false,
      "properties": {
        "contactPolicyType": {
          "const": "count",
          "type": "string"
        },
        "description": {
          "type": "string"
        },
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "operator": {
          "$ref": "#/definitions/Operator"
        },
        "value": {
          "type": "string"
        }
      },
      "required": [
        "id",
        "name",
        "contactPolicyType",
        "operator",
        "value"
      ],
      "type": "object"
    },
    "Operator": {
      "enum": [
        "leq",
        "geq",
        "eq"
      ],
      "type": "string"
    },
    "PartialCampaignOptimizationContactPoliciesAugurSettings": {
      "items": {
        "$ref": "#/definitions/ContactPolicy"
      },
      "type": "array"
    }
  }
}