export const textInputLineImg = `
iVBORw0KGgoAAAANSUhEUgAAAeoAAABWCAIAAACPcELXAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAN
MUlEQVR4nO3daXMbx5kH8KfnAIYgbh4iKVIUIUsidR8mJZlKxXa0FW0qVVtbqUrlzdZWJf4aW05i
5xskVfvCm0+xFcuJJK+ukJIs6rJoUaIEihIJXrivGcxgel+0M0Z4WBDNA2P/f69mGj09TbDqj8GD
BoZxzgkAANxG2u4JAADAeiC+AQBcCfENAOBKiG8AAFdCfAMAuBLiGwDAlRDfAACuhPgGAHAlxDcA
gCshvgEAXAnxDQDgSpsS35ev3yyV9To7f3r5Wv0j/+XS1fo7J+YXL10bvX5zbK0O6Wxu9M79+gd0
jD0YT8wvruPAN/W/f7uysvHW2MO5haUtODsANDJluyewWTjR2IPxcz9+x+tRa9sXk+mp6ZnB44e2
a2KrevR40u/39XZ31dP5+OEBRZE3e0oA0OC+t/FtGIbP17Qsu78fVPV7+18DgPptXRBwogfjE0vJ
DGOkqurg0YOa5hUPTU5NT79KGJVKNBwaPHZIkiQiyuYL9778qlq1iejowf0tkfBaI7+anfvq6XNx
1NGD/a3R8FIqc//RRDaX+/zGrVhvt3NV++TZ1NTL2bKuf37j1pm3j4rGr54+n1tYqlTM7q6Og/v3
fD1mYn5iMs4Y83jUt498M9Vl0tnc0+cvAv7mZSNMTMaJaDGZ1g3Dsqonjgy0t7YQ0ZWR24PHDvua
NHH4p5ev/+v7Z2/dfbiwmJQVOTG/ePrk0dc+k7fvfbm7Z2dbS2RiMq4oSiqTzeULllU9cmBf5442
IjIt6879R8VSmYj6ertju7pfOyYAuM7WxffEZFyR5J/86BQRLaXSYw+/emfwGBGVywZxev/sKSJ6
MD4x8WxqYG/MNK3bdx8OD51o0ry6Ubk68sV7Z4dUZZXZLiZTjyfj774zpKpKWdevjtx5d3iwNRoe
Hjw2cuf+e8NDtZ337dkdCYec4klZNxLzi91dHQN7Y5zzz2/c6unaEQz4l1LpqemZ94aHJElaSmVu
3n3w4zODa/1dM3MLg8cOLRuBiKZnEu8OD6mKUiiWrox8cf69YVleveIxdPzwGxVPaj15NnX29MlA
s88wKpeujYr4Hrl9b2DfnraWiG3zv9++G/Q3t0YjbzoyADS4rVt5sru7q39fTGy3RiO5QkFse72e
t/p2ie0D+9+ankkQ0dTLmVhvT5PmJSLN6+nZ2bHWh3WPJ6eOHx4Q9YQmTevf2zcZn65/VtFwqLtz
BxExxjp3tKWzOSJ6/DR+/PCAuJxvjYY5J6NSWXOEUHDlCEQU6+0Rrzf+Zl93547ZuU35qHP3rp2B
Zh8Reb0en6+prBvZXMHjUdtaIkQkSWxgX+zFy9nNODUAbK+tu/rWNO/0TOLV7DwnTkSmaYl2SWLf
zEaWxc1/0tlcNpd/8WqWiBhjpmnt6etZddhcvhANh5zdlmh4JrFQ/6y8Xo+zLcuyZVWJKJsv3Lr7
UDQyxnTDMIyK1+OpfwRxoNMeCvoLpVL9s6pf7awUWbaq1Uwut5TKfH7jlpiDbdsBf/NmnBoAttfW
xfeT5y8KxdKpE0dkWSKiTy9fX7Ub5zYRcU7HDg2IS8hvJ0v/9AbCtrlt2999tu8OD7HX96rXVt6R
jnPe17PzYP9bW3ZGANgWW1c8eTmTODywV2Q359+EbG2yWVZVlCxCQf9iMlXPsAF/cyabd3ZT6Uwo
GPiOUw00+5KpzHccpFY2V/D7fESkKIplWU77N7G+ca8VoUBgob6nDgBcbevi29fUtLCYJCKb83tf
Pjb/kWK6rk+9nBHb40+e9e7sIqK+XTvjL145deRHE5O6Yaw67MC+2NjDcZGJpbL++Gl8z+7VyyyC
R1XWGsrRvzc29mC8UjGJyKpW7z+aqP/PdDx/8cqqVomoUCrNzi90dbQRUTQUejkzJzo8eTZV/Uel
RVVV3VizvP5GIuEg5zw+/fVTOjO3sDXfMAKALbZZxZPrN8fEhbbw/tlTxw7tH73zYPzJM0mS+9/a
nc0XqtWqLMv+5majYl6+ftOoVFoi4UP9e4nI6/GcPXVy7OG4ZVWJeN+ubs27+tK9aDi0N9Z76fpN
WZIYk94+dtBZlreqUDBgc/vi1ZHBY4fX6tPeGj2wL/Z/f78tSYwxduTAvnU8A+1t0dEv7huVimlZ
bx89JJad7I31jt65/7crI5LEero6PN6vl6V3d+64OvLFTGJerMBxlMv6pWujzm44GDh59OBrT/2j
UyfvPHj09PkLxigaCR8/1L+O+QNAg2NbWZb94ZiYjKuqGuvFgmsA2Cz4ySoAAFdCfAMAuBKKJwAA
roSrbwAAV0J8AwC4EuIbAMCVEN8AAK6E+AYAcCXENwCAK23Gl+b5aOLPE6mL4rcDAQCAMWl/9Nzp
zl9v4A/Ubfy679HE/1jVypmuD2Tpe3ifSQCAdaja5sjsJ4rsOd35m40ac+OLJxOpi8huAIBasqSe
6fpgInVxA8fc+Pjm3EZ2AwAsI0vqxpaU8dElAIArIb4BAFwJ8Q0A4EqIbwAAV0J8AwC4EuIbAMCV
EN8AAK6E+AYAcCXENwCAKyG+AQBcCfENALBOpl1O6y+26+yIbwCA9ajYpc/iH//l+YeLpafbMgHE
NwDAG6vYxb/GP14qT5p2+a9Tf8gas1s/h824XcP6JZNJy7IYY4wxIpIkKRQKKYpCRNlsVlGU5ubm
jTrX3NxcR0fHGx2SSCQ6OztXtieTyWAwqKr4nUWAH4RKtXgh/lFKj4vdvtA7Ie8qybDZGiu+iSgc
Dnu9XrFdqVSSyWRbW5sk4V0CADQEo5r/LP5xSp8Su/uj/3Km6zcbeA+d+jV0LHo8Hk3TyuXydk8E
AICIyKjmL8R/72R3f/SnZ7o+2Jbspga8+l5GVVXLspY1FgqFcrnMGLNtOxAINDU1iXbLsjKZDOec
c65pWiAQEEWYYrFYKpWIiDEWCoWcKodpmtlslnNu23YkEvF4PKK9WCwWi0XGGOe8dnyHbduZTMY0
TUmSnPcKgmEYuVxObPv9fnFsKpXSNK1YLFar1fb2dryZAGhwnPiNmf/e6T/SFxp2GnUreyH+UcZ4
KXYPtPxsqPM/t2mCRI0f36Zpitq3o1QqVSqV1tZWEa+Li4sej0eWZc55MpmMRqOqqnLOc7lcPp8P
BoO1/S3LSiaT7e3tItYLhUJLS4toX1paEqXwUqlULpfb2trEy0MymVyZ0blcTlXVaDRKRLquixMR
kWVZuVyupaVFkiTbtpeWlhRFEa8W4lyyLG/ZUwcA68PJvvbyj8+zN56lr3DisdBZIipb2Qvx32WN
GdHnYOvPBzv+Y1un2djFE13Xy+Xysotfr9cbDodF/jLGPB5PpVIhonK5rGmayErGWDAYrFarRJTP
553+iqJommYYhhgqGAw67SKsiSiXy0UiEeez03A4nM/nayfAOS+Xy36/X+xqmuaEu8hxcXEtSVIg
ECgWi+Ihv9+P7AZwhXxl/lX+HhFx4tde/vFZ5lrZSl+I/9bJ7sOt/7bt2U0NePWdyWSc2oKiKCs/
t5Rl2TAMEYucc9M0RdHDNM3atR+MsUgkwjkXV9ZOO+fc6Vabp5IkiSrKsvaV1Ztqtaqqqsh351xi
wzRNp3LyLecCgEYW9HSej/3XhfhHlWqRE7/26k8+JVyy0uLRI23/fmLHr7Z3hkLDxXftypNVlUol
XdfD4bCI9Ww2K9pr89TBOZdlua2trc6zO2sWa4lMXzbsqodzzltbW1HaBnC7qNZ3vu/Dz+IfGdU8
EXey+2j7L463/3J75+ZwX9CUSqVAIOBEpJOkiqKYplnbs1AoiG6iilIPkd21eb3sop6IZFleNqAz
B1VVdV2v/28BgIYV1XrPxz7U5KDTcrz9l42T3eTG+FYUxSle67ruFJebmpp0XXcSPJ/Pi5D1+/2p
VEokMuc8m82uvJquFQgE0um0SGSxwiQQCNR2YIxpmuYUxA3DcOYTCARyuZxTbCkWi85DAOA6EW/P
+diHTUqIiE7s+NXR9l9s94z+ScMVT14rGAym0+lSqcQYU1U1FAqJOGaMRaNRsRCQc+7xeEKhEBH5
/X7G2NLSklip4vf7v7244fP5OOcLCwuiGh4IBDRNWzmHTCYzPz8vSZJYnC7aVVWNRCLpdJqIOOde
r9fn823KswAAWyLs7T7f99tE8WF/9KfbPZflGiu+W1pa1npIZDERSZK0VjdVVVtbW1e2Nzc3r/y2
/bJvzNfWx1ftT0TON+YlSRKrBlfyer0rS+1rdQaAxhfydoW8Xds9i1W4r3gCAACE+AYAcCnENwCA
KyG+AQBcCfENAOBKiG8AAFdCfAMAuBLiGwDAlRDfAACuhPgGAHAlxDcAgCshvgEAXAnxDQDgSohv
AABX2vj4Zkyq2ubr+wEA/JBUbZOxjYzcjY/v/dFzI7OfIMEBABxV2xyZ/WR/9NwGjsnWuuvud8BH
E3+eSF3k/NvuSQYA8MPBmLQ/eu5056+JVrmp+jrH3IT4BgCATYePLgEAXAnxDQDgSohvAABXQnwD
ALgS4hsAwJUQ3wAArvT/b1kxC+6fy48AAAAASUVORK5CYII=
`;
