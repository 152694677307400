import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import ReactLoading from 'react-loading';

import vars from '../../../../../scss/base/var.module.scss';
import Button from '../../../atoms/button/Button';
import { dataManagementRoutes } from '../../routes';
import styles from '../../styles.module.scss';

interface Props {
  writing?: boolean;
  written?: boolean;
  amount?: number;
  error?: string;
  data?: {
    decodingErrorAmount?: number;
    parsingErrors?: object;
  };
  dataSourceCode: string;
}

class UploadConfirmation extends Component<Props> {
  render() {
    const { writing, written, amount, error, data, dataSourceCode } =
      this.props;
    return (
      <div className={classNames(styles.uploadConfirmationParent)}>
        <div className={styles.animationContainer}>
          {writing && (
            <ReactLoading
              type={'spinningBubbles'}
              color={vars.colorPrimary}
              height={64} // explicit default
            />
          )}
        </div>

        {writing ? (
          <h3>Transferring data</h3>
        ) : (
          <h3>Data transfer completed</h3>
        )}
        {writing && (
          <Fragment>
            <p>
              Your data is being transferred into the Cassandra Table now.
              <br />
              Wrote {amount} rows so far ...{' '}
            </p>
            <p>You can leave this Wizard, the transfer will keep on running.</p>
          </Fragment>
        )}
        {written && <p> Wrote {amount} rows</p>}
        <Button
          color={'primary'}
          className={styles.backButton}
          label={'Back to Data Management'}
          linkTo={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}`}
          id={'confirmationRunButton'}
        />

        <br />
        {written && data.decodingErrorAmount > 0 && (
          <div className={styles.encodingErrors}>
            <p>
              Encountered {data.decodingErrorAmount} decoding error(s) and
              skipped that many lines. Selecting the correct encoding will fix
              this.
            </p>
          </div>
        )}
        {written && !isEmpty(data.parsingErrors) && (
          <div className={classNames(styles.parsingErrors, 'table-reset')}>
            <p>Encountered parsing errors while trying to parse the columns.</p>
            <p>Please make sure the correct column types were selected.</p>
            <table>
              <tr>
                <th>Column Name</th>
                <th>Number of parsing Errors</th>
              </tr>
              {Object.entries(data.parsingErrors)
                .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                .map(([column, parsingErrorAmount]) => (
                  <tr>
                    <td>{column}</td>
                    <td>{parsingErrorAmount}</td>
                  </tr>
                ))}
            </table>
          </div>
        )}
        {error && (
          <div className={styles.error}>
            <p>Error: {JSON.stringify(error)}</p>
          </div>
        )}
      </div>
    );
  }
}

export default UploadConfirmation;
