import { defineMessages } from 'react-intl';

export default defineMessages({
  msgAuditLog: {
    id: 'auditLog.auditLog',
    defaultMessage: 'Audit Log',
  },
  msgTimestamp: {
    id: 'auditLog.timestamp',
    defaultMessage: 'Timestamp',
  },
  msgEventType: {
    id: 'auditLog.eventType',
    defaultMessage: 'Event Type',
  },
  msgDetails: {
    id: 'auditLog.details',
    defaultMessage: 'Details',
  },
  msgUserId: {
    id: 'auditLog.userId',
    defaultMessage: 'User Id',
  },
  msgUserName: {
    id: 'auditLog.userName',
    defaultMessage: 'User Name',
  },
  msgEventDetails: {
    id: 'auditLog.eventDetails',
    defaultMessage: 'Event Details',
  },
  msgCausedBy: {
    id: 'auditLog.causedBy',
    defaultMessage: 'Caused by',
  },
  msgCaused: {
    id: 'auditLog.caused',
    defaultMessage: 'Caused',
  },
  msgUser: {
    id: 'auditLog.user',
    defaultMessage: 'User',
  },
  msgEventsRelatedTo: {
    id: 'auditLog.eventsRelatedTo',
    defaultMessage: 'Events Related to',
  },
  msgRelatedEvents: {
    id: 'auditLog.relatedEvents',
    defaultMessage:
      '{relatedEventCount, plural, =0 {No related events} one {# related event} other {# related events}}',
  },
});
