import React, { FC, useEffect, useRef } from 'react';
import './contextMenu.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux-act';

import { DeprecatedRootState } from '../../../../store/state.type';
import { useAppDispatch } from '../../../../store/store';
import { RepoMeta } from '../../../../store/workbench/state.types';
import { useSelectedDirRepoMeta } from '../../../workbench/hooks';

export type ContextMenuProps = {
  menuId: string;
  entries: ContextMenuEntry[];
  hide: () => void;
};

export type ContextMenuEntry =
  | {
      /** Text to show for the entry */
      title: MessageDescriptor;
      /** Icon to show for the entry */
      icon: (
        state: DeprecatedRootState
      ) => React.ComponentType<{ className: string }>;
      /** Optional: onClick handler */
      onClick?: (dispatch: Dispatch) => void;
      isVisible?: (repoMeta: RepoMeta | undefined) => boolean;
      isDisabled?: boolean;
      type?: 'entry';
    }
  | {
      type: 'spacer';
      isVisible?: (repoMeta: RepoMeta | undefined) => boolean;
    };

/**
 * Maybe not strictly simpler, but removes some of the close redux integration.
 * (But it also handles the clickOutside itself and adds state-dependent icons)
 *
 * @param menuId
 * @param entries
 * @param hide
 * @constructor
 */
const SimpleContextMenu: FC<ContextMenuProps> = ({ menuId, entries, hide }) => {
  const ref = useRef(null);
  useEffect(() => {
    const clickOutsideListener = (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) hide();
    };
    document.addEventListener('click', clickOutsideListener);
    return () => {
      document.removeEventListener('click', clickOutsideListener);
    };
  }, [hide]);

  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useAppDispatch();
  const repoMeta: RepoMeta | undefined = useSelectedDirRepoMeta();

  return (
    <div className={'context-menu'} id={menuId} ref={ref}>
      <div className={'arrow'} />
      {entries.map((e, i) => {
        if (e.isVisible && !e.isVisible(repoMeta)) return null;
        if (e.type === 'spacer') {
          return <hr style={{ margin: '2px' }} />;
        }
        let onClick = (evt) => {
          if (e.onClick) {
            e.onClick(dispatch);
            evt.stopPropagation(); // To not trigger the surrounding listener, which contains the button that toggles visibility
          }
        };
        if (e.isDisabled) {
          onClick = undefined;
        }
        const Icon = e.icon(state);
        return (
          <div
            key={i}
            data-testid={`cm-${e.title.defaultMessage}`}
            className={
              e.isDisabled
                ? 'cm-item-container cm-item-container--disabled'
                : 'cm-item-container'
            }
            onClick={onClick}
          >
            <Icon className={'context-menu-icon'} />
            <FormattedMessage
              id={e.title.id}
              defaultMessage={e.title.defaultMessage}
            >
              {(message) => <p className={'context-menu-text'}>{message}</p>}
            </FormattedMessage>
          </div>
        );
      })}
    </div>
  );
};

export default SimpleContextMenu;
