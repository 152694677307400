import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { Propensity } from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationPropensitiesAugurSettings,
  CampaignOptimizationPropensitiesValidationError,
} from './type';
import { validateDescription } from '../../campaign-optimization-channels/v1/validate';

export const valueIdRegex = /^[A-Za-z0-9 _\-\(\)'&\[\]\/]*$/;

export const validateValueId = (
  valueId?: string,
  existingValueIds?: string[]
): string | undefined => {
  // The name field is required
  if (!valueId) {
    return moduleOptimizationMessages.msgNewAugurStepPropensitiesErrNoValueId
      .defaultMessage;
  }

  // Valid characters
  if (!valueIdRegex.test(valueId)) {
    return moduleOptimizationMessages
      .msgNewAugurStepPropensitiesErrValueIdInvalidChar.defaultMessage;
  }

  // Max length
  if (valueId.length > 64) {
    return moduleOptimizationMessages
      .msgNewAugurStepPropensitiesErrValueIdLength.defaultMessage;
  }

  // Prevent valueId duplicates
  if ((existingValueIds || []).includes(valueId)) {
    return moduleOptimizationMessages
      .msgNewAugurStepPropensitiesErrValueIdExists.defaultMessage;
  }
};

export const validateSinglePropensity = (
  propensity: Propensity,
  allPropensities?: Propensity[]
): [string?, string?] => {
  // Validate value id
  const valueIdError = validateValueId(
    propensity.valueId,
    (allPropensities || [])
      .filter((x) => x.id !== propensity.id)
      .map((pr) => pr.valueId)
  );
  // Validate description
  const descriptionError = validateDescription(propensity.description);

  return [valueIdError, descriptionError];
};

/**
 * Validation for the "sub form": add propensity
 * @param value
 */
export const validatePropensities = (
  value: CampaignOptimizationPropensitiesAugurSettings
): DeepPartial<CampaignOptimizationPropensitiesValidationError> | undefined => {
  const error: DeepPartial<CampaignOptimizationPropensitiesValidationError> =
    {};
  // Is there at least one propensity added?
  if (!value || value.length === 0) {
    error.global =
      moduleOptimizationMessages.msgNewAugurStepPropensitiesErrNoPropensities.defaultMessage;
  } else {
    value?.forEach((propensity) => {
      const constraintError = validateSinglePropensity(propensity, value);
      if (!_.isEmpty(constraintError)) {
        error.rows = {
          ...error.rows,
          [propensity.id]: constraintError,
        };
      }
    });
  }

  return cleanErrorObject(error);
};
