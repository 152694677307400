import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TimeLineState {
  activePage: string;
  reportCount: string[];
}

const initialState: TimeLineState = {
  activePage: '',
  reportCount: [],
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload;
    },
    setReportCount: (state, action: PayloadAction<string[]>) => {
      state.reportCount = action.payload;
    },
    prevPage: (state) => {
      const index = state.reportCount.indexOf(state.activePage);
      const usedIndex = index === 0 ? state.reportCount.length - 1 : index - 1;
      state.activePage = state.reportCount[usedIndex];
    },
    nextPage: (state) => {
      const index = state.reportCount.indexOf(state.activePage);
      const usedIndex = index === state.reportCount.length - 1 ? 0 : index + 1;
      state.activePage = state.reportCount[usedIndex];
    },
  },
});

export const { setActivePage, setReportCount, prevPage, nextPage } =
  timelineSlice.actions;

const reducer = timelineSlice.reducer;
export { reducer as multiReportReducer };
