// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/rejected-features-s3/v1/type.ts' --type 'ConfigRejectedFeaturesS3Config'
export const schemaConfigRejectedFeaturesS3 = {
    "$ref": "#/definitions/ConfigRejectedFeaturesS3Config",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<RejectedFeaturesS3Config>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "properties": {
                "s3Bucket": {
                    "$ref": "#/definitions/ConfigEntry%3CS3BucketSelectAugurSettings%3E"
                },
                "s3File": {
                    "$ref": "#/definitions/ConfigEntry%3CS3FileSelectAugurSettings%3E"
                }
            },
            "required": [
                "s3Bucket",
                "s3File"
            ],
            "type": "object"
        },
        "ConfigEntry<S3BucketSelectAugurSettings>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "$ref": "#/definitions/S3BucketSelectAugurSettings"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigEntry<S3FileSelectAugurSettings>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "$ref": "#/definitions/S3FileSelectAugurSettings"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigRejectedFeaturesS3Config": {
            "$ref": "#/definitions/Config%3CRejectedFeaturesS3Config%3E"
        },
        "S3BucketSelectAugurSettings": {
            "additionalProperties": false,
            "properties": {
                "bucketName": {
                    "type": "string"
                },
                "dataSourceCode": {
                    "type": "string"
                }
            },
            "required": [
                "dataSourceCode",
                "bucketName"
            ],
            "type": "object"
        },
        "S3FileSelectAugurSettings": {
            "type": "string"
        }
    }
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/rejected-features-s3/v1/type.ts' --type 'PartialRejectedFeaturesS3AugurSettings'
export const schemaAugurSettingsDefaultRejectedFeaturesS3 = {
    "$ref": "#/definitions/PartialRejectedFeaturesS3AugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "PartialRejectedFeaturesS3AugurSettings": {
            "additionalProperties": false,
            "properties": {
                "enforced": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "rejected": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                }
            },
            "type": "object"
        }
    }
};