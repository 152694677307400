import adminMsgs from 'common/dist/messages/admin';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import User from './User';
import { useUsersList } from '../../../../core/api/admin';
import Button from '../../../atoms/button/Button';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import * as routes from '../../../index/routes';
import Paging, { usePagingParameters } from '../../../molecules/paging/Paging';
import { usersRoutes } from '../routes';
import styles from '../styles.module.scss';

export const adminAddUserLink = `${usersRoutes.basePath}/${routes.app.admin.addUser}`;
const PAGE_SIZE = 20;

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  account: {
    name: string;
  };
  email: string;
  attributes?: {
    isTechnical: string[];
  };
  realmRoles: { name: string }[];
};

const UsersList: FC = () => {
  const { offset, search } = usePagingParameters();
  const { data: users, isLoading } = useUsersList(offset, PAGE_SIZE, search);

  const renderContent = () => {
    const renderHeadline = () => {
      return (
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersFirstName} />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersLastName} />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersEmail} />
          </div>
          <div className={'ct-col ct-col-120px ct-col-admin'}>
            <FormattedMessage {...adminMsgs.msgUsersIsAdmin} />
          </div>
        </div>
      );
    };

    if (isLoading) {
      return (
        <div className={styles.listContainer}>
          <div className={'ct-list'}>
            {renderHeadline()}
            {Array.from({ length: 10 }).map((_, i) => (
              <div key={i} className={'ct-row'}>
                <div className={'ct-col ct-col-200px'}>
                  <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
                </div>
                <div className={'ct-col ct-col-200px'}>
                  <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
                </div>
                <div className={'ct-col ct-col-200px'}>
                  <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
                </div>
                <div className={'ct-col ct-col-120px ct-col-admin'}>
                  <LoadingPlaceholder width={'16px'} height={'16px'} />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.listContainer}>
        <div className={'ct-list'}>
          {renderHeadline()}
          {users && users?.map((user) => <User {...user} key={user.id} />)}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.listPageParent}>
      <div className={styles.topButtonBar}>
        <Button
          color={'secondary'}
          label={adminMsgs.msgAddUser}
          linkTo={adminAddUserLink}
        />
      </div>
      <div className={styles.pagingWrapper}>
        <Paging
          itemsPerPage={PAGE_SIZE}
          currentItems={users?.length}
          headerLabel={'users'}
          searchEnabled
        >
          {renderContent()}
        </Paging>
      </div>
    </div>
  );
};

export default UsersList;
