import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';
import { FiSave } from 'react-icons/fi';

export type Props = {
  path: string;
  content: string;
  unsavedChanges: boolean;
  infoNotification: ToBeRefined;
  closeNotebook: ToBeRefined;
  saveNotebook: ToBeRefined;
  /** Is the Markdown edit or rendered tab selected? */
  selectedTab: ToBeRefined;
  /** Call this when the Markdown edit / rendered tab is selected */
  onTabSelect: ToBeRefined;
  paneId: string;
};

export default class ButtonBar extends Component<Props> {
  render() {
    const {
      path,
      saveNotebook,
      content,
      unsavedChanges,
      selectedTab,
      onTabSelect,
    } = this.props;
    return (
      <div className={'workbench-buttons'}>
        <div
          title={'Save (Cmd+S)'}
          className={
            'workbench-button' + (unsavedChanges ? ' unsaved-changes' : '')
          }
          onClick={() => saveNotebook(path, content, 'file')}
        >
          <FiSave className={'icon save-icon'} size={'20px'} />
        </div>

        <div className={'markdown-tab-bar'}>
          <div
            className={'md-tab ' + (selectedTab === 'rendered' ? 'active' : '')}
            onClick={() => onTabSelect('rendered')}
          >
            <span>Rendered</span>
          </div>
          <div
            className={'md-tab ' + (selectedTab === 'rendered' ? '' : 'active')}
            onClick={() => onTabSelect('edit')}
          >
            <span>Edit</span>
          </div>
        </div>
      </div>
    );
  }
}
