import {
  AugurResultType,
  CodeCapsuleResultType,
  HabitatResultType,
  SearchResults,
} from 'common/dist/types/responseBodies/dashboard';
import React, { FC } from 'react';

import SingleResultList from './SingleResultList';
import styles from './styles.module.scss';

const getAugurLink = (code, habitatCode) =>
  `/app/habitat/${habitatCode}/augur/${code}`;

const getHabitatLink = (code) => `/app/models?habitatCode=${code}`;
const getCodeCapsuleLink = (code, habitatCode) =>
  `/app/habitat/${habitatCode}/codecapsule/${code}`;

export type Props = {
  query: string;
  result: SearchResults;
  onSuggestionClick: () => void;
};

const SearchResult: FC<Props> = (props) => {
  const { query, result, onSuggestionClick } = props;

  return (
    <div className={styles.searchResult}>
      {/*
      <div className='SearchResult--headline'>
        <FormattedMessage
          id='search.query'
          defaultMessage='Search results for "{query}"'
          values={{ query }}
        />
      </div>
      */}

      {result?.augurs?.length > 0 && (
        <SingleResultList<AugurResultType>
          label={{
            id: 'search.augurs',
            defaultMessage: 'Augurs',
          }}
          elements={result.augurs}
          getLink={(augur) => getAugurLink(augur.code, augur.habitatCode)}
          onElementClick={onSuggestionClick}
        />
      )}

      {result?.habitats?.length > 0 && (
        <SingleResultList<HabitatResultType>
          label={{
            id: 'search.habitats',
            defaultMessage: 'Habitats',
          }}
          elements={result.habitats}
          getLink={(habitat) => getHabitatLink(habitat.code)}
          onElementClick={onSuggestionClick}
        />
      )}

      {result?.codeCapsules?.length > 0 && (
        <SingleResultList<CodeCapsuleResultType>
          label={{
            id: 'search.codeCapsules',
            defaultMessage: 'Code Capsules',
          }}
          elements={result.codeCapsules}
          getLink={(codeCapsule) =>
            getCodeCapsuleLink(codeCapsule.code, codeCapsule.habitatCode)
          }
          onElementClick={onSuggestionClick}
        />
      )}

      {/* FIXME: see https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1427
      result?.apps?.length > 0 && (
        <SingleResultList<AppResultType>
          label={{
            id: 'search.apps',
            defaultMessage: 'Apps',
          }}
          elements={result.apps}
          getLink={undefined} // TODO What is supposed to happen when clicking the app entry?
          onElementClick={onSuggestionClick}
        />
      )*/}
    </div>
  );
};

export default SearchResult;
