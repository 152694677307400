import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../_components/TextInput';
import { Field } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import { validateDirectoryName } from '../validation';
import SelectedDirs from '../../../../../molecules/selected-dirs/SelectedDirs';
import { useContent } from '../../../../../../core/api/workbench/content';
import { useSelectedDirPath } from '../../../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';

const Step1 = ({
  directoryName,
  directoryNameTouched,
  directoryNameError,
  isEditing,
  initialName,
}) => {
  const selectedDirPath = useSelectedDirPath();

  const { data } = useContent(contentArrayToPath(selectedDirPath, false));
  const content = useMemo(() => data?.content ?? [], [data]);

  const validate = useCallback(
    (value) => validateDirectoryName(value, content),
    [content]
  );

  function errorMessage() {
    if (directoryNameTouched && directoryNameError) {
      return <p className={'error'}>{directoryNameError}</p>;
    }
  }

  return (
    <div className={'step-container step-1-container'}>
      <div className={'description-container'}>
        {validateDirectoryName(
          directoryName,
          content,
          isEditing,
          initialName
        ) ? (
          <div className={'bubble bubble-info'}>
            <p>1</p>
          </div>
        ) : (
          <div className={'bubble bubble-ok'}>
            <FiCheck className={'checked-icon'} size={'20px'} />
          </div>
        )}
        <div className={'description'}>
          <div className={'title'}>
            <p>Select Name</p>
          </div>
          <div className={'text'}>
            <p>
              Select a name for the directory. Browse on the left to change the
              path where the directory will be created.
            </p>
          </div>
        </div>
      </div>
      <div
        className={
          'input-container' +
          (directoryNameTouched && validateDirectoryName(directoryName, content)
            ? ' input-container-error'
            : '')
        }
      >
        <div className={'error-container'}>{errorMessage()}</div>
        <div className={'name-input'}>
          <Field
            name='directoryName'
            label='directoryName'
            type='text'
            component={TextInput}
            validate={validate}
          />
          <SelectedDirs
            dirs={[...selectedDirPath, directoryName]}
            highlightLastEntry
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;

Step1.propTypes = {
  directoryName: PropTypes.string,
  directoryNameTouched: PropTypes.bool,
  directoryNameError: PropTypes.string,
  /** Is the directory being edited: Needed for validation as in that case the name can be the same */
  isEditing: PropTypes.bool,
  /** The initial name of the directory: Needed for correct validation in the case of isEditing */
  initialName: PropTypes.string,
};
