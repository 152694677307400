export const s3FileSelectImg = `
iVBORw0KGgoAAAANSUhEUgAAAlEAAABoCAIAAABe9DLFAAANP0lEQVR4Xu3d21MU2QHH8f1b8g/s
P5C/IKnK8+5zqvKYvCQPyWvysFX7sORh47ouu7oqF1HRqJQIAgPMgFxFrnIHkfugIDedgclPWs6e
OTO0zA7TTezvp6as6dPHnhm2qr97hu7yswwAANHwmTsAAMAniuYBAKKC5gEAooLmAQCiguYBAKKC
5gEAooLmAQCiguYBAKKC5gEAooLmAQCiguYBAKLi/6B5Xf2Dm1tv3NEANbZ2uEPF2d7Zbevs1WNi
ek6bkzPzM/MLZu+ZvxwAwBNQ83Ra16m8qf2JHo/bOodGJ/bfvnUnneCsmqeDrCU33NFseeeceYRa
O3vfbO+YzXT6QA+zeeYvBwDwBNG8kedTHT0DqVTK2zw8PFxYXF5dd9NykrNq3ovF5ZHxKXc0W945
Zx4hVd8dspz5ywEAPEE0796jloODX9YxhfrEmpdOp7XYdUctZ/tyAAAjoOa5Qzkmpue8Lz/1Z9+z
EbuRuc3zmSxzC4vN8a4WPRLdrR09C0srGtTC8mFz+4OGWKyjR4OTs/P2X/GcNEevsre//6TvWSzR
3RBL6Ek6+xWVMb0N85YmZ/Ic3KP3pnd1S6/R0WMedY/bfH6fd/qDAwD8BdG8Ry2JrTfb7qhleGxy
cHT84PDQ25yafaGzvNnrNM9/8tjEdM/TIVNBTVtLvvKe513DOfLOUaC6+4d29/a8zdn5l89Gxu0J
bZ29+ovec710V9+gF9q8Uil3ned/DUtBBwcA+AiieQpeY2vn+NTs/n6e61ZSqZSWZc5ga2fvu+Pf
/9nN85+cTh/UN8c/xDBH3p458s65/6hlfeNDOD32L+SWV9efjTy3dma0CtRizh6xFdS8Qg8OAPAR
RPMyx9etaI2iVcvo+HQqnTa7FpdXtXSz5r6nhVTy1Wvvud08/8mr6xv2ms+Rt2eOvHPqHrc6HW3t
6DFLyadDY2YpabQk3DAbBTWv0IMDAHwE1DzbwtJKQyyxs/vhq0Kd8bU4a3/SZz+0kFpZW/cm2M3z
nzy/sDQ2OePNzJW3Z468cxpi7kUletG9/X3veby7P5bodt6S3qTddVtBzSv04AAAHyE0L3P0lV1H
z4D3XGf86bkX2fuzOM3zmTy3sPi8BM3LvZDyffP2jpvX1b+9s5u9309hzSvw4AAAH+E0b//tu0ct
ce/5y6XVwdGJ7P1Z7Ob5T15d3+gfHHVHj+XtmSPvHP/m6RVX15PZ+/0U1LxCDw4A8BFO81bWku1d
fd7zd+9Sja1+92jbzfOfnE4fmJTm0uJyYDjrepBceef4N29pZc0ntLkKal6hBwcA+Aiiea0dPfaF
GApY3eNWe/ky/Hzy6dCYuf0glU7bd6G59yr4Th6bmO4bHDF7Dw8PN15ves/Vy7rHbfrTTM6Vd45/
8zJHH3B+Ycls7u7t2ZuOgpqXKfDgAAAfQTRvb3+/d2BYJ/qWeFdDLKGTeHLjwzWZhs77j9s6Y0d3
kbd29vo0L+M7OXN837d3u3dLomv2xUuza3F5VX/xpHvSPblzPto8rS/7h0a9t6QXTXQ/1XrRmp6l
0OYVdHAAgI8gmgcAwHlA8wAAUUHzAABRQfMAAFFB8wAAUUHzAABRQfMAAFFB8wAAUUHzAABRQfMA
AFFB8wAAUUHzAABREUTz0un0xPRcfXN7VW1dxe0HPHjw4MEj4g/lQFFQGhQItxmlVPLmpdMHPQMj
LYnulTX+7VMAwAeKgtKgQCgT7r6SKXnzJmbm9KncUQAAMhkFQplwR0um5M2rb46vJjfcUQAAMhkF
QplwR0um5M2rqq1zhwAAOBZkJkrevIrbD9whAACOBZkJmgcACFOQmaB5AIAwBZkJmgcACFOQmaB5
AIAwBZkJmgcACFOQmaB5AIAwBZkJmgcACFOQmaB5AIAwBZkJmgcACFOQmaB5AIAwBZkJmgcACFOQ
maB5AIAwBZkJmgcACFOQmaB5AIAwBZkJmgcACFOQmaB5AIAwBZkJmpfHgwcP1tbW3FHLTz/95A6V
3uDgYHd3tzsakpmZmebmZncUAAoXZCbOUfPa2tq++uqr/f19e3B0dPRXn1tra2uvHisrK7t48aLZ
jMfj7mzLp928VCr17Nkzd7RANA+IiOGp1X9eitV3TLo7ctQ0Dv+7snNzO+scfhqnz0TxzlfzysvL
W1pa7MFimmdraGh4/vy5O3qCT7t5e3t7169fd0cLRPOAiPjtH8t/84cyPZQ0d5/lr2X13jQ9cfd9
zOkzUbzz1bze3t7vvvvOXurRPIPmAQieaZ5P9kzw9PjTv/7r7v6Y02eieOeref39/cqevdTL27x4
PH7lyhWduH/++efx8XFnb15O8/QqFRUVV69e1XFu3LixvLxszf3QvJ6eHh3/8uXLd+/eVSfsCU7z
3r17V19fr0EdUH/q4PZeRzKZrK6u1pE1WX8ODAzYe1U1vaJ26U8dM51Om125zfOZLIuLi/po165d
qziSSCQ0uLW1pZ/b119/XXnk4cOHZr4ypslXjtTU1Gxubv5yrEzmzZs3N2/e1C6956GhIZoHRMTw
1OrnX/zHJO1vZY+cCXbwfvfna3y3edoP4zXv4ODAXurlNq+1tVUB854rNjpx6/xrT8jLbl4qlbL7
p54pQjqnmxE1T9016ZqenlYnDg8PzQSnefrrY2Nj3nOF5969ez5rSv3d169fm81Xr16Z5/oJ6HX1
E/A29dNQycxep3n+kxcWFhSn7e1tMzI7O+s9ybvO014Fcmdnx9tUL5VSs1c/MdXu5cuX3mZ7e3tT
UxPNAyLipOwpb1/+42aRwcsUkoninbvm6YkWWGap5zTv7du33377rTnRi/qhVY7ZPIn/d5vz8/Od
nZ1mU82rq6uz9meePHliX/dhN09FdM7+KoTWVfaIocVTbW2tO3pEH03LLGdQNdW499xu3kcna6+z
UDPyNk/znauH9J9DH8173tHRMTyc9Z2GFoI0D4iO3Owpb7//y/Xig5cpJBPFO4/Ns5d6TvMmJyfN
Is/QEsSc60+S27yBgYHbt2/funVLp28l6v79+2aXmqfXtea+b5WWQWbTbl5jY+OLFy/Mpic3Kh7l
UH/X+RLSo4+mn4AzqM+uJZf33G6e/2QtSX/88Udnr5HbPC2Xcwuq1bP3dahoyah3bu/VIpjmAZHi
ZO/zLy+cSfAyhWSieOexeRlrqec0T6farq4us+m5c+eO/yUnmZzm3b17Vy9h1ovJZFL9M3vVPFMa
49KlS+a53TxVU8msyfbDDz8oJGaObXZ2VoFpamrS4tL+vlQfTS/hHEc5N9/c2s3zn7yzs1NVVfXh
uDlym6ePX1ZW5hytsrLS3NGhj2PPzxxFl+YBUaPs2Ze0nEnwMoVkonjntHlmqXea5tXW1q6vrzuD
Drt5q6urOqfbe5UKpctsFto8+/dzp6T30N7ervWT+SWZPtrTp0+zZ2VxmuczWc2rrq52R4/lbZ7+
v8EeceQ2b2JiguYBEaS8KXImeF/8vabI4GUKyUTxzmnzMsdLvdzvNh89cq8aunz5ckHfbSoezj3p
sVjMad7pv9vU+5mbmzObBdFa0HwJOT4+rreRvT+L3Tz/yf7fber/JJzm6aen+tojDr7bBGCY7P2K
W/HyOn0mind+m5dOpy9cuKDVjH1u9a5hsb8S1BrLuYoyL7t5U1NTTU1NZpeO5v1iz4zkvYbFvqnA
fkUdLfdXjKdXXl7ufRylyP9iHLt5H5187dq1ra0td/SI1tC5PzFVbXd31xk0EokE17AAsBW/vDNO
n4nind/mZY7uP7t48aJzbvXuVfA6oQRqyVLovQoKxvfff2+uy9cBtapzmmffq+DduOZzr0JVVZWd
BMXGKYShQq+srJhNHdlecukn0NjYaH7LqFWgfatf7r0KPpMXFhb0nu2MmS9RM0dX/SwtLZlN0VK1
oqLCzNeHHRsbM9/Z6uBKLPcqACiF02eieOe6eVrqffPNN7nn1ng8ruWRTtxqj88dCDbnGpb5+Xmt
bCorK2/cuKHgbW5uOs2z70m/c+eO/z3pqVRKx/cOKFo1mqv8HcqhXkhvXqnTTD137ijo6+vTcZQf
dbS6utqneRnfyZmje+xqji5J1Qt5V82YXclkUoPaZd+T7mVPb0xH05NYLGbfvaB3XnN0mQz3pAM4
W6fPRPHOUfMAABEUZCZoHgAgTEFmguYBAMIUZCZoHgAgTEFmguYBAMIUZCZoHgAgTEFmguYBAMIU
ZCZoHgAgTEFmguYBAMIUZCZoHgAgTEFmguYBAMIUZCZoHgAgTEFmguYBAMIUZCZoHgAgTEFmouTN
q6rN+sdXAQCwBZmJkjevvjm+mtxwRwEAyGQUCGXCHS2ZkjdvYmauJZH1L50CAOBRIJQJd7RkSt68
dPqgZ2BEn2plLenuAwBElaKgNCgQyoS7r2RK3rzM++ylJ6bn6pvbq2rrKm4/4MGDBw8eEX8oB4qC
0qBAuM0opSCaBwDAeUDzAABRQfMAAFFB8wAAUUHzAABRQfMAAFFB8wAAUUHzAABRQfMAAFFB8wAA
UUHzAABR8T94c8JbvAz2qQAAAABJRU5ErkJggg==
`;
