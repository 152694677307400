export const campaignOptimizationCommunicationGroupsImg = `
iVBORw0KGgoAAAANSUhEUgAABBQAAAJCCAMAAABHxmR+AAAB/lBMVEX////u8PLm5ubc4uumrrW7u7uf01bsPT0KCgozMzP9/f7j
4+Opsbfk5OS/xcrt7/Grs7m6wMXg4+bO0tb6+/vL0dXr6+siTpAHGzKttLvm6ev19fbT19vX2961vME3NzfKz9O8wseyub/j5ujr
7vDa3uHY2Nj39/fc3+LGzNDBx8yIiIjk5+rf4uX8/Pzp6+3P1NjDyc63vcKwt77q7e/h5OfFxcXHzdLZ3ODy8vLv7+/d4eQ/Pz+N
jY1lZWVLS0tFRUXx8vSBgYG9xMm8vLyzs7NZWVnt7e23t7ff399RUVH09PTMzMy0u8BgYGDV2d15eXm4v8TP6atycnLQ1dkSEhLo
6OiZmZn5+flvb2+mpqZcXFzx8fHCwsIWFhbd4OPa2tqwsLBra2uutrw7OzsxMTEiIiLR0dF8fHwODg7g4ODV1dWVlZWgoKBOTk6r
q6tJSUnIyMhVVVWEhITxbm7T09ORkZGpqanJycmjo6MbGxvBwcEqKiri4uLh4eF0dHSdnZ12dnZoaGjPz8++vr7uiouf01fc3Nyt
ra3L6KOv23IUJzzi8srQ0NDu3N7Z7rugpqyRmaAvWZe634Znc4DtdXbsR0irvNXo6evV3eru9+FxjrjwqqyVqsrumpvtxse/4o52
gIyk1V/sWlrrUFFaZnXz+uq5x9xKbqRbZ3bj+5naAAA3FklEQVR42uzUoRHAQAgAQWZoBfkF0X8l0XEk8tnVpy8AAAAAAAAAAAAA
AAAAAAAAAAAAeOk6CVzrVMcnlcDlKgB+MhBYoWKoM4AFsmOmTAFWyIqZYwqwQp5paAqwQqYpAKYAmMLDvt30pooFYBx/mjyrkxME
XxYCGtQa1CiKLym5d0VcGmNMXLho4sIv1Y87HMBaap2ZztzOeO35LXoVxPR4Tv5X0GqapqOgaZqOgqZpOgqapukoaJp2oqOgaVqB
joKmaQU6CpqmFegoaJpWoKOgaVqBjoKmaQU3F4Xg8XGFXPzo43f3/Jha4R/xH2NceZlK+C5Wi11/N3LwJUqPAa66p4X4WbcUhZg0
fWQMuvjdTZkxW118XoMDvCOqAYASTXwPe4Mp6Qb4AiYf8CeCqgBgsAHtf40Cw7uKwtGyrK1Jsh/8iihM6H2nKJRmpLE7uC1yKZD6
L6NgsayjcBNRoH1PUZhAcSrkEp+1f3Y+jkLwHOM7qNY5m0MZkTv8evFz8NdRcJ730P7XKBhsB3cXBaBH2vj3VBS+jx3l+WRSBviv
qShoNxCF2OTubRSqDWNqhQuBRK9Sqpb7RtdHEIVWZY5UNepb3qiKG3SOAqa0oKzcQWtiIyUOg6bl5Xf8yXJmTXwAj5UFfi5rmLtl
4MFdwK5sw64AnMqWzWSn726g2N52HR6gjCtDdGpW2AhwN3zJCXKxKV8KQ75cDde2dbN/EFVK6iVsoDRZGq6DxMb1kfhRs9rLKIAy
96ymMVkBeKkcaVR+oOw+IiG64Xr7NIYyqQh/M2jlT1x1jfWy8j3evP0/Uah2KeNzFJwjKUlaQbppM2VitmoxIcdIPKypNB3cnjdR
OJCqWwuTShgAEK1sbBUkxkcqZgz06O1II7umMOLAo7LcY07Fy68piApTLR9Ag5XsriFwL4akj4LCkC9Ww5VtLXahtFgGStyOZPoI
5/WaQiSpNAMAE2Zz0gFcKqP8moK/zHZ4Ij1uPGNC2uclKvW7iq+LAixa5ygsaazglyUb6SbZXjjDOqUZxcPscXuT4cN+vOTxBs/8
3kThB/kA2KS78jszLgG4bM5FsKhzkQ5jMBdxi00VhTYtd3iKAuk+Oz2THoLYYxj7eRRcmotqYLdppFGQrMWrDdnFvWjQRNF5yFdW
w8W2yyhILh+dhcnwFAWb3MWBPWMNGNMsB2I+oAns4yk3cTWPQktNln+Q3KTHyfbQH095TJdoGGO/obzF/5ZuwC+JQizZO0VBSPpI
eOxnU+4AqJCqykNKAdQ4QEJsGeHmvInCnlxANBkh4aSrscVDttL7akzTAMCKjNEjawDyKOR3FmRc+PTBNzlGYmWyox7LHhIDhrgX
HqcoKA75cjV8tO0iCuwjEfF4isKUHhIHtcXNbjuScfHThyHr+2wapAOY2YWvBzKGkFwB2WxqXxUFuKz7eRQCewzF5QCAkU1ojxIJ
QcYI1A+lwS1uzpsogDzA5lFAMVgBLA7E6bsHZr5yd+EcPZrB2yhUoazZLUThdcAGPXVvJpDYcIl70aeBguKQL1fDR9suo7BCYk7m
USjlK0j0wz2ebR+JQO0oRMHIZ1JILtRxh/zOGIFkIz2mej8nbjcYBbFmP4uCIublTUgO8LqpzDUU8kHF2k5FlLg5xXcKNiJO7ZRH
A+iSdW+xR8In5zjp0cKbKByRClkpRME7PfdOdaCRx+AnLdwLt9j54pA/Wg0fbbs8fYDikCKLQplS4A3H7k7afB+FNW2kLLqAmc9W
W20NyfXuRRfha6OAMfmSz6/vzkjKdR6Fbjbl29cpX/Ds9ublTRTmZAyPrywAUT29NUr3Vt9EwXgbhRZSO/YLUViyi7wDbfXYEMro
jqJQZh2vxovnwpA/Wg2FbRdRsLIo1ItRKKZHDA0m1vJdFIRkjNQTPXVcfI6C8CRJOZhD+8IowGM7SKPgmJx5h3k1uh4F2T256SjU
OFM/pt1cBwnxMrFIuhj/SRSM0xPs3kUhQmrC1n1G4YFcIVeVHBWG/PkozDgCSjz+WRQq5HLTWaF+GYXn8zuFYhSA6sJrk9KG9oVR
2B+5S6NQ4zZAwr0WhQf142adoyDqrAER6wLv7EOawieHUDqN+H0UZkgN+PPd6UMFqRaf7jMKgUkPuS7pF4b86SgE/DgK5VOPo0Y1
vRqcEPLy9GGIfM+oGIVcvKYB7QujgAVlmy6wpAtleS0KgeQGSmTe4GX3cxQ8yhh4IedQQjOCY84EEj65xzF7pC95EQU6+Z7HQhQi
ZocLycN9RgERZSkv55qD4pD/fhT62UtrX4lCnPe4wzrm+fWFOd9HYcA+8j2rYhRG+cJbsAntK6MAg6QL9DlFYsNrUcCO6706ss4F
bk4eBX9sMKubxb54/WAr/5XHPKbr3QFQYxsXUWgJQIS0BODSOEWhesyecseZuNMooEU5QiJu0/SLQ/77UWhwXQX2sytRwIDLABBT
PmGVBcJZZ1FYcnOKQik7OxBL9lGMwjOlj4R7Rx8GAzcZBcck3TTv1qY2Zcj6JvhwyoM2Z5PIkzc5JVO+cqE8SFruZkBGACLS63X7
phqV2PLouUtyfBGFOtu13ZRyBeCFHPSyKOAnaWw2BmUH9xqF1Yw0W2GbnL2gOOS/H4UHybZnmKZ1JQqrOpuVyZqzPWCx7m0GZrPJ
0AZcmuE8iwKeKJ+i3ZTH1bsoYMD6bqR+qRs+j/2s24wCGqR7+gpq2xYGuf9wylHtM3GMbu8y42sU5PrpBzLxlompjYRwJRPmBong
iYm1jYsoLBd1ku3skO35a86w60zM5rjbKCCYSCr9PVAY8ieiANsk2Zz3r0QBjsWEEZ9uyn61k14Cio/nrzljQ8Xy8T4K+5BKewjt
l0bhOr+zcJCIr3fY6XSHv83fAYl4dBgj59tR194jUx0PY4GiNApA6Q927V4noRgMwLBOTVy6Q5yMwYGfBScn7oLBhKvpynbORGCA
C9UQFL4IDGIioc+znpw27Ze8U9/GX1+6k/1Rh/nldXKNMfxDwzwY3D9ceORJ//3sP71+p7sbz3i2verhaLod1sFe09Fzp3d8+afH
2ejG53BVUajd4IaeKFIjUbgTBRCFQBRAFAJRAFEARAEQBUAUAFEARAH4JgpAIApAIApAIApAIApAIApAIApAIApAIApAIApAIApA
IApAIApAIApAIApAIApAIApA8I9RACohCsDvonAPVEAUAFEARAEQBUAUAFEARAHYEwUgEAUgEAUgEAUgEAUgEAUgEAUgEAUgEAUg
EAUgEAUgEAXgEnlHFIDPIqyWzbpNqV03y1UWBajcvGzSgU2ZiwJUbF7SD2UuClCrRZuOaBeiAFXKJZ1QsihAfXKTTmqyKEB1mnRG
IwpQm5LOKqIAH+zdsQ2DMBBA0aS6hbOCWzpcWaSAQaMUlgIiwVWae2+ILxnsu1xaXGiiAJlMNS7USRQgkRKXiihAHlMMmEQB0igx
oIgCpLHFgE0UIItnDHmKAiSxxJBFFCCJOYbMogBJrDFkFQVIokb3OBFdFQVIIrp2O1GiEwVI4ncUFlGAbBwfAB8aAb8kAZeXANec
AQ+igDdPp4EdQ1aAPePYgD2DW4EDI96BT5bBAEfWxgHd/6ow2zoNGZXvZwdRgJRajRO13UUBknqxb8c2AMIwFAXl4dO6C2VShEGB
AiFLbJC7IZ7s4mf7ORMyRAH2le0se4cnCaIAe5ujr+P+GlYfMyJEAfiIAiAKgCgAogCIAvASBaAQBaAQBaAQBaAQBaAQBaAQBbjY
sZvWRqEojOM88CwvUSMubojBt4BKxZhkSMClawmF7Lvol5qPO9xrbzNTy8xAO21hzm+TE6I52eSPKn7xNaPgLS/JogtLfIS7HH/N
y+8ghPjwKCxHkorkeMa/l7HA32q5gRDig6PgVVRVvELb14o7zH1KFKKdREGIT4rCjn6PSaO4xb+2jT38mbY18OISQoiPjcJ338/h
xMzwNWi5RBDik6Kw4Ak3j/wiT/YkCkJ8UhRacoWbdXqGUay7zVhfjzD6ZIW4y8Y6B4pLlwUnW45V0qNP9tmwBVCe9pvuAcZD4r4x
TjwAyQXtdciG5DitiDApk+AQVM/bl/X9YRyme4soUTpJSnuutdotsvsqxvOCsho33RaT9hRMpwoh3hyFMwfMrUaqIPB5+AYAIfuO
2WJP1XiDHXQOoGF04eMQKO4QUpshBoCIS0wW9AAwXWo/GDJmtiWBcs83uRkyqh2smipYBJqBOSXTpNahORdG6VOne8W0nRY0HcfF
vc8IRq/opxm5KSCEeGsUIp4ws/J59UwxlFraKATavJYca90AeOBgo3Cv1uboRxU+Db73WhQeVVXYb9PeLQop6+P0d98CQDK9LWqG
t9sHF4Xz9CzUi3go7ILs0ABoU17NOVqVHtCeWEMI8dYoVLxipuYF1pKpjYJawcio7qZBHYGGjGF0fDq+YvlaFNi5XefnKGxZw7py
byOQeTAKP5hFwTuoHFZokxG5n9GqEUDPCta9kksFId4chZpnvHTH0Y0pGyBkDau6DTnQ8AArdsOZ11ej0MLaMnqOwsgVLG9jPs51
hMlGz6KwZoVJof3CLOjcwernvsRDDiH+e2+NQscYL615dWPMEAi5hnEbduyBhjWsJRewvjF6LQoZ3MeJi8J3pnhSHD3cHLU/i0LN
HE8qLs2CGJPBLCiUaiCEeLdnCju8dGLpxm/sgNC9fx52ZmiYuCgk7ujktSgEmOQ8uSg8MMFLxTKOas15FPb0ALc4Ngv6XxYkZLBe
QQjxHlG48ISXFrxzY8sACNm4KPS/RCFyUYh+G4V0FgV3MXLjXfeK5KE7zKOgNZyY4XwBziPJ7CQ3D0K8QxR6BvhJ84Od+2lVFArj
OM4PnuVBLWlRaDT+gbp4cSYVhZatRQL3Lnr/72GuT3lrcGRoXPb7LDvPsdX5YiFa7e0e/S4Ra0YUzpNRaMTFs2grtbtPImAxjkJg
MPDEG0WhlzaHQEwLIpobhciRBA+WeEArPu72spoRhXoyCp9ywKCfOfRL6jKOgiUp7nZyGkVhsM9kCaK3NzcK2EmJbxtjcqB7nNhS
/FejoIvKNpNRSCXA3UoqIDA2lO2Mo7B7RGor6SgKTZlDebID0dubHYXUmD3u7IseqygzKVTuXKOXo1BJC9XKZBRwlhjKDkz09LdB
J+MohLLFzUnOGEVhJQ1UyKeXiGZGQbliKqjUkosNPdaLXJuwEA8vRyE1gV4lXE9FQUevIXqllMPzELc9Rjdf6yEKTz8ukswk4ygU
kuW4jXkgenvzowDPyKWskmrlyGUDdZDMPZ3cQH7h9SiglLopupWpF9NRgCtOWRX+Qo79zMmsfxZL3zKHH9J2AI4mDsNhb36UhbeM
y7XxMI4CdpK1n2FsyZFPNBLNjYI6BaLWHgZxph/4eD0KOvfFfOTWRBRUF+jULkKv0i8MKlSi840R+fjea7uOfKkL/C0K8HTVOeQg
otlRUIW3cqsEzzZxtcH/ipZNnOJf8q5ZRk8vj93roU6LSBerP68Q+v3yBLto/CVvE4j4inciYhSIiFEgIkaBiBgFImIUiAiMAhH9
ZqeOaQAIoQCKLRj6YcS/r5ufgEsgaUU0pACEFICQAhBSAEIKQEgBCCkAIQUgpACEFICQAhBSAEIKQNyYwpm9gKfsOf+lMIuPHTu2
YRiEoihqrF+5i+QJMoFRygzg/TeKUmEqKiwjzhnhFRf0YUC5VxReEUfaFmAgWzoiXn2ikOOdFmA46R25RxS+EZoAQ0oR3w5RyHEs
wJCOyB2i8PFRgFGl+HSIQoQbIwxqi+gShQUYlCgAogCIAiAKgCgAogCIAlCIAlARBaAiCkBFFICKKAAVUQAqQ0dhP9e5nbsxrmPw
TDdGYV/ZjVGowkPdGIXZn8a/0xjXMXikG6Owsq7GuFh4JFFoEIVCFOYgCg2iUIjCHEShQRQKUZiDKDSIQiEKcxCFBlH4sXO2r4kc
cRxvvyQvfhwDd1C40FLmRUE4fFFowVe76zaoqMgiWBAjoSoLipr4hEkkaB4hgSPQHgehHHnRF+Xa/7I6O9vNJHvJejlzctnPG3fH
88b54u+Tnd3Z9Qil8DT4nFJgsV09urWPhXDRwv08OznD4/IZwgg2zMcPI5TCsvIZpbDfJ9o2iaxFVAIza7ifHBURiPro6HiwCo/a
Bj6KzxGGOsyHhxEr1kr6CRzGWw3jqB3B/IRSmI9vfvza2/n6x+tLv74YKeiUrUaA5pZpx/DJYd3S3e83dAA5HqwOzsmuHWi07tUF
tfFRPH4YAYY5sapzhDEgM39s2yPM6GW5MahxrYq5CaUwnxN+Xln5zd35bWXl5+tW+EKksElWBYKc2W3isWGpAgJzyo0mwHQ6hMMw
e5cUXr2A5MUr3OD+MMzHD6NJWwhMkXQGTAweAzDeNmcvubhWnzuMUApz8f3KlB9cJ0z5/iuPL0MKLKWdeT+zc8zH40qBGWbLedXG
AGLrBqc7pPB8be0tBG/X1p5DZSnDmEcKY9timBIxj6992zTpc4cRSmEufliZ8vxrMXd47ggiAO/fXf1yu/WXq3fvl1AKaRrApZKN
YkZu1Ih2IpjRyWG/faTXgbPqYLA7BhDpVCbpaL6YAeobDT2NKa1OBoLqHsA6F+gV8+1NpwPnpZnWj/Q0HCYbg4NBhwHodeLlThXj
Tv1mz+l9DEcNPQmPM96QvdCseT8aja7fIYW/1tbW/sCUP6Ybf0ElcBinxfxOsgm/gc8dhjvMSGfQ2MnBIVJcP4omRPcb1OgkAoaR
pg4E67wCFDSpCl4LEkYohQfwSljhO9cJr4J85urly5dv/rzZ+uebafPV8kmhSDmosB3SDMsWs1PG27pt1Uz7tBU3axY/HgMJShja
sWFvR1b5dqlPOoCk/F+YpgMZOhzYRm2bigBYNo8pF33qG5ysjKgz0zYKFs9WgHac2/E+IrRxs+dUYdcul1J0MIFL1f3jXSEdcusO
Kbx4Pf39/w78Pn15/QIqAcPIlKhb6/NsDn4DvzsMzMJoXgtDDhNpjVtlkgI65GZt+sECgFKctHjeJwz4hDGgiJtKGliXuszwgyBh
hFJ4AN/8tDLlp2/c1yCf+eelawXVCVP+WT4prHMGlSjtMGByQFWA8e3UEKjEs/0BA9K0CyRo+2gMxKhm7op/3rolBc1qial/0pVC
RMvWgbMiFcSOdSbm7w2n+N1qUXtOaeamqLDotZqtw6FbCCAFWQBuOagEDKNsJwEM+/bQb+D+baoUvDDcYaZ5rQnUD+gQwCpFmRja
rpw+qGGMRRiQYaTdMIRB4HBBh9ePH9pBwgil8AmsENgJQgrSCqoTllQKRhwqdT7ADFbuMzCuVUS1UE20mQdAgiyxnXWunPVodFsK
4kNj88iVwsCOiLdrFAF26UTsGFlVCmrPKUqInVIXLjpl4NA3gkhBHCq/fi0PnFWChdGhqtONvQ4kqIwZcuB7NPJvU6Ugw3hmHrnl
nkoJ9WTMLICGM7ym1lClMJRhoJxlgAwD18KwUv9HsAOXSNesBAgjlMJDcOcN7rmFYNMH1wqqE5Zz+pBNQUXndbcgVsF4CTN2qeMU
tQEk6FBsNyjieGDnlhQazo5lSClkeEMaZ9ADTkcQRG1VCmrPqaz7feAy4JCUy4GkgLdrgre4RbAwrCxzjyHG7sAhBw5tBz5tt6Qg
h24ZcpirtAtBetAEzpNyRMeeFJQwzmkVSG0rYSheZN7hQ6JrpwOEEUrh4VbwnBCMd54VVCe8W8ITjUYXCqyUhcOQtsC4Ln+ZPfFm
oQwkKO3UiQnRZkZvSaEIRyGWlEKMzqHChqttTZHCzZ5TNXfOAJcoZ3BIWcGkgL/XpvyN2wQKA2YDkFbMeQPX5JtR+LTdkkIRgpol
y71IFag8yyUHdEMKpe1rYcAnjLIbQZN0CCJ52o4FCCOUwsMR1yLFeoV5rfCrs/ur64RlvCSZ50yt1pThbvEoGG9LKdSvSWHfqYP4
B6WwdUMKHVJ+rK1omRP1U4oU1J5lu1oH7f/Labs0jxT+xW0ChZGhHTgkKKkMXApAbfOXwpYiBekPj1gjS8St7g0pKGHAJ4xa301T
HqxUNVufBAgjlMIjSkG1guBytnMpd94t5ToF/Xq9DqJgVtmriA9IYfPDUrB9pVClU3is2mb+PJfBjiIFtWdfKYzoBNI9jQVMH3zC
4FE4JCnhDVyRwuaHpWD7SmHAcY02ZaPpizGMG1JwwhBtO75SOOq6VnGmIwMqDTElnD4sHnX6ML8VPCcs5+KlGDXg0qMBWMOEwx51
5pZCi3yl0HOn0SydA+ummk4JKlJQe1aloP78gR4dLuBE4+0wkC3A4ZAic0shQr5SOKQWBJV0BCe0DoF1QwpKGH5SKNLQ3dgDWN7u
wCE80bho1BONwblyrXDpbizpikYYWgSSEsWALfevuk4ngaWwSRuY0fGXQkarQb6/i5asY2YoUlB79pUC68q2Ng0XcEnSJ4yCloGg
3EUgKShh+Evh1P3K6zTELsXkIZYqBTUMqGGoVyJrWQAjnoAkvCQZmMVfklQRMvC4XNZlzkhTduiWWhRAM26NMSWn5RFYChXnKsWk
7y8F6LQqWvpaBk15TJ7kihTUnn2lgIF9KupBs7CAxUs+YezJ71qljYBS8MIY9/2lAKsr+hhya9ZhUpa+oUphcj0M+IWRiguBnZMO
TMx1uISLlxaHunjpgVa4XNp7H4AO77YTk7PEMR0zp1jLq5nhrhlvBZcCa/DDyvi03Lf9pVDJ8sMTlitQB2AlvjEZ56LmMW1WwIz+
ZMJEHSg9+0ohY2mjemSUNS88KTxgmXOAMHRqxMa5Nj9mwaUALwxfKexr3WqkmUjZJ0Cmu51gmdNCNtUdNgHeYE13iZMMo9v6gBRy
ZqpaybU1YwLEKJqU9MJlzgvmlTNvUJc5z2+Fy+W9IUo8QoATJzI34NBLEREvVBBcCsjkaUqhovlLAZkDTkTdqlw6TMStix6nNjDi
RBlRB0rPvlLAWUmbfXQPLpUH3BAVKIyqSUT2DkMwKahh+EsBJ2WaYvXkkDnZjcyIaB9oEE/hdhjwDSNW5kRaIQOgSv+TD2+I8mPB
N0TNb4XLJb5LUtBMHCZPmLcfSe9PMCeVRHKIO3gWS+bcLobp0yaAM9FQSfbm6JnFEhMoPODW6UBh1JN77FOHkUmkW5DsJXsMwPBC
zIyS9TnCmKzGGG4S3jq9WL4X1yI/+tbp92/EcoX3y3zr9FMlDCOUwmM+ZEVd3vzmz6V+nsJTJQwjlMJHP47tuwc9ju3bq6tvl/sh
K0+VMIxQCktPKIV7CKXgEUrhaRBK4R5CKXiEUngahFK4h1AKHqEUngahFO4hlIJHKIWnQSiFewil4BFK4WkQSuEeQil4hFJ4GoRS
uIdQCh6hFP5jx45tGISBMIxy0VV0kZggE2BRMgD7b5QyuI8RJ94b4S8+W/cMF0bheHEY4zwGt3RhFJYXizHOY3BLY6PQW57+PB6L
MX5jaMJdjYoCUJQoAKIAiAIgCoAoAKIAiALwIwpARxSAjigAHVEAOqIAdKpEYZ6AkuYhUdgyJqCkyG1AFFquE1DSmm1AFPb0VYCa
InMfEIVo+VEFKCg+2WJEFOKduYZrI5Qyx5r5jjFRiJZAQS1GRSH2tiVQytb2+EMUgAcQBUAUAFEARAH4slPHNAAAAAyD/LveudQD
iEAKgBQAKQAnBSCkAIQUgJACEFIAQgpASAEIKQAhBSCkAIQUgJACEFIAQgpASAEIKQAhBSCkAIQUgJACEFIAQgpASAEIKQAhBSCk
AIQUxr4ZtLgJRHGcB//j4GjEg2KCUQNRDCbqkkCOniUEct9DvlQ/buvoaMZNW9qm0LXzO+yOxnEn+t4P542r0WgUtBQ0Go2CloJG
o1HQUtBoNApaChqNRkFLQaPRKGgpaDQaBS0FjUajoKWg0WgUtBQ0Go3CvyeFLMppJIpIM3O8qMOL6Q/5ElX0fXiU0S/BU182q0ti
GMktp59RRTn9MnkUE9ExoJnyx1JYYcVpwGSkmTkWJPdDRH9Cgs2PUg8r+iUc5lGHzdCR/kxcSxzpl3GwEF03NE9eIAU4Wgr/ExYS
27adIFkzwPkbUnD2vyOFN2aToDJQBqc83t0KNBk9Jz9u/lAKFN4rmiUvkELJIi2F/wgLb9TBfQabfp/F8gs9wxU24Mst/QqrO6eW
OIRVkYAfYb7RU6LOBt7y7belsMSBZskLpLBlK66l8P/QSkGyMLGg1zFK4dfZSD/ZMDhJfKx/IAXBb0uBQubRHHmBFDwbtpbC/4OQ
guQGg77xD0ihYDm1VCbLlfh8/2tSWCKhOfIKKfAViyZSqPbrsLweO5GeA4+Wh7BJI6L4cgitYzY8fx6a1eFCmk+EIgV+R06CzfEa
GnYlJ/hpURbpjnryWxo2xoV3uRTw6nIwiN4Dr+0Y5BQdmm+dsy7lAuYGwZYo6APD2xvhNVmKtuizTb5FzYYUdtJOgZqpWxRCXjbR
xgib9FaJUwYHFEGQfWucSbBw6nItN3yH+CUNrSSinkV6LZt6yRUpUMhymiGvkAJFrOCKFBYmwtpYgW2757nzAaFRgO14LRpuRC1e
AXNtmVjP8tLOFUUKlOLULQeCFes7wqh/hMe9vkPm5+IOt7ZMhOJGG8hXQCOT64jMAbPWLpg4VegCrmsTYd2ltQl3XbA+ShzsDmiM
qzmpcR7hk6BARo+UiNswdasa7vpqomxHGLkumOue6QxbDrisQ+DIxVdkecGuxoqxk/yWzDIsFxZXpJBgSTPkJVIgB/tHKXiueRb2
Ns1YXHDLXQhpN6m7a22Pmr6Ru8zvrvGKNJ8GVQo+jt3OAyeijcm+tBJAI1LP6jL1zMx3IqpSFFxIIWH2Gx+lkMBp919MLMfpg5TC
CSKYuIMyFsESlm0M5Wvc6IEGHglcNh1uJKRgmJuu9MiycfogpZBg1XavahidFEqr3Y5K0ZkCpBURxSlsRQobpDRDXiMF3rDsQQo3
XGTN5yx+9gWZEP1hIauI6ICTPOxEms+CKoUtjMfZ9UI0nP6GVswSMcK6HnGJrZACax0xSgE32Tn8IAU+LG51pStHxlDOGhrJUZKg
QkgjQ5StIEdt4/BBChmailp4LYZkoYmpM1IiBhT2CxumpUihgksz5DVSoDdcH6SQygXcjbjdtvRpMjYiomwoDMeuflT4PKhSWGDd
Zq5bUUfN8jbPLyQIDE506pfuZMtATYoUSj7ORaZS8JEMUWLGRM5wshVTSgrrcTwDo0tWSKmDhyyfSiHFkh67W3I7hiUmG478m64i
BbojpvnxIilQgP0ghRFHXHK7n+6NjT3ORD4u1FODk+aTIKSgZmOGdFyOeG9TLfRo4DAuW1aVkMJJlYIzLisep1JIEVFPgkXbZ/ks
aE5yBG/fk8JurD5splIo3eFUIRNfMacOs6AHKtdUpdBgjouSr5GC8G+mSMHb3pIreilsqWVs7NtGAn/bY8zy2s4UVQobGOIpe9vj
tOLnV7B0KRPrOlG+gUiVwpJ6MhhTKRRj5z2WbZ/zcBpOAz6Sn0wf8vFQfyIFjjH16/ZAi1GPKz+JF0sndTGRgoUFzY9XSYEWsEYp
ZOkdALPSXgq7scQwSqHGA2fSfBJUKdgIiPZ4wCEibpcACltk4t0lBQO5IoXxfDGuUym4Y+clbNHnmRT2COh5oXHdFRrNB40FqhSE
i0aH7D5Kgd8KBqA8lBMp1NjS/HiZFOiIm5TChbHDZZETvf9ICgbO2cAcp2YzRZVCjRPRDXY2kJMguqUu7mf5RK5IIVal8E49HmpF
CmpnH/5zKSilhysieoC74CJM+XjofiKFHOuHwWUfpBCv0NjbNkatiRSueKP58TopxCHzOil4zM1IsP2RFAK8k+bzoUghZ6wi2sKh
J3CfmZxojXzYw59JwR+rlslUCsbYOcB5IgV1FiOQZe1RAXUXptn4bHOa1hTckCRXxj9IIcFRfjqRQjjLee/rpEA7WJ0UlkOI2D+S
gg97uHP2TP/fbI4MUhgXlMaFJNraEZFjD4m7fXzFJ2bNMykYY9r7UykEWI6hlj+Twjh7FcR35tFIiKjrexvSHtlUCtehS85C+iCF
kHEScHMiBXOWFfLXSUFUDoUU/OEGXH8khcp0417z+u2lT8SjFG4wPWWfZ7K83fTk5z7RG4qhKuA8kwIi+ahvVlMpRENsnFHTd6UQ
M1Nu+bAqkhyR9mF653J5wqKpFG5ISA5p/0EKY13jHaoUPIQ0Q14phbg0GXtYKuYJ4HxfCuTAENFR3ef5tuhMGaVwTsFOfbY0Xl9i
SB7+TYoXyOTO7k1X75kUWCg6x+v+EfPeDFIYn92zkmVPpTBdB+DWsCAa1yi75ooxI+5Og3fxG4mUgvi4i8ATK/lHKaz7AI5cMC7H
IF/nnCGvlAKdAdYVfOtttN2XpY/ilH9XCtUazX63uJXzvLRf2bmbVlWBOI7j/OC/lCYnWiga5QOYnNBbDkdo2TrctHfR+38P1wd0
8F7PvQSeTsfz/+xiTKagLzUNM1cm7eLKxbwT3Q9obclaR75r0lG015iuUsWm/eTZVzJv8rxaUo6xKESetS7Pqytd2oGjEfp+F4Xs
SKYjw/d+T/x4FFK9rCG2tFyszsqJT3TJul1HkXFN69u0FUBAnvTtLgrqTnGhil/1C/o7CmfDSpV0F8Z+R7cSeg7xPJfFJo0C9m0U
spgqxiKrRkl+GAVgvaTK3QH7PkxqLb1FIdAJT1QxtgFqwTvVTikaQWxQZSMxGoW3ZFePW1u0iupR3EUBoopJ5arwryjYxhW9/EQN
awW9FVFuminl/X+PRE4XBWTNDI1Lgj+joO/n5ciJdnoOwfI+xyWFzzrN2Q4LHxWh7P9cmOf+LN/YnyeJChmgkx2KUAn0fDeyMaKJ
AiCk+4ZelocJMHhyhlHDTUqactbbWykAHQUgi/IEPbsoA2hCFlJgYDDYziBRAXoOpZgjPuKdfRkdhQnorwoDOgrT21jz3F7DUWCP
es0oYE/uc6NQ0kx/93IU2KNeNArByXtqFIRnYp44CuxRLxoFlOQ+MwrpTI9t5SiwL+eYNqYRnvGR/QJTCw+YKY4CY4yjwBjjKDDG
OAqMMY4CY4yjwBjjKDDGNI7Cb/bq2AaiGASC6CfYFhy4AJdABS6K8i9GIkU6o3ktLBoAJEQBQEIUACREAUBCFAAkRAFAQhQAJEQB
QPKHUTh+hYddP1Zj8cmun6YobBee59sqLD6c75YouGJ9eNoKuVVYfLQV8o4oHMWH54WOFVh8uNBpiIKLrzHAkluBxYdb8oYoXH0Y
QNcKLD6dbkMUxImMIFmBxX/s1M1LAmEQgPEhEMZ38SZePHjcQ+B9l9AQbMuDeLEvPyCJhYTs0E2Eutml/zkTSYZA2trMted3mPf0
Xgbm2Xe5HFEAUQBRAFEAUQBRAFEAUQBRAFEAUcAHogCiAIMogCjAIAogCjCIAogCDKIAogCDKIAowCAKIAowiAKIAgyiAKIAgyiA
KMAgCiAKMIgCiAIMogCiAIMogCjAIAogCjCIAogCDKIAogCDKIAowCAKIAowiAL2JQr+mS+7zX3rkwvmJeecZBVR+J/+PArBY2t2
pxq3BrI9g9GDJPE8epWk3Gl7qqrDSqPkJGXO9QufYjP2ZGG3o1COVsLAT2Er1agvG/lRWZC1KDRj1fikdrx46p5sy4U+ffX6lrOn
N5JQeaSqs177tqvdTlVSNb6aqg6P7p2pa7e+mDsehdBbK4ROfqjonctGgRcKMhaFmurhfNn8l7xWAtlo+1Fw+ckqCk1JZl7RuFmS
hagx1ZGkqRBrt9WpqE4CWbvWTEQhLL679IOS5x24X4tC9Mbe+fS0jYRx+CdLSC8z8s3yxYfc8AEpR6RYIUVowZRUKBKKCRAsQMTg
bQkUBCwhbaEgtZf9zhuPPR67uNm2u9pNWj8H2vnjGSN4H8/MmwSNlVKYTikcUHiGBKdCq/iPsB0L3wJr9xDRdIzvnCCgIx0Jfw5o
Gf8eziy1mgD0ekY2bD+YDikojxka99g/3kehGJNXMWKaT3S+i59HClaQfQLzkPYxWQgp/AjXdGQhZYHaNfxrbNN5FRF8QAuIMPS9
1oCmTQpgHu/iX0dJoWQapXBCR1CwC1rERMFGUkhOFTJ1LFNQNTlqg/whxDndZ/urkVhBeoONHRosoHeIuU2WChs0YvqkAJvrDCml
FCaF/1MKbEA3yLC3ewmB+abRG64uzkFwvQz7tjEc9tcAvJ7vB+cn2viGgxZHjNW6T3piZXM05rWBiNetOwjY5w/1wdHuHSRXh/Ug
qD+8FLfRaoWDVjTCSus9ADHLRTDbeOMinefVcj3on5jIsUjDKjLc7c5jxMvWGuauV0UkG7fbowuX71hskdYpBNFc0eROawfs4GIY
XFw+IscrqiDBo4GY5mpmxNb0SQEmt9K0hKebapNmmZqumRYkRs3UNLPLIGg6DJbjGWBOEyM6Tges5umaW6siouto3HUcwHDs3ASi
FFdXa6buuR2UTJQU5mhgoIA/AgqPzn3yd+LDvnbngoL1SkiLWGiHlf6Qeu/HNmzTK8Q4IlJYz7d2abheH9ALR0QzzcexeEHUW+8R
rdtAfCVV1kfjhAcA9n2fyPf7YB/oLSJO2+SfH4U0jMcfzbMYBi9eBDS8QpZVukYBe9S680kcnWhL0cwB0YMRh/c6Eh5oIfIH7Zp9
ai8t+RRuIcsltSCZpZeQHE+hFGo81iszOfdMneuWlIUeFdMzB3vUZHppu8mjC7gBxk0hCV4ztKgH57r4QWo657quA1ayQamOmrRo
RCc2BK/Z+miK0UVlynKypLBDRyhgLQy3mgDbC2hexH64G0Qx+TgIDwbrrngQN8Y0FEkhPBo+AWhuUj0jBadHDS+yUI8+ImKelkQu
ZMX336kzhVQKyxTcVQH7NGz/FkthqX1fjWQR+iYyVOiuWArbQbD1ygLeBXRoRrG/RC9YsRRWz/0bA2D3fv6Y8mNmZ9KgmamWgsE1
jGAadxkAW+NNjOhyswqgYybOaMaxzhyuG7EURjaxqlBScHStA4C5nNtq+5BKoarHExgeN+NqjzvJjJP+urlv5GeRwnVhusHohWsQ
vAvaehT7FOgYwTaIdhlG2L5vj2kokAIN3iOCVehPJYVl2mbx2oToCoDhBw4SO+w8l8LLMPgEwR1VmJACrUHwIb808Gm/WAp+72US
2ScQ2H06KJZC2H6E4DFsm1BciHbZ93SqpcC4Htd2k7KuCUXyKhJZMFGrG3IFYcb/6NEFSgoqu1njXoEUXO5ALkksUS0rLG6iZIKk
sEwPeM4prSprbIvYX5TnavQ7Itg6zY1pKJLCImS0b6VSMP1BEwJ2KCreUiPNF7SeS6FBb1RkngkprKf9d6EwiSwIjDmJIaRAnxGx
HwYdma+kWVYoBdpUNSdQLNHrzFbizVRLAZwzoJqGNGqiXedJuavbWWfA4DoTEa/hCylYSPC4nZeCukytTSxVwXWUTJQUlvEM1qc9
9SK9gYj9YwiuqJdeuTCmoUgKc+mWZTGVwhu1O3duokmd1y5iZqjxTAqs7deQ8ES7QgpbiNGpD4VGA8R8IokmpBAYyUHkZTbIi6XA
kfA7zUJRIR3Kmos/gRSECbJRbuZ6KUcAtsVke04KGiRN7jyTQld8ldIwcusDjaNkgqSwRY0CKQzJhkCEfS36YkHwmi7S2F/DmIYC
KTDErNC8lALbpBUUYc/d9gqkwKkOiUkVIYU7xHTpHBnabZb024zxEyn05bnAb5CIuyiQQoAYYaPcSuETJPNTvlJgnIvFvaEEED/I
uWtkOnnIY/LOF1IwIelw85kUXG5D4nJLVgu8UgoTJYW1ol/jThhkVw3H0XkiZOw38lLINoyXQhsFUujLhbjCWjk5D4jIL5DCHW0g
pe0LKfxRLIVZ+h05pBQaMrD3M+nL60Ip1LOLAyd7pvAKkhbdTLUUDK6LuNRSRAWaUfbAtJgK8xymtIg6aMwqJC8FuTpQGxSRfSil
MJlSeE09ZGG6zuBkKtkuLUSZx69Jof3NUvCLpHBEHHnOAgpnVzevH68KpLBCh0gZhEykJIulsEozedfI7cODjHIts16aL5TCOlJ6
qnt+lXE45dmH+BGvcTODMEG1GeUPk5wEd59JgeWlkBmVca1ACtWMFLqlFCZYCrWQjpHhiYYMLGgzJLA6ef9MCvvjpLBKb5FjjQb3
djxigRTmqJ+J8h7GSKFFJ8jyJKXQgmA3M/MJ3eSksJFIoZcxUJtlx76FpE6PUy0Fl3eTB38BRk3jWhUwuPm3UnDVVQXbB5N3Mndg
lVKYYCmgQdtQsEYUSuyc9LRmMMCPSeEP+e2Nk0KLdiDZuYm6pWecCwVSMMIhJFd0MU4Ke+R7yHD4hRQuM8v+1SiwTSWcukxJGkgH
7+XMtYGEZjgwplkKVc4NueUvxOS1+NkvMSyjUAqm0jV3Cw4arex5RCmFSZbCFfk1pOy36bU4/rtWEbz0I1LAPB3I8hgpZN9s/IrW
gZo63FsskAJm1W6+RcvjpIAL2oRizv9CCp/VwsBst5sACytyJF9mH05VjqEBhe0PjFR5G5hmKZjcVK9rFBieC9iagxg72V7YmZAu
lAI3VI/mMylkkg2M66yUwiRLAX2qu0hoztJD7IbAhoAt0dMPSWFH5jqdwTgp2AEdp69TuAXs9IyzVimSwg6tMghc3zfHSmEuDO8Z
EqxK2MtLodOj43xedtjuykWElMLQTi4f0hwybNCtHJdWplgKzOV6VfxHT/f8Lq/JwFV7gRo3IcsaiqXgqh7smRSYlkrD4Q5KKUy0
FPaHNOslgV2no458ursicjboAj8kBdMf6CK218NxUsAWBXFs3lLFBlg9XmEwsx+KqcEGw4wUsEStalTUZ+kaY6WAB6JDm4mLr3q0
uCGloI5PjlnU+IaCWnyy0BLlFT+UKcnw3GEAc5doF1n+9MM70XeTluyplUK15nHdlod/moGIZhzSJncz2wcwLSkzj1vFUtCS/IOh
85pMUCgpwJYT1LjOSilMthRg1sk/PFtY2PoYph/HZq3S8ORg7aRCS9qPSQGXNJhf+W2+F96PlYLRIv/j6d7pOg1EinAvbD+s7d1u
+5UF8k8XAFahrbczqRQ+zVJ9fuZmM6Bto1gKiq02BRtvFu7u+yFdMiWFhPnQ3759WuzTUIwMd0Drt3u3H2npUL4has0fnqycfRhS
30GOvba/u7W384IqDqZOCpoZ4WmcR4Gq1gyOZTdNrhvJ0sFr2nbTS94R1dG417Wtrs7dr2UfulGPpqtzR07k2jaTUkCX627Trplc
66CUwoRLAfZhmwTBDVJuAhrhX1bxg1LAvRj1aGFMSlJwNqSIDQ+CtZ64lQebrZJY1t/4RMNUCugs+zRieAB8IYXnETlXD0lwPgM0
UilIHo9oRHvXRYx+Lr7nlv0gpYA/xAhBqwpF5jZp3UNOCn2MmHQpJOiea0PR1EStW4Wg6uY/xZGZuihb+JoUkhE0CzFGdEE1lQI6
Ho8wGUopTLwUgNrB9cn1zDuGLHzl7BPDP8C+ujk7xjfgPN2+dSBhj2ef38cxfRVvSlc+f0IGNnez5uHbqL3dOrk+e/nVme92jg0o
tKfbJ6kIIQXA2rvJza5u8+B07T2KmGwpfB1mWx2WLTZzZRiWZaAAKQWAWRaDYjTelxMY5WezTYkUSvIoKfzvTMvffYikUFJK4Wen
lEIphe+hlMIvQCmFUgrfQymFX4BSCqUUvodSCr8ApRRKKXwPpRR+AarvJuBjwqZFCqxaphVKKZR8hV9TCiWlFEq+RimFklIKJTlK
KZT8xd4d2zAMA0EQNINvwYEKUAmsgEWpfAPODmAmGZDomRaOWIYvCgRRQBQIooAoEEQBUSCIAqJAEAVEgSAKiAJBFBAFgiggCgRR
QBQIooAoEEQBUSCIAqJAEAVEgSAKiAJBFBAFgiggCgRRQBQIooAoEEQBUSCIAqJAEAXuGoXhiSyhRpuw+Opq/CAKvW5w3oiz3tXb
hMUXF7tfFoW9jhePd9TeJiy+uNj9TBRSr8PP8XDvo3qbsfjSYvcro7D14vH61mYsvri+XRaFtPdRPNjoe5uz+Mq+u5+OAvAHRAH4
sGf3KA5CURiGj1xwBelsLcU9pM4GXJGgCCnsUoWsdGCayWV+0Grg3ufZw3n54IgCIAqAKACiAIgCIArAF1EAMqIAZEQByIgCkBEF
ICMKQEYUgIwoABlRADKiAGREAciIApARBSAjCkBGFIDMf0bh2gDFu56IQtcAxesOR2FIYwMUb0xDHNOnMBWgeF2kPo6ZUsStAYp2
i0hTHNRH2ApQtu7z1E8ZOz8IKNS1G+OkPgGF6+OUqR8SUKyhnwIAAAAAAAAAAAAAAAAAAAAAAAAAAKAil23Z19c8v9Z92S4B1G17
zu2b+bkFUK/72n6z3gOo02Nvf7Q/AqjQfW5/MRsLUKGl/cMSQGWWtlUF4L0JqsBHe3eM4yAMRAHUlU+Qk3EoS1iWKOioEEXOuVuE
KJFBoUmTee8QXx6PxwNPU/7IvQIEspT8UdGDgDi6XmRd7rXrTCYgiL54GFIaFRAQVj0KhaGrH2oCQmj5OBQ65iAghvVqKKwJCOBW
roZCMUkNEbR8EgrqB4hpPAkFzxohqPk8FDxVgIjq9VDQlIQItryr4/DQUmrDrubdloDfV/Lung4teVcS8PuEAqB8AFw0AlqSgMdL
gGfOwD8DUcAro9PAO5+sAJ0vf8emIQlh+bgV8MU7YBkMYG0cYMEsYBU98AWjTADeTCWfKO4TIKRlzodmfQeIaqq5Ux0TILK2lvyi
rOYdILpbG+e6lbLVeWxmpQEAAAAAAAAAAAAAAAAAAAAAnv4AUkeO1EyMA1cAAAAASUVORK5CYII=
`;