import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TargetHierarchyConfig, ConfigTargetHierarchyConfig } from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { ElementOptionType } from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import { useIntl } from 'react-intl';
import InputElementSelect from '../../../../../atoms/react-hook-form-input-elements/input-element-select/InputElementSelect';

const TargetHierarchyConfigFormComponent: FC<
  ConfigFormComponentProps<TargetHierarchyConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } = useForm<ConfigTargetHierarchyConfig>({
    defaultValues: configValue,
  });

  const intl = useIntl();

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const taxonomyOptions: ElementOptionType[] = elements
    .filter(
      (element) => element.type === SETTINGS_ELEMENT_TYPES.TARGET_TAXONOMY
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='taxonomy'
        control={control}
        render={({ field }) => (
          <InputElementSelect
            value={
              field.value.source === 'input-element'
                ? field.value.elementUuid
                : ''
            }
            isInputElement={field.value.source === 'input-element'}
            onChange={(newValue) => {
              field.onChange(newValue);
              updateConfig();
            }}
            options={taxonomyOptions}
            label={augurElementsMessages.taxonomyLabel}
            placeholder={augurElementsMessages.selectTaxonomyElement}
          />
        )}
      />
    </div>
  );
};

export default TargetHierarchyConfigFormComponent;
