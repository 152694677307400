export function addNewAugur(summary, newAugur) {
  const habitatIndex = summary.findIndex(
    (habitat) => habitat.code === newAugur.habitatCode
  );

  if (habitatIndex === -1) {
    return summary;
  }

  const habitat = {
    ...summary[habitatIndex],
    augurs: [...summary[habitatIndex].augurs, newAugur],
  };
  return [
    ...summary.slice(0, habitatIndex),
    habitat,
    ...summary.slice(habitatIndex + 1),
  ];
}

export function addNewHabitat(summary, newHabitat) {
  return [...summary, newHabitat];
}
