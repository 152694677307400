import { REPO_TYPE } from 'common/dist/types/repository';

import {
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldName,
  fieldType,
} from './repository.form';

// do not allow .asr, otherwise all hell will break loose
const directoryRegex = /^[a-zA-Z0-9-]+$/;

export function validate(allValues, formProps) {
  const { contentInCurrentDir } = formProps;
  const errors = {};

  errors[fieldName] = validateRepositoryName(
    allValues[fieldName],
    contentInCurrentDir
  );
  errors[fieldDescription] = validateRepositoryDescription(
    allValues[fieldDescription]
  );
  errors[fieldType] = validateRepositoryType(allValues[fieldType]);
  errors[fieldCodeCapsuleHabitat] = validateRepositoryHabitat(
    allValues[fieldCodeCapsuleHabitat],
    allValues[fieldType]
  );

  return errors;
}

/**
 * Validates the repository name
 * 'undefined' = valid
 * @param value
 * @param contentInCurrentDir
 * @returns {{id: 'no-id', defaultMessage: string}|undefined}
 */
export function validateRepositoryName(value, contentInCurrentDir) {
  // No empty names
  if (!value || !value?.display) {
    return { display: { id: 'no-id', defaultMessage: 'Please enter a name' } };
  }
  // Maximum length of 255 characters
  if (value && value.length > 255) {
    return {
      id: 'no-id',
      defaultMessage: 'At most 255 characters are allowed',
    };
  }
  // Specify the allowed characters
  if (!directoryRegex.test(value?.slug)) {
    return {
      slug: {
        id: 'no-id',
        defaultMessage: 'Please enter valid name / remove invalid characters',
      },
    };
  }

  if (contentInCurrentDir.map((c) => c.name).includes(value?.slug)) {
    return {
      slug: {
        id: 'no-id',
        defaultMessage:
          'Directory/Repository with the given name already exists in this directory.',
      },
    };
  }
  return undefined;
}

/**
 * Validates the repository description
 * 'undefined' = valid
 * @param value
 * @returns {{id: 'no-id', defaultMessage: string}|undefined}
 */
export function validateRepositoryDescription(value) {
  // No empty descriptions
  if (!value || value.trim().length === 0) {
    return { id: 'no-id', defaultMessage: 'Please enter a description' };
  }

  // Maximum length of 255 characters
  if (value && value.length > 255) {
    return {
      id: 'no-id',
      defaultMessage: 'At most 255 characters are allowed',
    };
  }

  return undefined;
}

/**
 * Validates the repository type
 * 'undefined' = valid
 * @param value
 * @returns {{id: string, defaultMessage: string}|undefined}
 */
export function validateRepositoryType(value) {
  if (!value) {
    return { id: 'no-id', defaultMessage: 'Please select a repository type' };
  }

  return undefined;
}

/**
 * Valides the selected habitat
 * 'undefined' = valid
 * @param value
 * @param selectedType
 */
export function validateRepositoryHabitat(value, selectedType) {
  if (selectedType !== REPO_TYPE.CODE_CAPSULE) return undefined; // Only relevant for code capsules

  if (!value) {
    return { id: 'no-id', defaultMessage: 'Please select a Habitat' };
  }

  return undefined;
}
