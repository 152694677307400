import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { S3BucketSelectV1AugurSettings as S3BucketSelectAugurSettings } from 'common/dist/types/augurSettings/s3BucketSelectV1AugurSettings';
export type { S3BucketSelectAugurSettings };

export type S3BucketSelectConfig = Record<string, never>;
export type ConfigS3BucketSelectConfig = Config<S3BucketSelectConfig>;

export type PartialS3BucketSelectAugurSettings =
  Partial<S3BucketSelectAugurSettings>;

export const configAllowedInputsS3BucketSelect: AllowedInputs<S3BucketSelectConfig> =
  {};

export const configTypeS3BucketSelect = `{}`;

export const augurSettingsTypeS3BucketSelect = `{
  dataSourceCode: string,
  bucketName: string
}`;

export const defaultConfigS3BucketSelect: ConfigS3BucketSelectConfig = {};

export type AugurSettingsDefaultS3BucketSelect =
  PartialS3BucketSelectAugurSettings;
export const defaultAugurSettingsDefaultS3BucketSelect: AugurSettingsDefaultS3BucketSelect =
  {};

// JSONSchemaType<S3BucketSelectAugurSettings>
export const defaultValidationSchemaS3BucketSelect = {
  type: 'object',
  required: ['dataSourceCode', 'bucketName'],
};
