import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';

import styles from './styles.module.scss';
import { TimelineEntry } from './types';
import Button from '../../atoms/button/Button';
import { FormattedRelativeTimeConvenient } from '../../atoms/formatted-date-time/FormattedDateTime';
import { AugurCategory } from '../../molecules/augur-menu/types';

// Maximum number of highlighted entries to display
const MAX_HIGHLIGHTED_ENTRIES = 8;

interface TooltipState {
  type: 'job-code' | 'max-entries' | null;
  content: string;
  entryId: string | null;
}

export interface TimelineEntryProps {
  entry: TimelineEntry & { gridRow: number };
  isOutOfView?: boolean;
  isAbove?: boolean;
  highlightedRefsMap: React.MutableRefObject<Map<string, HTMLDivElement>>;
  entriesWithGridRow: (TimelineEntry & { gridRow: number })[];
  scrollToElement: (id: string) => void;
  setTooltipState: React.Dispatch<React.SetStateAction<TooltipState>>;
  tooltipState: TooltipState;
  selectedPageCategory: AugurCategory;
}

const TimelineEntryComponent: FC<TimelineEntryProps> = ({
  entry,
  isOutOfView = false,
  isAbove = false,
  highlightedRefsMap,
  entriesWithGridRow,
  scrollToElement,
  setTooltipState,
  tooltipState,
  selectedPageCategory,
}) => {
  const Icon = entry.Icon;
  const entryStyle = isOutOfView ? {} : { gridRow: entry.gridRow };

  useEffect(() => {
    if (
      tooltipState.type === 'max-entries' &&
      tooltipState.entryId === entry.id
    ) {
      const timer = setTimeout(() => {
        setTooltipState({ type: null, content: '', entryId: null });
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [tooltipState, entry.id, setTooltipState]);

  return (
    <div
      data-testid={`${entry.category}`}
      id={`entry-${entry.id}`}
      data-tooltip-id='timeline-tooltip'
      onMouseEnter={() =>
        setTooltipState({
          type: 'job-code',
          content: `Job Code: ${entry.jobCode}`,
          entryId: entry.id,
        })
      }
      onMouseLeave={() =>
        setTooltipState({ type: null, content: '', entryId: null })
      }
      key={`${isOutOfView ? 'highlighted-' : ''}${entry.id}`}
      data-id={entry.id}
      data-highlighted={entry.highlighted}
      ref={(el) => {
        if (el && !isOutOfView) {
          highlightedRefsMap.current.set(entry.id, el);
        } else {
          highlightedRefsMap.current.delete(entry.id);
        }
      }}
      className={classNames(styles.rowContainer, {
        [styles.selected]: entry.selected,
        [styles.selectable]: !!entry.onSelected,
        [styles.highlighted]: entry.highlighted,
        [styles.highlightedOutOfView]: isOutOfView,
      })}
      onClick={
        entry.onSelected
          ? (e) => {
              e.preventDefault();
              if (
                entriesWithGridRow.filter(
                  (row) => row.selected && row.category === entry.category
                ).length >= MAX_HIGHLIGHTED_ENTRIES &&
                !entry.highlighted
              ) {
                setTooltipState({
                  type: 'max-entries',
                  content: `You have already ${MAX_HIGHLIGHTED_ENTRIES} selected entries in the ${selectedPageCategory} category`,
                  entryId: entry.id,
                });
              } else {
                entry.onSelected(!entry.selected);
              }
            }
          : isOutOfView
          ? (e) => {
              e.stopPropagation();
              void scrollToElement(entry.id);
            }
          : undefined
      }
      style={entryStyle}
    >
      <span className={styles.content}>{entry.name}</span>
      <Icon className={styles.jobsIcon} />
      <span className={styles.timestamp}>
        <FormattedRelativeTimeConvenient date={entry.timestamp} />
      </span>
      {isOutOfView && (
        <Button
          className={styles.scrollButton}
          Icon={() =>
            isAbove ? <FiChevronsUp size={20} /> : <FiChevronsDown size={20} />
          }
          form={'bubble'}
          onClick={(e) => {
            e.stopPropagation();
            void scrollToElement(entry.id);
          }}
          title={'Scroll into view'}
        />
      )}
    </div>
  );
};

export default React.memo(TimelineEntryComponent);
