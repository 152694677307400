import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { updateFirstName, updateLastName } from './addUser.form';
import styles from './styles.module.scss';
import InputError from '../../../atoms/input-error/InputError';

export default class UserDetailsStepComponent extends Component<WrappedFieldProps> {
  render() {
    const {
      input: { value, onChange, onFocus, onBlur },
      meta: { touched, error },
    } = this.props;
    return (
      <Fragment>
        <div className={styles.formGroup}>
          <div className={styles.header}>
            <label className={styles.formLabel}>
              <span>First Name</span>
            </label>{' '}
            <div className={styles.textInputLineError}>
              {error?.firstName && (
                <InputError touched={touched} error={error.firstName} />
              )}
            </div>
          </div>
          <input
            data-testid={'addUserFirst'}
            className={classNames({
              [styles.inputContainerError]: touched && error?.firstName,
            })}
            type={'text'}
            value={(value || {}).firstName}
            onChange={(e) => onChange(updateFirstName(value, e.target.value))}
            onFocus={onFocus}
            onBlur={(e) => onBlur(updateFirstName(value, e.target.value))}
          />
        </div>
        <div className={styles.formGroup}>
          <div className={styles.header}>
            <label className={styles.formLabel}>
              <span>Last Name</span>
            </label>{' '}
            <div className={styles.textInputLineError}>
              {error?.lastName && (
                <InputError touched={touched} error={error.lastName} />
              )}
            </div>
          </div>
          <input
            data-testid={'addUserLast'}
            className={classNames({
              [styles.inputContainerError]: touched && error?.lastName,
            })}
            type={'text'}
            value={(value || {}).lastName}
            onChange={(e) => onChange(updateLastName(value, e.target.value))}
            onFocus={onFocus}
            onBlur={(e) => onBlur(updateLastName(value, e.target.value))}
          />
        </div>
      </Fragment>
    );
  }
}
