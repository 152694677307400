import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MetricsResponse } from 'common/dist/types/metrics';

import { apiRequest } from '../_tools';

export function useMetricsHook(
  notebookUser: string
): UseQueryResult<MetricsResponse> {
  return useQuery<unknown, unknown, MetricsResponse>(
    ['workbench-metrics'],
    () => queryFn(notebookUser),
    {
      refetchInterval: 10000,
    }
  );
}

async function queryFn(notebookUser) {
  const { response, error } = await apiRequest(
    `/jupyter/user/${notebookUser}/proxy/3040/metrics`
  );
  if (error) {
    throw Error('Unable to fetch' + error);
  }
  return response;
}
