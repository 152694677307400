import { Buffer } from 'buffer';
import { Branch } from 'common/dist/types/repository';
import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  useBranchesByModuleCode,
  useModuleByCode,
} from '../../../../core/api/modules';
import { useUserSummary } from '../../../../core/api/users';
import { useFile } from '../../../../core/api/workbench/collab';
import Busy from '../../../atoms/busy/Busy';
import RepositoryContent from '../../../molecules/repository-content/RepositoryContent';
import RepositoryFilePreview from '../../../molecules/repository-file-preview/RepositoryFilePreview';
import RepositoryHeader from '../../../molecules/repository-header/RepositoryHeader';
import { moduleDetailsLink } from '../../routes';

export type Props = {
  moduleCode: string;
};

const ModuleFiles: FC<Props> = (props) => {
  const { moduleCode } = props;

  const [activeBranch, switchBranch] = useState<Branch>();
  const [selectedFile, setSelectedFile] = useState<string>();
  const {
    isInitialLoading: isLoading,
    error,
    data: module,
  } = useModuleByCode(moduleCode);
  const userHook = useUserSummary(module?.createdBy ?? undefined);
  const branchesHook = useBranchesByModuleCode(moduleCode);
  const {
    isInitialLoading: userLoading,
    isError: userError,
    data: user,
  } = userHook
    ? userHook
    : { isInitialLoading: false, isError: false, data: undefined };
  if (isLoading || userLoading) {
    return <Busy isBusy />;
  }
  if (!module.repository) {
    return <Redirect to={moduleDetailsLink(moduleCode)} />;
  }

  const branches = branchesHook.data;
  if (!activeBranch && branches) {
    switchBranch(branches[0]);
  }
  const options = (branches || []).map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));
  const repoDescription = module.repository.repoDescription;
  const repoFullName = module.repository.repoFullName;

  const parts = (repoFullName || '').split('/');
  const group = parts[0];
  const nameShown = parts[1];

  const { data: file, isInitialLoading: fileLoading } = useFile(
    group,
    nameShown,
    selectedFile
  );
  let content: string;
  if (file && file.content) {
    const encoding: BufferEncoding = file?.encoding as BufferEncoding;
    content = Buffer.from(file.content, encoding).toString('utf8');
  }
  return (
    <div className={'CollaborationSpace--content'}>
      <div className={'repository'}>
        <RepositoryHeader
          repositoryType={'module'}
          loading={isLoading}
          error={error}
          data={module?.repository}
          branches={branches}
          activeBranch={activeBranch}
          switchBranch={(branchName) =>
            switchBranch(branches.find((branch) => branchName === branch.name))
          }
        />
        <RepositoryContent
          setSelectedFile={setSelectedFile}
          activeBranch={activeBranch?.name}
          repoFullName={module?.repository?.repoFullName}
        />
        <RepositoryFilePreview
          selectedFile={selectedFile}
          fileLoading={fileLoading}
          content={content}
          file={file}
        />
      </div>
    </div>
  );
};

export default ModuleFiles;
