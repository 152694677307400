import { connect } from 'react-redux';
import DirectoryStep1 from './DirectoryStep1';
import { getFormInitialValues } from 'redux-form';
import {
  addDirectoryFormName,
  fieldDirectoryName,
} from '../AddDirectoryWizard.container';

function safeDirectoryName(state) {
  return state.form?.directoryWizard?.values?.directoryName || '';
}

function safeDirectoryTouched(state) {
  return state.form.directoryWizard?.fields?.directoryName?.touched;
}

function safeDirectoryError(state) {
  return state.form.directoryWizard?.syncErrors?.directoryName?.message;
}

export function mapStateToProps(state) {
  return {
    directoryName: safeDirectoryName(state),
    directoryNameTouched: safeDirectoryTouched(state),
    directoryNameError: safeDirectoryError(state),
    isEditing: state.workbench.showEditDirectory?.path !== undefined,
    initialName:
      getFormInitialValues(addDirectoryFormName)(state)?.[fieldDirectoryName],
  };
}

export default connect(mapStateToProps)(DirectoryStep1);
