import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import vars from '../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../atoms/button/Button';

export default class GitListCommits extends Component {
  renderLoaded() {
    const { data, repositoryPath, gitListCommits, activeBranch, unpushedData } =
      this.props;
    const unpushedShas = unpushedData ? unpushedData.map((d) => d.hexsha) : [];
    return (
      <div className={'git-list-commits'}>
        <div className={'refresh-button-container'}>
          <Button
            Icon={() => <FiRefreshCw size={16} />}
            color={'transparent'}
            onClick={() =>
              gitListCommits(repositoryPath, activeBranch, 20, 0, false)
            }
          />
        </div>

        {data && data.length > 0 && (
          <div className={'commit-list ct-list'}>
            <div className={'ct-headline ct-row'}>
              <div className={'ct-col ct-col-flex-grow'}>Message</div>
              <div className={'ct-col ct-col-200px'}>Timestamp</div>
              <div className={'ct-col ct-col-200px'}>Comitter</div>
            </div>
            {data.map((commit) => (
              <div className={'ct-row'} key={commit.hexsha}>
                <div className={'ct-col ct-col-flex-grow'}>
                  {commit.message}
                  {unpushedShas.includes(commit.hexsha) && (
                    <span className={'unpushed-label'}>
                      <i> unpushed</i>
                    </span>
                  )}
                </div>
                <div className={'ct-col ct-col-200px'}>
                  {commit.committed_date}
                </div>
                <div className={'ct-col ct-col-200px'}>{commit.committer}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no commits for this repository yet
      </div>
    );
  }

  renderError() {
    const { error, repositoryPath, gitListCommits, activeBranch } = this.props;
    return (
      <div className={'git-list-commits'}>
        {error}

        <div className={'refresh-button-container'}>
          <Button
            Icon={() => <FiRefreshCw size={16} />}
            onClick={() => gitListCommits(repositoryPath, activeBranch, 20, 0)}
          />
        </div>
      </div>
    );
  }

  render() {
    const { loading, loaded, error } = this.props;
    if (loading) return this.renderLoading();
    if (loaded) return this.renderLoaded();
    if (error) return this.renderError();
    return this.renderEmpty();
  }
}
GitListCommits.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      committed_date: PropTypes.string.isRequired,
      committer: PropTypes.string.isRequired,
      hexsha: PropTypes.string.isRequired,
    })
  ),
  error: PropTypes.string,
  repositoryPath: PropTypes.string.isRequired,
  activeBranch: PropTypes.string.isRequired,
  gitListCommits: PropTypes.func.isRequired,
  unpushedData: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      committed_date: PropTypes.string.isRequired,
      committer: PropTypes.string.isRequired,
      hexsha: PropTypes.string.isRequired,
    })
  ),
};
