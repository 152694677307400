// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/feature-importance/v1/type.ts' --type 'ConfigFeatureImportanceConfig'
export const schemaConfigFeatureImportance = {
  $ref: '#/definitions/ConfigFeatureImportanceConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<FeatureImportanceConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigFeatureImportanceConfig: {
      $ref: '#/definitions/Config%3CFeatureImportanceConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'FeatureImportanceV1ReportData'
export const schemaReportTypeFeatureImportance = {
  $ref: '#/definitions/FeatureImportanceV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    Feature: {
      additionalProperties: false,
      properties: {
        discarded: {
          items: {
            type: 'string',
          },
          type: 'array',
        },
        feature: {
          type: 'string',
        },
        featureType: {
          enum: ['numerical', 'categorical'],
          type: 'string',
        },
        importance: {
          type: 'number',
        },
      },
      required: ['feature', 'importance', 'featureType'],
      type: 'object',
    },
    FeatureImportanceV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        featureImportanceCalculatedWith: {
          type: 'string',
        },
        features: {
          items: {
            $ref: '#/definitions/Feature',
          },
          type: 'array',
        },
      },
      required: ['features', 'featureImportanceCalculatedWith'],
      type: 'object',
    },
  },
};
