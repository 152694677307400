import classNames from 'classnames';
import { BiographyEntry } from 'common/dist/types/augurBiography';
import React, { FC } from 'react';

import styles from '../styles.module.scss';
import { JobTypeToSpeaking } from 'common/dist/types/job';

type Props = {
  entry: BiographyEntry;
  isReactivated?: boolean;
};

const CommonEntryParts: FC<Props> = (props: Props) => {
  const { entry, isReactivated } = props;
  return (
    <div className={styles.jobTypeContainer}>
      <span className={styles.jobType}>
        {isReactivated
          ? 'Model Reactivation'
          : JobTypeToSpeaking[entry.jobType]}
      </span>
      {entry.error && (
        <span className={classNames(styles.errorIcon, 'icon-alert')} />
      )}
    </div>
  );
};

export default CommonEntryParts;
