import classNames from 'classnames';
import * as d3 from 'd3';
import { HierarchyLink, HierarchyPointLink } from 'd3-hierarchy';
import React from 'react';
import { Transition } from 'react-transition-group';

import { BinaryTreeState } from './BinaryTreeShadowModel';
import { TreeNode } from './Node';
import { Path } from './TreePath';

export function isLinkHighlighted(path: Path, link: HierarchyLink<any>) {
  return !path.find(({ id }) => link.target.data.id === id);
}

type LinkProps = {
  link: HierarchyPointLink<TreeNode>;
  state: BinaryTreeState;
  totalRecordCount: number;
  linkShapeFunc: (...args: any[]) => any;
  linkThicknessFunc: (...args: any[]) => any;
  animationDuration: {
    mount?: {
      delay: number;
      duration: number;
    };
    update?: {
      delay: number;
      duration: number;
    };
    exit?: {
      delay: number;
      duration: number;
    };
  };
};

export default class Link extends React.Component<LinkProps, {}> {
  linkRef: any;

  constructor(props: LinkProps) {
    super(props);
    this.linkRef = null;
    this.setLinkRef = this.setLinkRef.bind(this);
    this.handleComponentExit = this.handleComponentExit.bind(this);
  }

  componentDidMount() {
    const pathLength = this.linkRef.getTotalLength();
    this.linkRef.style.strokeDasharray = `${pathLength} ${pathLength}`;
    this.linkRef.style.strokeDashoffset = 0;
    if (this.props.animationDuration.mount) {
      this.animateLink(
        0,
        this.props.animationDuration.mount.delay,
        this.props.animationDuration.mount.duration
      );
    }
  }

  componentDidUpdate(prevProps: LinkProps) {
    const { link } = this.props;
    const { source: prevSource, target: prevTarget } = prevProps.link;

    if (
      link.source.x !== prevSource.x ||
      link.source.y !== prevSource.y ||
      link.target.x !== prevSource.x ||
      link.target.y !== prevSource.y
    ) {
      this.linkRef.style.strokeDasharray = null;
      this.linkRef.style.strokeDashoffset = null;
      if (this.props.animationDuration.update) {
        this.animateLink(
          0,
          this.props.animationDuration.update.delay,
          this.props.animationDuration.update.duration
        );
      }
    }
  }

  setLinkRef(ref: SVGPathElement) {
    this.linkRef = ref;
  }

  handleComponentExit() {
    const pathLength = this.linkRef.getTotalLength();
    this.linkRef.style.strokeDasharray = `${pathLength} ${pathLength}`;
    this.linkRef.style.strokeDashoffset = 0;
    if (this.props.animationDuration.exit) {
      this.animateLink(
        pathLength,
        this.props.animationDuration.exit.delay,
        this.props.animationDuration.exit.duration
      );
    }
  }

  animateLink(length: number, delay: number, duration: number) {
    const { link } = this.props;
    const d = this.props.linkShapeFunc(
      { x: link.source.x, y: link.source.y },
      { x: link.target.x, y: link.target.y }
    );
    d3.select(this.linkRef)
      .transition()
      .delay(delay)
      .duration(duration)
      .style('stroke-dashoffset', length)
      .attr('d', d);
  }

  render() {
    const {
      link,
      state,
      totalRecordCount,
      linkThicknessFunc,
      animationDuration,
      ...restProps
    } = this.props;
    const path =
      (state.previewNodePath.length && state.previewNodePath) ||
      state.selectedNodePath;
    const isHighLighted = isLinkHighlighted(path, link);
    const className = classNames({
      link: true,
      'link--highlighted': !isHighLighted,
    });
    const strokeWidth = linkThicknessFunc(
      (Number(link.target.data.recordCount) * 100) / totalRecordCount
    );
    return (
      <Transition
        timeout={{
          exit: animationDuration.exit
            ? animationDuration.exit.delay + animationDuration.exit.duration
            : 0,
        }}
        onExit={this.handleComponentExit}
        {...restProps}
      >
        <path
          ref={this.setLinkRef}
          style={{ strokeWidth: `${strokeWidth}px` }}
          className={className}
        />
      </Transition>
    );
  }
}
