import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { DeprecatedRootState } from '../../../../store/state.type';
import ContentElement, {
  ContentElementProps,
} from '../content-element/ContentElement';
import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';

export const sortContentFunction = (x, y) => {
  const typeX = scoreForType(x.type, x.name);
  const typeY = scoreForType(y.type, y.name);
  const name = nameComparison(x.name, y.name);

  if (typeX + name > typeY - name) return 1;
  if (typeX + name < typeY - name) return -1;
  return 0;
};

/**
 * Scores for the file types required for sorting.
 * The bigger the score, the lower it will be in the list.
 *
 * @param type
 * @param name file / directory name
 * @returns {number}
 */
function scoreForType(type, name) {
  if (type === ContentElementType.SPECIAL_ONE_DIRECTORY_UP) return 10;
  else if (type === ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN) return 10;
  else if (type === ContentElementType.RECYCLE_BIN) return 15;
  else if (type === ContentElementType.FILE_REPOSITORY_INFO) return 20;
  else if ([ContentElementType.DIRECTORY_REPOSITORY].includes(type)) return 30;
  else if ([ContentElementType.DIRECTORY_PLAIN].includes(type)) return 40;
  else if (
    [
      ContentElementType.FILE_DEFAULT,
      ContentElementType.FILE_CODE,
      ContentElementType.FILE_NOTEBOOK,
      ContentElementType.FILE_TEXT,
      ContentElementType.FILE_MARKDOWN,
    ].includes(type)
  )
    return 50;
  return 50;
}

function nameComparison(nameX, nameY) {
  if (nameX.toLowerCase() > nameY.toLowerCase()) {
    return 1;
  }
  if (nameX.toLowerCase() < nameY.toLowerCase()) {
    return -1;
  }
  return 0;
}

export type Props = {
  content: Omit<
    ContentElementProps,
    'isContextMenuExpanded' | keyof RouteComponentProps
  >[];
  hideRecycleBin?: boolean;
};

const ContentList: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { content, hideRecycleBin } = props;
  const expandedContextMenuPath = useSelector<DeprecatedRootState>(
    (state) => state.workbench.content.openContextMenu
  );
  const showHidden = useSelector<DeprecatedRootState>(
    (state) => state.workbench.settings.showHidden
  );

  return (
    <div className={'content-list'}>
      {content &&
        content
          .filter((c) => {
            // Never show the recycleBin as the directory it is.
            if (hideRecycleBin && c.name === '__recycleBin') {
              return false;
            }
            // Filter out an element if 1. hidden files are hidden 2. it starts with "." 3. but it is not the parent directory placeholder
            else if (
              !showHidden &&
              c.name.startsWith('.') &&
              ![
                ContentElementType.SPECIAL_ONE_DIRECTORY_UP,
                ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN,
              ].includes(c.type)
            ) {
              return false;
            }
            return true;
          })
          .sort(sortContentFunction)
          .map((contentElement) => (
            <ContentElement
              key={contentElement.path}
              {...contentElement}
              isContextMenuExpanded={
                expandedContextMenuPath === contentElement.path
              }
            />
          ))}
    </div>
  );
};

export default ContentList;
