import { ValueType } from 'common/dist/types/module.nbc';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import { isInteger } from 'lodash';

import { ParameterTuningErrorType } from './ParameterTuningSelection';
import { ParameterTuningAugurSettings, ParameterTuningConfig } from './type';

export const validateParameter = (
  parameter: {
    name: string;
    speakingName: string;
    description: string;
    validValues: ValueType[];
  },
  algorithmName: string,
  value?: ParameterTuningAugurSettings
) => {
  const validValues = parameter.validValues;
  const currentAlgorithmValue = value?.algorithms?.find(
    (algorithm) => algorithm.name === algorithmName
  );
  const currentParameterValue = currentAlgorithmValue?.parameters?.find(
    (param) => param.name === parameter.name
  );
  const currentValue = currentParameterValue?.values || [''];

  // To check if each value entered is correct the algorithm is
  // iterating through all values in valueSplit and checks for
  // each possibility by type to be valid. If one is found, the counter
  // ups by 1. If counter === amount values, the values are valid
  let isValid = false;
  if (currentValue?.[0] !== '') {
    let count = 0;
    currentValue.forEach((vS) => {
      let counted = false;
      validValues.forEach((vV) => {
        const type = vV.type;

        if (!counted) {
          if (type === 'double') {
            if (Number(vS) >= vV.min && Number(vS) <= vV.max) {
              count += 1;
              counted = true;
            }
          } else if (type === 'int') {
            if (
              isInteger(Number(vS)) &&
              Number(vS) >= vV.min &&
              Number(vS) <= vV.max
            ) {
              counted = true;

              count += 1;
            }
          }
        }
      });
    });
    if (count === currentValue.length) {
      isValid = true;
    }
  }

  return isValid;
};

export const validateParameterTuning = (
  value?: ParameterTuningAugurSettings,
  config?: Partial<ParameterTuningConfig>
) => {
  const errors: ParameterTuningErrorType = {
    global: '',
    algorithms: {},
  };
  if (errors.algorithms) {
    config?.parameterTuningSchema?.availableAlgorithms?.forEach((algorithm) => {
      if (value?.algorithms?.find((algo) => algo.name === algorithm.name)) {
        errors.algorithms[algorithm.name] = {};
        const parameters = algorithm.parameters;
        let hasError = false;
        parameters.forEach((parameter) => {
          const isValid = validateParameter(parameter, algorithm.name, value);
          if (!isValid) {
            hasError = true;
            errors.algorithms[algorithm.name][parameter.name] = 'Invalid value';
          }
        });
        if (!hasError) {
          delete errors.algorithms[algorithm.name];
        }
      }
    });
  }

  const hasSelectedAlgorithm = value?.algorithms && value.algorithms.length > 0;
  if (!value?.searchStrategy) {
    errors.global = 'Select search strategy';
  } else if (!hasSelectedAlgorithm) {
    errors.global = 'Select an algorithm';
  } else {
    errors.global = '';
  }

  return cleanErrorObject(errors);
};
