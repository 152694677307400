import { typeVersionToReportDataName } from '../../../molecules/augur-layout-elements/report-elements/types/utils';
import { typeVersionToAugurSettingsName } from '../../../molecules/augur-layout-elements/settings-elements/types/util';
import { ModuleConfiguration } from '../type';

/**
 * Build the json schema for the reports.
 * The references (reportData.json, augurSettings.json) are served by the backend.
 */
export function reportAndSettingsSchemas(moduleConfig: ModuleConfiguration) {
  const learningReportElements =
    moduleConfig.augurReportConfiguration.learning.flatMap(
      (page) => page.elements
    );
  const evaluationReportElements =
    moduleConfig.augurReportConfiguration.evaluation.flatMap(
      (page) => page.elements
    );
  const predictionReportElements =
    moduleConfig.augurReportConfiguration.prediction.flatMap(
      (page) => page.elements
    );
  const settingsElements = moduleConfig.augurSettingsConfiguration.flatMap(
    (page) => page.elements
  );
  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    definitions: {
      LearningReport: {
        properties: Object.fromEntries(
          learningReportElements.map((r) => [
            r.reportKey,
            {
              $ref:
                'reportData.json#/definitions/' +
                typeVersionToReportDataName(r.type, r.version),
            },
          ])
        ),
        required: learningReportElements.map((r) => r.reportKey),
      },
      EvaluationReport: {
        properties: Object.fromEntries(
          evaluationReportElements.map((r) => [
            r.reportKey,
            {
              $ref:
                'reportData.json#/definitions/' +
                typeVersionToReportDataName(r.type, r.version),
            },
          ])
        ),
        required: evaluationReportElements.map((r) => r.reportKey),
      },
      PredictionReport: {
        properties: Object.fromEntries(
          predictionReportElements.map((r) => [
            r.reportKey,
            {
              $ref:
                'reportData.json#/definitions/' +
                typeVersionToReportDataName(r.type, r.version),
            },
          ])
        ),
        required: predictionReportElements.map((r) => r.reportKey),
      },
      Settings: {
        properties: Object.fromEntries(
          settingsElements.map((r) => [
            r.settingsKey,
            {
              $ref:
                'augurSettings.json#/definitions/' +
                typeVersionToAugurSettingsName(r.type, r.version),
            },
          ])
        ),
        required: settingsElements.map((r) => r.settingsKey),
      },
    },
  };
}
