import { K8sResources } from 'common/dist/types/job';
import {
  GeneralConfiguration,
  SERVING_TYPES,
} from 'common/dist/types/moduleVersion';

export type GeneralConfigurationForm = {
  supportsLearning: boolean;
  supportsEvaluation: boolean;
  supportsPrediction: boolean;
  supportsRealtimePrediction: boolean;
  resources: {
    learning: K8sResources;
    evaluation: K8sResources;
    prediction: K8sResources;
    realtimePrediction: K8sResources;
  };
};

export const toGeneralConfigurationForm = (
  generalConfig: GeneralConfiguration
): GeneralConfigurationForm => ({
  supportsLearning: generalConfig.supportsLearning || false,
  supportsEvaluation: generalConfig.supportsEvaluation || false,
  supportsPrediction: generalConfig.supportsPrediction || false,
  supportsRealtimePrediction: generalConfig.supportsRealtimePrediction || false,
  resources: {
    learning: generalConfig.learning?.resources,
    evaluation: generalConfig.evaluation?.resources,
    prediction: generalConfig.prediction?.resources,
    realtimePrediction: generalConfig.realtimePrediction?.resources,
  },
});

export const fromGeneralConfigForm = (
  generalConfigForm: GeneralConfigurationForm
): GeneralConfiguration => ({
  supportsLearning: generalConfigForm.supportsLearning
    ? generalConfigForm.supportsLearning
    : undefined,
  supportsEvaluation: generalConfigForm.supportsLearning
    ? generalConfigForm.supportsEvaluation
    : undefined,
  supportsPrediction: generalConfigForm.supportsLearning
    ? generalConfigForm.supportsPrediction
    : undefined,
  supportsRealtimePrediction: generalConfigForm.supportsLearning
    ? generalConfigForm.supportsRealtimePrediction
    : undefined,
  learning: generalConfigForm.supportsLearning
    ? {
        resources: generalConfigForm.resources.learning,
      }
    : undefined,
  evaluation:
    generalConfigForm.supportsLearning && generalConfigForm.supportsEvaluation
      ? {
          resources: generalConfigForm.resources.evaluation,
        }
      : undefined,
  prediction:
    generalConfigForm.supportsLearning && generalConfigForm.supportsPrediction
      ? {
          resources: generalConfigForm.resources.prediction,
        }
      : undefined,
  realtimePrediction:
    generalConfigForm.supportsLearning &&
    generalConfigForm.supportsRealtimePrediction
      ? {
          resources: generalConfigForm.resources.realtimePrediction,
          availableServingTypes: [SERVING_TYPES.REST],
        }
      : undefined,
});
