import React, { Component } from 'react';
import { availableRepositoryTypes } from '../repository.form';
import RepositoryTypeCard from './RepositoryTypeCard';

interface RepositoryStepTypeProps {
  repositoryType?: string;
  repositoryTypeTouched?: boolean;
  repositoryTypeError?: string;
  meta: {
    touched?: boolean;
    asyncValidating?: boolean;
    valid?: boolean;
    error?: object;
  };
  input?: {
    value?: any;
    onChange(...args: unknown[]): unknown;
    onFocus(...args: unknown[]): unknown;
    onBlur(...args: unknown[]): unknown;
  };
  disabled?: boolean;
}

export default class RepositoryStepType extends Component<RepositoryStepTypeProps> {
  errorMessage() {
    const { repositoryTypeTouched, repositoryTypeError } = this.props;
    if (repositoryTypeTouched && repositoryTypeError) {
      return <p className={'error'}>{repositoryTypeError}</p>;
    }
  }

  render() {
    const {
      repositoryTypeError,
      repositoryTypeTouched,
      input: { value, onChange, onBlur },
      disabled,
    } = this.props;
    return (
      <div className={'step-container step-type-container'}>
        <div
          className={`input-container${
            repositoryTypeTouched && repositoryTypeError
              ? ' input-container-error'
              : ''
          }`}
        >
          <div className={'error-container'}>{this.errorMessage()}</div>
          <div className={'name-input'}>
            <div className={'repository-types'}>
              {availableRepositoryTypes.map((element) => (
                <RepositoryTypeCard
                  name={element.name}
                  displayName={element.displayName}
                  key={element.name}
                  onChange={(name) => {
                    onChange(name);
                    onBlur(name); // To touch the redux-form Field
                  }}
                  selectedRepositoryType={value}
                  disabled={disabled}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
