import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import {
  Channel,
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationCommunicationGroupsAugurSettings,
  CampaignOptimizationCommunicationGroupsConfig,
  CampaignOptimizationCommunicationGroupsValidationError,
} from './type';
import {
  validateName,
  validateDescription,
} from '../../campaign-optimization-channels/v1/validate';

export const validateCommunicationGroups = (
  value: CampaignOptimizationCommunicationGroupsAugurSettings,
  config?: CampaignOptimizationCommunicationGroupsConfig
):
  | DeepPartial<CampaignOptimizationCommunicationGroupsValidationError>
  | undefined => {
  const error: DeepPartial<CampaignOptimizationCommunicationGroupsValidationError> =
    {};

  // No validation required: The communication groups are optional
  value?.forEach((communicationGroup) => {
    const communicationGroupError = validateSingleCommunicationGroup(
      communicationGroup,
      value,
      config
    );
    if (!_.isEmpty(communicationGroupError)) {
      error.rows = {
        ...error.rows,
        [communicationGroup.id]: communicationGroupError,
      };
    }
  });
  return cleanErrorObject(error);
};

/**
 * Validation for the "sub form": add communication group
 * @param communicationGroup
 * @param allCommunicationsGroups
 * @param config
 */
export const validateSingleCommunicationGroup = (
  communicationGroup: Partial<CommunicationGroup>,
  allCommunicationsGroups: Partial<CommunicationGroup>[],
  config?: CampaignOptimizationCommunicationGroupsConfig
): {
  name?: string;
  description?: string;
  communicationIds?: string;
} => {
  const error = {};
  // Validate name
  const nameError = validateName(
    communicationGroup.name,
    (allCommunicationsGroups || [])
      .filter((x) => x.id !== communicationGroup.id)
      .map((comm) => comm.name)
      .filter((name): name is string => name !== undefined)
  );

  // Validate description
  const descriptionError = validateDescription(communicationGroup.description);

  let communicationIdsError;
  // At least one communication is required
  if ((communicationGroup?.communicationIds || []).length === 0) {
    communicationIdsError =
      moduleOptimizationMessages
        .msgNewAugurStepCommunicationGroupsErrNoCommunication.defaultMessage;
  } else if (
    communicationGroup.communicationIds &&
    !config?.communications?.find((element: Communication) =>
      communicationGroup.communicationIds?.some((id) => element?.id === id)
    )
  ) {
    communicationIdsError =
      moduleOptimizationMessages
        .msgNewAugurStepCommunicationGroupsErrNoCommunication.defaultMessage;
  }

  return {
    name: nameError,
    description: descriptionError,
    communicationIds: communicationIdsError,
  };
};
