import Ajv, { ValidateFunction } from 'ajv';
import { ComponentType, ReactNode } from 'react';
import { DeepPartial } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';

import { FieldInputProps, SETTINGS_ELEMENT_TYPES } from './type';
import { AllowedInputs } from './util';
import { Config } from '../../../../pages/augur/type';
import { TileSizeRestriction } from '../../../grid-layout-editor/type';
import { ConfigFormComponentProps, SharedInputProps } from '../../common/utils';
import CampaignOptimizationChannels from '../campaign-optimization-channels/v1/CampaignOptimizationChannels';
import { campaignOptimizationChannelsImg } from '../campaign-optimization-channels/v1/img/campaignOptimizationChannelsImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationChannels,
  schemaConfigCampaignOptimizationChannels,
} from '../campaign-optimization-channels/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationChannels,
  CampaignOptimizationChannelsAugurSettings,
  CampaignOptimizationChannelsConfig,
  CampaignOptimizationChannelsValidationError,
  configAllowedInputsCampaignOptimizationChannels,
  configTypeCampaignOptimizationChannels,
  defaultAugurSettingsDefaultCampaignOptimizationChannels,
  defaultConfigCampaignOptimizationChannels,
} from '../campaign-optimization-channels/v1/type';
import { validateChannels } from '../campaign-optimization-channels/v1/validate';
import CampaignOptimizationCommunicationGroups from '../campaign-optimization-communication-groups/v1/CampaignOptimizationCommunicationGroups';
import { campaignOptimizationCommunicationGroupsImg } from '../campaign-optimization-communication-groups/v1/img/campaignOptimizationCommunicationGroupsImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationCommunicationGroups,
  schemaConfigCampaignOptimizationCommunicationGroups,
} from '../campaign-optimization-communication-groups/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationCommunicationGroups,
  CampaignOptimizationCommunicationGroupsAugurSettings,
  CampaignOptimizationCommunicationGroupsConfig,
  CampaignOptimizationCommunicationGroupsValidationError,
  configAllowedInputsCampaignOptimizationCommunicationGroups,
  configTypeCampaignOptimizationCommunicationGroups,
  defaultAugurSettingsDefaultCampaignOptimizationCommunicationGroups,
  defaultConfigCampaignOptimizationCommunicationGroups,
} from '../campaign-optimization-communication-groups/v1/type';
import { validateCommunicationGroups } from '../campaign-optimization-communication-groups/v1/validate';
import CampaignOptimizationCommunications from '../campaign-optimization-communications/v1/CampaignOptimizationCommunications';
import { campaignOptimizationCommunicationsImg } from '../campaign-optimization-communications/v1/img/campaignOptimizationCommunicationsImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationCommunications,
  schemaConfigCampaignOptimizationCommunications,
} from '../campaign-optimization-communications/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationCommunications,
  CampaignOptimizationCommunicationsAugurSettings,
  CampaignOptimizationCommunicationsConfig,
  CampaignOptimizationCommunicationsValidationError,
  configAllowedInputsCampaignOptimizationCommunications,
  configTypeCampaignOptimizationCommunications,
  defaultAugurSettingsDefaultCampaignOptimizationCommunications,
  defaultConfigCampaignOptimizationCommunications,
} from '../campaign-optimization-communications/v1/type';
import { validateCommunications } from '../campaign-optimization-communications/v1/validate';
import CampaignOptimizationConstraints from '../campaign-optimization-constraints/v1/CampaignOptimizationConstraints';
import { campaignOptimizationConstraintsImg } from '../campaign-optimization-constraints/v1/img/campaignOptimizationConstraintsImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationConstraints,
  schemaConfigCampaignOptimizationConstraints,
} from '../campaign-optimization-constraints/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationConstraints,
  CampaignOptimizationConstraintsAugurSettings,
  CampaignOptimizationConstraintsConfig,
  CampaignOptimizationConstraintsValidationError,
  configAllowedInputsCampaignOptimizationConstraints,
  configTypeCampaignOptimizationConstraints,
  defaultAugurSettingsDefaultCampaignOptimizationConstraints,
  defaultConfigCampaignOptimizationConstraints,
} from '../campaign-optimization-constraints/v1/type';
import { validateConstraints } from '../campaign-optimization-constraints/v1/validate';
import CampaignOptimizationContactPolicies from '../campaign-optimization-contact-policies/v1/CampaignOptimizationContactPolicies';
import { campaignOptimizationContactPoliciesImg } from '../campaign-optimization-contact-policies/v1/img/campaignOptimizationContactPoliciesImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationContactPolicies,
  schemaConfigCampaignOptimizationContactPolicies,
} from '../campaign-optimization-contact-policies/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationContactPolicies,
  CampaignOptimizationContactPoliciesAugurSettings,
  CampaignOptimizationContactPoliciesConfig,
  CampaignOptimizationContactPoliciesValidationError,
  configAllowedInputsCampaignOptimizationContactPolicies,
  configTypeCampaignOptimizationContactPolicies,
  defaultAugurSettingsDefaultCampaignOptimizationContactPolicies,
  defaultConfigCampaignOptimizationContactPolicies,
} from '../campaign-optimization-contact-policies/v1/type';
import { validateContactPolicies } from '../campaign-optimization-contact-policies/v1/validate';
import CampaignOptimizationPropensities from '../campaign-optimization-propensities/v1/CampaignOptimizationPropensities';
import { campaignOptimizationPropensitiesImg } from '../campaign-optimization-propensities/v1/img/campaignOptimizationChannelsImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationPropensities,
  schemaConfigCampaignOptimizationPropensities,
} from '../campaign-optimization-propensities/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationPropensities,
  CampaignOptimizationPropensitiesAugurSettings,
  CampaignOptimizationPropensitiesConfig,
  CampaignOptimizationPropensitiesValidationError,
  configAllowedInputsCampaignOptimizationPropensities,
  configTypeCampaignOptimizationPropensities,
  defaultAugurSettingsDefaultCampaignOptimizationPropensities,
  defaultConfigCampaignOptimizationPropensities,
} from '../campaign-optimization-propensities/v1/type';
import { validatePropensities } from '../campaign-optimization-propensities/v1/validate';
import CampaignOptimizationTargetFunction from '../campaign-optimization-target-function/v1/CampaignOptimizationTargetFunction';
import { campaignOptimizationTargetFunctionImg } from '../campaign-optimization-target-function/v1/img/campaignOptimizationTargetFunctionImg';
import {
  schemaAugurSettingsDefaultCampaignOptimizationTargetFunction,
  schemaConfigCampaignOptimizationTargetFunction,
} from '../campaign-optimization-target-function/v1/schema';
import {
  augurSettingsTypeCampaignOptimizationTargetFunction,
  CampaignOptimizationTargetFunctionAugurSettings,
  CampaignOptimizationTargetFunctionConfig,
  CampaignOptimizationTargetFunctionValidationError,
  configAllowedInputsCampaignOptimizationTargetFunction,
  configTypeCampaignOptimizationTargetFunction,
  defaultAugurSettingsDefaultCampaignOptimizationTargetFunction,
  defaultConfigCampaignOptimizationTargetFunction,
} from '../campaign-optimization-target-function/v1/type';
import { validateTargetFunction } from '../campaign-optimization-target-function/v1/validate';
import CassandraKeyspaceSelect from '../cassandraKeyspaceSelect/v1/CassandraKeyspaceSelect';
import { cassandraKeyspaceSelectImg } from '../cassandraKeyspaceSelect/v1/img/CassandraKeyspaceSelectImg';
import {
  schemaAugurSettingsDefaultCassandraKeyspaceSelect,
  schemaConfigCassandraKeyspaceSelect,
} from '../cassandraKeyspaceSelect/v1/schema';
import {
  augurSettingsTypeCassandraKeyspaceSelect,
  CassandraKeyspaceSelectAugurSettings,
  CassandraKeyspaceSelectConfig,
  configAllowedInputsCassandraKeyspaceSelect,
  configTypeCassandraKeyspaceSelect,
  defaultAugurSettingsDefaultCassandraKeyspaceSelect,
  defaultConfigCassandraKeyspaceSelect,
  defaultValidationSchemaCassandraKeyspaceSelect,
} from '../cassandraKeyspaceSelect/v1/type';
import CassandraTableColumnSelect from '../cassandraTableColumnSelect/v1/CassandraTableColumnSelect';
import { cassandraTableColumnSelectImg } from '../cassandraTableColumnSelect/v1/img/CassandraTableColumnSelect';
import {
  schemaAugurSettingsDefaultCassandraTableColumnSelect,
  schemaConfigCassandraTableColumnSelect,
} from '../cassandraTableColumnSelect/v1/schema';
import {
  augurSettingsTypeCassandraTableColumnSelect,
  CassandraTableColumnSelectAugurSettings,
  CassandraTableColumnSelectConfig,
  CassandraTableColumnSelectValidationError,
  configAllowedInputsCassandraTableColumnSelect,
  configTypeCassandraTableColumnSelect,
  defaultAugurSettingsDefaultCassandraTableColumnSelect,
  defaultConfigCassandraTableColumnSelect,
} from '../cassandraTableColumnSelect/v1/type';
import { validateCassandraTableColumnSelect } from '../cassandraTableColumnSelect/v1/validate';
import CassandraTableSelect from '../cassandraTableSelect/v1/CassandraTableSelect';
import { cassandraTableSelectImg } from '../cassandraTableSelect/v1/img/CassandraTableSelect';
import {
  schemaAugurSettingsDefaultCassandraTableSelect,
  schemaConfigCassandraTableSelect,
} from '../cassandraTableSelect/v1/schema';
import {
  augurSettingsTypeCassandraTableSelect,
  CassandraTableSelectAugurSettings,
  CassandraTableSelectConfig,
  configAllowedInputsCassandraTableSelect,
  configTypeCassandraTableSelect,
  defaultAugurSettingsDefaultCassandraTableSelect,
  defaultConfigCassandraTableSelect,
  defaultValidationSchemaCassandraTableSelect,
} from '../cassandraTableSelect/v1/type';
import { DropdownSelectCFE } from '../dropdown-select-cfe/v1/DropdownSelectCFE';
import { dropdownSelectCfeImg } from '../dropdown-select-cfe/v1/img/dropdownSelectCfeImg';
import {
  schemaAugurSettingsDefaultDropdownSelect,
  schemaConfigDropdownSelect,
} from '../dropdown-select-cfe/v1/schema';
import {
  augurSettingsTypeDropdownSelect,
  configAllowedInputsDropdownSelect,
  configTypeDropdownSelect,
  defaultAugurSettingsDefaultDropdownSelect,
  defaultConfigDropdownSelect,
  defaultValidationSchemaDropdownSelect,
  DropdownSelectCFEAugurSettings,
  DropdownSelectCFEConfig,
} from '../dropdown-select-cfe/v1/type';
import { parameterTuningImg } from '../parameter-tuning/v1/img/parameterTuningImg';
import ParameterTuning from '../parameter-tuning/v1/ParameterTuningSelection';
import {
  schemaAugurSettingsDefaultParameterTuning,
  schemaConfigParameterTuning,
} from '../parameter-tuning/v1/schema';
import {
  augurSettingsTypeParameterTuning,
  configAllowedInputsParameterTuning,
  configTypeParameterTuning,
  defaultAugurSettingsDefaultParameterTuning,
  defaultConfigParameterTuning,
  ParameterTuningAugurSettings,
  ParameterTuningConfig,
} from '../parameter-tuning/v1/type';
import { validateParameterTuning } from '../parameter-tuning/v1/validate';
import { pipelineTuningImg } from '../pipeline-tuning/v1/img/pipelineTuningImg';
import PipelineTuning from '../pipeline-tuning/v1/PipelineTuning';
import {
  schemaAugurSettingsDefaultPipelineTuning,
  schemaConfigPipelineTuning,
} from '../pipeline-tuning/v1/schema';
import {
  augurSettingsTypePipelineTuning,
  configAllowedInputsPipelineTuning,
  configTypePipelineTuning,
  defaultAugurSettingsDefaultPipelineTuning,
  defaultConfigPipelineTuning,
  PipelineTuningAugurSettings,
  PipelineTuningConfig,
  PipelineTuningValidationError,
} from '../pipeline-tuning/v1/type';
import { validatePipelineTuning } from '../pipeline-tuning/v1/validate';
import { rejectedFeaturesImg } from '../rejected-features-cassandra/v1/img/rejectedFeaturesImg';
import RejectedFeaturesCassandra from '../rejected-features-cassandra/v1/RejectedFeaturesCassandra';
import {
  schemaAugurSettingsDefaultRejectedFeaturesCassandra,
  schemaConfigRejectedFeaturesCassandra,
} from '../rejected-features-cassandra/v1/schema';
import {
  augurSettingsTypeRejectedFeaturesCassandra,
  configAllowedInputsRejectedFeaturesCassandra,
  configTypeRejectedFeaturesCassandra,
  defaultAugurSettingsDefaultRejectedFeaturesCassandra,
  defaultConfigRejectedFeaturesCassandra,
  defaultValidationSchemaRejectedFeaturesCassandra,
  RejectedFeaturesCassandraAugurSettings,
  RejectedFeaturesCassandraConfig,
} from '../rejected-features-cassandra/v1/type';
import RejectedFeaturesS3 from '../rejected-features-s3/v1/RejectedFeaturesS3';
import {
  schemaAugurSettingsDefaultRejectedFeaturesS3,
  schemaConfigRejectedFeaturesS3,
} from '../rejected-features-s3/v1/schema';
import {
  augurSettingsTypeRejectedFeaturesS3,
  configAllowedInputsRejectedFeaturesS3,
  configTypeRejectedFeaturesS3,
  defaultAugurSettingsDefaultRejectedFeaturesS3,
  defaultConfigRejectedFeaturesS3,
  defaultValidationSchemaRejectedFeaturesS3,
  RejectedFeaturesS3AugurSettings,
  RejectedFeaturesS3Config,
} from '../rejected-features-s3/v1/type';
import { s3BucketSelectImg } from '../s3BucketSelect/v1/img/s3BucketSelectImg';
import S3BucketSelect from '../s3BucketSelect/v1/S3BucketSelect';
import {
  schemaAugurSettingsDefaultS3BucketSelect,
  schemaConfigS3BucketSelect,
} from '../s3BucketSelect/v1/schema';
import {
  augurSettingsTypeS3BucketSelect,
  configAllowedInputsS3BucketSelect,
  configTypeS3BucketSelect,
  defaultAugurSettingsDefaultS3BucketSelect,
  defaultConfigS3BucketSelect,
  defaultValidationSchemaS3BucketSelect,
  S3BucketSelectAugurSettings,
  S3BucketSelectConfig,
} from '../s3BucketSelect/v1/type';
import { s3FileSelectImg } from '../s3FileSelect/v1/img/S3FileSelect';
import S3FileSelect from '../s3FileSelect/v1/S3FileSelect';
import {
  schemaAugurSettingsDefaultS3FileSelect,
  schemaConfigS3FileSelect,
} from '../s3FileSelect/v1/schema';
import {
  augurSettingsTypeS3FileSelect,
  configAllowedInputsS3FileSelect,
  configTypeS3FileSelect,
  defaultAugurSettingsDefaultS3FileSelect,
  defaultConfigS3FileSelect,
  defaultValidationSchemaS3FileSelect,
  S3FileSelectAugurSettings,
  S3FileSelectConfig,
} from '../s3FileSelect/v1/type';
import { s3ParquetColumnSelectImg } from '../s3ParquetColumnSelect/v1/img/S3ParquetColumnSelectImg';
import S3ParquetColumnSelect from '../s3ParquetColumnSelect/v1/S3ParquetColumnSelect';
import {
  schemaAugurSettingsDefaultS3ParquetColumnSelect,
  schemaConfigS3ParquetColumnSelect,
} from '../s3ParquetColumnSelect/v1/schema';
import {
  augurSettingsTypeS3ParquetColumnSelect,
  configAllowedInputsS3ParquetColumnSelect,
  configTypeS3ParquetColumnSelect,
  defaultAugurSettingsDefaultS3ParquetColumnSelect,
  defaultConfigS3ParquetColumnSelect,
  S3ParquetColumnSelectAugurSettings,
  S3ParquetColumnSelectConfig,
  S3ParquetColumnSelectValidationError,
} from '../s3ParquetColumnSelect/v1/type';
import { validateS3ParquetColumnSelect } from '../s3ParquetColumnSelect/v1/validate';
import { samplingStrategyImg } from '../sampling-strategy/v1/img/samplingStrategyImg';
import SamplingStrategy from '../sampling-strategy/v1/SamplingStrategy';
import {
  schemaAugurSettingsDefaultSamplingStrategy,
  schemaConfigSamplingStrategy,
} from '../sampling-strategy/v1/schema';
import {
  augurSettingsTypeSamplingStrategy,
  configAllowedInputsSamplingStrategy,
  configTypeSamplingStrategy,
  defaultAugurSettingsDefaultSamplingStrategy,
  defaultConfigSamplingStrategy,
  defaultValidationSchemaSamplingStrategy,
  SamplingStrategyAugurSettings,
  SamplingStrategyConfig,
} from '../sampling-strategy/v1/type';
import { targetHierarchyImg } from '../target-hierarchy/v1/img/targetHierarchyImg';
import {
  schemaAugurSettingsDefaultTargetHierarchy,
  schemaConfigTargetHierarchy,
} from '../target-hierarchy/v1/schema';
import { TargetHierarchy } from '../target-hierarchy/v1/TargetHierarchy';
import {
  augurSettingsTypeTargetHierarchy,
  configAllowedInputsTargetHierarchy,
  configTypeTargetHierarchy,
  defaultAugurSettingsDefaultTargetHierarchy,
  defaultConfigTargetHierarchy,
  defaultValidationSchemaTargetHierarchy,
  TargetHierarchyAugurSettings,
  TargetHierarchyConfig,
} from '../target-hierarchy/v1/type';
import { targetTaxonomyImg } from '../target-taxonomy/v1/img/targetTaxonomyImg';
import {
  schemaAugurSettingsDefaultTargetTaxonomy,
  schemaConfigTargetTaxonomy,
} from '../target-taxonomy/v1/schema';
import TargetTaxonomy from '../target-taxonomy/v1/TargetTaxonomy';
import {
  augurSettingsTypeTargetTaxonomy,
  configAllowedInputsTargetTaxonomy,
  configTypeTargetTaxonomy,
  defaultAugurSettingsDefaultTargetTaxonomy,
  defaultConfigTargetTaxonomy,
  TargetTaxonomyAugurSettings,
  TargetTaxonomyConfig,
  TargetTaxonomyValidationError,
} from '../target-taxonomy/v1/type';
import { validateTargetTaxonomy } from '../target-taxonomy/v1/validate';
import { textInputAreaImg } from '../text-input-area/v1/img/textInputArea';
import {
  schemaAugurSettingsDefaultTextInputArea,
  schemaConfigTextInputArea,
} from '../text-input-area/v1/schema';
import TextInputArea from '../text-input-area/v1/TextInputArea';
import {
  augurSettingsTypeTextInputArea,
  configAllowedInputsTextInputArea,
  configTypeTextInputArea,
  defaultAugurSettingsDefaultTextInputArea,
  defaultConfigTextInputArea,
  defaultValidationSchemaTextInputArea,
  TextInputAreaAugurSettings,
  TextInputAreaConfig,
  TextInputAreaValidationError,
} from '../text-input-area/v1/type';
import { textInputLineImg } from '../text-input-line/v1/img/textInputLineImg';
import {
  schemaAugurSettingsDefaultTextInputLine,
  schemaConfigTextInputLine,
} from '../text-input-line/v1/schema';
import TextInputLine from '../text-input-line/v1/TextInputLine';
import {
  augurSettingsTypeTextInputLine,
  configAllowedInputsTextInputLine,
  configTypeTextInputLine,
  defaultAugurSettingsDefaultTextInputLine,
  defaultConfigTextInputLine,
  defaultValidationSchemaTextInputLine,
  TextInputLineAugurSettings,
  TextInputLineConfig,
  TextInputLineValidationError,
} from '../text-input-line/v1/type';
import CampaignOptimizationCommunicationsConfigFormComponent from '../campaign-optimization-communications/v1/CampaignOptimizationCommunicationsConfigFormComponent';
import DropdownSelectCFEConfigFormComponent from '../dropdown-select-cfe/v1/DropdownSelectCFEConfigFormComponent';
import TextInputLineConfigFormComponent from '../text-input-line/v1/TextInputLineConfigFormComponent';
import TextInputAreaConfigFormComponent from '../text-input-area/v1/TextInputAreaConfigFormComponent';
import SamplingStrategyConfigFormComponent from '../sampling-strategy/v1/SamplingStrategyConfigFormComponent';
import TargetHierarchyConfigFormComponent from '../target-hierarchy/v1/TargetHierarchyConfigFormComponent';
import S3FileSelectConfigFormComponent from '../s3FileSelect/v1/S3FileSelectConfigFormComponent';
import CassandraTableSelectConfigFormComponent from '../cassandraTableSelect/v1/CassandraTableSelectConfigFormComponent';
import S3ParquetColumnSelectConfigFormComponent from '../s3ParquetColumnSelect/v1/S3ParquetColumnSelectConfigFormComponent';
import CassandraTableColumnSelectConfigFormComponent from '../cassandraTableColumnSelect/v1/CassandraTableColumnSelectConfigFormComponent';
import RejectedFeaturesCassandraConfigFormComponent from '../rejected-features-cassandra/v1/RejectedFeaturesCassandraConfigFormComponent';
import RejectedFeaturesS3ConfigFormComponent from '../rejected-features-s3/v1/RejectedFeaturesS3ConfigFormComponent';
import CampaignOptimizationCommunicationGroupsConfigFormComponent from '../campaign-optimization-communication-groups/v1/CampaignOptimizationCommunicationGroupsConfigFormComponent';
import CampaignOptimizationConstraintsConfigFormComponent from '../campaign-optimization-constraints/v1/CampaignOptimizationConstraintsConfigFormComponent';
import { GlobalErrorType, validateBySchema } from '../common/validate';

export const ajv = new Ajv();

export type AugurSettingsProps<
  TValueType,
  TConfig,
  TErrorType = unknown
> = SharedInputProps &
  Partial<FieldInputProps<TValueType>> & {
    config: Partial<TConfig>;
    error?: DeepPartial<TErrorType>;
  };

export type AugurSettingsElementMeta<
  TSettingsType = unknown,
  TConfigType extends Record<string, unknown> = Record<string, unknown>,
  TErrorType = unknown
> = {
  name: MessageDescriptor;
  description: MessageDescriptor;
  type: string;
  version: string;
  imageIcon: string;
  tileSizeRestrictions: TileSizeRestriction;
  Component: ComponentType<
    AugurSettingsProps<TSettingsType, TConfigType, TErrorType>
  >;
  defaultConfig: Config<TConfigType>;
  validateConfig: ValidateFunction;
  configAllowedInputs: AllowedInputs<TConfigType>;
  configurationType: string;
  defaultAugurSettingsDefault: Partial<TSettingsType>;
  validateAugurSettingsDefault: ValidateFunction;
  augurSettingsType: string;
  validationFunction: (
    settings: TSettingsType,
    config: TConfigType
  ) => DeepPartial<GlobalErrorType & TErrorType> | undefined;
  ConfigFormComponent?: ComponentType<ConfigFormComponentProps<TConfigType>>;
};

// Then the types of the components need to be inserted correctly
export type SettingsElementMetas = {
  [SETTINGS_ELEMENT_TYPES.TEXT_INPUT_LINE]: {
    v1: AugurSettingsElementMeta<
      TextInputLineAugurSettings,
      TextInputLineConfig,
      TextInputLineValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.TEXT_INPUT_AREA]: {
    v1: AugurSettingsElementMeta<
      TextInputAreaAugurSettings,
      TextInputAreaConfig,
      TextInputAreaValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.DROPDOWN_SELECT]: {
    v1: AugurSettingsElementMeta<
      DropdownSelectCFEAugurSettings,
      DropdownSelectCFEConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.SAMPLING_STRATEGY]: {
    v1: AugurSettingsElementMeta<
      SamplingStrategyAugurSettings,
      SamplingStrategyConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.TARGET_TAXONOMY]: {
    v1: AugurSettingsElementMeta<
      TargetTaxonomyAugurSettings,
      TargetTaxonomyConfig,
      TargetTaxonomyValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.TARGET_HIERARCHY]: {
    v1: AugurSettingsElementMeta<
      TargetHierarchyAugurSettings,
      TargetHierarchyConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.PARAMETER_TUNING]: {
    v1: AugurSettingsElementMeta<
      ParameterTuningAugurSettings,
      ParameterTuningConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.PIPELINE_TUNING]: {
    v1: AugurSettingsElementMeta<
      PipelineTuningAugurSettings,
      PipelineTuningConfig,
      PipelineTuningValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT]: {
    v1: AugurSettingsElementMeta<
      S3BucketSelectAugurSettings,
      S3BucketSelectConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT]: {
    v1: AugurSettingsElementMeta<
      CassandraKeyspaceSelectAugurSettings,
      CassandraKeyspaceSelectConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.S3_FILE_SELECT]: {
    v1: AugurSettingsElementMeta<S3FileSelectAugurSettings, S3FileSelectConfig>;
  };
  [SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_SELECT]: {
    v1: AugurSettingsElementMeta<
      CassandraTableSelectAugurSettings,
      CassandraTableSelectConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.S3_PARQUET_COLUMN_SELECT]: {
    v1: AugurSettingsElementMeta<
      S3ParquetColumnSelectAugurSettings,
      S3ParquetColumnSelectConfig,
      S3ParquetColumnSelectValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_COLUMN_SELECT]: {
    v1: AugurSettingsElementMeta<
      CassandraTableColumnSelectAugurSettings,
      CassandraTableColumnSelectConfig,
      CassandraTableColumnSelectValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_S3]: {
    v1: AugurSettingsElementMeta<
      RejectedFeaturesS3AugurSettings,
      RejectedFeaturesS3Config
    >;
  };
  [SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_CASSANDRA]: {
    v1: AugurSettingsElementMeta<
      RejectedFeaturesCassandraAugurSettings,
      RejectedFeaturesCassandraConfig
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationChannelsAugurSettings,
      CampaignOptimizationChannelsConfig,
      CampaignOptimizationChannelsValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationPropensitiesAugurSettings,
      CampaignOptimizationPropensitiesConfig,
      CampaignOptimizationPropensitiesValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationCommunicationsAugurSettings,
      CampaignOptimizationCommunicationsConfig,
      CampaignOptimizationCommunicationsValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationCommunicationGroupsAugurSettings,
      CampaignOptimizationCommunicationGroupsConfig,
      CampaignOptimizationCommunicationGroupsValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_TARGET_FUNCTION]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationTargetFunctionAugurSettings,
      CampaignOptimizationTargetFunctionConfig,
      CampaignOptimizationTargetFunctionValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONSTRAINTS]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationConstraintsAugurSettings,
      CampaignOptimizationConstraintsConfig,
      CampaignOptimizationConstraintsValidationError
    >;
  };
  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONTACT_POLICIES]: {
    v1: AugurSettingsElementMeta<
      CampaignOptimizationContactPoliciesAugurSettings,
      CampaignOptimizationContactPoliciesConfig,
      CampaignOptimizationContactPoliciesValidationError
    >;
  };
};

export const settingsElementMetas: SettingsElementMetas = {
  [SETTINGS_ELEMENT_TYPES.TEXT_INPUT_LINE]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Text Input Line' },
      description: {
        id: 'no-id',
        defaultMessage: 'Creates a input element for a single line.',
      },
      type: SETTINGS_ELEMENT_TYPES.TEXT_INPUT_LINE,
      version: 'v1',
      imageIcon: textInputLineImg,
      Component: TextInputLine,
      tileSizeRestrictions: { minH: 2, minW: 3, maxH: 2 },
      defaultConfig: defaultConfigTextInputLine,
      validateConfig: ajv.compile(schemaConfigTextInputLine),
      configAllowedInputs: configAllowedInputsTextInputLine,
      configurationType: configTypeTextInputLine,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultTextInputLine,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultTextInputLine
      ),
      augurSettingsType: augurSettingsTypeTextInputLine,
      validationFunction: validateBySchema(
        defaultValidationSchemaTextInputLine
      ),
      ConfigFormComponent: TextInputLineConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.TEXT_INPUT_AREA]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Text Input Area' },
      description: {
        id: 'no-id',
        defaultMessage: 'Create a text area with 5 default lines.',
      },
      type: SETTINGS_ELEMENT_TYPES.TEXT_INPUT_AREA,
      version: 'v1',
      imageIcon: textInputAreaImg,
      Component: TextInputArea,
      tileSizeRestrictions: { minH: 3, minW: 3 },
      defaultConfig: defaultConfigTextInputArea,
      validateConfig: ajv.compile(schemaConfigTextInputArea),
      configAllowedInputs: configAllowedInputsTextInputArea,
      configurationType: configTypeTextInputArea,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultTextInputArea,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultTextInputArea
      ),
      augurSettingsType: augurSettingsTypeTextInputArea,
      validationFunction: validateBySchema(
        defaultValidationSchemaTextInputArea
      ),
      ConfigFormComponent: TextInputAreaConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.DROPDOWN_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Dropdown Select' },
      description: {
        id: 'no-id',
        defaultMessage:
          'Dropdown to select from multiple options. Also supports multi-selection. Can be used for control flow.',
      },
      type: SETTINGS_ELEMENT_TYPES.DROPDOWN_SELECT,
      version: 'v1',
      imageIcon: dropdownSelectCfeImg,
      tileSizeRestrictions: { minH: 2, minW: 3 },
      Component: DropdownSelectCFE,
      defaultConfig: defaultConfigDropdownSelect,
      validateConfig: ajv.compile(schemaConfigDropdownSelect),
      configAllowedInputs: configAllowedInputsDropdownSelect,
      configurationType: configTypeDropdownSelect,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultDropdownSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultDropdownSelect
      ),
      augurSettingsType: augurSettingsTypeDropdownSelect,
      validationFunction: validateBySchema(
        defaultValidationSchemaDropdownSelect
      ),
      ConfigFormComponent: DropdownSelectCFEConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.SAMPLING_STRATEGY]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Sampling Strategy' },
      description: {
        id: 'no-id',
        defaultMessage: 'Select amount in percentage how two values are split.',
      },
      type: SETTINGS_ELEMENT_TYPES.SAMPLING_STRATEGY,
      version: 'v1',
      imageIcon: samplingStrategyImg,
      tileSizeRestrictions: { minH: 5, minW: 3 },
      Component: SamplingStrategy,
      defaultConfig: defaultConfigSamplingStrategy,
      validateConfig: ajv.compile(schemaConfigSamplingStrategy),
      configAllowedInputs: configAllowedInputsSamplingStrategy,
      configurationType: configTypeSamplingStrategy,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultSamplingStrategy,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultSamplingStrategy
      ),
      augurSettingsType: augurSettingsTypeSamplingStrategy,
      validationFunction: validateBySchema(
        defaultValidationSchemaSamplingStrategy
      ),
      ConfigFormComponent: SamplingStrategyConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.TARGET_TAXONOMY]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Target Taxonomy' },
      description: {
        id: 'no-id',
        defaultMessage: 'Target Taxonomy',
      },
      type: SETTINGS_ELEMENT_TYPES.TARGET_TAXONOMY,
      version: 'v1',
      imageIcon: targetTaxonomyImg,
      Component: TargetTaxonomy,
      tileSizeRestrictions: { minH: 4, minW: 5 },
      defaultConfig: defaultConfigTargetTaxonomy,
      validateConfig: ajv.compile(schemaConfigTargetTaxonomy),
      configAllowedInputs: configAllowedInputsTargetTaxonomy,
      configurationType: configTypeTargetTaxonomy,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultTargetTaxonomy,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultTargetTaxonomy
      ),
      augurSettingsType: augurSettingsTypeTargetTaxonomy,
      validationFunction: validateTargetTaxonomy,
    },
  },

  [SETTINGS_ELEMENT_TYPES.TARGET_HIERARCHY]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Target Hierarchy' },
      description: {
        id: 'no-id',
        defaultMessage: 'Target Hierarchy',
      },
      type: SETTINGS_ELEMENT_TYPES.TARGET_HIERARCHY,
      version: 'v1',
      imageIcon: targetHierarchyImg,
      tileSizeRestrictions: { minH: 4, minW: 4 },
      Component: TargetHierarchy,
      defaultConfig: defaultConfigTargetHierarchy,
      validateConfig: ajv.compile(schemaConfigTargetHierarchy),
      configAllowedInputs: configAllowedInputsTargetHierarchy,
      configurationType: configTypeTargetHierarchy,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultTargetHierarchy,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultTargetHierarchy
      ),
      augurSettingsType: augurSettingsTypeTargetHierarchy,
      validationFunction: validateBySchema(
        defaultValidationSchemaTargetHierarchy
      ),
      ConfigFormComponent: TargetHierarchyConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.PARAMETER_TUNING]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Parameter Tuning' },
      description: {
        id: 'no-id',
        defaultMessage: 'Select values for the algorithms parameters.',
      },
      type: SETTINGS_ELEMENT_TYPES.PARAMETER_TUNING,
      version: 'v1',
      imageIcon: parameterTuningImg,
      tileSizeRestrictions: { minH: 8, minW: 8 },
      Component: ParameterTuning,
      defaultConfig: defaultConfigParameterTuning,
      validateConfig: ajv.compile(schemaConfigParameterTuning),
      configAllowedInputs: configAllowedInputsParameterTuning,
      configurationType: configTypeParameterTuning,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultParameterTuning,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultParameterTuning
      ),
      augurSettingsType: augurSettingsTypeParameterTuning,
      validationFunction: validateParameterTuning,
    },
  },

  [SETTINGS_ELEMENT_TYPES.PIPELINE_TUNING]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Pipeline Tuning',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Define the parameters of the pipeline',
      },
      type: SETTINGS_ELEMENT_TYPES.PIPELINE_TUNING,
      version: 'v1',
      imageIcon: pipelineTuningImg,
      Component: PipelineTuning,
      tileSizeRestrictions: { minH: 10, minW: 6 },
      defaultConfig: defaultConfigPipelineTuning,
      validateConfig: ajv.compile(schemaConfigPipelineTuning),
      configAllowedInputs: configAllowedInputsPipelineTuning,
      configurationType: configTypePipelineTuning,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultPipelineTuning,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultPipelineTuning
      ),
      augurSettingsType: augurSettingsTypePipelineTuning,
      validationFunction: validatePipelineTuning,
    },
  },

  [SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'S3 Bucket Select' },
      description: {
        id: 'no-id',
        defaultMessage: 'Select a S3 Bucket where your data should come from.',
      },
      type: SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT,
      version: 'v1',
      imageIcon: s3BucketSelectImg,
      tileSizeRestrictions: { minH: 4, minW: 4 },
      Component: S3BucketSelect,
      defaultConfig: defaultConfigS3BucketSelect,
      validateConfig: ajv.compile(schemaConfigS3BucketSelect),
      configAllowedInputs: configAllowedInputsS3BucketSelect,
      configurationType: configTypeS3BucketSelect,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultS3BucketSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultS3BucketSelect
      ),
      augurSettingsType: augurSettingsTypeS3BucketSelect,
      validationFunction: validateBySchema(
        defaultValidationSchemaS3BucketSelect
      ),
    },
  },

  [SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Cassandra Keyspace Select' },
      description: {
        id: 'no-id',
        defaultMessage:
          'Select a cassandra keyspace where your data should come from.',
      },
      type: SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT,
      version: 'v1',
      imageIcon: cassandraKeyspaceSelectImg,
      tileSizeRestrictions: { minH: 4, minW: 4 },
      Component: CassandraKeyspaceSelect,
      defaultConfig: defaultConfigCassandraKeyspaceSelect,
      validateConfig: ajv.compile(schemaConfigCassandraKeyspaceSelect),
      configAllowedInputs: configAllowedInputsCassandraKeyspaceSelect,
      configurationType: configTypeCassandraKeyspaceSelect,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCassandraKeyspaceSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCassandraKeyspaceSelect
      ),
      augurSettingsType: augurSettingsTypeCassandraKeyspaceSelect,
      validationFunction: validateBySchema(
        defaultValidationSchemaCassandraKeyspaceSelect
      ),
    },
  },

  [SETTINGS_ELEMENT_TYPES.S3_FILE_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'S3 File Select' },
      description: {
        id: 'no-id',
        defaultMessage: 'Select a s3 file where your data should come from.',
      },
      type: SETTINGS_ELEMENT_TYPES.S3_FILE_SELECT,
      version: 'v1',
      imageIcon: s3FileSelectImg,
      tileSizeRestrictions: { minH: 6, minW: 4 },
      Component: S3FileSelect,
      defaultConfig: defaultConfigS3FileSelect,
      validateConfig: ajv.compile(schemaConfigS3FileSelect),
      configAllowedInputs: configAllowedInputsS3FileSelect,
      configurationType: configTypeS3FileSelect,
      defaultAugurSettingsDefault: defaultAugurSettingsDefaultS3FileSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultS3FileSelect
      ),
      augurSettingsType: augurSettingsTypeS3FileSelect,
      validationFunction: validateBySchema(defaultValidationSchemaS3FileSelect),
      ConfigFormComponent: S3FileSelectConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Cassandra Table Select' },
      description: {
        id: 'no-id',
        defaultMessage:
          'Select a cassandra table where your data should come from.',
      },
      type: SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_SELECT,
      version: 'v1',
      imageIcon: cassandraTableSelectImg,
      tileSizeRestrictions: { minH: 4, minW: 4 },
      Component: CassandraTableSelect,
      defaultConfig: defaultConfigCassandraTableSelect,
      validateConfig: ajv.compile(schemaConfigCassandraTableSelect),
      configAllowedInputs: configAllowedInputsCassandraTableSelect,
      configurationType: configTypeCassandraTableSelect,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCassandraTableSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCassandraTableSelect
      ),
      augurSettingsType: augurSettingsTypeCassandraTableSelect,
      validationFunction: validateBySchema(
        defaultValidationSchemaCassandraTableSelect
      ),
      ConfigFormComponent: CassandraTableSelectConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.S3_PARQUET_COLUMN_SELECT]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'S3 Parquet Column Select',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Select the columns from your s3 table.',
      },
      type: SETTINGS_ELEMENT_TYPES.S3_PARQUET_COLUMN_SELECT,
      version: 'v1',
      imageIcon: s3ParquetColumnSelectImg,
      Component: S3ParquetColumnSelect,
      tileSizeRestrictions: { minH: 3, minW: 4 },
      defaultConfig: defaultConfigS3ParquetColumnSelect,
      validateConfig: ajv.compile(schemaConfigS3ParquetColumnSelect),
      configAllowedInputs: configAllowedInputsS3ParquetColumnSelect,
      configurationType: configTypeS3ParquetColumnSelect,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultS3ParquetColumnSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultS3ParquetColumnSelect
      ),
      augurSettingsType: augurSettingsTypeS3ParquetColumnSelect,
      validationFunction: validateS3ParquetColumnSelect,
      ConfigFormComponent: S3ParquetColumnSelectConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_COLUMN_SELECT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Cassandra Table Column Select' },
      description: {
        id: 'no-id',
        defaultMessage: 'Select the columns from your cassandra table.',
      },
      type: SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_COLUMN_SELECT,
      version: 'v1',
      imageIcon: cassandraTableColumnSelectImg,
      tileSizeRestrictions: { minH: 3, minW: 4 },
      Component: CassandraTableColumnSelect,
      defaultConfig: defaultConfigCassandraTableColumnSelect,
      validateConfig: ajv.compile(schemaConfigCassandraTableColumnSelect),
      configAllowedInputs: configAllowedInputsCassandraTableColumnSelect,
      configurationType: configTypeCassandraTableColumnSelect,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCassandraTableColumnSelect,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCassandraTableColumnSelect
      ),
      augurSettingsType: augurSettingsTypeCassandraTableColumnSelect,
      validationFunction: validateCassandraTableColumnSelect,
      ConfigFormComponent: CassandraTableColumnSelectConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_S3]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Rejected Features (S3)',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Reject or enforce features from your table.',
      },
      type: SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_S3,
      version: 'v1',
      imageIcon: rejectedFeaturesImg,
      Component: RejectedFeaturesS3,
      tileSizeRestrictions: { minH: 5, minW: 4 },
      defaultConfig: defaultConfigRejectedFeaturesS3,
      validateConfig: ajv.compile(schemaConfigRejectedFeaturesS3),
      configAllowedInputs: configAllowedInputsRejectedFeaturesS3,
      configurationType: configTypeRejectedFeaturesS3,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultRejectedFeaturesS3,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultRejectedFeaturesS3
      ),
      augurSettingsType: augurSettingsTypeRejectedFeaturesS3,
      validationFunction: validateBySchema(
        defaultValidationSchemaRejectedFeaturesS3
      ),
      ConfigFormComponent: RejectedFeaturesS3ConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_CASSANDRA]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Rejected Features (Cassandra)',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Reject or enforce features from your table.',
      },
      type: SETTINGS_ELEMENT_TYPES.REJECTED_FEATURES_CASSANDRA,
      version: 'v1',
      imageIcon: rejectedFeaturesImg,
      Component: RejectedFeaturesCassandra,
      tileSizeRestrictions: { minH: 5, minW: 4 },
      defaultConfig: defaultConfigRejectedFeaturesCassandra,
      validateConfig: ajv.compile(schemaConfigRejectedFeaturesCassandra),
      configAllowedInputs: configAllowedInputsRejectedFeaturesCassandra,
      configurationType: configTypeRejectedFeaturesCassandra,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultRejectedFeaturesCassandra,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultRejectedFeaturesCassandra
      ),
      augurSettingsType: augurSettingsTypeRejectedFeaturesCassandra,
      validationFunction: validateBySchema(
        defaultValidationSchemaRejectedFeaturesCassandra
      ),
      ConfigFormComponent: RejectedFeaturesCassandraConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Channels (Campaign Optimization)' },
      description: {
        id: 'no-id',
        defaultMessage: 'Define the Channels for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS,
      version: 'v1',
      imageIcon: campaignOptimizationChannelsImg,
      Component: CampaignOptimizationChannels,
      tileSizeRestrictions: { minH: 4, minW: 5 },
      defaultConfig: defaultConfigCampaignOptimizationChannels,
      validateConfig: ajv.compile(schemaConfigCampaignOptimizationChannels),
      configAllowedInputs: configAllowedInputsCampaignOptimizationChannels,
      configurationType: configTypeCampaignOptimizationChannels,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationChannels,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationChannels
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationChannels,
      validationFunction: validateChannels,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Propensities (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage:
          'Define the Propensities for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES,
      version: 'v1',
      imageIcon: campaignOptimizationPropensitiesImg,
      Component: CampaignOptimizationPropensities,
      tileSizeRestrictions: { minH: 4, minW: 5 },
      defaultConfig: defaultConfigCampaignOptimizationPropensities,
      validateConfig: ajv.compile(schemaConfigCampaignOptimizationPropensities),
      configAllowedInputs: configAllowedInputsCampaignOptimizationPropensities,
      configurationType: configTypeCampaignOptimizationPropensities,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationPropensities,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationPropensities
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationPropensities,
      validationFunction: validatePropensities,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Communications (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage:
          'Define the Communications for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS,
      version: 'v1',
      imageIcon: campaignOptimizationCommunicationsImg,
      tileSizeRestrictions: { minH: 6, minW: 6 },
      Component: CampaignOptimizationCommunications,
      defaultConfig: defaultConfigCampaignOptimizationCommunications,
      validateConfig: ajv.compile(
        schemaConfigCampaignOptimizationCommunications
      ),
      configAllowedInputs:
        configAllowedInputsCampaignOptimizationCommunications,
      configurationType: configTypeCampaignOptimizationCommunications,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationCommunications,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationCommunications
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationCommunications,
      validationFunction: validateCommunications,
      ConfigFormComponent:
        CampaignOptimizationCommunicationsConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Communication Groups (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage:
          'Define the Communication Groups for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS,
      version: 'v1',
      imageIcon: campaignOptimizationCommunicationGroupsImg,
      Component: CampaignOptimizationCommunicationGroups,
      tileSizeRestrictions: { minH: 6, minW: 6 },
      defaultConfig: defaultConfigCampaignOptimizationCommunicationGroups,
      validateConfig: ajv.compile(
        schemaConfigCampaignOptimizationCommunicationGroups
      ),
      configAllowedInputs:
        configAllowedInputsCampaignOptimizationCommunicationGroups,
      configurationType: configTypeCampaignOptimizationCommunicationGroups,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationCommunicationGroups,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationCommunicationGroups
      ),
      augurSettingsType:
        augurSettingsTypeCampaignOptimizationCommunicationGroups,
      validationFunction: validateCommunicationGroups,
      ConfigFormComponent:
        CampaignOptimizationCommunicationGroupsConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_TARGET_FUNCTION]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Target Function (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage:
          'Define the Target Function for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_TARGET_FUNCTION,
      version: 'v1',
      imageIcon: campaignOptimizationTargetFunctionImg,
      Component: CampaignOptimizationTargetFunction,
      tileSizeRestrictions: { minH: 7, minW: 7 },
      defaultConfig: defaultConfigCampaignOptimizationTargetFunction,
      validateConfig: ajv.compile(
        schemaConfigCampaignOptimizationTargetFunction
      ),
      configAllowedInputs:
        configAllowedInputsCampaignOptimizationTargetFunction,
      configurationType: configTypeCampaignOptimizationTargetFunction,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationTargetFunction,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationTargetFunction
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationTargetFunction,
      validationFunction: validateTargetFunction,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONSTRAINTS]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Constraints (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Define the Constraints for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONSTRAINTS,
      version: 'v1',
      imageIcon: campaignOptimizationConstraintsImg,
      Component: CampaignOptimizationConstraints,
      tileSizeRestrictions: { minH: 5, minW: 5 },
      defaultConfig: defaultConfigCampaignOptimizationConstraints,
      validateConfig: ajv.compile(schemaConfigCampaignOptimizationConstraints),
      configAllowedInputs: configAllowedInputsCampaignOptimizationConstraints,
      configurationType: configTypeCampaignOptimizationConstraints,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationConstraints,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationConstraints
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationConstraints,
      validationFunction: validateConstraints,
      ConfigFormComponent: CampaignOptimizationConstraintsConfigFormComponent,
    },
  },

  [SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONTACT_POLICIES]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Contact Policies (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage:
          'Define the Contact Policies for your Campaign Optimization',
      },
      type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CONTACT_POLICIES,
      version: 'v1',
      imageIcon: campaignOptimizationContactPoliciesImg,
      Component: CampaignOptimizationContactPolicies,
      tileSizeRestrictions: { minH: 6, minW: 6 },
      defaultConfig: defaultConfigCampaignOptimizationContactPolicies,
      validateConfig: ajv.compile(
        schemaConfigCampaignOptimizationContactPolicies
      ),
      configAllowedInputs:
        configAllowedInputsCampaignOptimizationContactPolicies,
      configurationType: configTypeCampaignOptimizationContactPolicies,
      defaultAugurSettingsDefault:
        defaultAugurSettingsDefaultCampaignOptimizationContactPolicies,
      validateAugurSettingsDefault: ajv.compile(
        schemaAugurSettingsDefaultCampaignOptimizationContactPolicies
      ),
      augurSettingsType: augurSettingsTypeCampaignOptimizationContactPolicies,
      validationFunction: validateContactPolicies,
    },
  },
};
