import { EChartsOption } from 'echarts';
import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { LineChartConfig, LineChartReportData } from '../type';

export type LineChartDevProps = {
  legend?: string;
};

export type Props = LineChartReportData & LineChartConfig & LineChartDevProps;

export const LineChart: FC<Props> = (props) => {
  const { data, xLabel, yLabel, legend, baseLine, helpLine } = props;

  const primary = useThemeColor('primary');

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const markLinesData = [];
  if (baseLine) {
    markLinesData.push({
      yAxis: baseLine,
    });
  }
  if (helpLine) {
    markLinesData.push([
      {
        coord: helpLine[0],
      },
      {
        coord: helpLine[1],
      },
    ]);
  }

  const formattedData: EChartsOption['series'] = {
    name: legend || '',
    type: 'line',
    data: data,
    color: primary,
    markLine: {
      // @ts-ignore
      data: markLinesData,
      symbol: 'none',
    },
  };

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis',
      confine: true,
    },
    grid: {
      containLabel: true,
      left: 18,
      top: 32,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      nameLocation: 'middle',
      type: 'value',
    },
    yAxis: {
      name: yLabel,
      type: 'value',
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  LineChartReportData,
  LineChartConfig
>;

export const LineChartSingle: FC<SingleProps> = ({ input, config }) => {
  return (
    <LineChart {...input.reportValue} {...config} legend={input.jobCode} />
  );
};
