import classNames from 'classnames';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import React, { FC } from 'react';
import { DeepPartial } from 'react-hook-form';
import { FiMinus } from 'react-icons/fi';

import {
  TargetFunctionErrorType,
  TargetFunctionValueType,
} from './CampaignOptimizationTargetFunction';
import { SPEAKING_SUM_OF, SPEAKING_SUM_OVER } from './common';
import styles from './styles.module.scss';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

type Option = { label: string; value: string };

export type Props = {
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: TargetFunctionSummandGroup) => void;
  value: TargetFunctionValueType;
  onChange?: (...event: any[]) => void;
  error?: DeepPartial<TargetFunctionErrorType>;
  onBlur?: React.FocusEventHandler;
  readOnly?: boolean;
};

const summandGroupSumOfOptions: Option[] = ['costs', 'values'].map((c) => ({
  // @ts-ignore
  label: SPEAKING_SUM_OF[c],
  value: c,
}));

const summandGroupSumOverOptions: Option[] = ['allCommunications'].map((c) => ({
  // @ts-ignore
  label: SPEAKING_SUM_OVER[c],
  value: c,
}));
const drvNewValue = (
  value: TargetFunctionValueType,
  eventValue: string,
  key: string,
  rowIndex: number
): DeepPartial<TargetFunctionValueType> => {
  return {
    ...value,
    summandGroups: [
      ...value?.summandGroups?.slice(0, rowIndex),
      {
        ...value?.summandGroups?.[rowIndex],
        [key]: eventValue,
      },
      ...value?.summandGroups?.slice(rowIndex + 1),
    ],
  };
};
const renderNameField = (props: Props) => {
  const {
    rowIndex = 0,
    constraintId,
    value,
    error,
    onChange,
    onBlur,
    readOnly,
  } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.name}
      invalid={!!error?.rows?.[constraintId]?.name}
      label={'Name'}
      placeholder={'Name'}
      value={value?.summandGroups?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(value, e.target.value, 'name', rowIndex);
        onChange?.(newValue);
      }}
      onBlur={onBlur}
      disabled={readOnly}
    />
  );
};

const renderDescriptionField = (props: Props) => {
  const {
    rowIndex = 0,
    constraintId,
    value,
    error,
    onChange,
    onBlur,
    readOnly,
  } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.description}
      invalid={!!error?.rows?.[constraintId]?.description}
      label={'Description'}
      placeholder={'Description'}
      value={value?.summandGroups?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'description',
          rowIndex
        );
        onChange?.(newValue);
      }}
      onBlur={onBlur}
      disabled={readOnly}
    />
  );
};

const renderSumOfField = (props: Props) => {
  const { rowIndex, constraintId, value, error, onChange, onBlur, readOnly } =
    props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optTargetFunction_sumOf'}
      label={'Sum of'}
      placeholder={'Please select Sum of'}
      options={summandGroupSumOfOptions}
      onBlur={onBlur} // @ts-ignore
      onChange={(option: Option) => {
        const newValue = drvNewValue(value, option.value, 'sumOf', rowIndex);
        onChange?.(newValue);
      }}
      value={summandGroupSumOfOptions.find(
        // @ts-ignore
        (o) => o.value === value?.summandGroups?.[rowIndex]?.sumOf
      )}
      error={error?.rows?.[constraintId]?.sumOf}
      isTouched={true}
      disabled={readOnly}
    />
  );
};

const renderSumOverField = (props: Props) => {
  const {
    rowIndex = 0,
    constraintId,
    value,
    error,
    onChange,
    onBlur,
    readOnly,
  } = props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optTargetFunction_sumOver'}
      label={'Sum over'}
      placeholder={'Please select Sum over'}
      options={summandGroupSumOverOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = drvNewValue(value, option.value, 'sumOver', rowIndex);
        onChange?.(newValue);
      }}
      value={summandGroupSumOverOptions.find(
        (o) => o.value === value?.summandGroups?.[rowIndex]?.sumOver
      )}
      error={error?.rows?.[constraintId]?.sumOver}
      isTouched={true}
      disabled={readOnly}
    />
  );
};

const renderFactorField = (props: Props) => {
  const {
    rowIndex = 0,
    constraintId,
    value,
    error,
    onChange,
    onBlur,
    readOnly,
  } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.factor}
      invalid={!!error?.rows?.[constraintId]?.factor}
      label={'Factor'}
      placeholder={'1'}
      value={value?.summandGroups?.[rowIndex]?.factor}
      onChange={(e) => {
        const newValue = drvNewValue(value, e.target.value, 'factor', rowIndex);
        onChange?.(newValue);
      }}
      onBlur={onBlur}
      disabled={readOnly}
    />
  );
};

const AddSummandGroup: FC<Props> = (props) => {
  const { rowIndex = 0, removableRows, onRemoveRow, value, readOnly } = props;
  const handleRemoveRow = () => {
    if (!readOnly && onRemoveRow) {
      onRemoveRow(value.summandGroups[rowIndex]);
    }
  };
  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={classNames(commonStyles.RemoveIcon, {
              [commonStyles.readOnly]: readOnly,
            })}
            onClick={handleRemoveRow}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderSumOverField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderSumOfField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderFactorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddSummandGroup;
