import { connect } from 'react-redux';
import AddRepositoryWizard from './AddRepositoryWizard';
import { isAsyncValidating, isValid } from 'redux-form';

import {
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldName,
  fieldType,
  formName,
} from './repository.form';
import { selectValue } from './selectorUtils';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState, { location }) {
  const initialRepositoryType =
    qs.parse(location.search, { ignoreQueryPrefix: true }).repositoryType || '';
  const initialHabitatCode =
    qs.parse(location.search, { ignoreQueryPrefix: true }).habitatCode || '';

  return {
    repositoryName: selectValue(state, formName, fieldName),
    repositoryType: selectValue(state, formName, fieldType),
    repositoryDescription: selectValue(state, formName, fieldDescription),
    codeCapsuleHabitat: selectValue(state, formName, fieldCodeCapsuleHabitat),
    contentInCurrentDir: state.workbench.collab.content.data,
    formValid: isValid(formName)(state),
    isAsyncValidating: isAsyncValidating(formName)(state),

    initialValues: {
      [fieldType]: initialRepositoryType,
      [fieldCodeCapsuleHabitat]: {
        code: initialHabitatCode,
      },
    },
  };
}

export default withRouter(connect(mapStateToProps)(AddRepositoryWizard));
