export const s3BucketSelectImg = `
iVBORw0KGgoAAAANSUhEUgAAAkQAAADTCAIAAADvfpvfAAAcIElEQVR4Xu3d63Mb1d3A8f4t/Qd4
XeZ5w4u+odNOp6UdaDpT2rQhQAulwBRCZ9IOdBrapiSFYEruISQOBDvgxIlz813y/X5L5Pv9EtuJ
77Yk7/OLTnxyfGTJUjZovcn3Mxpm9+x6JSsz+81ZrcJ3HAAAfO479gAAAH5DzAAAvkfMAAC+R8wA
AL5HzAAAvkfMAAC+R8wAAL5HzAAAvkfMAAC+R8wAAL5HzAAAvkfMAAC+54+YVdU335mZtUcBAIjJ
XMw6e/qvlgSvl1XK41ppRUt7aGl52d4pgYcVMznIxOSUPbre5PTtnPyr8grlUVheJa82UF0/NjFp
75dUJBrt7R+yR9MUDocbWjr0m1ZUXiXvYTS6au8HAI+9DMWs7UZXsKZRzs5qdXV1dXB4dPzWJl3R
HlbMBoZH22522aPrScyCNQ3myNz8QnlVfU1jqzmY3MpKuChQZY+mSep1s6tXtyscjtzs7l1cWjL3
AQA4GYtZ3uWiaDRqj6bM25gpEuOuvgF7NAH3MZOCXikO2qMAgI1kLmb2UJxQd5+6pCb/rWtqM+MX
H7MkO4u+weHC8iqZ2RQFqkuCNYMjYzIoU8FLhWUXrhQXB2tksLO33/wRLVHMFhYX86+V6tXZufnK
uiZ5AepSZEtHSKabek956hx5ptgT1Ta1qXGZipZV1qlrhvJT/UMj+mjxJianKmob7dH1VsJheWfU
0eS5rLfoclHAXBVXiu+P9A+OyHs4MjZRVlUnP9vY2iGD0dVV+UXuXtIMyFtXFahuuG0cc2ziljyR
ugArv8j8wqLeBADeylDM5MQ6MztnjxpaOzqb229G13rQ1TtQt9YAJy5myXfuCHXXNLTovMluE5PT
atnNzExcul62uHTvcz45s5tX/AaGRhpiPVA2nJkNDo9GIvdelZSvvrld+rp+l/uWl1ekncmns5IW
OaZalplcQWG5+THkpjErr6pvD3Ub253SitruvkG9uryyMn1nRi2PTUyWVtbq409N35Hm6T0BwFsZ
ipmU7GpJxc2u3qW1GJjC4bCcl63BkoralbXP2MyYJd9ZaiHn9ET3SLiMmZz9dRfjyWRFL28YM4v0
TA5ojxokVDIHkglcZKOkyTyvvHrdj/cODDW23tCrm8bMXHXuzgWnZa5pjpjkPdd/HErrjU55DeYI
AHglQzFz1m76qKprlr/+t9/sDkciepNMUGSyZex7V1PbTemKWjZjlnzn8VtT5izN4jJmlbVN5ul7
dm5enkuCJA8p2deXrut5W6KYjd+alGDc3b+qTn5Epnr2HutJPzp7+gPVDbJz38CwuamhpWNo/cRO
3mEzYJvGrKntfvlES0dIXY+NF4lE4v8CIXNT+XO0BgHAE5mLmUlOmnJi1R+6yPlaplNyvjYfMimR
06XawYxZ8p3lHN3R2aP2jOcyZhKhW1P3Zma378xcL6ucm1+4v7W6XvKmljeMWVfvgJRMNukR+UWM
7cnI5Kw91F0SrNGfzMmLMT/QUi4X3T/gpjGTibKx0aluaJm+fe+iomVmbl5Sbb3nxYHqTd9MAMgM
b2LmxK6SBWvu3eAgfepOeqOgFbMkO/cNDt/41mIm53N9mVTml9YNF1eKgzOJYyYJunh93WdgkqVz
F68bu2xO5lL6d98wZmYd42NmjqgbQIyNTnV9i/6EzDIzO6f/sABgC/IsZkvLK3oaMTQy3tweWr99
HTNmyXcevzVV39xuj65xE7OFxSWpkV41l53Yhbgvvr6UJGby49bI+K3J3Pxr5sim+odG1G2HjrrM
OLLuMmN0/WVG6yMxcd64pzQ+Zi0dIeuAWjgc4XYPAFuZZzEbm5gsq7p3x4Sc+q+WVKzfvo4Zs+Q7
RyJR81KbRaaD5i0SG0oUs8q6ph7jTj+Zh+nbKUVP/9ClwjIdM5l1Wd8SkznZ1ZJ1Iw2tHfGTp+Sa
22/qAsXfANLTP2j+dvJ0+pqkmF9YPJ2br1fjYzYxOS3vszliulZasby8Yo8CwNaQoZiVBGvM+wCl
TPnXSmRqokdab3TKVEPnIRyJdPbc/x6YfWt+0p07Qt11zW16q5zQp27fUcsSwvxrpeanVvHiYyaT
qoraJv11MUVeg57kzS8sVDe0yD46Zk7s7D81fe95FZmZ6TdhZGziRmePlTfT9J0Zyaf5mdzw6Hhe
QaFZlMLyqoG1W/Nn5+bNq6BO7H3QHx8uLC5W1jaZ95vEx8yJXTvtG7x/m4m8Ufo3kj+sokD10tqz
r8amufPGywMAD2UoZotLS7WNreofGLxSHJC2TU7du1NRkyBJAIpjX3MuqahNEjMn6c5O7JMzOfMW
B2vkIQnpHbj/zyRKEuQHk39pOjf/Wuxbw7EjBKqlbfH/NqPMtNRvJM8uL0/O+1bMZFl+Vh66glIU
qYWMyH9bOu5eKU0SMyf2Xa7yqnr1pWyZw1XXt8gRzB1WwmEJnryf8hvJdustWo3d5Bn75niVlExm
ZvFfmjZ2v0t9aboo9hXsotjrNHuseiZbS2JvS3N7yLpZHwC8kqGYAQDw7SFmAADfI2YAAN8jZgAA
3yNmAADfI2YAAN8jZgAA3yNmAADfI2YAAN8jZgAA3yNmAADfI2YAAN/LUMwikUiou6+gsCw7N/9U
zgUePHjw4PGYPyQHEgVJgwTCbkb6MhGzSCRa09hWFKiO/7fnAQCPLYmCpEECIZmwt6UpEzEL9fTJ
y7VHAQC4+/96rJZM2KNpykTMCgrLxyen7FEAABxHAiGZsEfTlImYZefm20MAAKxxn4lMxOxUzgV7
CACANe4zQcwAAB5znwliBgDwmPtMEDMAgMfcZ4KYAQA85j4TxAwA4DH3mSBmAACPuc8EMQMAeMx9
JogZAMBj7jNBzAAAHnOfCWIGAPCY+0wQMwCAx9xngpgBADzmPhPEDADgMfeZIGYAAI+5zwQxAwB4
zH0miBkAwGPuM0HMAAAec5+JrRWzEydOfPPNN/ao4/zqV7+an5+3R9N37tw5e2gjJSUlTz755Nzc
nDX+0Ucf/fKXv7QGvz1LS0sXL160R+MMDg6+8847O3fufPnll1966aW33norGAzqrVVVVbt375ZN
ssP27ds/+OCD+N8LAJJo7Rp/92BxQbDT3hDny6ut+09X3JlbsjdsJvVMJPJ4xew3v/mNPbQRidn3
vve9vLw8c3B1dfWZZ57JZMxmZmZeffVVe3S92dnZn/zkJ/X19XpkZGTk1KlTelXez/HxcbUcjUZP
nz69a9cuvRUANvV/2w9/90f75CGtsrcZ3thXoHaTBXvbZlLPRCLEbAMSszfeeENmM+agBOPNN9/c
ajGT4mZlZdmjSW3btm15edkeBYAEdMyS9EyXTB473tvgNJ5c6plIxK8xkz1feOEFOde/+OKLpaWl
5qa+vj6pzksvvaQuu50/f16N7969+6mnnvrTmoWFBfOnTBKz//73v/Kkk5OTevAf//jHtWvX4mNW
Vla2c+dOeSXy36NHj8oETm+SF1lYWHh/V8epqKj45JNP9Orvfvc7mVr95z//eSVmz549S0v3pudj
Y2My8v3vf1+92r179+qfMh0+fNiaQW7q+eefn5iYsEcBIIHWrvEnnv1Yt+rNfZetHcySPf3KSS4z
phqzQ4cOSWxUNhYXF1977bXq6mq99be//e3w8LBeHRoa0supz8zk+CdPnjx9+rQaCYfDP/vZzyQ8
Vsxqa2slmfpTqE8//fTgwYN6ayoxe//997u7u9VqUVGR9ExvTWVmVl5eLpG2RxOQ3yI7O/uHP/yh
WVwA2FSinkm3ntt11mXJnHQykYj/YiYLzzzzjJyX9daRkZEdO3ao5dHR0SQn97RiJtMXvb+M/POf
/5SntmImz9vb26tXJRLbtm3Tbds0Zj/96U/r6uqM7c727dv1cioxE3LAXbt21dfXR6NRe1vM9PS0
FPfXv/71k08++fTTT1vPCACpiO+ZdOsHr37uvmROOplIZMvF7Be/+IW+Eqg99dRTOmaBQGD//v3r
f87ZuXOn2kEmajIzS/SZUFoxc2KHHRwclIW33367qanJipk8l1RWryoyOSsrK1PLm8bs2WefjUQi
xnZHflmZ/6nlFGMmZCZ69OjRP//5z3/729/kddqb10hlZa5pXZUFgBRZPXviuayHUjInnUwksuVi
tunMLCcn54svvli/3fnrX//a09Ojlmtqav7whz9kZWXFT1bSjVlubu6hQ4fkqX/+8587sUmhGbO+
vj7ph15V8vLyzp49q5Y3jZl019h411tvvXXr1i21nHrMtKmpKXkrDh8+bG8wvP7667KbPQoAKZCe
mfeDPJSSOelkIpFHJGa7d+82L/eJzs5Omay8+OKLLS0tetCKmZTm5TWvvPKKHtcxk5xs27btwoUL
Bw4ccFKLmbz+r776Si1/9tlnVsyCwaD1mZmx8S6Jmb474wFipuzYscP8pNAib0tBQdo3zgKAIt2S
eumSPfv2ly5L5qSTiUT8F7NAILBv3771250XXnhhw3v3FxYWzE+h0p2ZObF5zI9//OOuri4nLmYb
XmaUVpWXl6vl7OxsKxuXLl1KPWazs7MPFrP3339fpoD26JqPP/5YZpz2KACkTPfsAb5StqHUM5GI
/2KmbgAxP2oaHh6Or4ImAdMXG2W3lZWV9ds3YMZMJnYywVLL8TeAyAHNGaE80XPPPadvACktLbWu
+L333nupxywcDsdfh0yFdVuKaXV19fnnn29ubrY3AECa3E/ItNQzkYj/YubEbs3fv3+/SpSMy/RF
35o/MjISCoX0njJuXj88cODAmTNn9GoiZsxM8TGrqal5+eWX1S0b0omsrKxPP/1Ub1V3o+gPqPLy
8v7yl7+kHjMnNuNsb283ttvk7Tpy5Ii+Z0RSfezYMf1179bWVnlJ8jLUqizs3btXjqlWAWCLSD0T
ifgyZuL48eMy5dq5c6f0QNqjx8fHx9955x3ZJA177bXXZHl0dFRvXVhYePfdd3//+9//KYUvTduj
G8XMiU2/5DWof/nQ+tK0E/vo7o9//OOrMRKz+O+ZGfveZcWsv79ffgs5QqIvTYurV6+q48uETH5x
iZmulxP7XPD1GNm0bds2+UtAkl8cADyReiYS2VoxAwA8htxngpgBADzmPhPEDADgMfeZIGYAAI+5
zwQxAwB4zH0miBkAwGPuM0HMAAAec58JYgYA8Jj7TBAzAIDH3GeCmAEAPOY+E8QMAOAx95kgZgAA
j7nPBDEDAHjMfSaIGQDAY+4zkYmYZefm20MAAKxxn4lMxKygsHx88t7/oBIAAJMEQjJhj6YpEzEL
9fQVBe79n6ABADBJICQT9miaMhGzSCRa09gmL3dsYtLeBgB4XEkUJA0SCMmEvS1NmYiZc7dnkVB3
X0FhWXZu/qmcCzx48ODB4zF/SA4kCpIGCYTdjPRlKGYAAHx7iBkAwPeIGQDA94gZAMD3iBkAwPeI
GQDA94gZAMD3iBkAwPeIGQDA94gZAMD3iBkAwPeIGQDA94gZAMD3iBkAwPeIGQDA94gZAMD3iBkA
wPeIGQDA94gZAMD3iBkAwPeIGQDA94gZAMD3iBkAwPeIGQDA94gZAMD3iBkAwPcekZhdLQnaQwCA
x0aGYtYR6r5wpfh6WaV6SHs6e/rtnVx4sJhFotHe/iF7FADgN5mLmVmvaDQarGnoeXghebCYrayE
iwJV9igAwG+8iZlYWFy6VlphjrhBzADgceZZzMSFK8V6WaIiaTE2Oo2tN8YmbunV6OpqS0foelml
7CmPQHXD7ZlZvdWM2dz8wpXiwOzcvFqVgxSWV0k45VFWWTe/sKjGFxYXi8qrcuRFBGtKgjW1TW36
CAAAf/EsZhIbc2a2acxKK2q7+wb16vLKyvSdGb2qYxZXssnSytql5WW1OjV9R3Kolh1mZgDwqPAm
ZotLyzIrGhoZ1yPJYzYxOV1Z12RutaiYScPMkgmZk62E1x229Ubn6Pi9wxIzAHg0ZC5m+m7Gy0Xl
2ecullXWhSMRvUPymLV0hAZHxsytFomZKpnMzPRgJBKRmBl73SXHbL/ZrZaJGQA8GjIXM3Nmtrq6
KqupX2asbmiZvn3/omK8gsLy81eKzZKJmbn5ry9JPevMR3Gguu1ml9qBmAHAo8GbmCmlFbWT07fV
clGg2opZQ2vH/ZjVt5ifkMWTmVlz+82mthvm4MzsXLCm0RyxEDMAeDR4GbPGthv9QyNqWcK2uLRk
bq2qazYvM5ofsMVTn5nVNrZ2dPbowXA4Yt7uEY+YAcCjwcuYBWsaptZmZvXN7ROTU+bWvMtF5g0g
VfXN5laLvpuxorbRvOnxWmnF8vKKXrWsrq5eKX6QL6gBALYUz2ImoZJpk+RErU5N3ymrrFOrkWhU
2lYSrLFuze8bHNarMqmaMe5a1DGTI8hxBoZH1er4rcmiQPXSWs/k6LJp3vhoTWonT61XAQB+lLmY
5V8tUd93Vo/qhhZrztQ/NKK+Ey3dkqnYhl+aLiqvKg7WSJ9kHzNC5pemo9Go7KB/VvVMflDqWByo
bm4PmTfrSxFlUB58aRoA/CtDMQMA4NtDzAAAvkfMAAC+R8wAAL5HzAAAvkfMAAC+R8wAAL5HzAAA
vkfMAAC+R8wAAL5HzAAAvpehmEUikVB3X0FhWXZu/qmcCzx48ODB4zF/SA4kCpIGCYTdjPRlImaR
SLSmsS32j/9O2tsAAI8riYKkQQIhmbC3pSkTMQv19MnLtUcBAHAcCYRkwh5NUyZiVlBYPr7+f7wJ
AIAigZBM2KNpykTMsnPz7SEAANa4z0QmYnYq54I9BADAGveZIGYAAI+5zwQxAwB4zH0miBkAwGPu
M0HMAAAec58JYgYA8Jj7TBAzAIDH3GeCmAEAPOY+E8QMAOAx95kgZgAAj7nPBDEDAHjMfSaIGQDA
Y+4zQcwAAB5znwliBgDwmPtMEDMAgMfcZ4KYAQA85j4TxAwA4DH3mSBmbl24cGFsbMwe9drRo0ft
Ie9sqRcDYAtyn4mtFbPS0tI9e/YsLS2Zg+3t7YWFheZIWkZGRv79739/tub48eOXL19eWVmx93tQ
DxazcDjc1NRkjz48D6sfoVBoZmbGHk3Tw3oxADzR2jX+7sHigmCnvSHOl1db95+uuDO37hyeitQz
kciWi9nhw4eLiorMQfcx++qrr8yR6urqb775xhxx48Fitri4+Pnnn9ujD8/D6sfFixeHhobs0TQ9
rBcDwBP/t/3wd3+0Tx7SKnub4Y19BWo3WbC3bSb1TCSy5WJWW1v7ySefmJOzhx4zkZWVtbq6ag0+
GGK2qYf1YgB4QscsSc90yeSx4720ZwupZyKRLRez+vp66Zk5OdswZuXl5cePH5cenDhx4ubNm9ZW
04Yxk5+dnZ1Vy3IoeQpza1dXl/WMLS0tJ0+elKc7depUdnb2jRs39CYzZuFwWHbQW6enp8+ePXvs
2DF5kZ999ll/f78an5mZkUP961//Oh1z6dIlNR4vGAzqHz9z5ozZ+EQHV6x+JN85Go2WlJTI+Ocx
OTk54+PjMh4IBPbv3y+HUq9zcHBQ7b+yslJQUCDj8iPyX/nzMo8m5A/xyJEjsvXcuXPz8/PEDPC1
1q7xJ579WLfqzX2XrR3Mkj39ykkuM96LmZxbzclZfMzkzHvlyhW1LCdWOc/29PSYO5jiYyZzsg8/
/FCeRa1uGjMpSn5+fiQSUavygwMDA3qrjplVMjmJSzJ15+bm5syCpjIza2pqunbtmp5ByhHkKdRy
8oM762O26c6SycbGRr0qr03vvOHMTPbv6OhQy/K25OXlmXWXP0F5T9TbOzU1df78eWIG+F2inkm3
ntt11mXJnHQykchWjJks1NTU6MmZFbPl5eUDBw7oFInbt2/LnEOvWqyYycn3aoweSR4z6cfBgweT
XJNUMVMlM+eI169ftxIrCZRBtZxKzL7++muZUdmjMckP7qyPWfKdZTnJJ4jxMevu7rb+bqF+d7Us
b6+U0twqP/73v//dHAHgR/E9k2794NXP3ZfMSScTiWzRmJmTMytmnZ2delqmyQlUImcNKubdjLLb
nj17pIXSP71D8pj19/cXFCT7MFNiJudrKVMoFDLHpa9WAmVVJpFqOZWYyQS0ubnZHo1JfnBnfcyS
7yzPYs6rLPExk78HmBNTRf8uvb29Mptcv9HZu3evNQLAj6yePfFc1kMpmZNOJhLZojFzjMmZFbPa
2tqqqiq9qpw7d25iYsIaVOIvM8oJNysra35+Xq0mj1lra2tFRYW51SIx++ijj9ra2qzxDz/88MuN
qK2pxExmOXJwefENDQ361SrJD+6sj1nyneUpRkdH9c6W+JjJ65F5mHWoQ4cOqW87yB+f/MGZ+wvZ
ao0A8CnpmXk/yEMpmZNOJhLZujHTk7NUYpabm3vr1i1rUImPmZAjBAIBtSwLyWNWWVlpbrVIDOQI
Uib9oZpy+PBhc9WSSswUmXF2dHTk5ORIV/Rg8oM762OWfOfkd2NuGDNzXmvZMGYHDx60RgD4l3RL
6qVL9uzbX7osmZNOJhLZujFz1iZn8ZcZL1+276U5duxYksuM8THr7u7Oz89Xy9KqlpYWc2tzc7N5
mTH+6UwqBjIzkwmKeTXvyJEj5gd7Fol0ijHTgsGgvk0j+cGd9TFLvnNJSUmS20EvXbpkxUzejb6+
PnPExGVG4HGge/YAXynbUOqZSGRLx0zmOllZWQ0NDfE3gJjZkIlCkvvlNoyZPIuemcmpXE7o5ta8
vDzzBpAUZzZ1dXXmnRRyTrc+RTNJXZK85g2ZrzP5wZ31MUu+88DAwPnz5+3RNfI+yDzVHJHV+M8s
Nfkjs27G4QYQ4FHlfkKmpZ6JRLZ0zJzYv9bxv//9z7p9Tt2ar3ombZMpTlq35kv8pE/63nTJlZz6
9YdS0s6cnBzzGWVKJNMRPbmRBTmm3mpeppNA6mmcHFCmROYVvOHhYXNOc/z4cfM48SQb+tKlumNQ
fz9s04ObMdt05y+++MKcmy4uLk5OTqpleQ360zUtOzu7tbVVr87MzJirEnVuzQeQltQzkchWj5mc
zT/44AMrZk7srg0JkvRATpRJbsZzYjHbu3ev+jrw57FvPcvZWX0pWJNVGVc7SMw2/NK07KC+Oyz7
mDcZWp85Xb9+XX4LtSzVlC6ePHlS/WB+fr75wZ4EQwblsIm+NC1VOBYj8Thx4oT1ziQ/uNWP5Dur
L03L76Vez5kzZ6R2eqsUWt5neQ36S9NSVvnLhLwkdTQ5cnd3t97fib1yvjQNIHWpZyKRrRUzAMBj
yH0miBkAwGPuM0HMAAAec58JYgYA8Jj7TBAzAIDH3GeCmAEAPOY+E8QMAOAx95kgZgAAj7nPBDED
AHjMfSaIGQDAY+4zQcwAAB5znwliBgDwmPtMEDMAgMfcZ4KYAQA85j4TmYhZdu69/6czAADx3Gci
EzErKCwfn5yyRwEAcBwJhGTCHk1TJmIW6ukrClTbowAAOI4EQjJhj6YpEzGLRKI1jW3ycscmJu1t
AIDHlURB0iCBkEzY29KUiZg5d3sWCXX3FRSWZefmn8q5wIMHDx48HvOH5ECiIGmQQNjNSF+GYgYA
wLeHmAEAfI+YAQB8j5gBAHyPmAEAfI+YAQB8j5gBAHyPmAEAfI+YAQB8j5gBAHyPmAEAfO//AVSg
rbtJq2HnAAAAAElFTkSuQmCC
`;
