// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/pipeline-tuning-best-result/v1/type.ts' --type 'ConfigPipelineTuningBestResultConfig'
export const schemaConfigPipelineTuningBestResult = {
  $ref: '#/definitions/ConfigPipelineTuningBestResultConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<PipelineTuningBestResultConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigPipelineTuningBestResultConfig: {
      $ref: '#/definitions/Config%3CPipelineTuningBestResultConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'PipelineTuningBestResultV1ReportData'
export const schemaReportTypePipelineTuningBestResult = {
  $ref: '#/definitions/PipelineTuningBestResultV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    OptimizationMethod: {
      additionalProperties: false,
      properties: {
        id: {
          const: 'grid_search_cross_validation',
          type: 'string',
        },
        nSplits: {
          type: 'number',
        },
      },
      required: ['id', 'nSplits'],
      type: 'object',
    },
    ParameterType: {
      additionalProperties: false,
      description:
        'Describes the actual used parameters during one single parameter optimization run',
      properties: {
        name: {
          description:
            'Parameter name in the form: "clf|preprocessing__<node_id>__<parameter_id>" For example: "clf__decision_tree_classifier__min_samples_leaf"',
          type: 'string',
        },
        value: {
          description:
            'Actual used value of the parameter during one parameter optimization run',
          type: ['string', 'number'],
        },
      },
      required: ['name', 'value'],
      type: 'object',
    },
    PathType: {
      additionalProperties: false,
      description: 'Describes one single path through a pipeline',
      properties: {
        path: {
          description: 'List of the nodes passed',
          items: {
            type: 'string',
          },
          type: 'array',
        },
        pathId: {
          type: 'string',
        },
        pipelineId: {
          type: 'string',
        },
      },
      required: ['pathId', 'pipelineId', 'path'],
      type: 'object',
    },
    PipelineResultType: {
      additionalProperties: false,
      description:
        'Describes one pipeline (including the schema, the set parameters) and the paths taken through this pipeline during the training process',
      properties: {
        displayName: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        paths: {
          items: {
            $ref: '#/definitions/PathType',
          },
          type: 'array',
        },
        schema: {
          $ref: '#/definitions/PipelineTuningValue',
        },
      },
      required: ['id', 'displayName', 'schema', 'paths'],
      type: 'object',
    },
    PipelineTuningBestResultV1ReportData: {
      $ref: '#/definitions/PipelineTuningResultsType',
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
    },
    PipelineTuningResultsType: {
      additionalProperties: false,
      properties: {
        bestModel: {
          anyOf: [
            {
              $ref: '#/definitions/SingleParametersResult',
            },
            {
              $ref: '#/definitions/SinglePathResult',
            },
          ],
        },
        kpi: {
          description:
            'KPI the model has been optimized against during training',
          type: 'string',
        },
        pipelines: {
          description:
            'The schema of the pipeline used, including the derived steps',
          items: {
            $ref: '#/definitions/PipelineResultType',
          },
          type: 'array',
        },
        singleResults: {
          items: {
            anyOf: [
              {
                $ref: '#/definitions/SingleParametersResult',
              },
              {
                $ref: '#/definitions/SinglePathResult',
              },
            ],
          },
          type: 'array',
        },
      },
      required: ['bestModel', 'kpi', 'pipelines', 'singleResults'],
      type: 'object',
    },
    PipelineTuningValue: {
      additionalProperties: false,
      description: 'Type for the value of the form field',
      properties: {
        displayName: {
          description: 'Display name of the Pipeline',
          type: 'string',
        },
        edges: {
          description: 'List of edges for the pipeline',
          items: {
            additionalProperties: false,
            properties: {
              sourceID: {
                description: 'ID of the source node',
                type: 'string',
              },
              targetID: {
                description: 'ID of the target node',
                type: 'string',
              },
            },
            required: ['sourceID', 'targetID'],
            type: 'object',
          },
          type: 'array',
        },
        id: {
          description: 'ID of the Pipeline',
          type: 'string',
        },
        nodes: {
          description: 'List of nodes of the pipeline',
          items: {
            $ref: '#/definitions/PipelineTuningValueNodeOrGroup',
          },
          type: 'array',
        },
        optimizationMethod: {
          $ref: '#/definitions/OptimizationMethod',
          description: 'Optimization method for this pipeline',
        },
      },
      required: ['id', 'displayName', 'optimizationMethod', 'nodes', 'edges'],
      type: 'object',
    },
    PipelineTuningValueNode: {
      additionalProperties: false,
      description: 'Type for a node of type "node"',
      properties: {
        description: {
          $ref: '#/definitions/ToBeRefined',
          description: 'Description of the node',
        },
        displayName: {
          description: 'Name of the node',
          type: 'string',
        },
        id: {
          description: 'ID of the node',
          type: 'string',
        },
        isActive: {
          type: 'boolean',
        },
        isEditable: {
          description: 'Is the node editable (for static parameters)?',
          type: 'boolean',
        },
        isTuneable: {
          description: 'Is the node tunable?',
          type: 'boolean',
        },
        nodeType: {
          description: 'Sub-type of the node',
          enum: ['transformer', 'classifier'],
          type: 'string',
        },
        staticParameters: {
          description: 'Selected static parameters',
          items: {
            $ref: '#/definitions/SelectedStaticParameter',
          },
          type: 'array',
        },
        tuningParameters: {
          description: 'Selected parameters',
          items: {
            $ref: '#/definitions/SelectedTuningParameter',
          },
          type: 'array',
        },
        type: {
          const: 'node',
          description: 'Type of the node = "node"',
          type: 'string',
        },
      },
      required: [
        'id',
        'type',
        'nodeType',
        'displayName',
        'description',
        'isTuneable',
        'isEditable',
      ],
      type: 'object',
    },
    PipelineTuningValueNodeOrGroup: {
      anyOf: [
        {
          $ref: '#/definitions/PipelineTuningValueNode',
        },
        {
          additionalProperties: false,
          description: 'Type for a node of type "group"',
          properties: {
            id: {
              description: 'ID of the node',
              type: 'string',
            },
            nodes: {
              description: 'Nodes of the group',
              items: {
                $ref: '#/definitions/PipelineTuningValueNode',
              },
              type: 'array',
            },
            type: {
              const: 'group',
              description: 'Type of the node = "group"',
              type: 'string',
            },
          },
          required: ['id', 'type', 'nodes'],
          type: 'object',
        },
      ],
    },
    SelectedStaticParameter: {
      additionalProperties: false,
      properties: {
        displayName: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        value: {
          type: ['string', 'number'],
        },
      },
      required: ['id', 'displayName', 'value'],
      type: 'object',
    },
    SelectedTuningParameter: {
      additionalProperties: false,
      properties: {
        displayName: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        valueCandidates: {
          items: {
            type: ['string', 'number'],
          },
          type: 'array',
        },
      },
      required: ['id', 'displayName', 'valueCandidates'],
      type: 'object',
    },
    SingleParametersResult: {
      additionalProperties: false,
      properties: {
        algorithm: {
          type: 'string',
        },
        parameters: {
          items: {
            $ref: '#/definitions/ParameterType',
          },
          type: 'array',
        },
        score: {
          type: 'number',
        },
      },
      required: ['algorithm', 'parameters', 'score'],
      type: 'object',
    },
    SinglePathResult: {
      additionalProperties: false,
      description:
        'Type for the result of one tuning step of one path followed through one pipeline. Since:\n- there are several pipeline schemas, the pipelineId is tracked\n- there are several paths through a pipeline, the pathId is tracked\n- there are several parameter constellations for a path, the parameters are tracked',
      properties: {
        parameters: {
          items: {
            $ref: '#/definitions/ParameterType',
          },
          type: 'array',
        },
        pathId: {
          type: 'string',
        },
        pipelineId: {
          type: 'string',
        },
        score: {
          type: 'number',
        },
      },
      required: ['parameters', 'pathId', 'pipelineId', 'score'],
      type: 'object',
    },
    ToBeRefined: {
      description:
        'This is a marker type to denote that a type has to be refined further. Instead of simply using any, using this type allows for a project-wide search later.\n\nIn the optimal case this type is not even necessary anymore in the near future once the migration from JavaScript to Typescript is done.\n\nTODO This type is currently also defined in the frontend repository. Remove this type declaration from the "frontend" for this one.',
    },
  },
};
