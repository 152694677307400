import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import {
  TextInputLineAugurSettings,
  TextInputLineConfig,
  TextInputLineValidationError,
} from './type';
import InputError from '../../../../../atoms/input-error/InputError';
import { AugurSettingsProps } from '../../types/meta';

export interface TextInputLineDevProps {
  wrapMultipleErrorLines?: boolean;
}

export type Props = TextInputLineDevProps &
  AugurSettingsProps<
    TextInputLineAugurSettings,
    TextInputLineConfig,
    TextInputLineValidationError
  >;

const TextInputLine: FC<Props> = ({
  config: { placeholder },
  wrapMultipleErrorLines,
  disabled,
  onChange,
  onBlur,
  isTouched,
  isValidating,
  error,
  name,
  inputRef,
  invalid,
  value,
  isDirty,
  readOnly,
}) => {
  const isSuccess = !invalid && !readOnly && !isValidating;
  const isPending = isValidating && isDirty && !readOnly;

  const fieldClassName = classNames(
    styles.TextInputLineInput,
    isTouched && {
      [styles.error]: error,
      [styles.success]: isSuccess,
      [styles.pending]: isPending,
    }
  );

  return (
    <div className={styles.TextInputLine}>
      <div className={styles.TextInputLineHeader}>
        <div
          className={styles.TextInputLineError}
          style={{
            whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
          }}
        >
          {error && (
            <InputError
              touched={isTouched}
              error={{
                id: 'no-id',
                defaultMessage: error,
              }}
            />
          )}
        </div>
      </div>
      <input
        className={fieldClassName}
        type='text'
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        ref={inputRef}
        value={value ?? ''} // force controlled input
        readOnly={readOnly}
        aria-invalid={error ? 'true' : 'false'}
      />
      {isSuccess && (
        <div className={styles.TextInputLineSuccess}>
          <span className='icon-ok' />
        </div>
      )}
      {isPending && (
        <div className={styles.TextInputLinePending}>
          <span className='icon-refresh icon-spin' />
        </div>
      )}
    </div>
  );
};

export default TextInputLine;
