import repositoryMsgs from 'common/dist/messages/repository';
import {
  REPO_TYPE,
  RepoType,
  repoTypeToSpeaking,
} from 'common/dist/types/repository';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';
import BreadCrumbs from '../../atoms/bread-crumbs/BreadCrumbs';
import {
  collaborationSpaceRoutes,
  overviewAppsLink,
  overviewCodeCapsulesLink,
  overviewModulesLink,
  overviewPlainLink,
} from '../../collaborationSpace/routes';

type Props = {
  repositoryType?: RepoType;
};

const RepositoryBreadcrumbs: FC<Props> = ({ repositoryType }) => {
  const intl = useIntl();

  let linkTo = `${collaborationSpaceRoutes.basePath}`;
  if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
    linkTo = overviewCodeCapsulesLink();
  } else if (repositoryType === REPO_TYPE.APP) {
    linkTo = overviewAppsLink();
  } else if (repositoryType === REPO_TYPE.PLAIN) {
    linkTo = overviewPlainLink();
  } else if (repositoryType === REPO_TYPE.MODULE) {
    linkTo = overviewModulesLink();
  }
  const speakingRepoType = repoTypeToSpeaking[repositoryType];

  const message = speakingRepoType
    ? intl.formatMessage(repositoryMsgs.backToOverview, { speakingRepoType })
    : `Back to Browser`;
  return (
    <div className={styles.breadCrumbs}>
      <BreadCrumbs
        backToProps={[
          {
            linkTo,
            label: message,
          },
        ]}
      />
    </div>
  );
};

export default RepositoryBreadcrumbs;
