import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component, Fragment } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import TextInputCol from './dialog-settings-cols/TextInputCol';
import ModalDialogVariables from './ModalDialogVariables.container';
import { ButtonProps } from '../../../../../../../../atoms/button/Button';
import Modal from '../../../../../../../../organisms/modal/Modal';
import {
  fieldNameDescription,
  fieldNameLabel,
  fieldNameVariableName,
} from '../python3-input-cell/elements/_interface/input-element.form';
import {
  AsInputElement,
  AsVariable,
} from '../python3-input-cell/elements/_interface/P3InputElement';
import { p3AdditionalSettingsForType as p3InputAdditionalSettingsForType } from '../python3-input-cell/elements/_interface/Python3InputElementManager';
import { AsOutputElement } from '../python3-output-cell/elements/_interface/P3OutputElement';
import { p3AdditionalSettingsForType as p3OutputAdditionalSettingsForType } from '../python3-output-cell/elements/_interface/Python3OutputElementManager';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

type Props = {
  inputOrOutput: 'input' | 'output';
  isOpen: boolean;
  element: AsInputElement | AsOutputElement;
  path: string;
  cellId: string;
  formValues: ToBeRefined;
  valid: boolean;
  selectVariable: {
    active: boolean;
    path: string;
    cellId: string;
    elementFieldName: string;
    validTypes: string[];
    label?: string;
  };
  variables: AsVariable[];

  closeDialog: () => void;
  updateInputElement: (
    path: string,
    cellId: string,
    elementId: string,
    formValues: ToBeRefined
  ) => void;
  updateOutputElement: (
    path: string,
    cellId: string,
    elementId: string,
    formValues: ToBeRefined
  ) => void;
  showSelectVariable: (
    path: string,
    cellId: string,
    name: string,
    validVariableTypes: string[],
    label: string
  ) => void;
  hideSelectVariable: () => void;
};

export default class ModalDialog extends Component<Props & InjectedFormProps> {
  static defaultProps = {
    variables: [],
    selectVariable: {},
  };

  renderCommonSettingsInput() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameVariableName}
            label={'Variable name'}
            component={TextInputCol}
            placeholder={'Enter Variable Name'}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameLabel}
            label={'Label (optional)'}
            component={TextInputCol}
            placeholder={'Enter Label'}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameDescription}
            label={'Description (optional)'}
            component={TextInputCol}
            placeholder={'Enter Description'}
          />
        </div>
      </Fragment>
    );
  }

  renderCommonSettingsOutput() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameLabel}
            label={'Label (optional)'}
            component={TextInputCol}
            placeholder={'Enter Label'}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameDescription}
            label={'Description (optional)'}
            component={TextInputCol}
            placeholder={'Enter Description'}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      isOpen,
      closeDialog,
      formValues,
      path,
      cellId,
      element,
      updateInputElement,
      updateOutputElement,
      valid,
      variables,
      selectVariable,
      showSelectVariable,
      hideSelectVariable,
      inputOrOutput,
    } = this.props;

    let AdditionalSettings = undefined;
    if (inputOrOutput === 'input') {
      AdditionalSettings = p3InputAdditionalSettingsForType(element.type);
    } else if (inputOrOutput === 'output') {
      AdditionalSettings = p3OutputAdditionalSettingsForType(element.type);
    }

    const buttons: ButtonProps[] = [
      {
        color: 'white',
        label: 'Cancel',
        onClick: () => {
          closeDialog();
          hideSelectVariable();
        },
      },
      {
        color: 'primary',
        label: 'OK',
        onClick: () => {
          if (valid) {
            if (inputOrOutput === 'input') {
              updateInputElement(path, cellId, element.id, formValues);
            } else if (inputOrOutput === 'output') {
              updateOutputElement(path, cellId, element.id, formValues);
            }
            closeDialog();
            hideSelectVariable();
          }
        },
      },
    ];

    return (
      <Modal
        show={isOpen}
        buttons={buttons}
        hideModal={() => {
          closeDialog();
          hideSelectVariable();
        }}
      >
        <div className={'modal-parent'}>
          <div className={'modal-body'}>
            <div className={'modal-body-left'}>
              <div className={'modal-body-headline'}>
                <span>Settings</span>
              </div>

              {inputOrOutput === 'input' && this.renderCommonSettingsInput()}
              {inputOrOutput === 'output' && this.renderCommonSettingsOutput()}

              {AdditionalSettings && (
                <Fragment>
                  <div className={'horizonal-separator'} />
                  <AdditionalSettings
                    path={path}
                    cellId={cellId}
                    variables={variables}
                    showSelectVariable={showSelectVariable}
                  />
                </Fragment>
              )}
            </div>

            {selectVariable.active && (
              <Field
                name={selectVariable.elementFieldName}
                variables={variables}
                component={ModalDialogVariables}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
