import classNames from 'classnames';
import commonMessages from 'common/dist/messages/common';
import orchestrationMessages from 'common/dist/messages/orchestration';
import {
  DiscreteProgressSteps,
  Job as JobT,
  JobTypeToSpeaking,
  StatusType,
  SuperTypeType,
} from 'common/dist/types/job';
import React, { memo, ReactElement, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import {
  FiBook,
  FiChevronDown,
  FiChevronsDown,
  FiChevronsUp,
  FiChevronUp,
  FiEdit,
  FiMonitor,
  FiServer,
  FiTrash2,
} from 'react-icons/fi';

import styles from './styles.module.scss';
import { useModuleByCode } from '../../../../core/api/modules';
import { speakingStatus } from '../../../orchestration/common/jobStatus';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';
import {
  PRIORITY_HIGH,
  PRIORITY_HIGHEST,
  PRIORITY_LOW,
  PRIORITY_LOWEST,
  PRIORITY_MEDIUM,
} from '../../../runCodeCapsuleModal/runCodeCapsule.form';

/**
 * Derive the additional CSS class dependent on the node status
 *
 * @param status
 * @returns {string}
 */
function nodeStatusClass(status: StatusType): string {
  switch (status) {
    case 'cancelled':
      return styles.statusCancelled;
    case 'failure':
      return styles.statusFailure;
    case 'running':
      return styles.statusRunning;
    case 'success':
      return styles.statusSuccess;
    case 'triggered':
      return styles.statusTriggered;
    case 'waiting':
      return styles.statusWaiting;
    case 'waiting-in-execution-queue':
      return styles.statusWaitinInExecutionQueue;
    default:
      return '';
  }
}

export function speakingSuperType(superType: SuperTypeType): string {
  switch (superType) {
    case 'augur':
      return 'Augur';
    case 'code-capsule':
      return 'Code Capsule';
    case 'app':
      return 'App';
    case 'backup':
      return 'Backup';
    case 'module':
      return 'Module';
    default:
      return 'Unknown';
  }
}

export function getName(
  node: JobT,
  augurName: string,
  codeCapsuleName: string
): string {
  if (node.superName) return node.superName;
  // Prio 1 if it is given.
  else if (node.superType === 'augur' && augurName) return augurName;
  else if (node.superType === 'code-capsule') return codeCapsuleName;
  // If it's a code capsule, use its name
  else if (node.superType === 'app') return node.appName;
  // If it's a code capsule, use the App Name // TODO The field appName is not there for job yet, so the field will be empty!
  else if (node.superType === 'backup') return `${node.templateId}`;
  else if (node.superType === 'module') return node.name;
  return '- no name -';
}

export function renderJobPriority(
  job: JobT,
  size: 'slim' | 'large'
): ReactElement {
  switch (job.priority) {
    case PRIORITY_HIGHEST:
      return (
        <div className={classNames(styles.jobPriority, styles.priorityHighest)}>
          <FiChevronsUp size={size == 'slim' ? 10 : 16} />
        </div>
      );
    case PRIORITY_HIGH:
      return (
        <div className={classNames(styles.jobPriority, styles.priorityHigh)}>
          <FiChevronUp size={size == 'slim' ? 10 : 16} />
        </div>
      );
    case PRIORITY_MEDIUM:
      return null;
    case PRIORITY_LOW:
      return (
        <div className={classNames(styles.jobPriority, styles.priorityLow)}>
          <FiChevronDown size={size == 'slim' ? 10 : 16} />
        </div>
      );
    case PRIORITY_LOWEST:
      return (
        <div className={classNames(styles.jobPriority, styles.priorityLowest)}>
          <FiChevronsDown size={size == 'slim' ? 10 : 16} />
        </div>
      );
    default:
      return null;
  }
}

export function renderSlimIcon(node: JobT): ReactElement {
  switch (node.superType) {
    case 'augur':
      return <span className='icon-augur' />;
    case 'code-capsule':
      return <span className='icon-code-capsule' />;
    case 'app':
      return <FiMonitor size={16} />;
    case 'module':
      return <FiBook size={16} />;
    case 'backup':
      return <FiServer size={16} />;
  }
}

export interface Props {
  job: JobT;
  /** Should the status (for example 'Waiting') be shown in the Job? */
  showJobStatus: boolean;
  /** For example if the job details are supposed to be shown on click */
  onClick?: (jobCode: string) => void;
  /** Discrete progress for running jobs */
  progressSteps?: DiscreteProgressSteps;
  augurName?: string;
  codeCapsuleName?: string;
  /** Is the handle disabled (used for css, since nodesConnectable in ReactFlow i.a. does not change the cursor */
  handlesDisabled?: boolean;
  /** Flag indicating whether the handles should be visible or not, default true. */
  handlesVisible?: boolean;
  /** Show edit and or delete button in the top right? */
  editable?: boolean;
  /** If missing the button will be missing too */
  onClickEdit?: (jobCode: string) => void;
  /** If missing the button will be missing too */
  onClickDelete?: (jobCode: string) => void;
}

/**
 * Functional component for a react flow node
 * Accepts the props as the field data inside all passed props TODO?, What about defaultProps?
 */
export const JobNodeLarge = memo(({ data }: { data: Props }) => {
  const {
    job,
    augurName,
    codeCapsuleName,
    progressSteps,
    showJobStatus,
    onClick,
    handlesDisabled = true,
    handlesVisible = true,
    editable = false,
    onClickDelete,
    onClickEdit,
  } = data;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  let moduleCode = undefined;
  if (job.superType === 'module') moduleCode = job.moduleCode;
  const module = useModuleByCode(moduleCode, moduleCode !== undefined);

  return (
    <>
      {handlesVisible && (
        <Handle
          type='target'
          position={Position.Left}
          className={classNames(styles.flowHandleLarge, styles.flowHandleLeft, {
            [styles.flowHandleDisabled]: handlesDisabled,
          })}
        />
      )}

      <div
        className={classNames(
          styles.job,
          styles.jobLarge,
          nodeStatusClass(job.status),
          { [styles.clickable]: onClick !== undefined }
        )}
        onClick={() => {
          if (onClick) onClick(job.jobCode);
        }}
      >
        {renderJobPriority(job, 'large')}
        {progressSteps && progressSteps.total > 0 && (
          <div className={styles.progress}>
            {progressSteps.started}/{progressSteps.total}
          </div>
        )}
        {editable && (
          <div className={styles.buttons}>
            {onClickDelete && (
              <FiTrash2
                className={classNames(styles.button, styles.deleteButton)}
                onClick={() => {
                  setShowConfirmModal(true);
                }}
              />
            )}
            {onClickEdit && (
              <FiEdit
                className={classNames(styles.button, styles.editButton)}
                onClick={() => {
                  onClickEdit(job.jobCode);
                }}
              />
            )}
          </div>
        )}
        <div>
          <strong>{speakingSuperType(job.superType)}</strong>
        </div>
        {/** TODO fix styles, especially the text-overflow */}
        <div className={styles.jobType}>
          {getName(job, augurName, codeCapsuleName)}
          {moduleCode && module?.data?.name}
        </div>

        <div className={styles.jobName}>
          {JobTypeToSpeaking[job.jobType]}
          <span style={{ fontStyle: 'italic' }}>
            {job.name && `: ${job.name}`}
          </span>
        </div>

        {job.superType === 'code-capsule' && (
          <div className={styles.codeCapsuleVersion}>
            <span>{job.capsuleVersionNumber}</span>
          </div>
        )}

        {job.superType === 'module' && (
          <div className={styles.codeCapsuleVersion}>
            <span>{job.moduleVersionNumber}</span>
          </div>
        )}
        {job.superType === 'app' && (
          <div className={styles.codeCapsuleVersion}>
            <span>{job.appVersionNumber}</span>
          </div>
        )}
        {showJobStatus && (
          <>
            <div className={styles.jobStatus}>{speakingStatus(job.status)}</div>
            <div className={styles.statusBar} />
            {job.status === 'running' && (
              <div
                className={classNames(
                  styles.statusBar,
                  styles.statusBarBackground
                )}
              />
            )}
          </>
        )}
      </div>

      {handlesVisible && (
        <Handle
          type='source'
          position={Position.Right}
          className={classNames(
            styles.flowHandleLarge,
            styles.flowHandleRight,
            {
              [styles.flowHandleDisabled]: handlesDisabled,
            }
          )}
        />
      )}

      <ConfirmationModal
        secure={false}
        show={showConfirmModal}
        payload={{}}
        buttonConfirmAction={() => {
          if (onClickDelete) onClickDelete(job.jobCode);
          setShowConfirmModal(false);
        }}
        hideModal={() => setShowConfirmModal(false)}
        buttonConfirmText={commonMessages.delete}
        buttonConfirmColor={'red'}
        description={orchestrationMessages.jobDeleteModalDescription}
        headline={orchestrationMessages.jobDeleteModalHeadline}
        headlineColor={'red'}
      />
    </>
  );
});

export const JobNodeSlim = memo(({ data }: { data: Props }) => {
  const { job, onClick, showJobStatus } = data;
  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        className={classNames(styles.flowHandleSlim, styles.flowHandleLeft)}
      />

      <div
        className={classNames(
          styles.job,
          styles.jobSlim,
          nodeStatusClass(job.status),
          {
            [styles.clickable]: onClick !== undefined,
          }
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick(job.jobCode);
        }}
      >
        {renderJobPriority(job, 'slim')}
        {renderSlimIcon(job)}
        {showJobStatus && (
          <>
            <div className={styles.statusBar} />
            {job.status === 'running' && (
              <div
                className={classNames(
                  styles.statusBar,
                  styles.statusBarBackground
                )}
              />
            )}
          </>
        )}
      </div>

      <Handle
        type='source'
        position={Position.Right}
        className={classNames(styles.flowHandleSlim, styles.flowHandleRight)}
      />
    </>
  );
});
export const nodeTypes = {
  jobNodeSlim: JobNodeSlim,
  jobNodeLarge: JobNodeLarge,
};

const Job = JobNodeLarge;
export default Job;
