import { habitatFromGroupName } from 'common/dist/constants/keycloak';
import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Group } from '../../../../store/admin/state.types';
import { NamesState } from '../../../../store/names/state.types';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import styles from '../styles.module.scss';

export const adminAddHabitatsLink = `${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}`;
export const adminHabitatDetailsLink = (groupId: string): string =>
  `${usersRoutes.basePath}/${usersRoutes.habitats.path}/${groupId}`;

export interface Props {
  loadGroups: (fetchPermissions: boolean) => void;
  fetchNamesForAdminPermission: () => void;
  data: Group[];
  loading: boolean;
  loaded: boolean;
  error?: string;
  names: NamesState;
}

const Habitats: FC<Props> = ({
  loadGroups,
  fetchNamesForAdminPermission,
  data,
  names,
  loading,
}) => {
  const intl = useIntl();

  useEffect(() => {
    loadGroups(false);
    fetchNamesForAdminPermission();
  }, [loadGroups, fetchNamesForAdminPermission]);

  const EntryElement: FC<{ groups: (Group & { speakingName: string })[] }> = ({
    groups,
  }) => {
    if (!groups[0].speakingName) {
      // This happens e.g. if the Habitat exists in the Dashboard but not in Keycloak, early exit:
      return (
        <div style={{ cursor: 'default' }} className={'ct-row'}>
          <div className={'ct-col ct-col-flex-grow'}>
            <span style={{ fontStyle: 'italic' }}>{'<Unknown Habitat>'}</span>
          </div>
        </div>
      );
    }

    return (
      <LinkWithQuery
        to={adminHabitatDetailsLink(
          `${
            groups.find((g) => _.isEqual(g.attributes.permissions, ['edit']))
              ?.id
          }&${
            groups.find((g) => _.isEqual(g.attributes.permissions, ['view']))
              ?.id
          }`
        )}
        style={{ textDecoration: 'none' }}
      >
        <div className={'ct-row'} data-testid={groups[0].speakingName}>
          <div className={'ct-col ct-col-flex-grow'}>
            <span>{groups[0].speakingName}</span>
          </div>
        </div>
      </LinkWithQuery>
    );
  };

  const renderHeadline = () => {
    return (
      <div className={'ct-headline ct-row'}>
        <div className={'ct-col ct-col-flex-grow'}>
          <span>Name</span>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={'ct-list'}>
        {renderHeadline()}
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i} className={'ct-row'}>
            <div className={'ct-col ct-col-200px'}>
              <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.listPageParent}>
      <div className={styles.pagingWrapper}>
        <div className={'ct-list'}>
          {renderHeadline()}
          {data &&
            Object.entries(
              _.groupBy(
                data
                  .filter((g) => _.isEqual(g.attributes.isHabitat, ['true']))
                  .map((g) => ({
                    ...g,
                    habitatCode: habitatFromGroupName(g.name),
                  })),
                'habitatCode'
              )
            )
              .map(([_, groups]) =>
                groups.map((group) => ({
                  ...group,
                  speakingName:
                    names.habitatNames[habitatFromGroupName(group.name)],
                }))
              )
              .sort((a, b) => (a[0].speakingName > b[0].speakingName ? 1 : -1))
              .map((groups) => <EntryElement groups={groups} />)}
        </div>
      </div>
    </div>
  );
};
export default Habitats;
