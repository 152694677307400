import _ from 'lodash';

export const scheduleType = {
  EVALUATION: 'evaluation',
  PREDICTION: 'prediction',
  LEARNING: 'learning',
  INITIAL_LEARNING: 'initial-learning',
} as const;
export type ScheduleTypeType = typeof scheduleType[keyof typeof scheduleType];
export const scheduleTypeValues = _.values(scheduleType);
export const jobType = scheduleType;

export const scheduleMode = {
  SCHEDULED: 'scheduled',
  MANUAL: 'manual',
  TRIGGER: 'trigger',
};
export const scheduleModeValues = _.values(scheduleMode);

export const schedulePeriod = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};
export const schedulePeriodValues = _.values(schedulePeriod);

export const JOB_STATUS = {
  PENDING: 'pending',
  FINISHED: 'finished',
  FAILED: 'failed',
} as const;
export type JobStatus = typeof JOB_STATUS[keyof typeof JOB_STATUS];
