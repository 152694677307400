import React, { FC, useEffect } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { event as EVENT_NOTIFICATION } from '../../../../core/notifications';
import {
  clearCassandraCredentials,
  fetchCassandraCredentials,
} from '../../../../redux/modules/data.cassandra.module';
import { sendNotification } from '../../../../redux/modules/notifications.module';
import { CassandraCredentialsState } from '../../../../store/dataManagement/state.types';
import { ReduxActUnknown3 } from '../../../../store/reduxAct';
import { RootState } from '../../../../store/store';
import Button from '../../../atoms/button/Button';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import TextInputLinePassword from '../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';
import ClipboardWrapper from '../../../molecules/clipboard/ClipboardWrapper';

export type Props = {
  dataSourceCode: string;
};
const Authentication: FC<Props & WrappedComponentProps> = (props) => {
  const credentials = useSelector<RootState, CassandraCredentialsState>(
    (state) => state.data.cassandra[props.dataSourceCode]?.credentials
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // "componentWillUnmount"
    return () => {
      if (props.dataSourceCode) {
        dispatch(clearCassandraCredentials(props.dataSourceCode));
      }
    };
  }, [dispatch, props.dataSourceCode]);

  return (
    <div>
      <div className={styles.inputRow}>
        <div className={styles.inputParent}>
          <TextInputLine
            touched={true}
            error={undefined}
            id={'cassandra-username'}
            labelId={'datapools.cassandra.auth.username'}
            labelDefault={'Username'}
            onChange={() => undefined}
            value={credentials?.data?.username}
            validating={false}
            valid={undefined}
            hasLabel={true}
            disabled={true}
            onBlur={() => undefined}
            onFocus={() => undefined}
            placeholderDefault={undefined}
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputParent}>
          <TextInputLinePassword
            touched={true}
            error={undefined}
            id={'cassandra'} // -password is appended to the id internally... wtf?
            label={{
              id: 'datapools.cassandra.auth.password',
              defaultMessage: 'Password',
            }}
            onChange={() => undefined}
            value={credentials?.data?.password}
            disabled={true}
          />
        </div>
      </div>

      <div className={styles.bottomRow} data-testid={'bottomRow'}>
        {credentials?.error && (
          <span className={styles.Error}>{credentials.error}</span>
        )}

        <div>
          {!credentials?.error && credentials?.data && (
            <span>
              {props.intl.formatMessage({
                id: 'datapools.cassandra.auth.validUntil',
              })}
              &nbsp;
              {props.intl.formatDate(credentials?.data?.expiresAt)}&nbsp;
              {props.intl.formatTime(credentials?.data?.expiresAt)}
            </span>
          )}
        </div>

        <div className={styles.buttonLine}>
          <Button
            label={'Request Credentials'}
            color={'primary'}
            onClick={() =>
              dispatch(fetchCassandraCredentials(props.dataSourceCode))
            }
            disabled={!props.dataSourceCode || credentials?.loading}
            isBusy={credentials?.loading}
          />

          <ClipboardWrapper
            data={
              credentials?.data?.username + '\n' + credentials?.data?.password
            }
          >
            <Button
              label={'Copy to Clipboard'}
              color={'secondary'}
              onClick={() => {
                dispatch(
                  (sendNotification as ReduxActUnknown3)(
                    'Credentials Copied',
                    'Credentials successfully copied to your Clipboard',
                    EVENT_NOTIFICATION
                  )
                );
              }}
              disabled={!credentials?.data}
            />
          </ClipboardWrapper>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Authentication);
