import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { S3FileSelectAugurSettings } from './type';
import { FieldInputProps } from '../../types/type';

export const S3FileSelectInputField: FC<
  Partial<FieldInputProps<S3FileSelectAugurSettings>>
> = (props) => {
  const {
    onChange,
    onBlur,
    isTouched,
    name,
    value,
    invalid,
    disabled,
    readOnly,
  } = props;
  const fieldClassName = classNames(
    styles.TextInputLineInput,
    isTouched && {
      //error,
      success: !invalid,
      // pending: isValidating,
    }
  );
  return (
    <div className={styles.TextInputLine}>
      {/*<div className={styles.TextInputLineHeader}>*/}
      {/*  S3 Bucket File*/}
      {/*  <div className={styles.TextInputLineError}>*/}
      {/*    {error && <InputError touched={isTouched} error={error} />}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <input
        className={fieldClassName}
        type='text'
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      {isTouched && !invalid && (
        <div className={styles.TextInputLineSuccess}>
          <span className='icon-ok' />
        </div>
      )}
      {/*{isValidating && (*/}
      {/*  <div className={styles.TextInputLinePending}>*/}
      {/*    <span className='icon-refresh icon-spin' />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
