import { Pie } from '@nivo/pie';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { SamplingStrategyAugurSettings, SamplingStrategyConfig } from './type';
import vars from '../../../../../../../scss/base/var.module.scss';
import { useDimensions } from '../../../../../../utils';
import NumericSliderInput from '../../../../../atoms/input-elements/numeric-slider-input/NumericSliderInput';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  SamplingStrategyAugurSettings,
  SamplingStrategyConfig
>;

const SamplingStrategy: FC<Props> = (props) => {
  const {
    isTouched,
    onChange,
    value,
    config: { valueLabel, otherLabel },
    readOnly,
  } = props;
  const trainingSplit = value ? value : 0.7;

  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);

  const pieHeight = height - 40;

  const smallView = 400;
  const marginSmallView = 30;
  const topMarginSmallView = 50;
  return (
    <div ref={ref} className={styles.samplingStrategy}>
      <Pie
        height={pieHeight}
        width={width}
        theme={{}}
        data={[
          {
            id: valueLabel,
            label: valueLabel + ': ' + (trainingSplit * 100).toFixed(0) + '%',
            value: (trainingSplit * 100).toFixed(0),
          },
          {
            id: otherLabel,
            label:
              otherLabel + ': ' + ((1 - trainingSplit) * 100).toFixed(0) + '%',
            value: (100 - trainingSplit * 100).toFixed(0),
          },
        ]}
        margin={{
          top: width < smallView ? topMarginSmallView : 50,
          right: width < smallView ? 10 : 150,
          bottom: width < smallView ? marginSmallView : 50,
          left: width < smallView ? marginSmallView : 150,
        }}
        colors={(entry) => {
          switch (entry.id) {
            case valueLabel:
              return vars.colorPrimary;
            case otherLabel:
              return vars.colorTextLight;
            default:
              return '#ffffff';
          }
        }}
        arcLabelsTextColor={{ from: 'color' }}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={0}
        enableArcLabels={false}
        arcLinkLabelsThickness={width < smallView ? 0 : 1}
        arcLinkLabelsTextColor={width < smallView ? 'transparent' : '#071b32'}
        arcLinkLabel={(d) => `${d.id}: ${d.value}%`}
        innerRadius={height < 120 ? 1 : 0.5}
        arcLabel={(d) => (width >= smallView ? '' : `${d.id}: ${d.value}%`)}
        padAngle={1}
        legends={
          width < smallView
            ? [
                {
                  anchor: 'top-left',
                  direction: 'column',
                  itemHeight: 20,
                  itemWidth: 100,
                  translateX: -marginSmallView,
                  translateY: -topMarginSmallView,
                },
              ]
            : []
        }
        arcLinkLabelsTextOffset={2}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      />

      <NumericSliderInput
        id={'trainingSplit'}
        name={'trainingSplit'}
        touched={isTouched ? isTouched : false}
        hasLabel={false}
        valuePreview={() => ({ id: ' ', defaultValue: ' ' })}
        value={trainingSplit}
        onChange={(initialTrainingSplit: number) =>
          onChange?.(initialTrainingSplit)
        }
        onBlur={() => {}}
        lowerBound={0.01}
        upperBound={0.99}
        stepSize={0.01}
        readOnly={readOnly}
      />
    </div>
  );
};

export default SamplingStrategy;
