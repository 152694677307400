import { defineMessages } from 'react-intl';

export default defineMessages({
  msgPermissionHabitatUser: {
    id: 's3.permissions.habitat.user',
    defaultMessage: 'Buckets fetched with Habitat Permissions.',
  },
  wizardBucketHeadline: {
    id: 's3.wizard.bucket.headline',
    defaultMessage: 'Create S3 Bucket',
  },
  wizardBucketNameTitle: {
    id: 's3.wizard.bucket.name.title',
    defaultMessage: 'Bucket Name',
  },
  wizardBucketNameDescription: {
    id: 's3.wizard.bucket.name.description',
    defaultMessage: 'Enter a name for the bucket.',
  },
  wizardBucketNameRuleRequired: {
    id: 's3.wizard.bucket.name.rule.required',
    defaultMessage: 'Please supply a name.',
  },
  msgPermissionUser: {
    id: 's3.permissions.logged.in.user',
    defaultMessage: 'Buckets fetched with User Permissions.',
  },
  wizardBucketNameRuleMinMaxLength: {
    id: 's3.wizard.bucket.name.rule.min_max_length',
    defaultMessage:
      'Bucket names must be between 3 (min) and 63 (max) characters long.',
  },
  wizardBucketNameRuleAllowedCharacters: {
    id: 's3.wizard.bucket.name.rule.allowed_characters',
    defaultMessage:
      'Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-).',
  },
  wizardBucketNameRuleLetterNumberStartEnd: {
    id: 's3.wizard.bucket.name.rule.letter_number_start_end',
    defaultMessage: 'Bucket names must begin and end with a letter or number.',
  },
  wizardBucketNameRuleNoSubsequentDots: {
    id: 's3.wizard.bucket.name.rule.no_subsequent_dots',
    defaultMessage: 'Bucket names must not contain two adjacent periods.',
  },
  wizardBucketNameRuleExists: {
    id: 's3.wizard.bucket.name.rule.exists',
    defaultMessage: 'This bucket name already exists in this data source.',
  },
});
