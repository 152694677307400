import adminMsgs from 'common/dist/messages/admin';
import _ from 'lodash';
import React, { FC, useEffect } from 'react';

import { Group } from '../../../../store/admin/state.types';
import Button from '../../../atoms/button/Button';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import styles from '../styles.module.scss';

export const adminAddGroupLink = `${usersRoutes.basePath}/${routes.app.admin.addGroup}`;
export const adminGroupDetailsLink = (groupId) =>
  `${usersRoutes.basePath}/${usersRoutes.groups.path}/${groupId}`;

export interface Props {
  loadGroups: (fetchPermissions: boolean) => void;
  data: Group[];
  loading: boolean;
  loaded: boolean;
  error?: string;
}

const Groups: FC<Props> = ({ data, loadGroups, loading }) => {
  useEffect(() => loadGroups(false), [loadGroups]);

  const renderHeadline = () => {
    return (
      <div className={'ct-headline ct-row'}>
        <div className={'ct-col ct-col-200px'}>
          <span>Name</span>
        </div>
      </div>
    );
  };

  const renderList = () => {
    if (loading) {
      return (
        <div className={'ct-list'}>
          {renderHeadline()}
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className={'ct-row'}>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={'ct-list'}>
        {renderHeadline()}
        {data &&
          data
            .filter((g) => !_.isEqual(g.attributes.isHabitat, ['true']))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((group) => (
              <LinkWithQuery
                key={group.id}
                to={adminGroupDetailsLink(group.id)}
                style={{ textDecoration: 'none' }}
              >
                <div className={'ct-row'}>
                  <div className={'ct-col ct-col-200px'}>
                    <span>{group.name}</span>
                  </div>
                </div>
              </LinkWithQuery>
            ))}
      </div>
    );
  };

  return (
    <div className={styles.listPageParent}>
      <div className={styles.topButtonBar}>
        <Button
          color={'secondary'}
          label={adminMsgs.msgAddGroup}
          linkTo={adminAddGroupLink}
        />
      </div>

      <div className={styles.pagingWrapper}>
        <div className={styles.listContainer}>{renderList()}</div>
      </div>
    </div>
  );
};

export default Groups;
