// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/pipeline-tuning/v1/type.ts' --type 'ConfigPipelineTuningConfig'
export const schemaConfigPipelineTuning = {
  $ref: '#/definitions/ConfigPipelineTuningConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<PipelineTuningConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        pipelineTuningSchemas: {
          $ref: '#/definitions/ConfigEntry%3Cdef-alias-1580778638-353-3131-1580778638-0-26053%5B%5D%3E',
        },
      },
      required: ['pipelineTuningSchemas'],
      type: 'object',
    },
    'ConfigEntry<def-alias-1580778638-353-3131-1580778638-0-26053[]>': {
      anyOf: [
        {
          additionalProperties: false,
          properties: {
            source: {
              const: 'hard-coded',
              type: 'string',
            },
            value: {
              items: {
                $ref: '#/definitions/PipelineTuningSchemaType',
              },
              type: 'array',
            },
          },
          required: ['source', 'value'],
          type: 'object',
        },
        {
          additionalProperties: false,
          properties: {
            elementUuid: {
              type: 'string',
            },
            source: {
              const: 'input-element',
              type: 'string',
            },
          },
          required: ['source', 'elementUuid'],
          type: 'object',
        },
      ],
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
    },
    ConfigPipelineTuningConfig: {
      $ref: '#/definitions/Config%3CPipelineTuningConfig%3E',
    },
    PipelineTuningSchemaType: {
      additionalProperties: false,
      properties: {
        displayName: {
          type: 'string',
        },
        edges: {
          items: {
            additionalProperties: false,
            properties: {
              sourceID: {
                type: 'string',
              },
              targetID: {
                type: 'string',
              },
            },
            required: ['sourceID', 'targetID'],
            type: 'object',
          },
          type: 'array',
        },
        id: {
          type: 'string',
        },
        nodes: {
          items: {
            anyOf: [
              {
                additionalProperties: false,
                properties: {
                  description: {
                    type: 'string',
                  },
                  displayName: {
                    type: 'string',
                  },
                  id: {
                    type: 'string',
                  },
                  isEditable: {
                    type: 'boolean',
                  },
                  isInactive: {
                    type: 'boolean',
                  },
                  isOptional: {
                    type: 'boolean',
                  },
                  isTuneable: {
                    type: 'boolean',
                  },
                  nodeType: {
                    enum: ['transformer', 'classifier'],
                    type: 'string',
                  },
                  selectedNodeId: {
                    type: 'string',
                  },
                  staticParameters: {
                    items: {
                      additionalProperties: false,
                      properties: {
                        description: {
                          type: 'string',
                        },
                        displayName: {
                          type: 'string',
                        },
                        id: {
                          type: 'string',
                        },
                        name: {
                          type: 'string',
                        },
                        validValues: {
                          items: {
                            anyOf: [
                              {
                                additionalProperties: false,
                                properties: {
                                  maxValue: {
                                    type: 'number',
                                  },
                                  minValue: {
                                    type: 'number',
                                  },
                                  type: {
                                    enum: ['double', 'int'],
                                    type: 'string',
                                  },
                                },
                                required: ['type', 'minValue', 'maxValue'],
                                type: 'object',
                              },
                              {
                                additionalProperties: false,
                                properties: {
                                  type: {
                                    const: 'string',
                                    type: 'string',
                                  },
                                  values: {
                                    items: {
                                      type: 'string',
                                    },
                                    type: 'array',
                                  },
                                },
                                required: ['type', 'values'],
                                type: 'object',
                              },
                            ],
                          },
                          type: 'array',
                        },
                      },
                      required: [
                        'id',
                        'name',
                        'displayName',
                        'description',
                        'validValues',
                      ],
                      type: 'object',
                    },
                    type: 'array',
                  },
                  tuningParameters: {
                    items: {
                      additionalProperties: false,
                      properties: {
                        description: {
                          type: 'string',
                        },
                        displayName: {
                          type: 'string',
                        },
                        id: {
                          type: 'string',
                        },
                        name: {
                          type: 'string',
                        },
                        validValues: {
                          items: {
                            anyOf: [
                              {
                                additionalProperties: false,
                                properties: {
                                  maxValue: {
                                    type: 'number',
                                  },
                                  minValue: {
                                    type: 'number',
                                  },
                                  type: {
                                    enum: ['double', 'int'],
                                    type: 'string',
                                  },
                                },
                                required: ['type', 'minValue', 'maxValue'],
                                type: 'object',
                              },
                              {
                                additionalProperties: false,
                                properties: {
                                  type: {
                                    const: 'string',
                                    type: 'string',
                                  },
                                  values: {
                                    items: {
                                      type: 'string',
                                    },
                                    type: 'array',
                                  },
                                },
                                required: ['type', 'values'],
                                type: 'object',
                              },
                            ],
                          },
                          type: 'array',
                        },
                      },
                      required: [
                        'id',
                        'name',
                        'displayName',
                        'description',
                        'validValues',
                      ],
                      type: 'object',
                    },
                    type: 'array',
                  },
                  type: {
                    const: 'node',
                    type: 'string',
                  },
                },
                required: [
                  'type',
                  'nodeType',
                  'id',
                  'isOptional',
                  'isEditable',
                  'isTuneable',
                  'displayName',
                  'description',
                  'staticParameters',
                  'tuningParameters',
                ],
                type: 'object',
              },
              {
                additionalProperties: false,
                properties: {
                  displayName: {
                    type: 'string',
                  },
                  id: {
                    type: 'string',
                  },
                  nodes: {
                    items: {
                      additionalProperties: false,
                      properties: {
                        description: {
                          type: 'string',
                        },
                        displayName: {
                          type: 'string',
                        },
                        id: {
                          type: 'string',
                        },
                        isEditable: {
                          type: 'boolean',
                        },
                        isInactive: {
                          type: 'boolean',
                        },
                        isOptional: {
                          type: 'boolean',
                        },
                        isTuneable: {
                          type: 'boolean',
                        },
                        nodeType: {
                          enum: ['transformer', 'classifier'],
                          type: 'string',
                        },
                        selectedNodeId: {
                          type: 'string',
                        },
                        staticParameters: {
                          items: {
                            additionalProperties: false,
                            properties: {
                              description: {
                                type: 'string',
                              },
                              displayName: {
                                type: 'string',
                              },
                              id: {
                                type: 'string',
                              },
                              name: {
                                type: 'string',
                              },
                              validValues: {
                                items: {
                                  anyOf: [
                                    {
                                      additionalProperties: false,
                                      properties: {
                                        maxValue: {
                                          type: 'number',
                                        },
                                        minValue: {
                                          type: 'number',
                                        },
                                        type: {
                                          enum: ['double', 'int'],
                                          type: 'string',
                                        },
                                      },
                                      required: [
                                        'type',
                                        'minValue',
                                        'maxValue',
                                      ],
                                      type: 'object',
                                    },
                                    {
                                      additionalProperties: false,
                                      properties: {
                                        type: {
                                          const: 'string',
                                          type: 'string',
                                        },
                                        values: {
                                          items: {
                                            type: 'string',
                                          },
                                          type: 'array',
                                        },
                                      },
                                      required: ['type', 'values'],
                                      type: 'object',
                                    },
                                  ],
                                },
                                type: 'array',
                              },
                            },
                            required: [
                              'id',
                              'name',
                              'displayName',
                              'description',
                              'validValues',
                            ],
                            type: 'object',
                          },
                          type: 'array',
                        },
                        tuningParameters: {
                          items: {
                            additionalProperties: false,
                            properties: {
                              description: {
                                type: 'string',
                              },
                              displayName: {
                                type: 'string',
                              },
                              id: {
                                type: 'string',
                              },
                              name: {
                                type: 'string',
                              },
                              validValues: {
                                items: {
                                  anyOf: [
                                    {
                                      additionalProperties: false,
                                      properties: {
                                        maxValue: {
                                          type: 'number',
                                        },
                                        minValue: {
                                          type: 'number',
                                        },
                                        type: {
                                          enum: ['double', 'int'],
                                          type: 'string',
                                        },
                                      },
                                      required: [
                                        'type',
                                        'minValue',
                                        'maxValue',
                                      ],
                                      type: 'object',
                                    },
                                    {
                                      additionalProperties: false,
                                      properties: {
                                        type: {
                                          const: 'string',
                                          type: 'string',
                                        },
                                        values: {
                                          items: {
                                            type: 'string',
                                          },
                                          type: 'array',
                                        },
                                      },
                                      required: ['type', 'values'],
                                      type: 'object',
                                    },
                                  ],
                                },
                                type: 'array',
                              },
                            },
                            required: [
                              'id',
                              'name',
                              'displayName',
                              'description',
                              'validValues',
                            ],
                            type: 'object',
                          },
                          type: 'array',
                        },
                        type: {
                          const: 'node',
                          type: 'string',
                        },
                      },
                      required: [
                        'type',
                        'nodeType',
                        'id',
                        'isOptional',
                        'isEditable',
                        'isTuneable',
                        'displayName',
                        'description',
                        'staticParameters',
                        'tuningParameters',
                      ],
                      type: 'object',
                    },
                    type: 'array',
                  },
                  type: {
                    const: 'group',
                    type: 'string',
                  },
                },
                required: ['type', 'id', 'nodes', 'displayName'],
                type: 'object',
              },
            ],
          },
          type: 'array',
        },
        optimizationMethod: {
          additionalProperties: false,
          properties: {
            id: {
              const: 'grid_search_cross_validation',
              type: 'string',
            },
            nSplits: {
              type: 'number',
            },
          },
          required: ['id', 'nSplits'],
          type: 'object',
        },
      },
      required: ['id', 'displayName', 'optimizationMethod', 'edges', 'nodes'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/pipeline-tuning/v1/type.ts' --type 'PartialPipelineTuningAugurSettings'
export const schemaAugurSettingsDefaultPipelineTuning = {
  $ref: '#/definitions/PartialPipelineTuningAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    OptimizationMethod: {
      additionalProperties: false,
      properties: {
        id: {
          const: 'grid_search_cross_validation',
          type: 'string',
        },
        nSplits: {
          type: 'number',
        },
      },
      required: ['id', 'nSplits'],
      type: 'object',
    },
    PartialPipelineTuningAugurSettings: {
      items: {
        $ref: '#/definitions/PipelineTuningValue',
      },
      type: 'array',
    },
    PipelineTuningValue: {
      additionalProperties: false,
      description: 'Type for the value of the form field',
      properties: {
        displayName: {
          description: 'Display name of the Pipeline',
          type: 'string',
        },
        edges: {
          description: 'List of edges for the pipeline',
          items: {
            additionalProperties: false,
            properties: {
              sourceID: {
                description: 'ID of the source node',
                type: 'string',
              },
              targetID: {
                description: 'ID of the target node',
                type: 'string',
              },
            },
            required: ['sourceID', 'targetID'],
            type: 'object',
          },
          type: 'array',
        },
        id: {
          description: 'ID of the Pipeline',
          type: 'string',
        },
        nodes: {
          description: 'List of nodes of the pipeline',
          items: {
            $ref: '#/definitions/PipelineTuningValueNodeOrGroup',
          },
          type: 'array',
        },
        optimizationMethod: {
          $ref: '#/definitions/OptimizationMethod',
          description: 'Optimization method for this pipeline',
        },
      },
      required: ['id', 'displayName', 'optimizationMethod', 'nodes', 'edges'],
      type: 'object',
    },
    PipelineTuningValueGroup: {
      additionalProperties: false,
      description: 'Type for a node of type "group"',
      properties: {
        id: {
          description: 'ID of the node',
          type: 'string',
        },
        nodes: {
          description: 'Nodes of the group',
          items: {
            $ref: '#/definitions/PipelineTuningValueNode',
          },
          type: 'array',
        },
        type: {
          const: 'group',
          description: 'Type of the node = "group"',
          type: 'string',
        },
      },
      required: ['id', 'type', 'nodes'],
      type: 'object',
    },
    PipelineTuningValueNode: {
      additionalProperties: false,
      description: 'Type for a node of type "node"',
      properties: {
        description: {
          $ref: '#/definitions/ToBeRefined',
          description: 'Description of the node',
        },
        displayName: {
          description: 'Name of the node',
          type: 'string',
        },
        id: {
          description: 'ID of the node',
          type: 'string',
        },
        isActive: {
          type: 'boolean',
        },
        isEditable: {
          description: 'Is the node editable (for static parameters)?',
          type: 'boolean',
        },
        isTuneable: {
          description: 'Is the node tunable?',
          type: 'boolean',
        },
        nodeType: {
          description: 'Sub-type of the node',
          enum: ['transformer', 'classifier'],
          type: 'string',
        },
        staticParameters: {
          description: 'Selected static parameters',
          items: {
            $ref: '#/definitions/SelectedStaticParameter',
          },
          type: 'array',
        },
        tuningParameters: {
          description: 'Selected parameters',
          items: {
            $ref: '#/definitions/SelectedTuningParameter',
          },
          type: 'array',
        },
        type: {
          const: 'node',
          description: 'Type of the node = "node"',
          type: 'string',
        },
      },
      required: [
        'id',
        'type',
        'nodeType',
        'displayName',
        'description',
        'isTuneable',
        'isEditable',
      ],
      type: 'object',
    },
    PipelineTuningValueNodeOrGroup: {
      anyOf: [
        {
          $ref: '#/definitions/PipelineTuningValueNode',
        },
        {
          $ref: '#/definitions/PipelineTuningValueGroup',
        },
      ],
    },
    SelectedStaticParameter: {
      additionalProperties: false,
      properties: {
        displayName: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        value: {
          type: ['string', 'number'],
        },
      },
      required: ['id', 'displayName', 'value'],
      type: 'object',
    },
    SelectedTuningParameter: {
      additionalProperties: false,
      properties: {
        displayName: {
          type: 'string',
        },
        id: {
          type: 'string',
        },
        valueCandidates: {
          items: {
            type: ['string', 'number'],
          },
          type: 'array',
        },
      },
      required: ['id', 'displayName', 'valueCandidates'],
      type: 'object',
    },
    ToBeRefined: {
      description:
        'This is a marker type to denote that a type has to be refined further. Instead of simply using any, using this type allows for a project-wide search later.\n\nIn the optimal case this type is not even necessary anymore in the near future once the migration from JavaScript to Typescript is done.\n\nTODO This type is currently also defined in the frontend repository. Remove this type declaration from the "frontend" for this one.',
    },
  },
};
