export const usersRoutes = {
  basePath: '/app/admin/users',

  userList: {
    path: 'list',
  },

  groups: {
    path: 'groups',
  },

  domainPermissions: {
    path: 'domainPermissions',
  },

  appPermissions: {
    path: 'appPermissions',
  },

  habitats: {
    path: 'habitats',
  },

  dataSourcePermissions: {
    path: 'dataSourcePermissions',
  },

  cassandraPermissions: {
    path: 'cassandraPermissions',
  },

  s3Permissions: {
    path: 's3Permissions',
  },

  adminUserDetails: {
    path: 'user',
  },
};
