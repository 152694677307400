import {
  PipelineTuningValueNode,
  SelectedStaticParameter,
  StaticParameterType,
} from 'common/dist/types/pipeline';
import _ from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { renderParameterDescription } from './NodeEditor';
import styles from './styles.module.scss';
import { isValidNumber } from '../../../../../../../../utils';
import { IntlTextInputLine } from '../../../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { PipelineTuningErrorType } from '../../PipelineTuning';

type Props = {
  isEditable: boolean;
  staticParameters: StaticParameterType[];
  parameter: SelectedStaticParameter[];
  pipelineIndex: number;
  onChange: (value: SelectedStaticParameter[]) => void;
  error: PipelineTuningErrorType | undefined;
  node: PipelineTuningValueNode;
  readOnly?: boolean;
};

const StaticParameters: FC<Props> = (props) => {
  const {
    isEditable,
    staticParameters,
    parameter,
    pipelineIndex,
    onChange,
    error,
    node,
    readOnly,
  } = props;

  if (!staticParameters) {
    return null;
  } else {
    const paramsMap = _.keyBy(parameter, 'id');

    return (
      <div className={'PipelineTuningSelection--static'}>
        <span className={'PipelineTuningSelection--params-headline'}>
          Static Parameters
        </span>

        <div className={'PipelineTuningSelection--params-container'}>
          {staticParameters.map((param) => {
            return (
              <div
                key={param.id}
                className={'PipelineTuningSelection--params-param'}
              >
                <span
                  className={'PipelineTuningSelection--params-param-headline'}
                >
                  {param.displayName}
                </span>
                {renderParameterDescription(param.description)}
                <div
                  className={
                    'PipelineTuningSelection--params-param-input-container'
                  }
                >
                  {!error?.[
                    pipelineIndex.toString() + '-' + node.id + '-' + param.id
                  ] && <div className={styles.marginTop} />}
                  <IntlTextInputLine
                    name={param.id}
                    placeholder={param.displayName}
                    disabled={!isEditable || readOnly}
                    isTouched={true}
                    error={
                      error?.[
                        pipelineIndex.toString() +
                          '-' +
                          node.id +
                          '-' +
                          param.id
                      ]
                    }
                    invalid={
                      !!error?.[
                        pipelineIndex.toString() +
                          '-' +
                          node.id +
                          '-' +
                          param.id
                      ]
                    }
                    value={paramsMap[param.id]?.value.toString()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const isValidNum = isValidNumber(e.target.value);
                      onChange(
                        parameter.map((p) =>
                          p.id === param.id
                            ? {
                                ...paramsMap[p.id],
                                value: isValidNum
                                  ? Number(e.target.value)
                                  : e.target.value,
                              }
                            : p
                        )
                      );
                    }}
                    onBlur={() => {}}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default StaticParameters;
