import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { updateEmail, updatePassword } from './addUser.form';
import styles from './styles.module.scss';
import TextInputLinePassword from '../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';
import InputError from '../../../atoms/input-error/InputError';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

export default class CredentialsStepComponent extends Component<WrappedFieldProps> {
  errorMessage() {
    const {
      meta: { touched, error },
    } = this.props;
    if (touched && error) {
      return <p className={'error'}>{error.message}</p>;
    }
  }

  render() {
    const {
      input: { value, onChange, onFocus, onBlur },
      meta: { touched, error },
    } = this.props;

    return (
      <Fragment>
        <div className={styles.formGroup}>
          <div className={styles.header}>
            <label className={styles.formLabel}>
              <span>E-Mail</span>
            </label>
            <div className={styles.textInputLineError}>
              {error?.email && (
                <InputError touched={touched} error={error.email} />
              )}
            </div>
          </div>
          <input
            data-testid={'addUserMail'}
            className={classNames({
              [styles.inputContainerError]: touched && error?.email,
            })}
            type={'text'}
            value={(value || {}).email}
            onChange={(e) => onChange(updateEmail(value, e.target.value))}
            onFocus={onFocus}
            onBlur={(e) => onBlur(updateEmail(value, e.target.value))}
          />
        </div>
        <div className={styles.formGroup}>
          <div className={styles.header}>
            <label className={styles.formLabel}>
              <span>Password</span>
            </label>
            <div className={styles.textInputLineError}>
              {error?.password && (
                <InputError touched={touched} error={error.password} />
              )}
            </div>
          </div>
          <TextInputLinePassword
            testId={'addUserPassword'}
            value={(value || {}).password}
            onChange={(e) => onChange(updatePassword(value, e.target.value))}
            onFocus={onFocus}
            onBlur={(e) => onBlur(updatePassword(value, e.target.value))}
            touched={touched}
            error={error}
            autoComplete={'new-password'}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>
            <span>Temporary Password?</span>
          </label>
          <ThemedSwitch
            onChange={(checked) =>
              onChange({ ...value, isTemporaryPassword: checked })
            }
            checked={(value || {}).isTemporaryPassword}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            themeColor={'primary'}
          />
        </div>
      </Fragment>
    );
  }
}
