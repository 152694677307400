import {
  DASHBOARD_COMPONENT_SCOPE_IDS,
  RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX,
  GENERAL_RESOURCE_SUFFIXES,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
} from '../constants/keycloak';
import { routes } from '../constants/frontendRoutes';

/**
 * Given a list of permissions and a resource suffix, checks whether the user has access to this general functionality
 * (Model Management, Workbench, etc. ...)
 * @param permissions
 * @param resourceSuffix
 */
export const hasAccessPermission = (
  permissions: Record<string, string[]>,
  resourceSuffix: GENERAL_RESOURCE_SUFFIXES
): boolean => {
  return new Set(
    permissions?.[
      `${RESOURCE_TYPE_DASHBOARD_COMPONENT_PREFIX}:${resourceSuffix}`
    ] || []
  ).has(DASHBOARD_COMPONENT_SCOPE_IDS.ACCESS);
};

/**
 * Get the link for the first main tab the user has permissions for
 * Used for example to get the link for the logo in the left top corner
 */
export const getLinkForFirstPermittedMainTab = (
  permissions: Record<string, string[]>
): string | null => {
  if (!permissions) return null;

  const PRIORITIZED_ROUTES: [GENERAL_RESOURCE_SUFFIXES, string][] = [
    [RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX, routes.mainTabs.models],
    [RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX, routes.mainTabs.apps],
    [
      RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
      routes.mainTabs.workbench,
    ],
    [
      RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
      routes.mainTabs.orchestration,
    ],
    [RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX, routes.mainTabs.data],
  ];

  for (let i = 0; i < PRIORITIZED_ROUTES.length; i++) {
    const [resourceSuffix, route] = PRIORITIZED_ROUTES[i];
    if (hasAccessPermission(permissions, resourceSuffix)) {
      return route;
    }
  }

  // Fallback: no permitted main tabs
  return routes.noMainTabs;
};
