import { defineMessages } from 'react-intl';

export default defineMessages({
  msgEditableTableInputLabel: {
    id: 'apps.editableTable.input.label',
    defaultMessage: 'Input Table (optional)',
  },
  msgEditableTableInputPlaceholder: {
    id: 'apps.editableTable.input.placeholder',
    defaultMessage:
      'Select the variable with the DataFrame to use as input table',
  },
});
