import React, { Component } from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FiXSquare } from 'react-icons/fi';
import { RepoType } from 'common/dist/types/repository';

interface ContextMenuRepoProps {
  menuId: string;
  /** Name of the repo - Can be missing if the extra information besides git is not available */
  repoName?: string;
  /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
  repoFullName: string;
  repoType: RepoType;
  showDeleteRepoConfirm(...args: unknown[]): unknown;
}

export default class ContextMenuRepo extends Component<ContextMenuRepoProps> {
  render() {
    const { menuId, showDeleteRepoConfirm, repoName, repoFullName, repoType } =
      this.props;
    return (
      <ContextMenu id={menuId} className={'context-menu'}>
        <MenuItem>
          <div
            className={'cm-item-container'}
            onClick={(e) => {
              e.preventDefault(); // Don't bubble the click event up (otherwise the repository info will open)
              showDeleteRepoConfirm(repoName, repoFullName, repoType);
            }}
          >
            <FiXSquare className={'context-menu-icon'} />
            <p className={'context-menu-text'}>Delete</p>
          </div>
        </MenuItem>
      </ContextMenu>
    );
  }
}
