import React, { Component } from 'react';

import * as loginHistory from '../../redux/modules/account.loginHistory.module';
import { pageDataLoad } from '../../redux/modules/page.module';
import * as userModule from '../../redux/modules/user.module';
import store from '../../store/store';
import ChangePassword from '../molecules/user-profile/change-password/ChangePassword';
import Language from '../molecules/user-profile/language/Language.container';
import LoginHistory from '../molecules/user-profile/login-history/LoginHistory.container';
import AccountInfo from '../molecules/user-profile/user-info/UserInfo';
import UserSidenotes from '../molecules/user-profile/user-sidenotes/UserSidenotes.container';
import MainContainer from '../pages/main-container/MainContainer';
import './style.scss';

type Props = {
  saveChanges: () => void;
  cancel: () => void;
};

class UserProfile extends Component<Props, {}> {
  componentDidMount = () => {
    store.dispatch(
      pageDataLoad(
        userModule.fetchUser(),
        // @ts-ignore: Type actions/reducers first
        userModule.fetchUserSuccess,
        // @ts-ignore: Type actions/reducers first
        userModule.fetchUserFail
      )
    );

    store.dispatch(
      pageDataLoad(
        loginHistory.loadLoginHistory(),
        // @ts-ignore: Type actions/reducers first
        loginHistory.loadLoginHistorySuccess,
        // @ts-ignore: Type actions/reducers first
        loginHistory.loadLoginHistoryFail
      )
    );
  };

  render() {
    const { cancel, saveChanges } = this.props;
    return (
      <MainContainer
        additionalClassname={'Account'}
        transparent={false}
        fullWidth
      >
        <div className={'UserProfile'}>
          <AccountInfo />
          <Language />
          <ChangePassword />
          <LoginHistory />
          <UserSidenotes />
        </div>
      </MainContainer>
    );
  }
}

export default UserProfile;
