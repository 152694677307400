import React, { FC, Fragment, useEffect, useState } from 'react';

import { AltaSigmaCell } from '../../../../../../../../../../../store/workbench/state.types';
import Button from '../../../../../../../../../../atoms/button/Button';
import { download } from '../../../../../../../../../../organisms/workbench-browser/context-menu/download';
import { AsOutputElement } from '../_interface/P3OutputElement';

type Props = {
  path: string;
  cellId: string;
  element: AsOutputElement;
  cell: AltaSigmaCell;
  notebookUser: string;
  parentType: 'notebook' | 'app';
  appVersionCode: string;
};

const DownloadFile: FC<Props> = (props: Props) => {
  const {
    path,
    cellId,
    element,
    cell,
    notebookUser,
    parentType,
    appVersionCode,
  } = props;
  const downloadPath = element?.settings?.downloadPath?.inputValue;
  const defaultButtonLabel = 'Download File';
  const buttonLabel =
    element?.settings?.downloadButtonLabel?.inputValue || defaultButtonLabel;

  const [notFoundError, setNotFoundError] = useState(undefined);
  useEffect(() => {
    // Reset the error when the element changes.
    setNotFoundError(undefined);
  }, [element]);

  return (
    <Fragment>
      <div className={'output-element-download-button'}>
        <Button
          label={buttonLabel}
          color={'primary'}
          disabled={!downloadPath}
          onClick={() => {
            const url =
              parentType === 'app'
                ? `/jupyterapp/user/${notebookUser}/${appVersionCode.toLowerCase()}/files/${downloadPath}`
                : `/jupyter/user/${notebookUser}/files/${downloadPath}`;

            const parts = downloadPath.split('/');
            const name = parts[parts.length - 1];
            download(name, url);
          }}
        />
      </div>
      {notFoundError && (
        <div className={'output-element-not-found-error'}>{notFoundError}</div>
      )}
    </Fragment>
  );
};

export default DownloadFile;
