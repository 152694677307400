import { Summary } from 'common/dist/types/habitat';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ActionButtons from './ActionButtons';
import DetailValues from './DetailValues';
import NameContainer from './NameContainer';
import { CProps } from './OverlayAugurDetails.container';
import styles from './styles.module.scss';
import { useAugur } from '../../../../core/api/augurs';
import { RootState } from '../../../../store/store';
import { AugurDetailsRouteParams } from '../../../index/routes';

const OverlayAugurDetails: FC<CProps> = ({
  triggerLearningJob,
  triggerEvaluationJob,
  triggerPredictionJob,
}) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const useAugurQueryResult = useAugur(augurCode);

  const summary = useSelector<RootState, Summary>((state) => state.summary);
  const habitat = summary.find((h) => h.code === habitatCode);

  return (
    <div className={styles.detailsBar}>
      <NameContainer
        isLoaded={useAugurQueryResult?.isSuccess}
        augurName={useAugurQueryResult?.data?.name}
        augurHealth={useAugurQueryResult?.data?.health}
        habitatName={habitat?.name}
      />
      <DetailValues />
      <ActionButtons
        triggerLearningJob={triggerLearningJob}
        triggerEvaluationJob={triggerEvaluationJob}
        triggerPredictionJob={triggerPredictionJob}
      />
    </div>
  );
};

export default OverlayAugurDetails;
