import React, { FC, Fragment } from 'react';
import { FiGitMerge } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import {
  moduleMergeRequestIdLink,
  repositoryMergeRequestIdLink,
} from '../../routes';
import './mergeRequestListItem.scss';
import { MergeRequest } from './MergeRequests.container';

import { formatDate } from 'common/dist/utils/dates';

import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';
import { useModuleByCode } from '../../../../core/api/modules';

export type Props = {
  mr: MergeRequest;
  group: string;
  repositoryName: string;
  moduleCode?: string;
};

const MergeRequestListItem: FC<Props> = ({
  mr,
  group,
  repositoryName,
  moduleCode,
}) => {
  const { data: module } = useModuleByCode(moduleCode, !!moduleCode);

  let linkTo = repositoryMergeRequestIdLink(group, repositoryName, mr.id);
  if (module) {
    const parts = (module?.repository?.repoFullName || '').split('/');
    const group = parts[0];
    const repositoryName = parts[1];
    linkTo = moduleMergeRequestIdLink(moduleCode, group, repositoryName, mr.id);
  }

  return (
    <Fragment>
      <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <div className={'MergeRequestListItem--element'}>
          <div className={'element-icon-parent'}>
            <FiGitMerge className={'element-icon'} size={'20px'} />
          </div>
          <div className={'element-name-parent'}>
            <div className={'element-name'}>
              <span className={'name'}>{mr.title}</span>
            </div>
            <div className={'element-commit'}>
              <span className={'commit-details'}>
                Created&nbsp;
                <span
                  title={
                    mr
                      ? formatDate(
                          new Date(mr.created_at),
                          'dd.MM.yyyy HH:mm:ss'
                        )
                      : ''
                  }
                >
                  {mr && (
                    <FormattedRelativeTimeConvenient
                      date={new Date(mr.created_at)}
                    />
                  )}
                </span>
                &nbsp;by&nbsp;
                <span>{mr ? mr.user.full_name : ''}</span>
              </span>
            </div>
          </div>
        </div>
      </Link>
    </Fragment>
  );
};

export default MergeRequestListItem;
