import classNames from 'classnames';
import { Communication } from 'common/dist/types/module.optimization';
import React, { FC, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import CommunicationsTable from './CommunicationsTable';
import {
  CampaignOptimizationCommunicationsAugurSettings,
  CampaignOptimizationCommunicationsConfig,
  CampaignOptimizationCommunicationsValidationError,
} from './type';
import InputError from '../../../../../atoms/input-error/InputError';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationCommunicationsAugurSettings,
  CampaignOptimizationCommunicationsConfig,
  CampaignOptimizationCommunicationsValidationError
>;

export type CommunicationsErrorType = {
  global: string;
  rows: {
    [constraintId: string]: {
      name?: string;
      description?: string;
      channelId?: string;
      propensityId?: string;
      boostFactor?: string;
      variableCosts?: string;
      fixedCosts?: string;
    };
  };
};

const CampaignOptimizationCommunications: FC<Props> = (props) => {
  const {
    config,
    value = [],
    onChange,
    invalid,
    onBlur,
    error,
    readOnly,
  } = props;
  const { channels = [], propensities = [] } = config;

  const [addedNewRow, setAddedNewRow] = useState(false);

  const handleChange = (updatedCommunications: Communication[]) => {
    onChange?.(updatedCommunications);
  };

  // Update communications if the config changes
  useEffect(() => {
    const updatedCommunications = value.map((communication) => {
      const updatedCommunication = { ...communication };

      // Update communication if necessary
      if (!channels.find((channel) => channel.id === communication.channelId)) {
        updatedCommunication.channelId = '';
      }
      if (
        !propensities.find(
          (propensity) => propensity.id === communication.propensityId
        )
      ) {
        updatedCommunication.propensityId = '';
      }

      return updatedCommunication;
    });

    handleChange(updatedCommunications);
  }, [channels, propensities]);
  const handleAddRow = () => {
    if (!readOnly) {
      handleChange([
        ...value,
        {
          id: uuidv4(),
          name: '',
          channelId: '',
          propensityId: '',
          boostFactor: '',
        },
      ]);
      setAddedNewRow(true);
    }
  };
  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && <InputError touched={true} error={error.global} />}
      </div>

      <div className={commonStyles.tableContainer}>
        <CommunicationsTable
          channels={channels || []}
          propensities={propensities || []}
          addedNewRow={addedNewRow}
          rowIndex={value ? value.length - 1 : 0}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          invalid={invalid}
          error={error}
          readOnly={readOnly}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={classNames(commonStyles.AddIcon, {
            [commonStyles.readOnly]: readOnly,
          })}
          onClick={handleAddRow}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationCommunications;
