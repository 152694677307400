import { WithRequired } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import Button, { ButtonProps } from '../../atoms/button/Button';

export type Props = {
  /** List of buttons to show */
  buttons: WithRequired<ButtonProps, 'id'>[];
  /** Which of the buttons is active? */
  activeButton?: string;
  /** Default: bubbles */
  design?: 'bubbles' | 'tabs';
};

const TabLineBubbles: FC<Props> = ({
  buttons,
  design = 'bubbles',
  activeButton,
}) => {
  function renderButton(button: ButtonProps) {
    if (design === 'bubbles') {
      return (
        <Button
          {...button}
          type={'button'}
          form={'bubble'}
          color={button.id === activeButton ? 'primary' : 'white'}
          disableHoverAnimation={button.id === activeButton}
        />
      );
    } else {
      return (
        <Button
          {...button}
          type={'button'}
          form={'tab'}
          toggled={button.id === activeButton}
          disableHoverAnimation={button.id === activeButton}
        />
      );
    }
  }

  return (
    <div
      className={classNames({
        [styles.tabs]: design === 'tabs',
        [styles.bubbles]: design === 'bubbles',
      })}
    >
      {buttons && buttons.map((btn) => renderButton(btn))}
    </div>
  );
};

export default TabLineBubbles;
