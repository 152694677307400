import classNames from 'classnames';
import React, { useCallback } from 'react';
import ReactGridLayout, { DragOverEvent, Layout } from 'react-grid-layout';
import { FiAlertOctagon, FiX } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import {
  GridLayoutElement,
  GridLayoutElementPayload,
  isDragEvent,
  isGridLayoutElementTransferData,
  PAYLOAD_CATEGORY,
  PayloadCategory,
} from './type';
import { useDimensions } from '../../../utils';

export type Props = {
  arrangement: Layout[];
  elements: GridLayoutElement[];
  category?: PayloadCategory;
  selectedElementUuid?: string;
  onSelectElement?: (id: string) => void;
  onAddElement?: (id: string, payload: GridLayoutElementPayload) => void;
  onRemoveElement?: (id: string) => void;
  onChangeArrangement?: (arrangement: Layout[]) => void;
  viewOnly?: boolean;
};

const GRID_DIMENSIONS = {
  WIDTH: 12,
  HEIGHT: 12,
} as const;
const MINIMUM_ROW_HEIGHT = 45 as const;

function GridLayoutEditor({
  arrangement,
  elements,
  category = PAYLOAD_CATEGORY.NONE,
  selectedElementUuid,
  onSelectElement,
  onAddElement,
  onRemoveElement,
  onChangeArrangement,
  viewOnly = false,
}: Props) {
  const removeElement = useCallback(
    (id: string) => {
      onRemoveElement?.(id);
      onChangeArrangement?.(arrangement.filter((el) => el.i !== id));
      // deselect removed element
      if (selectedElementUuid === id) onSelectElement?.(undefined);
    },
    [
      arrangement,
      onChangeArrangement,
      onRemoveElement,
      onSelectElement,
      selectedElementUuid,
    ]
  );

  const addElement = useCallback(
    (element: Layout, payload: GridLayoutElementPayload) => {
      onChangeArrangement?.([...arrangement, element]);
      onAddElement?.(element.i, payload);
      onSelectElement?.(element.i);
    },
    [arrangement, onChangeArrangement, onAddElement, onSelectElement]
  );

  const [ref, { height, width }] = useDimensions<HTMLDivElement>();

  // spacing between grid tiles in px
  const spacing = 18;

  const onDrop = (layout: Layout[], item: Layout, event: Event) => {
    // this is called when an element from the outside is dropped onto the grid
    if (isDragEvent(event)) {
      const transferData: unknown = JSON.parse(
        event.dataTransfer.getData('text/plain')
      );
      if (
        isGridLayoutElementTransferData(transferData) &&
        category === transferData.payload.category
      ) {
        // add the actual element to the layout
        addElement(
          {
            i: uuidv4(),
            w: item.w,
            h: item.h,
            x: item.x,
            y: item.y,
            minW: transferData.minW,
            maxW: transferData.maxW,
            minH: transferData.minH,
            maxH: transferData.maxH,
          },
          transferData.payload
        );
      }
    }
  };

  const onDragDropOver = (event: DragOverEvent) => {
    if (isDragEvent(event)) {
      const eventData = event?.dataTransfer?.getData('text/plain');
      //This check will remove the error
      // Uncaught SyntaxError: Unexpected end of JSON input
      //     at JSON.parse (<anonymous>)
      //Therefore we insert a default fallback
      if (!eventData)
        return {
          h: 2,
          w: 3,
        };
      const transferData: unknown = JSON.parse(eventData);
      if (
        isGridLayoutElementTransferData(transferData) &&
        category === transferData.payload.category
      ) {
        // set the size of the element that was dragged from the outside
        return {
          h: transferData.minH,
          w: transferData.minW,
        };
      }
    }
    // do nothing if anything else than our custom objects are dragged
    return false;
  };

  return (
    <div
      ref={ref}
      id={'layoutEditorContainer'} // id used as for layout element portalling
      className={styles.container}
      // deselect element when clicking the empty grid
      onClick={
        viewOnly
          ? undefined
          : (event) => {
              onSelectElement?.(undefined);
              event.stopPropagation();
            }
      }
    >
      <ReactGridLayout
        className={classNames({
          [styles.grid]: !viewOnly,
        })}
        style={
          {
            '--grid-height': `${Math.max(
              MINIMUM_ROW_HEIGHT + spacing,
              (height - spacing) / GRID_DIMENSIONS.HEIGHT
            )}px`,
            '--grid-width': `${(width - spacing) / GRID_DIMENSIONS.WIDTH}px`,
            backgroundPosition: `${spacing / 2}px ${spacing / 2}px`,
          } as React.CSSProperties
        }
        layout={arrangement}
        width={width}
        margin={[spacing, spacing]}
        cols={GRID_DIMENSIONS.WIDTH}
        rowHeight={Math.max(
          MINIMUM_ROW_HEIGHT,
          (height - (GRID_DIMENSIONS.HEIGHT + 1) * spacing) /
            GRID_DIMENSIONS.HEIGHT
        )}
        useCSSTransforms={false}
        preventCollision={false}
        compactType={null}
        allowOverlap={true}
        resizeHandles={viewOnly ? [] : ['nw', 'se']}
        isDroppable={!viewOnly}
        isDraggable={!viewOnly}
        isResizable={!viewOnly}
        /* There is currently a bug with react-grid-layout where onLayoutChange doesn't work for dragging
            when using allowOverlap, instead onDragStop has to be used.
            https://github.com/react-grid-layout/react-grid-layout/issues/1775 */
        onDragStop={
          viewOnly ? undefined : (layout) => onChangeArrangement?.(layout)
        }
        /* After resizing the onLayoutChanged event does fire, so it is not necessary to update the layout here */
        onResizeStop={
          viewOnly ? undefined : (layout) => onChangeArrangement?.(layout)
        }
        onDrop={viewOnly ? undefined : onDrop}
        onDropDragOver={viewOnly ? undefined : onDragDropOver}
      >
        {elements.map(({ id, error, element }) => {
          return (
            <div
              key={id}
              className={classNames(styles.item, styles.overwrite, {
                [styles.selected]: id === selectedElementUuid,
                [styles.bordered]: !viewOnly,
                [styles.interactive]: !viewOnly,
                [styles.error]: !!error,
              })}
              onClick={
                viewOnly
                  ? undefined
                  : (event) => {
                      onSelectElement?.(id);
                      event.stopPropagation();
                    }
              }
            >
              <div className={styles.itemWrapper}>{element}</div>
              {!viewOnly && (
                <div
                  className={styles.removeButton}
                  onClick={(event) => {
                    removeElement(id);
                    event.stopPropagation();
                  }}
                >
                  <FiX size={16} />
                </div>
              )}
              {!!error && (
                <div className={styles.errorIndicator} title={error}>
                  <FiAlertOctagon size={18} color={'red'} />
                </div>
              )}
            </div>
          );
        })}
      </ReactGridLayout>
    </div>
  );
}

export default GridLayoutEditor;
