import { createAction } from 'redux-act';
import * as Api from '../../core/api';
import { call, put, takeEvery } from 'redux-saga/effects';

export const fetchAugurName = createAction(
  'orch fetch augur name',
  (augurCode) => ({ augurCode })
);

export const fetchAugurNameSuccess = createAction(
  'orch fetch augur name - success',
  (augurCode, name) => ({ augurCode, name })
);

export const fetchAugurNameFailure = createAction(
  'orch fetch augur name - failure',
  (augurCode, error) => ({ augurCode, error })
);

export const fetchRepositoryName = createAction(
  'orch fetch repository name',
  (repositoryCode) => ({ repositoryCode })
);

export const fetchRepositoryNameSuccess = createAction(
  'orch fetch repository name - success',
  (repositoryCode, name) => ({ repositoryCode, name })
);

export const fetchRepositoryNameFailure = createAction(
  'orch fetch repository name - failure',
  (repositoryCode, error) => ({ repositoryCode, error })
);

export const reducer = {
  [fetchAugurNameSuccess]: (state, { augurCode, name }) => ({
    ...state,
    names: {
      ...state.names,
      augurNames: {
        ...state.names.augurNames,
        [augurCode]: name,
      },
    },
  }),
  [fetchRepositoryNameSuccess]: (state, { repositoryCode, name }) => ({
    ...state,
    names: {
      ...state.names,
      repositoryNames: {
        ...state.names.repositoryNames,
        [repositoryCode]: name,
      },
    },
  }),
};

// TODO Reducer + add reducer to redux
// TODO Add saga to saga watcher

export function* fetchAugurNameSaga({ payload: { augurCode } }) {
  const { response, error } = yield call(Api.augurs.getAugurName, augurCode);
  if (response) {
    yield put(fetchAugurNameSuccess(augurCode, response.name));
  } else {
    yield put(fetchAugurNameFailure(augurCode, error));
  }
}

export function* watchFetchAugurName() {
  yield takeEvery(fetchAugurName.getType(), fetchAugurNameSaga);
}

export function* fetchRepositoryNameSaga({ payload: { repositoryCode } }) {
  const { response, error } = yield call(
    Api.repositories.getRepositoryName,
    repositoryCode
  );
  if (response) {
    yield put(fetchRepositoryNameSuccess(repositoryCode, response.name));
  } else {
    yield put(fetchRepositoryNameFailure(repositoryCode, error));
  }
}

export function* watchFetchRepositoryName() {
  yield takeEvery(fetchRepositoryName.getType(), fetchRepositoryNameSaga);
}
