import { DeepPartial } from "react-hook-form";
import { S3ParquetColumnSelectAugurSettings, S3ParquetColumnSelectConfig, S3ParquetColumnSelectValidationError } from "./type";
import { cleanErrorObject } from "common/dist/validation/helpers";

export const validateS3ParquetColumnSelect = (
    value: S3ParquetColumnSelectAugurSettings,
    config?: S3ParquetColumnSelectConfig,
) : DeepPartial<S3ParquetColumnSelectValidationError> | undefined => {
    const error: DeepPartial<S3ParquetColumnSelectValidationError> = {};

    if (config) {
        if (typeof config.file === 'string' && !config.file.endsWith('.parquet')) {
            error.global = 'The file must end with `.parquet`';
        }
        else if (config && !config.isOptional && (!value || value.length === 0)) {
            error.global = 'Please select a column';
        }
    }

    return cleanErrorObject(error);
}