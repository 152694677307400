import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InfluencerBar from './InfluencerBar';
import Header from './Header';

import GraphWait from '../../../../../../../components/details/GraphWait';
import _ from 'lodash';
import { OrderByType, SortByType, sortFeatures } from './sorting';
import { FeatureImportanceConfig, FeatureImportanceReportData } from '../type';
import { ReportElementProps } from '../../../types/meta';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import styles from './styles.module.scss';

export type Props = FeatureImportanceReportData & FeatureImportanceConfig;

const FeatureImportance: FC<Props> = (props: Props) => {

    const { featureImportanceCalculatedWith, features } = props;

    const [sortBy, setSortBy] = useState<SortByType>('importance');
    const [orderBy, setOrderBy] = useState<OrderByType>('descending');

    if (_.isEmpty(features)) {
        return (
            <GraphWait jobType={'learning'}>
                <FormattedMessage
                    id='augur.influencers.not_available'
                    defaultMessage='Influencers are not available'
                />
            </GraphWait>
        );
    }

    const processedFeatures = features.sort((f1, f2) => sortFeatures(f1, f2, sortBy, orderBy));

    return (
        <div className={styles.featureImportance}>
            <div className={styles.featureImportanceInner}>
                <div className={styles.infoText}>
                    {'Feature importance was calculated with ' + featureImportanceCalculatedWith}
                </div>

                <div>
                    <Header
                        sort={(sortBy, orderBy) => {
                            setSortBy(sortBy);
                            setOrderBy(orderBy);
                        }}
                        sortBy={sortBy}
                        orderBy={orderBy}
                    />

                    {processedFeatures.map((f) => (
                        <InfluencerBar
                            key={`feature-${f.feature}`}
                            feature={f}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const FeatureImportanceSingle: FC<
  ReportElementProps<FeatureImportanceReportData, FeatureImportanceConfig>
> = buildSingleReportWrapper<FeatureImportanceReportData, FeatureImportanceConfig>(FeatureImportance);

export default FeatureImportance;
