import { createAction } from 'redux-act';
import { eventChannel } from 'redux-saga';
import { put, call, takeEvery, delay, take } from 'redux-saga/effects';
import * as JupyterServerApi from '../../../core/api/workbench/jupyterServer';
import {
  success as successNotification,
  error as errorNotification,
} from 'react-notification-system-redux';
import {
  notificationStartAppTriggered,
  notificationStartAppFailed,
} from '../notifications/notifications';

/**
 * Checks whether the App notebook is already running for the given user and app version code
 * @type {ComplexActionCreator1<unknown, {notebookUser: unknown}, {}>}
 */
export const checkWhetherAppIsRunning = createAction(
  'check whether app is running',
  (notebookUser, appVersionCode, retry) => ({
    notebookUser,
    appVersionCode,
    retry,
  })
);

/**
 * Called when the check request was successfully completed.
 * @type {ComplexActionCreator1<unknown, {isRunning: unknown}, {}>}
 */
export const checkWhetherAppIsRunningSuccess = createAction(
  'check whether app is running - success',
  (isRunning) => ({ isRunning })
);

/**
 * Called when the check request could not be completed. Again: Independent of the result whether the notebook is
 * running or not - this case means the request didn't give an expected response (or possibly even no response in time).
 * @type {ComplexActionCreator1<unknown, {error: unknown}, {}>}
 */
export const checkWhetherAppIsRunningFailure = createAction(
  'check whether app is running - failure',
  (error) => ({ error })
);

export const startApp = createAction(
  'start app',
  (notebookUser, appVersionCode) => ({ notebookUser, appVersionCode })
);

// This doesn't mean the server is running - it only means that the async request was submitted successfully.
export const startAppSuccess = createAction(
  'start app - success ',
  (success, eventSourceAvailable) => ({ success, eventSourceAvailable })
);

export const startAppFail = createAction('start app - fail', (error) => ({
  error,
}));

export const appStartStatusMessage = createAction(
  'app start status message',
  (message) => ({ message })
);

export const reducer = {
  [checkWhetherAppIsRunning]: (state) => ({
    ...state,
    app: {
      ...state.app,
      appRunning: {
        ...state.app.appRunning,
        loading: true,
      },
    },
  }),
  [checkWhetherAppIsRunningSuccess]: (state, { isRunning }) => ({
    ...state,
    app: {
      ...state.app,
      appRunning: {
        ...state.app.appRunning,
        loading: false,
        loaded: true,
        isRunning,
      },
    },
  }),
  [checkWhetherAppIsRunningFailure]: (state, { error }) => ({
    ...state,
    app: {
      ...state.app,
      appRunning: {
        ...state.app.appRunning,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [startApp]: (state) => ({
    ...state,
    app: {
      ...state.app,
      appStarting: {
        ...state.app.appStarting,
        loading: true,
        loaded: false,
        error: undefined,
        eventSourceAvailable: undefined,
        eventSourceMessages: [],
      },
    },
  }),
  [startAppSuccess]: (state, { eventSourceAvailable }) => ({
    ...state,
    app: {
      ...state.app,
      activeStep: 0,
      appStarting: {
        ...state.app.appStarting,
        loading: false,
        loaded: true,
        eventSourceAvailable,
      },
    },
  }),
  [startAppFail]: (state, { error }) => ({
    ...state,
    app: {
      ...state.app,
      appStarting: {
        ...state.app.appStarting,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [appStartStatusMessage]: (state, { message }) => ({
    ...state,
    app: {
      ...state.app,
      appStarting: {
        ...state.app.appStarting,
        eventSourceMessages: [
          ...state.app.appStarting.eventSourceMessages,
          message,
        ],
      },
    },
  }),
};

function startEventSource(response, notebookUser, serverName) {
  return eventChannel((emitter) => {
    const source = new EventSource(
      `${location.protocol}//${
        window.location.hostname + (location.port ? ':' + location.port : '')
      }/jupyterapp/hub/api/users/${notebookUser}/servers/${serverName}/progress`
    );
    // --- OPEN
    source.addEventListener(
      'open',
      (e) => emitter(startAppSuccess(response, true)),
      false
    );
    // --- MESSAGE
    source.addEventListener(
      'message',
      (e) => emitter(appStartStatusMessage(JSON.parse(e.data))),
      false
    );
    // --- ERROR / CLOSE
    source.addEventListener(
      'error',
      (e) => {
        source.close();
        return emitter(startAppSuccess(response, false));
      },
      false
    );

    return () => {};
  });
}

export function* startAppSaga({ payload: { notebookUser, appVersionCode } }) {
  const serverName = appVersionCode.toLowerCase();
  try {
    const { response, error } = yield call(
      JupyterServerApi.startApp,
      notebookUser,
      serverName
    );
    if (response) {
      yield put(successNotification(notificationStartAppTriggered()));
      // --- Start EventSource
      // startEventSource(response);
      const channel = yield call(
        startEventSource,
        response,
        notebookUser,
        serverName
      );
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
      // ---
    } else {
      yield put(startAppFail(error));
      yield put(errorNotification(notificationStartAppFailed()));
    }
  } catch (e) {
    // console.log('Caught error:', e);
    // yield put(startAppFail());
    // yield put(errorNotification(notificationStartServerFailed()));
  }
}

export function* watchStartApp() {
  yield takeEvery(startApp.getType(), startAppSaga);
}

export function* checkWhetherAppIsRunningSaga({
  payload: { notebookUser, appVersionCode, retry },
}) {
  const serverName = appVersionCode.toLowerCase();
  try {
    const resp = yield call(
      JupyterServerApi.checkApp,
      notebookUser,
      serverName
    );
    const status = resp.status;
    if (!status || status === 302) {
      yield put(checkWhetherAppIsRunningSuccess(true));
    } else {
      yield put(checkWhetherAppIsRunningSuccess(false));
      if (retry) {
        yield delay(3000); // Sleep for 3 seconds
        yield put(checkWhetherAppIsRunning(notebookUser, true));
      }
    }
  } catch (e) {
    yield put(checkWhetherAppIsRunningFailure(e));
  }
}

export function* watchCheckWhetherAppIsRunning() {
  yield takeEvery(
    checkWhetherAppIsRunning.getType(),
    checkWhetherAppIsRunningSaga
  );
}
