import React, { Component } from 'react';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor,
} from 'react-intl';
import './styles.scss';
import ThemedSwitch from '../../themed-switch/ThemedSwitch';

export type Props = {
  /** Is the field disabled? */
  disabled: boolean;
  intl: IntlShape;
  /** ID of the input element */
  id?: string;
  /** Name of the input field */
  name?: string;
  /** Optional Label of the toggle */
  label?: MessageDescriptor;
  /** Description of the toggle (left of the toggle) */
  description?: MessageDescriptor;
  /** Is the toggle checked? */
  checked: boolean;
  /** onChange callback */
  onChange: (e: boolean) => void;
};

class ToggleInput extends Component<Props> {
  renderLabel() {
    const { label } = this.props;
    if (!label) return null;
    else if (!label.id) {
      return (
        <span className={'ToggleInput--label'}>{label.defaultMessage}</span>
      );
    } else {
      return (
        <FormattedMessage id={label.id} defaultMessage={label.defaultMessage}>
          {(text) => <span className={'ToggleInput--label'}>{text}</span>}
        </FormattedMessage>
      );
    }
  }

  renderDescription() {
    const { description } = this.props;
    if (!description) return null;
    else if (!description.id) {
      return (
        <span className={'ToggleInput--description'}>
          {description.defaultMessage}
        </span>
      );
    } else {
      return (
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        >
          {(text) => <span className={'ToggleInput--description'}>{text}</span>}
        </FormattedMessage>
      );
    }
  }

  render() {
    const { label, description, checked, onChange, disabled } = this.props;

    return (
      <div className={'ToggleInput'}>
        <div className={'ToggleInput--header'}>
          {label && this.renderLabel()}
        </div>

        <div className={'ToggleInput--container'}>
          {description && this.renderDescription()}

          <ThemedSwitch
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            themeColor={'primary'}
          />
        </div>
      </div>
    );
  }
}
/** @deprecated use react hook form variant instead*/
export default injectIntl(ToggleInput);
