import React, { FC } from 'react';
import {
  CassandraTableColumnSelectAugurSettings,
  CassandraTableColumnSelectConfig,
  CassandraTableColumnSelectValidationError,
} from './type';
import { useCassandraTableSamples } from '../../../../../../core/api/data';
import { OptionType } from '../../common/type';
import { AugurSettingsProps } from '../../types/meta';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { MultiValue, SingleValue } from 'react-select';

export type Props = AugurSettingsProps<
  CassandraTableColumnSelectAugurSettings,
  CassandraTableColumnSelectConfig,
  CassandraTableColumnSelectValidationError
>;

export type CassandraTableColumnSelectErrorType = {
  global: string;
}

const CassandraTableColumnSelect: FC<Props> = (props) => {
  const { config, onChange, onBlur, error, value, disabled, portalTarget } = props;
  const { keyspace, table, isMulti, isClearable, isSearchable } = config;

  const tableSamples = useCassandraTableSamples(
    keyspace?.dataSourceCode,
    keyspace?.keyspaceName,
    table
  );

  const columns = tableSamples.data?.colSpecs || [];

  const columnOptions: OptionType[] = columns.map(column => ({
    label: column.colName,
    value: column.colName,
  }));

  // Find all options that match the values in the value array
  const selectedOptions = columnOptions.filter(option =>
    Array.isArray(value) && value.includes(option.value)
  );

  // Handle single and multi-select cases in onChange handler
  const handleChange = (
    selected: SingleValue<OptionType> | MultiValue<OptionType>
  ) => {
    if (Array.isArray(selected)) {
      onChange?.(selected.map((option) => option.value));
    } else {
      // @ts-ignore
      onChange?.(selected ? [selected.value] : []);
    }
  };
  
  return (
    <DropdownSelectInput<OptionType, any>
      key={'cassandraTableColumnSelect_' + value}
      id={'cassandraTableColumnSelect'}
      label={'Cassandra Keyspace Table Columns'}
      placeholder={isMulti ? 'No Columns selected' : 'No Column Selected'}
      options={columnOptions}
      onBlur={onBlur}
      onChange={handleChange}
      value={selectedOptions}
      error={error?.global}
      isTouched={true}
      isClearable={isClearable}
      searchable={isSearchable}
      disabled={disabled}
      menuPortalTarget={portalTarget}
      isMulti={isMulti}
      appearance={'multiLines'}
    />
  );
};

export default CassandraTableColumnSelect;
