import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import AddTuple from './AddTuple';
import commonStyles from './commonStyles.module.scss';
import styles from './styles.module.scss';
import { TableRow, TupleListTableError } from './TupleListTable';
import Table, { RenderColumn } from '../table/Table';

type Props = {
  label1: string;
  placeholder1: string;
  label2: string;
  placeholder2: string;
  addedNewRow?: boolean;
  rowIndex: number;
  data: TableRow[];
  setData: (data: TableRow[]) => void;
  errors?: DeepPartial<TupleListTableError>;
  isTouched?: boolean;
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  readOnly?: boolean;
};

const TupleTable: FC<Props> = (props) => {
  const {
    label1,
    placeholder1,
    label2,
    placeholder2,
    addedNewRow,
    rowIndex,
    data,
    setData,
    errors,
    isTouched,
    invalid,
    onBlur,
    readOnly,
  } = props;

  const [visible, setVisible] = useState({
    index: addedNewRow ? data.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  });

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setVisible({
        index: addedNewRow ? rowIndex : data.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex, data.length]);

  const renderColumns: RenderColumn<TableRow, keyof TableRow>[] = [
    {
      key: 'value1',
      renderHeader: () => <span>{label1}</span>,
      renderCell: (value1: string, row: TableRow) => (
        <span
          className={classNames({
            [styles.erroneous]: !!errors?.rows?.[row.id]?.[0],
          })}
        >
          {value1}
        </span>
      ),
    },
    {
      key: 'value2',
      renderHeader: () => <span>{label2}</span>,
      renderCell: (value2?: string, row?: TableRow) => (
        <span
          className={classNames({
            [styles.erroneous]: !!errors?.rows?.[row.id]?.[1],
          })}
        >
          {value2}
        </span>
      ),
    },
  ];

  const onRemove = (removedRow: TableRow) => {
    const updatedValue = data.filter((row) => row.id !== removedRow.id);
    setData(updatedValue);
  };

  const renderClickedRow = (
    element: TableRow,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddTuple
                rowIndex={rowIndex}
                rowId={element.id}
                data={data}
                setData={setData}
                isTouched={isTouched}
                invalid={invalid}
                error={errors}
                onBlur={onBlur}
                removableRows={visible.shown}
                onRemoveRow={onRemove}
                label1={label1}
                placeholder1={placeholder1}
                label2={label2}
                placeholder2={placeholder2}
                readOnly={readOnly}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table<TableRow>
      data={Array.isArray(data) ? data : []}
      renderColumns={renderColumns}
      showHeader
      verticalAlignMiddle
      roundedBorder
      removableRows={true}
      onRemoveRow={onRemove}
      addlRowClassname={(rowIndex: number, row: TableRow) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!errors?.rows?.[row.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setVisible({
          index: rowIndex,
          shown: true,
        });
      }}
      readOnly={readOnly}
    />
  );
};

export default TupleTable;
