import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { Channel } from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationChannelsAugurSettings,
  CampaignOptimizationChannelsValidationError,
} from './type';

export const nameRegex = /^[A-Za-z0-9 _\-\(\)'&\[\]\/]*$/;
export const descriptionRegex = /^[A-Za-z0-9 _\-/äöüÄÖÜß\(\)'&\[\],\/]*$/;

export const validateName = (
  name?: string,
  existingNames?: string[]
): string | undefined => {
  // The name field is required
  if (!name) {
    return moduleOptimizationMessages.msgNewAugurStepChannelsErrNoName
      .defaultMessage;
  }

  // Valid characters
  if (!nameRegex.test(name)) {
    return moduleOptimizationMessages.msgNewAugurStepChannelsErrNameInvalidChar
      .defaultMessage;
  }

  // Max length
  if (name.length > 64) {
    return moduleOptimizationMessages.msgNewAugurStepChannelsErrNameLength
      .defaultMessage;
  }

  // Prevent name duplicates
  if ((existingNames || []).includes(name)) {
    return moduleOptimizationMessages.msgNewAugurStepChannelsErrNameDuplicate
      .defaultMessage;
  }
};

export const validateDescription = (
  description?: string
): string | undefined => {
  // The description field is optional
  if (!description) return;

  // Valid characters
  if (!descriptionRegex.test(description)) {
    return moduleOptimizationMessages
      .msgNewAugurStepChannelsErrDescriptionInvalidChar.defaultMessage;
  }

  // Max length
  if (description.length > 1024) {
    return moduleOptimizationMessages
      .msgNewAugurStepChannelsErrDescriptionLength.defaultMessage;
  }
};

export const validateSingleChannel = (
  channel: Channel,
  allChannels?: Channel[]
): [string?, string?] => {
  // Validate name
  const nameError = validateName(
    channel.name,
    (allChannels || []).filter((x) => x.id !== channel.id).map((ch) => ch.name)
  );
  // Validate description
  const descriptionError = validateDescription(channel.description);

  return [nameError, descriptionError];
};

export const validateChannels = (
  value: CampaignOptimizationChannelsAugurSettings
): DeepPartial<CampaignOptimizationChannelsValidationError> | undefined => {
  const error: DeepPartial<CampaignOptimizationChannelsValidationError> = {};

  // Is there at least one channel added?
  if (!value || (value || []).length === 0) {
    error.global =
      moduleOptimizationMessages.msgNewAugurStepChannelsErrNoChannels.defaultMessage;
  } else {
    value?.forEach((channel) => {
      const constraintError = validateSingleChannel(channel, value);
      if (!_.isEmpty(constraintError)) {
        error.rows = {
          ...error.rows,
          [channel.id]: constraintError,
        };
      }
    });
  }

  return cleanErrorObject(error);
};
