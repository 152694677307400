import React, { Component } from 'react';
import DropZone from 'react-dropzone';
import './styles.scss';

import { ToBeRefined } from 'common/dist/types/todo_type';

import DropZoneBody from './DropZoneBody';

export type Props = {
  /** Is the image being fetched? */
  loading: boolean;
  /** Was the image fetched? */
  loaded: boolean;
  /** Fetched image */
  data: {
    imageType: string;
    imageData: string;
  };
  /** Error while fetching the image? */
  error?: string;

  /** Is the image being uploaded? */
  uploading: boolean;
  /** Was the image uploaded successfully? */
  uploaded: boolean;
  /** Upload progress */
  progress?: number;
  /** Error while uploading the image? */
  uploadError?: string;

  /** Function to get the avatar */
  fetchAvatar: () => void;
  /** Function to upload the avatar */
  uploadAvatar: (avatar: ToBeRefined) => void;
  /** Function to delete the avatar */
  deleteAvatar: () => void;
};

export const MAX_SIZE = 1048576; // 1MB max

export default class AccountAddPhoto extends Component<Props, {}> {
  componentDidMount() {
    const { fetchAvatar } = this.props;
    fetchAvatar();
  }

  render() {
    const {
      uploadAvatar,
      loading,
      loaded,
      data,
      error,
      deleteAvatar,
      uploading,
      progress,
    } = this.props;

    function handleDrop(files: File[]) {
      const file = files[0];
      if (file) {
        // Can be undefined if a too large file was dropped (and was then rejected by the DropZone)
        uploadAvatar(file);
      }
    }

    const hasAvatar = loaded && data;

    const dropZoneClassName = `AccountAddPhoto--dz ${
      hasAvatar ? 'AccountAddPhoto--dz-avatar' : 'AccountAddPhoto--dz-no-avatar'
    }`;

    return (
      <div className={'AccountAddPhoto'}>
        <div className={'AccountAddPhoto--grey-block'}>
          <div className={'AccountAddPhoto--wrap'}>
            <DropZone
              className={dropZoneClassName}
              onDrop={handleDrop}
              accept={'image/*'}
              minSize={0}
              maxSize={MAX_SIZE}
              multiple={false}
            >
              {({ rejectedFiles }) => {
                const isFileTooLarge =
                  rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_SIZE;
                return (
                  <DropZoneBody
                    loading={loading}
                    loaded={loaded}
                    data={data}
                    error={error}
                    deleteAvatar={deleteAvatar}
                    isFileTooLarge={isFileTooLarge}
                    uploading={uploading}
                    progress={progress}
                  />
                );
              }}
            </DropZone>
          </div>
        </div>
      </div>
    );
  }
}
