// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/image/v1/type.ts' --type 'ConfigImageConfig'
export const schemaConfigImage = {
  $ref: '#/definitions/ConfigImageConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ImageConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigImageConfig: {
      $ref: '#/definitions/Config%3CImageConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'ImageV1ReportData'
export const schemaReportTypeImage = {
  $ref: '#/definitions/ImageV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    ImageV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        image: {
          type: 'string',
        },
      },
      required: ['image'],
      type: 'object',
    },
  },
};
