import { AppVersion } from 'common/dist/types/app';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { BuildAppData, BuildAppError, fieldTag, form } from './form';
import StepTag from './step-tag/StepTag.container';
import { RepoDetails } from '../../../../store/workbench/state.types';
import { LoadableData } from '../../../../utils';
import Wizard from '../../../pages/wizard/Wizard';
import { repositoryDetailsLink2 } from '../../routes';

export interface Props {
  tag?: string;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: RepoDetails;
  notebooksToExecute: string[];
  versions: LoadableData<AppVersion[], unknown>;

  triggerAppBuild(
    repositoryCode: string,
    appCode: string,
    appVersionNumber: string,
    notebooksToExecute: string[]
  ): void;

  fetchRepoDetails(...args: unknown[]): unknown;
}

interface State {
  buttonClicked: boolean;
}

interface RouteParams {
  group?: string;
  repositoryName?: string;
}

class BuildAppWizard extends Component<
  Props &
    InjectedFormProps<BuildAppData, Props, BuildAppError> &
    RouteComponentProps<RouteParams>,
  State
> {
  constructor(props) {
    super(props);
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    const { fetchRepoDetails } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;

    fetchRepoDetails(group, repositoryName);
  }

  render() {
    const {
      valid,
      tag,
      triggerAppBuild,
      notebooksToExecute,
      data,
      handleSubmit,
      loading,
      history,
    } = this.props;

    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const { code: repoCode, app } = data || {};

    return (
      <form
        onSubmit={handleSubmit((values) => {
          this.setState({ buttonClicked: true });
          triggerAppBuild(
            repoCode, // repositoryCode
            app.code,
            tag, // versionNumber,
            notebooksToExecute
          );
          history.push(repositoryDetailsLink2(group, repositoryName));
        })}
        style={{ height: '100%' }}
      >
        <Wizard
          headline={'Build App'}
          buttons={[
            {
              id: 'wizard.cancel',
              label: 'Cancel',
              color: 'white',
              linkTo: repositoryDetailsLink2(group, repositoryName),
            },
            {
              label: 'Build',
              color: 'secondary',
              type: 'submit',
              disabled: loading || !valid || this.state.buttonClicked,
            },
          ]}
        >
          <Field
            name={fieldTag}
            label={'Capsule Tag'}
            component={StepTag}
            appCode={app?.code}
          />
        </Wizard>
      </form>
    );
  }
}

// @ts-ignore
export default withRouter(reduxForm(form)(BuildAppWizard));
