import { ParameterTuningResultsV1ReportData as ParameterTuningResultsReportData } from 'common/dist/types/reportData/parameterTuningResultsV1ReportData';
import { PythonExampleProps } from './single/stories/exampleData';
import { Config } from '../../../../../pages/augur/type';

export type { ParameterTuningResultsReportData };

export type ParameterTuningResultsConfig = {};

export type ConfigParameterTuningResultsConfig =
  Config<ParameterTuningResultsConfig>;

export const configTypeParameterTuningResults = `{}`;

export const reportDataTypeParameterTuningResults = `{
    modelTuningResults: {
        bestModel: {
            algorithm: string;
            parameters: {
                name: string;
                value: string | number;
            }[];
            score: number;
        };
        singleResults: {
            algorithm: string;
            parameters: {
                name: string;
                value: string | number;
            }[];
            score: number;
        }[];
        kpi: string;
    },
    parameterTuningSchema {
        availableSearchStrategies: {
          name: string;
          speakingName: string; 
          description: string;
        }[];
      
        availableAlgorithms: {
          name: string;
          speakingName: string; 
          description: string;
          parameters: {
            name: string;
            speakingName: string; 
            description: string;
            validValues: {
              type: string; 
              min?: number; 
              max?: number; 
            }[];
          }[];
        }[];
    }
}`;

export const defaultConfigParameterTuningResults: ConfigParameterTuningResultsConfig =
  {};

export const defaultReportDataDefaultParameterTuningResults: ParameterTuningResultsReportData =
  PythonExampleProps;
