import datamanMsgs from 'common/dist/messages/dataManagement';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';
import { FormErrors, InjectedFormProps, reduxForm } from 'redux-form';

import {
  commitForm,
  fieldData,
  fieldKeys,
  FormData,
  formName,
  getCommitColSpecs,
} from './form';
import StepData from './step-data/StepData';
import StepKeys from './step-keys/StepKeys';
import UploadSummary from './UploadSummary';
import {
  CassandraUploadInfoOutput,
  ColSpec,
} from '../../../../../store/dataManagement/state.types';
import { ButtonProps } from '../../../../atoms/button/Button';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import { ValueType } from '../../../../organisms/cassandra-table-preview/CassandraTablePreview';
import Wizard from '../../../../pages/wizard/Wizard';
import { dataManagementRoutes } from '../../../routes';
import { UPLOAD_WIZARDS } from '../uploadWizards';

export type Props = {
  /** Code of the data source */
  dataSourceCode: string;
  formValues: FormData;
  formErrors: FormErrors<FormData>;
  // /** Is the whole form valid? */
  // valid: boolean;
  /** Is the commitInfo loading? */
  loading: boolean;
  /** Was the commitInfo loaded? */
  loaded: boolean;
  /** Was there an error during loading the commitInfo? */
  error: string;
  /** The commitInfo data */
  data: CassandraUploadInfoOutput;
  /** Request the commit info from the data management API */
  requestCommitInfo: (dataSourceCode: string, uploadCode: string) => void;
  uploadCode: string;
  /** Commit the upload */
  commitUpload: (dataSourceCode: string, data: ToBeRefined) => void;
  setActiveUploadWizard: (wizard: string, uploadCode: string) => void;
  destroyUploadForm: () => void;
};

class WizardCommit extends Component<
  Props & InjectedFormProps<FormData, Props>
> {
  componentDidMount() {
    const { requestCommitInfo, dataSourceCode, uploadCode } = this.props;
    requestCommitInfo(dataSourceCode, uploadCode);
  }

  componentDidUpdate(
    prevProps: Readonly<Props & InjectedFormProps<FormData, Props>>
  ) {
    const { data, touch } = this.props;
    if (data && data !== prevProps.data) {
      // If the data arrives touch its field to show errors if there are any (e.g. duplicate column names)
      touch(fieldData);
    }
  }

  renderError() {
    const { error } = this.props;
    return <span>Error: {JSON.stringify(error)}</span>;
  }

  renderLoading() {
    return <span>Loading ...</span>;
  }

  getCommitKeys(colSpecs: ColSpec[], keys: string[], overwriteCols: ValueType) {
    return keys.map(
      (columnId) =>
        overwriteCols[columnId]?.newName ?? colSpecs[columnId].colName
    );
  }

  renderLoaded() {
    const {
      data,
      dataSourceCode,
      uploadCode,
      valid,
      formValues,
      commitUpload,
      setActiveUploadWizard,
      destroyUploadForm,
      formErrors,
    } = this.props;

    if (!data) return null; //If there's no data, there's nothing to render

    const buttons: ButtonProps[] = [
      {
        color: 'primary',
        label: 'Back',
        onClick: () => setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, uploadCode),
      },
      {
        linkTo: `${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}`,
        color: 'white',
        label: 'Cancel',
      },
      {
        color: 'secondary',
        label: 'Commit',
        disabled: !valid,
        onClick: () => {
          commitUpload(dataSourceCode, {
            uploadCode,
            colSpecs: getCommitColSpecs(
              data.dataPreview?.colSpecs,
              formValues[fieldData] || {}
            ),
            clusteringKeys: this.getCommitKeys(
              data.dataPreview?.colSpecs || [],
              formValues[fieldKeys]?.clustering || [],
              formValues[fieldData] || {}
            ),
            partitionKeys: this.getCommitKeys(
              data.dataPreview?.colSpecs || [],
              formValues[fieldKeys]?.partition || [],
              formValues[fieldData] || {}
            ),
          });
          destroyUploadForm();
        },
      },
    ];

    return (
      <Wizard
        additionalClassname={'wizard-parent-upload'}
        headline={'Commit File Upload to Cassandra'}
        buttons={buttons}
      >
        <UploadSummary
          meta={data.meta}
          keyspaceErrors={data.keyspaceErrors}
          tableErrors={data.tableErrors}
          dataErrors={data.dataErrors}
        />
        <GenericFormStep
          fieldName={fieldData}
          formName={formName}
          title={datamanMsgs.msgCassandraUploadCommitDataTitle}
          description={datamanMsgs.msgCassandraUploadCommitDataDescription}
          component={StepData}
          num={1}
          renderError={true}
          putComponentBelow={true}
          fieldProps={{
            colSpecs: data.dataPreview?.colSpecs,
            data: data.dataPreview?.data,
            additionalInformation: { defaultHeader: data.defaultHeader },
          }}
        />
        <GenericFormStep
          fieldName={fieldKeys}
          formName={formName}
          title={datamanMsgs.msgCassandraUploadCommitKeysTitle}
          description={datamanMsgs.msgCassandraUploadCommitKeysDescription}
          component={StepKeys}
          num={2}
          renderError={true}
          putComponentBelow={true}
          fieldProps={{
            colSpecs: data.dataPreview?.colSpecs,
            overwriteCols: formValues?.[fieldData],
            disabled: formErrors[fieldData] !== undefined,
          }}
        />
      </Wizard>
    );
  }

  render() {
    const { loading, error } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }
}

export default reduxForm<FormData, Props>(commitForm)(WizardCommit);
