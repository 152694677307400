import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderByType, SortByType } from './sorting';
import AscDesc, { ASC_DESC_VALUE } from '../../../../../atoms/ascDesc/AscDesc';
import styles from '../../../report-elements/feature-importance/v1/single/styles.module.scss';

export type Props = {
  sort: (sortBy: SortByType, orderBy: OrderByType) => void;
  sortBy: SortByType;
  orderBy: OrderByType;
};

const Header: FC<Props> = (props: Props) => {
  const { sort, sortBy, orderBy } = props;

  return (
    <div className={classNames(styles.featureRow, styles.featureRowHeadline)}>
      <div
        className={classNames(
          styles.featureHeaderCell,
          styles.rejected,
          styles.rejectedHeader
        )}
      >
        <AscDesc
          value={
            sortBy === 'rejected'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'rejected',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.use'
            defaultMessage='Reject/Auto/Force'
          />
        </AscDesc>
      </div>

      <div className={classNames(styles.featureHeaderCell, styles.feature)}>
        <AscDesc
          value={
            sortBy === 'feature'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'feature',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.feature'
            defaultMessage='Feature'
          />
        </AscDesc>
      </div>
    </div>
  );
};

export default Header;
