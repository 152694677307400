import { HierarchyPointNode } from 'd3-hierarchy';
import parseNode from './nodeParse';
import { TreeNode } from '../Node';

export const DEFAULT_NODE = {};
export const DEFAULT_PATH = [];

export function buildPath(nodes: HierarchyPointNode<TreeNode>[]) {
  return nodes.map((node) => parseNode(node)).reverse();
}

export function getParentsFor(first: HierarchyPointNode<TreeNode>) {
  const parents = [first];

  let node = first;

  while (node.parent) {
    parents.push(node.parent);
    node = node.parent;
  }

  return parents;
}

export function getPath(selectedNode: HierarchyPointNode<TreeNode>) {
  const parents = getParentsFor(selectedNode);
  return buildPath(parents);
}

export function getOrigin(node: HierarchyPointNode<TreeNode>) {
  let x;
  let y;
  if (node.parent) {
    x = node.parent.x;
    y = node.parent.y;
  } else {
    x = node.x;
    y = node.y;
  }
  return { x, y };
}

export function getDepth(node: TreeNode) {
  let depth = 0;
  if (node.renderedChildren) {
    node.renderedChildren.forEach((d) => {
      const tmpDepth = getDepth(d);
      if (tmpDepth > depth) {
        depth = tmpDepth;
      }
    });
  }

  return 1 + depth;
}

export function expand(node, depth, maxDepth) {
  return {
    ...node,
    renderedChildren:
      depth < maxDepth && node.children
        ? node.children.map((child) => expand(child, depth + 1, maxDepth))
        : [],
  };
}

export function collapse(node) {
  return {
    ...node,
    renderedChildren: [],
  };
}

export function expansionStrategy(node: HierarchyPointNode<TreeNode>, expandHeight: number) {
  const ancestors = node.ancestors();
  let nextNode = expand(node.data, 0, expandHeight);
  ancestors.slice(1).forEach((ancestor) => {
    let newChildren;
    if (ancestor.data.children[0].id !== nextNode.id) {
      newChildren = [collapse(ancestor.data.children[0]), nextNode];
    } else {
      newChildren = [nextNode, collapse(ancestor.data.children[1])];
    }
    nextNode = {
      ...ancestor.data,
      renderedChildren: newChildren,
    };
  });
  return nextNode;
}
