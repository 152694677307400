import { createAction } from 'redux-act';
import * as Api from '../../core/api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchAugurName,
  fetchRepositoryName,
} from './orchestration.names.module';
import { sendNotification } from './notifications.module';
import { event } from '../../core/notifications';
import { fetchJobDetails } from './orchestration.jobdetails.module';
import { arrayToMap } from 'common/dist/utils/arrayToMap';
import notifications from 'common/dist/messages/notifications';

export const fetchPreQueue = createAction(
  'fetch pre-queue',
  (showSpinner = true) => ({ showSpinner })
);

export const fetchPreQueueSuccess = createAction(
  'fetch pre-queue - success',
  (data) => data
);

export const fetchPreQueueError = createAction(
  'fetch pre-queue - error',
  (error) => error
);

export const cancelJob = createAction('cancel job', (jobCode) => ({ jobCode }));

export const cancelJobSuccess = createAction('cancel job', (jobCode) => ({
  jobCode,
}));

export const cancelJobFailure = createAction(
  'cancel job',
  (jobCode, error) => ({ jobCode, error })
);
export const cancelJobGroup = createAction(
  'cancel jobgroup',
  (jobGroupCode) => jobGroupCode
);

export const cancelJobGroupSuccess = createAction(
  'cancel jobgroup',
  (jobGroupCode) => jobGroupCode
);

export const cancelJobGroupFailure = createAction(
  'cancel jobgroup',
  (jobGroupCode, error) => ({ jobGroupCode, error })
);

export const hideJobGroupConfirm = createAction('hide JobGroup confirm');

export const showJobGroupConfirm = createAction(
  'show JobGroup confirm',
  (jobGroupCode) => jobGroupCode
);

/**
 * Sorts the array of JobGroups ASC by timestamp and returns an array with the JobGroupCodes.
 * This controls the ordering of the Job Queue -> Pre-Queue screen
 * @param data
 * @returns {*[]|*}
 */
function getSortedJobGroupCodes(data) {
  if (!data) return [];
  data.sort((a, b) => (a.addedToPreQueue > b.addedToPreQueue ? 1 : -1));
  return data.map((jg) => jg.code);
}

export const reducer = {
  [hideJobGroupConfirm]: (state) => ({
    ...state,
    queue: {
      ...state.queue,
      pre: {
        ...state.queue.pre,
        cancelJobGroupConfirm: {
          ...state.queue.pre.cancelJobGroupConfirm,
          show: false,
        },
      },
    },
  }),
  [showJobGroupConfirm]: (state, jobGroupCode) => ({
    ...state,
    queue: {
      ...state.queue,
      pre: {
        ...state.queue.pre,
        cancelJobGroupConfirm: {
          show: true,
          jobGroupCode,
        },
      },
    },
  }),
  [fetchPreQueue]: (state, { showSpinner }) => ({
    ...state,
    queue: {
      ...state.queue,
      pre: {
        ...state.queue.pre,
        loading: showSpinner,
      },
    },
  }),
  [fetchPreQueueSuccess]: (state, data) => ({
    ...state,
    queue: {
      ...state.queue,
      pre: {
        ...state.queue.pre,
        loading: false,
        loaded: true,
        error: '',
        jobGroupCodes: getSortedJobGroupCodes(data),
        jobGroups: arrayToMap(data, 'code'),
      },
    },
  }),
  [fetchPreQueueError]: (state, error) => ({
    ...state,
    queue: {
      ...state.queue,
      pre: {
        ...state.queue.pre,
        loading: false,
        loaded: false,
        error: error || 'Pre-Queue could not be loaded.', // TODO Use intl-id
        jobGroupCodes: [],
        jobGroups: {},
      },
    },
  }),
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function* fetchEntityNames(jobs) {
  // TODO this sends exactly one request for every Job. This can be optimized to:
  //   1. Send one single request that is then answered at once
  let augurCodes = [];
  let repositoryCodes = [];
  for (const j of jobs) {
    if (j.superType === 'augur') {
      // yield put(fetchAugurName(j.augurCode));
      augurCodes = augurCodes.concat(j.augurCode);
    }
    if (j.superType === 'code-capsule') {
      // yield put(fetchRepositoryName(j.repositoryCode));
      repositoryCodes = repositoryCodes.concat(j.repositoryCode);
    }
  }

  for (const augurCode of augurCodes.filter(onlyUnique)) {
    yield put(fetchAugurName(augurCode));
  }

  for (const repositoryCode of repositoryCodes.filter(onlyUnique)) {
    yield put(fetchRepositoryName(repositoryCode));
  }
}

export function* fetchPreQueueSaga() {
  const { response, error } = yield call(Api.orchestration.fetchPreQueue);
  if (response) {
    yield put(fetchPreQueueSuccess(response));

    const jobs = response.flatMap((jg) => jg?.jobs);
    yield fetchEntityNames(jobs);
  } else {
    yield put(fetchPreQueueError(error));
  }
}

export function* watchFetchPreQueue() {
  yield takeEvery(fetchPreQueue.getType(), fetchPreQueueSaga);
}

export function* cancelJobSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(Api.orchestration.cancelJob, jobCode);
  if (response) {
    yield put(cancelJobSuccess(jobCode));
    yield put(
      sendNotification(
        notifications.msgJobCanceledTitle.id,
        notifications.msgJobCanceledDescription.id,
        event
      )
    );
    yield put(fetchJobDetails(jobCode));
  } else {
    yield put(cancelJobFailure(jobCode, error));
  }
}

export function* watchCancelJob() {
  yield takeEvery(cancelJob.getType(), cancelJobSaga);
}
export function* cancelJobGroupSaga({ payload: { jobGroupCode } }) {
  const { response, error } = yield call(
    Api.orchestration.cancelJobGroup,
    jobGroupCode
  );
  if (response) {
    yield put(cancelJobGroupSuccess(jobGroupCode));
    yield put(fetchPreQueue(false));
    yield put(
      sendNotification(
        notifications.msgJobGroupCanceledTitle.id,
        notifications.msgJobGroupCanceledDescription.id,
        event
      )
    );
  } else {
    yield put(cancelJobGroupFailure(jobGroupCode, error));
  }
}

export function* watchCancelJobGroup() {
  yield takeEvery(cancelJobGroup.getType(), cancelJobGroupSaga);
}
