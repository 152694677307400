import { CampaignOptimizationChannelsV1AugurSettings as CampaignOptimizationChannelsAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationChannelsV1AugurSettings';
export type { CampaignOptimizationChannelsAugurSettings };
import { DeepPartial } from 'react-hook-form';

import { Config } from '../../../../../pages/augur/type';
import { TupleListTableError } from '../../../../tuple-list-table/TupleListTable';
import { AllowedInputs } from '../../types/util';

export type CampaignOptimizationChannelsConfig = Record<string, never>;
export type ConfigCampaignOptimizationChannelsConfig =
  Config<CampaignOptimizationChannelsConfig>;

export type PartialCampaignOptimizationChannelsAugurSettings =
  Partial<CampaignOptimizationChannelsAugurSettings>;

export type CampaignOptimizationChannelsValidationError =
  DeepPartial<TupleListTableError>;

export const configAllowedInputsCampaignOptimizationChannels: AllowedInputs<CampaignOptimizationChannelsConfig> =
  {};

export const configTypeCampaignOptimizationChannels = `{}`;

export const augurSettingsTypeCampaignOptimizationChannels = `{
    id: string;
    name: string;
    description?: string;
  }[]`;

export const defaultConfigCampaignOptimizationChannels: ConfigCampaignOptimizationChannelsConfig =
  {};

export const defaultAugurSettingsDefaultCampaignOptimizationChannels: PartialCampaignOptimizationChannelsAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationChannelsAugurSettings>
export const defaultValidationSchemaCampaignOptimizationChannels = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'name'],
  },
};
