import classNames from 'classnames';
import {
  BiographyEntry,
  BiographyInfo,
  BiographyInfoEntry,
  BiographyType,
} from 'common/dist/types/augurBiography';
import { formatDate } from 'common/dist/utils/dates';
import qs from 'qs';
import React, { FC } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import ErroneousEntry from './entries/ErroneousEntry';
import EvaluationEntry from './entries/EvaluationEntry';
import LearningEntry from './entries/LearningEntry';
import PredictionEntry from './entries/PredictionEntry';
import TimestampAndLine from './entries/TimestampAndLine';
import styles from './styles.module.scss';
import { AugurDetailsQueryParams } from '../../../components/index/routes';
import {
  locationWithRemovedQuery,
  locationWithUpdatedQuery,
} from '../../../utils';
import { dataManagementRoutes } from '../../dataManagement/routes';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export type Props = {
  biography: BiographyType;
  selectable: boolean;
};

function renderGap() {
  return (
    <div className={classNames(styles.entry, styles.entryGap)}>
      <TimestampAndLine createdAt={null} renderDate={false} />

      <div className={styles.entryBody}>
        <FiMoreHorizontal size={16} />
      </div>
    </div>
  );
}

function shouldRenderDate(
  entry: BiographyEntry | undefined,
  previousEntry: BiographyEntry
) {
  if (!entry || !previousEntry) return true;
  return (
    formatDate(new Date(entry.createdAt), 'dd.MM.yyyy') !==
    formatDate(new Date(previousEntry.createdAt), 'dd.MM.yyyy')
  );
};

export const renderBiographyInfo = (biographyInfo: BiographyInfo) => {
  return (
    <div>
      {biographyInfo.map((entry: BiographyInfoEntry, index) => {
        const { label, type } = entry;

        switch (type) {
          case 'text':
            return (
              <div className={styles.info}>
                <div className={styles.infoTitle}>{`${label}:`}</div>
                <div className={styles.infoValue}>{entry.value}</div>
              </div>
            );

          case 's3_path': {
            const { ds_code, bucket, path } = entry.data;
            const formattedPath = path.startsWith('/') ? path : "/" + path; //Check for leading slash in absolute path
            const tooltipId = uuidv4();
            return (
              <div className={styles.info} key={index}>
                <div className={styles.infoTitle}>{`${label}:`}</div>
                <div className={styles.infoValue} data-tooltip-id={`biography-entry-tooltip-${tooltipId}`}>
                  <Link to={`${dataManagementRoutes.basePath}/s3/${ds_code}/bucket/${bucket}/path${formattedPath}`}>
                    {formattedPath}
                  </Link>
                  <Tooltip id={`biography-entry-tooltip-${tooltipId}`} className={styles.tooltip} delayShow={300}>
                    <b>S3 Data Source:</b> {ds_code}<br />
                    <b>Bucket:</b> {bucket}<br />
                    <b>Path:</b> {formattedPath}<br />
                  </Tooltip>
                </div>
              </div>
            );
          }

          case 'cassandra_table': {
            const { ds_code, keyspace, table } = entry.data;
            const tooltipId = uuidv4();
            return (
              <div className={styles.info} key={index}>
                <div className={styles.infoTitle}>{`${label}:`}</div>
                <div className={styles.infoValue} data-tooltip-id={`biography-entry-tooltip-${tooltipId}`}>
                  <Link to={`${dataManagementRoutes.basePath}/cassandra/${ds_code}/keyspace/${keyspace}/table/${table}`}>
                    {table}
                  </Link>
                  <Tooltip id={`biography-entry-tooltip-${tooltipId}`} className={styles.tooltip} delayShow={300}>
                    <b>Cassandra Data Source:</b> {ds_code}<br />
                    <b>Keyspace:</b> {keyspace}<br />
                    <b>Table:</b> {table}<br />
                  </Tooltip>
                </div>
              </div>
            );
          }

          default:
            return (
              <div className={styles.info}>
                <div className={styles.infoTitle}>{`${label}:`}</div>
                <div className={styles.infoValue}>Unknown type</div>
              </div>
            );
        }
      })}
    </div>
  );
};

const AugurBiographyList: FC<
  Props & WrappedComponentProps & RouteComponentProps
> = ({ biography, location, history, selectable }) => {
  const queryParams: AugurDetailsQueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  function changeQueryParameters(entry) {
    if (entry.jobType === 'learning') {
      //Deselect option
      if (entry.modelCode === queryParams.modelCode) {
        history.push(locationWithRemovedQuery(location, ['modelCode']));
      } else {
        //Select Option
        history.push(
          locationWithUpdatedQuery(location, {
            modelCode: entry.modelCode,
          })
        );
      }
    }
    // Evaluation/ Prediction
    else {
      //Deselect option
      if (entry.modelCode === queryParams.modelCode) {
        history.push(locationWithRemovedQuery(location, ['modelCode']));
      } else {
        //Select option
        history.push(
          locationWithUpdatedQuery(location, {
            modelCode: entry.modelCode,
          })
        );
      }
    }
  }

  return (
    <div className={styles.augurBiographyList}>
      <div className={styles.entries}>
        {biography.entries.map((entry, i) => {
          const renderDate = shouldRenderDate(entry, biography.entries[i - 1]);
          if (entry.error)
            return <ErroneousEntry entry={entry} renderDate={renderDate} />;
          let entryComponent;

          switch (entry.jobType) {
            case 'learning':
              entryComponent = (
                <LearningEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
            case 'evaluation':
              entryComponent = (
                <EvaluationEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
            case 'prediction':
              entryComponent = (
                <PredictionEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
          }
          return (
            <div
              key={i}
              onClick={() => {
                selectable && changeQueryParameters(entry);
              }}
            >
              {entryComponent}
            </div>
          );
        })}
        {biography.overlappingWithGap && renderGap()}
        {biography.overlappingLearning && (
          <div key={'overlappingLearning'}>
            <LearningEntry
              entry={biography.overlappingLearning}
              renderDate={true}
              selected={false}
              selectable={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(withRouter(AugurBiographyList));
