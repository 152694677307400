// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/parameter-tuning/v1/type.ts' --type 'ConfigParameterTuningConfig'
export const schemaConfigParameterTuning = {
  $ref: '#/definitions/ConfigParameterTuningConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    AvailableAlgorithm: {
      additionalProperties: false,
      properties: {
        description: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        parameters: {
          items: {
            $ref: '#/definitions/Parameter',
          },
          type: 'array',
        },
        speakingName: {
          type: 'string',
        },
      },
      required: ['name', 'speakingName', 'description', 'parameters'],
      type: 'object',
    },
    'Config<ParameterTuningConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        parameterTuningSchema: {
          $ref: '#/definitions/ConfigEntry%3CParameterTuningSchema%3E',
        },
      },
      required: ['parameterTuningSchema'],
      type: 'object',
    },
    'ConfigEntry<ParameterTuningSchema>': {
      anyOf: [
        {
          additionalProperties: false,
          properties: {
            source: {
              const: 'hard-coded',
              type: 'string',
            },
            value: {
              $ref: '#/definitions/ParameterTuningSchema',
            },
          },
          required: ['source', 'value'],
          type: 'object',
        },
        {
          additionalProperties: false,
          properties: {
            elementUuid: {
              type: 'string',
            },
            source: {
              const: 'input-element',
              type: 'string',
            },
          },
          required: ['source', 'elementUuid'],
          type: 'object',
        },
      ],
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
    },
    ConfigParameterTuningConfig: {
      $ref: '#/definitions/Config%3CParameterTuningConfig%3E',
    },
    Parameter: {
      additionalProperties: false,
      properties: {
        default: {
          items: {
            type: 'number',
          },
          type: 'array',
        },
        description: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        speakingName: {
          type: 'string',
        },
        validValues: {
          items: {
            $ref: '#/definitions/ValueType',
          },
          type: 'array',
        },
      },
      required: [
        'name',
        'speakingName',
        'description',
        'validValues',
        'default',
      ],
      type: 'object',
    },
    ParameterTuningSchema: {
      additionalProperties: false,
      properties: {
        availableAlgorithms: {
          items: {
            $ref: '#/definitions/AvailableAlgorithm',
          },
          type: 'array',
        },
        availableSearchStrategies: {
          items: {
            additionalProperties: false,
            properties: {
              description: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
              speakingName: {
                type: 'string',
              },
            },
            required: ['name', 'speakingName', 'description'],
            type: 'object',
          },
          type: 'array',
        },
      },
      required: ['availableSearchStrategies', 'availableAlgorithms'],
      type: 'object',
    },
    ValueType: {
      additionalProperties: false,
      properties: {
        max: {
          type: 'number',
        },
        min: {
          type: 'number',
        },
        type: {
          enum: ['int', 'double'],
          type: 'string',
        },
      },
      required: ['type', 'min', 'max'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/parameter-tuning/v1/type.ts' --type 'PartialParameterTuningAugurSettings'
export const schemaAugurSettingsDefaultParameterTuning = {
  $ref: '#/definitions/PartialParameterTuningAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialParameterTuningAugurSettings: {
      additionalProperties: false,
      properties: {
        algorithms: {
          items: {
            additionalProperties: false,
            properties: {
              name: {
                type: 'string',
              },
              parameters: {
                items: {
                  additionalProperties: false,
                  properties: {
                    name: {
                      type: 'string',
                    },
                    values: {
                      items: {
                        type: 'number',
                      },
                      type: 'array',
                    },
                  },
                  required: ['name', 'values'],
                  type: 'object',
                },
                type: 'array',
              },
            },
            required: ['name', 'parameters'],
            type: 'object',
          },
          type: 'array',
        },
        searchStrategy: {
          additionalProperties: false,
          properties: {
            name: {
              type: 'string',
            },
          },
          required: ['name'],
          type: 'object',
        },
      },
      type: 'object',
    },
  },
};
