import classNames from 'classnames';
import React, { Component, KeyboardEvent } from 'react';
import { FiX } from 'react-icons/fi';
import onClickOutside from 'react-onclickoutside';

import styles from './styles.module.scss';

export type Props = {
  loading?: boolean;
  loaded?: boolean;
  submitSearchQuery: (search: string | undefined) => void;
  initialValue?: string;
  submitOnChange?: boolean;
};

type State = {
  value: string;
};

class TextInputSearch extends Component<Props, State> {
  initialState = {
    value: '',
  };
  inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = this.initialState;
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.reset = this.reset.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick() {
    this.props.submitSearchQuery(this.inputRef.current?.value);
  }
  handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') this.handleClick();
  }

  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue) {
      this.setState({ value: initialValue });
    }
  }

  handleClickOutside() {}

  reset() {
    //wait till this in finished
    this.setState(this.initialState);
    this.props.submitSearchQuery('');
  }

  render() {
    const { loading, submitOnChange } = this.props;
    const { value } = this.state;
    return (
      <div className={styles.TextInputSearch}>
        <div className={styles.SearchBarInputGroup}>
          <div className={styles.SearchBarInputGroupButton}>
            <button
              data-testid={'SearchBtn'}
              type='button'
              className={styles.SearchBarButton}
              disabled={!value}
              onClick={this.handleClick}
            >
              {!loading && (
                <span
                  className={classNames('icon-search', styles.iconSearch)}
                />
              )}
              {loading && <span className='icon-refresh icon-spin' />}
            </button>
          </div>
          <input
            data-testid={'Search'}
            ref={this.inputRef}
            type='text'
            className={styles.TextInputSearchInputGroupField}
            placeholder={'Search'}
            autoComplete='off'
            value={value}
            onChange={(e) => {
              this.setState({ value: e.target.value });
              if (submitOnChange) {
                this.handleClick();
              }
            }}
            onKeyDown={this.handleKeyDown}
          />
          <div className={styles.fixedContainer}>
            {value && (
              <div className={styles.SearchBarInputGroupButton}>
                <button
                  data-testid={'SearchReset'}
                  type='button'
                  className={styles.SearchBarButton}
                  onClick={this.reset}
                >
                  <FiX size={16} className={styles.SearchBarIconCross} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
/** @deprecated use react hook form variant instead*/
export default onClickOutside(TextInputSearch);
