import classNames from 'classnames';
import React, { FC } from 'react';
import { DeepPartial } from 'react-hook-form';
import { FiMinus } from 'react-icons/fi';

import commonStyles from './commonStyles.module.scss';
import styles from './styles.module.scss';
import { TableRow, TupleListTableError } from './TupleListTable';
import { IntlTextInputLine } from '../../../components/atoms/react-hook-form-input-elements/text-input-line/TextInputLine';

type Props = {
  rowIndex?: number;
  rowId: string;
  label1: string;
  placeholder1: string;
  label2: string;
  placeholder2: string;
  removableRows?: boolean;
  onRemoveRow?: (removedRow: TableRow) => void;
  data: TableRow[];
  setData: (data: TableRow[]) => void;
  isTouched?: boolean;
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  error?: DeepPartial<TupleListTableError>;
  readOnly?: boolean;
};

const drvNewValue = (
  data,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...data.slice(0, rowIndex),
    {
      ...data[rowIndex],
      [key]: eventValue,
    },
    ...data.slice(rowIndex + 1),
  ];
};

const renderField = (
  props: Props,
  fieldKey: string,
  label: string,
  placeholder: string,
  index?: number
) => {
  const {
    rowIndex,
    rowId,
    data,
    setData,
    isTouched,
    invalid,
    onBlur,
    error,
    readOnly,
  } = props;

  return (
    <IntlTextInputLine
      key={`tuple_${fieldKey}`}
      isTouched={isTouched}
      invalid={invalid}
      error={error?.rows?.[rowId]?.[index]}
      label={label}
      placeholder={placeholder}
      value={data[rowIndex]?.[fieldKey]}
      onChange={(e) => {
        const newValue = drvNewValue(data, e.target.value, fieldKey, rowIndex);
        setData(newValue);
      }}
      onBlur={onBlur}
      disabled={readOnly}
    />
  );
};

const AddTuple: FC<Props> = (props) => {
  const {
    rowIndex,
    removableRows,
    onRemoveRow,
    label1,
    placeholder1,
    label2,
    placeholder2,
    data,
    error,
    readOnly,
  } = props;
  const handleRemoveRow = () => {
    if (!readOnly) {
      onRemoveRow && onRemoveRow(data[rowIndex]);
    }
  };
  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={classNames(commonStyles.RemoveIcon, {
              [commonStyles.readOnly]: readOnly,
            })}
            onClick={handleRemoveRow}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderField(props, 'value1', label1, placeholder1, 0)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderField(props, 'value2', label2, placeholder2, 1)}
        </div>
      </div>
    </div>
  );
};

export default AddTuple;
