// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-communications/v1/type.ts' --type 'ConfigCampaignOptimizationCommunicationsConfig'
export const schemaConfigCampaignOptimizationCommunications = {
    "$ref": "#/definitions/ConfigCampaignOptimizationCommunicationsConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Channel": {
            "additionalProperties": false,
            "properties": {
                "description": {
                    "description": "Optional description of the channel",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the channel",
                    "type": "string"
                },
                "name": {
                    "description": "Name of the channel",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name"
            ],
            "type": "object"
        },
        "Config<CampaignOptimizationCommunicationsConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "properties": {
                "channels": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-1562112972-469-681-1562112972-0-3782%5B%5D%3E"
                },
                "propensities": {
                    "$ref": "#/definitions/ConfigEntry%3Cdef-alias-1562112972-0-469-1562112972-0-3782%5B%5D%3E"
                }
            },
            "required": [
                "channels",
                "propensities"
            ],
            "type": "object"
        },
        "ConfigCampaignOptimizationCommunicationsConfig": {
            "$ref": "#/definitions/Config%3CCampaignOptimizationCommunicationsConfig%3E"
        },
        "ConfigEntry<def-alias-1562112972-0-469-1562112972-0-3782[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/Propensity"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigEntry<def-alias-1562112972-469-681-1562112972-0-3782[]>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "items": {
                                "$ref": "#/definitions/Channel"
                            },
                            "type": "array"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "Propensity": {
            "additionalProperties": false,
            "properties": {
                "description": {
                    "description": "Optional description of the propensity",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the propensity",
                    "type": "string"
                },
                "valueId": {
                    "description": "valueId of the propensity. The valueId will later be matched into the \"value_id\" field of the customer table. The customer table has to be uploaded by the User. To select the customer table is one step in the New Augur Wizard when creating a new Augur",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "valueId"
            ],
            "type": "object"
        }
    }
}

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-communications/v1/type.ts' --type 'PartialCampaignOptimizationCommunicationsAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationCommunications = {
    "$ref": "#/definitions/PartialCampaignOptimizationCommunicationsAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Communication": {
            "additionalProperties": false,
            "properties": {
                "boostFactor": {
                    "description": "Boost Factor of the communication =\"conversion rate of this communication\"\n-> \"If I contact someone via Channel A and ask him to buy product(=valueId via the propensityId) B the conversion rate is 0.5\"",
                    "type": "string"
                },
                "channelId": {
                    "description": "Channel ID (foreign key of a Channel)",
                    "type": "string"
                },
                "description": {
                    "description": "Optional description of the Communication",
                    "type": "string"
                },
                "fixedCosts": {
                    "description": "Fixed costs for this communication\n-> \"If I decide to contact someone via Channel A and ask him to buy product B this will initially cost 1000.0 (for example to design a catalogue or to train the call center). These costs are ignored if no one is contacted via this communication - and will fully be added if there is at least one person is contacted via this communication.",
                    "type": "string"
                },
                "id": {
                    "description": "Auto generated uuid for the Communication",
                    "type": "string"
                },
                "name": {
                    "description": "Name of the Communication",
                    "type": "string"
                },
                "propensityId": {
                    "description": "Propensity ID (foreign key of a Propensity)",
                    "type": "string"
                },
                "variableCosts": {
                    "description": "Variable costs for this communication\n-> \"Each time I contact someone via Channel A and ask him to buy product B the costs are 10.0",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "channelId",
                "propensityId",
                "boostFactor"
            ],
            "type": "object"
        },
        "PartialCampaignOptimizationCommunicationsAugurSettings": {
            "items": {
                "$ref": "#/definitions/Communication"
            },
            "type": "array"
        }
    }
}