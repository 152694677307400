export const targetHierarchyImg = `
iVBORw0KGgoAAAANSUhEUgAAAqwAAAFeCAMAAAB3rr2NAAAA6lBMVEX////MzMzu7u/j4+MHGzL6+/v39/f8/Pzx8fH19fX+/v7y
8vP5+flea3rl5eXr6+vp6enz9PTU1NR7hZLe4eTCyM0IHDMNIDedpa65v8UhM0gdL0Tk5OQVKD3n5+fM0dV2gY7o6Ojb3uG/xMrt
7e2AipaCjJcYKkBueoc7Sl1TYXE0RFfIzNGpsbhodYOpqamQmqRMWmtBUWJgbHxHVmcoOk4RJDrR1dkvQFPo6uyyuL+Ejpra3eDV
2dxXZHS8wcelrLS1u8GVnqiutbxjcH7i5OehqLG2vMIsPVGMlqDg4OCZoavZ296Ikp1h8gdrAAAWoklEQVR42uzXQU4DMQxGYbtW
JpNMFyQH4ECj3v88CBAS3ZkCdT193zqbSE+/EgEAAAAAAAAAAAAAAAAAAAAAAMc39qlAiLkP+YFdgUC7uF1UrXUBAvRmqhf/rs4m
QJg2vds6VGkVoZrqcA6rCRDKnNM6GVZEazrFQ5W/FYJ1VWesAgQjVqRBrEiDWJEGsSINYkUaxIo0iBVpECvSIFakQaxIg1iRBrEi
jV/FWssVuUkvVQ6ulv5kN/7yQLGe7EqVWyy2ycGtdpZvmq2Ce8e6bO9O9rp9iIn1vBZxq+viOUCsD+kP3qzFXuRTSKzDFnErtnoO
/G+stRUBsaaIFcRKrG/snd2O2yAQhRFHw6814HtkCcnv/4xVzBTskqx2q2altj4XdYiHYSb+QhicdP91/TlYrfOtLQ+Ns4p03VKk
AabL2x7DBCu5XPc4m8kikmIyx+O4b1mb64o1YXcNMHJpS+5XLkJM25ZiOHxpZOeo+c3ytKx7jcu2G4jEribfvXlds+5N5VPmrGXM
uD5CjBKqdBNYjatZG3G5yL9P8s4xKOPu7YI3z6xb+UlhhFfKgRxKTozi5ULr1t7oF1g9OCVGCk/MCM4D8A+kGJwyLnPfygXM3EbF
lqqc7rIbkDPApBQxozAvj35ATmkDW5mdNQDbDbpMQUkPF/LWyY8QCpJtzQpOey4yJmdiQPdQGdUKrBr10XaS8Yu8UR8hLQ73ovbN
sDqsqqmW8Gi69gHvCxqtGTo8rhIXe4F1g2/90xMzgi7Z2MPwoCgkuGfLANfIsAm7OikhBqWCxyboy6BVKE3toKGXMC8DAhdzzILN
qeVy5BiRJdQoCC8N1syeDsT56LY3O4eNlxabYPos74RMhzPeb1jfDauVo1qgj2uAVdoNFA89zg8ZwAvjsLMZATLz1J+Mp2JnWAk1
yOnLCrFUJSybE4sJ3VfzgTitWS+h5vLwrmFOnx2KeqitF4OtWJOwfnRwkLYtVWCd8zaQSEPGDevbC6wkr7GWK7QpkT4uy8ZBPaHN
dDuHZTYjiFePOIqgGdYdvp9OqitAvFPyp6419THp8MGTbwlOcPSJzp4D50c3NtJsJxjx2kseud7OEFjnvMd7zNywvh9W2ewOvLVL
EMcld20aEkWYiQd5ejIj1Nba0QnnPMPK3J+pRQ1lxI+K/SSw6ucGoWA5D+VHv8sELLBKiHrAZoNSyvV+SWCd8r4ksN2wvh3WwHxi
1sEPi/24OEYkpzrJA7vJbMxmlc1PbTzBGiSI8Rkv8gVbpGewkom6QmBdX9DsgLTa3vBG1MewxrtUBFaJKyO82LpKCA3WKe9LAjes
b4dVaZjBSi9p5UJEnOSe7bOuMJPZmGkLTppgpVNZpbGcw0sAWJsriyYVAJyywOpnWMWwAtgcNYhOaoXXBgDVCaxVqQ7th7BOeV8T
uGF9N6wClkXuFY3IIim1IlKXfQHrZDboYaahCdZjCNGvxXQwLgP6XOxrsPNkx5rVTLB2Wa8rynqgZqgrKEWMtC6PXJDOy5OtfBXW
awL3bsDbYZWaOcJfd7KkpjYzBjOsk9mgJ+PDO1htofzSkjLi8GZk3/QFrLMMg/r7r6u29gRrgv0yrKrUkcAN6zfAusK3Tda+fTi4
pdE2kV7AOpkNevZ+xUNcZ1hzB8SWbf5OlkQp3nSnLH0Mq3dBRoE7Zv0+agzK9sTpCquG7wVa/Rys1wRuWL8B1lAyQffShHrhFc7z
hS14tQyYzcYqE6mzr2dY106Z6w4ve7rMg8W9j8EfwxrhTzWiZQ4SDOp5mekGrNdXxMN9FlbfR9b31tXbYW0U9O1Ch7JZ1W85jbtG
KnWAJlhns0FPFkNCsWreT8qlHU3ZwuUW1CLD7GMbPyL2/fflF1j1NbfNCkDrkdMemtdj0MJWkETusJ4ztMz2s7AqhhdvMrPGaNXp
aOM//y3174XV4LTVbTZ2ZokVKcgz7Az5jBxewTqbDVhpQ1rJOG5mo3+ipVU72pPX4OVy+vBmXGFq9wgWssoy3PJwlR207yMPgy6H
bV3IpBZMSKhxWdYNrjFfPS0+l5WLoROsth6hxgKjPg3rwtg9+R1adgP4OPQjgdX/rT8Mq9oQx70h2/aHXAdnA4DiwssCazYbsKqw
FwDIizorVPlFjdV4KAlq10GRqP8Sxx/1VovMMkofeRgMrXwEo8VrPJrsz61Mj17xBKsK+gi1LurzsCrbgopqR7hhfQusQ6POGXX2
4kmdZI2noJ7qU2bkjX3ypLAQyC9PupHxi+2uvQmtk7fTr/e6gUicXrxaf0opGDlH9DTULyosR1C5qFvvhvVSy9/fc/ua6LQtndWt
98OqYW5Yf08avj+6/zTON8DqkdUN6++JSqsMg8ambr0b1spgumH9XRlG3fcK5PtHLe+H1SVHo7Wm+zX/mkJMlbO+1wD3/3V166/X
D3bt4ASiIASCqM2cnNsYlvz849kU3JM01IuhEIQmVtggVtggVtggVtggVtggVtggVtggVtggVtggVtggVtgYx3oDWHWHsZbYAmFZ
qmKidQJYddQx8cRpxa6UXoy0ilqxKEsdQ590ki8LK24e6YuxFrCo4w+vS8CK6hcAAAA/9u62J20oDMDwk+aBQtUPBtpqG1K2Od4E
wjvoUKei0+j+/99ZOa1zzmwumwO63denBmj4cudwQtLnAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB+R65YsH6oUMwJsCGK1s9xTj42
xVZco+3IDzh23PKWABugaBVs+Sm7wNqKTZCzLFteYFsW+1asX/FXVs0iSys2QMGy5UW2VRBg3SzLkRc5liXAuv1ahiuOtT2aCvC6
sUal+R9+fWlfnuuqK8Drxrqn+/JH3mpJnplWiBWZiLXcqyuxIgOxVn1VJVa8dqztWkt3ajVZirqDcfP02Jaly5q922nsyNLRxeLw
sBtJrnYp6QvD2ajqmstaQwe1WlkeRZexFrHilWPdC+paDwIRcRZa6Q/7ofZNrTt60FRtSswNNez361otahJvO9TxadP3arbISRBq
JQja8p0qseKvbQNq2t0VkXxXb5NYq37vJLds1Z9cLGO+9ktJrO980+bujpYetwHEipXF6oQzW5a26/0k1kpa20iPxThXE6t9WHkj
RmtyRKxYfazz4IMkmmES66kYkfYlNTCxdrQniRMtESvW+G/AVlhJYj0X4073JPXRxDrUN5IKR8SK9cSajy5vF4Gmsc7FKD1WF5lY
m+G7B7MxsWINsdp7A9/zvHHjJo21IJJclCV1YGKtq/eV7xArVh5rvqnN3lk5LzJKY90Wo6FtSZVNrGFQfkSsWH2sVX0vicHTWHt6
LKl9E+vAt+Vbc2LFamM99HNi2PWnse5r92uAJtaFRpLI966JFauPNQgk4erTWO3xJL3zaGJidfVUEiWtEitWGOt18jPfStZL5z7U
if0Yq/nAwNxqDSomVhl5F47Epn7lyuxkq8SKFcRqNqL3UV7afngxnV4u/GpL79xvY7Ub3s1nt307DlwdSuztTE+vp+6Huncusbw3
i06OiBV/P9btpqpeiXy68WKzczn2vVYcq7ctD+6C+J3J6OphDd1dVLxYsy1LTsNT/fgsVo9Y8aqxGiedyBYRO+q825LYwTQv37k6
bsftnmlPEvZ95+xKHhx13LwAa39gcMstS6qhZwJsbqwH3swRY16/cQTY3Filod1tiUWhfhRgk4dc5IZepdXt13XSEWDDxwdFjVFw
OLydC8BgNvwvGHmJ7GCYMLKDMe3IDg7AQHZwtBAAAAAAAAAAAAAAAAAAAAAAAAAAAP8AHhhEVvAoNrKCIRfICsYHISsYzIbMYOQl
MuMLe3fbmzQUhnH8irloFSFmQlvWBotOy0MBeYYhD2ODuWX6/b+OcFo2UFAjqw57/xKWZcmWvfjn7ISd3ue3hwkLEYGox7S/3gNC
HCCSCzD2VBl9rKO7UqVwLYt8bEUVq5sZHfiLZU6xJeVZdAbLl1z7EldRxZrjKQ7yjhlsmdMfA4nLPGcQsXQ0sZ7R17ByRScFEUeH
xPrx48flh78Ua4VTBHz2IeLooFjV6+OOWKtehWnPw4pbb3XLk6GOlStPP6mV0lg5v5z7ft1FwrtC+IX2oFMw1KdeiS3Py+Je10og
UJfLCmPqwJV1T6w5u8iibQNIzplvtpsOm6rWNN+XyTKWDIdOs1lk4QXTQeEOu5OyZXo6MLYd5m27ijW92UZoIlcWx1QEK+vWNsBj
/QSAVudtEGvBaowTAAyrdwkgeW1lgljPLNXmSZqZ9TZgp6TNVxBxdGCsv9qzJp2BjpVUsRnEmjegdDiEMqOKVffzb6FUeuc/i/UT
KxCxFPG7ASP7EwJlJ4h1AsVlE6GWirXGBgJjZvbHmmyYxTcQsXTgnlW99sW66aWTD2KdQbljDqELFWubbxFyOntjzXZoy3sBcXVA
rOGO9Zexau7V7dxmGOsISoYGQq6KteycrQ26e2JNfbA4OYeIqcNi/fXKqudaFslu6SaM9TmUNLMIPVOxFvnASu6Mte/zRv57FWMR
r6xameXGNKsBnTDWFJQSqwhlVayOnX2wM9aa1WvIIfA4i3hlLdwn19qOtcEhQqcq1pal//xsQM5sLSDiLOJ3A3wrAUUvbsd6yjpC
BRXrnC4CWuN6R6ynrMiRgJiLOFbbRsDgdqx6t/cMyqueitXgBIEMCz/GqvtdDSLeoor1OvgzXwnXy5HDnr4ZK67ZeqZ+dCuvYkXH
vMRK38ovAGRZwIYhGxAxF1WsU6a/uBqqlnPZ7w/nVqHCO2MzVr3Em69G9bZrG2yrTeqAk4ux8alozrCkmQN3fI61r0x/uJeFiKM/
iXUPbEqVSS6AzzckzcEMQ5OVMNbQnU3S6izWa+hJu8elchVKySQvsFbnBgMijv4g1t80rrk6AN2tnb3E0vu+hu8shtUUMGUDAf1L
bbrA2nnNkG2qODjWw700sgiV5MifeNKxvjcHSSij4k0SQjzdWFFiPYUl15GD/yKCIRePKdE285V6s8heDUI88fFBbqlj++3bEYSQ
wWzi/yIjL8XxkGHC4njImHZxPOQCDHE85GohIYQQQgghhBBCCCGEEEIIIYTYJP+WFbElB17EsZCjhOJYyCFtcSzk8RdxNOTBQnE0
fvuRbSH+yP8wDEOIoxkztNforlQpXD/Hj046MpAo1g6L1c2McJBnmVNsSXkWncHytaPLGj9BxNhhseZ4ikP8eHfLnP4YSFzmOcN3
nvsSa7w9sVjP6GtYuaKT2v4VLnxKrPH2xGKtcIqAzz4e1PIkJdaYOyTWqldh2vOw4tZb3fJkqGPlytNPaqU0Vs4v575fd5HwrhB+
oT3oFAz1qVdiy/OyuNe1EgjUeYEH2auljMQab4fEmrOLLNo2gOSc+Wa76bCpak3zfZksY8lw6DSbRRZeMB0U7rA7KVumpwNj22He
tqtY05tthCac4jufJdZ4e5xtgMf6CQCtztsg1oLVGCcAGFbvEkDy2soEsZ5Zqs2TNDPrbcBOSZuvJFbx+LEmnYGOlVSxGcSaN6B0
OIQyo4pV9/NvoVR65z+L9RMrkFjF48c6stcVlZ0g1gkUl02EWirWGhsIjJnZH2uyYRbfSKwiyncDXjr5INYZlDvmELpQsbb5FiGn
szfWbId2HxKriChWzb26ndsMYx1BydBAyFWxlp2ztUF3T6ypDxYn55BYRSSx6rmWRbJbugljfQ4lzSxCz1SsRT6wkjtj7fu8mQES
q4gkVq3McmOa1YDO9oXuJVYRyqpYHTv7YGesNavXSEBiFRHFWrhPrrUda4NDhE5VrC1L//nZgJzZWgASq4gqVt9KQNGL27Geso5Q
QcU6p4uA1rjeEespKylIrCK6WG0bAYPbserdXvidr3oqVoMTBDIs/Bir7nc1SKwiilivgz/zlXC9HDns6Zux4pot9a3PWnkVKzrm
JVb6Vn4BIMsCNgzZwJbZbV9iFY8S65TpL66GquVc9vvDuVWo8M7YjFUv8earUb3t2gbbapM64ORibHwqmjMsaebAHZ9j7SvTH+5l
AbSZk1jFo8SaKpNcAJ9vSJqDGYYmK2GsoTubpNVZrNfQk3aPS+UqlJJJXmCtzg2GxCoeK1ZlXHN1ALpbO3uJpfd9Dd9ZDKspYMoG
AvqX2nSBtfOaoUGIf/7A4Esji1CJUwjxdGN9bw6SUEbFmySEeLqxosR6CkuuQ3mMWjztIReJtpmv1JtF9moQ4omPD3JLHdtv344g
hAxmE3EhIy/F8ZBhwuJ4yJh2cTzkAgxxPORqISGEEEIIIYQQQnxr715b0wbDMI5fjFuzWWUUTdImuGRra+IRz6d5qq1dS7fv/3Wm
T6LT6caYwsxy/UAQUeOLPzGE5H6IiIiIiIiIiIiIiIiIiIiIiIiI6D/AGwYpKngrNkUFh1xQVHB8EEUFB7NRZHDkJUXGHw8TJvob
ERrTThSZBTD+wujVL7lPb/d+wswt+fNPoPg6LlYnOzpy89lr7EgXTLH7y8cMO6q2dMt5Me/SoLg6flXsI+wv4Y5HKY+BxDwv99gy
E/tLArjuiAuKqzOL9UbKSaw8iJ3e2r5nfsJKLm/0QDF1ZrGWZIpAWbaqrG/e5kodFFPHxFoplCRTKGDFqTU7xclQw8pDQbus+hms
DOaP5XLNQaLwgPCFVr/t6uppwZdmoZDDRsdMIFCTGTZqoiMwkxoopo6J9cryxLMsAKlHyTdaDVsaqtaM3BZFiljSbbEbDU/cd5IJ
CrelMymaRkEDxpYtecuqYE1rtBCayBQbbmOAwFyyoJg6zWFAQWqXAJI1eQlidc36OAFAN7tzAKknMxvEemOqNi8zkl0fBhyUsuQ9
DsgI19WMrZPEmrL7GlbSXiMoKq9DacsQyr2oWLVy/iOUUnfwu1g/SwkH3BjWJSimThLryPqMQNEOYp1AcaSBUFPFWpU6AmPJ/jrW
VN3wPmDf1DPuQXF12rMBF3Y+iDVM6lWuEJqpWFvyESG7/ctYc22xethz4RsmDwJi7GSxJp2Hl0dLwlhHULKiI+SoWIv2zVq/84tY
03emTAbYU7Wl7IDi6zSxaldNU0Q6/nMY61soGcltvkLF6skPZupgrL2yPN9jz5uJeHVeVhtrJ4k1WZRifZpLAu0w1jQUXyoI5VSs
tpX74WCsVbN7qMmxZdR46VfMnSRWd5NcczfWugwRulaxNk3t99cGXBnNBfY5nqWDYu4ksZbNBBTN2431WmoIuSrWR3EQSNafDsR6
LaU09g3ynY+guDtJrJaFgC67sWqdbvjJ910Vqy4TBLLi7seqlTtJHOAbY1DsHRfrU/A3Xwr3lyNbutp2rHiSpvrom2ZexYq2McdK
z8wvAOTExZah1LHj/qUH4NYsgei4WKeS+eokUTHtea83fDTdkrzq27Fqvjx/0ysvHUuXFpY+9WUyG+ufw7P7SaPvjAdY+yaZu40c
gJZcAahI8W7jBhRTx8WaLorIAvjyLCJG/x5DQ0phrKFXS0TM9mK9D71sdWWpWIHiGyIzrNVki76JtSpbCqCYOiJWZVx1NACaU725
wNJtL4mfLIaVNDCVOgLa1+p0gbVBVU+C6J/fMHih5xDyZQqi84311uinoIy85xSIzjdW+FJLY8mxZQaicx5ykWgZ+VKt4UmXl0vR
2Y8Pcvy2VW69jEDEwWwUFxx5SdHBYcIUHRzTTtHBBTAoOri0EBERERERERERERERERERERERERERERERERERERERERERERERERFF
33e+Rf4Oz6N0EwAAAABJRU5ErkJggg==
`;