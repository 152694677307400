import { connect } from 'react-redux';
import GitNotPulled from './GitNotPulled';
import {
  gitListCommits,
  gitListNotPulledCommits,
  gitPull,
  hidePullConflictsModal,
} from '../../../../../../redux/workbench/modules/container-interactions.module';

export function mapStateToProps(state) {
  const { loading, loaded, data, error } =
    state.workbench.showRepositoryInfo.gitNotPulledCommits;
  return {
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    loading,
    loaded,
    data,
    error,
    pullConflictsModal: state.workbench.pullConflictsModal || {},
  };
}

export const mapDispatchToProps = {
  gitListNotPulledCommits,
  gitPull,
  hidePullConflictsModal,
  gitListCommits,
};

export default connect(mapStateToProps, mapDispatchToProps)(GitNotPulled);
