import React, { Component, Fragment } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Props } from './DataSourceContentElement.container';
import { dataManagementRoutes } from '../routes';
import './dataSourceContentElement.scss';
import { dataSourceIcon } from './change-ds/step-ds-options/DataSourceButton';

import {
  DSRolesToSpeaking,
  typeToSpeaking,
} from 'common/dist/types/dataManagement/dataSource';

import { ReduxActUnknown2 } from '../../../store/reduxAct';

export default class DataSourceContentElement extends Component<Props, {}> {
  render() {
    const { ds, showDeleteDataSourceConfirm } = this.props;
    return (
      <Fragment>
        <div className={'data-source-element'}>
          <Link
            to={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/${ds.code}`} /*Redirect to the editpage*/
            style={{ textDecoration: 'none', flexGrow: 1 }}
          >
            <div className={'element'}>
              <div className={'element-icon-parent'}>
                {dataSourceIcon(ds.ds_type)}
              </div>

              <div className={'element-ds-container'}>
                <div className={'element-name'}>
                  <span className={'name'}>{ds.name}</span>
                </div>
                <div className={'element-ds-type'}>
                  <span className={'ds-type-details'}>
                    <span className={'ds-type'}>
                      {typeToSpeaking(ds.ds_type)}
                    </span>
                  </span>
                </div>
              </div>

              {ds.role && (
                <div className={'element-ds-role-container'}>
                  <div className={'element-ds-role'}>
                    <span>{DSRolesToSpeaking[ds.role]}</span>
                  </div>
                </div>
              )}

              <div className={'element-ds-buttons'}>
                <Link
                  to={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/${ds.code}`}
                >
                  <div
                    className={'manage-ds-button'}
                    data-testid={`edit-${ds.name}`}
                  >
                    <FiEdit className={'icon'} size={'20px'} />
                  </div>
                </Link>
                <div
                  data-testid={`delete-${ds.name}`}
                  className={'manage-ds-button'}
                  onClick={(e) => {
                    (showDeleteDataSourceConfirm as ReduxActUnknown2)(
                      ds.name,
                      ds.code
                    );
                    e.preventDefault();
                  }}
                >
                  <FiTrash2 className={'icon'} size={'20px'} />
                </div>
              </div>
            </div>
          </Link>
        </div>
      </Fragment>
    );
  }
}
