import qs from 'qs';
import { useHistory } from 'react-router-dom';

import { TimeTravel, TimeTravelHelpers, TimeTravelSelection } from './types';
import { AugurCategory } from '../../molecules/augur-menu/types';

export const useTimeTravel = (): TimeTravel => {
  const history = useHistory();

  const queryParameter = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
    allowEmptyArrays: true,
  }) as TimeTravelSelection;
  const { singleSelection, compareSelection } = queryParameter;

  const updateCompareSelection = (compareSelection: string[]) => {
    const previousSearch = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      allowEmptyArrays: true,
    });
    const search = qs.stringify(
      {
        ...previousSearch,
        singleSelection: undefined,
        compareSelection,
      } satisfies TimeTravelSelection,
      { addQueryPrefix: true, allowEmptyArrays: true }
    );
    history.replace(`${history.location.pathname}${search}`);
  };

  const updateSingleSelection = (singleSelection: string) => {
    const previousSearch = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      allowEmptyArrays: true,
    });
    const search = qs.stringify(
      {
        ...previousSearch,
        singleSelection,
        compareSelection: undefined,
      } satisfies TimeTravelSelection,
      { addQueryPrefix: true, allowEmptyArrays: true }
    );
    history.replace(`${history.location.pathname}${search}`);
  };

  const onEntrySelected = (jobCode: string, selected: boolean) => {
    if (compareSelection) {
      const newSelection = selected
        ? [...compareSelection, jobCode]
        : compareSelection.filter(
            (selectedJobCode) => selectedJobCode !== jobCode
          );
      updateCompareSelection(newSelection);
    } else {
      const newSelection = selected ? jobCode : undefined;
      updateSingleSelection(newSelection);
    }
  };

  return {
    singleSelection,
    compareSelection,
    updateSingleSelection,
    updateCompareSelection,
    onEntrySelected,
  };
};

export const useTimeTravelHelpers = (
  selectedPageCategory?: AugurCategory
): TimeTravelHelpers => {
  const { singleSelection, compareSelection } = useTimeTravel();

  const isSelected = (code: string) => {
    // compare mode
    if (compareSelection) {
      return compareSelection.includes(code);
    }

    // explicit selection
    if (singleSelection) {
      return singleSelection === code;
    }

    return false;
  };

  const isHighlighted = (
    code: string,
    type: AugurCategory,
    implicitSelection: string
  ) => {
    if (type !== selectedPageCategory) return false;

    // compare mode
    if (compareSelection) {
      return compareSelection.includes(code);
    }

    // explicit selection
    if (singleSelection) {
      return singleSelection === code;
    }

    // implicit selection
    return implicitSelection === code;
  };

  return {
    isHighlighted,
    isSelected,
  };
};
