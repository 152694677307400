import { K8sResources } from './job';
import { Module } from './module';

export type ModuleVersion = {
  code: string;
  /** Version number of the module version */
  number: string;
  /** Image name of the module version */
  imageName: string;
  config: ModuleConfiguration;
  /** We need the executiontype for spark **/
  executionType: 'other' | 'spark';
  commitSha?: string;
  createdAt: Date;
  description: string | null;
  openApiSpec?: Record<string, unknown>;
};

export const SERVING_TYPES = {
  REST: 'rest',
} as const;
export type ServingType = (typeof SERVING_TYPES)[keyof typeof SERVING_TYPES];

export interface GeneralConfiguration extends AugurJobsConfiguration {
  supportsLearning: boolean;
  supportsEvaluation: boolean;
  supportsPrediction: boolean;
  supportsRealtimePrediction: boolean;
}

export type AugurJobsConfiguration = {
  learning?: { resources?: K8sResources };
  evaluation?: { resources?: K8sResources };
  prediction?: { resources?: K8sResources };
  realtimePrediction?: {
    resources?: K8sResources;
    availableServingTypes?: ServingType[];
  };
};

/**
 * File that contains the general settings, the module layout and module settings
 * This file can be missing entirely. Will be added automatically after entering edit mode in the dev augur
 * configuring settings and saving them. Can also be added via templates.
 */
export interface ModuleConfiguration {
  apiVersion: string; // Just a replication from the ModuleRepository for convenience. They are not versioned separately

  // ... Other fields
  generalConfiguration: GeneralConfiguration;

  // Augur Settings Configuration
  augurSettingsConfiguration: Record<string, unknown>[];

  // Augur Reports Configuration
  augurReportConfiguration: {
    learning: Record<string, unknown>[];
    evaluation: Record<string, unknown>[];
    prediction: Record<string, unknown>[];
  };
}

export type ModuleVersionWithModule = ModuleVersion & {
  module: Module;
};
