import pagingMessages from 'common/dist/messages/paging';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  offset: number;
  currentItems: number;
  totalItems?: number;
  itemLabel?: string;
}

const GenericPagingHeader: FC<Props> = ({
  offset,
  currentItems,
  totalItems,
  itemLabel,
}) => {
  const intl = useIntl();

  const label = itemLabel
    ? itemLabel
    : intl.formatMessage(pagingMessages.pagingHeaderLabelDefault);
  const from = offset + 1;
  const to = offset + currentItems;

  // Don't show a header if there are no entries
  if (to === 0) {
    return null;
  }

  if (from > to) {
    return (
      <span>
        <FormattedMessage {...pagingMessages.pagingHeaderInfoNoMoreItems} />
      </span>
    );
  }

  if (totalItems) {
    return (
      <span>
        <FormattedMessage
          {...pagingMessages.pagingHeaderInfoTotal}
          values={{
            from,
            to,
            total: totalItems,
            label,
          }}
        />
      </span>
    );
  }

  return (
    <span>
      <FormattedMessage
        {...pagingMessages.pagingHeaderInfoDefault}
        values={{ from, to, label }}
      />
    </span>
  );
};

export default GenericPagingHeader;
