export interface CodeCapsule {
  /** codeCapsuleCode **/
  code: string;
  name: string;
  repoCode?: string;
  createdAt: Date;
  versions: CodeCapsuleVersion[];
  habitatName?: string;
  habitatCode: string;
}

export interface CodeCapsuleVersion {
  number: string;
  imageName: string;
  availableParameters?: AvailableParameter[];
  proposedResources?: ProposedResources;
  availableNotebooks: string[];
  createdAt: Date;
  requestCredentialsFor: string[];
}

export interface AvailableParameter {
  id: string;
  key: string;
  value: string;
}

export interface ProposedResources {
  cpu?: string;
  memory?: string;
}

/**
 * Status for both the CodeCapsuleReport and the notebooks ran by that CodeCapsule
 */
export const NotebookStatus = {
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  FAILURE: 'failure',
} as const;

export type NotebookStatusType =
  typeof NotebookStatus[keyof typeof NotebookStatus];

export interface Report {
  jobCode?: string;
  createdAt?: Date;
  executedNotebooks?: string[];
  status: NotebookStatusType;
  repoCode?: string; // "success" / "failure"
  codeCapsuleVersion?: CodeCapsuleVersion;
}

export type EnrichedReport = Report & {
  notebooks: {
    [notebookName: string]: {
      status: NotebookStatusType | undefined;
    };
  };
};
