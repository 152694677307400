import React, { FC } from 'react';
import { TextOutputConfig, TextOutputReportData } from '../type';
import { ReportElementProps } from '../../../types/meta';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import styles from './styles.module.scss';
import Markdown from 'react-markdown'


export type Props = TextOutputReportData & TextOutputConfig;

const TextOutput: FC<Props> = (props) => {
  const { text } = props;

  return (
    <div className={styles.textOutput}>
      <Markdown>
        {text}
      </Markdown>
    </div>
  )
}

export const TextOutputSingle: FC<
  ReportElementProps<TextOutputReportData, TextOutputConfig>
> = buildSingleReportWrapper<TextOutputReportData, TextOutputConfig>(TextOutput);

export default TextOutput;