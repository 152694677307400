import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Content from './content/Content';
import RecycleBin from './recycleBin/RecycleBin';
import { workbenchRoutes } from '../../../workbench/common/workbenchRoutes';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';

export interface Props {
  setSidebarVisible: (isExpanded: boolean) => void;
}

function renderContent() {
  return (
    <Switch>
      <Route path={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}>
        <Content />
      </Route>
      <Route path={`${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}`}>
        <RecycleBin />
      </Route>
      <Route exact path={`${workbenchRoutes.basePath}`}>
        <Redirect
          to={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}
        />
      </Route>
    </Switch>
  );
}

const Browser: FC<Props> = ({ setSidebarVisible }) => {
  return (
    <div className={'browser'} data-testid='Browser'>
      <BrowserCollapseFlap isExpanded onClick={setSidebarVisible} />

      {renderContent()}
    </div>
  );
};

export default Browser;
