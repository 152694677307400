import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'error.headline',
    defaultMessage: 'An unexpected error occurred.',
  },
  description: {
    id: 'error.description',
    defaultMessage: 'Try reloading the page.',
  },
  fetchingS3BucketHeadline: {
    id: 'error.s3.fetching.bucket.headline',
    defaultMessage: `An error occurred while fetching the bucket { bucketName }.`,
  },
  fetchingCassandraTableHeadline: {
    id: 'error.cassandra.fetching.keyspace.headline',
    defaultMessage: `An error occurred while fetching the keyspace { keyspaceName }.`,
  },
});
