import { connect } from 'react-redux';

import Header from './Header';
import * as selector from '../../../../redux/selectors/menu.selector';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    isTopMenuShown: selector.isMenuShown(state),
    configNavbarLabel: state.config?.navbarLabel,
  };
}

export default connect(mapStateToProps)(Header);
