// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/classification-tree/v1/type.ts' --type 'ConfigClassificationTreeConfig'
export const schemaConfigClassificationTree = {
  $ref: '#/definitions/ConfigClassificationTreeConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ClassificationTreeConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigClassificationTreeConfig: {
      $ref: '#/definitions/Config%3CClassificationTreeConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/bar-chart/v1/type.ts' --type 'ClassificationTreeV1ReportData'
export const schemaReportTypeClassificationTree = {
  $ref: '#/definitions/ClassificationTreeV1ReportData',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    ClassificationTreeData: {
      additionalProperties: false,
      properties: {
        root: {
          $ref: '#/definitions/ClassificationTreeNode',
        },
      },
      required: ['root'],
      type: 'object',
    },
    ClassificationTreeNode: {
      additionalProperties: false,
      properties: {
        children: {
          items: {
            $ref: '#/definitions/ClassificationTreeNode',
          },
          type: 'array',
        },
        id: {
          type: 'string',
        },
        recordCount: {
          type: 'string',
        },
        score: {
          type: 'string',
        },
        scoreDistribution: {
          items: {
            $ref: '#/definitions/ScoreDistribution',
          },
          type: 'array',
        },
        simplePredicate: {
          $ref: '#/definitions/SimplePredicate',
        },
      },
      required: [
        'children',
        'id',
        'recordCount',
        'score',
        'scoreDistribution',
        'simplePredicate',
      ],
      type: 'object',
    },
    ClassificationTreeV1ReportData: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that consumes it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        data: {
          $ref: '#/definitions/ClassificationTreeData',
        },
      },
      required: ['data'],
      type: 'object',
    },
    ScoreDistribution: {
      additionalProperties: false,
      properties: {
        confidence: {
          type: 'number',
        },
        recordCount: {
          type: 'number',
        },
        value: {
          type: 'string',
        },
      },
      required: ['confidence', 'recordCount', 'value'],
      type: 'object',
    },
    SimplePredicate: {
      additionalProperties: false,
      properties: {
        field: {
          type: 'string',
        },
        operator: {
          type: 'string',
        },
        value: {
          type: 'number',
        },
      },
      required: ['field', 'operator', 'value'],
      type: 'object',
    },
  },
};
