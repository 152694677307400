import React, { Fragment, useEffect, useRef } from 'react';
import AceEditor from 'react-ace';
import ReactAce from 'react-ace';

import 'brace/mode/python';
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';
import CodeCellOutput from './CodeCellOutput';
import {
  AltaSigmaCodeCell,
  Session,
} from '../../../../store/workbench/state.types';

export type Props = {
  /** Cell content */
  cell: AltaSigmaCodeCell;
  /** Path of the notebook */
  path: string;
  /** IDs of the currently selected cells */
  selectedCells: string[];
  session: Session;
  changeSource: (data: {
    path: string;
    cellId: string;
    source: string;
  }) => void;
  /** Force focus "flag": ID of the cell that needs to get focused after mounting, because it was newly created or the type changed */
  forceFocus?: string;
  /** Clears the "forceFocus" flag */
  clearForceFocus: (path: string) => void;
  readOnly?: boolean;
};

function getAceMode(session: Session) {
  const kernelName = session?.kernel?.name;
  if (!kernelName) return undefined;
  if (kernelName.startsWith('python3') || kernelName === 'pysparkkernel')
    return 'python';
  return undefined;
}

/**
 * Component wraps the code editor and the outputs for this cell.
 */
function CodeCell({
  cell,
  path,
  changeSource,
  session,
  readOnly = false,
  forceFocus,
  clearForceFocus,
}: Props) {
  const editorRef = useRef<ReactAce>();

  useEffect(() => {
    if (
      forceFocus === cell.id &&
      editorRef &&
      !editorRef.current.editor.isFocused()
    ) {
      editorRef.current.editor.focus();
      clearForceFocus(path);
    }
  }, [cell.id, path, clearForceFocus, forceFocus]);

  const aceMode = getAceMode(session);

  return (
    <Fragment>
      <div className={'editor-parent-container'}>
        <div
          className={`editor-container${
            cell.outputs && cell.outputs.length > 0 ? ' has-output' : ''
          }`}
        >
          <AceEditor
            ref={editorRef}
            width={'100%'}
            className={'code-cell-editor'}
            mode={aceMode}
            theme={'tomorrow'}
            onChange={(source, event) => {
              changeSource({
                path,
                cellId: cell.id,
                source,
              });
            }}
            showGutter
            highlightActiveLine
            value={
              Array.isArray(cell.source) ? cell.source.join('') : cell.source
            }
            setOptions={{
              maxLines: Infinity,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
              useSoftTabs: false,
            }}
            editorProps={{ $blockScrolling: Infinity }}
            readOnly={readOnly}
          />
        </div>
      </div>
      {cell.outputs && cell.outputs.length > 0 && (
        <CodeCellOutput outputs={cell.outputs} path={path} cellId={cell.id} />
      )}
    </Fragment>
  );
}

export default CodeCell;
