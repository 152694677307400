import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useRouteMatch, withRouter } from 'react-router-dom';

import MergeRequestDetailsHeader from './MergeRequestDetailsHeader.container';
import MergeRequestOverview from './tab-overview/MergeRequestOverview.container';
import MergeRequestResolve from './tab-resolve/MergeRequestResolve.container';
import BackTo from '../../../../atoms/back-to/BackTo';
import TabLine from '../../../../molecules/tab-line/TabLine';
import { MergeRequest } from '../../../giteaTypes';
import {
  moduleMergeRequestLink,
  repositoryMergeRequestLink,
} from '../../../routes';

export type Props = {
  /** Is the MergeRequest information from Gitea loading? */
  mrLoading: boolean;
  /** MergeRequest information from Gitea */
  mrData: MergeRequest;

  /** Load the details for the Merge Request (from Gitea) */
  loadMergeRequestDetails: ToBeRefined;
};

type RouteParams = {
  id?: string;
  group?: string;
  repositoryName?: string;
  moduleCode?: string;
};

// @ts-ignore
const MergeRequestDetails: FC<Props & RouteComponentProps> = (props) => {
  const { mrLoading, mrData, loadMergeRequestDetails } = props;
  const {
    params: { id, group, repositoryName, moduleCode },
  } = useRouteMatch<RouteParams>();
  let linkTo = repositoryMergeRequestLink(group, repositoryName);
  if (moduleCode) linkTo = moduleMergeRequestLink(moduleCode);
  useEffect(() => {
    //@ts-ignore
    loadMergeRequestDetails(group, repositoryName, id);
  }, [group, repositoryName, id, loadMergeRequestDetails]);

  // Show the conflicts tab if the data was loaded - and the merge request is indeed not mergeable
  const showConflictsTab = !mrLoading && mrData && !mrData.mergeable;

  const tabNames = ['Overview'];
  const tabContents = [
    <MergeRequestOverview
      group={group}
      repositoryName={repositoryName}
      id={id as unknown as number}
      key='overview'
    />,
  ];

  if (showConflictsTab) {
    tabNames.push('Merge Conflicts');

    tabContents.push(
      <MergeRequestResolve
        //@ts-ignore
        group={group}
        repositoryName={repositoryName}
        id={id}
        key='conflicts'
      />
    );
  }

  return (
    <div className={'CollaborationSpace--content'}>
      <div className={'repository'}>
        <BackTo label={'Back to Merge Requests'} linkTo={linkTo} />

        <MergeRequestDetailsHeader
          id={id as unknown as number}
          group={group}
          repositoryName={repositoryName}
        />

        <TabLine
          tabNames={tabNames}
          tabContents={tabContents}
          style={{
            margin: '0 -10px',
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(MergeRequestDetails);
