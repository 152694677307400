import { Buffer } from 'buffer';
import { RepoType as RepoTypeType } from 'common/dist/types/repository';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useFile } from '../../../../core/api/workbench/collab';
import RepositoryContent from '../../../molecules/repository-content/RepositoryContent';
import RepositoryFilePreview from '../../../molecules/repository-file-preview/RepositoryFilePreview';
import RepositoryHeader from '../../../molecules/repository-header/RepositoryHeader';
import styles from '../styles.module.scss';

type RepositoryProps = {
  activeBranch: string;
  match: {
    params: {
      group?: string;
      repositoryName?: string;
    };
  };
  loading?: boolean;
  branches?: {
    name: string;
  }[];
  loaded?: boolean;
  error?: string;
  data?: RepositoryData;
  currentUserId: string;

  fetchRepoDetails(...args: unknown[]): unknown;

  fetchBranches(...args: unknown[]): unknown;

  switchBranch(...args: unknown[]): unknown;
};

export type RepositoryData = {
  name: string;
  repoDescription?: string;
  repoType: RepoTypeType;
  repoFullName: string;
};

const RepositoryOverview: FC<RepositoryProps & RouteComponentProps> = ({
  activeBranch,
  fetchRepoDetails,
  fetchBranches,
  switchBranch,
  match,
  loading,
  branches,
  error,
  data,
  currentUserId,
}) => {
  const { group, repositoryName } = match.params;
  const [selectedFile, setSelectedFile] = useState<string>();
  useEffect(() => {
    fetchRepoDetails(group, repositoryName);
    fetchBranches(group, repositoryName);
    // Only runs on mount and cleanup
  }, [fetchRepoDetails, fetchBranches, group, repositoryName]);

  const { name, repoDescription, repoType, repoFullName } = data || {};
  const parts = (repoFullName || '').split('/');
  const nameShown = name !== undefined ? name : parts[1];
  const { data: file, isInitialLoading: fileLoading } = useFile(
    group,
    parts[1],
    selectedFile
  );
  let content: string;
  if (file && file.content) {
    const encoding: BufferEncoding = file?.encoding as BufferEncoding;
    content = Buffer.from(file.content, encoding).toString('utf8');
  }

  return (
    <div className={styles.pageContainer}>
      <RepositoryHeader
        repositoryType={repoType}
        loading={loading}
        error={error}
        data={data}
        branches={branches}
        activeBranch={branches?.find(
          (b) => b.name === (activeBranch || 'master')
        )}
        switchBranch={switchBranch}
      />

      <RepositoryContent
        setSelectedFile={setSelectedFile}
        repoFullName={repoFullName}
        activeBranch={activeBranch || 'master'}
      />
      <RepositoryFilePreview
        selectedFile={selectedFile}
        fileLoading={fileLoading}
        content={content}
        file={file}
      />
    </div>
  );
};

export default withRouter(RepositoryOverview);
