import classNames from 'classnames';
import React, { Component, ComponentType } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import {
  locationWithWhitelistQuery,
  toLinkWhitelistQuery,
} from '../../../utils';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';
import LoadingPlaceholder from '../../atoms/loading-placeholder/LoadingPlaceholder';

/** */
export type LinkType = {
  type: 'link';
  /** Color of the link - default: black */
  color?: 'black' | ' blue';
  /** Id of the entry. Used for the html id attribute and to check if the tab is selected */
  id: string;
  /** Where should the link point to? */
  to: string;
  /** Intl ID of the display name */
  nameIntlId: string;
  /** Default display name if the intlId can't be resolved */
  nameDefault: string;
  /** Icon component */
  icon: ComponentType<{ size?: string }>;
  /** If selectedTab is undefined, this category is activated per default */
  activePerDefault?: boolean;
  disabled?: boolean;
};

/** Type Specs for the horizontal line entry for the SideMenu */
export type HrType = {
  type: 'hr';
};

/** Type Specs for a "category" headline for the SideMenu */
export type HeadlineType = {
  type: 'headline';
  headline: MessageDescriptor;
};

export type LoadingType = {
  type: 'loading';
};

export type MenuEntryType = LinkType | HrType | HeadlineType | LoadingType;

type Props = {
  /** List of entries for the menu */
  menuEntries: MenuEntryType[];
  /** Which of the tabs (id) is selected? */
  selectedTab: string;
  /** Handle the selection of an entry
   * @deprecated Just use the menuEntry .to
   * */
  handleSelect?: (selectedTab: string, query?: string) => void;
  collapsable?: boolean;
  /** Not optional if collapsable */
  isExpanded?: boolean;
  /** Not optional if collapsable */
  setExpanded?: (isExpanded: boolean) => void;
  preserveWhitelistQueryOnLink?: string[];
};

class SideMenu extends Component<Props & RouteComponentProps> {
  renderLink(
    {
      color = 'black',
      activePerDefault,
      id,
      nameIntlId,
      nameDefault,
      to,
      disabled,
      icon: Icon,
    }: LinkType,
    index: number
  ) {
    const {
      selectedTab,
      handleSelect,
      location,
      preserveWhitelistQueryOnLink,
    } = this.props;

    const Inner = (
      <>
        <div className={'SideMenu-tab-icon'} data-testid={`tab-${id}`}>
          <Icon size={'16px'} />
        </div>
        <FormattedMessage id={nameIntlId} defaultMessage={nameDefault} />
      </>
    );

    return (
      <div
        key={index}
        className={classNames(
          'SideMenu-tab-link',
          `SideMenu-tab-link--color-${color}`,
          {
            'tab-link-active':
              selectedTab === id || (activePerDefault && !selectedTab),
          }
        )}
      >
        {disabled ? (
          <div
            className={classNames(
              'SideMenu-tab-link--inner',
              'SideMenu-tab-link--disabled'
            )}
          >
            {Inner}
          </div>
        ) : (
          <Link
            to={
              preserveWhitelistQueryOnLink?.length > 0
                ? toLinkWhitelistQuery(
                    to,
                    location,
                    preserveWhitelistQueryOnLink
                  )
                : to
            }
            id={`tab-${id}`}
            className={'SideMenu-tab-link--inner'}
            onClick={() =>
              !!handleSelect &&
              handleSelect(
                to,
                preserveWhitelistQueryOnLink?.length > 0
                  ? locationWithWhitelistQuery(
                      location,
                      preserveWhitelistQueryOnLink
                    ).search
                  : undefined
              )
            }
          >
            {Inner}
          </Link>
        )}
      </div>
    );
  }

  renderHr(index: number) {
    return <div key={index} className={'SideMenu-hr'} />;
  }

  renderHeadline(entry: HeadlineType, index: number) {
    return (
      <div key={index} className={'SideMenu-headline'}>
        <FormattedMessage
          id={entry.headline.id || 'no-id'}
          defaultMessage={entry.headline.defaultMessage}
        />
      </div>
    );
  }

  renderLoading(index: number) {
    return (
      <div key={index} className={'SideMenu-loading'}>
        <LoadingPlaceholder width={'100%'}>
          <span className={'SideMenu-loading-inner'}>Loading Placeholder</span>
        </LoadingPlaceholder>
      </div>
    );
  }

  renderEntry(entry: MenuEntryType, index: number) {
    switch (entry.type) {
      case 'hr':
        return this.renderHr(index);
      case 'headline':
        return this.renderHeadline(entry, index);
      case 'link':
        return this.renderLink(entry, index);
      case 'loading':
        return this.renderLoading(index);
      default:
        return null;
    }
  }

  render() {
    const { menuEntries, collapsable, isExpanded, setExpanded } = this.props;
    return (
      <div className='SideMenu'>
        {menuEntries.map((entry, index: number) =>
          this.renderEntry(entry, index)
        )}
        {collapsable && (
          <BrowserCollapseFlap isExpanded={isExpanded} onClick={setExpanded} />
        )}
      </div>
    );
  }
}

export default withRouter(SideMenu);
