import { JupyterNotebookFormat } from './jupyterNotebookFormat';
import { NotebookStatus, NotebookStatusType } from '../../types/codeCapsule';

export interface PapermillNotebook extends JupyterNotebookFormat {
  metadata: {
    papermill: {
      duration: string;
      end_time: string;
      exception: string;
      start_time: string;
    };
  };
}

/**
 * Interpret a notebook after/while being executed by papermill
 */
export function getStatusFromPapermillNotebook(
  notebookOutput: PapermillNotebook
): NotebookStatusType | undefined {
  if (notebookOutput.metadata.papermill.exception) {
    return NotebookStatus.FAILURE;
  }
  if (
    notebookOutput.metadata.papermill.start_time &&
    notebookOutput.metadata.papermill.end_time
  ) {
    return NotebookStatus.SUCCESS;
  }
  if (notebookOutput.metadata.papermill.start_time) {
    return NotebookStatus.IN_PROGRESS;
  }
  return undefined;
}
