export type KpiOptionType = {
  /** ID of the KPI */
  id: string;
  /** Intl message ID of the KPI */
  label: string;
  /** Min value for the KPI */
  min: number;
  /** Max value for the KPI */
  max: number;
  /** Comparator for the retraining: Retrain if lower or greater than the threshold of the Augur? */
  comparator: ComparatorType;
  /** TODO What is this used for? */
  group: 0 | 1 | 2;
  /** Health buckets (entries: [threshold, health], health = 0 worst health, health = 4 best health */
  health: [number, HealthValue][];
  /** Takes the raw kpiValue and formats it */
  format: (kpiValue: number) => string;
};

export type HealthValue = 0 | 1 | 2 | 3 | 4;

export type ComparatorType = 'lt' | 'gt';

const safeToFixed = (num: number, fractionDigits: number): string => {
  if (!num) return '';
  return num.toFixed(fractionDigits);
};

export const negateComparator = (
  comparator: ComparatorType
): ComparatorType => {
  switch (comparator) {
    case 'lt':
      return 'gt';
    case 'gt':
      return 'lt';
  }
};

export const getComparatorMessageDescriptor = (
  comparator: ComparatorType
): { id: string; defaultMessage: string } => {
  switch (comparator) {
    case 'lt':
      return {
        id: 'kpiSelect.less_than',
        defaultMessage: 'less than',
      };
    case 'gt':
      return {
        id: 'kpiSelect.greater_than',
        defaultMessage: 'greater than',
      };
  }
};

const safeToPercent = (num: number): string => {
  if (!num) return '';
  return `${(num * 100).toFixed(2)}%`;
};

export const kpiOptions: KpiOptionType[] = [
  {
    id: 'averageSquaredError',
    label: 'kpi.averageSquaredError',
    min: 0,
    max: Infinity,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'areaUnderTheCurve',
    label: 'kpi.areaUnderTheCurve',
    min: 0.5,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'truePositiveRate',
    label: 'kpi.truePositiveRate',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'trueNegativeRate',
    label: 'kpi.trueNegativeRate',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'falsePositiveRate',
    label: 'kpi.falsePositiveRate',
    min: 0,
    max: 1,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'falseNegativeRate',
    label: 'kpi.falseNegativeRate',
    min: 0,
    max: 1,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'sensitivity',
    label: 'kpi.sensitivity',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'specificity',
    label: 'kpi.specificity',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'accuracy',
    label: 'kpi.accuracy',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'precision',
    label: 'kpi.precision',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'negativePredictiveValue',
    label: 'kpi.negativePredictiveValue',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'misclassificationRate',
    label: 'kpi.misclassificationRate',
    min: 0,
    max: 1,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToPercent(kpiValue),
  },
  {
    id: 'cumulativeCapturedResponseTop10',
    label: 'kpi.cumulativeCapturedResponseTop10',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'cumulativeCapturedResponseTop25',
    label: 'kpi.cumulativeCapturedResponseTop25',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'cumulativeLiftTop10', // todo: check min/max/health
    label: 'kpi.cumulativeLiftTop10',
    min: 1,
    max: 100,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'cumulativeLiftTop25', // todo: check min/max/health
    label: 'kpi.cumulativeLiftTop25',
    min: 1,
    max: 100,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'cumulativeResponseTop10',
    label: 'kpi.cumulativeResponseTop10',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'cumulativeResponseTop25',
    label: 'kpi.cumulativeResponseTop25',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 1,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'fScore',
    label: 'kpi.fScore',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'informedness',
    label: 'kpi.informedness',
    min: -1,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'markedness',
    label: 'kpi.markedness',
    min: -1,
    max: 1,
    comparator: 'gt',
    group: 0,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'rootAverageSquaredError',
    label: 'kpi.rootAverageSquaredError',
    min: 0,
    max: Infinity,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'maximumAbsoluteError',
    label: 'kpi.maximumAbsoluteError',
    min: 0,
    max: Infinity,
    comparator: 'lt',
    group: 2,
    health: [
      [0.1, 4],
      [0.2, 3],
      [0.3, 2],
      [0.4, 1],
      [1, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
  {
    id: 'giniCoefficient',
    label: 'kpi.giniCoefficient',
    min: 0,
    max: 1,
    comparator: 'gt',
    group: 2,
    health: [
      [0.9, 4],
      [0.8, 3],
      [0.7, 2],
      [0.6, 1],
      [0, 0],
    ],
    format: (kpiValue) => safeToFixed(kpiValue, 3),
  },
];

export const kpiOptionsGrouped: { [kpi: string]: KpiOptionType } =
  kpiOptions.reduce((obj, option) => {
    obj[option.id] = option; // eslint-disable-line
    return obj;
  }, {} as { [kpi: string]: KpiOptionType });
