import classNames from 'classnames';
import React from 'react';
import { OptionProps } from 'react-select';

import styles from '../s3BucketSelect/v1/styles.module.scss';

export type ElementOptionType = {
  title: string;
  type: string;
  uuid: string;
};

type ElementOptionComponentProps<T extends boolean> = OptionProps<
  ElementOptionType,
  T
>;

export const ElementOptionComponent = <T extends boolean>(
  props: ElementOptionComponentProps<T>
) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.optionComponent, {
        [styles.isSelected]: props.isSelected,
      })}
      ref={innerRef}
      data-testid={props.label}
    >
      <span className={styles.title}>{props.data.title}</span>
      <span className={styles.subtitle}>Type: {props.data.type}</span>
      <span className={styles.subtitle}>UUID: {props.data.uuid}</span>
    </div>
  );
};
