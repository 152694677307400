import { createAction } from 'redux-act';
import { queryClient } from '../../components/index/react_query';
import { takeEvery } from '@redux-saga/core/effects';

// ONLY USE THIS FILE IN REDUX SAGA FILES, DON'T USE IT IN REACT QUERY HOOKS, USE useQueryClient INSTEAD
// This action can be used to invalidate React Query queries from Redux Saga sagas

export const invalidateQueries = createAction('invalidateQueries', (key) => ({
  key,
}));

export function* invalidateQueriesSaga({ payload: { key } }) {
  yield queryClient.invalidateQueries(key);
}

export function* watchInvalidateQueries() {
  yield takeEvery(invalidateQueries.getType(), invalidateQueriesSaga);
}
