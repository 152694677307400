import React, { FC, Fragment } from 'react';
import Bar from './Bar';
import { FiCheckCircle, FiSlash } from 'react-icons/fi';
import { Feature } from '../type';
import { MdCategory } from 'react-icons/md';
import { TiSortNumerically } from 'react-icons/ti';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type Props = {
  feature: Feature;
};

const InfluencerBar: FC<Props> = (props: Props) => {
  const { feature } = props;

  const renderActive = () => {
    return (
      <Fragment>
        <div
          className={classNames(styles.discardedOrUsedIcon, styles.iconUsed)}
        >
          <FiCheckCircle size={16} />
        </div>
        <div
          className={classNames(styles.featureParent)}
          title={'Feature is used for the model'}
        >
          <span className={styles.featureText}>{feature.feature}</span>
        </div>
      </Fragment>
    );
  };

  const renderDiscarded = () => {
    return (
      <Fragment>
        <div
          className={classNames(
            styles.discardedOrUsedIcon,
            styles.iconDiscarded
          )}
        >
          <FiSlash size={16} />
        </div>

        <div
          className={classNames(styles.featureParent)}
          title={
            feature.discarded &&
            'Feature is auto-rejected:\n' + feature.discarded.join(', ')
          }
        >
          <span className={classNames(styles.featureText, styles.discarded)}>
            {feature.feature}
          </span>
        </div>
      </Fragment>
    );
  };

  return (
    <div className={styles.featureRow}>
      <span className={classNames(styles.featureCell, styles.importance)}>
        <Bar importance={feature.importance} />
      </span>

      <div className={classNames(styles.featureCell, styles.type)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          title={
            feature.featureType === 'numerical' ? 'Numerical' : 'Categorical'
          }
        >
          {feature.featureType === 'numerical' ? (
            <TiSortNumerically size={16} />
          ) : (
            <MdCategory size={16} />
          )}
        </div>
      </div>

      <div className={classNames(styles.featureCell, styles.feature)}>
        {!feature.discarded ? renderActive() : renderDiscarded()}
      </div>
    </div>
  );
};

export default InfluencerBar;
