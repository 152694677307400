import { defineMessages } from 'react-intl';

export default defineMessages({
  overviewFilterUseCase: {
    id: 'modules.overview.filter.use_case',
    defaultMessage: 'Use Case',
  },
  overviewFilterCreator: {
    id: 'modules.overview.filter.creator',
    defaultMessage: 'Creator',
  },
  modulesCardVersion: {
    id: 'modules.card.version',
    defaultMessage: 'Version',
  },
  modulesCardVersions: {
    id: 'modules.card.versions',
    defaultMessage: 'Versions',
  },
  moduleWizardHeadline: {
    id: 'module.wizard.headline',
    defaultMessage: 'Add new Module',
  },
  moduleWizardHeadlineEdit: {
    id: 'module.wizard.headline.edit',
    defaultMessage: 'Edit Module',
  },
  moduleBuildWizardHeadline: {
    id: 'module.build.wizard.headline',
    defaultMessage: 'Build Module',
  },
  moduleWizardModuleNameStepTitle: {
    id: 'module.wizard.module.name.step.title',
    defaultMessage: 'Enter Name',
  },
  moduleWizardModuleNameStepDescription: {
    id: 'module.wizard.module.name.step.description',
    defaultMessage: 'Enter a name for the module',
  },
  moduleWizardModuleDescriptionStepTitle: {
    id: 'module.wizard.module.description.step.title',
    defaultMessage: 'Enter Description',
  },
  moduleWizardModuleDescriptionStepDescription: {
    id: 'module.wizard.module.description.step.description',
    defaultMessage: 'Enter a short description for the module',
  },
  moduleWizardModuleUploadIconStepTitle: {
    id: 'module.wizard.module.upload.icon.step.title',
    defaultMessage: 'Upload Icon (Optional)',
  },
  moduleWizardModuleUploadIconStepDescription: {
    id: 'module.wizard.module.upload.icon.step.description',
    defaultMessage: 'Upload an Icon for the module',
  },
  moduleWizardModuleTemplateStepTitle: {
    id: 'module.wizard.module.template.step.title',
    defaultMessage: 'Template (Optional)',
  },
  moduleWizardModuleTemplateStepDescription: {
    id: 'module.wizard.module.template.step.description',
    defaultMessage: 'Select a template to start a bootstrap Module with',
  },
  moduleBuildTagTitle: {
    id: 'module.build.tag.title',
    defaultMessage: 'Set Tag',
  },
  moduleBuildTagDescription: {
    id: 'module.build.tag.description',
    defaultMessage: 'Set the Tag for the Module',
  },
  moduleConfigurationPageSupportsLearning: {
    id: 'module.configuration.page.supports.learning',
    defaultMessage: 'Supports Learning',
  },
  moduleConfigurationPageSupportsEvaluation: {
    id: 'module.configuration.page.supports.evaluation',
    defaultMessage: 'Supports Evaluation',
  },
  moduleConfigurationPageSupportsPrediction: {
    id: 'module.configuration.page.supports.prediction',
    defaultMessage: 'Supports Prediction',
  },
  moduleConfigurationPageSupportsRealtimePrediction: {
    id: 'module.configuration.page.supports.realtime.prediction',
    defaultMessage: 'Supports Realtime Prediction',
  },
});
