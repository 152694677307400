// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/rejected-features-cassandra/v1/type.ts' --type 'ConfigRejectedFeaturesCassandraConfig'
export const schemaConfigRejectedFeaturesCassandra = {
    "$ref": "#/definitions/ConfigRejectedFeaturesCassandraConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "CassandraKeyspaceSelectAugurSettings": {
            "additionalProperties": false,
            "properties": {
                "dataSourceCode": {
                    "type": "string"
                },
                "keyspaceName": {
                    "type": "string"
                }
            },
            "required": [
                "dataSourceCode",
                "keyspaceName"
            ],
            "type": "object"
        },
        "CassandraTableSelectAugurSettings": {
            "type": "string"
        },
        "Config<RejectedFeaturesCassandraConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "properties": {
                "cassandraKeyspace": {
                    "$ref": "#/definitions/ConfigEntry%3CCassandraKeyspaceSelectAugurSettings%3E"
                },
                "cassandraTable": {
                    "$ref": "#/definitions/ConfigEntry%3CCassandraTableSelectAugurSettings%3E"
                }
            },
            "required": [
                "cassandraKeyspace",
                "cassandraTable"
            ],
            "type": "object"
        },
        "ConfigEntry<CassandraKeyspaceSelectAugurSettings>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "$ref": "#/definitions/CassandraKeyspaceSelectAugurSettings"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigEntry<CassandraTableSelectAugurSettings>": {
            "anyOf": [
                {
                    "additionalProperties": false,
                    "properties": {
                        "source": {
                            "const": "hard-coded",
                            "type": "string"
                        },
                        "value": {
                            "$ref": "#/definitions/CassandraTableSelectAugurSettings"
                        }
                    },
                    "required": [
                        "source",
                        "value"
                    ],
                    "type": "object"
                },
                {
                    "additionalProperties": false,
                    "properties": {
                        "elementUuid": {
                            "type": "string"
                        },
                        "source": {
                            "const": "input-element",
                            "type": "string"
                        }
                    },
                    "required": [
                        "source",
                        "elementUuid"
                    ],
                    "type": "object"
                }
            ],
            "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element."
        },
        "ConfigRejectedFeaturesCassandraConfig": {
            "$ref": "#/definitions/Config%3CRejectedFeaturesCassandraConfig%3E"
        }
    }
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/rejected-features-cassandra/v1/type.ts' --type 'PartialRejectedFeaturesCassandraAugurSettings'
export const schemaAugurSettingsDefaultRejectedFeaturesCassandra = {
    "$ref": "#/definitions/PartialRejectedFeaturesCassandraAugurSettings",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "PartialRejectedFeaturesCassandraAugurSettings": {
            "additionalProperties": false,
            "properties": {
                "enforced": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "rejected": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                }
            },
            "type": "object"
        }
    }
};