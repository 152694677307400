import {
  CASSANDRA_KEYSPACE_SCOPES,
  RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX,
} from 'common/dist/constants/keycloak';
import commonMessages from 'common/dist/messages/common';
import dataManagementMessages from 'common/dist/messages/dataManagement';
import { DSType } from 'common/dist/types/dataManagement/dataSource';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchDataSources } from '../../../../../redux/modules/data.dataSources.module';
import { DataSources } from '../../../../../store/dataManagement/state.types';
import { RootState, useAppDispatch } from '../../../../../store/store';
import ResourcePermissions from '../../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import { bucketOrKeyspaceRoute, detailsRoute } from '../DataSourcePermissions';

const KeyspacePermissions: FC = () => {
  const { dataSourceCode, keyspace } = useParams<{
    dataSourceCode: string;
    keyspace: string;
  }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const dataSources = useSelector<RootState, DataSources>(
    (state) => state.data.dataSources
  );

  const dataSource = dataSources.data.find((ds) => ds.code === dataSourceCode);
  useEffect(() => {
    // If there are no dataSources (or they aren't an array) fetch them, but avoid it if they are already fetched.
    if (!(dataSources.data.length > 0)) {
      dispatch(fetchDataSources());
    }
  }, [dispatch, dataSources.data]);

  const detailsRows = [
    {
      key: dataManagementMessages.msgS3PermissionsRowDataSourceName,
      value: dataSource?.name,
    },
    {
      key: dataManagementMessages.msgCassandraPermissionsRowKeyspaceName,
      value: keyspace,
    },
  ];

  return (
    <ResourcePermissions
      baseUrl={bucketOrKeyspaceRoute(
        DSType.Cassandra,
        dataSourceCode,
        keyspace
      )}
      showBackButton
      backButtonLabel={intl.formatMessage(commonMessages.backTo, {
        origin: 'Keyspaces',
      })}
      backToUrl={detailsRoute(DSType.Cassandra, dataSourceCode)}
      resourceType={`${RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX}:${dataSourceCode}`}
      resourceName={`${RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX}:${dataSourceCode}:${keyspace}`}
      details={{
        headline: dataManagementMessages.msgCassandraPermissionsHeadline,
        rows: detailsRows,
      }}
      scopes={CASSANDRA_KEYSPACE_SCOPES}
      messagesGroupPermissions={{
        subTitle: dataManagementMessages.msgCassandraPermissionsGroupSubTitle,
        description:
          dataManagementMessages.msgCassandraPermissionsGroupDescription,
      }}
      messagesUserPermissions={{
        subTitle: dataManagementMessages.msgCassandraPermissionsUserSubTitle,
      }}
    />
  );
};

export default KeyspacePermissions;
