import classNames from 'classnames';
import React from 'react';
import { OptionProps } from 'react-select';

import styles from './styles.module.scss';

export type DescriptionOptionType = {
  label: string;
  value: string;
  description?: string;
  isDisabled?: boolean;
};
export type OptionComponentProps<T extends boolean> = OptionProps<
  DescriptionOptionType,
  T
>;
function DescriptionOption<T extends boolean>(props: OptionComponentProps<T>) {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(
        styles.descriptionOption,
        { [styles.isSelected]: props.isSelected },
        { [styles.isDisabled]: props.isDisabled }
      )}
      ref={innerRef}
    >
      <span className={styles.label}>{props.data.label}</span>
      {props.data.description && (
        <span className={styles.description}>{props.data.description}</span>
      )}
    </div>
  );
}

export default DescriptionOption;
