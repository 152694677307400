import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationTargetFunctionAugurSettings,
  CampaignOptimizationTargetFunctionValidationError,
} from './type';
import {
  validateDescription,
  validateName,
} from '../../campaign-optimization-channels/v1/validate';
import { numberRegex } from '../../common/type';

const validateSumOver = (
  sumOver: TargetFunctionSummandGroup['sumOver'] | undefined
): string | undefined => {
  // The sumOver field is required
  if (!sumOver) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoSumOver
      .defaultMessage;
  }
};

const validateSumOf = (
  sumOf: TargetFunctionSummandGroup['sumOf'] | undefined
): string | undefined => {
  // The sumOf field is required
  if (!sumOf) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoSumOf
      .defaultMessage;
  }
};

export const validateFactor = (factor?: string): string | undefined => {
  // The factor field is required
  if (!factor) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoFactor
      .defaultMessage;
  }

  // Valid characters
  if (!numberRegex.test(factor)) {
    return moduleOptimizationMessages
      .msgNewAugurStepTargetFunctionErrInvalidFactor.defaultMessage;
  }
};

// ---

export const validateTargetFunction = (
  value: CampaignOptimizationTargetFunctionAugurSettings
):
  | DeepPartial<CampaignOptimizationTargetFunctionValidationError>
  | undefined => {
  const error: DeepPartial<CampaignOptimizationTargetFunctionValidationError> =
    {};

  // Is maximizeOrMinimize set?
  if (value && !value.minimizeOrMaximize) {
    error.minimizeOrMaximize =
      moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoMinMax.defaultMessage;
  }
  // Is there at least one summand group added?
  if (!value || (value.summandGroups || []).length === 0) {
    error.global =
      moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoContactPolicies.defaultMessage;
  } else {
    value?.summandGroups?.forEach((summandGroup) => {
      const summandGroupError = validateSingleSummandGroup(
        summandGroup,
        value?.summandGroups
      );
      if (!_.isEmpty(summandGroupError)) {
        error.rows = {
          ...error.rows,
          [summandGroup.id]: summandGroupError,
        };
      }
    });
  }
  return cleanErrorObject(error);
};

export const validateSingleSummandGroup = (
  summandGroup: Partial<TargetFunctionSummandGroup>,
  allSummandGroups?: Partial<TargetFunctionSummandGroup>[]
): {
  name?: string;
  description?: string;
  sumOf?: string;
  sumOver?: string;
  factor?: string;
} => {
  // Validate name
  const nameError = validateName(
    summandGroup.name,
    (allSummandGroups || [])
      .filter((x) => x.id !== summandGroup.id)
      .map((ch) => ch.name)
      .filter((name): name is string => name !== undefined)
  );

  // Validate description
  const descriptionError = validateDescription(summandGroup.description);

  // Validate contactPolicyType
  const sumOfError = validateSumOf(summandGroup.sumOf);

  // Validate operator
  const sumOverError = validateSumOver(summandGroup.sumOver);

  // Validate factor
  const factorError = validateFactor(summandGroup.factor);

  return {
    name: nameError,
    description: descriptionError,
    sumOf: sumOfError,
    sumOver: sumOverError,
    factor: factorError,
  };
};
