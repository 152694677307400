import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector, isPristine, isValid } from 'redux-form';

import { gitCommitForm } from './gitCommit.form';
import GitCommitModal from './GitCommitModal';
import { gitAddAllAndCommit } from '../../../../../../redux/workbench/modules/container-interactions.module';
import { hideGitCommitModal } from '../../../../../../redux/workbench/modules/repository.module';

export type GitCommitModalProps = {
  isOpen?: boolean;
  hideGitCommitModal: () => void;
  valid?: boolean;
  pristine?: boolean;
  gitAddAllAndCommit(
    repositoryPath: string,
    commitMessage: string,
    activeBranch: string
  ): void;
  commitMessage?: string;
  repositoryPath: string;
  activeBranch: string;
};

export function mapStateToProps(state, { location }) {
  return {
    isOpen: state.workbench.showRepositoryInfo.showGitCommitModal,
    valid: isValid(gitCommitForm.form)(state),
    pristine: isPristine(gitCommitForm.form)(state),
    commitMessage: formValueSelector(gitCommitForm.form)(
      state,
      gitCommitForm.fieldCommitMessage
    ),
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
  };
}

export const mapDispatchToProps = {
  hideGitCommitModal,
  gitAddAllAndCommit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default withRouter(connector(GitCommitModal));
