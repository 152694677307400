import React, { Component, ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

export type Props = {
  /** CSS class name of the image to use. The image must be registered at client/scss/partials/images.scss */
  classNameImage: string;

  /** @deprecated use headline instead
   *  Intl ID for the headline text */
  headlineId?: string;
  /** @deprecated use headline instead
   *  Default message for the headline */
  headlineDefault?: string;
  /** Either the separate headline* must be set or as a complete object here TODO make required once deprecated fields are removed */
  headline?: React.ComponentProps<typeof FormattedMessage>;

  /** @deprecated use description instead
   *   Intl ID for the description text */
  descriptionId?: string;
  /** @deprecated use description instead
   *   Default message for the description */
  descriptionDefault?: string;
  /** Either the separate description* must be set or as a complete object here */
  description?: React.ComponentProps<typeof FormattedMessage>;

  /** Optional component of actions (for example "Create Habitat now!" button) */
  Actions?: ComponentType;
};

class IndicatorEmpty extends Component<Props> {
  render() {
    const {
      classNameImage,
      headlineId,
      headlineDefault,
      headline,
      descriptionId,
      descriptionDefault,
      description,
      Actions,
    } = this.props;

    if (!headlineDefault && !headline)
      throw new Error(
        'headline must be set either separately or passed as FormattedMessage props'
      );

    return (
      <div className={'IndicatorEmpty'}>
        <div className={'IndicatorEmpty--picture ' + classNameImage}>
          &nbsp;
        </div>
        {headline ? (
          <FormattedMessage {...headline}>
            {(txt) => <span className={'IndicatorEmpty--headline'}>{txt}</span>}
          </FormattedMessage>
        ) : (
          <FormattedMessage
            id={headlineId || 'no-id'}
            defaultMessage={headlineDefault}
          >
            {(txt) => <span className={'IndicatorEmpty--headline'}>{txt}</span>}
          </FormattedMessage>
        )}
        {description ? (
          <FormattedMessage {...description}>
            {(txt) => (
              <span className={'IndicatorEmpty--description'}>{txt}</span>
            )}
          </FormattedMessage>
        ) : (
          (descriptionId || descriptionDefault) && (
            <FormattedMessage
              id={descriptionId || 'no-id'}
              defaultMessage={descriptionDefault}
            >
              {(txt) => (
                <span className={'IndicatorEmpty--description'}>{txt}</span>
              )}
            </FormattedMessage>
          )
        )}

        {Actions && (
          <div className={'IndicatorEmpty--actions'}>
            <Actions />
          </div>
        )}
      </div>
    );
  }
}

export default IndicatorEmpty;
