import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useIntl } from 'react-intl';

import dayjs from 'dayjs';
import { TimePicker } from 'antd';

import { DropdownSelectInput, Option } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';

import { SelectedSchedule as ScheduleType } from 'common/src/utils/schedules';

import styles from './styles.module.scss';

import {
  schedulePeriods,
  weekDaysMap,
  monthDaysMap,
} from 'common/dist/constants/schedulePeriods';
import { SelectedSchedule } from 'common/dist/utils/schedules';
const hourFormat = 'HH:mm';
const minuteFormat = 'mm';

type OptionType = { label: string | number; value: number | string };

export type Props = {
  untouch: () => void;
  validateField: (value: SelectedSchedule) => {};
};

const RegularScheduleSelect: FC<WrappedFieldProps & Props> = ({
  input: { onChange, value },
  validateField,
}) => {
  const intl = useIntl();

  // Set type for redux-form onChange(val) method
  const val: ScheduleType = value;

  // Map options for DropdownSelectInputs
  const periodOptions: OptionType[] = schedulePeriods.map((period) => {
    return {
      value: period.id,
      label: intl.formatMessage({ id: period.label }),
    };
  });

  const weeklyOptions: OptionType[] = schedulePeriods[2].options?.map(
    (option) => {
      return {
        label: intl.formatMessage({ id: option.label }),
        value: option.id,
      };
    }
  );

  const monthlyOptions: OptionType[] = schedulePeriods[3].options?.map(
    (option) => {
      return {
        label: intl.formatMessage({ id: option.label }),
        value: option.id,
      };
    }
  );

  // Handle changes from DropdownSelectInput & from TimePicker
  const handlePeriodChange = (option: OptionType) => {
    const updatedValue: ScheduleType = {
      trigger: val.trigger,
      trigTimed: {
        period: option.value as 'hourly' | 'daily' | 'weekly' | 'monthly',
        dayOfMonth: undefined,
        dayOfWeek: undefined,
        hourOfDay: undefined,
        minuteOfHour: undefined,
      },
    };
    onChange(updatedValue);
    validateField(updatedValue);
    return updatedValue;
  };

  const handlePeriodValueChange = (option: OptionType) => {
    const updatedValue: ScheduleType = {
      trigger: val.trigger,
      trigTimed: {
        period: val.trigTimed?.period,
        dayOfMonth:
          val.trigTimed?.period === 'monthly'
            ? Number(option.value)
            : undefined,
        dayOfWeek:
          val.trigTimed?.period === 'weekly' ? Number(option.value) : undefined,
        hourOfDay: undefined,
        minuteOfHour: undefined,
      },
    };
    onChange(updatedValue);
    validateField(updatedValue);
    return updatedValue;
  };

  const handleTimeChange = (time: dayjs.Dayjs, hourly: boolean) => {
    const hourOfDay = hourly ? undefined : time ? time.hour() : 0;
    const updatedValue: ScheduleType = {
      trigger: val.trigger,
      trigTimed: {
        period: val.trigTimed?.period,
        dayOfMonth: val.trigTimed?.dayOfMonth,
        dayOfWeek: val.trigTimed?.dayOfWeek,
        hourOfDay: hourOfDay,
        minuteOfHour: time ? time.minute() : 0,
      },
    };
    onChange(updatedValue);
  };

  return (
    <div className={styles.fieldsContainer}>
      <DropdownSelectInput
        placeholder={'Select Period'}
        onChange={handlePeriodChange}
        options={periodOptions}
        value={periodOptions.find(
          (option) => option.value === val.trigTimed?.period
        )}
      />

      {val.trigTimed?.period === 'weekly' && (
        <DropdownSelectInput
          options={weeklyOptions}
          placeholder={schedulePeriods[2].labelOptions.defaultMessage.toLocaleString()}
          value={weeklyOptions.find(
            (option) => option.value === val.trigTimed?.dayOfWeek
          )}
          onChange={handlePeriodValueChange}
        />
      )}

      {val.trigTimed?.period === 'monthly' && (
        <DropdownSelectInput
          options={monthlyOptions}
          placeholder={schedulePeriods[3].labelOptions.defaultMessage.toLocaleString()}
          value={monthlyOptions.find(
            (option) => option.value === val.trigTimed?.dayOfMonth
          )}
          onChange={handlePeriodValueChange}
        />
      )}

      {(val.trigTimed?.period === 'daily' ||
        val.trigTimed?.dayOfMonth !== undefined ||
        val.trigTimed?.dayOfWeek !== undefined) &&
        val.trigTimed?.period !== 'hourly' && (
          <TimePicker
            inputReadOnly
            showNow={false}
            size='large'
            onChange={(time: dayjs.Dayjs) => handleTimeChange(time, false)}
            onSelect={(time: dayjs.Dayjs) => handleTimeChange(time, false)}
            defaultValue={undefined}
            format={hourFormat}
            value={
              val.trigTimed?.hourOfDay !== undefined &&
              dayjs()
                .hour(val.trigTimed?.hourOfDay)
                .minute(val.trigTimed?.minuteOfHour)
            }
          />
        )}

      {val.trigTimed?.period === 'hourly' && (
        <TimePicker
          inputReadOnly
          showNow={false}
          size='large'
          onChange={(time: dayjs.Dayjs) => handleTimeChange(time, true)}
          onSelect={(time: dayjs.Dayjs) => handleTimeChange(time, true)}
          defaultValue={undefined}
          format={minuteFormat}
          value={
            val.trigTimed?.minuteOfHour !== undefined &&
            dayjs().minute(val.trigTimed?.minuteOfHour)
          }
          autoFocus={true}
        />
      )}
    </div>
  );
};

export default RegularScheduleSelect;
