import { Feature } from "../type";

export type SortByType = 'importance' | 'feature';
export type OrderByType = 'ascending' | 'descending';

const sortByImportance = (p1: Feature, p2: Feature) => p1.importance - p2.importance;

const sortByFeature = (p1: Feature, p2: Feature) => {
  const el1 = p1.feature.toLowerCase();
  const el2 = p2.feature.toLowerCase();

  if (el1 < el2) {
    return -1;
  }

  if (el1 > el2) {
    return 1;
  }

  return 0;
};

export const sortFeatures = (
  f1: Feature,
  f2: Feature,
  sort: SortByType,
  order: OrderByType
): number => {

  if (sort === 'feature') {
    return order === 'descending'
      ? sortByFeature(f2, f1)
      : sortByFeature(f1, f2);
  }

  if (sort === 'importance') {
    return order === 'descending'
      ? sortByImportance(f2, f1)
      : sortByImportance(f1, f2);
  }

  return 0;
};
