import React, { FC } from 'react';

import {
  CampaignOptimizationChannelsAugurSettings,
  CampaignOptimizationChannelsConfig,
  CampaignOptimizationChannelsValidationError,
} from './type';
import TupleListTable, {
  TableRow,
} from '../../../../tuple-list-table/TupleListTable';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationChannelsAugurSettings,
  CampaignOptimizationChannelsConfig,
  CampaignOptimizationChannelsValidationError
>;

const CampaignOptimizationChannels: FC<Props> = (props) => {
  const { onChange, value, error, invalid, onBlur, readOnly } = props;

  const tableRows = (value || []).map((channel) => ({
    id: channel.id,
    value1: channel.name,
    value2: channel.description,
  }));

  const handleTableChange = (updatedRows: TableRow[]) => {
    const updatedChannels = updatedRows.map((row) => ({
      id: row.id,
      name: row.value1 || '',
      description: row.value2,
    }));
    if (onChange) {
      onChange(updatedChannels);
    }
  };

  return (
    <TupleListTable
      label1='Name'
      placeholder1='Name'
      label2='Description (Optional)'
      placeholder2='Description'
      data={tableRows}
      onChange={handleTableChange}
      isTouched={true}
      errors={error}
      invalid={invalid}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
};

export default CampaignOptimizationChannels;
