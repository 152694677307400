// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-propensities/v1/type.ts' --type 'ConfigCampaignOptimizationPropensitiesConfig'
export const schemaConfigCampaignOptimizationPropensities = {
  $ref: '#/definitions/ConfigCampaignOptimizationPropensitiesConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<CampaignOptimizationPropensitiesConfig>': {
      additionalProperties: {
        $ref: '#/definitions/ConfigEntry%3Cnever%3E',
      },
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigCampaignOptimizationPropensitiesConfig: {
      $ref: '#/definitions/Config%3CCampaignOptimizationPropensitiesConfig%3E',
    },
    'ConfigEntry<never>': {
      additionalProperties: false,
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      properties: {
        elementUuid: {
          type: 'string',
        },
        source: {
          const: 'input-element',
          type: 'string',
        },
      },
      required: ['source', 'elementUuid'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/campaign-optimization-propensities/v1/type.ts' --type 'PartialCampaignOptimizationPropensitiesAugurSettings'
export const schemaAugurSettingsDefaultCampaignOptimizationPropensities = {
  $ref: '#/definitions/PartialCampaignOptimizationPropensitiesAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialCampaignOptimizationPropensitiesAugurSettings: {
      items: {
        $ref: '#/definitions/Propensity',
      },
      type: 'array',
    },
    Propensity: {
      additionalProperties: false,
      properties: {
        description: {
          description: 'Optional description of the propensity',
          type: 'string',
        },
        id: {
          description: 'Auto generated uuid for the propensity',
          type: 'string',
        },
        valueId: {
          description:
            'valueId of the propensity. The valueId will later be matched into the "value_id" field of the customer table. The customer table has to be uploaded by the User. To select the customer table is one step in the New Augur Wizard when creating a new Augur',
          type: 'string',
        },
      },
      required: ['id', 'valueId'],
      type: 'object',
    },
  },
};
