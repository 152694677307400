import React, { FC } from 'react';
import { ConfigNavbarLabel } from 'common/dist/types/frontendConfig';
import styles from './styles.module.scss';

type Props = {
  configNavbarLabel?: ConfigNavbarLabel;
};

const NavbarLabel: FC<Props> = ({ configNavbarLabel }) => {
  if (!configNavbarLabel) return null;

  return (
    <div
      className={styles.headerLabel}
      style={{
        color: configNavbarLabel?.color,
        backgroundColor: configNavbarLabel?.backgroundColor,
      }}
    >
      {configNavbarLabel?.message}
    </div>
  );
};

export default NavbarLabel;
