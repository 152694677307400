import * as React from 'react';

const SwaggerIcon = () => (
  <svg
    height='16px'
    width='16px'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='150 100 780 780'
  >
    <path
      d='M301.4 330.9c-1.7 19.4.7 39.3-.6 59a496.2 496.2 0 0 1-7.9 58.2 107.5 107.5 0 0 1-46.5 65.6c46 29.9 51.1 76.3 53.8 123.4 1.5 25.4.8 51 3.4 76.1 1.9 19.5 7.4 24.5 27.5 25.2h24.1v60.2c-53.8 10.2-106.7-6.8-118.8-57.2a345.2 345.2 0 0 1-6.2-56.4c-1.3-20.2-.9-40.3-2.4-60.5-4.3-55.3-12.4-74-66.1-76.6V479a108.9 108.9 0 0 0 13.8-1.9c29-1.5 43-10.8 48.8-39a339.3 339.3 0 0 0 6.9-49.1 907.8 907.8 0 0 1 7-94.7 74.8 74.8 0 0 1 79.1-69.1c13-.6 27.9 0 38.8 0v61.7a82.2 82.2 0 0 1-14.8 1.3c-37.5-1.4-37.1 11.5-39.9 42.7zm108.4 183v2.2a34.1 34.1 0 0 1-34.4 33.8 26 26 0 0 1-4-.3h-2.5a37.4 37.4 0 0 1-37.5-37.3V510a38.9 38.9 0 0 1 41.4-35.6h.9a36.6 36.6 0 0 1 36.3 37.2 18.5 18.5 0 0 1-.1 2.3zm140.1-1.2a36.8 36.8 0 0 1-33.2 40 36.2 36.2 0 0 1-5.7.1 37.2 37.2 0 0 1-40.2-34 38.5 38.5 0 0 1-.1-4.8 22.8 22.8 0 0 1 0-3.7 38.1 38.1 0 0 1 39.3-36.7 37.2 37.2 0 0 1 39.8 34.4c.1 1.6.1 3.1.1 4.7zm139.5-.6c-1.3 23.3-21.5 48.3-41.4 37.5a38.5 38.5 0 0 1-39.9-37v-1.3a40.9 40.9 0 0 1 81.3.8zm161.3-33.7v68.7a14.6 14.6 0 0 1-7.9 2.1 47.2 47.2 0 0 0-51.4 42.6 56.1 56.1 0 0 0-.2 7.1c-2.1 21.5-1.6 43-3.1 65.1a539.7 539.7 0 0 1-6.2 70.7 77.2 77.2 0 0 1-80 66.3c-14.1.9-23.1 0-44.6 0v-61.3c10.8 0 16.6-1.1 23.9-1.3 26.2-.7 35.9-9.2 37.2-35.2 1.5-28.6 2.5-57.2 3.6-86a121.8 121.8 0 0 1 51.5-103.7 99.4 99.4 0 0 1-44.2-60.3c-5.4-31-7.1-62.9-10.1-94.3-1.6-15.8-1.6-31.6-3.1-47.3a25.9 25.9 0 0 0-28.2-23.3h-30.4v-59.9c75.3-12 122.9 12.1 127.5 81 1.9 29.1 2.1 58.4 3.8 87.4a235.6 235.6 0 0 0 7.3 46.6 38.8 38.8 0 0 0 43 33.4 57.6 57.6 0 0 1 11.7 1.4z'
      fill='#173647'
    />
  </svg>
);
export default SwaggerIcon;
