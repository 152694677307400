import React, { FC } from 'react';
import * as d3 from 'd3';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import Bar from './Bar';
import { Point } from 'common/dist/types/module.charts';

type Props = {
  data: Point[];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
};

const Bars: FC<Props> = (props) => {
  const { data, xScale, yScale } = props;

  xScale.domain(data.map(([x]) => +x));
  yScale.domain([0, d3.max(data, ([, y]) => y)]);

  return (
    <g>
      {data.map((item) => (
        <Bar
          key={`${item[0]}_${item[1]}`}
          xScale={xScale}
          yScale={yScale}
          item={item}
        />
      ))}
    </g>
  );
};

export default Bars;
