import { NodeInfo } from '../../../core/api/codeCapsules';
import { GpuSelectOptionType } from '../../runCodeCapsuleModal/RunCodeCapsuleModal';

export const extractGpuProductOptions = (
  nodeInfo: NodeInfo
): GpuSelectOptionType[] => {
  return (nodeInfo?.nodes || [])
    .map((node) => ({
      value: { model: node.resources.gpu.model },
      label: node.resources.gpu.model,
    }))
    .filter((x) => !!x.value?.model);
};

export const isGpuAvailable = (nodeInfo: NodeInfo): boolean => {
  return (
    (nodeInfo?.nodes || []).find((n) => n.resources.gpu.allocatable > 0) !==
    undefined
  );
};
