// --- These are the message keys used for the socket communication

export default {
  augur: {
    created: 'augur.created',
    progress: 'augur.progress',
  },
  dataConnection: {
    created: 'dataConnection.created',
  },
  habitat: {
    created: 'habitat.created',
  },
  prediction: {
    started: 'prediction.started',
  },
  s3Bucket: {
    created: 's3Bucket.created',
  },
  jobProgress: {
    update: 'progress_update',
  },
  jobEvents: {
    update: 'events_update',
  },
  jobStatus: {
    update: 'status_update',
  },
  jobLog: {
    appendLines: 'log_append_lines',
  },
  realtimeAugur: {
    create: 'realtime_augur_create',
    update: 'realtime_augur_update',
    delete: 'realtime_augur_delete',
  },
};
