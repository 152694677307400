import msgsWorkbench from 'common/dist/messages/workbench';
import { K8sResources } from 'common/dist/types/job';
import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  getCpuValueInMilliunits,
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
} from 'common/dist/utils/kubernetes';
import React, { FC, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Notifications from 'react-notification-system-redux';
import { useSelector } from 'react-redux';

import StartServerButton from './StartServerButton.container';
import styles from './styles.module.scss';
import { useNodeInfo } from '../../../../core/api/codeCapsules';
import { RootState } from '../../../../store/store';
import {
  WB_DEF_CPU_LIM,
  WB_DEF_CPU_REQ,
  WB_DEF_MEM_LIM,
  WB_DEF_MEM_REQ,
  WB_MAX_GPU,
} from '../../../admin/users/user-details/Attributes.form';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import K8sNodeInfo from '../../../molecules/k8s-node-info/K8sNodeInfo';
import { K8sResourcesSelect } from '../../../molecules/k8s-resources-select/K8sResourcesSelect';
import { validateK8sResources } from '../../../molecules/k8s-resources-select/validate';
import CollapsibleHeadlineArea from '../../../organisms/collapsible-container/collapsible-headline-area/CollapsibleHeadlineArea';
import MainContainer from '../../../pages/main-container/MainContainer';

type Image = {
  label: string;
  value: string;
};

export type FormData = { image: string; resources: K8sResources };

const getDefaultResources = (
  userAttributes: Record<string, string[]>
): K8sResources => ({
  cpuRequest:
    userAttributes &&
    userAttributes[WB_DEF_CPU_REQ] &&
    userAttributes[WB_DEF_CPU_REQ].length > 0 &&
    isValidK8sCpuSpec(userAttributes[WB_DEF_CPU_REQ][0])
      ? userAttributes[WB_DEF_CPU_REQ][0]
      : '',
  cpuLimit:
    userAttributes &&
    userAttributes[WB_DEF_CPU_LIM] &&
    userAttributes[WB_DEF_CPU_LIM].length > 0 &&
    isValidK8sCpuSpec(userAttributes[WB_DEF_CPU_LIM][0])
      ? userAttributes[WB_DEF_CPU_LIM][0]
      : '',
  memoryRequest:
    userAttributes &&
    userAttributes[WB_DEF_MEM_REQ] &&
    userAttributes[WB_DEF_MEM_REQ].length > 0 &&
    isValidK8sMemorySpec(userAttributes[WB_DEF_MEM_REQ][0], true)
      ? userAttributes[WB_DEF_MEM_REQ][0]
      : '',
  memoryLimit:
    userAttributes &&
    userAttributes[WB_DEF_MEM_LIM] &&
    userAttributes[WB_DEF_MEM_LIM].length > 0 &&
    isValidK8sMemorySpec(userAttributes[WB_DEF_MEM_LIM][0], true)
      ? userAttributes[WB_DEF_MEM_LIM][0]
      : '',
});

const isGpuEnabled = (userAttributes: Record<string, string[]>) => {
  return (
    userAttributes &&
    (!userAttributes[WB_MAX_GPU] || // attribute not set
      !(userAttributes[WB_MAX_GPU].length > 0) || // attribute not set
      !(
        isValidK8sCpuSpec(userAttributes[WB_MAX_GPU][0], true, false) ||
        userAttributes[WB_MAX_GPU][0] === '0'
      ) || // attribute invalid
      getCpuValueInMilliunits(userAttributes[WB_MAX_GPU][0]) <= 0)
  ); // limit <= 0
};

const StartServer: FC = () => {
  const notifications = useSelector<RootState, ToBeRefined[]>(
    (state) => state.workbench.notifications
  );

  const images = useSelector<RootState, Image[]>(
    (state) => state.config?.images
  );

  const isSpawning = useSelector<RootState, boolean>(
    (state) => state.workbench.notebookStarting.loading
  );
  // Get the current user attributes
  const userAttributes = useSelector<
    RootState,
    Record<string, string[]> | undefined
  >((state) => state.currentUser.attributes);
  const nodeInfo = useNodeInfo();

  const [message, onMessageChange] = useState('');

  const form = useForm<FormData, 'K8sResources'>({
    mode: 'all',
  });

  const { isValid } = useFormState({ control: form.control });

  return (
    <MainContainer transparent={true} fullWidth>
      <div className={styles.startServer} data-testid={'workbench-start'}>
        <div className={styles.startServerParent} id={'start-server-parent'}>
          <div
            id={'start-server-headline-parent'}
            className={styles.startServerHeadlineParent}
          >
            <span className={styles.startServerHeadline}>
              Start your AI Workbench Server
            </span>
          </div>
          <div
            id={'start-server-button-parent'}
            className={styles.startServerButtonParent}
          >
            <div className={styles.imageSelectParent}>
              <span className={styles.imageSelectHeadline}>
                Workbench Image
              </span>
              <Controller
                name={'image'}
                control={form.control}
                defaultValue={images?.[0]?.value}
                render={({ field }) => (
                  <DropdownSelectInput
                    id={'workbench-image-select'}
                    isLoading={false}
                    disabled={isSpawning}
                    scrollMenuIntoView
                    options={images || []}
                    value={images?.find((i) => i.value === field.value)}
                    onChange={(selectedOption: {
                      label: string;
                      value: string;
                    }) => {
                      field.onChange(selectedOption?.value);
                    }}
                    label={msgsWorkbench.workbenchSelectImage}
                  />
                )}
              />
            </div>
            <div className={styles.resourcesSelectParent}>
              <Controller
                name={'resources'}
                control={form.control}
                rules={{
                  validate: (value) => {
                    const resourceValidationError = validateK8sResources(
                      value || {},
                      userAttributes
                    );

                    return !resourceValidationError;
                  },
                }}
                render={({ field }) => {
                  return (
                    <K8sResourcesSelect
                      nodeInfo={nodeInfo?.data}
                      value={field.value}
                      onChange={field.onChange}
                      validateResources={(resources) =>
                        validateK8sResources(resources, userAttributes)
                      }
                      gpuEnabled={isGpuEnabled(userAttributes)}
                      defaultPlaceholders={getDefaultResources(userAttributes)}
                    />
                  );
                }}
              />
            </div>
            <CollapsibleHeadlineArea
              title={{ id: 'no-id', defaultMessage: 'Kubernetes Node Info' }}
              initialCollapsed
              renderBody={() => (
                <K8sNodeInfo renderTitle={false} nodeInfo={nodeInfo?.data} />
              )}
            />

            <StartServerButton
              onMessageChange={onMessageChange}
              handleSubmit={form.handleSubmit}
              isFormValid={isValid}
            />
          </div>
          <div
            id={'start-server-message-parent'}
            className={styles.startServerMessageParent}
          >
            <span>{message}</span>
          </div>
        </div>
      </div>
      <Notifications notifications={notifications} />
    </MainContainer>
  );
};

export default StartServer;
