import { getApiRequest, postApiRequest } from './_apiRequests';

export function startServer(jupyterUser, image, resources) {
  const url = `/jupyter/hub/spawn/${jupyterUser}`;
  const options = {
    headers: {},
    credentials: 'include',
    mode: 'cors',
  };
  const data = new URLSearchParams();
  data.append('image', image);
  Object.keys(resources || {}).forEach((k) => {
    if (resources[k]) {
      if (
        typeof resources[k] === 'object' &&
        resources[k] !== null &&
        k === 'gpuProduct'
      ) {
        // If it's an object and the key is gpuProduct we want to check differently
        const { model } = resources[k];
        //If the key model is defined we append this
        if (model) data.append(k, model);
      } else {
        // If it's not an object, append it as before
        data.append(k, resources[k]);
      }
    }
  });
  return postApiRequest(url, data, options, 'form');
}

export function login(jupyterUser) {
  const url = `/jupyter/hub/user/${jupyterUser}`;
  return getApiRequest(url, {}, 'application/json');
}

export function checkServer() {
  const url = `/jupyter/hub/api/user`;
  const options = {
    headers: {},
    credentials: 'include',
    mode: 'cors',
  };
  return getApiRequest(url, options, 'form');
}

/**
 * Starts the app notebook for a given user and a given servername
 * @param jupyterUser
 * @param serverName
 * @returns {*}
 */
export function startApp(jupyterUser, serverName) {
  const url = `/jupyterapp/hub/api/users/${jupyterUser}/servers/${serverName}`;
  const options = {};
  const body = {};
  return postApiRequest(url, body, options, 'application/json');
}

export function checkApp(jupyterUser, serverName) {
  const url = `/jupyterapp/user/${jupyterUser}/${serverName}`;
  return getApiRequest(url, {}, 'application/json');
}
