import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { DeepPartial } from 'react-hook-form';
import { TargetFunctionErrorType } from './CampaignOptimizationTargetFunction';
import { CampaignOptimizationTargetFunctionV1AugurSettings as CampaignOptimizationTargetFunctionAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationTargetFunctionV1AugurSettings';

export type { CampaignOptimizationTargetFunctionAugurSettings };

export type CampaignOptimizationTargetFunctionConfig = {};
export type ConfigCampaignOptimizationTargetFunctionConfig =
  Config<CampaignOptimizationTargetFunctionConfig>;

export type PartialCampaignOptimizationTargetFunctionAugurSettings =
  Partial<CampaignOptimizationTargetFunctionAugurSettings>;

export type CampaignOptimizationTargetFunctionValidationError =
  DeepPartial<TargetFunctionErrorType>;

export const configAllowedInputsCampaignOptimizationTargetFunction: AllowedInputs<CampaignOptimizationTargetFunctionConfig> =
  {};

export const configTypeCampaignOptimizationTargetFunction = `{}`;

export const defaultConfigCampaignOptimizationTargetFunction: ConfigCampaignOptimizationTargetFunctionConfig =
  {};

export const augurSettingsTypeCampaignOptimizationTargetFunction = `{
    summandGroups: {
        id: string;
        name: string;
        description?: string | undefined;
        sumOver: 'allCommunications';
        sumOf: 'costs' | 'values';
        factor: string;
    }[],
    minimizeOrMaximize: 'minimize' | 'maximize',
}`;

export const defaultAugurSettingsDefaultCampaignOptimizationTargetFunction: PartialCampaignOptimizationTargetFunctionAugurSettings =
  {
    summandGroups: [],
    minimizeOrMaximize: 'minimize',
  };

// JSONSchemaType<CampaignOptimizationTargetFunctionAugurSettings>
export const defaultValidationSchemaCampaignOptimizationTargetFunction = {};
