import { createAction } from 'redux-act';
import { eventChannel } from 'redux-saga';
import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import * as JupyterServerApi from '../../../core/api/workbench/jupyterServer';
import * as JupyterContentApi from '../../../core/api/workbench/content';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import {
  notificationStartServerFailed,
  notificationStartServerTriggered,
} from '../notifications/notifications';

/**
 * Checks whether the Jupyter notebook is already running for the given user.
 * @type {ComplexActionCreator1<unknown, {notebookUser: unknown}, {}>}
 */
export const checkWhetherNotebookIsRunning = createAction(
  'check whether notebook is running',
  (notebookUser, retry) => ({ notebookUser, retry })
);

/**
 * Called when the check request was successfully completed. This function is independent of the result whether the
 * @type {ComplexActionCreator1<unknown, {isRunning: unknown}, {}>}
 */
export const checkWhetherNotebookIsRunningSuccess = createAction(
  'check whether notebook is running - success',
  ({ isRunning, isSpawning, isStopping, reloginRequired }) => ({
    isRunning,
    isSpawning,
    isStopping,
    reloginRequired,
  })
);

/**
 * Called when the check request could not be completed. Again: Independent of the result whether the notebook is
 * running or not - this case means the request didn't give an expected response (or possibly even no response in time).
 * @type {ComplexActionCreator1<unknown, {error: unknown}, {}>}
 */
export const checkWhetherNotebookIsRunningFailure = createAction(
  'check whether notebook is running - failure',
  (error) => ({ error })
);

export const startServer = createAction(
  'start server',
  (notebookUser, image, resources) => ({
    notebookUser,
    image,
    resources,
  })
);

// This doesn't mean the server is running - it only means that the async request was submitted successfully.
export const startServerSuccess = createAction(
  'start server - success ',
  (success, eventSourceAvailable) => ({ success, eventSourceAvailable })
);

export const startServerFail = createAction('start server - fail', (error) => ({
  error,
}));

export const serverStartStatusMessage = createAction(
  'server start status message',
  (message) => ({ message })
);

export const resetEventSource = createAction('reset event source');

export const showShutdownWorkbenchModal = createAction(
  'show shutdown workbench modal'
);

export const hideShutdownWorkbenchModal = createAction(
  'hide shutdown workbench modal'
);

export const reducer = {
  [checkWhetherNotebookIsRunning]: (state) => ({
    ...state,
    notebookRunning: {
      ...state.notebookRunning,
      loading: true,
    },
  }),
  [checkWhetherNotebookIsRunningSuccess]: (
    state,
    { isRunning, isSpawning, isStopping, reloginRequired }
  ) => ({
    ...state,
    notebookRunning: {
      ...state.notebookRunning,
      loading: false,
      loaded: true,
      isRunning,
      isSpawning,
      isStopping,
      reloginRequired,
      error: undefined,
    },
  }),
  [checkWhetherNotebookIsRunningFailure]: (state, { error }) => ({
    ...state,
    notebookRunning: {
      ...state.notebookRunning,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [startServer]: (state) => ({
    ...state,
    notebookStarting: {
      ...state.notebookStarting,
      loading: true,
      loaded: false,
      error: undefined,
      eventSourceAvailable: undefined,
      eventSourceMessages: [],
    },
  }),
  [startServerSuccess]: (state, { eventSourceAvailable }) => ({
    ...state,
    notebookStarting: {
      ...state.notebookStarting,
      loading: false,
      loaded: true,
      eventSourceAvailable,
    },
  }),
  [startServerFail]: (state, { error }) => ({
    ...state,
    notebookStarting: {
      ...state.notebookStarting,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [serverStartStatusMessage]: (state, { message }) => ({
    ...state,
    notebookStarting: {
      ...state.notebookStarting,
      eventSourceMessages: [
        ...state.notebookStarting.eventSourceMessages,
        message,
      ],
    },
  }),
  [startServerSuccess]: (state) => ({
    ...state,
    notebookStarting: {
      ...state.notebookStarting,
      eventSourceAvailable: false,
    },
  }),
  [showShutdownWorkbenchModal]: (state) => ({
    ...state,
    shutdownWorkbenchModalVisible: true,
  }),
  [hideShutdownWorkbenchModal]: (state) => ({
    ...state,
    shutdownWorkbenchModalVisible: false,
  }),
};

function startEventSource(response, notebookUser) {
  return eventChannel((emitter) => {
    const source = new EventSource(
      `${location.protocol}//${
        window.location.hostname + (location.port ? ':' + location.port : '')
      }/jupyter/hub/api/users/${notebookUser}/server/progress`
    );
    // --- OPEN
    source.addEventListener(
      'open',
      (e) => emitter(startServerSuccess(response, true)),
      false
    );
    // --- MESSAGE
    source.addEventListener(
      'message',
      (e) => {
        return emitter(serverStartStatusMessage(JSON.parse(e.data)));
      },
      false
    );
    // --- ERROR / CLOSE
    source.addEventListener(
      'error',
      (e) => {
        source.close();
        return emitter(startServerSuccess(response, false));
      },
      false
    );

    return () => {};
  });
}

export function* startServerSaga({
  payload: { notebookUser, image, resources },
}) {
  try {
    const { response, error } = yield call(
      JupyterServerApi.startServer,
      notebookUser,
      image,
      resources
    );
    if (response) {
      yield put(successNotification(notificationStartServerTriggered()));
      // --- Start EventSource
      // startEventSource(response);
      const channel = yield call(startEventSource, response, notebookUser);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
      // ---
    } else {
      yield put(startServerFail(error));
      yield put(errorNotification(notificationStartServerFailed()));
    }
  } catch (e) {
    // console.log('Caught error:', e);
    // yield put(startServerFail());
    // yield put(errorNotification(notificationStartServerFailed()));
  }
}

export function* watchStartServer() {
  yield takeEvery(startServer().type, startServerSaga);
}

const eventSourceAvailable = (state) =>
  state.workbench.notebookStarting.eventSourceAvailable;

export function* checkWhetherNotebookIsRunningSaga({
  payload: { notebookUser, retry },
}) {
  try {
    yield call(JupyterServerApi.login, notebookUser);
    const { response, status } = yield call(JupyterServerApi.checkServer);

    if (!response && status === 403) {
      // No "response" and status=403: The login for the JupyterHub is not valid.
      // We need to ask the user to logout to delete the deprecated cookie.
      // This case was only detected after re-deploying the platform (or is re-starting Keycloak already enough?)
      yield put(
        checkWhetherNotebookIsRunningSuccess({
          isRunning: false,
          isSpawning: false,
          isStopping: false,
          reloginRequired: true,
        })
      );
    } else if (!response) {
      // No response and status!=403: Unknown cases. This could be a timeout from nginx when jupyterhub is down. Our timeout should typically happen earlier.
      yield put(
        checkWhetherNotebookIsRunningFailure('JupyterHub is not reachable.')
      );
    } else if (response.server && !response.pending) {
      // Make sure the content can be fetched
      const { response: contentResponse, error: contentError } = yield call(
        JupyterContentApi.fetchContent,
        notebookUser,
        ''
      );

      if (contentResponse && Object.keys(contentResponse).includes('path')) {
        // Content could be fetched: state that the notebook is running
        yield put(
          checkWhetherNotebookIsRunningSuccess({
            isRunning: true,
            isSpawning: false,
            isStopping: false,
            reloginRequired: false,
          })
        );
      } else {
        // Content couldn't be fetched: Wait 3 seconds and try again
        if (retry) {
          yield delay(3000); // Sleep for 3 seconds
          yield put(checkWhetherNotebookIsRunning(notebookUser, true));
        }
      }
    } else if (response.pending === 'check') {
      yield put(
        checkWhetherNotebookIsRunningFailure(
          'Your Workbench is pending.\n\nPlease wait a moment.'
        )
      );
    } else if (response.pending === 'spawn') {
      // console.log("spawning...");
      yield put(
        checkWhetherNotebookIsRunningSuccess({
          isRunning: false,
          isSpawning: true,
          isStopping: false,
          reloginRequired: false,
        })
      );
      // console.log(yield select(eventSourceAvailable));
      if (!(yield select(eventSourceAvailable))) {
        // console.log("starting event source...");
        const channel = yield call(startEventSource, response, notebookUser);
        while (true) {
          // console.log("waiting for message...");
          const action = yield take(channel);
          // console.log("received message: ", action);
          yield put(action);
        }
      }
    } else if (response.pending === 'stop') {
      yield put(
        checkWhetherNotebookIsRunningSuccess({
          isRunning: false,
          isSpawning: false,
          isStopping: true,
          reloginRequired: false,
        })
      );
    } else {
      yield put(
        checkWhetherNotebookIsRunningSuccess({
          isRunning: false,
          isSpawning: false,
          isStopping: false,
          reloginRequired: false,
        })
      );
      if (retry) {
        yield delay(3000); // Sleep for 3 seconds
        yield put(checkWhetherNotebookIsRunning(notebookUser, true));
      }
    }
  } catch (e) {
    if (e.name === 'AbortError') {
      // Translate a browser client timeout (DOMException: The operation was aborted.) into something more appropriate
      yield put(
        checkWhetherNotebookIsRunningFailure(
          'Timeout while checking health of Jupyter Hub.'
        )
      );
    } else {
      yield put(checkWhetherNotebookIsRunningFailure(e.message));
    }
  }
}

export function* watchCheckWhetherNotebookisRunning() {
  yield takeEvery(
    checkWhetherNotebookIsRunning().type,
    checkWhetherNotebookIsRunningSaga
  );
}
