export const dataManagementRoutes = {
  basePath: '/app/data',

  keyspaces: 'keyspaces',
  auth: 'auth',
  buckets: 'buckets',
  upload: 'upload',
  manage: 'manage',
};

export const s3PreviewRoute = (
  dataSourceCode: string,
  bucketName: string,
  path: string
): string =>
  `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucketName}/path/${path}`;

export const cassandraPreviewRoute = (
  dataSourceCode: string,
  keyspaceName: string,
  tableName: string
): string =>
  `${dataManagementRoutes.basePath}/keyspace/${dataSourceCode}/table/${keyspaceName}/preview-table/${tableName}`;
