import msgsWorkbench from 'common/dist/messages/workbench';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import { ButtonProps } from '../../../../../atoms/button/Button';
import Modal from '../../../../../organisms/modal/Modal';

type Props = {
  show?: boolean;
  branch?: string;
  commitsBehind?: number;
  hidePushConflictsModal: () => void;
  repositoryPath: string;
  gitPull: (repositoryPath: string, branch: string, force?: boolean) => void;
};

const PushConflictsModal: FC<Props> = (props) => {
  const {
    hidePushConflictsModal,
    show,
    branch,
    repositoryPath,
    gitPull,
    commitsBehind,
  } = props;

  const buttons: ButtonProps[] = [
    {
      color: 'white',
      onClick: () => {
        hidePushConflictsModal();
      },
      label: 'Cancel',
    },
    {
      color: 'primary',
      onClick: () => {
        gitPull(repositoryPath, branch);
        hidePushConflictsModal();
      },
      label: 'Pull',
    },
  ];

  return (
    <Modal
      show={show}
      hideModal={hidePushConflictsModal}
      headline={
        msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalHeadline
      }
      buttons={buttons}
    >
      <div className={styles.modalContent}>
        <span>
          <FormattedMessage
            {...msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalLine1}
            values={{
              commitsBehind,
              pluralSuffix: commitsBehind > 1 ? 's' : '',
            }}
          />
        </span>
        <span>
          <FormattedMessage
            {...msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalLine2}
          />
        </span>
      </div>
    </Modal>
  );
};

export default PushConflictsModal;
