import classNames from 'classnames';
import React, { FC } from 'react';
import { FaUserCog } from 'react-icons/fa';

import styles from './styles.module.scss';
import { useUserSummary } from '../../../core/api/users';

export interface Props {
  userId: string;
  isTechnical?: boolean;
  showTooltip?: boolean;
  fallbackToIcon?: boolean;
  size?: number;
  fontSize?: number;
}

export const hashString = (string: string, numberOfBuckets: number): number =>
  Array.from(string).reduce((acc, val) => acc + val.charCodeAt(0), 0) %
  numberOfBuckets;

// Taken from https://m2.material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=546E7A
export const colors = [
  '#e53935',
  '#d81b60',
  '#8e24aa',
  '#5e35b1',
  '#3949ab',
  '#1e88e5',
  '#039be5',
  '#00acc1',
  '#00897b',
  '#43a047',
  '#7cb342',
  '#c0ca33',
  '#fdd835',
  '#ffb300',
  '#fb8c00',
  '#f4511e',
  '#546e7a',
];

const AvatarIcon: FC<Props> = ({
  userId,
  showTooltip = true,
  fallbackToIcon = false,
  size = 28,
  fontSize = 11,
}) => {
  const userSummary = useUserSummary(userId);
  if (!userSummary.isSuccess) {
    return null;
  }
  const { firstName, lastName, isTechnical, avatar } = userSummary.data || {};
  const initials =
    (firstName ? firstName[0]?.toUpperCase() : '') +
    (lastName ? lastName[0]?.toUpperCase() : '');
  return (
    <div
      className={styles.image}
      style={{ width: `${size}px`, height: `${size}px` }}
      title={
        showTooltip
          ? firstName || lastName
            ? `${firstName || ''} ${lastName || ''}`
            : 'Unknown User'
          : undefined
      }
    >
      {avatar ? (
        <img
          src={`data:${avatar.imageType};base64, ${avatar.imageData}`}
          alt={`Avatar of ${firstName} ${lastName}`}
        />
      ) : fallbackToIcon ? (
        <span className={classNames(styles.icon, 'icon-person')} />
      ) : (
        <div
          className={styles.initials}
          style={{
            backgroundColor: colors[hashString(userId, colors.length)],
            fontSize,
          }}
        >
          {isTechnical ? <FaUserCog size={'16px'} /> : initials || '?'}
        </div>
      )}
    </div>
  );
};

export default AvatarIcon;
