import { AlgorithmNames, SingleParametersResult } from 'common/dist/types/module.nbc';
import { ToBeRefined } from 'common/dist/types/todo_type';
import * as React from 'react';
import { Component } from 'react';
import { Column, useTable } from 'react-table';

import styles from './styles.module.scss';

interface ColMinMax {
  [paramOrScore: string]: { max: number; min: number };
}

interface Props {
  algorithmTuningResults: SingleParametersResult[];
  algorithmNames: AlgorithmNames;
  maxScore: number;
  minScore: number;
}

function toReactTableStructure<
  T extends { score: number } & Record<string, number | string>
>(
  algorithmTuningResults: SingleParametersResult[],
  algorithmNames: AlgorithmNames
): { shapedCols: Column<T>[]; shapedData: T[] } {
  const shapedData = algorithmTuningResults.map(
    (smr) =>
      ({
        score: smr.score,
        ...Object.fromEntries(
          smr.parameters.map(({ name, value }) => [name, value])
        ),
      } as T)
  );
  // No sorting, because score is first due to above
  // and the other columns keep their defined order and are consistent with elsewhere
  const shapedCols = Object.keys(shapedData[0]).map((colName) => ({
    Header: colName === 'score' ? 'Score' : algorithmNames.parameters[colName],
    accessor: colName as keyof T,
  }));

  return { shapedCols, shapedData };
}

function AlgorithmTuningResultsTable({
  algorithmTuningResults,
  algorithmNames,
  maxScore,
  minScore,
}: Props) {
  const { shapedCols, shapedData } = React.useMemo(
    () => toReactTableStructure(algorithmTuningResults, algorithmNames),
    algorithmTuningResults
  );

  // Collect min and max for each column for shading
  // Strings are not handled explicitly, but should just result in NaN, which leads to no shading
  const reducer = (acc: ColMinMax, curr: ToBeRefined) =>
    Object.fromEntries(
      Object.entries(acc).map(([k, v]) => [
        k,
        {
          max: Math.max(v.max, curr[k]),
          min: Math.min(v.min, curr[k]),
        },
      ])
    );
  const initialValue = Object.fromEntries(
    Object.entries(shapedData[0]).map(([k]) => [
      k,
      {
        max: Number.MIN_VALUE,
        min: Number.MAX_VALUE,
      },
    ])
  );
  const colMinMax: ColMinMax = shapedData.reduce(reducer, initialValue);

  // Sort descending by score
  const sortedShapedData = shapedData.sort((a, b) => -(a.score - b.score));

  const tableInstance = useTable({
    columns: shapedCols,
    data: sortedShapedData,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className={'table-reset'}>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} key={index}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} key={index}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      let { min, max } = colMinMax[cell.column.id];
                      if (cell.column.id === 'score') {
                        max = maxScore;
                        min = minScore;
                      }
                      // Set alpha to a maximum alpha value * percent of maximum cell value. Maximum alpha if min = max
                      const alpha =
                        0.8 *
                        (min === max ? 1 : (cell.value - min) / (max - min));
                      return (
                        // Apply the cell props
                        <td
                          {...cell.getCellProps()}
                          style={{ background: `rgba(34, 78, 144, ${alpha})` }}
                          key={index}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default class AlgorithmTuningResults extends Component<Props> {
  render() {
    const { algorithmTuningResults, algorithmNames, maxScore, minScore } =
      this.props;
    return (
      <div className={styles.resultContainer}>
        <h3 className={styles.resultHeadline}>{algorithmNames.speakingName}</h3>
        <AlgorithmTuningResultsTable
          algorithmTuningResults={algorithmTuningResults}
          algorithmNames={algorithmNames}
          maxScore={maxScore}
          minScore={minScore}
        />
      </div>
    );
  }
}
