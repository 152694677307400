import { ConfigForm, ConfigPageForm } from './config.form';
import {
  AUGUR_CATEGORY,
  AUGUR_CATEGORY_TYPE,
  AugurCategory,
  AugurCategoryType,
  AugurMenuCategory,
  AugurMenuEntry,
  ID_AUGUR_BIOGRAPHY,
  ID_GENERAL_SETTINGS,
  ID_MODEL_ARCHIVE,
} from '../../../molecules/augur-menu/types';

/**
 * Extracts the Augur menu category entries from a config object.
 * @param pagesForm Config form state of pages
 * @param mode Mode in which the AugurMenu is used in, determines which categories/entries are enabled or diabled
 *   - 'live' -> everything is enabled
 *   - 'view' -> General Augur Settings are disabled
 *   - 'edit' -> General Augur Settings, Model Viewer, and Augur Biography are disabled
 */
export function configFormToMenuCategories(
  pagesForm: ConfigForm['pages'],
  mode: 'live' | 'view' | 'edit'
): AugurMenuCategory[] {
  const pageToMenuEntry = (page: ConfigPageForm): AugurMenuEntry => ({
    id: page.uuid,
    name: page.title,
    iconId: page.iconId,
  });

  const pages = Object.values(pagesForm);

  return [
    {
      id: AUGUR_CATEGORY.LEARNING,
      type: AUGUR_CATEGORY_TYPE.REPORT,
      title: { id: 'no-id', defaultMessage: 'Learning' },
      entries: pages
        .filter((page) => page.menuCategory === 'learning')
        .map(pageToMenuEntry),
    },
    {
      id: AUGUR_CATEGORY.EVALUATION,
      type: AUGUR_CATEGORY_TYPE.REPORT,
      title: { id: 'no-id', defaultMessage: 'Evaluation' },
      entries: pages
        .filter((page) => page.menuCategory === 'evaluation')
        .map(pageToMenuEntry),
    },
    {
      id: AUGUR_CATEGORY.PREDICTION,
      type: AUGUR_CATEGORY_TYPE.REPORT,
      title: { id: 'no-id', defaultMessage: 'Prediction' },
      entries: pages
        .filter((page) => page.menuCategory === 'prediction')
        .map(pageToMenuEntry),
    },
    {
      id: AUGUR_CATEGORY.MODEL_MANAGEMENT,
      type: AUGUR_CATEGORY_TYPE.MODEL_MANAGEMENT,
      title: { id: 'no-id', defaultMessage: 'Model Management' },
      isFixed: true,
      isDisabled: mode === 'edit' || mode === 'view',
      entries: [
        {
          id: ID_MODEL_ARCHIVE,
          name: 'Model Archive',
          iconId: 'modelHistory',
        },
        {
          id: ID_AUGUR_BIOGRAPHY,
          name: 'Augur Biography',
          iconId: 'bookOpen',
        },
      ],
    },
    {
      id: AUGUR_CATEGORY.SETTINGS,
      type: AUGUR_CATEGORY_TYPE.SETTINGS,
      title: {
        id: 'details.sideNav.settings',
        defaultMessage: 'Settings',
      },
      entries: [
        {
          id: ID_GENERAL_SETTINGS,
          name: 'General Settings',
          iconId: 'sliders',
          isFixedAtTop: true,
          isDisabled: mode === 'edit' || mode === 'view',
        },
        ...pages
          .filter((page) => page.menuCategory === 'settings')
          .map(pageToMenuEntry),
      ],
    },
  ];
}

export function menuCategoriesToPagesFormState(
  menuCategories: AugurMenuCategory[]
): ConfigForm['pages'] {
  return menuCategories.reduce((acc, menuCategory) => {
    return menuCategory.entries.reduce((acc, entry) => {
      // overwrite values that can be changed in AugurMenu for existing pages
      // create new page entry for newly added pages
      // deleted pages are not included in the reduced AugurMenu entries
      if (menuCategory.isFixed || entry.isFixedAtTop) {
        // no static categories in form state
        return acc;
      } else {
        return {
          ...acc,
          [entry.id]: {
            uuid: entry.id,
            title: entry.name,
            iconId: entry.iconId,
            menuCategory: menuCategory.id,
          },
        };
      }
    }, acc);
  }, {} as ConfigForm['pages']);
}

/**
 * Returns a tuple of menu category type and menu category id with the given page id.
 * If pageId is undefined or page could not be found an empty list is returned.
 */
export function getMenuCategoryByPageId(
  menuCategories: AugurMenuCategory[],
  pageId?: string
): [AugurCategoryType?, AugurCategory?] {
  if (!pageId) return [];

  for (const menuCategory of menuCategories) {
    const page = menuCategory.entries.find((entry) => entry.id === pageId);
    if (page) {
      return [menuCategory.type, menuCategory.id];
    }
  }

  return [];
}

/**
 * Returns whether the given string is a valid Augur page category.
 */
export function isValidPageCategory(
  pageCategory: string
): pageCategory is AugurCategory {
  return Object.values(AUGUR_CATEGORY).includes(pageCategory as AugurCategory);
}

/**
 * Returns the first selectable page entry in the AugurMenu.
 * If no entry is available for selection undefined is returned.
 * @param pageCategory If this parameter is specified, the first valid page entry of this category is returned.
 */
export function getValidPage(
  menuCategories: AugurMenuCategory[],
  pageCategory?: AugurCategory
) {
  for (const category of menuCategories) {
    if (
      !category.isDisabled &&
      (!pageCategory || category.id === pageCategory)
    ) {
      for (const entry of category.entries) {
        if (!entry.isDisabled) {
          return entry;
        }
      }
    }
  }
  return undefined;
}

/**
 * Returns whether a page with the given id that is also selectable exists in the given AugurMenu object.
 * If the page with the given id exists and it is not disabled, true is returned.
 */
export function isSelectablePageId(
  menuCategories: AugurMenuCategory[],
  pageId: string,
  pageCategory?: string
) {
  for (const menuCategory of menuCategories) {
    if (
      menuCategory.isDisabled ||
      (pageCategory && menuCategory.id !== pageCategory)
    )
      continue;

    const page = menuCategory.entries.find((entry) => entry.id === pageId);
    if (page && !page.isDisabled) {
      return true;
    }
  }
  return false;
}
