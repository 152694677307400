import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import BestModelChart from './BestModelChart';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import { FormattedMessage } from 'react-intl';
import ModelParameters from './model-parameters/ModelParameters';
import { PipelineTuningBestResultConfig, PipelineTuningBestResultReportData } from '../type';
import { ReportElementProps } from '../../../types/meta';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';


export type Props = PipelineTuningBestResultReportData & PipelineTuningBestResultConfig;


const PipelineTuningBestResult: FC<Props> = (props: Props) => {
  const { pipelines, bestModel, kpi } = props;

  const [activeNodeId, setActiveNodeId] = useState<string>();

  if (!bestModel) {
    // Only show the component if the pipelineTuningResults are actually there
    return <div className={styles.pipelineTuningResultsBestModel} />;
  }

  const bestModelsPipeline = (pipelines || []).find(
    (p) => p.id === bestModel.pipelineId
  );
  const bestModelsPipelineSchema = bestModelsPipeline?.schema;
  const bestModelsPath = bestModelsPipeline?.paths.find(
    (p) => p.pathId === bestModel.pathId
  );
  const bestModelsParameters = bestModel.parameters;

  const kpiMsgId = kpiOptionsGrouped[kpi]?.label;
  const scoreFormatter = kpiOptionsGrouped[kpi]?.format;

  return (
    <div className={styles.pipelineTuningResultsBestModel} style={{width: '100%', height: '100%', overflow: 'auto'}}>
      <div className={styles.infos}>
        <div className={styles.infoLine}>
          <span className={styles.infosKey}>
            Optimization KPI:
          </span>
          <FormattedMessage
            id={kpiMsgId}
            // @ts-ignore
            defaultValue={kpi || ''}
          >
            {(text) => (
              <span className={styles.infosValue}>
                {text}
              </span>
            )}
          </FormattedMessage>
        </div>
        <div className={styles.infoLine}>
          <span className={styles.infosKey}>
            Training-Score:
          </span>
          <span className={styles.infosValue}>
            {scoreFormatter(bestModel.score)}
          </span>
        </div>
      </div>

      <div className={styles.modelContainer}>
        <BestModelChart
          //@ts-ignore
          pipeline={bestModelsPipelineSchema}
          //@ts-ignore
          path={bestModelsPath}
          setActiveNodeId={setActiveNodeId}
        />

        <ModelParameters
          //@ts-ignore
          pipeline={bestModelsPipelineSchema}
          //@ts-ignore
          path={bestModelsPath}
          parameters={bestModelsParameters}
          activeNodeId={activeNodeId}
        />
      </div>
    </div>
  );

}

export const PipelineTuningBestResultSingle: FC<
  ReportElementProps<PipelineTuningBestResultReportData, PipelineTuningBestResultConfig>
> = buildSingleReportWrapper<PipelineTuningBestResultReportData, PipelineTuningBestResultConfig>(PipelineTuningBestResult);

export default PipelineTuningBestResult;
