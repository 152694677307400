import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { CampaignOptimizationCommunicationsV1AugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationCommunicationsV1AugurSettings';
import {
  Channel,
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationCommunicationsConfig,
  CampaignOptimizationCommunicationsValidationError,
} from './type';
import {
  validateDescription,
  validateName,
} from '../../campaign-optimization-channels/v1/validate';

export const numberRegex = /^-?\d+\.?\d*$/;

export const validateNumber = (
  number?: string,
  valueName?: string
): string | undefined => {
  // The field is required
  if (!number) {
    return `Please enter the ${valueName}`; // TODO how can this be moved into common/src/messages?
  }

  // Valid characters
  if (!numberRegex.test(number)) {
    return moduleOptimizationMessages
      .msgNewAugurStepCommunicationsErrInvalidNumber.defaultMessage;
  }
};

export const validateCommunications = (
  value: CampaignOptimizationCommunicationsV1AugurSettings,
  config?: CampaignOptimizationCommunicationsConfig
):
  | DeepPartial<CampaignOptimizationCommunicationsValidationError>
  | undefined => {
  const error: DeepPartial<CampaignOptimizationCommunicationsValidationError> =
    {};
  // Is there at least one communication added?
  if (!value || value.length === 0) {
    error.global =
      moduleOptimizationMessages.msgNewAugurStepCommunicationsErrNoCommunications.defaultMessage;
  } else {
    value?.forEach((communication) => {
      const constraintError = validateSingleCommunication(
        communication,
        value,
        config
      );
      if (!_.isEmpty(constraintError)) {
        error.rows = {
          ...error.rows,
          [communication.id]: constraintError,
        };
      }
    });
  }

  return cleanErrorObject(error);
};

/**
 * Validation for the "sub form": add communication
 * @param communication
 * @param allCommunications
 * @param config
 */
export const validateSingleCommunication = (
  communication: Partial<Communication>,
  allCommunications: Partial<Communication>[],
  config?: CampaignOptimizationCommunicationsConfig
): {
  name?: string;
  description?: string;
  channelId?: string;
  propensityId?: string;
  boostFactor?: string;
  variableCosts?: string;
  fixedCosts?: string;
} => {
  const error = {};

  // Validate name
  const nameError = validateName(
    communication.name,
    (allCommunications || [])
      .filter((x) => x.id !== communication.id)
      .map((comm) => comm.name)
      .filter((name): name is string => name !== undefined)
  );
  // Validate description
  const descriptionError = validateDescription(communication.description);

  let channelIdError;
  // The channel is required
  if (!communication.channelId) {
    channelIdError =
      moduleOptimizationMessages.msgNewAugurStepCommunicationsErrNoChannel
        .defaultMessage;
  } else if (
    communication.channelId &&
    !config?.channels?.find(
      (element: Channel) => element?.id === communication.channelId
    )
  )
    channelIdError =
      moduleOptimizationMessages.msgNewAugurStepCommunicationsErrNoChannel
        .defaultMessage;

  let propensityIdError;
  // The propensity is required
  if (!communication.propensityId) {
    propensityIdError =
      moduleOptimizationMessages.msgNewAugurStepCommunicationsErrNoPropensity
        .defaultMessage;
  } else if (
    communication.propensityId &&
    !config?.propensities?.find(
      (element: Propensity) => element?.id === communication.propensityId
    )
  )
    propensityIdError =
      moduleOptimizationMessages.msgNewAugurStepCommunicationsErrNoPropensity
        .defaultMessage;

  // Validate Boost Factor
  const boostFactorError = validateNumber(
    communication.boostFactor,
    'Boost Factor'
  );

  // Validate Variable Costs
  let variableCostsError;
  if (communication.variableCosts) {
    variableCostsError = validateNumber(
      communication.variableCosts,
      'Variable Costs'
    );
  }
  // Validate Fixed Costs
  let fixedCostsError;
  if (communication.fixedCosts) {
    fixedCostsError = validateNumber(communication.fixedCosts, 'Fixed Costs');
  }

  return {
    name: nameError,
    description: descriptionError,
    channelId: channelIdError,
    propensityId: propensityIdError,
    boostFactor: boostFactorError,
    variableCosts: variableCostsError,
    fixedCosts: fixedCostsError,
  };
};
