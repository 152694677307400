export function convertSpace(value: number | undefined): string {
  if (value === undefined) return '';

  const factor = getOperatingSystemFactor();
  const convertedValue = value / (factor * factor * factor);

  // Format the number and trim trailing zeros
  let formattedValue = convertedValue.toFixed(2);
  formattedValue = formattedValue.replace(/\.?0+$/, '');

  return formattedValue;
}

export function getOperatingSystemFactor(): number {
  const userAgent = navigator.userAgent.toLowerCase();

  //mac = 1000 , ubuntu=1000, windows=1024
  if (userAgent.indexOf('mac') > -1) return 1000;
  if (userAgent.indexOf('ubuntu') > -1) return 1000;

  return 1024;
}
export function formatFileSizeForOS(value: number): string {
  if (!value && value !== 0) return '';

  const factor = getOperatingSystemFactor();
  const unit = factor === 1000 ? 'B' : 'iB';

  let reducedSize = value;
  let sizeUnit = 'B';

  if (reducedSize >= factor) {
    reducedSize /= factor;
    sizeUnit = `k${unit}`;

    if (reducedSize >= factor) {
      reducedSize /= factor;
      sizeUnit = `M${unit}`;

      if (reducedSize >= factor) {
        reducedSize /= factor;
        sizeUnit = `G${unit}`;
      }
    }
  }

  // Format the number and trim trailing zeros
  let formattedSize = reducedSize.toFixed(2);
  formattedSize = formattedSize.replace(/\.?0+$/, '');

  return `${formattedSize} ${sizeUnit}`;
}
