import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { TextInputAreaV1AugurSettings as TextInputAreaAugurSettings } from 'common/dist/types/augurSettings/textInputAreaV1AugurSettings';

export type { TextInputAreaAugurSettings };

export type TextInputAreaConfig = {
  placeholder?: string;
};
export type ConfigTextInputAreaConfig = Config<TextInputAreaConfig>;

export type PartialTextInputAreaAugurSettings = TextInputAreaAugurSettings; // Partial<TextInputAreaAugurSettings>

export type TextInputAreaValidationError = string;

export const configAllowedInputsTextInputArea: AllowedInputs<TextInputAreaConfig> =
  {};

export const configTypeTextInputArea = `{
  placeholder: { source: 'hard-coded', value: string }
}`;

export const augurSettingsTypeTextInputArea = `string`;

export const defaultConfigTextInputArea: ConfigTextInputAreaConfig = {
  placeholder: { source: 'hard-coded', value: '' },
};

export const defaultAugurSettingsDefaultTextInputArea: PartialTextInputAreaAugurSettings =
  '';

// JSONSchemaType<TextInputAreaAugurSettings>
export const defaultValidationSchemaTextInputArea = {
  type: 'string',
};
