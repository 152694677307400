import {
  PieChartData,
  PieChartV1ReportData as PieChartReportData,
} from 'common/dist/types/reportData/pieChartV1ReportData';

import { Config } from '../../../../../pages/augur/type';

export type { PieChartReportData, PieChartData };

export type LegendEntryType = {
  color: string;
  label: string;
};

export const configTypePieChart = `{
 legend?: {
  label: string,
  color: string
 }[]
}`;
export const reportDataTypePieChart = `{
  data: [label: string]: number
}`;

export type PieChartConfig = {
  legend?: LegendEntryType[];
};
export type ConfigPieChartConfig = Config<PieChartConfig>;

export const defaultConfigPieChart: ConfigPieChartConfig = {
  legend: {
    source: 'hard-coded',
    value: [],
  },
};

export const defaultReportDataDefaultPieChart: PieChartData = {
  data: {
    truePositives: 258,
    trueNegatives: 230,
    falsePositives: 65,
    falseNegatives: 116,
  },
};
