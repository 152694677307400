import { ApiError } from '../types/responseBodies/errors';

/**
 * Like react-intl.MessageDescriptor + values. Custom type to allow jsonSchema generation without typescript error
 * from the package (Namespace 'Intl' has no exported member 'Locale')
 */
export type ErrorMessage = {
  id: string;
  defaultMessage: string;
  values?: { [value: string]: string };
};

export function isApiError(error: unknown): error is ApiError {
  return (
    error instanceof Object &&
    'formattedMessage' in error &&
    'status' in error &&
    'requestId' in error
  );
}
