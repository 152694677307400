import { DASHBOARD_COMPONENT_SCOPES } from 'common/dist/constants/keycloak';
import { App } from 'common/dist/types/app';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { detailsRoute } from './AppPermissions';
import { fetchAppsAdmin } from '../../../../redux/modules/apps.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { RootState } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';

const SingleAppPermissions: FC = () => {
  const { id } = useParams<{ id: string }>();

  const appsLoading = useSelector<RootState, boolean>(
    (state) => state.appsAdmin?.loading
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAppsAdmin(false));
  }, [dispatch]);

  const app = useSelector<DeprecatedRootState, App>((state) =>
    (state.appsAdmin?.data || []).find((app) => app.code === id)
  ) || { name: '' };

  if (appsLoading) {
    return <Busy isBusy />;
  }

  return (
    <ResourcePermissions
      baseUrl={detailsRoute(id)}
      showBackButton={false}
      resourceType={'app'}
      resourceName={`app:${id}`}
      details={{
        headline: {
          id: 'no-id',
          defaultMessage: `Permissions for App ${app.name}`,
        },
        rows: [],
      }}
      scopes={DASHBOARD_COMPONENT_SCOPES}
      messagesGroupPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign group-wise permissions to the app ${app.name}`,
        },
        description: {
          id: 'no-id',
          defaultMessage: `If you are a member of this group you have access to the app ${app.name}`,
        },
      }}
      messagesUserPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign user-wise permissions to the app ${app.name}`,
        },
      }}
    />
  );
};

export default SingleAppPermissions;
