import { defineMessages } from 'react-intl';

export default defineMessages({
  msgGitCommitModal: {
    id: 'git.commit.modal',
    defaultMessage: 'Git Add & Commit Modal',
  },
  msgGitCommitModalClose: {
    id: 'git.commit.modal.close',
    defaultMessage: 'Close Dialog',
  },
  msgGitCommitMessage: {
    id: 'git.commit.message',
    defaultMessage: 'Commit Message',
  },
  createButton: {
    id: 'repository.create_button',
    defaultMessage: 'Create {type}',
  },
  noRepositoriesTitle: {
    id: 'repository.no_repositories.title',
    defaultMessage: 'There are no repositories of type {type} yet',
  },
  noRepositoriesDescription: {
    id: 'repository.no_repositories.description',
    defaultMessage: 'Create your first {type} now',
  },
  deleteRepositoryModalHeadline: {
    id: 'repository.delete_repository.headline',
    defaultMessage: 'Delete Repository',
  },
  deleteRepositoryModalDescription: {
    id: 'repository.delete_repository.description',
    defaultMessage:
      'You are about to delete this repository. Deleting the repository cannot be undone, the repository data will be lost. Please type {nameShown} and confirm if you are absolutely sure.',
  },
  backToOverview: {
    id: 'repository.back_to_overview',
    defaultMessage: 'Back to {speakingRepoType} Browser',
  },
});
