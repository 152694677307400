import { App } from 'common/dist/types/app';
import React, { FC, useEffect } from 'react';
import { FiMonitor } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import SingleAppPermissions from './SingleAppPermissions';
import styles from './styles.module.scss';
import { fetchAppsAdmin } from '../../../../redux/modules/apps.module';
import { RootState } from '../../../../store/store';
import { toLinkWhitelistQuery } from '../../../../utils';
import * as routes from '../../../index/routes';
import {
  LinkType,
  LoadingType,
  MenuEntryType,
} from '../../../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../../../molecules/with-sidemenu/WithSidemenu';
import { MAIN_MENU_QUERY_WHITELIST } from '../../../organisms/main-menu/MainMenu';
import MainContainer from '../../../pages/main-container/MainContainer';
import NotFound from '../../../pages/not-found/NotFound.container';
import { usersRoutes } from '../routes';

export const detailsRoute = (id: string): string =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.appPermissions.path}/${id}`;

function getActiveCategory(path: string) {
  if (!path) return null;
  const rep = path.replace(
    `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.appPermissions.path}/`,
    ''
  );
  const parts = rep.split('/');
  return parts.length >= 1 ? parts[0] : null;
}

const AppPermissions: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAppsAdmin(false));
  }, [dispatch]);

  const apps = useSelector<RootState, App[]>((state) => state.appsAdmin?.data);

  const appsLoading = useSelector<RootState, boolean>(
    (state) => state.appsAdmin?.loading
  );

  const deriveMenuEntries = () => {
    if (!apps || (apps?.length === 0 && appsLoading)) {
      return Array.from({ length: 3 }).map(
        (_, i) =>
          ({
            type: 'loading',
          } as LoadingType)
      );
    }
    return (apps || []).map(
      (app: App) =>
        ({
          type: 'link',
          id: app.code,
          to: detailsRoute(app.code),
          nameIntlId: 'todo',
          nameDefault: app.name,
          icon: FiMonitor,
        } as LinkType)
    );
  };

  const menuEntries: MenuEntryType[] = deriveMenuEntries();

  const history = useHistory();
  const selectedCategory = getActiveCategory(history.location.pathname);
  const location = useLocation();

  const linkToFirstApp = `${usersRoutes.basePath}/${
    usersRoutes.appPermissions.path
  }/${(menuEntries.filter((me) => me.type === 'link')?.[0] as LinkType)?.id}`;

  return (
    <MainContainer fullWidth transparent>
      <div className={styles.appPermissions}>
        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          preserveWhitelistQueryOnLink={['search']}
        >
          <div className={styles.permissionsContainer}>
            <Switch>
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}/:id`}
                component={SingleAppPermissions}
              />
              <Redirect
                to={toLinkWhitelistQuery(
                  linkToFirstApp,
                  location,
                  MAIN_MENU_QUERY_WHITELIST
                )}
                path={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}/`}
                exact
              />
              {/*Fallback: 404*/}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </div>
        </WithSidemenu>
      </div>
    </MainContainer>
  );
};

export default AppPermissions;
