import CSS from 'csstype';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

type State = {
  open: boolean;
};

export type Props = {
  title?: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
  maxHeight?: number;
  containerStyle?: CSS.Properties;
  contentStyle?: CSS.Properties;
  childrenStyle?: CSS.Properties;
};

const CollapsibleArea: FC<Props> = (props) => {
  const {
    onChange,
    children,
    containerStyle,
    contentStyle,
    maxHeight,
    childrenStyle,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [childHeight, setChildHeight] = useState<number>(0);
  const childRef = useRef();

  useEffect(() => {
    if (!childRef.current) return;

    const updateHeight = () => {
      if (childRef.current) {
        requestAnimationFrame(() => {
          // @ts-ignore
          setChildHeight(childRef.current?.clientHeight);
        });
      }
    };
    // Initial measurement
    updateHeight();
    const resizeObserver = new ResizeObserver(updateHeight);

    resizeObserver.observe(childRef.current);

    return () => {
      if (childRef.current) {
        resizeObserver.unobserve(childRef.current);
      }
    };
  }, [children]); // Re-run if children change

  const renderButton = () => {
    if (childHeight < (maxHeight || 0)) return null;

    const msg = open ? (
      <FormattedMessage id='dashboard.show_less' defaultMessage='Show Less' />
    ) : (
      <FormattedMessage id='dashboard.show_more' defaultMessage='Show More' />
    );

    const icon = open ? (
      <FiChevronUp className={styles.buttonIcon} />
    ) : (
      <FiChevronDown className={styles.buttonIcon} />
    );

    return (
      <button
        type={'button'}
        className={styles.button}
        id={'collapsableWizardButton'}
        onClick={() => {
          setOpen(!open);
          onChange?.(!open);
        }}
      >
        {msg}
        {icon}
      </button>
    );
  };

  const restrictHeight = childHeight >= maxHeight && !open;

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.content} style={contentStyle}>
        {renderButton()}
        <div
          className={styles.childrenOuterWrapper}
          style={{
            height: restrictHeight ? maxHeight : undefined,
            ...childrenStyle,
          }}
        >
          <div className={styles.childrenInnerWrapper} ref={childRef}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleArea;
