import { AugurReport } from 'common/dist/types/reports';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FiCode } from 'react-icons/fi';

import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import InputError from '../../atoms/input-error/InputError';
import { TextInputArea } from '../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import { TransformationWrapper } from './EditAugur';
import { isAugurSettingsElement } from './type';
import { ConfigForm } from './utils/config.form';
import { findElementMeta } from '../../molecules/augur-layout-elements/common/utils';

export type Props = {
  selectedElementId: string;
  sampleReport: AugurReport;
  onBack: () => void;
};

const EditConfigurationPage: FC<Props> = ({
  selectedElementId,
  sampleReport,
  onBack,
}) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<ConfigForm>();
  const [isConfigFormVisible, setIsConfigFormVisible] = useState(true);
  const [jsonParseError, setJsonParseError] = useState<string | null>(null);

  const configError = errors.elements?.[selectedElementId]?.config;
  const isConfigErroneous = configError !== undefined;

  const element = getValues(`elements.${selectedElementId}`);
  const elementMeta = findElementMeta(element.type, element.version);

  const safeJsonParse = (value: string) => {
    try {
      setJsonParseError(null);
      return JSON.parse(value);
    } catch {
      setJsonParseError('Inavlid JSON format');
      return null;
    }
  };

  useEffect(() => {
    const parsedConfig = safeJsonParse(element.config);
    setIsConfigFormVisible(
      elementMeta.ConfigFormComponent && parsedConfig !== null
    );
  }, [element, elementMeta]);

  return (
    <div className={styles.editConfigContainer}>
      <div className={styles.header}>
        <h2>Edit Configuration</h2>
        {elementMeta.ConfigFormComponent && (
          <Button
            form={'squared'}
            Icon={() => <FiCode size={16} />}
            onClick={() => {
              if (safeJsonParse(element.config) !== null) {
                setIsConfigFormVisible((prev) => !prev);
              }
            }}
            title={
              isConfigFormVisible
                ? 'Switch to JSON Editor'
                : isConfigErroneous
                ? 'Cannot switch to Configuration UI. Please fix the JSON.'
                : 'Switch to Configuration UI'
            }
            toggled={!isConfigFormVisible}
            disabled={jsonParseError !== null}
          />
        )}
      </div>

      <div className={styles.body}>
        <div className={styles.leftPanel}>
          <div className={styles.element}>
            <TransformationWrapper
              elementId={selectedElementId}
              sampleReport={sampleReport}
              // Only report element should be interactive because they present data
              // Settings elements contain input forms, which should remain non-interactive
              interactionDisabled={isAugurSettingsElement(element)}
            />
          </div>
        </div>
        <div className={styles.rightPanel}>
          <Controller
            name={`elements.${selectedElementId}.config`}
            control={control}
            render={({ field, fieldState }) => {
              const { onChange, value, ref } = field;
              const parsedValue = safeJsonParse(value);

              if (
                elementMeta.ConfigFormComponent &&
                isConfigFormVisible &&
                parsedValue !== null
              ) {
                return (
                  <elementMeta.ConfigFormComponent
                    configValue={parsedValue}
                    onConfigChange={(updatedConfig) =>
                      onChange(JSON.stringify(updatedConfig, null, 2))
                    }
                    elements={Object.values(getValues('elements'))}
                  />
                );
              } else {
                return (
                  <TextInputArea
                    label={'Configuration'}
                    placeholder={'{\n\n}'}
                    {...fieldState}
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                    amountRows={10}
                    onChange={onChange}
                    value={value}
                  />
                );
              }
            }}
          />
          {isConfigFormVisible && isConfigErroneous && (
            <InputError touched={true} error={configError.message} />
          )}
          {!isConfigFormVisible && (
            <TextInputArea
              label={'Configuration Type'}
              disabled={true}
              value={elementMeta?.configurationType}
              amountRows={10}
            />
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <Button color='primary' label='Close' onClick={onBack} />
      </div>
    </div>
  );
};

export default EditConfigurationPage;
