import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FiPlusCircle } from 'react-icons/fi';

type Props = {
  addEntry: () => void;
};

const AddEntry: FC<Props> = ({ addEntry }) => {
  return (
    <div className={styles.addEntry} onClick={addEntry}>
      <hr className={styles.addEntryHr} />
      <div className={styles.addEntryIcon}>
        <FiPlusCircle size={16} />
      </div>
      <div className={styles.hrLine} />
    </div>
  );
};

export default AddEntry;
