import { K8S_POD_STATUS } from 'common/dist/types/orchestration/realtime';
import React, { FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { useAugur } from '../../../../core/api/augurs';
import {
  openApiSpecUrl,
  useRealtimeSummaryAugur,
} from '../../../../core/api/orchestration';
import { realtimeAugurCodeToRoom, socket } from '../../../../socket';
import BackTo from '../../../atoms/back-to/BackTo';
import stylesOrchestrationDetails from '../../details/styles.module.scss';
import { realtimeLink } from '../../routes';
import { Row } from '../RealtimeSummary';
import stylesSummary from '../styles.module.scss';

export interface RealtimeDetailsProps {
  augurCode: string;
}

const RealtimeDetails: FC<RealtimeDetailsProps> = ({ augurCode }) => {
  const { data: augur } = useAugur(augurCode);
  const augurEnabled = augur !== undefined;
  const realtimeInfo = useRealtimeSummaryAugur(augurCode, augurEnabled);
  useEffect(() => {
    if (augurEnabled) {
      socket.emit('subscribe', realtimeAugurCodeToRoom);
      return () => socket.emit('unsubscribe', realtimeAugurCodeToRoom);
    }
  }, [augurEnabled]);
  const isRunning = realtimeInfo?.status === K8S_POD_STATUS.RUNNING;

  const [iframeKey, setIframeKey] = useState(1);

  return (
    <div className={'Orchestration--content'}>
      <div className={styles.parent}>
        <div className={stylesOrchestrationDetails.detailsHeader}>
          <BackTo linkTo={realtimeLink()} label={'Back to overview'} />
        </div>
        <div className={stylesSummary.tableHeader}>
          <strong>Augur Name</strong>
          <strong>Augur Code</strong>
          <strong>Model Code</strong>
          <strong>Status</strong>
          <strong>Started at</strong>
          <strong>Actions</strong>
        </div>
        {augur && (
          <Row
            augur={augur}
            realtimeInfo={realtimeInfo}
            key={1}
            notInteractive={true}
          />
        )}
        {isRunning && (
          <>
            <div
              className={styles.link}
              onClick={() => setIframeKey((prev) => prev + 1)}
            >
              Refresh view for authorization
            </div>
            <iframe
              key={iframeKey}
              src={`/api/swagger-ui-dist/?url=${openApiSpecUrl(
                realtimeInfo.modelCode
              )}`}
              style={{ flexGrow: 1 }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RealtimeDetails;
