import commonMessages from 'common/dist/messages/common';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import DirectoryStep1 from './DirectoryStep1.container';
import { workbenchRoutes } from '../../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../../atoms/button/Button';
import { useSelectedDirPath } from '../../../../hooks';

interface Props {
  headline;
  directoryName: string;
  filledCompletely: boolean;
  isCreatingDirectory: boolean;
  onSave: (directoryName: string, directoryPath: string) => void;
  /** Click listener for the Cancel button */
  onCancel: () => void;

  /* --- Edit directory Wizard --- */
  /** List of opened notebooks (paths). Needed to disable the edit wizard button, since otherwise the opened notebooks
   * that belong into this directory can't be saved anymore (the notebooks contain the path - that is changed when
   * renaming directories) */
  openedNotebooks?: string[];
  currentPath: string;
}

const fieldName = 'directoryName';

const DirectoryWizard: FC<Props & InjectedFormProps> = ({
  headline,
  onSave,
  directoryName,
  filledCompletely,
  isCreatingDirectory,
  openedNotebooks,
  currentPath,
}) => {
  const selectedDirPath = useSelectedDirPath();

  const notebookInsideDirOpened = !!openedNotebooks?.find((nbPath) =>
    nbPath.startsWith(currentPath + '/')
  );

  const fileBrowserPath = `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`;

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>{headline}</p>
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          {notebookInsideDirOpened && (
            <div style={{ marginBottom: '20px' }}>
              <span style={{ color: 'red' }}>
                Please close all notebooks from inside this directory before
                editing
              </span>
            </div>
          )}

          <DirectoryStep1 />
          <div className={'dialog-button-container'}>
            <Button
              label={commonMessages.cancel}
              color='white'
              linkTo={fileBrowserPath}
            />

            <Button
              label='Finish'
              onClick={() => {
                if (filledCompletely && !isCreatingDirectory) {
                  onSave(directoryName, contentArrayToPath(selectedDirPath));
                }
              }}
              linkTo={fileBrowserPath}
              color='secondary'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const form = { form: 'directoryWizard' };
export default reduxForm(form)(DirectoryWizard);
