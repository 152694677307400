import { REPO_TYPE } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { FiBook, FiGitBranch } from 'react-icons/fi';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ModuleFiles from './moduleFiles/ModuleFiles';
import ModuleOverview from './moduleOverview/ModuleOverview';
import ModuleVersions from './moduleVersions/ModuleVersions';
import styles from './styles.module.scss';
import { useModuleByCode } from '../../../core/api/modules';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import Branches from '../repository-details/branches/Branches.container';
import MergeRequestDetails from '../repository-details/merge-requests/merge-request-details/MergeRequestDetails.container';
import MergeRequests from '../repository-details/merge-requests/MergeRequests.container';
import { collaborationSpaceRoutes } from '../routes';
import OpenMergeRequestWizard from '../wizards/open-merge-request/OpenMergeRequestWizard.container';

type Props = {};

/**
 * @param url - The matched url of the route where this is matched. All created links here are relative to that, but combined with the url they result in absolute paths
 *              E.g. .../modules/details/QTY20Z
 * @param repoAvailable - Does the module have a repo?
 */
function getModuleDetailsMenuEntries(
  url: string,
  repoAvailable: boolean
): MenuEntryType[] {
  return [
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Module',
      },
    },
    {
      type: 'link',
      id: 'modules',
      activePerDefault: true,
      to: url,
      nameIntlId: 'todo',
      nameDefault: 'Details',
      icon: FiBook,
    },
    {
      type: 'link',
      id: 'versions',
      to: `${url}/${collaborationSpaceRoutes.modules.details.versions}`,
      nameIntlId: 'todo',
      nameDefault: 'Versions',
      icon: FiBook,
    },
    {
      type: 'hr',
    },
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Repository',
      },
    },
    {
      type: 'link',
      id: 'files',
      to: `${url}/${collaborationSpaceRoutes.modules.details.files}`,
      nameIntlId: 'todo',
      nameDefault: 'Files',
      icon: FiBook,
      disabled: !repoAvailable,
    },
    {
      type: 'link',
      id: 'branches',
      to: `${url}/branches`,
      nameIntlId: 'dashboard.tab.collab.repositories.branches',
      nameDefault: 'Branches',
      icon: FiGitBranch,
      disabled: !repoAvailable,
    },
    /*
    {
      type: 'link',
      id: 'merge-requests',
      to: `${url}/merge-requests`,
      nameIntlId: 'dashboard.tab.collab.repositories.merge-requests',
      nameDefault: 'Merge Requests',
      icon: FiGitMerge,
      disabled: !repoAvailable,
    },
     */
  ];
}

const ModuleDetails: FC<Props> = () => {
  // Path should be the one from the outer route. We use it to build absolute path/routes in a relative way
  const { path, url, params } = useRouteMatch<{ moduleCode: string }>();
  const moduleCode = params.moduleCode;
  const { data: module } = useModuleByCode(moduleCode);
  const repoAvailable = Boolean(module?.repository);

  // Get the selectedCategory. This could all be a bit more coherent and less duplication, but the main point is we want to discover
  // which route/sidemenu entry is selected via react-router and not manually like the getActiveCategory methods
  const pathsToIds = {
    [`${path}/${collaborationSpaceRoutes.modules.details.versions}`]:
      'versions',
    [`${path}/${collaborationSpaceRoutes.modules.details.files}`]: 'files',
    [`${path}/branches`]: 'branches',
    [`${path}/merge-requests`]: 'merge-requests',
    [`${path}/:group/:repositoryName/merge-requests/:id`]: 'merge-requests',
    [`${path}/:group/:repositoryName/merge`]: 'merge',
    [`${path}/:group/:repositoryName/merge/:id`]: 'merge',
    [`${path}`]: 'modules',
  };
  const match = useRouteMatch(Object.keys(pathsToIds));
  const selectedCategory: string | undefined = pathsToIds[match?.path];
  return (
    <div className={styles.moduleDetails}>
      <WithSidemenu
        menuEntries={getModuleDetailsMenuEntries(url, repoAvailable)}
        selectedTab={selectedCategory}
        handleSelect={() => {}}
      >
        <Switch>
          {/* Module Details */}
          <Route path={`${path}`} exact>
            <ModuleOverview moduleCode={moduleCode} />
          </Route>
          {/* Module Versions */}
          <Route
            path={`${path}/${collaborationSpaceRoutes.modules.details.versions}`}
            exact
          >
            <ModuleVersions moduleCode={moduleCode} />
          </Route>
          {repoAvailable && (
            <>
              {/* Module files */}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.files}`}
                exact
              >
                <ModuleFiles moduleCode={moduleCode} />
              </Route>
              {/* Module Branches */}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.branches}`}
                exact
              >
                <Branches moduleCode={moduleCode} repoType={REPO_TYPE.MODULE} />
              </Route>
              {/* Module Merge Requests */}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.mergeRequest}`}
                exact
              >
                <MergeRequests moduleCode={moduleCode} />
              </Route>
              {/*modules/:group/:repositoryName/merge-request/:id*/}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.mergeRequestDetails}`}
                exact
              >
                <MergeRequestDetails />
              </Route>
              {/*modules/details/:moduleCode/:group/:repositoryName/merge*/}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.merge}`}
                exact
                component={OpenMergeRequestWizard}
              />
              {/*/:group/:repositoryName/merge/edit/:id*/}
              <Route
                path={`${path}/${collaborationSpaceRoutes.modules.details.mergeWizardEdit}`}
                component={OpenMergeRequestWizard}
              />
            </>
          )}
        </Switch>
      </WithSidemenu>
    </div>
  );
};

export default ModuleDetails;
