import React, { FC } from 'react';

import NameContainer from './NameContainer';
import styles from './styles.module.scss';
import { useRepoMeta } from '../../main-menu/MenuEntries';

type Props = {};

const OverlayDevAugurDetails: FC<Props> = (props) => {
  // @ts-ignore
  const { repoMeta } = useRepoMeta();

  return (
    <div className={styles.detailsBar}>
      <NameContainer
        isLoaded={true}
        augurName={repoMeta?.repoFullName.split('/').at(-1)}
        augurHealth={4} // TODO extract value from report?
        habitatName={'Dev Augur'}
      />
    </div>
  );
};

export default OverlayDevAugurDetails;
