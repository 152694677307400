import _ from 'lodash';

import { p3CellToSource } from './cell-implementations/app-cells/python3-input-cell/elements/_interface/Python3InputElementManager';
import Python3InputCellWrapper from './cell-implementations/app-cells/python3-input-cell/Python3InputCellWrapper';
import Python3OutputCellWrapper from './cell-implementations/app-cells/python3-output-cell/Python3OutputCellWrapper';
import CodeCellWrapper from './cell-implementations/code-cell/CodeCellWrapper';
import CredentialsCellWrapper from './cell-implementations/credentials-cell/CredentialsCell';
import MarkdownCellWrapper from './cell-implementations/markdown-cell/MarkdownCellWrapper';
import RawCellWrapper from './cell-implementations/raw-cell/RawCellWrapper';
import NotebookCellWrapper from './NotebookCellWrapper';
import {
  isAppMainNotebook,
  ParentRepoType,
} from '../notebook-content/ButtonBar';

// Contains all available cells (All cells, custom AND specific cells must be listed here, otherwise they can't be
//  rendered)
const allCells: Array<NotebookCellWrapper> = [
  new CodeCellWrapper(),
  new MarkdownCellWrapper(),
  new RawCellWrapper(),
  new Python3InputCellWrapper(),
  new Python3OutputCellWrapper(),
  new CredentialsCellWrapper(),
];

// Contains the cells that are available in ALL notebooks by default
const defaultCells: Array<NotebookCellWrapper> = [
  new CodeCellWrapper(),
  new MarkdownCellWrapper(),
  new RawCellWrapper(),
];

const appCells: Array<NotebookCellWrapper> = [
  new Python3InputCellWrapper(),
  new Python3OutputCellWrapper(),
  new CredentialsCellWrapper(),
];

/**
 * Returns the cell implementation for the given ID
 * @param searchType
 */
export function getCellByType(
  searchType: string
): NotebookCellWrapper | undefined {
  const foundCell = allCells.find((c) => c.type === searchType);
  if (!foundCell) {
    // console.log(`Unknown cell type: ${searchType}`);
    return undefined;
  }
  return foundCell;
}

export const getCellByTypeMemoized = _.memoize(getCellByType);

/**
 * Returns whether the given cell type is executable
 * @param type
 */
export function isCellExecutable(type: string): boolean {
  const foundCell = getCellByType(type);
  if (!foundCell) {
    // console.log(`Unknown cell type: ${type}`);
    return false;
  }
  return foundCell.executable;
}

export function getAvailableCells(
  notebookName: string,
  kernelspecName: string,
  parentRepository: ParentRepoType
) {
  let cells = [...defaultCells];
  if (isAppMainNotebook(notebookName, kernelspecName, parentRepository)) {
    cells = [...cells, ...appCells];
  }
  return cells.map((e) => ({
    label: e.name,
    value: e.type,
  }));
}

/**
 * Takes a cell and returns the source code for it depending on the cell type.
 * For many types nothing special happens, only for the type "python3-input" the source needs to be derived before it
 * can be returned
 * @param cell
 */
export function getSource(cell: any) {
  if (cell.cell_type === 'python3-input') {
    // For these cells the source needs to be derived first.
    return p3CellToSource(cell);
  }

  // For all other cells the source is already contained in the 'source' attribute
  return cell.source;
}
