import classNames from 'classnames';
import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import Bubble, { Props as BubbleProps } from '../../atoms/bubble/Bubble';
import IntlWrapper from '../../atoms/intl-wrapper/IntlWrapper';
import { DeIntl } from '../../atoms/intl-wrapper/type';

export type Props = {
  title: string | MessageDescriptor;
  description: string | MessageDescriptor;
} & BubbleProps;

const BubbleStep: FC<DeIntl<Props>> = ({
  title,
  description,
  children,
  ...bubbleProps
}) => {
  return (
    <div className={classNames(styles.wizardStep)}>
      <div className={styles.wizardStepTop}>
        <div className={styles.infoContainer}>
          <div className={styles.bubbleContainer}>
            <Bubble {...bubbleProps} />
          </div>
          <div className={styles.textContainer}>
            {title && <span className={styles.title}>{title}</span>}
            {description && (
              <span className={styles.description}>{description}</span>
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputParent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const IntlBubbleStep: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={BubbleStep} props={props} />;
};

export default IntlBubbleStep;
