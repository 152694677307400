export const initial = {
  augurSettings: {},
  newAugur: {},
};

export const test = {
  augurSettings: {
    values: {
      predictionSchedule: {
        mode: 'manual',
      },
    },
  },
  newAugur: {
    values: {
      augurName: '',
      predictionTargets: [],
      predictionSchedule: {
        mode: '',
      },
    },
  },
  newDataConnection0: {
    values: {
      name: 'name',
    },
  },
  newDataConnection1: {
    values: {
      creationType: 'server',
    },
  },
  newDataConnection2: {
    values: {
      description: 'description',
    },
  },
  newDataConnection3: {
    values: {
      dataType: 'mapping',
    },
  },
  'account.position': {
    values: {
      position: 'manager',
    },
  },
};
