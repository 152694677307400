import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Tooltip } from 'react-tooltip';

import styles from './styles.module.scss';
import TimelineEntryComponent from './TimeLineEntry';
import { TimelineEntry } from './types';
import { AugurCategory } from '../../molecules/augur-menu/types';

export type Props = {
  entries: TimelineEntry[];
  selectedPageCategory: AugurCategory;
  activeModelCode?: string;
  lastElementRef?: (node: HTMLDivElement | null) => void;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>> | void;
};

// Maximum number of highlighted entries to display
const MAX_HIGHLIGHTED_ENTRIES = 8;

interface TooltipState {
  type: 'job-code' | 'max-entries' | null;
  content: string;
  entryId: string | null;
}

const AugurTimeline: FC<Props> = ({
  entries,
  activeModelCode,
  lastElementRef,
  selectedPageCategory,
  fetchNextPage,
}) => {
  // Ref for the main container element
  const containerRef = useRef<HTMLDivElement>(null);
  // Ref to store references to highlighted elements
  const highlightedRefsMap = useRef<Map<string, HTMLDivElement>>(new Map());

  // Memoized entries with grid row information
  const entriesWithGridRow = useMemo(
    () =>
      entries.map((entry, index) => ({
        ...entry,
        gridRow: index + 1,
      })),
    [entries]
  );

  // State for tooltip
  const [tooltipState, setTooltipState] = useState<TooltipState>({
    type: null,
    content: '',
    entryId: null,
  });

  // State for highlighted entries
  const [highlightedEntries, setHighlighted] = useState<
    Array<
      TimelineEntry & {
        gridRow: number;
        position: 'above' | 'below' | undefined;
      }
    >
  >([]);
  // State to track if scrolling is manual
  const [isManualScrolling, setIsManualScrolling] = useState(false);

  // Function to check visibility of entries and update highlighted entries
  const checkVisibility = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const containerTop = container.scrollTop;
    const containerBottom = containerTop + containerRect.height;

    const newHighlightedEntries = entriesWithGridRow
      .filter((entry) => entry.highlighted)
      .map((entry) => {
        const element = container.querySelector(`[data-id="${entry.id}"]`);
        if (!element) return null;

        const rect = element.getBoundingClientRect();
        const elementTop = rect.top - containerRect.top;
        const elementBottom = rect.bottom - containerRect.top;

        // Determine if the entry is above, below, or within the view
        if (elementBottom <= 0) {
          return { ...entry, position: 'above' as const };
        } else if (elementTop >= containerRect.height) {
          return { ...entry, position: 'below' as const };
        }
        return { ...entry, position: undefined };
      })
      .filter(Boolean);

    setHighlighted(newHighlightedEntries);
  }, [entriesWithGridRow]);

  // Function to scroll to a specific element
  const scrollToElement = useCallback(
    async (id: string) => {
      const container = containerRef.current;
      if (!container) return;

      const targetElement = container.querySelector(`[data-id="${id}"]`);
      if (targetElement) {
        setIsManualScrolling(true);
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for smooth scroll to finish
        setIsManualScrolling(false);
        checkVisibility();
      } else {
        // If the target element is not found, try to fetch the next page
        try {
          await fetchNextPage();
          // After fetching, check if the element is now available
          const newTargetElement = container.querySelector(`[data-id="${id}"]`);
          if (newTargetElement) {
            // If found, scroll to it
            setIsManualScrolling(true);
            newTargetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setIsManualScrolling(false);
            checkVisibility();
          } else {
            console.log('Element not found even after fetching next page');
          }
        } catch (error) {
          console.error('Error fetching next page:', error);
        }
      }
    },
    [checkVisibility, fetchNextPage]
  );

  // Function to handle scroll events
  const handleScroll = useCallback(() => {
    if (!isManualScrolling) {
      checkVisibility();
    }
  }, [isManualScrolling, checkVisibility]);

  // Effect to add scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  // Effect to observe highlighted elements
  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      let shouldCheck = false;
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          shouldCheck = true;
        }
      });
      if (shouldCheck) {
        checkVisibility();
      }
    };

    const intersectionObserver = new IntersectionObserver(observerCallback, {
      root: containerRef.current,
      threshold: 0,
      rootMargin: '-1px 0px',
    });

    const highlightedElements = containerRef.current?.querySelectorAll(
      '[data-highlighted="true"]'
    );
    highlightedElements?.forEach((el) => intersectionObserver.observe(el));

    return () => intersectionObserver.disconnect();
  }, [checkVisibility, entriesWithGridRow]);

  // Effect to observe the last element for infinite scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[0];
        if (lastEntry.isIntersecting && lastElementRef) {
          lastElementRef(lastEntry.target as HTMLDivElement);
        }
      },
      { root: containerRef.current, threshold: 0.5 }
    );

    const lastElement = containerRef.current?.lastElementChild;
    if (lastElement) {
      observer.observe(lastElement);
    }

    return () => observer.disconnect();
  }, [entriesWithGridRow, lastElementRef]);

  // Effect to check visibility when entries change
  useEffect(() => {
    checkVisibility();
  }, [entriesWithGridRow, checkVisibility]);

  const renderEntry = useCallback(
    (
      entry: TimelineEntry & { gridRow: number },
      isOutOfView = false,
      isAbove = false
    ) => (
      <TimelineEntryComponent
        key={entry.id}
        entry={entry}
        isOutOfView={isOutOfView}
        isAbove={isAbove}
        highlightedRefsMap={highlightedRefsMap}
        entriesWithGridRow={entriesWithGridRow}
        scrollToElement={scrollToElement}
        setTooltipState={setTooltipState}
        tooltipState={tooltipState}
        selectedPageCategory={selectedPageCategory}
      />
    ),
    [entriesWithGridRow, scrollToElement, tooltipState, selectedPageCategory]
  );

  // Memoized entries to prevent unnecessary re-renders
  const memoizedEntries = useMemo(
    () => entriesWithGridRow.map((entry) => renderEntry(entry)),
    [entriesWithGridRow, renderEntry]
  );

  // Filter and slice highlighted entries based on the selected category
  const filteredEntries = useMemo(
    () =>
      highlightedEntries
        .filter((entry) => entry.category === selectedPageCategory)
        .slice(0, MAX_HIGHLIGHTED_ENTRIES),
    [highlightedEntries, selectedPageCategory]
  );

  // Entries above the view
  const aboveEntries = useMemo(
    () => filteredEntries.filter((entry) => entry.position === 'above'),
    [filteredEntries]
  );

  // Entries below the view
  const belowEntries = useMemo(
    () => filteredEntries.filter((entry) => entry.position === 'below'),
    [filteredEntries]
  );

  // Generate model timeline elements
  const modelTimelineElements = useMemo(() => {
    let currentModel = null;
    let currentElement = null;
    const result = [];

    entriesWithGridRow.forEach((entry) => {
      if (entry.modelCode !== currentModel) {
        if (currentElement) {
          result.push(currentElement);
        }
        currentModel = entry.modelCode;
        currentElement = {
          modelCode: entry.modelCode,
          rowStart: entry.gridRow,
          rowEnd: entry.gridRow + 1,
        };
      } else if (currentElement) {
        currentElement.rowEnd = entry.gridRow + 1;
      }
    });

    if (currentElement) {
      result.push(currentElement);
    }

    return result;
  }, [entriesWithGridRow]);

  // Render the timeline
  return (
    <div className={styles.timeTravelContainer}>
      <Tooltip
        id='timeline-tooltip'
        className={styles.tooltip}
        place='top'
        isOpen={tooltipState.type !== null}
        content={tooltipState.content}
      />
      {/* Render entries above the view */}
      {aboveEntries.length > 0 && (
        <div className={styles.highlightedAboveContainer}>
          {aboveEntries.map((entry) => renderEntry(entry, true, true))}
        </div>
      )}
      {/* timeline grid */}
      <div className={styles.grid} ref={containerRef} onScroll={handleScroll}>
        {memoizedEntries}
        {/* Render model timeline elements */}
        {modelTimelineElements.map((element) => {
          const gridRow = `${element.rowStart} / ${element.rowEnd}`;
          return (
            <React.Fragment key={`${element.modelCode}-${element.rowStart}`}>
              <div
                className={classNames(styles.modelBar, {
                  [styles.highlighted]: element.modelCode === activeModelCode,
                })}
                style={{ gridRow }}
                title={
                  element.modelCode === activeModelCode
                    ? `Active Model: ${element.modelCode}`
                    : `Model: ${element.modelCode}`
                }
              >
                {element.modelCode}
              </div>
              {/* Render job connections */}
              {_.range(element.rowStart, element.rowEnd - 1).map(
                (rowNumber) => {
                  const gridRow = `${rowNumber} / ${rowNumber + 2}`;
                  return (
                    <div
                      key={`jobConnection-${rowNumber}`}
                      className={classNames(styles.jobConnection, {
                        [styles.highlighted]:
                          element.modelCode === activeModelCode,
                      })}
                      style={{ gridRow }}
                    />
                  );
                }
              )}
            </React.Fragment>
          );
        })}
      </div>
      {/* Render entries below the view */}
      {belowEntries.length > 0 && (
        <div className={styles.highlightedBelowContainer}>
          {belowEntries.map((entry) => renderEntry(entry, true, false))}
        </div>
      )}
    </div>
  );
};

// Memoize the entire component to prevent unnecessary re-renders
export default React.memo(AugurTimeline);
