import { formatDate } from 'common/dist/utils/dates';
import React, { FC, Fragment } from 'react';
import { BsDot } from 'react-icons/bs';
import { FiGitBranch } from 'react-icons/fi';
import { useRouteMatch } from 'react-router-dom';

import {
  moduleMergeRequestSourceBranchLink,
  repositoryMergeRequestSourceBranchLink,
} from '../../routes';

function calcTime(date) {
  const diff = (Date.now() - date) / 1000;
  if (diff < 60) {
    if (Math.floor(diff) === 1) return diff + ' second ago';
    else return Math.floor(diff) + ' seconds ago';
  } else if (diff < 60 * 60) {
    const mins = Math.floor(diff / 60);
    if (mins === 1) return mins + ' minute ago';
    else return mins + ' minutes ago';
  } else if (diff < 60 * 60 * 24) {
    const hour = Math.floor(diff / 60 / 60);
    if (hour === 1) return hour + ' hour ago';
    else return hour + ' hours ago';
  } else if (diff < 60 * 60 * 24 * 7) {
    const days = Math.floor(diff / 60 / 60 / 24);
    if (days === 1) return days + ' day ago';
    else return days + ' days ago';
  } else {
    const weeks = Math.floor(diff / 60 / 60 / 24 / 7);
    if (weeks === 1) return weeks + ' week ago';
    else return weeks + ' weeks ago';
  }
}

interface ContentElementProps {
  /** Name of the element */
  name: string;
  group: string;
  repositoryName: string;
  commit: {
    message: string;
    author: {
      name: string;
    };
    timestamp: string;
  };

  switchBranch(...args: unknown[]): unknown;
}

const ContentElement: FC<ContentElementProps> = ({
  name,
  group,
  repositoryName,
  commit,
  switchBranch,
}) => {
  const { params } = useRouteMatch<{ moduleCode: string }>();
  const moduleCode = params.moduleCode;
  let mrButtonLink = repositoryMergeRequestSourceBranchLink(
    group,
    repositoryName,
    name
  );
  if (moduleCode) {
    mrButtonLink = moduleMergeRequestSourceBranchLink(
      moduleCode,
      group,
      repositoryName,
      name
    );
  }

  return (
    <Fragment>
      <div className={'branch'} onClick={() => switchBranch(name)}>
        <div className={'branch-icon-parent'}>
          <FiGitBranch className={'content-element-icon'} size={'20px'} />
        </div>

        <div className={'branch-name-parent'}>
          <div className={'branch-name'}>
            <span className={'name'}>{name}</span>
          </div>
          <div className={'branch-commit'}>
            <span className={'commit-details'}>
              {commit ? (
                commit.message
              ) : (
                <i>There are no commits on this branch yet.</i>
              )}
            </span>
          </div>
          <div className={'branch-details'}>
            <span>{commit ? commit.author.name : ''}</span>
            <span>
              <BsDot />
            </span>
            <span
              title={
                commit
                  ? formatDate(
                      new Date(commit.timestamp),
                      'dd.MM.yyyy HH:mm:ss'
                    )
                  : ''
              }
            >
              {commit ? calcTime(new Date(commit.timestamp)) : ''}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContentElement;
